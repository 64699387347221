import { useEffect, useState } from "react";
import { createLineString } from "helpers/createLineString";
import { EVENT_CLUSTER_COLOR } from "utils/const";
import { useUserStore } from "zustandGloabalStore/user";

/**
 * Generates a lines event relation for a given component and parent event?.
 *
 * @param {Object} options - The options for generating the lines event relation.
 * @param {Object} options.component - The component object.
 * @param {Object[]} options.parentComponent - The parent component array.
 * @param {Object[]} options.componentEvents - The component events array.
 * @return {Object} The event lines and parent event lines.
 */
const useCreateLinesComponentEventsRelation = ({
  component,
  parentComponent,
  componentEvents,
}) => {
  const [componenEventLines, setComponentEventLines] = useState([]);
  const [parentComponentLines, setParentComponentLines] = useState([]);
  const { instanceId } = useUserStore((state) => state);


  useEffect(() => {
    setComponentEventLines([]);
  }, [component, setComponentEventLines]);

  useEffect(() => {
    const createLineFeature = () => {
      const componenEventLines = componentEvents
        .filter((point) => point?.instanceId === parseInt(instanceId))
        .map((event) => {
          const firstLocation = [
            component?.locationComponent.longitude,
            component?.locationComponent.latitude,
          ];
          const secondLocation = [
            event.locationOperation.longitude,
            event.locationOperation.latitude,
          ];
          const lineString = createLineString(
            [firstLocation, secondLocation],
            EVENT_CLUSTER_COLOR
          );
          return lineString;
        });
      setComponentEventLines((prev) => prev.concat(componenEventLines));
    };

    componentEvents && createLineFeature();
  }, [componentEvents, component, instanceId]);

  useEffect(() => {
    const createMarkerLine = () => {
      const eventLines = parentComponent?.map((marker) => {
        const firstLocation = [
          component?.locationComponent.longitude,
          component?.locationComponent.latitude,
        ];
        const secondLocation = [marker?.longitude, marker?.latitude];
        const lineString = createLineString(
          [firstLocation, secondLocation],
          EVENT_CLUSTER_COLOR
        );
        return lineString;
      });
      setParentComponentLines(eventLines);
    };

    parentComponent?.length > 0 && createMarkerLine();
  }, [parentComponent, component, instanceId]);

  return { parentComponentLines, componenEventLines };
};

export default useCreateLinesComponentEventsRelation;
