import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";

const CustomTabs = ({ value, onChange }) => {
  const StyledTabs = styled(Tabs)({
    display: "flex",
  });
  const role = localStorage.getItem("role");
  const tittleTab = localStorage.getItem("selectedSettingsCategory") || "";
  const location = useLocation();
  const navigate = useNavigate();

  const [currentRole, setCurrentRole] = useState(role);

  useEffect(() => {
    setCurrentRole(role);
  }, [role]);

  // Detectar si una subpestaña está activa
  const isNoCodeActive =
    location.pathname === "/config-admin" &&
    tittleTab === "No code development";
  const isPlatformSetUpActive =
    location.pathname === "/setUp-admin" && tittleTab === "Platform Set Up";

  // Si hay una subpestaña activa, el indicador se mueve ahí, de lo contrario, sigue la pestaña principal.
  const indicatorValue = isNoCodeActive || isPlatformSetUpActive ? 2 : value;

  return (
    <StyledTabs
      className="background-1"
      value={indicatorValue}
      onChange={onChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: SECOND_COLOR,
          height: "6px",
          borderRadius: "6px",
        },
      }}
    >
      <Tab
        label="Available Modules"
        style={{
          color: PRIMARY_COLOR,
          fontFamily: document.body.style.fontFamily,
        }}
        sx={{
          marginLeft: "115px",
          fontSize: "20px",
          "@media (max-width: 768px)": {
            marginLeft: "50px",
            fontSize: "17px",
          },
        }}
        onClick={() => navigate("/admin")}
      />
      <Tab
        style={{
          color: currentRole !== "Operario" ? PRIMARY_COLOR : "#9F9E9E",
        }}
        label="SETTINGS"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled={currentRole === "Operario"}
        onClick={() => navigate("/settings-app")}
      />

      {/* Subpestañas dinámicas */}
      {isNoCodeActive && (
        <Tab
          style={{
            color: PRIMARY_COLOR,
          }}
          label="NO CODE DEVELOPMENT"
          sx={{
            fontFamily: document.body.style.fontFamily,
            fontSize: "20px",
            "@media (max-width: 768px)": {
              fontSize: "17px",
            },
          }}
          disabled
        />
      )}

      {isPlatformSetUpActive && (
        <Tab
          style={{
            color: PRIMARY_COLOR,
          }}
          label="PLATFORM SET UP"
          sx={{
            fontFamily: document.body.style.fontFamily,
            fontSize: "20px",
            "@media (max-width: 768px)": {
              fontSize: "17px",
            },
          }}
          disabled
        />
      )}
      {/*Fin subpestañas */}
      <Tab
        className="module-color"
        label="AI AGENTS"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
      <Tab
        className="module-color"
        label="TUTORIALS"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
      <Tab
        className="module-color"
        label="LOGIN INFO"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
      <Tab
        className="module-color"
        label="Get More"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
    </StyledTabs>
  );
};

export default CustomTabs;
