import { Edit } from "@material-ui/icons";
import { validateBBox } from "@turf/turf";
import {
  InputProfile,
  Label,
  WrapperInput,
  LegendError,
  InputFile,
  LabelFile,
} from "./FormStyles";
import NoImage from "assets/NoImage";

const InputComponent = ({ field, onChange, title }) => {
  const {
    value,
    validate,
    type,
    label,
    placeholder,
    name,
    legendError,
    regularExpression,
    min,
    max,
    step,
    validation: validationTest,
  } = field;

  // Validate the field
  const validation = ({ target }) => {
    const { value } = target;

    if (regularExpression) {
      if (regularExpression.test(value)) {
        onChange({ target: { name, validate: "true" } });
      } else {
        onChange({ target: { name, validate: "false" } });
      }
    }

    if (validationTest) {
      validationTest(value);
    }
  };

  // Change the value of the field
  const handleOnchange = (e) => {
    onChange(e);
    validation(e);
  };

  // Type of input image
  if (type === "image") {
    let imageComponent = <NoImage />;

    if (value instanceof File) {
      imageComponent = (
        <img
          src={URL.createObjectURL(value)}
          alt=""
          style={{ maxWidth: "50%", height: "auto" }}
        />
      );
    } else if (value !== null && value !== undefined && value !== "") {
      imageComponent = <img src={value} alt="" style={{ width: "100%" }} />;
    }
    return (
      <div
        style={{
          width: "40%",
          justifyContent: "center",
          height: title === "Create a new Digital Twin Point" ? "none" : "50%",
          display: "flex",
          alignItems: "center",
          position:
            title === "Create a new Digital Twin Point" ? "none" : "relative",
          top: "35px",
        }}
      >
        <WrapperInput position={"none"}>
          <LabelFile
            valid={validate}
            width={title === "Create a new Digital Twin Point" ? "80%" : "100%"}
            height={
              title === "Create a new Digital Twin Point" ? "140px" : "200px"
            }
          >
            <span>
              <Edit />
              {label}
            </span>
            <div className="background-row-2">{imageComponent}</div>

            <InputFile
              type="file"
              id={name}
              onChange={handleOnchange}
              onKeyUp={validation}
              onBlur={validation}
              valid={validate}
              name={name}
              accept="image/*"
            />
          </LabelFile>
        </WrapperInput>
      </div>
    );
  }
  if (type === "select") return null;

  return (
    <>
      <WrapperInput
        width={title === "Create a new Digital Twin Line" ? "100%" : "60%"}
        position={"relative"}
      >
        <Label valid={validate}>
          {label}
          {(type === "range" || type === "color") && `: ${value}`}
          <InputProfile
            type={type}
            placeholder={placeholder}
            id={name}
            value={value === null ? "" : value}
            autocomplete="off"
            onChange={handleOnchange}
            onKeyUp={validation}
            onBlur={validation}
            valid={validate}
            name={name}
            min={min}
            max={max}
            step={step}
          />
        </Label>

        <LegendError valid={validateBBox}>{legendError}</LegendError>
      </WrapperInput>
    </>
  );
};

export default InputComponent;
