import { BLACK_COLOR } from "utils/const";
import ReportIcon from "images/ReportIcon";
import CompanyManagerIcon from "images/CompanyManager";
import ConfigIcon from "images/ConfigIcon";

export const infoHubAdminDeci = [
  {
    title: "INSTANCE MANAGERS",
    navigate: "/instance-manager",
    icon: <CompanyManagerIcon />,
    description: "Add and manage instances",
    color: BLACK_COLOR,
  },
  {
    title: "REPORTS",
    navigate: "#",
    icon: <ReportIcon />,
    description: "View reports",
    color: BLACK_COLOR,
  },
];
