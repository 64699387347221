import { useStoreBucketUser } from "zustandGloabalStore/bucketRoot";
import { getBucketNameEnv } from "config.js";

export const getPathBucket = (path) => {
  const bucketUser = useStoreBucketUser.getState().bucketUser;
  const bucketNameEnv = getBucketNameEnv();

  // Determine the folder path based on environment
  const folderPath =
    bucketNameEnv === "bucket-production"
      ? `${bucketUser.bucketName}/${path}`
      : `${bucketNameEnv}/${bucketUser.bucketName}/${path}`;

  return folderPath;
};
