import axios from "axios";
import { urls } from "utils/urlKeys";
const saveAdminCompany = async (body) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(urls.adminCompany.all, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export default saveAdminCompany;
