import { styled } from "@mui/material/styles";
import {
  BACKGROUD_GREY_PREVIEW,
  GRAY_COLOR,
  WHITE_BACKGROUND,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";
import Accordion from "@mui/material/Accordion";

// import {
//   PRIMARY_COLOR,
//   BLACK_COLOR,
//   WHITE_COLOR,
//   WHITE_SECOND_COLOR,
// } from "../../../../utils/const";

export const DynamicColorsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  padding: 10px;
  padding-right: 10px;
  margin-top: 1rem;
  gap: 1rem;
  height: fit-content;
`;

export const DynamicColorsTitle = styled("div")`
  width: 100%;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 10px 0px;
`;

export const DynamicColorsFields = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  padding-left: 20px;

  .library-name-container {
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-bottom: 1px solid black;
    gap: 5%;
    margin-top: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }
`;

export const MappingLibraryContainer = styled("div")`
  width: 600px;
  height: auto;
  overflow-y: auto;
  margin-top: -20px;
  border-radius: 10px;
  padding: 10px;

  .lib-container {
    box-shadow: 0px 0px 10px -3px;
    border-radius: 5px;
  }
`;

export const LibraryField = styled("div")`
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
  padding: 15px 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: ${WHITE_BACKGROUND};

  .library-names {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .color-container {
    height: auto;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
  }
  input[type="checkbox"] {
    width: 1.25rem;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid ${PRIMARY_COLOR};
    text-align: center;
    background-color: white;
    margin-left: 20px;
  }
  .color-item {
    display: flex;
    flex-direction: row;
    p {
      width: fit-content;
      margin-right: 10px;
      /* min-width: 200px; */
    }
    input[type="color"] {
      height: 20px;
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid ${PRIMARY_COLOR};
      text-align: center;
      background-color: white;
      margin-left: 1rem;
      margin-bottom: 5px;
    }
  }
  .color-item-leyend {
    width: 50%;
    display: flex;
    flex-direction: row;
  }
  .color-item-container {
    display: flex;
    width: 120px;
  }
  .input-number-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 50%;

    input[type="number"] {
      height: 25px;
      margin: 1px 1px;
      -moz-appearance: textfield;
      text-align: center;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .label-restriction-container {
    width: 80%;
    padding-right: 10px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .input-number-container-doble {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .input-number-solo {
    width: 200px;
  }
  .input-number-doble {
    width: 100px;
  }
`;

export const CustomizedAccordion = styled(Accordion)`
  /* width: 100% !important; */
  display: flex;
  flex-direction: column;
  background-color: ${WHITE_SECOND_COLOR};
  margin-top: 0px;
  border: 1px solid ${GRAY_COLOR};
  border-radius: 4px;
  padding: 5px;
  box-shadow: none;
  margin-bottom: 1rem;
`;

export const WrapperTypeObjectTitle = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0 0 10px 0px; */
  p {
    font-size: 1.4rem;
    color: ${(props) =>
      props.theme === "light" ? WHITE_COLOR : document.body.style.color};
  }
`;
