import { useState, useEffect, useCallback } from "react";
// Helpers
import {
  sumValues,
  countByValues,
} from "pages/Dashboard/helpers/objectsGroupDonutChart";
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";

export const useFindGroupDoughnutChartsObjects = ({
  openDialog,
  objects,
  loading,
}) => {
  const [charts, setCharts] = useState(null);
  const [groupDonutCharts, setGroupDonutCharts] = useState(null);
  const [objectsFormat, setObjectsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataGroupDonutChart, error: errorDataGroupDonutChart } = useSwr(
    urlsApiMg.dashboard.doughnutChartGroup("objects")
  );

  useEffect(() => {
    if (!errorDataGroupDonutChart && dataGroupDonutChart)
      setCharts(dataGroupDonutChart);
  }, [dataGroupDonutChart, errorDataGroupDonutChart, openDialog, reloadCharts]);

  useEffect(() => {
    const objectsFormat = createRowsObjects({ objects });
    setObjectsFormat(objectsFormat);
  }, [objects]);

  const getDataGroupDonutChart = useCallback(async () => {
    if (!loading && objectsFormat) {
      const chartsFilterCount = charts.filter(
        (chart) => chart.type === "count"
      );
      const countCharts = countByValues({
        charts: chartsFilterCount,
        objects: objectsFormat,
      });

      const chartsFilterSum = charts.filter((chart) => chart.type === "sum");
      const sumCharts = sumValues({
        charts: chartsFilterSum,
        objects: objectsFormat,
      });

      setGroupDonutCharts([...countCharts, ...sumCharts]);
    }
  }, [loading, objectsFormat, charts]);

  useEffect(() => {
    if (charts && objects) {
      getDataGroupDonutChart();
    }
  }, [charts, objects, getDataGroupDonutChart]);

  return { groupDoughnutCharts: groupDonutCharts };
};
