import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "../../utils/const";

export const GalleryContainerNoThumbnails = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    background-color: ${BLACK_COLOR};
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .image-gallery-content {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 0px 0px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const GalleryContainer = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 525px;
    background-color: ${BLACK_COLOR};
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .image-gallery-image:nth-child(${(props) => props.items}) {
      transform: rotate(90deg);
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 8px 15px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const LabelRotatePhoto = styled("label")`
  background: var(--background-primary_color);
  width: 100%;
  margin-bottom: 0px;
  border-radius: 5px;
  color: ${WHITE_SECOND_COLOR};
  padding: 0.6rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  p {
    margin-left: 0.5rem;
  }

  &:hover {
    background: var(--background-secondary_color);
  }
`;

export const Label = styled("label")`
  background: var(--background-primary_color);
  width: 100%;
  border-radius: 10px;
  color: ${WHITE_SECOND_COLOR};
  padding: 0.6rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  p {
    margin-left: 0.5rem;
  }

  &:hover {
    background: var(--background-secondary_color);
  }
`;

export const Wrapperbtn = styled("div")`
  width: 100%;
  min-width: 230px;
  max-width: 380px;
  /* height: 200px; */
  margin: 0 auto;
`;

export const InfoTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
  /* height: auto; */
  text-align: left;
  color: var(--background-color3);

  .main-currency-table {
    margin: 0px 10px 0px 10px;
  }
  .main-currency-table .currency-table--title {
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
  }
  .currency-table--container {
    padding: 20px;
  }
  .currency-table--container table {
    width: 100%;
    height: 100%;
    border-spacing: 4px;
  }

  .currency-table--container table td {
    width: 50%;
    height: auto;
    padding: 10px 0;
    font-size: 1.6rem;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
  }

  .currency-table--container table td div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .currency-table--container table td button {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    height: 2.3rem;
    background: transparent;
    border: none;
    word-break: break-word;
    cursor: pointer;
  }

  .currency-table--container .table__top-left {
    border-radius: 5px 0 0 0;
  }
  .currency-table--container .table__top-right {
    border-radius: 0 5px 0 0;
  }
  .currency-table--container .table__bottom-right {
    border-radius: 0 0 5px 0;
  }
  .currency-table--container .table__bottom-left {
    border-radius: 0 0 0 5px;
  }
  .currency-table--container .table__right {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: right;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
    background-color: #f8f8f8;
  }
  .currency-table--container .table__left {
    max-width: 200px;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  @media (min-width: 1280px) and (max-width: 1320px) {
    .currency-table--container .table__left {
      max-width: 80px;
    }
  }

  @media (min-width: 1320px) and (max-width: 1420px) {
    .currency-table--container .table__left {
      max-width: 100px;
    }
  }

  @media (min-width: 1420px) and (max-width: 1620px) {
    .currency-table--container .table__left {
      max-width: 150px;
    }
  }

  .currency-table--container .table__right button {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.3rem;
    margin-left: 1rem;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .object-id {
    cursor: pointer;
    color: blue;
  }

  .currency-table--container table tr:nth-child(2n + 1) {
    background-color: #f7f4f3;
  }
  .currency-table--container table tr:nth-child(2n) {
    background-color: ${WHITE_COLOR};
  }
`;

export const WraperTitleCardMarker = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;

  .title {
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    flex: 1;
    color: var(--background-primary_color);
    font-family: ${document.body.style.fontFamily};
  }

  .wraper-title-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    h2 {
      font-size: 2rem;
    }
  }
  span {
    color: var(--background-primary_color);
  }
  h1 {
    margin-top: 15px;
    text-align: center;
    font-size: 2rem;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #636363;
  }
  img {
    width: 45px;
    height: 38px;
    margin: 5px 0;
  }
  .dynamicImg {
    width: 60px;
    height: auto;
    padding: 0;
  }
`;

export const Img = styled.img`
  width: 160px;
  height: auto;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const NavbarLogoR = styled(LinkR)`
  color: ${WHITE_COLOR};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const NavBtnLink = styled.button`
  border-radius: 50px;
  background: #222729;
  padding: 10px 22px;
  color: ${WHITE_COLOR};
  font-size: 1.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${WHITE_COLOR};
    color: #222729;
  }
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: var(--background-primary_color);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: ${WHITE_COLOR};
  }
`;

export const ImageDetailsContainer = styled.div`
  height: 80hv;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-top: 50px;
  margin-top: 50px;
  align-items: center;
  background-color: ${WHITE_SECOND_COLOR};
`;

export const ImageRotation = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: rotate(${(props) => props.rotation}deg);
`;

export const DetailTypeTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  color: ${BLACK_COLOR};

  h1 {
    text-align: center;
    font-size: 2.2rem;
  }

  span {
    color: var(--background-primary_color);
    font-size: 1.9rem;
  }

  h2 {
    text-align: center;
    font-size: 2rem;
  }

  h3 {
    text-align: center;
    font-size: 2rem;
    color: var(--background-primary_color);
  }
  img {
    width: 45px;
    height: 38px;
    margin: 10px 0;
  }
  .dynamicImg {
    width: 20px;
    height: auto;
    padding: 0;
    margin-left: 5px;
  }
  .detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .type-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
