import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
// import Home from "./pages";
import SignIn from "./pages/SignIn";

import "mapbox-gl/dist/mapbox-gl.css";

// Operator pages
import OperatorHub from "./pages/Operator/Hub";
import Collections from "./pages/Operator/Collections";

// Auth pages
import EmailConfirmation from "./pages/Auth/emailConfirmation";
import PasswordConfirmation from "./pages/Auth/passwordConfirmation";
import ForgotPassword from "./pages/Auth/ForgotPassword";

// Admin decimetrix pages
import AdminDecimetrixHub from "./pages/AdminDecimetrix/Hub";
import AdminCompanies from "./pages/AdminDecimetrix/AdminCompanies";

// Admin company pages
import Profile from "./pages/AdminCompany/profile";
import AdminCompany from "./pages/AdminCompany/Hub";
import Operators from "./pages/AdminCompany/Operators";
import DigitalTwinAdmin from "./pages/AdminCompany/DigitalTwin";
// import DashboardsAdmin from "./pages/AdminCompany/Dashboard";
import DashboardsAdmin from "./pages/Dashboard";
import Chatbot from "./pages/AdminCompany/Chatbot";
import SensorOptimization from "./pages/AdminCompany/SensorOptimization";
import FlareEfficiency from "./pages/AdminCompany/FlareEfficiency";

import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// pages for operator
import VideoManager from "./pages/Operator/VideoManager";
import UploadVideos from "./pages/Operator/UploadVideo";
import Users from "pages/Operator/Users";
// import ConfigAdmin from "pages/ConfigAdmin/ConfigAdmin.jsx";
// import ConfigAdmin from "pages/ConfigAdmin";
import PlatformSetUp from "pages/PlatformSetUp";
import ConfigObjects from "pages/ConfigAdmin/Objects";
import ConfigLibrarues from "pages/ConfigAdmin/Libraries";
import ActionsEvents from "pages/ConfigAdmin/ActionsEvents";
// config GlobalConstants
import GlobalConstants from "pages/PlatformSetUp/GlobalConstants";
// import NewWindowTable from "components/Tables/NewWindowTable";
import ConfigComponents from "pages/ConfigAdmin/Components";
// Terms and conditions pages
import TermsCloud from "pages/TermsAndPrivacy/TermsAndConditions/TermsCloud";
import TermsMobile from "pages/TermsAndPrivacy/TermsAndConditions/TermsMobile";
import TermsSocrates from "pages/TermsAndPrivacy/TermsAndConditions/TermsSocrates";
import PolicyCloud from "pages/TermsAndPrivacy/PrivacyPolicy/PolicyCloud";
import PolicyMobile from "pages/TermsAndPrivacy/PrivacyPolicy/PolicyMobile";
import PolicySocrates from "pages/TermsAndPrivacy/PrivacyPolicy/PolicySocrates";
// Hooks
import useUserAuthenticated from "hooks/useUserAuthenticated";
import useUserRole from "hooks/useUserRole";
// Components
import Loading from "components/Lodings/LoadingV2";
import ConfigCards from "pages/AdminCompany/Hub/ConfigCards";
import Preferences from "pages/PlatformSetUp/Preferences";
import { createContext, useState, useEffect } from "react";
import { TooltipProvider } from "components/ToolTips/TooltipContext";
import { ThemeData } from "pages/PlatformSetUp/Preferences/ThemeContext";
import { FontData } from "pages/PlatformSetUp/Preferences/FontData";
import { CreateAccount } from "pages/CreateAccount";
import { DeleteAccount } from "pages/DeleteAccount";
import GlobalValuesFields from "pages/PlatformSetUp/GlobalValuesFields";
// Global Store
import { useStoreHostname } from "zustandGloabalStore/hostname";

export const ThemeContext = createContext(null);

const App = () => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "light";
  });

  const { userRole: operatorRole } = useUserRole();

  const { setHostname } = useStoreHostname();

  useEffect(() => {
    const currentDomain = window.location.hostname;
    // const currentDomain = "reddragonpro.decimetrix.com";
    setHostname(currentDomain);
  }, [setHostname]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  // Función para cambiar el font-family
  const changeFontFamily = (fontFamily) => {
    document.body.style.fontFamily = fontFamily;
  };

  const changeFontColor = (color) => {
    document.body.style.color = color;
  };

  const { token } = useSelector((state) => state.userReducer.user);

  const { loading, authenticated } = useUserAuthenticated({ token });
  // Validate user
  let role = "None";
  if (token) role = jwt_decode(token).role;

  // Change material ui theme
  const themef = createTheme({
    typography: {
      fontSize: 22.4,
    },
  });

  if (loading) {
    return <Loading />;
  }

  const routesToAnyPerson = () => (
    <>
      {/* <Route exact path="/" element={<Home />} /> */}
      <Route
        exact
        path="/password-reset/:token"
        element={<PasswordConfirmation />}
      />
      <Route
        exact
        path="/confirm-email-address/:token/:id"
        element={<EmailConfirmation />}
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {/* Terms and conditions routes */}
      <Route path="/terms-and-conditions-cloud-web" element={<TermsCloud />} />
      <Route path="/terms-and-conditions-mobile" element={<TermsMobile />} />
      <Route
        path="/terms-and-conditions-socrates"
        element={<TermsSocrates />}
      />
      <Route path="/privacy-policy-cloud-web" element={<PolicyCloud />} />
      <Route path="/privacy-policy-mobile" element={<PolicyMobile />} />
      <Route path="/privacy-policy-socrates" element={<PolicySocrates />} />
      {/* Create count */}
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/delete-account" element={<DeleteAccount />} />
    </>
  );
  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, changeFontFamily, changeFontColor }}
    >
      {" "}
      <div className="App" id={theme}>
        {" "}
        <ThemeData>
          <FontData>
            <TooltipProvider>
              <ThemeProvider theme={themef}>
                <Router>
                  <Routes>
                    {authenticated ? (
                      <>
                        {/* Any person */}
                        {routesToAnyPerson()}

                        {/* Admin Company*/}
                        <Route
                          path="/admin"
                          element={
                            role === "Administrador de compañía" ? (
                              <AdminCompany />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/profileAdmin"
                          element={
                            role === "Administrador de compañía" ? (
                              <Profile />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/digital-twin-admin"
                          element={
                            role === "Administrador de compañía" ? (
                              <DigitalTwinAdmin />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        {/* <Route exact path="/new-window-table" element={<NewWindowTable />} /> */}

                        <Route
                          path="/operators"
                          element={
                            role === "Administrador de compañía" ? (
                              <Operators />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/dashboard-admin"
                          element={
                            role === "Administrador de compañía" ? (
                              <DashboardsAdmin />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/chatbot"
                          element={
                            role === "Administrador de compañía" ? (
                              <Chatbot />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/sensor-optimization"
                          element={
                            role === "Administrador de compañía" ? (
                              <SensorOptimization />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/flare-efficiency"
                          element={
                            role === "Administrador de compañía" ? (
                              <FlareEfficiency />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        {/* Admin */}
                        <Route
                          path="/admin-decimetrix"
                          element={
                            role === "Administrador de decimetrix" ? (
                              <AdminDecimetrixHub />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/profile-admin-decimetrix"
                          element={
                            role === "Administrador de decimetrix" ? (
                              <Profile />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/digital-twin-admin-decimetrix"
                          element={
                            role === "Administrador de decimetrix" ? (
                              <DigitalTwinAdmin />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/admin-companies"
                          element={
                            role === "Administrador de decimetrix" ? (
                              <AdminCompanies />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/dashboard-admin-decimetrix"
                          element={
                            role === "Administrador de decimetrix" ? (
                              <DashboardsAdmin />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/dashboard-admin"
                          element={
                            role === "Administrador de decimetrix" ? (
                              <DashboardsAdmin />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        {/* Admin decimetrix and Administrador de compañía */}
                        <Route
                          path="/config-admin"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              // <ConfigAdmin />
                              <ConfigCards />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/setUp-admin"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              <PlatformSetUp />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/config-globalConstants"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              <GlobalConstants />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/config-preferences"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              <Preferences />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/config-globalValues-Fields"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              <GlobalValuesFields />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/config-actions-events"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              <ActionsEvents />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/component-configurations"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              <ConfigComponents />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/object-configurations"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              <ConfigObjects />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />
                        <Route
                          path="/library-configurations"
                          element={
                            role === "Administrador de decimetrix" ||
                            role === "Administrador de compañía" ? (
                              <ConfigLibrarues />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        {/* Operator */}
                        <Route
                          path="/digitalTwin"
                          element={
                            role === "Operario" ? (
                              <DigitalTwinAdmin />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        <Route
                          path="/hub"
                          element={
                            role === "Operario" ? (
                              <PrivateRoute />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        >
                          <Route
                            path="/hub"
                            element={
                              role === "Operario" ? (
                                <OperatorHub />
                              ) : (
                                <Navigate to="/signIn" />
                              )
                            }
                          />
                        </Route>
                        <Route
                          path="/operators-user"
                          element={
                            role === "Operario" ? (
                              <Users />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        <Route
                          path="/profile"
                          element={
                            role === "Operario" ? (
                              <PrivateRoute />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        >
                          <Route
                            path="/profile"
                            element={
                              role === "Operario" ? (
                                <Profile />
                              ) : (
                                <Navigate to="/signIn" />
                              )
                            }
                          />
                        </Route>
                        <Route
                          path="/collections"
                          element={
                            role === "Operario" ? (
                              <PrivateRoute />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        >
                          <Route
                            path="/collections"
                            element={
                              role === "Operario" ? (
                                <Collections />
                              ) : (
                                <Navigate to="/signIn" />
                              )
                            }
                          />
                        </Route>
                        <Route
                          path="/dashboard-user"
                          element={
                            role === "Operario" &&
                            operatorRole === "Equipo de oficina" ? (
                              <DashboardsAdmin />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        <Route
                          path="/profile"
                          element={
                            role === "Operario" ? (
                              <PrivateRoute />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        >
                          <Route
                            path="/profile"
                            element={
                              role === "Operario" ? (
                                <Profile />
                              ) : (
                                <Navigate to="/signIn" />
                              )
                            }
                          />
                        </Route>
                        <Route
                          path="/collections"
                          element={
                            role === "Operario" ? (
                              <PrivateRoute />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        >
                          <Route
                            path="/collections"
                            element={
                              role === "Operario" ? (
                                <Collections />
                              ) : (
                                <Navigate to="/signIn" />
                              )
                            }
                          />
                        </Route>
                        <Route
                          path="/dashboard-user"
                          element={
                            role === "Operario" ? (
                              <DashboardsAdmin />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        <Route
                          path="/field-video-manager"
                          element={
                            role === "Operario" ? (
                              <VideoManager />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        <Route
                          path="/upload-videos/:userId"
                          element={
                            role === "Operario" ? (
                              <UploadVideos />
                            ) : (
                              <Navigate to="/signIn" />
                            )
                          }
                        />

                        {/* None user */}
                        <Route
                          path="/signIn"
                          element={
                            role === "None" ? (
                              <SignIn />
                            ) : role === "Administrador de compañía" ? (
                              <Navigate to="/admin" />
                            ) : role === "Administrador de decimetrix" ? (
                              <Navigate to="/admin-decimetrix" />
                            ) : (
                              <Navigate to="/hub" />
                            )
                          }
                        />
                      </>
                    ) : (
                      <>
                        {routesToAnyPerson()}
                        <Route path="/signIn" element={<SignIn />} />
                      </>
                    )}
                    <Route path="*" element={<Navigate to="/signIn" />} />
                  </Routes>
                </Router>
              </ThemeProvider>
            </TooltipProvider>
          </FontData>
        </ThemeData>
      </div>
    </ThemeContext.Provider>
  );
};

export default App;
