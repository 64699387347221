// Required modules
import NavBar from "components/Admin/NavBar/NavBar";

// Styles
import { ConfigAdminContainer } from "../configAdminStyles";
import ObjectLibraries from "./ObjectLibraries";

export default function LibraryPoint({
  setContentDialogConfig,
  setOpenDialogConfig,
  instanceId,
  adminDecimetrixInstances,
}) {
  return (
    <div>
      {/* <NavBar linkLogo={"admin"} /> */}
      <ConfigAdminContainer className="background-2 border-top-1">
        <ObjectLibraries
          setContentDialogConfig={setContentDialogConfig}
          setOpenDialogConfig={setOpenDialogConfig}
          instanceId={instanceId}
          adminDecimetrixInstances={adminDecimetrixInstances}
        />
      </ConfigAdminContainer>
    </div>
  );
}
