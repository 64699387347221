import { useEffect, useRef, useState } from "react";
import {
  ContainerConfigGlobalValue,
  ContainerSelect,
  ContainerValue,
  GlobalFieldValue,
} from "./CreateGlobalSelectStyles";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";
import { urlsApiMg } from "utils/urlKeys";

export const CreateGlobalSelect = ({
  dataType,
  setField,
  setFields,
  field,
  setDialog,
  update,
}) => {
  const [dataGlobalTables, setDataGlobalTables] = useState([]);
  const [currentGlobalTables, setCurrentGlobalTables] = useState({});
  const [selectedGlobalTable, setSelectedGlobalTable] = useState({});
  const [dataCategories, setDataCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);

  const { data: globalTables, error: errorGlobalTables } = useSWR(
    urlsApiMg.globalValuesFields
  );

  useEffect(() => {
    if (globalTables && !errorGlobalTables && globalTables.length > 0) {
      setDataCategories(globalTables[0]?.tableCategories);
      if (globalTables[0]?.tableCategories.length > 0) {
        setDataGlobalTables(globalTables[0]?.tableCategories[0]?.groupTables);
        setCurrentCategory(globalTables[0]?.tableCategories[0]._id);
        setSelectedGlobalTable(
          globalTables[0]?.tableCategories[0]?.groupTables[0]
        );
        setField((current) => {
          if (Object.keys(current.globalTable || {}).length === 0) {
            return {
              ...current,
              options: [],
              globalTable: {
                categoryId: globalTables[0]?.tableCategories[0]?._id,
                tableId:
                  globalTables[0]?.tableCategories[0]?.groupTables[0]._id,
              },
            };
          }
          return current;
        });
      }

      if (
        globalTables[0]?.tableCategories[0]?.groupTables[0].rows.length !== 0
      ) {
        setDialog((current) => ({
          ...current,
          actions: {
            ...current.actions,
            agree: update ? "Update" : "Add",
          },
        }));
      } else {
        setDialog((current) => ({
          ...current,
          actions: {
            ...current.actions,
            agree: null,
          },
        }));
      }
    }
  }, [globalTables, errorGlobalTables, dataType]);

  const hasRunOnce = useRef(false);
  useEffect(() => {
    if (
      field.globalTable &&
      Object.keys(field.globalTable).length > 0 &&
      dataGlobalTables?.length > 0 &&
      !hasRunOnce.current &&
      dataCategories.length > 0
    ) {
      const dataCurrentCategory = dataCategories?.find(
        (elm) => elm._id === field.globalTable.categoryId
      );
      const dataTable = dataCurrentCategory?.groupTables?.find((item) => {
        return item._id === field.globalTable.tableId;
      });
      if (dataTable) {
        setDataGlobalTables(dataCurrentCategory?.groupTables);
        setCurrentCategory(dataCurrentCategory._id);
        setCurrentGlobalTables(dataTable._id);
        setSelectedGlobalTable(dataTable);
      }
      hasRunOnce.current = true;
    }
  }, [dataGlobalTables, field]);

  const handelSelectGlobalTable = (e) => {
    e.preventDefault();
    setCurrentGlobalTables(e.target.value);
    const currentSelectedTable = dataGlobalTables.find(
      (elm) => elm._id === e.target.value
    );
    setSelectedGlobalTable(currentSelectedTable);
    setField((current) => {
      return {
        ...current,
        options: [],
        globalTable: {
          categoryId: currentCategory,
          tableId: currentSelectedTable._id,
        },
      };
    });
    setFields((current) => {
      return current.map((elm) => {
        if (
          elm?.globalSelect &&
          elm?.hasOwnProperty("columnKeyGlobalSpecs") &&
          Object.keys(elm.columnKeyGlobalSpecs).length > 0 &&
          elm?.columnKeyGlobalSpecs?.selectGlobalId === field._id
        ) {
          const columnsTypeNumber = currentSelectedTable.dataColumns.filter(
            (item, ind) => ind !== 0 && item.dataType === "number"
          );
          const columnsTypeString = currentSelectedTable.dataColumns.filter(
            (item, ind) => ind !== 0 && item.dataType === "string"
          );
          return {
            ...elm,
            globalTable: {
              categoryId: currentCategory,
              tableId: currentSelectedTable._id,
            },
            columnKeyGlobalSpecs: {
              ...elm.columnKeyGlobalSpecs,
              categoryId: currentCategory,
              tableId: currentSelectedTable._id,
              column: {
                id:
                  elm.type === "string"
                    ? columnsTypeString[0]?._id || ""
                    : columnsTypeNumber[0]?._id || "",
                name:
                  elm.type === "string"
                    ? columnsTypeString[0]?.name || ""
                    : columnsTypeNumber[0]?.name || "",
              },
            },
          };
        }
        return elm;
      });
    });
    if (currentSelectedTable.rows.length !== 0) {
      setDialog((current) => ({
        ...current,
        actions: {
          ...current.actions,
          agree: update ? "Update" : "Add",
        },
      }));
    } else {
      setDialog((current) => ({
        ...current,
        actions: {
          ...current.actions,
          agree: null,
        },
      }));
    }
  };

  const handleSelectCategory = (e) => {
    const categorySelected = dataCategories.find(
      (elm) => elm._id === e.target.value
    );
    setCurrentCategory(e.target.value);
    setDataGlobalTables(categorySelected?.groupTables);
    setSelectedGlobalTable(categorySelected?.groupTables[0]);
    setField((current) => {
      return {
        ...current,
        options: [],
        globalTable: {
          categoryId: categorySelected._id,
          tableId: categorySelected?.groupTables[0]._id,
        },
      };
    });
    setFields((current) => {
      return current.map((elm) => {
        if (
          elm?.globalSelect &&
          elm?.hasOwnProperty("columnKeyGlobalSpecs") &&
          Object.keys(elm.columnKeyGlobalSpecs).length > 0 &&
          elm?.columnKeyGlobalSpecs?.selectGlobalId === field._id
        ) {
          const columnsTypeNumber =
            categorySelected?.groupTables[0].dataColumns.filter(
              (item, ind) => ind !== 0 && item.dataType === "number"
            );
          const columnsTypeString =
            categorySelected?.groupTables[0].dataColumns.filter(
              (item, ind) => ind !== 0 && item.dataType === "string"
            );
          return {
            ...elm,
            globalTable: {
              categoryId: categorySelected._id,
              tableId: categorySelected?.groupTables[0]._id,
            },
            columnKeyGlobalSpecs: {
              ...elm.columnKeyGlobalSpecs,
              categoryId: categorySelected._id,
              tableId: categorySelected?.groupTables[0]._id,
              column: {
                id:
                  elm.type === "string"
                    ? columnsTypeString[0]?._id || ""
                    : columnsTypeNumber[0]?._id || "",
                name:
                  elm.type === "string"
                    ? columnsTypeString[0]?.name || ""
                    : columnsTypeNumber[0]?.name || "",
              },
            },
          };
        }
        return elm;
      });
    });
  };

  return (
    <GlobalFieldValue>
      <ContainerConfigGlobalValue>
        <ContainerSelect>
          <h2>Config Unified Standards Catalog </h2>
          <label className="label">Select Category</label>
          <select
            className="select"
            value={currentCategory}
            onChange={(e) => handleSelectCategory(e)}
          >
            {dataCategories && dataCategories.length > 0 ? (
              dataCategories?.map((option, index) => {
                return (
                  <option key={index} value={option._id}>
                    {option.name}
                  </option>
                );
              })
            ) : (
              <ContainerValue>
                <label className="label">
                  First create Table in the Unified Standards Catalog module
                  <Link
                    to="/config-globalValues-Fields"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Go to link.
                  </Link>
                </label>
              </ContainerValue>
            )}
          </select>
          <label className="label">Standards Catalog Table</label>
          <select
            id={currentGlobalTables?._id}
            className="select"
            value={currentGlobalTables}
            onChange={(e) => handelSelectGlobalTable(e)}
          >
            {dataGlobalTables ? (
              dataGlobalTables?.map((option, index) => {
                return (
                  <option key={index} value={option._id}>
                    {option.name}
                  </option>
                );
              })
            ) : (
              <ContainerValue>
                <label className="label">
                  First create Table in the Unified Standards Catalog module
                  <Link
                    to="/config-globalValues-Fields"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Go to link.
                  </Link>
                </label>
              </ContainerValue>
            )}
          </select>
        </ContainerSelect>
        <ContainerSelect>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label
              className="label"
              style={{
                width: "100%",
                justifyContent: "center",
              }}
            >
              Selected Options
            </label>
          </div>
          {selectedGlobalTable && selectedGlobalTable?.rows?.length !== 0 ? (
            selectedGlobalTable.rows?.map((elm, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "5px",
                    background: PRIMARY_COLOR,
                    padding: "5px",
                    color: WHITE_COLOR,
                    borderRadius: "5px",
                    margin: 0,
                  }}
                >
                  {elm.Options}
                </div>
              );
            })
          ) : (
            <ContainerValue>
              <label className="label">
                First Complete Columns and Rows in Unified Standards Catalog
                module
                <Link
                  to="/config-globalValues-Fields"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Go to link.
                </Link>
              </label>
            </ContainerValue>
          )}
        </ContainerSelect>
      </ContainerConfigGlobalValue>
    </GlobalFieldValue>
  );
};
