import { useEffect, useState } from "react";
import { SelectContainerAdminConfig } from "../configAdminStyles";
import { ConfigObjectsContainer } from "pages/Dashboard/Config/ConfigObjects/indexStyle";
import useSwr from "swr";
import SelectObjectTypes from "pages/Dashboard/Config/ConfigObjects/SelectObjectTypes";
import CustomButton from "components/Buttons/CustomButton";
import SelectLibrary from "pages/Dashboard/components/SelectLibrary";
import SelectComponentTypes from "pages/Dashboard/Config/ConfigComponents/SelectComponentTypes";
import SelectEventTypes from "pages/Dashboard/Config/ConfigEvents/SelectEventTypes";
import { SelectLineObjectTypes } from "pages/Dashboard/Config/ConfigObjects/SelectLineObjectTypes";
import { urls } from "utils/urlKeys";
import { config } from "config.js";

export const DuplicateLibrary = ({
  instanceId,
  adminDecimetrixInstances,
  pointObject = false,
  lineObject = false,
  pointComponent,
  pointEvent,
  configAdmin,
  handleChangeForm,
  setDialog,
  setTypeElementsSelect,
}) => {
  const [currentInstance, setCurrentInstance] = useState(0);
  const [dataLibrary, setDataLibrary] = useState([]);
  const [allDataInstance, setAllDataInstance] = useState([]);

  const [librarySelected, setLibrarySelected] = useState(0);
  const [typeElements, setTypeElements] = useState([]);
  const [typeIdsSelected, setTypeIdsSelected] = useState([]);
  const [librarySelectedEvent, setLibrarySelectedEvent] = useState(null);

  const backendPg = config.URL_BACKEND_PG;

  const urlObjectLibraries =
    pointObject && currentInstance !== "defaultLibrary"
      ? urls.objects.librariesByInstanceId(currentInstance)
      : null;

  const urlTypeObjects =
    pointObject && librarySelected !== 0 && currentInstance !== "defaultLibrary"
      ? urls.objects.typeObjectsByInstanceId(
          librarySelected.id,
          currentInstance
        )
      : null;

  const urlComponentLibraries =
    pointComponent && currentInstance !== "defaultLibrary"
      ? urls.components.librariesByInstanceId(currentInstance)
      : null;

  const urlTypeComponents =
    pointComponent &&
    librarySelected !== 0 &&
    currentInstance !== "defaultLibrary"
      ? urls.components.typeComponentsByInstanceId(
          librarySelected.id,
          currentInstance
        )
      : null;

  const urlEventLibraries =
    pointEvent && currentInstance !== "defaultLibrary"
      ? urls.events.librariesByInstanceId(currentInstance)
      : null;

  const urlTypeEvents =
    pointEvent && librarySelected !== 0 && currentInstance !== "defaultLibrary"
      ? urls.events.typeEventsByInstanceId(librarySelected.id, currentInstance)
      : null;

  const urlLineObjectsLibraries =
    lineObject && currentInstance !== "defaultLibrary"
      ? urls.lines.librariesByInstanceId(currentInstance)
      : null;

  const urlTypeLineObjects =
    lineObject && librarySelected !== 0 && currentInstance !== "defaultLibrary"
      ? urls.lines.typeLinesByInstanceId(librarySelected.id, currentInstance)
      : null;

  // Points
  const {
    data: currentInstanceObjectLibraries,
    error: currentInstanceObjectLibrariesError,
  } = useSwr(`${backendPg}${urlObjectLibraries}`);

  const {
    data: currentInstanceTypeElementsObject,
    error: currentInstanceTypeElementsErrorObject,
  } = useSwr(urlTypeObjects);

  const {
    data: currentInstanceComponentLibraries,
    error: currentInstanceComponentLibrariesError,
  } = useSwr(`${backendPg}${urlComponentLibraries}`);

  const {
    data: currentInstanceTypeElementsComponent,
    error: currentInstanceObjectTypeElementsErrorComponent,
  } = useSwr(urlTypeComponents);

  const {
    data: currentInstanceEventLibraries,
    error: currentInstanceEventLibrariesError,
  } = useSwr(`${backendPg}${urlEventLibraries}`);

  const {
    data: currentInstanceTypeElementsEvent,
    error: currentInstanceObjectTypeElementsErrorEvent,
  } = useSwr(urlTypeEvents);

  // Lines
  const {
    data: currentInstanceLineObjectLibraries,
    error: currentInstanceLineObjectLibrariesError,
  } = useSwr(`${backendPg}${urlLineObjectsLibraries}`);

  const {
    data: currentInstanceTypeLineObject,
    error: currentInstanceTypeLineErrorObject,
  } = useSwr(urlTypeLineObjects);

  useEffect(() => {
    const currentInfoInstance = adminDecimetrixInstances.filter((elm) => {
      if (
        parseInt(elm.id) !== parseInt(instanceId) &&
        elm.id !== "defaultLibrary"
      ) {
        return elm;
      }
    });
    setCurrentInstance(currentInfoInstance[0]?.id);
    setAllDataInstance(currentInfoInstance);
  }, [adminDecimetrixInstances, instanceId]);

  useEffect(() => {
    if (pointObject) {
      !currentInstanceObjectLibrariesError &&
        currentInstanceObjectLibraries?.length > 0 &&
        currentInstanceObjectLibraries.map((elm) => {
          setDataLibrary((current) => [
            ...current,
            {
              id: elm.id,
              name: elm.name,
              icon: elm.icon,
              description: elm.description,
              fields: elm.fields,
            },
          ]);
        });
    }
    if (pointComponent) {
      !currentInstanceComponentLibrariesError &&
        currentInstanceComponentLibraries?.length > 0 &&
        currentInstanceComponentLibraries.map((elm) => {
          setDataLibrary((current) => [
            ...current,
            {
              id: elm.id,
              name: elm.name,
              icon: elm.icon,
              description: elm.description,
              fields: elm.fields,
            },
          ]);
        });
    }
    if (pointEvent) {
      !currentInstanceEventLibrariesError &&
        currentInstanceEventLibraries?.length > 0 &&
        currentInstanceEventLibraries.map((elm) => {
          setDataLibrary((current) => [
            ...current,
            {
              id: elm.id,
              name: elm.name,
              icon: elm.icon,
              description: elm.description,
              fields: elm.fields,
            },
          ]);
        });
    }
    if (lineObject) {
      !currentInstanceLineObjectLibrariesError &&
        currentInstanceLineObjectLibraries?.length > 0 &&
        currentInstanceLineObjectLibraries.map((elm) => {
          setDataLibrary((current) => [
            ...current,
            {
              id: elm.id,
              name: elm.name,
              icon: elm.icon,
              description: elm.description,
              fields: elm.fields,
            },
          ]);
        });
    }
  }, [
    currentInstanceObjectLibrariesError,
    currentInstanceComponentLibrariesError,
    currentInstanceEventLibrariesError,
    currentInstanceLineObjectLibrariesError,
    currentInstanceObjectLibraries,
    currentInstanceComponentLibraries,
    currentInstanceEventLibraries,
    currentInstanceLineObjectLibraries,
    currentInstance,
    pointObject,
    pointComponent,
    pointEvent,
  ]);

  useEffect(() => {
    if (pointObject) {
      !currentInstanceTypeElementsErrorObject &&
        currentInstanceTypeElementsObject?.length > 0 &&
        setTypeElements(currentInstanceTypeElementsObject);
    }
    if (pointComponent) {
      !currentInstanceObjectTypeElementsErrorComponent &&
        currentInstanceTypeElementsComponent?.length > 0 &&
        setTypeElements(currentInstanceTypeElementsComponent);
    }
    if (pointEvent) {
      !currentInstanceObjectTypeElementsErrorEvent &&
        currentInstanceTypeElementsEvent?.length > 0 &&
        setTypeElements(currentInstanceTypeElementsEvent);
    }
    if (lineObject) {
      !currentInstanceTypeLineErrorObject &&
        currentInstanceTypeLineObject?.length > 0 &&
        setTypeElements(currentInstanceTypeLineObject);
    }
  }, [
    currentInstanceTypeElementsErrorObject,
    currentInstanceObjectTypeElementsErrorComponent,
    currentInstanceObjectTypeElementsErrorEvent,
    currentInstanceTypeLineErrorObject,
    currentInstanceTypeElementsObject,
    currentInstanceTypeElementsComponent,
    currentInstanceTypeElementsEvent,
    currentInstanceTypeLineObject,
    currentInstance,
    librarySelected,
    pointObject,
    pointComponent,
    pointEvent,
  ]);

  const handleInstance = (e) => {
    setDataLibrary([]);
    setTypeElements([]);
    setTypeIdsSelected([]);
    setTypeElementsSelect([]);
    setCurrentInstance(e.target.value);
  };

  const handlerLibrarySelected = (e) => {
    setLibrarySelectedEvent(e);
    setTypeIdsSelected([]);
    setTypeElementsSelect([]);
    const library = dataLibrary.find((elm) => elm.id === e.value);
    setLibrarySelected(library);
  };

  const handlerClickElementType = async (id, libraryId, typeElement) => {
    setTypeIdsSelected((current) => {
      if (current.includes(id)) {
        return current.filter((item) => item !== id);
      }
      return [...current, id];
    });

    setTypeElementsSelect((current) => {
      if (current.some((item) => item.name === typeElement.name)) {
        return current.filter((item) => item.name !== typeElement.name);
      }
      return [
        ...current,
        {
          name: typeElement.name,
          urlIconColor: typeElement.urlIconColor || typeElement.icon,
        },
      ];
    });
  };

  const handlerClickLineType = async (id, libraryId, typeLine) => {
    setTypeIdsSelected((current) => {
      if (current.includes(id)) {
        return current.filter((item) => item !== id);
      }
      return [...current, id];
    });

    setTypeElementsSelect((current) => {
      if (current.some((item) => item.type === typeLine.type)) {
        return current.filter((item) => item.type !== typeLine.type);
      }
      return [
        ...current,
        {
          type: typeLine.type,
          description: typeLine.description,
          color: typeLine.color,
          dasharrayWidth: typeLine.dasharrayWidth,
          dasharrayPixels: typeLine.dasharrayPixels,
          fields: typeLine.fields,
        },
      ];
    });
  };

  const handlerDuplicateLibrary = () => {
    configAdmin.toggleForm();
    handleChangeForm({
      target: { name: "name", value: librarySelected.name, validate: "true" },
    });
    handleChangeForm({
      target: { name: "icon", value: librarySelected.icon, validate: "true" },
    });
    handleChangeForm({
      target: {
        name: "fields",
        value: librarySelected.fields,
        validate: "true",
      },
    });
    handleChangeForm({
      target: {
        name: "description",
        value: librarySelected.description,
        validate: "true",
      },
    });
    setDialog((current) => ({
      ...current,
      open: false,
    }));
  };

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        minHeight: "300px",
      }}
    >
      <SelectContainerAdminConfig
        style={{
          padding: "0px",
          marginRight: "0px",
        }}
      >
        <p
          style={{
            fontSize: "20px",
            fontWeight: 500,
            marginRight: "25px",
          }}
        >
          Select from which instance:{" "}
        </p>
        <div>
          <select
            style={{
              zIndex: 999,
            }}
            name="endPoint"
            id="endPoint"
            onChange={handleInstance}
          >
            {allDataInstance?.map((instance) => {
              return (
                <option
                  style={{
                    zIndex: 999,
                    position: "absolute",
                  }}
                  key={instance.id}
                  value={instance.id}
                >
                  {instance.name}
                </option>
              );
            })}
          </select>
        </div>
      </SelectContainerAdminConfig>
      <ConfigObjectsContainer
        style={{
          height: "auto",
          marginBottom: "20px",
        }}
        fontSize={"20px"}
      >
        <SelectLibrary
          libraries={dataLibrary}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={librarySelectedEvent}
        />
        {typeElements.length > 0 && pointObject && (
          <SelectObjectTypes
            objectTypes={typeElements}
            librarySelected={librarySelected.id}
            handlerClickObjectType={handlerClickElementType}
            typeIdsSelected={typeIdsSelected}
          />
        )}

        {typeElements.length > 0 && lineObject && (
          <SelectLineObjectTypes
            lineObjectTypes={typeElements}
            linelibrarySelected={librarySelected.id}
            handlerClickLineObjectType={handlerClickLineType}
            typeIdsSelected={typeIdsSelected}
          />
        )}

        {typeElements.length > 0 && pointComponent && (
          <SelectComponentTypes
            componentTypes={typeElements}
            librarySelected={librarySelected.id}
            handlerClickComponentType={handlerClickElementType}
            typeIdsSelected={typeIdsSelected}
          />
        )}

        {typeElements.length > 0 && pointEvent && (
          <SelectEventTypes
            eventTypes={typeElements}
            librarySelected={librarySelected.id}
            handlerClickEventType={handlerClickElementType}
            typeIdsSelected={typeIdsSelected}
          />
        )}
      </ConfigObjectsContainer>
      <div
        style={{
          border: "solid 1px",
        }}
      >
        <CustomButton
          onClick={() => handlerDuplicateLibrary()}
          text={"Duplicate Library"}
          margin={0}
        />
      </div>
    </div>
  );
};
