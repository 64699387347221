// Styles
import {
  ContainerContentTable,
  ContainerTable,
  ContentTable,
  Divider,
} from "../GenericTableStyle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Dependencies
import { flexRender } from "@tanstack/react-table";
// Helpers
import GenericFilterTable from "../helpers/components/Filter/GenericFilterTable";
import { FooterTools } from "../helpers/components/FooterTools";
import { InputSearchTable } from "../helpers/components/InputSearchTable";
import deleteAccessUserToInstances from "services/Instances/deleteAccessUserToInstances";
import createAccessUserToInstances from "services/Instances/createAccessUserToInstances";
import { useUserStore } from "zustandGloabalStore/user";
import { useSWRConfig } from "swr";
import { urls } from "utils/urlKeys";

export const AllowAccessUsersTable = ({
  style,
  table,
  useFilter,
  filterValue,
  setFilterCheckBox,
  filterCheckBox,
  columnResizeMode,
  state,
  handleGlobalFilterChange,
  buttonFilters,
  handlerSetValue,
  setUseFilter,
}) => {
  const { instanceManager } = useUserStore((state) => state);
  const { mutate } = useSWRConfig();
  const handleAllowAccess = async ({ status, userId }) => {
    if (status) {
      await deleteAccessUserToInstances({
        instanceId: instanceManager.id,
        userId,
      });
    } else {
      await createAccessUserToInstances({
        instanceId: instanceManager.id,
        userId,
      });
    }
    await mutate(urls.adminCompany.all);
    await mutate(urls.operators.all);
    await mutate(urls.instance.accessInstanceUser(userId, instanceManager.id));
    await mutate(urls.users.getAdminCompanyByInstance(instanceManager.id));
    await mutate(urls.users.getOperatorsInstanceDynamic(instanceManager.id));
  };

  return (
    <div
      className="background-1"
      style={{
        marginTop: "0px",
      }}
    >
      <ContainerTable className="background-2 border-color-1">
        <InputSearchTable
          state={state}
          handleGlobalFilterChange={handleGlobalFilterChange}
          table={table}
          useFilter={useFilter}
          setUseFilter={setUseFilter}
          buttonFilters={buttonFilters}
          handlerSetValue={handlerSetValue}
        />
      </ContainerTable>
      <Divider />
      <ContainerContentTable
        className="background-2 border-color-1"
        style={{ flex: 1, borderRadius: "5px" }}
      >
        <ContentTable
          className="background-row-2 color1"
          maxHeight={style?.maxHeight}
          style={{
            borderRadius: "5px 5px 0 0",
            overflow: "scroll",
            height: "480px",
          }}
        >
          <table
            {...{
              style: {
                width: "100%",
              },
            }}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  style={{
                    width: "100%",
                    fontFamily: document.body.style.fontFamily,
                  }}
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className="background-table-color"
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                        style: {
                          width: "100vw",
                        },
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ExpandLessIcon />,
                              desc: <ExpandMoreIcon />,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                          {header.column.getCanFilter() && useFilter ? (
                            <div style={{ marginTop: "5px" }}>
                              <GenericFilterTable
                                column={header.column}
                                table={table}
                                setValue={filterValue}
                                setFilterCheckBox={setFilterCheckBox}
                                filterCheckBox={filterCheckBox}
                              />
                            </div>
                          ) : null}
                        </>
                      )}

                      <div
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`,
                          style: {
                            transform:
                              columnResizeMode === "onEnd" &&
                              header.column.getIsResizing()
                                ? `translateX(${
                                    table.getState().columnSizingInfo
                                      .deltaOffset
                                  }px)`
                                : "",
                          },
                        }}
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  style={{ fontFamily: document.body.style.fontFamily }}
                  key={row.id}
                  className={
                    index % 2 !== 0
                      ? "background-row-1 color1"
                      : "background-row-2 color1"
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    if (
                      cell.getContext().column.columnDef.header().props
                        .children === "Access Status"
                    )
                      return (
                        <td
                          {...{
                            key: cell.id,
                            style: {
                              minWidth: `${cell.column.getSize()}px`,
                            },
                          }}
                        >
                          <input
                            style={{
                              width: "45px",
                              padding: "0 5px",
                            }}
                            type="checkbox"
                            checked={JSON.parse(cell.getValue())}
                            min={1}
                            onChange={() =>
                              handleAllowAccess({
                                status: JSON.parse(cell.getValue()),
                                userId: cell.row.original.id,
                              })
                            }
                          />
                        </td>
                      );
                    return (
                      <td
                        {...{
                          key: cell.id,
                          style: {
                            minWidth: `${cell.column.getSize()}px`,
                          },
                        }}
                      >
                        {flexRender(cell.getValue(), cell.getContext()) ||
                          cell.getValue()}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="h-2" />
        </ContentTable>
        <FooterTools table={table} />
      </ContainerContentTable>
    </div>
  );
};
