import CustomTabs from "./CustomTabs";
import React from "react";
import InstancesContainer from "./InstancesContainer";

export const TabsInstances = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <CustomTabs value={value} onChange={handleChange} />
      {value === 0 && <InstancesContainer />}
    </div>
  );
};
