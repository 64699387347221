import axios from "axios";
import { urlsApiMg } from "utils/urlKeys";

/**
 * Updates an object in the database.
 *
 * @param {Object} body - The updated object data.
 * @param {string} id - The ID of the object to update.
 * @return {Promise<Object>} An object containing the updated data and the response.
 */
const createViewTableColumns = async ({
  body,
  nameView,
  typeOfLibrary,
  typeOfTable,
}) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      urlsApiMg.orderColumns(
        `name=${nameView}&typeOfLibrary=${typeOfLibrary}&type=${typeOfTable}`
      ),
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const response = await res.data;
    return { response, res };
  } catch (error) {
    console.error(error);
  }
};

export default createViewTableColumns;
