// Green Dragon 🐉🟢
export const PRIMARY_COLOR = "#0E4D45";
export const PRIMARY_COLOR_TRANS = "#0E4D45b5";
export const PRIMARY_COLOR_TRANS_LOGO = "#0e4d4553";
export const SECOND_COLOR = "#31A636";
export const SECOND_COLOR_TRANS = "#31a6377d";
export const THIRD_COLOR = "#279129";
export const ICON_GREEN_COLOR = "#65ffab";
export const COLOR_TEXT_GD = "#0E4D45";

//White colors
export const WHITE_COLOR = "#ffffff";
export const WHITE_COLOR_THIRD = "#c5c5c5";

// Black colors
export const BLACK_COLOR = "#000000";
export const BLACK_GRAY_COLOR = "#404040";
export const GRAY_COLOR = "#bfbfbf";

//Ligth Theme
export const BACKGROUND_GREY = "#fafafa";
export const BACKGROUND_SECOND_GREY = "#eeeeee";
export const GREY_TRANS = "#40404053";
export const BACKGROND_DASHBOARD = "#f6f7fb";
export const BACKGROUD_GREY_PREVIEW = "#dddddd";

//Dark Theme
export const BACKGROUND_DARK = "#121212";
export const BACKGROUND_SECOND_DARK = "#222222";
export const BACKGROUND_THIRD_DARK = "#3a3a3a";
export const BACKGROUND_FOURTH_DARK = "#1f1f1f";
export const BACKGROUND_CARD_DARK = "#313131";
export const BORDER_DARK = "#636363";
export const LOGO_DARK_TRANS = "#f9f9f945";

//Border
export const BORDER_GREY = "#bebebe";

// Cluster color
export const EVENT_CLUSTER_COLOR = "#810955";
export const OBJECT_CLUSTER_COLOR = "#ff5f7e";
export const SENSOR_CLUSTER_COLOR = "#e00476";
export const CSV_CLUSTER_COLOR = "#d62ad0";
export const COMPONENT_CLUSTER_COLOR = "#251351";

//Red colors
export const RED_COLOR = "#ff0000";
export const DELETE_COLOR = "#D11010";
export const HOVER_DELETE_COLOR = "#EE1B1B";

// RED DRAGON
export const PRIMARY_COLOR_RD = "#19213c";
export const SECOND_COLOR_RD = "#d61233";
export const TRANS_COLOR_RD = "#d6123361";
export const COLOR_TEXT_RD = "#19213c";

export const VISIBILITY_OFF_ICON = "#969292";
export const SET_VISIBILITY_ICON = "#ff7c36";

export const WHITE_SECOND_COLOR = "#f9f9f9";
export const WHITE_BACKGROUND = "#fff";
export const GRAY_SECOND = "#ececec";

export const SPACE_COLOR = "#131313";

export const INFORMATION_COLOR = "#3f51b5";
export const SELECTION_COLOR = "#1084f0";
export const WARNING_COLOR = "#FFC107";
export const SECOND_WARNING_COLOR = "#DD6031";
export const SECOND_HOVER_WARNING_COLOR = "#bb481e";
