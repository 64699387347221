import { useState, useEffect } from "react";
// Global Store
import { useStoreHostname } from "zustandGloabalStore/hostname";
import {
  SECOND_COLOR_RD,
  PRIMARY_COLOR_RD,
  TRANS_COLOR_RD,
  COLOR_TEXT_RD,
} from "utils/const";
import { HOSTNAME } from "utils/constStrings";

export const useColorHostName = () => {
  const [color, setColor] = useState({
    primaryColor: null,
    secondaryColor: null,
    transColor: null,
    titlesColor: null,
  });
  const [isRedDragon, setIsRedDragon] = useState(false);

  const hostname = useStoreHostname((state) => state.hostname);

  useEffect(() => {
    if (hostname) {
      if (hostname === HOSTNAME.redDragon) {
        setIsRedDragon(true);
        setColor({
          primaryColor: PRIMARY_COLOR_RD,
          secondaryColor: SECOND_COLOR_RD,
          transColor: TRANS_COLOR_RD,
          titlesColor: COLOR_TEXT_RD,
        });
      }
    }
  }, [hostname]);

  return { color, isRedDragon };
};
