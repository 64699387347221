import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_SECOND_COLOR } from "utils/const";

export const FeatureDetails = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 15px 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  .title {
    font-size: 2rem;
    align-self: center;
  }

  .location-details {
    margin: 5px 0;
    font-weight: 500;
    font-size: 1.6rem;
  }

  .custom-select {
    margin: 5px 0;
    padding: 7px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--background-primary_color);
    color: #fff;
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
    font-size: 1.6rem;
  }
  textarea {
    height: 60px;
  }

  .main-currency-table {
    width: 100%;
    min-width: 235px;
    margin: 10px auto;
  }
  .currency-table--container {
    width: 100%;
    min-width: 230px;
    margin: 0 auto;
  }
  .currency-table--container table {
    width: 100%;
    height: 100%;
  }

  .currency-table--container table td {
    width: 50%;
    height: 40px;
    font-size: 1.6rem;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f9f9f9;
  }
  .currency-table--container .table__top-left {
    border-radius: 5px 0 0 0;
  }
  .currency-table--container .table__top-right {
    border-radius: 0 5px 0 0;
  }
  .currency-table--container .table__bottom-right {
    border-radius: 0 0 5px 0;
  }
  .currency-table--container .table__bottom-left {
    border-radius: 0 0 0 5px;
  }
  .currency-table--container .table__right {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
    background-color: #f9f9f9;
    color: #525252;
  }

  .input-location {
    border: 0px;
    padding: 0 5px;
    width: 100%;
    background-color: ${WHITE_SECOND_COLOR};
  }

  .button-copy {
    border: none;
    cursor: pointer;
  }
`;

export const TextLine = styled.p`
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #5c5757;
  font-size: 1.6rem;
`;

export const LineContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .container {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .line {
    width: 50px;
    height: ${(props) => props.width * 2}px;
    background: ${(props) => props.color};
  }

  .separator {
    width: ${(props) => props.separator * 2}px;
  }

  .circle {
    width: ${(props) => props.width * 8}px;
    height: ${(props) => props.width * 8}px;
    background: ${(props) => props.color};
    border-radius: 50%;
  }
`;

export const WraperTitleCardMarker = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;

  .wraper-title-element {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    margin: 10px 10vw;
    min-width: 300px;
    min-height: 150px;

    h2 {
      font-size: 2rem;
    }
  }
  span {
    color: var(--background-primary_color);
  }
  h1 {
    margin-top: 15px;
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #636363;
  }
  img {
    width: 45px;
    height: 38px;
    margin: 5px 0;
  }
  .dynamicImg {
    width: 60px;
    height: auto;
    padding: 0;
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  /* padding: 10px 20px; */
  width: 100%;
  min-width: 320px;
  color: #2c2c2c;

  b {
    display: flex;
    justify-content: center;
    font-size: medium;
  }

  h1 {
    font-size: 3rem;
  }

  .location {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .wrapper-inputs {
    display: flex;
    width: 100%;
    gap: 0 30px;
    justify-content: space-between;
    align-items: center;
  }

  .wrapper-inputs div {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
  }

  button[type="submit"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin: 20px 0;
    background-color: var(--background-primary_color);
    color: #fff;

    &:hover {
      cursor: pointer;
      background-color: var(--background-secondary_color);
    }
  }

  @media (max-width: 780px) {
    .wrapper-inputs {
      display: flex;
      flex-direction: column;
    }
    .wrapper-inputs div {
      align-self: center;
      width: 100%;
    }
  }
`;

export const LineConnectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .container-list-header {
    font-size: 20px;
    font-weight: lighter;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .container-list {
    margin-top: 10px;
  }
  .container-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${PRIMARY_COLOR};
    margin-bottom: 5px;
  }
  .location-details {
    font-size: 16px;
    width: 50%;
  }
  .container-img {
    display: flex;
    justify-content: center;
    width: 50%;
  }
  .container-img > img {
    width: 40px;
    height: auto;
  }
`;
