import Grid from "@material-ui/core/Grid";

import {
  ContentButtonConvert,
  IconConvert,
  LayerConvertCenter,
  DivConvert,
  DivConvertBorder,
  DivConvertBorderRight,
  Wrapperbtn,
} from "../ObjectDetails/ObjectDetailsElements";

import { WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";

import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import { useState } from "react";
import FetchTypeElements from "hooks/fetchTypeElements";
import { useEffect } from "react";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor2 } from "Styles";

const ContentDialogConvertObject = ({
  handleConvertObject,
  typeElementId,
  setTypeElement,
  setTypeEquipmentName,
  setTypeElementId,
  object,
}) => {
  const { data, error } = useFetchObjectLibraries({ id: null });
  const [libraryId, setLibraryId] = useState("");
  const [library, setLibrary] = useState("");
  const [icon, setIcon] = useState("");
  const { theme } = useContext(ThemeContext);

  const { data: typeElements, error: errorTypeElements } =
    FetchTypeElements(libraryId);

  useEffect(() => {
    const setData = () => {
      const { typeElement } = object;
      if (typeElement) {
        const { pointLibraryId } = typeElement;
        const lib = data?.find((lib) => lib.id === pointLibraryId);
        setLibrary(lib);
        setLibraryId(lib.id);
      }
    };
    data && !data?.error && object !== undefined && setData();
  }, [data, object]);

  useEffect(() => {
    if (typeElements) {
      const icon = typeElements?.find(
        ({ id }) => id.toString() === typeElementId
      )?.urlIconColor;
      setIcon(icon);
      if (icon === undefined) {
        setIcon(
          typeElements?.find(({ id }) => id === typeElements[0]?.id)
            ?.urlIconColor
        );
        setTypeElementId(typeElements[0]?.id);
      }
    }
  }, [typeElements, setTypeElementId, typeElementId]);

  return (
    <>
      <Grid className="wrapper-buttons">
        <Wrapperbtn>
          <div
            style={{
              margin: "0px 15px 0px 15px",
            }}
          >
            <br />
            <p>
              Object library name: <b>{library && library.name}</b>
            </p>
            <br />
            <p>New Type object:</p>
          </div>
        </Wrapperbtn>
      </Grid>
      <Grid className="wrapper-buttons">
        <Wrapperbtn>
          <ContentButtonConvert onClick={handleConvertObject}>
            <select
              defaultValue={typeElementId}
              onChange={setTypeElement}
              style={{
                backgroundColor: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: WHITE_COLOR,
                padding: "5px 15px",
                margin: "11px 11px 11px 15px",
                width: "95%",
              }}
            >
              {!errorTypeElements &&
                typeElements !== undefined &&
                typeElements
                  ?.sort((a, b) => a.name?.localeCompare(b.name))
                  ?.map((typeElementId) => (
                    <option key={typeElementId.id} value={typeElementId.id}>
                      {typeElementId.name}
                    </option>
                  ))}
            </select>
          </ContentButtonConvert>
        </Wrapperbtn>
      </Grid>
      {/* ICON & SYMBOL (PICTOGRAM) */}
      <IconConvert>
        <Grid container>
          <Grid item xs={12} md={12} lg={6} xl={6}>
            <>
              {icon && (
                <DivConvertBorder>
                  <LayerConvertCenter>Symbol</LayerConvertCenter>
                  <DivConvert>
                    <>
                      <img src={icon} width="100px" alt="ico" />
                    </>
                  </DivConvert>
                </DivConvertBorder>
              )}
            </>
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={6}>
            <>
              <DivConvertBorderRight>
                <LayerConvertCenter>Pictogram</LayerConvertCenter>
                <DivConvert>
                  <img
                    src="https://img.freepik.com/vector-premium/lapiz-icono-documento-diseno-pagina-web-signo-papel-aislado-om-fondo-blanco-simbolo-bloc-notas-pictograma-lapiz-vectorial-icono-linea-contrato-informe-cuaderno-archivo-lista_548264-470.jpg?w=2000"
                    width="100px"
                    alt="ico"
                  />
                </DivConvert>
              </DivConvertBorderRight>
            </>
          </Grid>
        </Grid>
      </IconConvert>
    </>
  );
};

export default ContentDialogConvertObject;
