import { useState, useEffect, useRef } from "react";
import { useSWRConfig } from "swr";
// Components
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import OpenDialog from "components/Dialogs/OpenDialog";
import ContentDialog from "./ContentDialog";
// Config
import { pathFolder } from "utils/paths";
import { getPathBucket } from "helpers/getPathBucket";
import saveObjectFile, {
  saveCaptureMedia,
} from "services/objects/saveObjectFile";
import updateObjectPg from "services/updateObjectPg";
import { urls } from "utils/urlKeys";
import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import { Box } from "@mui/material";
import CustomButton from "components/Buttons/CustomButton";
import { ProgressBar } from "components/ElementDetails/PhotoGalley/ProgressBar";
import { uploadFileToS3 } from "services/s3Manager/s3Manager";

const AddPicture = ({ object }) => {
  const userId = localStorage.getItem("userId");
  const [photosFile, setPhotosFile] = useState([]);
  const [fieldsCaptureMedia, setFieldsCaptureMedia] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [typeObjectFile, setTypeObjectMedia] = useState(null);
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const abortControllerRef = useRef(null);

  const { mutate } = useSWRConfig();

  const { data: dataLibrary, error: errorLibrary } = useFetchObjectLibraries({
    id: object?.typeElement?.pointLibraryId,
  });

  useEffect(() => {
    if (!errorLibrary & (dataLibrary?.length > 0)) {
      const captureMedia = dataLibrary[0]?.fields.filter(
        (it) => it.type === "captureMedia"
      );
      setFieldsCaptureMedia(captureMedia);
    }
  }, [dataLibrary, errorLibrary]);

  useEffect(() => {
    if (fieldsCaptureMedia.length > 0) {
      const typeMedia = fieldsCaptureMedia[0]?.name;
      setTypeObjectMedia(typeMedia);
    }
  }, [fieldsCaptureMedia]);

  const uploadFile = async (e) => {
    const files = e.target.files;
    let size = 0;
    Object.keys(files).forEach((key) => {
      size += files[key].size;
    });
    setTotalSize(size);

    Object.keys(files).forEach((key) => {
      setPhotosFile((current) => [...current, files[key]]);
    });
    Object.keys(files).forEach((key) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[key]);
      reader.onload = () => {
        setPhotos((current) => [reader.result, ...current]);
      };
    });
    setOpenModal(true);
    setTotalFiles(files.length);
  };

  const cancelUpload = () => {
    setIsCancelled(true);
    // Abort any in-flight requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setOpenModal(false);
    setPhotosFile([]);
    setPhotos([]);
    setUploadedSize(0);
    setUploadProgress(0);
    setIsUploading(false);
  };

  const savePhotos = async () => {
    try {
      setIsUploading(true);
      setIsCancelled(false);
      setUploadProgress(0);
      setUploadedSize(0);

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const folderPath = getPathBucket(
        pathFolder.objects.points.pictures(typeObjectFile)
      );

      for (let i = 0; i < photosFile.length; i++) {
        if (isCancelled) {
          console.log("Upload cancelled");
          break;
        }

        const file = photosFile[i];
        try {
          const { fileUrl } = await uploadFileToS3({
            file,
            folderPath,
            signal,
          });

          if (isCancelled) break;

          setUploadedSize((prev) => prev + file.size);

          if (typeObjectFile === null || typeObjectFile === undefined) {
            await saveObjectFile({
              objectId: object.id,
              typeObjectFileId: "3",
              urlFile: fileUrl,
            });
          } else {
            const aliasFieldMedia = fieldsCaptureMedia.find(
              (item) => item.name === typeObjectFile
            )?.alias;

            await saveCaptureMedia({
              pointObjectMgId: object.mongoId,
              media: [
                {
                  type: typeObjectFile,
                  alias: aliasFieldMedia,
                  url: fileUrl,
                },
              ],
            });
          }

          setUploadProgress((prev) => prev + 1);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Upload aborted");
            break;
          } else {
            console.error("Error uploading file:", error);
          }
        }
      }

      if (!isCancelled) {
        setOpenModal(false);
        setTotalFiles(0);
        setPhotosFile([]);
        setPhotos([]);
        setUploadedSize(0);
        await updateObjectPg(
          {
            lastUpdate: new Date(),
            userUpdateId: userId,
            haveMedia: true,
          },
          object.id
        );
        // Mutate
        mutate(urls.objects.detail(object.id));
        mutate(urls.objects.all);
      }
    } catch (error) {
      console.error("Error saving photos:", error);
    } finally {
      setIsUploading(false);
    }
  };
  return (
    <>
      <CustomButton
        text={"Add Pictures"}
        onClick={() => document.getElementById("files").click()}
        margin={0}
        icon={<AddAPhotoIcon sx={{ fontSize: "18px" }} />}
        padding={"7px 10px"}
      />

      <input
        id="files"
        style={{ visibility: "hidden", position: "absolute" }}
        accept="image/*"
        type="file"
        name="file"
        multiple
        onChange={uploadFile}
      />
      <OpenDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        content={{
          title: "Upload pictures",
          description: (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0,
                }}
              >
                {isUploading && (
                  <ProgressBar
                    count={uploadProgress}
                    totalFiles={totalFiles}
                    porcentaje={(uploadProgress * 100) / totalFiles}
                    uploadedSize={uploadedSize}
                    totalSize={totalSize}
                  />
                )}
                <ContentDialog
                  pictures={photos}
                  fieldsCaptureMedia={fieldsCaptureMedia}
                  typeObjectFile={typeObjectFile}
                  setTypeObjectMedia={setTypeObjectMedia}
                />
              </Box>
            </>
          ),
          agree: "Save",
          disagree: "Cancel",
        }}
        execute={savePhotos}
        disableBackdropClick={isUploading}
        disableEscapeKeyDown={isUploading}
        toExecuteOnClose={cancelUpload}
      />
    </>
  );
};

export default AddPicture;
