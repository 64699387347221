import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_PARAMS,
  RELOAD_DETAILS,
  SHOW_DRAWER_DIGITAL_TWIN,
  SHOW_CARD_MAKER_DIGITAL_TWIN,
  SHOW_DRAWER_OBJECT_DT,
  SET_DATA_OBJECT_DT,
  SET_DATA_OBJECT_DT_IMAGES,
  SET_PROFILE_IMAGE,
  SHOW_MARKERS_DIGITAL_TWIN,
  SET_MARKER_BY_TYPE_ELEMENT_ID,
  SET_VIEW_MODE_COLLECTION,
  SET_SECTION_COLLECTION,
  SET_DATE_COLLECTION,
  SHOW_MARKERS_BY_ACTIVITIES,
  SHOW_OPERATION_DETAILS,
  OPERATION_DETAILS,
  SET_FILTER_OBJECTS_BY_DATA,
  SET_DRAW_ANY_THING,
  SET_DRAWER_CONTROL,
  SET_UPDATE_REGION,
  SET_MAP,
  SET_OBJECTS_UNDER_DRAWN_POINT,
  SET_OPERATION_CLUSTERS,
  SET_OBJECT_CLUSTERS,
  SET_COMPONENT_CLUSTERS,
  SET_OBJECTS_DIGITAL_TWIN,
  SET_FILTERED_OBJECTS_DIGITAL_TWIN,
  SET_RADIUS_ELEMENTS_NEAR,
  RESET_STATES,
  M_ZOOM_VIEW,
  MAP_SETTINGS,
} from "./types";

export const setMapSettings = (payload) => {
  return {
    type: MAP_SETTINGS,
    payload,
  };
};

export const setMZoomView = (payload) => {
  return {
    type: M_ZOOM_VIEW,
    payload,
  };
};

export const resetStatesIndex = (payload) => {
  return {
    type: RESET_STATES,
    payload,
  };
};

export const setObjectsDigitalTwin = (payload) => {
  return {
    type: SET_OBJECTS_DIGITAL_TWIN,
    payload,
  };
};

export const setFilteredObjectsDigitalTwin = (payload) => {
  return {
    type: SET_FILTERED_OBJECTS_DIGITAL_TWIN,
    payload,
  };
};

export const setOperationClusters = (payload) => ({
  type: SET_OPERATION_CLUSTERS,
  payload,
});

export const setObjectClusters = (payload) => ({
  type: SET_OBJECT_CLUSTERS,
  payload,
});

export const setComponentClusters = (payload) => ({
  type: SET_COMPONENT_CLUSTERS,
  payload,
});

export const setObjectsUnderDrawnPoint = (payload) => ({
  type: SET_OBJECTS_UNDER_DRAWN_POINT,
  payload,
});

export const setMap = (payload) => ({
  type: SET_MAP,
  payload,
});

export const setDrawerControl = (payload) => ({
  type: SET_DRAWER_CONTROL,
  payload,
});

export const setUpdateRegion = (payload) => ({
  type: SET_UPDATE_REGION,
  payload,
});

export const setDrawAnyThing = (payload) => ({
  type: SET_DRAW_ANY_THING,
  payload,
});

export const setFilterObjectsByData = (payload) => {
  return {
    type: SET_FILTER_OBJECTS_BY_DATA,
    payload,
  };
};

export const setOperationDetails = (payload) => {
  return {
    type: OPERATION_DETAILS,
    payload,
  };
};

export const setShowOperationDetails = (payload) => {
  return {
    type: SHOW_OPERATION_DETAILS,
    payload,
  };
};

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const setCurrentUserParams = (payload) => ({
  type: SET_CURRENT_USER_PARAMS,
  payload,
});

export const reloadDetails = (payload) => ({
  type: RELOAD_DETAILS,
  payload,
});

export const showDrawerDigitalTwin = () => ({
  type: SHOW_DRAWER_DIGITAL_TWIN,
});

export const showDrawerObjectDt = (payload) => ({
  type: SHOW_DRAWER_OBJECT_DT,
  payload,
});

export const showCardMakerDigitalTwin = (payload) => ({
  type: SHOW_CARD_MAKER_DIGITAL_TWIN,
  payload,
});

export const setDataObjectDTImages = (payload) => ({
  type: SET_DATA_OBJECT_DT_IMAGES,
  payload,
});

export const setProfileImage = (payload) => ({
  type: SET_PROFILE_IMAGE,
  payload,
});

export const setDataObjectDT = (payload) => ({
  type: SET_DATA_OBJECT_DT,
  payload,
});

export const showMarkersDigitalTwin = () => ({
  type: SHOW_MARKERS_DIGITAL_TWIN,
});

export const showMarkerByTypeElementId = (payload) => ({
  type: SET_MARKER_BY_TYPE_ELEMENT_ID,
  payload,
});
export const setViewModeCollection = (payload) => ({
  type: SET_VIEW_MODE_COLLECTION,
  payload,
});

export const setSectionCollection = (payload) => ({
  type: SET_SECTION_COLLECTION,
  payload,
});

export const setDateCollection = (payload) => ({
  type: SET_DATE_COLLECTION,
  payload,
});

export const showMarkersByActivities = (payload) => ({
  type: SHOW_MARKERS_BY_ACTIVITIES,
  payload,
});

export const setRadiusElementsNear = (payload) => ({
  type: SET_RADIUS_ELEMENTS_NEAR,
  payload,
});
