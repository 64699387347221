import { useState } from "react";
import OpenDialog from "components/Dialogs/OpenDialog";
import { ButtonInfo } from "./ObjectDetails/ObjectDetailsElements";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import BlockIcon from "@mui/icons-material/Block";
// Dependencies
import { Grid } from "@mui/material";
import { CnstInfoTable } from "./OperationInfoStyles";
import {
  INFORMATION_COLOR,
  BLOCK_COLOR,
  SECOND_WARNING_COLOR,
} from "utils/const";
import useSWR from "swr";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import { FROM_TABLE } from "utils/constStrings";
import { urlsApiMg } from "utils/urlKeys";

const OperationInfo = ({
  content,
  autoComplete,
  warning,
  warningGlobalOptions,
  disabledSelectGlobalMesurement,
}) => {
  const { data: globalTables, error: errorGlobalTables } = useSWR(
    urlsApiMg.globalValuesFields
  );
  const globalDataTables =
    globalTables && !errorGlobalTables ? globalTables : [];

  const usedConstants = ((data) => {
    return data?.options?.length === 0
      ? []
      : data?.options?.map((it) => {
          if (typeof it === "object") {
            return it;
          } else {
            return JSON.parse(it);
          }
        });
  })(content);

  const [dialog, setDialog] = useState({
    open: false,
    actions: disabledSelectGlobalMesurement
      ? null
      : {
          agree: "Close",
        },
  });

  const handleShow = () => {
    setDialog((current) => ({
      ...current,
      open: true,
    }));
  };
  const handleShowOff = () => {
    setDialog((current) => ({
      ...current,
      open: false,
    }));
  };
  return (
    <ButtonInfo>
      {warning || warningGlobalOptions ? (
        <WarningAmberIcon
          sx={{
            fontSize: "1.8rem",
            color: SECOND_WARNING_COLOR,
          }}
          onClick={handleShow}
        />
      ) : autoComplete ? (
        <MotionPhotosAutoIcon
          sx={{
            fontSize: "1.8rem",
            color: INFORMATION_COLOR,
          }}
          onClick={handleShow}
        />
      ) : disabledSelectGlobalMesurement ? (
        <BlockIcon
          sx={{
            fontSize: "1.8rem",
            color: BLOCK_COLOR,
          }}
          onClick={handleShow}
        />
      ) : (
        <ContactSupportIcon
          sx={{
            fontSize: "1.8rem",
            color: INFORMATION_COLOR,
          }}
          onClick={handleShow}
        />
      )}
      <OpenDialog
        openDialog={dialog?.open}
        setOpenDialog={setDialog}
        execute={handleShowOff}
        isWarning={warning || warningGlobalOptions}
        isBlock={disabledSelectGlobalMesurement}
        content={{
          title:
            warning || warningGlobalOptions
              ? "Warning"
              : disabledSelectGlobalMesurement
              ? "Block"
              : autoComplete
              ? "Autocomplete Standards Catalog Table"
              : "Expression details",
          description: (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "25px",
              }}
            >
              {warning ? (
                <div>
                  <h3>
                    This attribute is autocompleted from a Standards Catalog
                    Table using a Global Key.
                  </h3>
                  <br />
                  <span>
                    Therefore one cannot mass edit its values. To change it,
                    change the Global Key.
                  </span>
                </div>
              ) : autoComplete ? (
                <div>
                  <p
                    style={{
                      marginBottom: "10px",
                      fontSize: "1.8rem",
                    }}
                  >
                    The selected value is located in the
                    <strong
                      style={{
                        margin: "4px",
                      }}
                    >
                      {content.columnKeyGlobalSpecs.column.name}
                    </strong>
                    column of the
                    <strong
                      style={{
                        margin: "4px",
                      }}
                    >
                      {
                        globalDataTables[0]?.tableCategories
                          ?.find(
                            (elm) =>
                              elm._id ===
                              content.columnKeyGlobalSpecs.categoryId
                          )
                          ?.groupTables?.find(
                            (item) =>
                              item._id === content.columnKeyGlobalSpecs.tableId
                          )?.name
                      }
                    </strong>
                    table in the
                    <strong
                      style={{
                        margin: "4px",
                      }}
                    >
                      {
                        globalDataTables[0]?.tableCategories?.find(
                          (elm) =>
                            elm._id === content.columnKeyGlobalSpecs.categoryId
                        ).name
                      }
                    </strong>
                    category.
                  </p>

                  <GenericTable
                    headers={
                      globalDataTables && globalDataTables.length > 0
                        ? globalDataTables[0]?.tableCategories
                            ?.find(
                              (elm) =>
                                elm._id ===
                                content.columnKeyGlobalSpecs.categoryId
                            )
                            ?.groupTables?.find(
                              (item) =>
                                item._id ===
                                content.columnKeyGlobalSpecs.tableId
                            )?.headers
                        : []
                    }
                    rows={
                      globalDataTables && globalDataTables.length > 0
                        ? globalDataTables[0].tableCategories
                            ?.find(
                              (elm) =>
                                elm._id ===
                                content.columnKeyGlobalSpecs.categoryId
                            )
                            ?.groupTables?.find(
                              (item) =>
                                item._id ===
                                content.columnKeyGlobalSpecs.tableId
                            )?.rows
                        : []
                    }
                    keyValues={
                      globalDataTables && globalDataTables.length > 0
                        ? globalDataTables[0].tableCategories
                            ?.find(
                              (elm) =>
                                elm._id ===
                                content.columnKeyGlobalSpecs.categoryId
                            )
                            ?.groupTables?.find(
                              (item) =>
                                item._id ===
                                content.columnKeyGlobalSpecs.tableId
                            )?.headers
                        : []
                    }
                    handlerClick={() => {}}
                    state={true}
                    configBar={true}
                    customTableWidth={"100%"}
                    customHeaderWidth={"5%"}
                    from={FROM_TABLE.details}
                  />
                </div>
              ) : warningGlobalOptions ? (
                <div>
                  <h3>
                    This Global Measurement Select has no more options to choose
                    from.
                  </h3>
                  <br />
                  <span>
                    Please create new values in the official global listings
                    table and in the global measures table.
                  </span>
                </div>
              ) : disabledSelectGlobalMesurement ? (
                <div>
                  <h3>The selected option is already synchronized.</h3>
                  <br />
                  <span>
                    The selected value of the measurement table is already
                    synchronized (already in use with another object).
                  </span>
                </div>
              ) : (
                <>
                  <div>
                    <span>
                      The result of this field is obtained from the following
                      expression:
                    </span>
                  </div>
                  <div>
                    <math-field
                      read-only
                      className="math-field"
                      id={content?.name}
                      style={{
                        display: "inline-block",
                        minWidth: "200px",
                        width: "100%",
                        minHeight: "60px",
                        fontSize: "2.2rem",
                        border: "none",
                      }}
                      value={content?.operation}
                    />
                  </div>
                  {usedConstants?.length > 0 && (
                    <ShowConstantsUsed array={usedConstants} />
                  )}
                </>
              )}
            </div>
          ),
          ...dialog.actions,
        }}
      />
    </ButtonInfo>
  );
};

const ShowConstantsUsed = ({ array }) => {
  return (
    <Grid container width={"100%"} justifyContent={"center"}>
      <CnstInfoTable>
        <tr>
          <th>Alias</th>
          <th>Name</th>
          <th>Value</th>
        </tr>
        {array.map((item) => (
          <tr>
            <td>{item.alias}</td>
            <td>{item.name}</td>
            <td>{item.value}</td>
          </tr>
        ))}
      </CnstInfoTable>
    </Grid>
  );
};

export default OperationInfo;
