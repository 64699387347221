import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "utils/const";

export const ForgotPasswordWrapper = styled.div`
  margin-top: 80px;
  padding: 21px;
`;

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  min-height: calc(100vh - 7rem);
  height: auto;
  padding: 5rem 0;

  .small {
    height: 90%;
    padding: 0 2rem;
    position: relative;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const ForgotForm = styled.form`
  min-width: 350px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 21px;
  border-radius: 1rem;
  min-height: 30vh;
`;

export const TitleForm = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: ${(props) => props.color || PRIMARY_COLOR};
`;

export const ForgotButton = styled.button`
  background-color: ${(props) => props.colorButton || PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  font-size: 1.6rem;
  height: 50px;
  padding: 10px;
  border-radius: 15px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${(props) => props.colorHoverButton || SECOND_COLOR};
  }
`;

export const Message = styled.div`
  height: 30px;
  line-height: 45px;
  color: ${(props) => (props.success ? SECOND_COLOR : "#f66060")};
  padding: 0px 15px;
  border-radius: 3px;
  margin: 10px auto;
  font-size: 1.6rem;
  display: ${(props) => !props.success && "none"};
`;

export const ForgotLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: ${(props) => props.color || PRIMARY_COLOR};
  cursor: pointer;
  margin-top: 13px;

  :hover {
    color: ${(props) => props.colorHover || SECOND_COLOR};
    text-decoration: underline;
  }
`;

export const NeuronImage = styled.img`
  position: absolute;
  top: 25%;
  left: 12%;
  opacity: 0.7;
  width: 30%;
  z-index: 0;
`;

export const LogoDecimetrix = styled.img`
  width: 60%;
  z-index: 10;
`;

export const BannerTitle = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${(props) => props.colorTitle || PRIMARY_COLOR};
  margin: 4rem 0;
  text-align: center;
  z-index: 10;
`;

export const BannerDescription = styled.p`
  font-size: 1.8rem;
  text-align: center;
  z-index: 10;
`;
