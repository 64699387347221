import Grid from "@mui/material/Grid";
// React
import { useState, useEffect, useRef } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { SetUsers, SetShowUsers, SetShowAllUsers } from "redux/actions/admin";
// Dependencies
import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import newWorkerClass from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
// import fogStyle from "utils/fogStyle";
// Components
import {
  CustomSelect,
  ButtonInformation,
  NueronTools,
  ButtonVisibilityOffTable,
  ButtonVisibilityOnTable,
  MessageLoadingMap,
} from "./userRoutes";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import DialogInformation from "components/Dialogs/DialogInformation.jsx";
import MapDrawer from "./MapDrawer";
import { MapCard, NavInfo } from "./UserRoutesElements";
import FloatingSetting from "components/Float/FloatingSettingMap";
import FloatingTable from "components/Float/FloatingTableOfAllMap";
import PolygonTable from "components/Drawers/DrawerPolygonTable";
import ExtendView from "./ExtendView";
import BaseMapButton from "components/Buttons/BaseMapButton";
import CaptureCoordinates from "./CaptureCoordinates";
import MovePoint from "./MovePoint";
//GeoControl
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { ContentInformation } from "../ContentDialogs/ContentDialogs";
// Services
import { findUsersByInstanceId } from "services/findUsersByInstanceId";
// Helpers
import HandlerDrawerControl from "./HandlerDrawerControl";
// Style
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { grey } from "@mui/material/colors";
import { IoIosArrowDown } from "react-icons/io";
import PreviewIcon from "@mui/icons-material/Preview";
// Const
import { BLACK_COLOR } from "utils/const";
import { MAPBOX_STYLE } from "utils/constStrings";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import MapDigital from "./Map";
import useFilterObjectsByDateAndUser from "hooks/objects/useFilterObjectsByDateAndUser";
import useFilterComponentsByDateAndUser from "hooks/components/useFilterComponentsByDateAndUser";
import useFilterEventsByDateAndUser from "hooks/events/useFilterEventsByDateAndUser";
import AvailableModules from "pages/AdminCompany/Hub/AvailableModules";
import MessagesDialog from "components/Dialogs/MessagesDialog";
import { CircularProgress } from "@mui/material";
mapboxgl.workerClass = newWorkerClass;

const CustomizedChevronLeftIcon = styled(ChevronLeftIcon)`
  background-color: var(--background-primary_color);
  border-radius: 23px;
`;

const CustomizedChevronRightIcon = styled(ChevronRightIcon)`
  background-color: var(--background-primary_color);
  border-radius: 23px;
`;

const MapDigitalTwin = ({
  objects,
  objectsMap,
  trackings,
  events,
  components,
  componentsMap,
  settingMap,
  lines,
}) => {
  const [baseMap, setBaseMap] = useState(
    settingMap?.styleMap
      ? { id: settingMap.styleMap.id, value: settingMap.styleMap.value }
      : {
        id: MAPBOX_STYLE.streets.id,
        value: MAPBOX_STYLE.streets.value,
      }
  );
  const [open, setOpen] = useState(true);
  const [haveObjects, setHaveObjects] = useState(false);
  const [information, setInfoObjects] = useState(false);
  const [section, setSection] = useState("points");
  const [seePolygonTable, setSeePolygonTable] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [currentDataEvent, setCurrentDataEvent] = useState({});
  const [numberTables, setNumberTables] = useState(false);
  const [copyCoordinates, setCopyCoordinates] = useState(false);
  const [copyLocation, setCopyLocation] = useState({});
  const [movePoint, setMovePoint] = useState(false);
  const [moveNewLocation, setMoveNewLocation] = useState({});
  const [visibilityGenericTable, setVisibilityGenericTable] =
    useState("inline");

  window.addEventListener("storage", (event) => {
    if (event.key === "currentId") {
      setCurrentId(JSON.parse(localStorage.getItem("currentId")));
    } else if (event.key === "typeEvent") {
      setCurrentDataEvent(JSON.parse(localStorage.getItem("typeEvent")));
    } else if (event.key === "tablesOpen") {
      setNumberTables(!numberTables);
    }
  });

  useEffect(() => {
    if (
      localStorage.getItem("newLocationMove") === null &&
      Object.keys(moveNewLocation).length > 0
    ) {
      setMoveNewLocation({});
    }
  }, [events, components, objects]);

  const { onCopyCoordinates, onMovePoints } = HandlerDrawerControl();

  const dispatch = useDispatch();

  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  const neuron = useSelector(
    (state) => state.digitalTwinReducer.setNeuronSelectTool
  );

  const messageLoadingMap = useSelector(
    (state) => state.digitalTwinReducer.messageLoadingMap
  );

  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });

  const users = useSelector((state) => state.adminReducer.SetUsers);
  const showUsers = useSelector((state) => state.adminReducer.SetShowUsers);

  const showAllUsers = useSelector(
    (state) => state.adminReducer.SetShowAllUsers
  );

  const mapRef = useRef();

  const {
    filterObjects,
    setFilterObjects,
    filterObjectsMap,
    setFilterObjectsMap,
  } = useFilterObjectsByDateAndUser({
    objects,
    objectsMap,
    users,
  });
  const { filterComponents, filterComponentsMap } =
    useFilterComponentsByDateAndUser({
      components,
      componentsMap,
      users,
    });
  const { filterEvents } = useFilterEventsByDateAndUser({
    events,
    users,
  });

  useEffect(() => {
    if (showUsers) dispatch(SetShowUsers(!showUsers));
  }, [
    dispatch,
    showUsers,
    // currentId,
    // showAllEvents,
    // showMarkerByElementWithMedia,
    // objects,
    // visibilityGenericTable,
  ]);

  useEffect(() => {
    findUsersByInstanceId().then((usersAdmin) => {
      const users = {};
      usersAdmin.forEach((user) => {
        users[user.id] = {
          operator: user.name,
          isShow: true,
        };
      });
      dispatch(SetUsers(users));
    });
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const seeAllMarkerOfUser = () => {
    for (const key in users) {
      users[key].isShow = true;
      setHaveObjects(false);
    }
    setFilterObjects(objects);
    setFilterObjectsMap(objectsMap);
  };

  if (showAllUsers) {
    dispatch(SetShowAllUsers(!showAllUsers));
    seeAllMarkerOfUser();
  }

  const handleTabInformation = (event) => {
    setSection(event.currentTarget.value);
  };

  // Modal that show the objects
  const handleInformationObjects = () => {
    setInfoObjects(true);
  };

  const handleVisibility = () => {
    if (visibilityGenericTable === "inline") {
      setVisibilityGenericTable("none");
    } else {
      setVisibilityGenericTable("inline");
    }
  };

  useEffect(() => {
    if (geometryPolygon?.type === "Polygon") setSeePolygonTable(() => true);
  }, [geometryPolygon]);

  return (
    <Grid container style={{ backgroundColor: BLACK_COLOR }}>
      {/* Dialogs */}
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        content={contentDialog}
      />
      {/* Dialog infotmation */}
      <DialogInformation
        openDialog={information}
        setOpenDialog={setInfoObjects}
        // content={ContentInformation(section, handleTabInformation)}
        content={ContentInformation({ section, handleTabInformation })}
      />
      <Grid item xs={12} sm={6} md={9}>
        <MapCard>
          <CustomSelect>
            <NavInfo tipo={open}>
              <MapDrawer drawState={open} dataObjects={objects} />
              {open === false ? (
                <IconButton
                  color="error"
                  onClick={handleDrawerOpen}
                  fontSize="medium"
                  sx={{ color: grey[50] }}
                >
                  <SimpleToolTip title="Open Map Drawer" placement="right">
                    <CustomizedChevronRightIcon sx={{ color: grey[100] }} />
                  </SimpleToolTip>
                </IconButton>
              ) : (
                <IconButton
                  color="error"
                  onClick={handleDrawerClose}
                  fontSize="large"
                  sx={{ color: grey[50] }}
                >
                  <SimpleToolTip title="Close Map Drawer" placement="right">
                    <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
                  </SimpleToolTip>
                </IconButton>
              )}
            </NavInfo>
          </CustomSelect>
          {seePolygonTable && (
            <PolygonTable
              setSeePolygonTable={setSeePolygonTable}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              visibilityGenericTable={visibilityGenericTable}
              setVisibilityGenericTable={setVisibilityGenericTable}
              handlerVisibiltyTable={
                <ButtonVisibilityOffTable
                  className="color1"
                  onClick={() => handleVisibility()}
                >
                  <IoIosArrowDown className="color1" />
                </ButtonVisibilityOffTable>
              }
            />
          )}
          {visibilityGenericTable === "inline" && (
            <FloatingTable setSeePolygonTable={setSeePolygonTable} />
          )}

          {visibilityGenericTable === "none" && (
            <SimpleToolTip title="Open Table" placement="top">
              <ButtonVisibilityOnTable onClick={() => handleVisibility()}>
                <PreviewIcon
                  sx={{ color: "var(--background-primary_color)" }}
                />
              </ButtonVisibilityOnTable>
            </SimpleToolTip>
          )}

          <FloatingSetting />
          <NueronTools
            display={neuron.show}
            top={neuron.top}
            right={neuron.right}
          />
          {/* Button information */}
          {objects && (
            <SimpleToolTip title="Information" placement="left">
              <ButtonInformation onClick={handleInformationObjects}>
                <InfoIcon style={{ color: "#282828", fontSize: "1.6rem" }} />
              </ButtonInformation>
            </SimpleToolTip>
          )}

          {/* Button hand map */}
          {/* <HandButton map={mapRef.current} /> */}

          {/* Button Extend view */}
          <ExtendView map={mapRef} />

          {/* Button layer map */}
          {objects && (
            <BaseMapButton
              setHaveObjects={setHaveObjects}
              setContentDialog={setContentDialog}
              baseMap={baseMap}
              setBaseMap={setBaseMap}
              position={357}
            />
          )}

          <MovePoint
            from={"digitalTwin"}
            setMovePoint={setMovePoint}
            movePoint={movePoint}
            moveNewLocation={moveNewLocation}
            setMoveNewLocation={setMoveNewLocation}
            onMovePoints={onMovePoints}
          />
          <CaptureCoordinates
            setCopyCoordinates={setCopyCoordinates}
            copyCoordinates={copyCoordinates}
            copyLocation={copyLocation}
            setCopyLocation={setCopyLocation}
            onCopyCoordinates={onCopyCoordinates}
          />
          <MessageLoadingMap>
            <MessagesDialog
              open={messageLoadingMap}
              severity={"info"}
              message={
                <div style={{ width: "110px", fontSize: "14px" }}>
                  Loading Map
                  <CircularProgress size={15} style={{ marginLeft: "8px" }} />
                </div>
              }
              position="static"
              title={false}
            />
          </MessageLoadingMap>
          <MapDigital
            baseMap={baseMap}
            setCopyLocation={setCopyLocation}
            setCopyCoordinates={setCopyCoordinates}
            copyCoordinates={copyCoordinates}
            setMoveNewLocation={setMoveNewLocation}
            setMovePoint={setMovePoint}
            movePoint={movePoint}
            mapRef={mapRef}
            trackings={trackings}
            filterObjects={filterObjects}
            filterObjectsMap={filterObjectsMap}
            filterEvents={filterEvents}
            filterComponents={filterComponents}
            filterComponentsMap={filterComponentsMap}
            currentId={currentId}
            setCurrentId={setCurrentId}
            currentDataEvent={currentDataEvent}
            setCurrentDataEvent={setCurrentDataEvent}
            allLines={lines}
          />
          <AvailableModules mapRef={mapRef} />
        </MapCard>
      </Grid>
    </Grid>
  );
};

export default MapDigitalTwin;
