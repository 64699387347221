// React
import { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { useSWRConfig } from "swr";
import {
  getTableRowsFilter,
  getTableRowsFull,
  setOriginalOrderViewColumns,
  setRowNum,
  setStateFilterButtonTable,
  showFilterLinesInMap,
} from "redux/actions/digitalTwin";
// Dependencies
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
// Styles
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import SaveIcon from "@mui/icons-material/Save";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
// import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import {
  AnimatedWorkspacesIcon,
  ButtonInfo,
  ContainerChangeGeoreference,
  ContentTable,
  Divider,
  IconContainer,
  SearchTable,
} from "./GenericTableStyle";
import {
  INFORMATION_COLOR,
  PRIMARY_COLOR,
  SET_VISIBILITY_ICON,
  VISIBILITY_OFF_ICON,
} from "utils/const";
// Hooks
import useShowDetails from "hooks/useShowDetails";
import useViewFilterClusterMap from "../../../hooks/useViewFilterClusterMap";
// Helper
import { isUrl, nameUrl, handleClickLink } from "helpers/isUrl";
import { removeAccents } from "helpers/removeAccents";
import createDefaultColumns from "./helpers/createDefaultColumns";
import SelectVisivilityColumns from "./helpers/SelectVisivilityColumns";
import relationTypeColor from "./helpers/relationTypeColor";
import fuzzyFilter from "./helpers/components/Filter/fuzzyFilter";
import { checkFilter } from "./helpers/components/Filter/checkFilter";
import rangeFilterByDate from "./helpers/components/Filter/rangeFilterByDate";
import SelectViewTable from "./helpers/SelectViewTable";
import DraggableColumnHeader from "./helpers/components/DraggableColumnHeader";
import { FooterTools } from "./helpers/components/FooterTools";
// Components
import { ConfigTable } from "./ConfigTable";
import OpenDialog from "components/Dialogs/OpenDialog";
import FormTable from "components/Dialogs/FormTable";
import EditFilterTable from "./EditFilterTable";
import ClickToolTip from "components/ToolTips/ClickToolTip";
import { ManageUsersTable } from "./ManageUsers/ManageUsersTable";
// Utils
import { FROM_TABLE, TYPE_ELEMENT } from "utils/constStrings";
import { BulkDelete } from "components/Tables/GenericTable/DeleteFilterTable";
// Config
import { config } from "config.js";
import updatePointEvent from "services/updatePointEvent";
import { urlKeys, urlKeysDetail } from "utils/urlKeys";
import {
  useFetchComponentsLibraries,
  useFetchEventLibraries,
  useFetchObjectLibraries,
  useFetchObjectLineLibraries,
} from "hooks/fetchLibraries";
import { extractFields } from "./helpers/extractFieldsFromLibrary";
import useMarkerPointsGroup from "hooks/useMarkerPointsGroup";
import { AllowAccessUsersTable } from "./ManageUsers/AllowAccessUsersTable";

/**
 * Renders a customized table with given headers, rows, and keyValues. The table
 * can handle click and delete events, with the option to add dynamic values.
 * @param {Object} headers - An array of table headers.
 * @param {Object} rows - An array of table rows.
 * @param {Object} keyValues - An array of key values to map to row data.
 * @param {object} fieldOrderMap - An object with the order of the fields.
 * @param {Function} handlerClick - A function to handle row click events.
 * @param {Function} handlerDelete - A function to handle row delete events.
 * @param {Array} isDynamicEvent - An array of objects containing dynamic values.
 * @return {JSX.Element} A customized table component.
 */
const GenericTable = ({
  headers,
  rows,
  setRows,
  keyValues,
  typeOfTable,
  handlerClick,
  handlerClickToggleLibrary,
  rowsDynamic,
  handlerDelete,
  style,
  tableVisibility,
  setTableVisibility,
  isDynamicEvent,
  typeOfLibrary,
  handleClose,
  handleDrawerClose,
  handleDrawerOpen,
  state,
  configBar,
  customHeaderWidth,
  customTableWidth,
  from = null,
  register,
  digitalTwin,
  pointLibraryId,
  dataChecked,
  setDataChecked,
  lines,
  valuesNoSessions,
  setValuesNoSessions,
  valuesNoSessionsMobile,
  setValuesNoSessionsMobile,
  detailId,
  nonLocation,
  fieldOrderMap,
}) => {
  const { handlerClickObjectId, handlerClickComponentId, handlerClickEventId } =
    useShowDetails();
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const [data, setData] = useState(() => [...rows]);
  const [columns, setColumns] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [dateFilters, setDateFilters] = useState({
    columnId: "",
    min: "",
    max: "",
  });
  const [columnVisibility, setColumnVisibility] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [useFilter, setUseFilter] = useState(false);
  const [columnResizeMode] = useState("onChange");
  const [filterValue, setFilterValue] = useState(false);
  const [tablesOpen, setTablesOpen] = useState(0);
  const [idFilters, setIdFilters] = useState([]);
  const [buttonFilters, setButtonFilters] = useState(true);
  const [buttonViewMarkersGroup, setButtonViewMarkersGroup] = useState(true);
  const [messageInfoFilter, setMessageInfoFilter] = useState(false);
  const [useOrderColumns, setUseOrderColumns] = useState(false);
  const [filterCheckBox, setFilterCheckBox] = useState([]);
  const [columnOrder, setColumnOrder] = useState(null);
  const [openDialogCreateView, setOpenDialogCreateView] = useState(false);
  const [openEditFilterData, setOpenEditFilterData] = useState(false);
  const [openBulkDelete, setOpenBulkDelete] = useState(false);
  const [currentNameviewColumnOrder, setCurrentNameViewColumnOrder] =
    useState("Default Table View");
  const [currentViewColumnOrder, setCurrentViewColumnOrder] = useState(null);
  const [messageEditFilter, setMessageEditFilter] = useState(false);
  const [selectedFiltersByColumnOrder, setSelectedFiltersByColumnOrder] =
    useState([]);
  const [openDialogNonGeoreferencedEvent, setOpenDialogNonGeoreferencedEvent] =
    useState(false);
  const [statusGeoreferencedEvent, setStatusGeoreferencedEvent] = useState({
    status: nonLocation ? "georeferenced" : "nonGeoreferenced",
  });
  const [numericFields, setNumericFields] = useState([]);

  const [viewFilterObjectsInMap, setViewFilterObjectsInMap] = useState(null);
  const [useMarkPointGroup, setUseMarkPointGroup] = useState(null);

  const optionDownload = useSelector(
    (state) => state.digitalTwinReducer.optionDownload
  );

  const allFilterTableRows = useSelector(
    (state) => state.digitalTwinReducer.filterTableRows
  );
  const useDefaultFetch = () => ({ data: [], error: null });

  // Logic to determine which hook to use
  let useFetchHook = useDefaultFetch;
  if (typeOfTable === TYPE_ELEMENT.OBJECT && !lines) {
    useFetchHook = useFetchObjectLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.COMPONENT) {
    useFetchHook = useFetchComponentsLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.EVENT) {
    useFetchHook = useFetchEventLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.OBJECT && lines) {
    useFetchHook = useFetchObjectLineLibraries;
  }

  const { data: dataLibrary, error: dataLibraryError } = useFetchHook({
    id: pointLibraryId,
  });

  useEffect(() => {
    if (dataLibrary && dataLibrary.length && !dataLibraryError) {
      const fieldsToExtract =
        typeOfTable === TYPE_ELEMENT.OBJECT && lines
          ? ["number", "operation", "range", "distance"]
          : ["number", "operation", "range"];
      // choose between complete data or filtered
      const extractedNumFields = extractFields({
        dataLibrary,
        fieldsToExtract,
      });
      setNumericFields(extractedNumFields);
    }
  }, [dataLibrary, dataLibraryError, lines, typeOfTable]);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility,
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  useEffect(() => {
    const numTables = localStorage.getItem("tablesOpen");
    if (numTables === null) {
      localStorage.setItem("tablesOpen", 0);
    }
  }, []);

  const originalOrderColumns = useSelector(
    (state) => state.digitalTwinReducer.originalOrderViewColumns
  );

  useEffect(() => {
    if (originalOrderColumns.length === 0) {
      const { allKeyValues } = createDefaultColumns(
        headers,
        keyValues,
        rowsDynamic,
        fieldOrderMap
      );
      dispatch(setOriginalOrderViewColumns(allKeyValues));
    }
  }, [rows, fieldOrderMap]);

  useEffect(() => {
    dispatch(setOriginalOrderViewColumns([]));
    setCurrentNameViewColumnOrder("Default Table View");
    table.setColumnOrder(currentViewColumnOrder);
  }, [typeOfTable, typeOfLibrary]);

  useEffect(() => {
    if (currentNameviewColumnOrder === "Default Table View") {
      const { allKeyValues } = createDefaultColumns(
        headers,
        keyValues,
        rowsDynamic,
        fieldOrderMap
      );
      table.setColumnOrder(allKeyValues);
      setCurrentViewColumnOrder(allKeyValues);
    }
  }, [currentNameviewColumnOrder, rows, fieldOrderMap]);

  useEffect(() => {
    if (filterCheckBox.length === 0) {
      const { columnsFormat } = createDefaultColumns(
        headers,
        keyValues,
        rowsDynamic,
        fieldOrderMap
      );
      setColumns(columnsFormat);
    }
  }, [headers, table, keyValues, fieldOrderMap]);

  useEffect(() => {
    table.setColumnOrder(currentViewColumnOrder);
  }, [currentViewColumnOrder]);

  useEffect(() => {
    const newRows = rows.filter((obj) => checkFilter(obj, filterCheckBox));
    if (filterCheckBox.length > 0) {
      if (dateFilters.columnId !== "") {
        if (newRows.length !== 0) {
          const filterByDate = rangeFilterByDate({
            rows: newRows,
            id: dateFilters.columnId,
            dateFilters,
          });
          return setData(filterByDate);
        }
        const filterByDate = rangeFilterByDate({
          rows: newRows,
          id: dateFilters.columnId,
          dateFilters,
        });
        return setData(filterByDate);
      } else {
        return setData(newRows);
      }
    }
    if (dateFilters.columnId !== "") {
      const filterByDate = rangeFilterByDate({
        rows,
        id: dateFilters.columnId,
        dateFilters,
      });
      return setData(filterByDate);
    } else {
      return setData(rows);
    }
  }, [filterCheckBox, columnFilters, rows, dateFilters, from]);

  useEffect(() => {
    dispatch(setRowNum(table.getPrePaginationRowModel().rows.length));
  }, [rows, table.getPrePaginationRowModel().rows.length]);

  useEffect(() => {
    if (filterCheckBox.length === 0 && from === FROM_TABLE.digitalTwin) {
      const allRowsById = table.getRowModel().rowsById;
      const allRows = [];
      for (let key in allRowsById) {
        allRows.push(allRowsById[key]);
      }
      dispatch(getTableRowsFull(allRows));
    }
  }, [optionDownload, typeOfLibrary, table.getRowModel().rowsById]);

  useEffect(() => {
    const allRowsById = table.getRowModel().rowsById;
    const allRows = [];
    for (let key in allRowsById) {
      allRows.push(allRowsById[key]);
    }
    const filterData = [];
    allRows.forEach((elm) => {
      const currentColumnVisible = elm.getVisibleCells();
      let currentData = {};
      currentColumnVisible.forEach((elm2) => {
        currentData[elm2.column.id] = elm2.getValue();
        if (currentColumnVisible.length === Object.keys(currentData).length) {
          filterData.push(currentData);
        }
      });
    });
    dispatch(getTableRowsFilter(filterData));
  }, [
    table,
    optionDownload,
    dataChecked,
    openEditFilterData,
    table.getRowModel().rowsById,
    dispatch,
  ]);

  let dynamicValues = {};
  isDynamicEvent?.map((value) => (dynamicValues[value.id] = value.isDynamic));

  useEffect(() => {
    const objectsFilters = table.getRowModel().rowsById;
    const allIds = [];
    for (let key in objectsFilters) {
      allIds.push(objectsFilters[key].original.id);
    }
    setIdFilters(allIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    table.getRowModel().rowsById,
    // dataObjectsPolygon,
    // filterOperations,
    // dataComponentsPolygon,
  ]);

  useEffect(() => {
    if (!buttonFilters) {
      setViewInMap();
    }
    if (!buttonViewMarkersGroup) {
      setViewMarkerPoints();
    }
    setUseFilter(false);
    setFilterCheckBox([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfLibrary]);

  const { viewInMap, setViewInMap } = useViewFilterClusterMap({
    setButtonFilters,
    typeOfTable,
    typeOfLibrary,
    idFilters: allFilterTableRows,
  });

  useEffect(() => {
    if (allFilterTableRows.length > 0) {
      if (viewFilterObjectsInMap) {
        viewInMap();
      } else {
        if (viewFilterObjectsInMap === false) {
          setViewInMap();
        }
      }
    }
  }, [allFilterTableRows, viewFilterObjectsInMap]);

  const { viewMarkerPoints, setViewMarkerPoints } = useMarkerPointsGroup({
    setButtonViewMarkersGroup,
    dataMarkerPoints: allFilterTableRows,
  });

  useEffect(() => {
    if (allFilterTableRows.length > 0) {
      if (useMarkPointGroup) {
        viewMarkerPoints();
      } else {
        if (useMarkPointGroup === false) {
          setViewMarkerPoints();
        }
      }
    }
  }, [allFilterTableRows, useMarkPointGroup]);

  const handlerSetValue = () => {
    setFilterCheckBox([]);
    setFilterValue(true);
    setData(rows);
    setDateFilters({ columnId: "", min: "", max: "" });
    setViewFilterObjectsInMap(false);
    setTimeout(() => {
      setFilterValue(false);
    }, 1000);
  };

  const handleGlobalFilterChange = (e) => {
    const value = e.target.value;
    const filteredData = rows.filter((row) => {
      return Object.values(row).some((cellValue) =>
        removeAccents(String(cellValue).toLowerCase()).includes(
          removeAccents(value.toLowerCase())
        )
      );
    });

    setData(filteredData);
  };

  const handleOpenWindow = () => {
    const width = window.innerWidth;
    const height = window.innerHeight / 2;
    localStorage.setItem("tablesOpen", tablesOpen + 1);
    handleClose();
    window.open(
      "/new-window-table",
      "_blank",
      `width=${width},height=${height}`
    );
  };

  const handleSaveOrderColumn = () => {
    if (useOrderColumns || filterCheckBox.length > 0)
      setOpenDialogCreateView(true);
  };

  const showFilterLines = useSelector(
    (state) => state.digitalTwinReducer.showFilterLinesInMap
  );

  const viewFilterLinesInMap = () => {
    dispatch(showFilterLinesInMap(!showFilterLines));
    if (!showFilterLines) {
      setTableVisibility("none");
      dispatch(setStateFilterButtonTable(true));
    } else {
      setTableVisibility("inline");
    }
  };

  const viewFilterObjects = () => {
    setTableVisibility("none");
    dispatch(setStateFilterButtonTable(true));
    setViewFilterObjectsInMap(true);
  };

  const handleConvertToGeoreferencedEvent = async () => {
    const allRowsById = table.getRowModel().rowsById;
    const allRows = [];
    for (let key in allRowsById) {
      allRows.push(allRowsById[key]);
    }
    const filterData = [];
    allRows.forEach((elm) => {
      if (elm.original.latitude && elm.original.longitude) {
        const currentColumnVisible = elm.getVisibleCells();
        let currentData = {};
        currentColumnVisible.forEach((elm2) => {
          currentData[elm2.column.id] = elm2.getValue();
          if (currentColumnVisible.length === Object.keys(currentData).length) {
            filterData.push(currentData);
          }
        });
      }
    });

    try {
      await Promise.all(
        filterData.map(async (elm) => {
          await updatePointEvent({
            id: elm.id,
            body: {
              isGeographic:
                statusGeoreferencedEvent.status === "georeferenced"
                  ? true
                  : false,
            },
          });
          return mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events?id=${elm.id}`
          );
        })
      );

      const {
        urlObjects,
        urlComponents,
        urlEventsObjects,
        urlEventsComponents,
        urlEventsEvents,
        urlDynamicComponentEvents,
        urlDynamicEventsObjects,
        urlDynamicEventsEvents,
      } = urlKeysDetail({ detailId });

      await Promise.all([
        mutate(urlKeys.eventsObjects),
        mutate(urlKeys.eventsComponents),
        mutate(urlKeys.eventsEvents),
        mutate(urlComponents),
        mutate(urlDynamicComponentEvents),
        mutate(urlDynamicEventsObjects),
        mutate(urlKeysDetail),
        mutate(urlDynamicEventsEvents),
        mutate(urlEventsObjects),
        mutate(urlEventsComponents),
        mutate(urlEventsEvents),
        mutate(urlObjects),
      ]);
      setStatusGeoreferencedEvent({ status: "" });
      setOpenDialogNonGeoreferencedEvent(false);
    } catch (error) {
      console.error("Error updating point events:", error);
    }
  };

  return (
    <div
      style={{
        marginTop: "0px",
        height: "100%",
      }}
    >
      <OpenDialog
        openDialog={openDialogCreateView}
        setOpenDialog={setOpenDialogCreateView}
        execute={() => setOpenDialogCreateView(false)}
        content={{
          title: "Save new table view",
          description: (
            <FormTable
              typeOfLibrary={typeOfLibrary}
              typeOfTable={typeOfTable}
              columnOrder={columnOrder}
              selectedFilters={filterCheckBox}
              setOpenDialogCreateView={setOpenDialogCreateView}
              setUseOrderColumns={setUseOrderColumns}
              setCurrentNameViewColumnOrder={setCurrentNameViewColumnOrder}
              setCurrentViewColumnOrder={setCurrentViewColumnOrder}
            />
          ),
        }}
        maxWidth={"200px"}
        minWidth={"500px"}
      />
      <OpenDialog
        openDialog={openEditFilterData}
        setOpenDialog={setOpenEditFilterData}
        execute={() => {}}
        content={{
          title: "Edit all filtered data",
          description: (
            <EditFilterTable
              typeOfTable={typeOfTable}
              pointLibraryId={pointLibraryId}
              setOpenEditFilterData={setOpenEditFilterData}
              isLine={lines}
            />
          ),
        }}
        maxWidth={"200px"}
        minWidth={"500px"}
      />
      <OpenDialog
        openDialog={openBulkDelete}
        setOpenDialog={setOpenBulkDelete}
        execute={() => {}}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        content={{
          title: "Delete filtered data ⚠️",
          description: (
            <BulkDelete
              typeOfTable={typeOfTable}
              setOpenBulkDelete={setOpenBulkDelete}
              isLine={lines}
            />
          ),
        }}
        maxWidth={"200px"}
        minWidth={"500px"}
      />
      <OpenDialog
        openDialog={openDialogNonGeoreferencedEvent}
        setOpenDialog={setOpenDialogNonGeoreferencedEvent}
        execute={() => handleConvertToGeoreferencedEvent()}
        content={{
          title: "Toggle Georeference Status",
          description: (
            <ContainerChangeGeoreference>
              <p className="description">
                Do you want to change this event's georeference status? You can
                choose to make it either a georeferenced or non-georeferenced
                event.
              </p>

              <div className="checkbox-group">
                <label className="checkbox-label">
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    checked={
                      statusGeoreferencedEvent.status === "nonGeoreferenced"
                    }
                    onChange={() =>
                      setStatusGeoreferencedEvent({
                        status: "nonGeoreferenced",
                      })
                    }
                  />
                  <span className="checkbox-text">Non-georeferenced</span>
                </label>

                <label className="checkbox-label">
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    checked={
                      statusGeoreferencedEvent.status === "georeferenced"
                    }
                    onChange={() =>
                      setStatusGeoreferencedEvent({
                        status: "georeferenced",
                      })
                    }
                  />
                  <span className="checkbox-text">Georeferenced</span>
                </label>
              </div>

              <p className="info-text">
                <strong>If Non-Georeferenced:</strong>{" "}
                <span className="warning-icon">⚠</span> The event will not
                appear on the digital twin map.
              </p>
              <p className="info-text">
                <strong>If georeferenced:</strong>{" "}
                <span className="warning-icon">⚠</span> Only events that have
                coordinates will be changed and located on the map.
              </p>
            </ContainerChangeGeoreference>
          ),
          disagree: "Cancel",
          agree: "Confirm",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />
      {!configBar && (
        <SearchTable
          // width={from === FROM_TABLE.digitalTwin ? "71%" : "100%"}
          margin={state ? 0 : 20}
          style={{ alignItems: "center" }}
          // inputWidth={from === FROM_TABLE.digitalTwin ? "30%" : "46%"}
          containerToolsWidth={from === FROM_TABLE.digitalTwin ? "30%" : "12%"}
        >
          <div className="container-inputs">
            <div
              style={{
                width: from === FROM_TABLE.digitalTwin ? "24%" : "40%",
              }}
            >
              <input
                type="text"
                onChange={handleGlobalFilterChange}
                className="input"
                placeholder="Search all columns..."
              />
            </div>
            <div
              style={{
                width: from === FROM_TABLE.digitalTwin ? "24%" : "40%",
              }}
            >
              <SelectVisivilityColumns table={table} />
            </div>
            {digitalTwin && (
              <SelectViewTable
                setCurrentNameViewColumnOrder={setCurrentNameViewColumnOrder}
                setCurrentViewColumnOrder={setCurrentViewColumnOrder}
                typeOfLibrary={typeOfLibrary}
                typeOfTable={typeOfTable}
                currentNameviewColumnOrder={currentNameviewColumnOrder}
                setUseOrderColumns={setUseOrderColumns}
                setSelectedFiltersByColumnOrder={
                  setSelectedFiltersByColumnOrder
                }
                setUseFilter={setUseFilter}
                handlerSetValue={handlerSetValue}
              />
            )}
            <div className="container-tools">
              <IconContainer>
                {useFilter || !buttonFilters || !buttonViewMarkersGroup ? (
                  <>
                    <button
                      onClick={() => handlerSetValue()}
                      style={{ background: "transparent" }}
                    >
                      <RestartAltIcon sx={{ fontSize: "3rem" }} />
                      <ClickToolTip title="Reset Filters" placement="top">
                        <ContactSupportIcon
                          sx={{
                            fontSize: "1.6rem",
                            color: `${INFORMATION_COLOR}`,
                            ml: "-5px",
                            mb: "17px",
                            cursor: "pointer",
                          }}
                        />
                      </ClickToolTip>
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {useFilter ? (
                  <>
                    <button
                      onClick={() => {
                        setUseFilter(!useFilter);
                        handlerSetValue();
                      }}
                      style={{ background: "transparent" }}
                    >
                      <FilterAltIcon sx={{ fontSize: "3rem" }} />
                    </button>
                  </>
                ) : (
                  <IconButton style={{ background: "transparent" }}>
                    <button
                      onClick={() => setUseFilter(!useFilter)}
                      style={{ background: "transparent" }}
                    >
                      <FilterAltOffIcon
                        sx={{
                          fontSize: "3rem",
                          color: `${VISIBILITY_OFF_ICON}`,
                        }}
                      />
                    </button>
                  </IconButton>
                )}
                <ClickToolTip
                  title="Decimetrix view filters tools"
                  placement="top"
                >
                  <ContactSupportIcon
                    sx={{
                      fontSize: "1.6rem",
                      color: `${INFORMATION_COLOR}`,
                      ml: "-6px",
                      mb: "15px",
                      cursor: "pointer",
                    }}
                  />
                </ClickToolTip>
                {from === FROM_TABLE.details && detailId && (
                  <>
                    <button
                      onClick={() => setOpenDialogNonGeoreferencedEvent(true)}
                      style={{ background: "transparent" }}
                    >
                      <LocationOnIcon sx={{ fontSize: "3rem" }} />
                    </button>
                    <ClickToolTip
                      title="Change status georeferenced"
                      placement="top"
                    >
                      <ContactSupportIcon
                        sx={{
                          fontSize: "1.6rem",
                          color: `${INFORMATION_COLOR}`,
                          ml: "-6px",
                          mb: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </ClickToolTip>
                  </>
                )}
                {state === false && !lines && (
                  <>
                    <div className="space">
                      {buttonFilters ? (
                        <IconButton style={{ background: "transparent" }}>
                          <button
                            onClick={() => viewFilterObjects()}
                            style={{ background: "transparent" }}
                          >
                            <PublicOffIcon
                              sx={{
                                fontSize: "3rem",
                                color: `${VISIBILITY_OFF_ICON}`,
                              }}
                            />
                          </button>
                        </IconButton>
                      ) : (
                        <IconButton style={{ background: "transparent" }}>
                          <button
                            onClick={() => setViewFilterObjectsInMap(false)}
                            style={{ background: "transparent" }}
                          >
                            <PublicIcon sx={{ fontSize: "3rem" }} />
                          </button>
                        </IconButton>
                      )}
                    </div>

                    <ClickToolTip
                      title={"Override filters in the left panel"}
                      placement="top"
                    >
                      <ContactSupportIcon
                        sx={{
                          fontSize: "1.6rem",
                          color: `${INFORMATION_COLOR}`,
                          ml: "-7px",
                          mb: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </ClickToolTip>
                  </>
                )}
                {state === false && !lines && (
                  <>
                    <div className="space">
                      {buttonViewMarkersGroup ? (
                        <IconButton style={{ background: "transparent" }}>
                          <button
                            onClick={() => setUseMarkPointGroup(true)}
                            style={{ background: "transparent" }}
                          >
                            <WorkspacesIcon
                              sx={{
                                fontSize: "3rem",
                                color: `${VISIBILITY_OFF_ICON}`,
                              }}
                            />{" "}
                          </button>
                        </IconButton>
                      ) : (
                        <IconButton style={{ background: "transparent" }}>
                          <button
                            onClick={() => setUseMarkPointGroup(false)}
                            style={{ background: "transparent" }}
                          >
                            <AnimatedWorkspacesIcon />
                          </button>
                        </IconButton>
                      )}
                    </div>

                    <ClickToolTip
                      title={"Flash animation to mark table data"}
                      placement="top"
                    >
                      <ContactSupportIcon
                        sx={{
                          fontSize: "1.6rem",
                          color: `${INFORMATION_COLOR}`,
                          ml: "-7px",
                          mb: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </ClickToolTip>
                  </>
                )}
                {state === false && lines && (
                  <>
                    <div className="space">
                      {!showFilterLines ? (
                        <IconButton style={{ background: "transparent" }}>
                          <button
                            onClick={() => viewFilterLinesInMap()}
                            style={{ background: "transparent" }}
                          >
                            <PublicOffIcon
                              sx={{
                                fontSize: "3rem",
                                color: `${VISIBILITY_OFF_ICON}`,
                              }}
                            />
                          </button>
                        </IconButton>
                      ) : (
                        <IconButton style={{ background: "transparent" }}>
                          <button
                            onClick={() => {
                              viewFilterLinesInMap();
                            }}
                            style={{ background: "transparent" }}
                          >
                            <PublicIcon sx={{ fontSize: "3rem" }} />
                          </button>
                        </IconButton>
                      )}
                    </div>

                    <ClickToolTip
                      title={
                        // <p style={{ textAlign: "initial" }}>
                        "Override filters in the left panel"
                        // </p>
                      }
                      placement="top"
                    >
                      <ContactSupportIcon
                        sx={{
                          fontSize: "1.6rem",
                          color: `${INFORMATION_COLOR}`,
                          ml: "-7px",
                          mb: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </ClickToolTip>
                  </>
                )}
                {digitalTwin && (
                  <>
                    <button
                      onClick={() => {
                        handleSaveOrderColumn();
                      }}
                      style={{ background: "transparent" }}
                    >
                      <PreviewOutlinedIcon
                        sx={{
                          fontSize: "3rem",
                          color:
                            (useOrderColumns || filterCheckBox.length > 0) &&
                            currentNameviewColumnOrder === "Default Table View"
                              ? SET_VISIBILITY_ICON
                              : currentNameviewColumnOrder ===
                                "Default Table View"
                              ? VISIBILITY_OFF_ICON
                              : "var(--background-primary_color)",
                        }}
                      />
                    </button>
                    {useOrderColumns || filterCheckBox.length > 0 ? (
                      <ClickToolTip
                        title="Click on the icon columns to save current table view"
                        placement="top"
                      >
                        <SaveIcon
                          sx={{
                            fontSize: "1.4rem",
                            color: `${INFORMATION_COLOR}`,
                            ml: "-1px",
                            mb: "15px",
                            cursor: "pointer",
                          }}
                        />
                      </ClickToolTip>
                    ) : (
                      <ClickToolTip
                        title={
                          currentNameviewColumnOrder === "Default Table View"
                            ? "Default table view"
                            : PRIMARY_COLOR === "PRIMARY_COLOR"
                            ? "Custom title when ViewWeekIcon is PRIMARY_COLOR"
                            : "The table view are saved"
                        }
                        placement="top"
                      >
                        <ContactSupportIcon
                          sx={{
                            fontSize: "1.6rem",
                            color: `${INFORMATION_COLOR}`,
                            ml: "-2px",
                            mb: "15px",
                            cursor: "pointer",
                          }}
                        />
                      </ClickToolTip>
                    )}
                  </>
                )}
                {digitalTwin && (
                  <>
                    <button
                      onClick={() => setOpenEditFilterData(true)}
                      style={{ background: "transparent" }}
                    >
                      <AppRegistrationIcon
                        sx={{
                          fontSize: "3rem",
                          color: `${VISIBILITY_OFF_ICON}`,
                          ml: "-3px",
                        }}
                      />
                    </button>
                    <ButtonInfo
                      onClick={() => setMessageEditFilter(!messageEditFilter)}
                      transform={"translate(0%, -50px)"}
                    >
                      <ClickToolTip title="Edit filtered data" placement="top">
                        <ContactSupportIcon
                          sx={{
                            fontSize: "1.6rem",
                            color: `${INFORMATION_COLOR}`,
                            ml: "-3px",
                            mb: "15px",
                          }}
                        />
                      </ClickToolTip>
                    </ButtonInfo>
                  </>
                )}
                {digitalTwin && (
                  <>
                    <button
                      onClick={() => setOpenBulkDelete(true)}
                      style={{ background: "transparent" }}
                    >
                      <DeleteSweepIcon
                        sx={{
                          fontSize: "3rem",
                          color: `${VISIBILITY_OFF_ICON}`,
                          ml: "-3px",
                        }}
                      />
                    </button>
                    <ButtonInfo
                      onClick={() => setMessageEditFilter(!messageEditFilter)}
                      transform={"translate(0%, -50px)"}
                    >
                      <ClickToolTip title="Bulk delete" placement="top">
                        <ContactSupportIcon
                          sx={{
                            fontSize: "1.6rem",
                            color: `${INFORMATION_COLOR}`,
                            ml: "-3px",
                            mb: "15px",
                          }}
                        />
                      </ClickToolTip>
                    </ButtonInfo>
                  </>
                )}
              </IconContainer>
            </div>
          </div>
        </SearchTable>
      )}
      {/* {tableVisibility === "inline" && from === FROM_TABLE.config && ( */}
      {from === FROM_TABLE.config && (
        <ConfigTable
          style={style}
          table={table}
          useFilter={useFilter}
          filterValue={filterValue}
          setFilterCheckBox={setFilterCheckBox}
          filterCheckBox={filterCheckBox}
          handlerClick={handlerClick}
          handlerClickToggleLibrary={handlerClickToggleLibrary}
          columnResizeMode={columnResizeMode}
          dynamicValues={dynamicValues}
          state={state}
          handleGlobalFilterChange={handleGlobalFilterChange}
          buttonFilters={buttonFilters}
          handlerSetValue={handlerSetValue}
          setUseFilter={setUseFilter}
          setMessageInfoFilter={setMessageInfoFilter}
          messageInfoFilter={messageInfoFilter}
        />
      )}
      {from === FROM_TABLE.users && (
        <ManageUsersTable
          register={register}
          style={style}
          table={table}
          useFilter={useFilter}
          filterValue={filterValue}
          setFilterCheckBox={setFilterCheckBox}
          filterCheckBox={filterCheckBox}
          columnResizeMode={columnResizeMode}
          state={state}
          handleGlobalFilterChange={handleGlobalFilterChange}
          buttonFilters={buttonFilters}
          handlerSetValue={handlerSetValue}
          setUseFilter={setUseFilter}
          setRows={setRows}
          rows={rows}
          valuesNoSessions={valuesNoSessions}
          setValuesNoSessions={setValuesNoSessions}
          valuesNoSessionsMobile={valuesNoSessionsMobile}
          setValuesNoSessionsMobile={setValuesNoSessionsMobile}
        />
      )}
      {from === FROM_TABLE.allowAccessUsers && (
        <AllowAccessUsersTable
          style={style}
          table={table}
          useFilter={useFilter}
          filterValue={filterValue}
          setFilterCheckBox={setFilterCheckBox}
          filterCheckBox={filterCheckBox}
          columnResizeMode={columnResizeMode}
          state={state}
          handleGlobalFilterChange={handleGlobalFilterChange}
          buttonFilters={buttonFilters}
          handlerSetValue={handlerSetValue}
          setUseFilter={setUseFilter}
        />
      )}
      {/* {tableVisibility && from !== FROM_TABLE.config && ( */}
      {from !== FROM_TABLE.config &&
        from !== FROM_TABLE.users &&
        from !== FROM_TABLE.allowAccessUsers && (
          <div>
            <Divider />
            <ContentTable
              className="background-row-2 color1"
              maxHeight={style?.maxHeight}
            >
              <table
                {...{
                  style: {
                    width: customTableWidth || "100vw",
                  },
                }}
              >
                <thead>
                  {table?.getHeaderGroups()?.map((headerGroup) => (
                    <tr
                      style={{ fontFamily: document.body.style.fontFamily }}
                      key={headerGroup.id}
                    >
                      {headerGroup.headers.map((header) => {
                        const customWidth = customHeaderWidth || "100vw";
                        return (
                          <DraggableColumnHeader
                            key={header.id}
                            header={header}
                            table={table}
                            customWidth={customWidth}
                            columnFilter={header?.column}
                            setValue={filterValue}
                            setFilterCheckBox={setFilterCheckBox}
                            filterCheckBox={filterCheckBox}
                            setDateFilters={setDateFilters}
                            dateFilters={dateFilters}
                            useFilter={useFilter}
                            columnResizeMode={columnResizeMode}
                            headers={headers}
                            keyValues={keyValues}
                            rowsDynamic={rowsDynamic}
                            setUseOrderColumns={setUseOrderColumns}
                            rows={rows}
                            typeOfLibrary={typeOfLibrary}
                            dataChecked={dataChecked}
                            setDataChecked={setDataChecked}
                            from={from}
                            selectedFiltersByColumnOrder={
                              selectedFiltersByColumnOrder
                            }
                            currentNameviewColumnOrder={
                              currentNameviewColumnOrder
                            }
                            typeOfTable={typeOfTable}
                            numericFields={numericFields}
                            isLine={lines}
                            pointLibraryId={pointLibraryId}
                            fieldOrderMap={fieldOrderMap}
                          />
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      style={{
                        fontFamily: document.body.style.fontFamily,
                      }}
                      className={
                        index % 2 !== 0
                          ? "background-row-1 color1"
                          : "background-row-2 color1"
                      }
                    >
                      {row.getVisibleCells().map((cell) => {
                        if (
                          cell.getContext().column.columnDef.header().props
                            .children === "UNIC ID" ||
                          cell.getContext().column.columnDef.header().props
                            .children === "Id" ||
                          (from === "videoManager" &&
                            cell.getContext().column.columnDef.header().props
                              .children === "First Name") ||
                          (from === "uploadVideos" &&
                            cell.getContext().column.columnDef.header().props
                              .children === "Event ID")
                        )
                          return (
                            <td
                              {...{
                                key: cell.id,
                                style: {
                                  minWidth: `${cell.column.getSize()}px`,
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() =>
                                handlerClick(
                                  row.original.id,
                                  dynamicValues[row.original.id]
                                )
                              }
                            >
                              {flexRender(cell.getValue(), cell.getContext())}
                            </td>
                          );
                        if (
                          flexRender(cell.getValue(), cell.getContext()) ===
                            null &&
                          cell.getValue() === undefined
                        )
                          return (
                            <td
                              {...{
                                key: cell.id,
                                style: {
                                  minWidth: `${cell.column.getSize()}px`,
                                },
                              }}
                            >
                              <HorizontalRuleIcon />
                            </td>
                          );

                        if (
                          isUrl(flexRender(cell.getValue(), cell.getContext()))
                        )
                          return (
                            <td
                              {...{
                                key: cell.id,
                                style: {
                                  minWidth: `${cell.column.getSize()}px`,
                                },
                              }}
                            >
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  fontSize: "inherit",
                                }}
                                onClick={(e) =>
                                  handleClickLink(
                                    e,
                                    flexRender(
                                      cell.getValue(),
                                      cell.getContext()
                                    )
                                  )
                                }
                              >
                                {nameUrl(
                                  flexRender(cell.getValue(), cell.getContext())
                                )}
                              </button>
                              {/* <a
                              href={flexRender(
                                cell.getValue(),
                                cell.getContext()
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {nameUrl(
                                flexRender(cell.getValue(), cell.getContext())
                              )}
                            </a> */}
                            </td>
                          );
                        if (
                          cell.getContext().column.columnDef.header().props
                            .children === "Object ID"
                        )
                          return (
                            <td
                              {...{
                                key: cell.id,
                                style: {
                                  minWidth: `${cell.column.getSize()}px`,
                                },
                              }}
                            >
                              <p
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handlerClickObjectId(
                                    flexRender(
                                      cell.getValue(),
                                      cell.getContext()
                                    )
                                  )
                                }
                              >
                                {flexRender(cell.getValue(), cell.getContext())}
                              </p>
                            </td>
                          );
                        if (
                          cell.getContext().column.columnDef.header().props
                            .children === "Object Id Initial" ||
                          cell.getContext().column.columnDef.header().props
                            .children === "Object Id Final"
                        )
                          return (
                            <td
                              {...{
                                key: cell.id,
                                style: {
                                  minWidth: `${cell.column.getSize()}px`,
                                },
                              }}
                            >
                              <p
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  flexRender(
                                    cell.getValue(),
                                    cell.getContext()
                                  ) &&
                                    handlerClickObjectId(
                                      flexRender(
                                        cell.getValue(),
                                        cell.getContext()
                                      )
                                    );
                                }}
                              >
                                {flexRender(
                                  cell.getValue(),
                                  cell.getContext()
                                ) ?? NaN}
                              </p>
                            </td>
                          );
                        if (
                          cell.getContext().column.columnDef.header().props
                            .children === "Relation Id"
                        )
                          return (
                            <td
                              {...{
                                key: cell.id,
                                style: {
                                  minWidth: `${cell.column.getSize()}px`,
                                },
                              }}
                            >
                              {relationTypeColor({
                                handlerClickObjectId,
                                handlerClickComponentId,
                                handlerClickEventId,
                                relationId: flexRender(
                                  cell.getValue(),
                                  cell.getContext()
                                ),
                              })}
                            </td>
                          );
                        if (
                          cell.getContext().column.columnDef.header().props
                            .children === "Severity Value"
                        )
                          return (
                            <td
                              key={cell.id}
                              style={{
                                minWidth: `${cell.column.getSize()}px`,
                                background: (() => {
                                  const value = flexRender(
                                    cell.getValue(),
                                    cell.getContext()
                                  );
                                  return value === 1
                                    ? "#FF0000"
                                    : value === 2
                                    ? "#FFFF00"
                                    : value === 3
                                    ? "#92D050"
                                    : value === 4
                                    ? "#B4C6E7"
                                    : "#FFFFFF";
                                })(),
                              }}
                            >
                              {flexRender(cell.getValue(), cell.getContext()) ||
                                cell.getValue()}
                            </td>
                          );
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                minWidth: `${cell.column.getSize()}px`,
                              },
                            }}
                          >
                            {flexRender(cell.getValue(), cell.getContext()) ||
                              cell.getValue()}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="h-2" />
            </ContentTable>
            <FooterTools table={table} />
          </div>
        )}
    </div>
  );
};

GenericTable.defaultProps = {
  handlerDelete: () => {},
  handlerClick: () => {},
};

export default GenericTable;
