import { useState, useEffect } from "react";
import { useSWRConfig } from "swr";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

// Components
import OpenDialog from "components/Dialogs/OpenDialog";
import ContentDialog from "./ContentDialog";
// Services
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
import uploadFilePointEvent, {
  saveCaptureMedia,
} from "services/uploadFilePointEvent";
// Styles
import { ContentButtonGallery, Label } from "../EventDetailsElements";
// Config
import { config } from "config.js";
import { setUpdateAtEvent } from "services/savePointEvent";
import { pathFolder } from "utils/paths";
import { getPathBucket } from "helpers/getPathBucket";

const AddPicture = ({ event }) => {
  const [photosFile, setPhotosFile] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [typeEventMedia, setTypeEventMedia] = useState(null);
  const { mutate } = useSWRConfig();

  useEffect(() => {
    const typeMedia = event?.fieldsCaptureMedia[0]?.name;
    setTypeEventMedia(typeMedia);
  }, [event]);

  const uploadFile = async (e) => {
    const files = e.target.files;
    Object.keys(files).forEach((key) => {
      setPhotosFile((current) => [...current, files[key]]);
    });
    Object.keys(files).forEach((key) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[key]);
      reader.onload = () => {
        setPhotos((current) => [reader.result, ...current]);
      };
    });
    setOpenModal(true);
  };

  const savePhotos = async () => {
    const folderPath = getPathBucket(
      pathFolder.events.points.pictures(typeEventMedia)
    );

    return Promise.all(
      photosFile.map(async (file) => {
        const { fileUrl } = await uploadFileToS3({ file, folderPath });
        if (typeEventMedia === null || typeEventMedia === undefined) {
          await uploadFilePointEvent({
            pointEventId: event.id,
            eventFileTypeId: 1,
            url: fileUrl,
          });
        } else {
          const aliasFieldMedia = event?.fieldsCaptureMedia.find(
            (item) => item.name === typeEventMedia
          ).alias;

          await saveCaptureMedia({
            pointEventMgId: event.mongoId,
            media: [
              {
                type: typeEventMedia,
                alias: aliasFieldMedia,
                url: fileUrl,
              },
            ],
          });
        }
      })
    ).then(() => {
      setOpenModal(false);
      setPhotosFile([]);
      setPhotos([]);
      setUpdateAtEvent(event.id);
      // Mutate
      const urlEventObject = `${config.URL_BACKEND_PG}api/v1/point-events?id=${event.id}`;
      mutate(urlEventObject);
      const urlEventComponent = `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${event.id}`;
      mutate(urlEventComponent);
      const urlEventRelation = `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${event.id}`;
      mutate(urlEventRelation);
    });
  };

  return (
    <>
      <ContentButtonGallery>
        <Label htmlFor="files">
          <AddAPhotoIcon />
          <p>Add Pictures</p>
        </Label>
        <input
          id="files"
          style={{ visibility: "hidden" }}
          accept="image/*"
          type="file"
          name="file"
          multiple
          onChange={uploadFile}
        />
      </ContentButtonGallery>
      <OpenDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        content={{
          title: "Upload pictures",
          description: (
            <ContentDialog
              pictures={photos}
              event={event}
              typeEventMedia={typeEventMedia}
              setTypeEventMedia={setTypeEventMedia}
            />
          ),
          agree: "Save",
          disagree: "Cancel",
        }}
        execute={savePhotos}
        disagree={() => {
          setPhotos([]);
          setPhotosFile([]);
        }}
      />
    </>
  );
};

export default AddPicture;
