import React, { useEffect, useRef, useState } from "react";
import {
  CardContainer,
  HubCard,
  HubContainer,
  HubH2,
  HubP,
  HubWrapper,
} from "./HubStyles";
import useGet from "hooks/useFetch";
import { useNavigate } from "react-router-dom";
import contentCards from "pages/ConfigAdmin/contentCardsHub";
import CustomTabs from "./CustomTabs";
import DescriptionHub from "./DescriptionHub";
import Layout from "components/Layout/AdminDecimetrix";
import AvailableModules from "./AvailableModules";
import { ShowAllButton2 } from "pages/PlatformSetUp/Preferences/preferencesstyles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { urls } from "utils/urlKeys";

function ConfigCards({ hideCards }) {
  const cardContainerRef = useRef(null);
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    //event.preventDefault();
    setValue(newValue);
  };

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (value === 0) {
      localStorage.setItem('selectedSettingsCategory', "");
    } else if (value === 1) {
      localStorage.setItem('selectedSettingsCategory', "No code development");
    }
  }, [value]);

  const handleMouseDown = (event) => {
    if (!cardContainerRef.current) return;
    setIsDragging(true);
    setStartX(event.pageX - cardContainerRef.current.offsetLeft);
    setScrollLeft(cardContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!cardContainerRef.current) return;
    if (!isDragging) return;
    const x = event.pageX - cardContainerRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    cardContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const [adminCompany] = useGet(urls.adminCompany.detail);

  const [adminDecimetrix] = useGet(urls.adminDecimetrix.detail);
  const navigation = (path) => {
    navigate(path);
    localStorage.setItem('selectedSettingsCategory', "");
  };

  return (
    <>
      <Layout>
        <DescriptionHub
          adminCompany={adminCompany}
          adminDecimetrix={adminDecimetrix}
        />
        <CustomTabs value={value} onChange={handleChange} />
        <HubContainer className="background-2 border-top-1">

          <CardContainer
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {!hideCards && value === 1 && (
              <>
                <HubWrapper ref={cardContainerRef} className="wrap-style">
                  {contentCards.map((item) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <HubCard
                        className="background-card-color"
                        key={item.id}
                        onClick={() => navigate(item.toNavigate)}
                      >
                        <HubH2>{item.title}</HubH2>
                        {item.image}
                        <HubP>{item.description}</HubP>
                      </HubCard>
                    </div>
                  ))}
                </HubWrapper>
              </>
            )}
            {value === 0 && <AvailableModules />}
          </CardContainer>
        </HubContainer>
      </Layout>
    </>
  );
}

export default ConfigCards;
