import useSwr from "swr";
import { urls } from "utils/urlKeys";

/**
 * Fetches constant names from a specified URL using the useSwr hook.
 * Returns the fetched data and any error that occurred during the fetch.
 * @param {idDecimetrix} idDecimetrix for getting specific constants names
 * @returns {Object} An object containing the fetched data and any error.
 *                   - data: An array of the constant names strings.
 *                   - error: Any error that occurred during the fetch request.
 *
 * @example
 * const { data, error } = useGetConstantNames();
 * // Use the fetched data and handle any error
 * ["constantName","constantName","constantName"]
 */
const useGetConstantNames = ({ idDecimetrix }) => {
  // if (parseInt(id) < 0) return { data: []} ;
  const urlParams = new URLSearchParams();
  urlParams.append("getNames", true);
  if (idDecimetrix) urlParams.append("adminDecimetrixId", idDecimetrix);
  const url = urls.globalConstants(`${urlParams.toString()}`);
  //const url = `${config.URL_BACKEND_PG}api/v1/global-constants?getNames=true&adminCompanyId=${adminid}`;
  const { data, error } = useSwr(url);
  return { data, error };
};

export default useGetConstantNames;
