import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
import { config, getBucketNameEnv } from "config.js";
import {
  FormContainer,
  WrapperForm,
  Form,
  // ContainerButtonCenter,
  // ContainerButtonBottom,
  ContainerButtonRegister,
  // ContainerButtonBack,
  Button,
  MessageError,
  MessageSuccess,
} from "components/Forms/FormStyles";

import InputComponent from "components/Forms/Input";

import { createBucket } from "services/s3Manager/s3Manager";
import { formatCompanyName } from "./helpers";

// import { StyleColor2 } from "Styles";

// const steps = [
//   "User details",
//   "Company and Legal Resp.",
//   "Responsible details",
//   "Oil Engineer details",
// ];

const FormSignUp = ({ handleClose }) => {
  const [firstName, setFirstName] = useState({ field: "", valid: null });
  const [secondName, setSecondName] = useState({ field: "", valid: null });
  const [firstLastName, setFirstLastName] = useState({
    field: "",
    valid: null,
  });
  // const { theme } = useContext(ThemeContext);
  // const [company, setCompany] = useState({ field: "", valid: null });
  const [email, setEmail] = useState({ field: "", valid: null });
  const [area, setArea] = useState({ field: "", valid: null });
  const [project, setProject] = useState({ field: "", valid: null });
  const [FormValidated, setFormValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");
  const token = localStorage.getItem("token");
  const [messageError, setMessageError] = useState("");
  const [nameCompany, setNameCompany] = useState({ field: "", valid: null });

  const expressions = {
    firstName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    firstLastName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    secondName: /^[a-zA-ZÀ-ÿ]{0,40}$/, // letters only
    // company: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    area: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    project: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    password: /^.{8,60}$/, // 8 a 60 characters.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    nameCompany: /^[a-zA-Z\s]{1,100}$/,
  };

  const saveUser = async (body) => {
    try {
      const res = await fetch(`${config.URL_BACKEND_PG}api/v1/admin-company`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify(body),
      });
      await res.json();
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //check the request fields filled

    //array to store the names of the fields that are not filled

    const missingFields = [];

    //check if the fields are filled
    if (!firstName.field) {
      missingFields.push("First Name");
    }
    if (!firstLastName.field) {
      missingFields.push("Last Name");
    }
    // if (!company.field) {
    //   missingFields.push("Company");
    // }

    if (!email.field) {
      missingFields.push("Email");
    }

    if (!area.field) {
      missingFields.push("Area");
    }
    if (!project.field) {
      missingFields.push("Project");
    }

    //required fields for company
    if (!nameCompany.field) {
      missingFields.push("Company Name");
    }

    //build the message with the missing fields

    let errorMessage = "";
    if (missingFields.length > 0) {
      errorMessage = `THE FOLLOWING FIELDS ARE REQUIRED: ${missingFields.join(
        " - "
      )}`;
    }

    //update messageError
    setMessageError(errorMessage);

    if (errorMessage) {
      setFormValidated(false);
      setLoading(false);
      return;
    }

    if (
      firstName.valid === "true" &&
      firstLastName.valid === "true" &&
      // company.valid === "true" &&
      nameCompany.valid === "true" &&
      email.valid === "true" &&
      area.valid === "true" &&
      project.valid === "true"
    ) {
      const body = {
        firstName: firstName.field,
        secondName: secondName.field,
        firstLastName: firstLastName.field,
        area: area.field,
        project: project.field,
        adminDecimetrixId: adminDecimetrixId,
        user: {
          email: email.field,
        },
        company: {
          name: nameCompany.field,
        },
      };

      const bucketName = getBucketNameEnv();

      let responseCreateBucket;
      if (bucketName === "bucket-production") {
        responseCreateBucket = await createBucket({
          bucketName: formatCompanyName(nameCompany.field),
          folderName: email.field,
        });
      } else {
        responseCreateBucket = await createBucket({
          bucketName: bucketName,
          folderName: `${formatCompanyName(nameCompany.field)}/${email.field}`,
        });
      }

      if (responseCreateBucket) {
        const res = await saveUser(body);
        setLoading(false);
        if (res.status === 409) {
          setFormValidated(false);
          setMessageError("This email is already registered");
        } else if (res.status === 201) {
          handleClose();
          setFormValidated(true);
          setMessageError(null);
          setFirstName({ field: "", valid: null });
          setSecondName({ field: "", valid: null });
          setFirstLastName({ field: "", valid: null });
          setEmail({ field: "", valid: null });
          setArea({ field: "", valid: null });
          setProject({ field: "", valid: null });
          setNameCompany({ field: "", valid: null });
        } else {
          setFormValidated(false);
          setMessageError("Filld all the fields correctly.");
        }
      } else {
        setFormValidated(false);
        setMessageError("Error saving, try again");
      }
    } else {
      setFormValidated(false);

      setMessageError("Filld all the fields correctly.2"); ///
    }
    setLoading(false);
  };

  //que las box sean responsive
  return (
    <>
      <br></br>
      <FormContainer>
        <WrapperForm style={{ marginTop: "1rem" }}>
          <Form action="" onSubmit={onSubmit}>
            <>
              <>
                <InputComponent
                  state={firstName}
                  changeState={setFirstName}
                  type="text"
                  label="First Name *"
                  name="firstName"
                  legendError="The name can only contain letters."
                  regularExpression={expressions.firstName}
                />
                <InputComponent
                  state={secondName}
                  changeState={setSecondName}
                  type="text"
                  label="Second Name"
                  name="secondName"
                  legendError="The name can only contain letters."
                  regularExpression={expressions.secondName}
                />
                <InputComponent
                  state={firstLastName}
                  changeState={setFirstLastName}
                  type="text"
                  label="Last Name *"
                  name="firstLastName"
                  legendError="The last name can only contain letters."
                  regularExpression={expressions.firstLastName}
                />
                {/* <InputComponent
                    state={company}
                    changeState={setCompany}
                    type="text"
                    label="Company *"
                    name="company"
                    legendError="The company can only contain letters, numbers, periods, hyphens and underscores."
                    regularExpression={expressions.company}
                  /> */}
                <InputComponent
                  state={nameCompany}
                  changeState={setNameCompany}
                  type="text"
                  label="Company Name *"
                  name="nameCompany"
                  legendError="The company name can only contain letters."
                  regularExpression={expressions.nameCompany}
                />
                <InputComponent
                  state={email}
                  changeState={setEmail}
                  type="email"
                  label="E-mail *"
                  placeholder="email@email.com"
                  name="email"
                  legendError="Email can only contain letters, numbers, periods, hyphens, and underscores."
                  regularExpression={expressions.email}
                />
                <InputComponent
                  state={area}
                  changeState={setArea}
                  type="text"
                  label="Area *"
                  placeholder=""
                  name="area"
                  legendError="The area can only contain letters."
                  regularExpression={expressions.area}
                />
                <InputComponent
                  state={project}
                  changeState={setProject}
                  type="project"
                  label="Project *"
                  placeholder=""
                  name="project"
                  legendError="The project can only contain letters."
                  regularExpression={expressions.project}
                />
              </>
            </>
          </Form>

          <>
            <ContainerButtonRegister>
              <Button type="submit" onClick={onSubmit}>
                Register
              </Button>
              {loading && (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
            </ContainerButtonRegister>
          </>

          {FormValidated ? (
            <MessageSuccess>
              <p>User saved successfully</p>
            </MessageSuccess>
          ) : messageError !== "" ? (
            <MessageError>
              <p>{messageError}</p>
            </MessageError>
          ) : null}
        </WrapperForm>
      </FormContainer>
    </>
  );
};

export default FormSignUp;
