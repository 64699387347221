// React
import { useContext, useEffect, useState } from "react";
// Components
import TextInput from "../GenericInput";
import SelectInput from "components/Forms/SelectInput";
import { ContentButton } from "components/Buttons/CustomButtonStyle";
import { Form } from "../SaveObject/DynamicFieldsStyle";
// Services
import uploadFileToS3 from "services/uploadFileToS3sdk";
import CustomButton from "components/Buttons/CustomButton";
import { updateAttributesComponentMg } from "services/updateAttributesMg";
import savePointComponentApiPg, {
  createPointComponent,
  setUpdateAtComponent,
} from "services/savePointComponent";
// Hooks
import { useForm } from "react-hook-form";
import useGet from "hooks/useFetch";
// eslint-disable-next-line no-unused-vars
import operationsExp from "hooks/useOperations";
import { useFetchComponentsLibraries } from "hooks/fetchLibraries";
// Dependecies
import useSwr from "swr";
import { useSWRConfig } from "swr";
// styles
import { Currency } from "./DynamicFieldsStyles";
import { StepSection } from "../Events/DynamicEventStyles";
// Config
import { config } from "config.js";
import CircularIndeterminate from "components/Lodings/LoadingV2";
import { isValidJSON } from "helpers/expressions/expressionHelpers";
import { urlKeys } from "utils/urlKeys";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  BLACK_COLOR,
  WHITE_COLOR,
} from "utils/const";
import { ThemeContext } from "App";
import { LineConteiner2 } from "pages/ConfigAdmin/typeElemts/FieldsStyle";
import { useDispatch } from "react-redux";
import { setMessageLoadingMap } from "redux/actions/digitalTwin";

export default function DynamicFields({
  feature,
  objectId,
  closeModal,
  pointTypeComponentId,
  dataComponent,
  update = false,
  setOpenDialog,
  handleUpdateData,
}) {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataFieldsTypeComponents, setDataFieldsTypeComponents] = useState();
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlDynamicComponents = `${config.URL_BACKEND_PG}api/v1/point-components?objectId=${objectId}`;
  //Show component in map after save it in the database and update the map
  const componentMap = `${config.URL_BACKEND_PG}api/v1/point-components?objectId=${objectId}&adminCompanyId=${adminCompanyId}`;

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/admin-company/operators/${adminCompanyId}`
  );

  const { data: dataLibrary, error: errorLibrary } =
    useFetchComponentsLibraries({
      id: dataComponent?.pointTypeComponent?.pointLibraryComponentId,
    });
  // Find type components to type elements.
  const [fieldsTypeComponents, errorFieldsTypeComponents] = useGet(
    `api/v1/point-type-components?id=${
      pointTypeComponentId || dataComponent?.pointTypeComponentId
    }`
  );

  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    setForm({});
    setDataFieldsTypeComponents();
    fieldsTypeComponents &&
      fieldsTypeComponents?.[0]?.fields?.forEach((field) => {
        setForm((current) => ({
          ...current,
          [field.name]: "",
        }));
      });
    fieldsTypeComponents &&
      setDataFieldsTypeComponents(fieldsTypeComponents?.[0]);
  }, [fieldsTypeComponents, errorFieldsTypeComponents]);

  useEffect(() => {
    pointTypeComponentId && reset();
    dataComponent && reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointTypeComponentId, dataComponent?.pointTypeComponentId]);

  useEffect(() => {
    !errorLibrary &&
      dataComponent?.attributes.forEach((field) => {
        const matchAlias = dataLibrary?.[0]?.fields.find(
          (fields) => fields.name === field.name || fields.alias === field.name
        );
        if (matchAlias) {
          field.name = matchAlias.name;
          field.alias = matchAlias.alias;
        }
      });

    if (update && dataComponent) {
      const existingFieldNames = dataComponent?.attributes?.map(
        (attribute) => attribute.name
      );
      !errorLibrary &&
        dataLibrary?.[0]?.fields?.forEach((field) => {
          if (
            !existingFieldNames.includes(field.name) &&
            field.type !== "notification" &&
            field.type !== "captureMedia" &&
            field.type !== "lineTag"
          ) {
            const newAttribute = {
              name: field.name,
              alias: field.alias,
              value: "",
            };
            dataComponent.attributes.push(newAttribute);
          }
        });

      dataComponent?.attributes?.forEach((elm) => {
        if (elm?.value?.[0] === "{") {
          const dataParse = JSON.parse(elm.value);
          if (dataParse.type === "number" && dataParse.value) {
            setValue(elm.name || elm.alias, parseFloat(dataParse.value));
          }
        } else {
          if (elm.value === "true" || elm.value === "false") {
            return setValue(elm.name || elm.alias, JSON.parse(elm.value));
          }
          const value =
            elm?.value?.length === 0 || elm?.value === " "
              ? ""
              : !isNaN(elm.value)
              ? parseFloat(elm.value)
              : elm.value;
          setValue(elm.name, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataComponent]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (dataFieldsTypeComponents) {
      const initialForm = {};
      dataFieldsTypeComponents?.fields?.forEach((field) => {
        if (field?.type === "check" && !update) {
          initialForm[field?.name] = "false";
          return;
        } else {
          initialForm[field?.name] = "";
          return;
        }
      });
      setForm(initialForm);
    }
  }, [dataFieldsTypeComponents]);

  const createBody = async ({ fileFields, data }) => {
    const newForm = {};
    Object.keys(form).forEach((key) => {
      const value = form[key];
      if (value !== "") newForm[key] = value;
      if (Object.keys(data).includes(key) && data[key] === "")
        newForm[key] = "";
    });

    const files = await Promise.all(
      fileFields.map(async (fileField) => {
        const files = data[fileField.name];
        const urlFile =
          files[0] !== undefined && (await uploadFileToS3(files[0], 8, files));
        const newData = {
          [fileField.name]: urlFile ? urlFile : "No File(s)",
        };
        return newData;
      })
    );

    const updatedForm = {};
    for (const key in newForm) {
      if (newForm.hasOwnProperty(key)) {
        const file = files.find((fileObj) => fileObj[key] !== undefined);
        if (file) {
          updatedForm[key] = file[key];
        } else {
          updatedForm[key] = newForm[key];
        }
      }
    }

    let updatedFormWithAlias = [];
    for (const key in newForm) {
      const obj = {};
      if (newForm.hasOwnProperty(key)) {
        const file = files.find((fileObj) => fileObj[key] !== undefined);
        if (file) {
          obj.name = key;
          obj.value = file[key];
        } else {
          obj.name = key;
          obj.value = newForm[key];
        }
      }
      updatedFormWithAlias.push(obj);
    }

    updatedFormWithAlias.forEach((field) => {
      if (update) {
        const matchAlias = dataComponent?.attributes.find(
          (fields) => fields.name === field.name
        );
        if (matchAlias) {
          field.alias = matchAlias?.alias;
          field._id = matchAlias?._id || null;
        }
      } else {
        const matchAlias = dataFieldsTypeComponents?.fields.find(
          (fields) => fields.name === field.name
        );
        if (matchAlias) {
          field.alias = matchAlias.alias;
        }
      }
    });
    return updatedFormWithAlias;
  };

  const handleSave = async (data) => {
    setLoading(true);
    if (update) {
      const numericFields = dataFieldsTypeComponents?.fields
        ?.map((field) => {
          if (field.type === "number") {
            const entries = Object.entries(data);
            for (const [key, value] of entries) {
              if (key === field.name) {
                return { ...field, value: value === "" ? NaN : value };
              }
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = dataFieldsTypeComponents.fields?.filter(
        (field) => field.type === "operation"
      );

      const fileFields = dataFieldsTypeComponents.fields?.filter(
        (field) => field.type === "file"
      );
      let newForm = await createBody({ fileFields, data });
      if (numericFields.length > 0) {
        numericFields.forEach((fld) => {
          const searching = dataComponent.attributes.find(
            (it) => it.name === fld.name || it.name === fld.alias
          );
          if (searching) {
            newForm.push({
              name: searching.name,
              value: JSON.stringify(fld),
              alias: searching.alias,
              _id: searching._id,
            });
          } else {
            newForm.push({
              name: fld.name,
              value: JSON.stringify(fld),
              alias: fld.alias,
            });
          }
        });
      }

      if (expFields.length > 0) {
        let searching = {};
        expFields.forEach((fld) => {
          const tmp = JSON.stringify(fld);
          searching = dataComponent.attributes.find(
            (it) => it.name === fld.name || it.name === fld.alias
          );
          if (searching) {
            newForm.push({
              ...searching,
              value: tmp,
            });
          }
          dataComponent.attributes.push({
            name: fld.name,
            value: tmp,
            alias: fld.alias,
          });
        });
      }

      const objetoMap = new Map();
      //compare equal number fields and value if object parsed
      newForm.forEach((objeto) => {
        if (objetoMap.has(objeto.name)) {
          const objetoExistente = objetoMap.get(objeto.name);
          const objetoActual = objeto;
          if (isValidJSON(objetoActual.value)) {
            objetoMap.set(objeto.name, objetoActual);
          } else {
            objetoMap.set(objeto.name, objetoExistente);
          }
        } else {
          objetoMap.set(objeto.name, objeto);
        }
      });
      newForm = [...objetoMap.values()];
      const form = newForm?.filter((form) => form.value !== "");

      await updateAttributesComponentMg(dataComponent.mongoId, form);

      await setUpdateAtComponent(dataComponent.id);

      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-components?id=${dataComponent.id}`
      );
      mutate(urlKeys.components);

      handleUpdateData();
      setOpenDialog(false);
    } else {
      /*agregando opeations */
      let elmt = "";
      const numericFields = dataFieldsTypeComponents.fields
        ?.map((field) => {
          elmt =
            document.getElementById(field.alias) ||
            document.getElementById(field.name);
          if (elmt && elmt.type === "number") {
            field.value = elmt.value;
            return field;
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = dataFieldsTypeComponents.fields?.filter(
        (field) => field.type === "operation"
      );

      const fileFields = dataFieldsTypeComponents.fields?.filter(
        (field) => field.type === "file"
      );

      const newForm = await createBody({ fileFields, data });
      const component = createPointComponent(
        feature,
        newForm,
        objectId,
        pointTypeComponentId
      );

      if (numericFields.length > 0) {
        let searching = {};
        numericFields.forEach((fld) => {
          const tmp = JSON.stringify(fld);
          searching = component.attributes.find(
            (it) => it.name === fld.name || it.name === fld.alias
          );
          if (searching) {
            component.attributes[component.attributes.indexOf(searching)] = {
              name: fld.name,
              value: tmp,
              alias: fld.alias,
            };
            return null;
          }
          component.attributes.push({
            name: fld.name,
            value: fld.value,
            alias: fld.alias,
          });
        });
      }
      if (expFields.length > 0) {
        let searching = {};
        expFields.forEach((fld) => {
          const tmp = JSON.stringify(fld);
          searching = component.attributes.find(
            (it) => it.name === fld.name || it.name === fld.alias
          );
          if (searching) {
            component.attributes[component.attributes.indexOf(searching)] = {
              name: fld.name,
              value: tmp,
              alias: fld.alias,
            };
            return null;
          }
          component.attributes.push({
            name: fld.name,
            value: tmp,
            alias: fld.alias,
          });
        });
      }
      //filtering empty values while creating the components
      component.attributes = component.attributes.filter(
        (it) => it.value !== ""
      );

      await savePointComponentApiPg(component);
      mutate(componentMap);
      mutate(urlKeys.components);
      mutate(urlKeys.componentsWeb);
      mutate(urlDynamicComponents);
      closeModal(false);
      dispatch(setMessageLoadingMap(true));
    }
    setLoading(false);
    reset();
  };

  if (dataFieldsTypeComponents === undefined) {
    return <CircularIndeterminate />;
  }

  return (
    <Form
      onSubmit={handleSubmit(handleSave)}
      style={{
        background:
          theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
        color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
      }}
    >
      {dataFieldsTypeComponents &&
        Object.keys(form).length > 0 &&
        dataFieldsTypeComponents.fields?.map((field, index) => {
          if (field.type === "select") {
            if (!update && form[field.name] === "") {
              form[field.name] = field.options[0];
            }
            return (
              <div key={index} onChange={(e) => handleChange(e)}>
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    options: field.options,
                    required: field.required,
                    type: field.type,
                    alias: field.alias,
                  }}
                  register={register}
                />
              </div>
            );
          }
          if (field.type === "check") {
            return (
              <div onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    required: field.required,
                    type: field.type,
                    alias: field.alias,
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          }
          if (field.type === "file") {
            return (
              <div onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    required: update ? false : field.required,
                    type: field.type,
                    possible: field.possible,
                    alias: field.alias,
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          }
          if (field.type === "range") {
            const step =
              field?.options?.length !== 0 ? parseInt(field.options[0]) : 1;
            if (!form[field.name]) {
              if (!update) {
                const middleValue = field?.min;
                form[field?.name] = middleValue.toString();
              } else {
                const value = dataComponent?.attributes.find(
                  (it) => it.name === field.name
                );
                if (value) {
                  form[field?.name] = value?.value;
                }
              }
            }
            return (
              <div key={index} onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    alias: field.alias,
                    required: field.required,
                    type: field.type,
                    step: step,
                    max: field.max,
                    min: field.min,
                  }}
                  register={register}
                  errors={errors}
                />
                <StepSection>
                  <b>Step: {step}</b>
                  <b>
                    {form[field.name] ? form[field.name] : field.min + step}
                  </b>
                </StepSection>{" "}
              </div>
            );
          }
          if (field.type === "currency") {
            if (!form.Currency) {
              setForm({ ...form, Currency: field.possible[0] });
            }
            return (
              <Currency onChange={(e) => handleChange(e)}>
                <TextInput
                  key={`${index + 1}`}
                  field={{
                    name: field.name,
                    required: field.required,
                    alias: field.alias,
                    type: field.type,
                  }}
                  register={register}
                  errors={errors}
                />
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    possible: field.possible,
                    required: field.required,
                    type: field.type,
                    alias: field.alias,
                  }}
                  register={register}
                />
              </Currency>
            );
          }
          if (field.type === "delegate") {
            if (
              !update &&
              form[field.name] === "" &&
              operatorsAdmin &&
              !errorOperators
            ) {
              form[
                field.name
              ] = `${allOperators?.operators?.[0]?.firstName} ${allOperators?.operators?.[0]?.firstLastName}`;
            }
            return (
              <div onChange={(e) => handleChange(e)}>
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    options: allOperators?.operators.map((operator) => {
                      return `${operator?.firstName} ${operator?.firstLastName}`;
                    }),
                    required: field.required,
                    type: field.type,
                    alias: field.alias,
                  }}
                  register={register}
                />
              </div>
            );
          }

          if (field.type === "lineTag") {
            return (
              <LineConteiner2>
                <hr />
                <h3>{field.alias}</h3>
                <hr />
              </LineConteiner2>
            );
          }
          if (field.type === "operation") {
            return (
              <TextInput
                key={index}
                field={field}
                register={register}
                errors={errors}
              />
            );
          }
          if (field.type === "captureMedia") return null;
          if (field.type === "notification") return null;
          if (field.type === "lineTag") return null;
          return (
            <div onChange={(e) => handleChange(e)}>
              <TextInput
                key={index}
                field={{
                  name: field.name,
                  required: field.required,
                  type: field.type,
                  possible: field.possible,
                  alias: field.alias,
                }}
                register={register}
                errors={errors}
              />
            </div>
          );
        })}
      <ContentButton style={{ margin: "-10px" }}>
        <CustomButton
          type={"submit"}
          isLoad={loading}
          text={update ? "update" : "Save"}
        />
      </ContentButton>
    </Form>
  );
}
