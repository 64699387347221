// Required modules
import styled, { css } from "styled-components";
import {
  WHITE_COLOR,
  WHITE_BACKGROUND,
  BORDER_GREY,
  BACKGROUND_GREY,
  HOVER_DELETE_COLOR,
  BACKGROUND_SECOND_GREY,
} from "utils/const";

import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";

export const LineTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 10px;
  padding-top: 30px;
  height: auto;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
`;

export const AddField = styled.div`
  min-width: 400px;
  width: 100%;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  .input-field {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    padding-left: 20px;
  }

  .tag.dragging {
    transform: translateX(
      10px
    ); /* Añade un pequeño desplazamiento horizontal durante el arrastre */
    cursor: grab; /* Cambia el cursor al estilo de agarre */
  }

  .required {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 10px;
    padding-top: 30px;
    height: auto;
    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }
    .name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 70%;

      .input {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;

        span {
          font-size: 1rem;
          color: red;
        }
      }

      .color-required {
        color: red;
      }
    }
    .type {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 70%;
    }
  }
  .select {
    font-size: 1.4rem;
    width: 100%;
    padding: 0 5px;
    background-color: var(--background-primary_color);
    color: ${WHITE_COLOR};
  }
  .input-field {
    padding-left: 20px;
  }

  .required2 {
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  #field-tags {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    min-height: 30px;
    width: 70%;

    .field-tags-input {
      width: 100%;
      max-width: 700px;
    }
    .tag-selectable {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
    }
    .tag-selectable-label {
      width: 70%;
    }
    .tag-selectable-button {
      width: 10%;
      border: none;
      background-color: transparent;
    }
    .tag-selectable-button:hover {
      color: ${HOVER_DELETE_COLOR};
    }
    .clear-icon {
      width: 40px;
      font-size: 30px;
      font-weight: bold;
    }
  }

  #checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 70%;
    row-gap: 5px;
    text-align: start;
    background: #ffffff;
    border: solid 1px #cccccc;
    padding: 11px;
    border-radius: 5px;
  }

  .input-checkbox {
    width: 100%;
  }

  .input-checkbox > input[type="checkbox"] {
    position: relative;
    width: 15%;
    height: 40px;
    background-color: ${WHITE_COLOR};
    border: 1px solid ${BORDER_GREY};
    border-radius: 5px;
    appearance: none;
    outline: none;
    cursor: pointer;
  }

  .input-checkbox > input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .input-checkbox > input[type="checkbox"]:checked {
    background-color: ${SECOND_COLOR};
    border-color: ${SECOND_COLOR};
  }

  .input-checkbox > input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAfZJREFUaEPtmF8rBUEYxp9DSVKSpCQpSW4kJUlJkpKkhE/h1p2P4M61LyAkSZIkSSeSJJIkSZIkJUkSvXrUXpw/s/POnj1bM7fnzLu/3/PO7EybQsJHKuH88AJxd9B3wHdAmYBfQsoA1dN9B9QRKgv4DigDzDZ9AkAVgIV89YuxA+MAloC/a84MgLlcEsUmMAZgGUApoS8ATAE4zyZRTAKjTL6MsJeEP0tCB0YIX07YK8KfJmEPDBO+grDXhD/JBy+/x72EhrjmKwl7Q/hjE/i4BQaZvLwuZdwS/sgUPk6BAcJXE/YOwCSAwzDwcQn0E76GsPeET4eFDyMwC2AFgLyXNaOP8LUs8kD4A9uiJpt4GsA8DxM5VGwlerlh6wj7SPh9W3iTDsi6XAw8QE5EG4keJl/PWk+E39PAmwjIf7QS3YRvIOwza+5q4U0FNBJdhG8k7Avhd1zAhxGwkejkmm8i7Cvht13BhxUII9HB5JsJ+wZA7vhbLuFtBEwk2gnfQth3wm+6hrcVyCVRQvhWwn4QfiMKeI1ANgkRaCPsJ+HXo4LXCmSS+Gf9IvxalPAuBDJJfBN+NWp4VwJBiR/Cy72pIMPkLmQKIie2CMgXhYINlwIFgw4+yAvEEnvgob4DvgPKBPwSUgaonu47oI5QWcB3QBmgevovuadeMWdblWkAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    filter: invert(100%);
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    font-size: 1.6rem;
    font-weight: 500;
    margin: 3px;
    color: ${WHITE_COLOR};
  }

  input[type="color"] {
    border: none;
    width: 30%;
    height: 20px;
    background-color: ${WHITE_COLOR};
    cursor: pointer;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border: 0px solid ${BACKGROUND_SECOND_GREY};
    border-radius: 0px;
  }

  .notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .notification label {
    font-size: 1.8rem;
    padding: 10px 0;
  }

  .notification input[type="checkbox"] {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }

  .notification input[type="checkbox"]:checked {
    background-color: ${SECOND_COLOR};
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 5%;
  padding-top: 30px;
  height: auto;
  width: 100%;

  .select-leyend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  .select-label {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .select-input {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .select-input-field {
    width: 90%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    padding-left: 20px;
  }

  .color-required {
    color: red;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .select-tags-container {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
`;

export const NumberRestrictionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  padding-top: 30px;
  height: auto;
  width: 100%;

  section {
    width: 70%;
    display: flex;
    justify-content: flex-start;
  }

  .restriction-option {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .input-restriction-checkbox {
    width: 20px;
    font-size: 2rem;
  }
  .input-restriction-leyend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    input {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0;

      span {
        font-size: 1rem;
        color: red;
      }
    }

    .color-required {
      color: red;
    }
  }

  .input-restriction-value {
    input {
      width: 100px;
      display: flex;
      flex-direction: column;
      gap: 0;

      span {
        font-size: 1rem;
        color: red;
      }
    }
  }

  .input-restriction-color {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    input {
      width: 100px;
    }
  }
`;

export const Button = styled.button`
  background: var(--background-primary_color);
  color: ${WHITE_COLOR};
  padding: 8px 13px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin: 13px 0;
  transition: 0.3s all ease-in-out;
`;

export const ConfigAdminContainer = styled.div`
  margin-top: 20px;
  min-height: 790px;
  height: auto;
  width: 100%;
  padding: 5px 5px 0px 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: ${BACKGROUND_GREY};
  padding-bottom: 15px;
  ${(props) =>
    props.neuron &&
    css`
      background-image: url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png");
      background-repeat: no-repeat;
      background-size: 25%;
      background-position: center;
    `}
`;

export const ConfigObjectsContainer = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 3fr 1.5fr;
  margin-right: 70px;
  width: 100%;
  padding: 4rem 1rem 1rem 1rem;
  align-items: start;
  justify-content: left;
  // border: 1px solid #ccc;
`;

export const TitleAdminConfig = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    font-size: 3rem;
    font-weight: 500;
    /* margin-bottom: 5rem; */
    margin-top: 5rem;
    margin-left: 5rem;
  }

  p {
    font-size: 18px;
  }

  grid-column: 2;
`;

export const ItemsAdminConfig = styled.div`
  display: flex;
  justify-content: center;
`;

export const ItemAdminCofig = styled.div`
  flex: 1;
  box-shadow: rgba(79, 79, 79, 0.2) 0px 0px 5px 0px;
  padding: 13px 21px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px;
  margin: 13px;
  transition: all 0.3s ease-in-out;

  h1 {
    font-size: 2rem;
    text-align: center;
    font-weight: 400;
  }

  .bottom {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const RowAdminConfig = styled.div`
  display: flex;
  width: 100%;
  transition: 0.3s all ease-in-out;
  max-height: 950px;

  ${(props) =>
    props.boxShadow &&
    css`
      box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
      border-radius: 5px;
      padding: 0 10.5px;
    `}

  &.down {
    animation-name: down;
    animation-duration: 0.3s;
  }

  &.up {
    animation-name: up;
    animation-duration: 0.3s;
  }

  @keyframes down {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes up {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
    }
  }

  /* aqui van las medias queries */
  @media screen and (max-width: 2575px) {
    & {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &.container-table {
      height: auto;
    }
    &.container-form {
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    & {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &.container-table {
      position: absolute;
      height: auto;
    }
    &.container-form {
      height: auto;
    }
  }
`;

export const ColumnAdminConfig = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-top: 15px;
  width: auto;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const TableAdminConfig = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: auto;
  width: auto;

  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const CreateNewRegionButton = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  padding-top: 21px;
  font-size: 2rem;
  padding: 21px;
  border-radius: 5px;
  margin-bottom: 1rem;
  background-color: ${WHITE_COLOR};
  border: 1px solid ${BORDER_GREY};

  justify-content: left;
  height: 15rem;

  button {
    width: 100%;
    height: 4rem;
    display: flex;
    background: var(--background-primary_color);
    color: ${WHITE_COLOR};
    padding: 8px 13px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: 7px;
    margin: ${({ margin }) => margin};
    transition: 0.3s all ease-in-out;
  }

  button:hover {
    background: var(--background-secondary_color);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    font-size: 17px;
  }
  p {
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const PentagonoContainer = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .text {
    display: block;
    font-weight: 600;
    cursor: pointer;
    color: #5c5757;
    font-size: 1.6rem;
  }
`;

export const Pentagono = styled.div`
  width: ${(props) => props.width * 2 + 150}px;
  height: ${(props) => props.width * 2 + 150}px;
  background: ${(props) =>
    props.background ||
    'url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png"), #FFF'};
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  opacity: ${(props) => props.opacity || "1"};
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  ${(props) =>
    props.small &&
    css`
      position: relative;
      width: 140px;
      height: 140px;
      z-index: 11;
    `}

  ${(props) =>
    props.into &&
    css`
      position: absolute;
      width: 140px;
      height: 140px;
      z-index: 12;
    `}
`;

export const ConfigContainer = styled.div`
  background-color: #fafafa;
  height: 6rem;
  border: 1px solid ${BORDER_GREY};
`;

export const TitleColumn = styled.h2`
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  margin: 15px 0;
`;

export const ButtonAdminConfig = styled.button`
  background: var(--background-primary_color);
  margin: 13px 0;
  outline: none;
  border: none;
  color: ${WHITE_COLOR};
  font-size: 1.5rem;
  padding: 8px 13px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &:hover {
    background: ${SECOND_COLOR};
  }

  ${(props) =>
    props.large &&
    css`
      width: 100%;
    `}
`;

export const IconAdminConfigChangeColum = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: var(--background-primary_color);

  .icon {
    color: var(--background-primary_color);
    font-size: 4.5rem;

    &:hover {
      color: var(--background-secondary_color);
    }
  }
`;

export const SeparatorWithText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin: 13px 0;
  border: solid 2px red;

  .left {
    flex: 1;
    background: ${SECOND_COLOR};
    margin-right: 1rem;
    height: 2px;
  }

  .right {
    flex: 1;
    background: ${SECOND_COLOR};
    height: 2px;
    margin-left: 1rem;
  }
`;

export const InformationShare = styled.div`
  background-image: url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png");
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center;
  min-height: 80%;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  padding: 21px;
  border-radius: 5px;
  border: 1px solid ${BORDER_GREY};
  background-color: ${WHITE_BACKGROUND};

  .body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ReturnSettingsPage = styled.div`
  // position: absolute;
  top: 5px;
  left: 42px;
  width: 27.5px;
  height: 27.5px;
  cursor: pointer;
  font-size: 2rem;
  color: ${WHITE_COLOR};
  background: var(--background-primary_color);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  // align-self: center;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  transition: 0.3s all ease-in-out;
  margin-top: 0.8rem;

  grid-column: 1;

  // border: 1px solid red;

  &:hover {
    transform: scale(1.05);
    background: ${SECOND_COLOR};
  }
`;

export const SelectType = styled.div`
  position: absolute;
  top: 33px;
  left: 80px;
`;

export const SelectContainerAdminConfig = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  font-size: 18px;
  margin-bottom: 13px;
  margin-right: 2rem;
  align-items: center;
  padding: 0 72px;
  grid-column: 3;

  h3 {
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  div {
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
  }

  p {
    font-size: 18px;
  }

  select {
    display: flex;
    flex: 1;
    font-size: 18px;
    background: var(--background-primary_color);
    color: ${WHITE_COLOR};
    padding: 8px 8px;
    outline: none;
    border: none;
    text-align: left;
    cursor: pointer;
    border-radius: 8px;
    margin: 0 10px;
    width: 170px;
    // border: solid 2px yellow;
  }
`;

export const SelectChooseLibrayAdminConfig = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 60%;
  font-size: 18px;
  align-items: center;

  h3 {
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  div {
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
  }

  p {
    font-size: 18px;
  }

  select {
    display: flex;
    flex: 1;
    font-size: 18px;
    background: var(--background-primary_color);
    color: ${WHITE_COLOR};
    padding: 8px 8px;
    outline: none;
    border: none;
    text-align: left;
    cursor: pointer;
    border-radius: 8px;
    margin: 0 10px;
    width: 170px;
    // border: solid 2px yellow;
  }
`;

export const LineContainerAdminConfig = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .container {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .text {
    display: block;
    font-weight: 600;
    cursor: pointer;
    color: #5c5757;
    font-size: 1.6rem;
  }

  .line {
    width: 50px;
    height: ${(props) => props.width * 2}px;
    background: ${(props) => props.color};
  }

  .separator {
    width: ${(props) =>
      props.separator <= 0 ? 0.1 * 2 : props.separator * 2}px;
  }

  .circle {
    width: ${(props) => props.width * 8}px;
    height: ${(props) => props.width * 8}px;
    background: ${(props) => props.color};
    border-radius: 50%;
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 5px 0;

  :before,
  :after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
  }

  .divider-title {
    padding: 0 10px;
    color: #666;
    font-size: 16px;
  }
`;
