import styled from "styled-components";

export const CustomInput = styled.input`
  width: 50%;
`;

export const LoadCsv = styled("div")`
  width: 100vw;
  display: flex;
  align-items: center;

  input {
    position: absolute;
  }
`;

export const ContentConfirm = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
  div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  strong {
    margin-left: 4px;
  }

  .autocomplete {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 50%;
    margin: 10px;
    align-items: center;
    p {
      width: 100%;
      text-align: center;
    }
    .contairer-inputs {
      display: flex;
      align-items: center;
      width: 50%;
      justify-content: space-evenly;
    }

    input {
      margin: 5px;
      width: 20px;
      height: 20px;
    }
  }
`;
