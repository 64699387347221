import { config } from "config.js";
const URL_BASE_PG = config.URL_BACKEND_PG;

export const resetPassword = async (body) => {
  try {
    const res = await fetch(`${URL_BASE_PG}api/v1/auth/resetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      const result = await res.json();
      throw new Error(result.message);
    }
    const data = await res.json();
    return { res, data };
  } catch (error) {
    throw error;
  }
};
