import { BiVideoRecording } from "react-icons/bi";
import { WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";
//ICONS
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { BiBrain } from "react-icons/bi";
import styled from "styled-components";
import TuneIcon from "@mui/icons-material/Tune";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LayersIcon from "@mui/icons-material/Layers";
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

const userId = localStorage.getItem("userId");

export const ListItemTextStyle = {
  color: PRIMARY_COLOR,
};

export const iconStyle = {
  color: PRIMARY_COLOR,
  fontSize: "2.5rem",
};

export const iconStyle2 = {
  color: WHITE_COLOR,
  fontSize: "2.5rem",
};

export const iconStyle3 = {
  color: document.body.style.color,
  fontSize: "2.5rem",
};

export const VersionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 6rem 2rem;
  position: relative;

  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2.2rem;
    color: ${PRIMARY_COLOR};
  }

  p {
    text-align: right;
    margin-right: 2rem;
    padding: 3rem;
  }

  sup {
    font-size: 2.3rem;
  }
`;

export const LogoBar = styled.div`
  display: flex;
  background-size: cover;
  width: 220px;
  height: 220px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 40%;
  div {
    margin-bottom: 2rem;
    z-index: -1;
  }
`;

const dashboard = (url, name) => ({
  icon: <InsertChartOutlinedIcon style={iconStyle} data-tip />,
  url,
});

const hubInstances = (url) => ({
  icon: <LayersIcon style={iconStyle} data-tip />,
  url,
});

const addUser = (url, name) => ({
  icon: (
    <ManageAccountsOutlinedIcon style={iconStyle} sx={iconStyle} data-tip />
  ),
  url,
});

const digitalTwin = (url, name) => ({
  icon: <ChangeCircleOutlinedIcon style={iconStyle} data-tip />,
  url,
});

const socrates = (url, name) => ({
  icon: <BiBrain style={iconStyle} data-tip />,
  url,
});

const video = (url, name) => ({
  icon: <BiVideoRecording style={iconStyle} data-tip />,
  url,
});

const aboutUs = (url, name) => ({
  icon: <InfoOutlinedIcon style={iconStyle} data-tip />,
  url: url,
});

const settingsWithSubcategories = {
  icon: <SettingsOutlinedIcon style={iconStyle} />,
  subcategories: {
    Objects: { icon: <SettingsOutlinedIcon />, url: "/object-configurations" },
    Components: {
      icon: <SettingsOutlinedIcon />,
      url: "/component-configurations",
    },
    Events: { icon: <SettingsOutlinedIcon />, url: "/config-actions-events" },
    Libraries: {
      icon: <SettingsOutlinedIcon />,
      url: "/library-configurations",
    },
  },
};

const platformSetUpWithSubcategories = {
  icon: <BackupTableIcon style={iconStyle} />,
  subcategories: {
    "Centralized Constants Management": {
      icon: <BackupTableIcon />,
      url: "/config-globalConstants",
    },
    "Unified Standards Catalog": {
      icon: <BackupTableIcon />,
      url: "/config-globalValues-Fields",
    },
    "Multi-Name Asset Configuration": {
      icon: <BackupTableIcon />,
      url: "/config-global-list-table",
    },
    "Measurement Data Integration": {
      icon: <BackupTableIcon />,
      url: "/config-global-measurement-table",
    },
  },
};

const preferencesWithSubcategories = {
  icon: <TuneIcon style={iconStyle} />,
  subcategories: {
    Tooltip: { icon: <TuneIcon />, url: "/config-preferences-tooltip" },
    Theme: { icon: <TuneIcon />, url: "/config-preferences-theme" },
    Font: { icon: <TuneIcon />, url: "/config-preferences-font" },
  },
};

//Settings Categories
const settingsAdmDecimetrix = {
  icon: <SettingsApplicationsIcon style={iconStyle} />,
  subcategories: {
    "Configuration No code development": settingsWithSubcategories,
  },
};

const settingsAdmCompany = {
  icon: <SettingsApplicationsIcon style={iconStyle} />,
  subcategories: {
    "Configuration No code development": settingsWithSubcategories,
    "Platform set up": platformSetUpWithSubcategories,
    "UX/UI style preferences": preferencesWithSubcategories,
  },
};

// Available Modules Categories
const availableModulesAdmDecimetrix = {
  icon: <DashboardIcon style={iconStyle} />,
  subcategories: {
    "Company managers": { icon: <ManageAccountsOutlinedIcon />, url: "/admin-companies" },
  }
};
const availableModulesCompany = {
  icon: <DashboardIcon style={iconStyle} />,
  subcategories: {
    "KPIs & Dashboards": { icon: <InsertChartOutlinedIcon />, url: "/dashboard-admin" },
    "Digital twin": { icon: <ChangeCircleOutlinedIcon />, url: "/digital-twin-admin" },
    "Users": { icon: <ManageAccountsOutlinedIcon />, url: "/operators" },
    "AI Socrates": { icon: <BiBrain />, url: "/chatbot" },
  }
};

const availableModulesItSupport = {
  icon: <DashboardIcon style={iconStyle} />,
  subcategories: {
    "Digital twin": { icon: <ChangeCircleOutlinedIcon />, url: "/digitalTwin" },
    "Users": { icon: <ManageAccountsOutlinedIcon />, url: "/operators-user" },
  }
};

const availableModulesEquCampo = {
  icon: <DashboardIcon style={iconStyle} />,
  subcategories: {
    "Digital twin": { icon: <ChangeCircleOutlinedIcon />, url: "/digitalTwin" },
    "Video Manager": { icon: <BiVideoRecording />, url: `/upload-videos/${userId}` },
  }
};

const availableModulesEquOficina = {
  icon: <DashboardIcon style={iconStyle} />,
  subcategories: {
    "KPIs & Dashboards": { icon: <InsertChartOutlinedIcon />, url: "/dashboard-user" },
    "Digital twin": { icon: <ChangeCircleOutlinedIcon />, url: "/digitalTwin" },
    "Video Manager": { icon: <BiVideoRecording />, url: "/field-video-manager" },
  }
};

const availableModulesDirProyecto = {
  icon: <DashboardIcon style={iconStyle} />,
  subcategories: {
    "Digital twin": { icon: <ChangeCircleOutlinedIcon />, url: "/digitalTwin" },
  }
};

export const SideBarItems = {
  "Administrador de decimetrix": {
    "Available Modules": availableModulesAdmDecimetrix,
    Settings: settingsAdmDecimetrix,
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "Administrador de compañía": {
    "Hub instances": hubInstances("/hub-instances"),
    "KPIs & Dashboards": dashboard("/dashboard-admin", "Dashboard"),
    "Digital twin": digitalTwin("/digital-twin-admin", "Digital twin"),
    Users: addUser("/operators", "Operators"),
    "Configuration No code development": settingsWithSubcategories,
    "Platform set up": platformSetUpWithSubcategories,
    "UX/UI style preferences": preferencesWithSubcategories,
    "AI Socrates": socrates("/chatbot", "Socrates"),
    "Available Modules": availableModulesCompany,
    Settings: settingsAdmCompany,
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "IT Support": {
    "Hub instances": hubInstances("/hub-instances"),
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    Users: addUser("/operators-user", "Operators"),
    "Available Modules": availableModulesItSupport,
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "Equipo de campo": {
    "Hub instances": hubInstances("/hub-instances"),
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "Video manager": video(`/upload-videos/${userId}`, "Video manager"),
    "Available Modules": availableModulesEquCampo,
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "Equipo de oficina": {
    "Hub instances": hubInstances("/hub-instances"),
    "KPIs & Dashboards": dashboard("/dashboard-user", "Dashboard"),
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "Video manager": video(`/field-video-manager`, "Video manager"),
    "Available Modules": availableModulesEquOficina,
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "Director de proyecto": {
    "Hub instances": hubInstances("/hub-instances"),
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "Available Modules": availableModulesDirProyecto,
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
};
