import React from "react";
import { HubCard, HubH2, HubP } from "pages/AdminCompany/Hub/HubStyles";
import Chartsicon from "images/Chartsicon";
import Cicleicon from "images/Cicleicon";
import VideoManagerIcon from "images/VideoManagerIcon";
import AddUsers from "images/AddUsers";
import useUserRole from "hooks/useUserRole";

const InfoHubOper = ({ navigate, customer, roles }) => {
  const { userRole } = useUserRole();
  return (
    <>
      {userRole === "Equipo de oficina" && (
        <HubCard onClick={() => navigate("/dashboard-user")}>
          <HubH2>DASHBOARD</HubH2>
          <Chartsicon />
          <HubP>Report of all the objects collected</HubP>
        </HubCard>
      )}
      <HubCard
        className="background-card-color"
        onClick={() => navigate("/digitalTwin")}
      >
        <HubH2>DIGITAL TWIN</HubH2>
        <Cicleicon />
        <HubP>View all objects and events on the map</HubP>
      </HubCard>
      {customer?.operator?.role?.role === roles.teamOffice ||
      customer?.operator?.role?.role === roles.teamField ? (
        <HubCard
          className="background-card-color"
          onClick={() => {
            if (customer.operator?.role?.role === roles.teamField)
              navigate(`/upload-videos/${customer.operator.userId}`);
            if (customer.operator?.role?.role === roles.teamOffice)
              navigate("/field-video-manager");
          }}
        >
          <HubH2>VIDEO MANAGER</HubH2>
          <VideoManagerIcon />
          <HubP>Manage your field-collected videos</HubP>
        </HubCard>
      ) : null}
      {customer?.operator?.role?.role === roles.itSupport && (
        <HubCard
          onClick={() => navigate("/operators-user")}
          className="last-item background-card-color"
        >
          <HubH2>USERS</HubH2>
          <AddUsers />
          <HubP>View your collected objects for the day</HubP>
        </HubCard>
      )}
    </>
  );
};

export default InfoHubOper;
