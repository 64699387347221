import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useCallback } from "react";
import { useControl } from "react-map-gl";
import { useDispatch, useStore } from "react-redux";
import { setDrawAnyThing, setDrawerControl, setMap } from "redux/actions/index";
// import { setFeaturesDetailsDT } from "redux/actions/admin";
import {
  setDrawLineMode,
  setNeuronSelectTool,
  setShowLines,
} from "redux/actions/digitalTwin";
import LineToolsModules from "./Lines/LineToolsModules";
import { setGeometryLine, setGeometryLineConfig } from "redux/actions/admin";

const typeDraw = {
  point: "draw_point",
  polygon: "draw_polygon",
  line: "draw_line_string",
};

export default function DrawControl(props) {
  const store = useStore();
  const dispatch = useDispatch();
  // Find points close to a coordinate

  const { selectLineForPopUp } = LineToolsModules();

  // const findClosePoint = useCallback(
  //   (coordinate, radius) => {
  //     let closestPoint = null;
  //     let closestDistance = Infinity;
  //     const { filteredObjects: points } = store.getState().digitalTwinReducer;
  //     const [x, y] = coordinate;
  //     points.filter((point) => {
  //       const [px, py] = point.geometry.coordinates;
  //       const fx = Math.sqrt((px - x) ** 2 + (py - y) ** 2);
  //       const r = fx < radius;
  //       if (r && fx < closestDistance) {
  //         closestDistance = fx;
  //         closestPoint = point;
  //       }
  //       return r;
  //     });
  //     return closestPoint;
  //   },
  //   [store]
  // );

  // clickmap refactored to work with points only
  const clickMap = useCallback(async ({ controlGl, map }) => {
    // const { type } = store.getState().digitalTwinReducer.drawAnyThing;
    let mode = controlGl.getMode();
    // const zoom = map.getZoom();
    // const maxZoom = 23;
    // const radius = 10;
    // const radiusZoom = radius * (maxZoom - zoom);
    if (mode === "draw_line_string") return;
    const drawLineMode = store.getState().digitalTwinReducer.drawLineMode;
    if (!drawLineMode.mode.length && drawLineMode.show) {
      controlGl.changeMode("simple_select");
      dispatch(
        setDrawLineMode({
          mode: "",
          drawStatus: false,
          show: false,
          features: [],
        })
      );
      return;
    }
    const { features } = controlGl.getAll();
    const lastFeature = features[features.length - 1];
    if (!lastFeature) {
      controlGl.changeMode("simple_select");
      return null;
    }
    // const { geometry } = lastFeature;
    // const { coordinates } = geometry;
    // const lenCoordinates = coordinates.length;
    // const point =
    // type === "point" ? coordinates : coordinates[lenCoordinates - 2];
    // let closePoint = findClosePoint(point, radiusZoom);

    // if (closePoint) {
    //   const [lon, lat] = closePoint.geometry.coordinates;
    //   if (type === "point") {
    //     coordinates[0] = lon;
    //     coordinates[1] = lat;
    //   }
    //   controlGl.set({
    //     type: "FeatureCollection",
    //     features: [{ ...lastFeature }],
    //   });
    //   if (type === "point") {
    //     dispatch(setFeaturesDetailsDT({ ...lastFeature }));
    //   }
    // }
  }, []);

  const addStorageEvent = ({ map }) => {
    window.addEventListener("storage", (event) => {
      if (event.key === "coordinates") {
        const coordinatesParse = JSON.parse(
          localStorage.getItem("coordinates")
        );
        map.flyTo({
          center: [coordinatesParse[0], coordinatesParse[1]],
          duration: 2000,
          zoom: 24,
        });
      }
    });
  };

  useControl(
    ({ map }) => {
      const controlGl = new MapboxDraw({
        ...props,
        clickBuffer: 6,
        touchBuffer: 0,
        // styles: [
        //   {
        //     id: "highlight-active-points",
        //     type: "circle",
        //     paint: {
        //       "circle-radius": 7,
        //       "circle-color": "#9c0b0b",
        //     },
        //   },
        //   {
        //     id: "points-are-blue",
        //     type: "circle",
        //     paint: {
        //       "circle-radius": 5,
        //       "circle-color": "#000088",
        //     },
        //   },
        //   {
        //     id: "line-dashed",
        //     type: "line",
        //     paint: {
        //       "line-color": "#dd28a1",
        //       "line-width": 2,
        //       "line-dasharray": [3, 6],
        //     },
        //   },
        //   {
        //     id: "selected-vertex", // Estilo para el vértice seleccionado
        //     type: "circle",
        //     paint: {
        //       "circle-radius": 3, // Tamaño más grande para destacar
        //       "circle-color": "#FF0000", // Color rojo para resaltar
        //     },
        //   },
        // ],
      });
      dispatch(setDrawerControl(controlGl));
      dispatch(setMap(map));
      addStorageEvent({ map });
      const canvas = map.getCanvasContainer();
      map.on("draw.select", props.onSelect);
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.update", () => {
        const { cancelDrawing } = store.getState().digitalTwinReducer;
        if (cancelDrawing) {
          controlGl.deleteAll();
        }
      });
      map.on("dragend", () => {
        const { cancelDrawing } = store.getState().digitalTwinReducer;
        if (cancelDrawing) {
          controlGl.deleteAll();
        }
      });
      map.on("draw.delete", props.onDelete);
      map.on("load", function () {
        map.addControl(controlGl);
        map.on("click", () => {
          clickMap({ controlGl, map });
        });
        map.on("draw.modechange", () => {
          const mode = controlGl.getMode();
          const drawLineMode = store.getState().digitalTwinReducer.drawLineMode;
          if (mode === typeDraw.line && drawLineMode.show) {
            canvas.style.cursor = "";
            controlGl.changeMode("simple_select");
            dispatch(
              setDrawLineMode({
                mode: "",
                drawStatus: false,
                show: false,
                features: [],
              })
            );
            return;
          }
          if (mode === typeDraw.line) {
            canvas.style.cursor = "";
            controlGl.changeMode("simple_select");
            // this state opens the line tool list, click on first tool control
            dispatch(
              setDrawLineMode({
                mode: "",
                drawStatus: false,
                show: true,
                features: [],
              })
            );
            dispatch(
              setNeuronSelectTool({ show: false, top: null, right: null })
            );
            dispatch(setGeometryLine(null));
            dispatch(setGeometryLineConfig({}));
          }

          props.setCopyCoordinates(false);
          props.setCopyLocation({});
          if (mode === typeDraw.polygon) {
            dispatch(
              setDrawLineMode({
                mode: "",
                show: false,
                drawStatus: false,
                features: [],
              })
            );
            dispatch(setShowLines(false));
            dispatch(
              setNeuronSelectTool({
                show: true,
                top: 250,
                right: 30,
              })
            );
          }

          props.setCopyCoordinates(false);
          props.setCopyLocation({});


          if (mode === typeDraw.point) {
            dispatch(
              setDrawLineMode({
                mode: "",
                show: false,
                drawStatus: false,
                features: [],
              })
            );
            dispatch(
              setNeuronSelectTool({
                show: true,
                top: 280,
                right: 30,
              })
            );
          }
          props.setCopyCoordinates(false);
          props.setCopyLocation({});

          if (mode !== typeDraw.polygon && mode !== typeDraw.point && mode !== typeDraw.line) {
            dispatch(
              setNeuronSelectTool({
                show: false,
                top: 250,
                right: 30,
              })
            );
          }
          const isDraw = mode.includes("draw");
          setTimeout(() => {
            dispatch(
              setDrawAnyThing({
                isDraw,
                type: mode.replace("draw_", ""),
              })
            );
          }, 10);
        });
      });
      map.on("mouseenter", "line-id", () => {
        canvas.style.cursor = "pointer";
      });
      map.on("mouseleave", "line-id", () => {
        canvas.style.cursor = "";
      });
      map.on("click", (e) =>
        selectLineForPopUp({ mapa: map, control: controlGl, e })
      );
    },
    {
      position: props.position,
    }
  );
  return null;
}

DrawControl.defaultProps = {
  onCreate: () => { },
  onUpdate: () => { },
  onDelete: () => { },
};
