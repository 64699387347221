const { pathFolder } = require("utils/paths");

export const getPathByType = ({ endpoint }) => {
  const from = endpoint.split("/")[2];
  // Objects
  if (from === "point-libraries") {
    return pathFolder.icons.configuration.object.libraries;
  }
  if (from === "type-elements") {
    return pathFolder.icons.configuration.object.types;
  }
  // Components
  if (from === "point-library-components") {
    return pathFolder.icons.configuration.component.libraries;
  }
  if (from === "point-type-components") {
    return pathFolder.icons.configuration.component.types;
  }
  // Events
  if (from === "point-library-events") {
    return pathFolder.icons.configuration.event.libraries;
  }
  if (from === "point-type-events") {
    return pathFolder.icons.configuration.event.types;
  }
  // Lines
  if (from === "line-libraries") {
    return pathFolder.icons.configuration.line.libraries;
  }
  if (from === "type-lines") {
    return pathFolder.icons.configuration.line.types;
  }
};
