import { updateChart } from "services/Dashboard/groupDoughnutChart";
import { useSWRConfig } from "swr";
import { useEffect, useState } from "react";
import { urlsApiMg } from "utils/urlKeys";

export const useSetCurrentStyle = ({ dataChart, setChartEditDialog }) => {
  const { mutate } = useSWRConfig();

  const [currentStyleLineDataChart, setCurrentStyleLineDataChart] =
    useState(dataChart);

  useEffect(() => {
    setCurrentStyleLineDataChart({
      ...dataChart,
      datasets: dataChart.datasets.map((elm) => {
        let updatedElm = {
          ...elm.donutStyle,
          data: elm.data,
        };
        return updatedElm;
      }),
    });
  }, [dataChart]);

  const handleUpdateChart = async () => {
    const { data } = await updateChart({
      id: dataChart._id,
      body: {
        ...currentStyleLineDataChart,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        datasets: currentStyleLineDataChart.datasets.map((elm) => {
          return {
            columnsValuesFilter: [],
            donutStyle: elm,
          };
        }),
      },
    });
    if (data) {
      mutate(
        urlsApiMg.dashboard.doughnutChartGroup(
          dataChart.fromType ? dataChart.fromType : dataChart.from
        )
      );
      setChartEditDialog(false);
    }
  };

  return {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
  };
};
