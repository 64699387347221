import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LayersIcon from "@mui/icons-material/Layers";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { setUpdateRegion } from "redux/actions/index";
import { setTableDetailsForward } from "redux/actions/digitalTwin";

import OpenDialog from "components/Dialogs/OpenDialog";
// Const
import {
  WHITE_SECOND_COLOR,
  WHITE_COLOR,
  PRIMARY_COLOR,
  BLACK_COLOR,
} from "utils/const";

import { Checkbox } from "@mui/material";

// check layer
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// redux actions Admin
import {
  showRegionDetailsDrawer,
  SetUsers,
  SetShowUsers,
  setGeometryPolygon,
  setDataObjectsPolygon,
  setFeaturesDetailsDT,
} from "redux/actions/admin";

import AccordionDigitalTwin from "./../Accordions/AccordionDigitalTwin";
import AccordionObjectsPolygon from "./../Accordions/AccordionObjectsPolygon";
import AccordionDetailsPolygon from "./../Accordions/AccordionDetailsPolygon";
import AccordionBookmarksViews from "../Accordions/AccordionBookmarksViews";

import {
  useStyles,
  CustomizeToggleButtonGroup,
  CustomizedAccordion,
  FilterTabStyles,
  ContentFilters,
  ContentFilterItem,
} from "./MapDrawerStyle";

import FilterByDate from "components/Accordions/FilterByDate";
import GenericFields from "components/Forms/SaveObject";
import AccordionSpecialFilters from "components/Accordions/AccordionSpecialFilters";
import AccordionLabels from "components/Accordions/AccordionLabels";
import CustomButton from "components/Buttons/CustomButton";
import { SynchronizeObjects } from "components/Forms/SaveObject/SynchronizeObjects";
import MessagesDialog from "components/Dialogs/MessagesDialog";

export default function MapDrawer({ drawState, dataObjects }) {
  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  const openRegionDetails = useSelector(
    (state) => state.adminReducer.showRegionDetailsDrawer
  );

  const role = localStorage.getItem("role");
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState("layers");
  const [showSaveObject, setShowSaveObject] = useState(false);
  const [openDialogSyncObjects, setOpenDialogSyncObjects] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleAlignment = (event) => {
    setSection(event.currentTarget.value);
  };

  const featureDetails = useSelector(
    (state) => state.adminReducer.setFeaturesDetailsDT
  );

  const users = useSelector((state) => state.adminReducer.SetUsers);
  const showUsers = useSelector((state) => state.adminReducer.SetShowUsers);

  useEffect(() => {
    setShowSaveObject(() => {
      if (featureDetails.geometry.type === "Point") return true;
      return false;
    });
  }, [featureDetails]);

  const handleChangeUserFilter = (event, id) => {
    const { isShow } = users[id];
    users[id].isShow = !isShow;
    dispatch(SetUsers(users));
    dispatch(SetShowUsers(!showUsers));
  };

  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );

  const updateRegion = useSelector(
    (state) => state.digitalTwinReducer.updateRegion
  );

  useEffect(() => {
    if (drawState === true) {
      handleDrawerOpen();
    } else if (drawState === false) {
      handleDrawerClose();
    } else {
    }
  }, [role, drawState]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const closeRegionDetails = () => {
    dispatch(showRegionDetailsDrawer(false));
  };

  const closeRegionCreatingDetails = () => {
    dispatch(setGeometryPolygon(null));
    dispatch(setDataObjectsPolygon([]));
    dispatch(setUpdateRegion({ id: null, isUpdate: false, data: {} }));
  };

  const handleClickDrawer = () => {
    dispatch(setTableDetailsForward(9));
  };

  return (
    <div style={{ position: "relative" }} onClick={handleClickDrawer}>
      {openAlert.open && (
        <MessagesDialog
          open={openAlert.open}
          severity={openAlert.severity}
          message={openAlert.message}
          handleClose={() => setOpenAlert(false)}
          margin={"0px 0px 0px 320px;"}
        />
      )}
      <Drawer
        className="background-2"
        sx={{
          width: 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: {
              xs: 240,
              sm: 320,
            },
            height: "calc(100vh - 90px)",
            borderRadius: "5px",
            boxSizing: "border-box",
            margin: "85px 7px",
            background: "var(--background-color3)",

            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#ccc",
              borderRadius: "5px",
            },
            "::-webkit-scrollbar-track-piece": {
              backgroundColor: WHITE_SECOND_COLOR,
              borderRadius: "5px",
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className="background-2" style={{ marginTop: "10px" }}>
          <div>
            <List
              sx={{
                height: "100%",
                position: "bottom",
              }}
              subheader={<li />}
            >
              <CustomizeToggleButtonGroup
                value={section}
                onChange={handleAlignment}
                size="large"
                exclusive
                aria-label="text view"
              >
                <ToggleButton
                  sx={{
                    fontSize: "1.4rem",
                    textTransform: "none",
                    color: BLACK_COLOR,
                    "&.Mui-selected": {
                      color: WHITE_COLOR,
                      backgroundColor: "var(--background-primary_color)",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#017371",
                    },
                    width: "49%",
                  }}
                  value="layers"
                >
                  <LayersIcon />
                  <p> Workspace</p>
                </ToggleButton>
                <ToggleButton
                  className="border-color-1 "
                  sx={{
                    fontSize: "1.4rem",
                    textTransform: "none",
                    color: BLACK_COLOR,
                    "&.Mui-selected": {
                      color: WHITE_COLOR,
                      backgroundColor: "var(--background-primary_color)",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#017371",
                    },
                    width: "49%",
                  }}
                  value="filters"
                >
                  <FilterAltIcon className="color1" />
                  <p>Filters</p>
                </ToggleButton>
              </CustomizeToggleButtonGroup>
            </List>
            <List
              sx={{
                height: "100%",
                position: "bottom",
              }}
              subheader={<li />}
            >
              {/* ---section workspace--- */}
              {section === "layers" && (
                <div className="background-2">
                  <div>
                    <br />

                    <div
                      style={{
                        padding: "0px 12px",
                      }}
                    >
                      {/* card with the summary of the objects when creating the polygon */}
                      {geometryPolygon?.type === "Polygon" && (
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            classes={{
                              content: classes.content,
                              expanded: classes.expanded,
                            }}
                          >
                            <CloseIcon
                              onClick={closeRegionCreatingDetails}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.54)",
                                position: "absolute",
                                alignSelf: "right",
                                right: "10px",
                              }}
                            />
                            <Typography className="color1">
                              {updateRegion?.isUpdate
                                ? "Update Region"
                                : "New Region"}
                            </Typography>
                          </AccordionSummary>
                          <AccordionObjectsPolygon
                            dataObjects={dataObjectsPolygon}
                          />
                        </CustomizedAccordion>
                      )}
                      {/* Boorkmarks views */}
                      {
                        <CustomizedAccordion
                          className="background-1 border-color-1"
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                          style={{
                            borderRadius: "4px",
                            marginBottom: "1rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1.5rem",
                                fontFamily: document.body.style.fontFamily,
                                color: document.body.style.color,
                                fontWeight: "bold",
                              }}
                            >
                              Map Views
                            </Typography>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon className="color1" />}
                            ></AccordionSummary>
                          </div>
                          <AccordionBookmarksViews />
                        </CustomizedAccordion>
                      }

                      {dataObjects.length > 0 && (
                        <AccordionDigitalTwin dataObjects={dataObjects} />
                      )}
                      {dataObjects.length > 0 && (
                        <AccordionSpecialFilters dataObjects={dataObjects} />
                      )}

                      {dataObjects.length > 0 && (
                        <AccordionLabels dataObjects={dataObjects} />
                      )}

                      {/* card with details of the region created  */}
                      {openRegionDetails &&
                      featureDetails.geometry.type == null ? (
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            classes={{
                              content: classes.content,
                              expanded: classes.expanded,
                            }}
                            // expandIcon={<ExpandMoreIcon />}
                          >
                            <CloseIcon
                              onClick={closeRegionDetails}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.54)",
                                position: "absolute",
                                alignSelf: "right",
                                right: "10px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "1.5rem",
                                color: document.body.style.color,
                              }}
                            >
                              Region Details
                            </Typography>
                            {/* <button>Edit Geometry</button> */}
                          </AccordionSummary>
                          <AccordionDetailsPolygon
                            dataObjects={dataObjectsPolygon}
                          />
                        </CustomizedAccordion>
                      ) : null}

                      <OpenDialog
                        openDialog={showSaveObject}
                        setOpenDialog={setShowSaveObject}
                        disagree={() => {
                          dispatch(
                            setFeaturesDetailsDT({
                              geometry: {
                                type: null,
                              },
                            })
                          );
                        }}
                        content={{
                          title: "Add new object",
                          description: <GenericFields />,
                        }}
                        maxWidth="1500px"
                        minWidth="300px"
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* ---section filters--- */}
              {section === "filters" && (
                <ContentFilters>
                  <CustomizedAccordion
                    className="background-1 color1 border-color-1 border-radius-1"
                    classes={{
                      content: classes.content,
                      expanded: classes.expanded,
                    }}
                    defaultExpanded={false}
                    style={{ marginBottom: "1rem" }}
                  >
                    <ContentFilterItem>
                      <Typography
                        sx={{
                          fontFamily: document.body.style.fontFamily,
                          color: document.body.style.color,
                        }}
                      >
                        Filter For User
                      </Typography>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="color1" />}
                      ></AccordionSummary>
                    </ContentFilterItem>
                    <AccordionSummary
                      style={{ color: document.body.style.color }}
                    >
                      <FilterTabStyles>
                        {Object.keys(users).map((key) => (
                          <div>
                            <Checkbox
                              defaultChecked={users[key].isShow}
                              onChange={(event) =>
                                handleChangeUserFilter(event, key)
                              }
                            />
                            <p>{users[key].operator}</p>
                          </div>
                        ))}
                        {/* {users.length !== 0 &&} */}
                      </FilterTabStyles>
                    </AccordionSummary>
                  </CustomizedAccordion>
                  <FilterByDate />
                  <OpenDialog
                    openDialog={showSaveObject}
                    setOpenDialog={setShowSaveObject}
                    disagree={() => {
                      dispatch(
                        setFeaturesDetailsDT({
                          geometry: {
                            type: null,
                          },
                        })
                      );
                    }}
                    content={{
                      title: "Add new object",
                      description: <GenericFields />,
                    }}
                    maxWidth="500px"
                  />
                </ContentFilters>
              )}
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
