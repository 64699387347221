import { useEffect, useRef, useState } from "react";
import {
  ContainerConfigGlobalValue,
  ContainerSelect,
  ContainerValue,
  GlobalFieldValue,
} from "./CreateGlobalSelectStyles";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { BLACK_COLOR, WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import { FROM_TABLE } from "utils/constStrings";
import { isUrl, nameUrl } from "helpers/isUrl";
import { urlsApiMg } from "utils/urlKeys";

export const AutocompleteConfigValue = ({
  dataType,
  field,
  setField,
  setDialog,
  update,
  globalSelects,
  InfoField,
}) => {
  const [dataGlobalTables, setDataGlobalTables] = useState({});
  const [currentGlobalTables, setCurrentGlobalTables] = useState({});
  const [selectedGlobalTable, setSelectedGlobalTable] = useState({});
  const [globalValues, setGlobalValues] = useState({
    tableId: null,
    columnSelected: "",
    columnSelectedId: null,
  });
  const [dataCategories, setDataCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);

  const { data: globalTables, error: errorGlobalTables } = useSWR(
    urlsApiMg.globalValuesFields
  );

  function globalTableDataByType(data, dataType, globalSelects) {
    return data.map((company) => {
      const tableCategories = company.tableCategories.map((category) => {
        const groupTables = category.groupTables
          .filter((table) => {
            return globalSelects.some(
              (select) => select?.globalTable?.tableId === table._id
            );
          })
          .map((table) => {
            const globalSelect = globalSelects.find(
              (select) => select?.globalTable?.tableId === table._id
            );

            const tableNameWithGlobalSelect =
              globalSelect && globalSelect.alias
                ? `${table.name} - (${globalSelect.alias})`
                : table.name;

            const filteredDataColumns = table.dataColumns.filter(
              (item) => item.name === "Options" || item.dataType === dataType
            );

            const filteredRows = table.rows
              .map((row) => {
                const filteredRow = {};

                filteredDataColumns.forEach((elm) => {
                  const value = row[elm.name];

                  if (elm.name === "Options") {
                    filteredRow[elm.name] = value;
                  } else if (
                    dataType === "number" &&
                    elm.dataType === "number"
                  ) {
                    const numValue = parseFloat(value);
                    if (!isNaN(numValue)) {
                      filteredRow[elm.name] = numValue;
                    }
                  } else if (
                    dataType === "string" &&
                    elm.dataType === "string"
                  ) {
                    filteredRow[elm.name] = String(value);
                  } else if (dataType === "file" && elm.dataType === "file") {
                    filteredRow[elm.name] = value;
                  } else if (dataType === "date" && elm.dataType === "date") {
                    // Convertir a objeto Date si es string

                    filteredRow[elm.name] = value;
                  }
                });

                return filteredRow;
              })
              .filter((row) => Object.keys(row).length > 1);

            return {
              ...table,
              name: tableNameWithGlobalSelect,
              selectGlobalId: globalSelect ? globalSelect._id : null,
              selectGlobalName: globalSelect ? globalSelect.name : null,
              dataColumns: filteredDataColumns,
              rows: filteredRows,
            };
          });

        return {
          ...category,
          groupTables,
        };
      });

      return {
        ...company,
        tableCategories,
      };
    });
  }

  useEffect(() => {
    if (globalTables && !errorGlobalTables && globalTables.length > 0) {
      const algo = globalTableDataByType(globalTables, dataType, globalSelects);
      setDataCategories(algo[0]?.tableCategories);
      if (algo.length > 0 && algo[0]?.tableCategories.length > 0) {
        const existingTable = algo[0]?.tableCategories.filter(
          (elm) => elm.groupTables.length > 0
        );
        setCurrentCategory(existingTable[0]?._id);
        setDataGlobalTables(existingTable[0]?.groupTables);
        setSelectedGlobalTable(existingTable[0]?.groupTables[0]);
        setGlobalValues((current) => ({
          ...current,
          tableId: existingTable[0]?.groupTables[0]?._id,
          columnSelected:
            existingTable[0]?.groupTables[0]?.dataColumns[1]?.name,
          columnSelectedId:
            existingTable[0]?.groupTables[0]?.dataColumns[1]?._id,
        }));
        setField((current) => {
          if (Object.keys(current.columnKeyGlobalSpecs || {}).length === 0) {
            return {
              ...current,
              columnKeyGlobalSpecs: {
                categoryId: existingTable[0]?._id,
                tableId: existingTable[0]?.groupTables[0]._id,
                selectGlobalId:
                  existingTable[0]?.groupTables[0]?.selectGlobalId,
                selectGlobalName:
                  existingTable[0]?.groupTables[0]?.selectGlobalName,
                column: {
                  id: existingTable[0]?.groupTables[0]?.dataColumns[1]?._id,
                  name: existingTable[0]?.groupTables[0]?.dataColumns[1]?.name,
                },
              },
            };
          }
          return current;
        });

        if (
          existingTable[0]?.groupTables[0]?.rows?.length !== 0 ||
          existingTable[0]?.groupTables[0]?.dataColumns?.length > 1
        ) {
          setDialog((current) => ({
            ...current,
            actions: {
              ...current.actions,
              agree: update ? "Update" : "Add",
            },
          }));
        } else {
          setDialog((current) => ({
            ...current,
            actions: {
              ...current.actions,
              agree: null,
            },
          }));
        }
      } else {
        setDialog((current) => ({
          ...current,
          actions: {
            ...current.actions,
            agree: null,
          },
        }));
      }
    }
  }, [globalTables, errorGlobalTables, dataType]);

  const hasRunOnce = useRef(false);
  useEffect(() => {
    if (
      field.columnKeyGlobalSpecs &&
      Object.keys(field.columnKeyGlobalSpecs).length > 0 &&
      dataGlobalTables?.length > 0 &&
      !hasRunOnce.current &&
      dataCategories.length > 0
    ) {
      const dataCurrentCategory = dataCategories?.find(
        (elm) => elm._id === field.columnKeyGlobalSpecs.categoryId
      );
      const dataTable = dataCurrentCategory?.groupTables?.find((item) => {
        return item._id === field.columnKeyGlobalSpecs.tableId;
      });
      if (dataTable) {
        setDataGlobalTables(dataCurrentCategory?.groupTables);
        setCurrentCategory(dataCurrentCategory._id);
        setCurrentGlobalTables(dataTable._id);
        setSelectedGlobalTable(dataTable);
        setGlobalValues((current) => ({
          ...current,
          tableId: field.columnKeyGlobalSpecs.tableId,
          columnSelected: field.columnKeyGlobalSpecs.column.name,
          columnSelectedId: field.columnKeyGlobalSpecs.column.id,
        }));
      }
      hasRunOnce.current = true;
    }
  }, [dataGlobalTables, dataCategories, field]);

  const handelSelectGlobalTable = (e) => {
    e.preventDefault();
    setCurrentGlobalTables(e.target.value);
    const selectedTable = dataGlobalTables.find(
      (table) => table._id === e.target.value
    );
    setSelectedGlobalTable(selectedTable);
    setGlobalValues((current) => ({
      ...current,
      tableId: selectedTable._id,
      columnSelected: selectedTable?.dataColumns[1]?.name,
      columnSelectedId: selectedTable?.dataColumns[1]?._id,
    }));
    setField((current) => ({
      ...current,
      options: [],
      globalTable: {
        categoryId: currentCategory,
        tableId: selectedTable?._id,
      },
      columnKeyGlobalSpecs: {
        categoryId: currentCategory,
        tableId: selectedTable._id,
        selectGlobalId: selectedTable.selectGlobalId,
        selectGlobalName: selectedTable.selectGlobalName,
        column: {
          id: selectedTable?.dataColumns[1]?._id,
          name: selectedTable?.dataColumns[1]?.name,
        },
      },
    }));
    if (
      selectedTable?.rows?.length !== 0 &&
      selectedTable?.dataColumns?.length > 1
    ) {
      setDialog((current) => ({
        ...current,
        actions: {
          ...current.actions,
          agree: update ? "Update" : "Add",
        },
      }));
    } else {
      setDialog((current) => ({
        ...current,
        actions: {
          ...current.actions,
          agree: null,
        },
      }));
    }
  };

  const handelSelectGlobalValues = ({ event, columnSelected }) => {
    if (columnSelected) {
      const selectedColumn = selectedGlobalTable.dataColumns.find(
        (elm) => elm.name === event.target.value
      );
      setGlobalValues((current) => ({
        ...current,
        tableId: selectedGlobalTable._id,
        columnSelected: selectedColumn.name,
        columnSelectedId: selectedColumn._id,
      }));
      setField((current) => ({
        ...current,
        columnKeyGlobalSpecs: {
          categoryId: currentCategory,
          tableId: selectedGlobalTable._id,
          selectGlobalId: selectedGlobalTable.selectGlobalId,
          selectGlobalName: selectedGlobalTable.selectGlobalName,
          column: {
            id: selectedColumn._id,
            name: selectedColumn.name,
          },
        },
      }));
    }
  };

  function getColumnValues(data, tableId, column) {
    if (data) {
      const table = data?.find((table) => {
        return table._id === tableId;
      });
      if (!table) {
        return null;
      }
      const rowValues = {};
      table.rows.forEach((row) => {
        const cellValue = row[column];
        rowValues[row.Options] = cellValue;
      });
      return rowValues;
    }
    return <HorizontalRuleIcon />;
  }

  const handleSelectCategory = (e) => {
    const categorySelected = dataCategories.find(
      (elm) => elm._id === e.target.value
    );
    setCurrentCategory(e.target.value);
    setDataGlobalTables(categorySelected?.groupTables);
    setSelectedGlobalTable(categorySelected?.groupTables[0]);
    setGlobalValues((current) => ({
      ...current,
      tableId: categorySelected?.groupTables[0]?._id,
      columnSelected: categorySelected?.groupTables[0]?.dataColumns[1]?.name,
      columnSelectedId: categorySelected?.groupTables[0]?.dataColumns[1]?._id,
    }));
    setField((current) => ({
      ...current,
      options: [],
      globalTable: {
        categoryId: categorySelected._id,
        tableId: categorySelected?.groupTables[0]?._id,
      },
      columnKeyGlobalSpecs: {
        categoryId: categorySelected._id,
        tableId: categorySelected?.groupTables[0]?._id,
        selectGlobalId: categorySelected?.groupTables[0]?.selectGlobalId,
        selectGlobalName: categorySelected?.groupTables[0]?.selectGlobalName,
        column: {
          id: categorySelected?.groupTables[0]?.dataColumns[1]?._id,
          name: categorySelected?.groupTables[0]?.dataColumns[1]?.name,
        },
      },
    }));
    if (
      categorySelected?.groupTables[0]?.rows?.length !== 0 &&
      categorySelected?.groupTables[0]?.dataColumns?.length > 1
    ) {
      setDialog((current) => ({
        ...current,
        actions: {
          ...current.actions,
          agree: update ? "Update" : "Add",
        },
      }));
    } else {
      setDialog((current) => ({
        ...current,
        actions: {
          ...current.actions,
          agree: null,
        },
      }));
    }
  };

  return (
    <GlobalFieldValue>
      <ContainerConfigGlobalValue>
        {dataGlobalTables?.length > 0 ? (
          <ContainerSelect>
            <div>
              <h2>Unified Standards Catalog Configuration</h2>
              <InfoField
                showVisibilityIcon={true}
                title={`Current Table: ${selectedGlobalTable.name}`}
                message={
                  <GenericTable
                    headers={
                      globalTables && globalTables.length > 0
                        ? globalTables[0].tableCategories
                            ?.find((elm) => elm._id === currentCategory)
                            ?.groupTables?.find(
                              (item) => item._id === selectedGlobalTable._id
                            )?.headers
                        : []
                    }
                    rows={
                      globalTables && globalTables.length > 0
                        ? globalTables[0].tableCategories
                            ?.find((elm) => elm._id === currentCategory)
                            ?.groupTables?.find(
                              (item) => item._id === selectedGlobalTable._id
                            )?.rows
                        : []
                    }
                    keyValues={
                      globalTables && globalTables.length > 0
                        ? globalTables[0].tableCategories
                            ?.find((elm) => elm._id === currentCategory)
                            ?.groupTables?.find(
                              (item) => item._id === selectedGlobalTable._id
                            )?.headers
                        : []
                    }
                    handlerClick={() => {}}
                    state={true}
                    configBar={true}
                    customTableWidth={"100%"}
                    customHeaderWidth={"5%"}
                    from={FROM_TABLE.details}
                  />
                }
              />
            </div>

            <label className="label">Select Category</label>
            <select
              className="select"
              value={currentCategory}
              onChange={(e) => handleSelectCategory(e)}
            >
              {dataCategories && dataCategories.length > 0 ? (
                dataCategories?.map((option, index) => {
                  return (
                    <option key={index} value={option._id}>
                      {option.name}
                    </option>
                  );
                })
              ) : (
                <ContainerValue>
                  <label className="label">
                    First create Table in the Unified Standards Catalog module
                    <Link
                      to="/config-globalValues-Fields"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Go to link.
                    </Link>
                  </label>
                </ContainerValue>
              )}
            </select>
            <label className="label">Select Standards Catalog Table</label>
            <select
              id={currentGlobalTables?._id}
              className="select"
              value={currentGlobalTables || ""}
              onChange={handelSelectGlobalTable}
            >
              {dataGlobalTables && dataGlobalTables.length > 0 ? (
                dataGlobalTables.map((option, index) => (
                  <option key={index} value={option._id}>
                    {option.name}
                  </option>
                ))
              ) : (
                <ContainerValue>
                  <label className="label">
                    First create Table in the Unified Standards Catalog module
                    <Link
                      to="/config-globalValues-Fields"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to link.
                    </Link>
                  </label>
                </ContainerValue>
              )}
            </select>
            {selectedGlobalTable &&
            selectedGlobalTable?.dataColumns?.length > 1 ? (
              <>
                <label className="label">Select Attribute</label>
                <select
                  id={globalValues.columnSelectedId}
                  className="select"
                  value={globalValues.columnSelected}
                  onChange={(e) =>
                    handelSelectGlobalValues({
                      event: e,
                      columnSelected: e.target.value,
                    })
                  }
                >
                  {selectedGlobalTable?.dataColumns?.map((elm, index) => {
                    if (index !== 0 && dataType === elm.dataType) {
                      return (
                        <option key={elm._id} value={elm.name}>
                          {elm.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  {`First Create Columns (Type ${dataType}) In The Unified Standards
Catalog  Module`}

                  <Link
                    to="/config-globalValues-Fields"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go to link.
                  </Link>
                </label>
              </ContainerValue>
            )}

            {selectedGlobalTable && selectedGlobalTable?.rows?.length !== 0 ? (
              <ContainerValue>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    className="label"
                    style={{
                      width: "50%",
                      justifyContent: "center",
                    }}
                  >
                    Option Selected
                  </label>
                  <label
                    className="label"
                    style={{
                      width: "50%",
                      justifyContent: "center",
                    }}
                  >
                    Global Value
                  </label>
                </div>
                {selectedGlobalTable?.rows?.map((row, index) => {
                  const values = getColumnValues(
                    dataGlobalTables,
                    globalValues.tableId,
                    globalValues.columnSelected
                  );
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "solid 1px #cccccc",
                        borderRadius: "5px",
                        background: PRIMARY_COLOR,
                        color: WHITE_COLOR,
                      }}
                    >
                      <div style={{ width: "50%" }}>{row.Options}</div>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "900",
                          padding: "10px",
                          background: WHITE_COLOR,
                          color: BLACK_COLOR,
                        }}
                      >
                        {values && values[row.Options] ? (
                          <label
                            style={{
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            {isUrl(values[row.Options]) ? (
                              <a
                                href={values[row.Options]}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  fontSize: "x-small",
                                }}
                              >
                                {nameUrl(values[row.Options])}
                              </a>
                            ) : (
                              values[row.Options]
                            )}
                          </label>
                        ) : (
                          <HorizontalRuleIcon />
                        )}
                      </div>
                    </div>
                  );
                })}
              </ContainerValue>
            ) : (
              <ContainerValue>
                <label className="label">
                  {`First Create Rows In The Unified Standards
Catalog  Module`}

                  <Link
                    to="/config-globalValues-Fields"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go to link.
                  </Link>
                </label>
              </ContainerValue>
            )}
          </ContainerSelect>
        ) : (
          <ContainerSelect>
            <label className="label">Select Category</label>
            <select
              className="select"
              value={currentCategory}
              onChange={(e) => handleSelectCategory(e)}
            >
              {dataCategories && dataCategories.length > 0 ? (
                dataCategories?.map((option, index) => {
                  return (
                    <option key={index} value={option._id}>
                      {option.name}
                    </option>
                  );
                })
              ) : (
                <ContainerValue>
                  <label className="label">
                    First create Table in the Unified Standards Catalog module
                    <Link
                      to="/config-globalValues-Fields"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Go to link.
                    </Link>
                  </label>
                </ContainerValue>
              )}
            </select>
            <ContainerValue>
              <label className="label">
                First create table in module
                <Link
                  to="/config-globalValues-Fields"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Unified Standards Catalog
                </Link>
              </label>
            </ContainerValue>
            <ContainerValue>
              <label className="label">
                Or create a global select in this library with a table of this
                category
              </label>
            </ContainerValue>
          </ContainerSelect>
        )}
      </ContainerConfigGlobalValue>
    </GlobalFieldValue>
  );
};
