export const config = {
  URL_BACKEND_PG: process.env.REACT_APP_URL_BACKEND,
  URL_BACKEND_MG: process.env.REACT_APP_URL_MG_API,
  URL_API_S3: process.env.REACT_APP_URL_API_S3,
  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
  USER_TOKEN: localStorage.getItem("token"),
  ADMIN_COMPANY_ID: localStorage.getItem("adminCompanyId"),
  USER_ID: localStorage.getItem("userId"),
  ROLE: localStorage.getItem("role"),
};

const envConfig = {
  local: {
    bucketName: "bucket-develop-local",
  },
  production: {
    bucketName: "bucket-production",
  },
  develop: {
    bucketName: "bucket-develop-deploy",
  },
};

export const getBucketNameEnv = () => {
  const env = process.env.REACT_APP_ENV || "local";
  return envConfig[env]?.bucketName || null;
};
