import styled from "styled-components";
import { WHITE_COLOR } from "utils/const";
// import { PRIMARY_COLOR } from "utils/const";

export const Loader = styled.div`
  display: grid;
  place-content: center;
  width: 200px;
  height: 200px;
  background-image: ${WHITE_COLOR};
  /* border-top: 16px solid rgba(14, 77, 69, 0.571); */
  /* border-bottom: 16px solid rgba(14, 77, 69, 0.571); */
  border-radius: 50%;
  animation: spin 2s linear infinite;

  .logo {
    width: 100px;
    height: 100px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingContainer = styled.div`
  /* display: grid;
  place-content: center;
  height: ${(props) => props.height || "70vh"};
  text-align: center; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 7rem);
  height: auto;
  padding: 5rem 0;

  p {
    margin: 20px 0;
  }
`;

export const LoadingContainerDt = styled.div`
  display: flex;
  place-content: center;
  height: 100vh;
  width: 100vw;
  margin-top: -80px;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.55;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeText {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.15;
    }
    100% {
      opacity: 0;
    }
  }

  p {
    margin: 20px 0;
  }
  .neuron {
    position: absolute;
    left: ${({left}) => left || "22%"};
    top: ${({top}) => top || "20%"};
    width: ${({width}) => width || "30%"};
    opacity: 0.225;
  }
  .logo {
    display: flex;
    width: 45vw;
    animation: fade 3s infinite;
    justify-content: center;
    align-items: center;
    z-index: 3;
    opacity: 0.15;
    filter: brightness(49%) contrast(100);
  }

  p {
    position: absolute;
    left: 47%;
    top: 80%;
    font-size: 40px;
    color: #292929;
    animation: fadeText 3s infinite;
    font-weight: 600;
  }
`;
