import axios from "axios";
import { config } from "config.js";

const uploadCSV = async (tableId, tableCategoriesId, groupId, file, upload) => {
  try {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("tableId", tableId);
    formData.append("tableCategoriesId", tableCategoriesId);
    formData.append("groupId", groupId);
    formData.append("file", file);
    formData.append("upload", upload);

    /*  const payload = {
        tableId,
        tableCategoriesId,
        groupId,
        file: JSON.stringify(file), // Enviar como JSON en el cuerpo
      };*/

    const res = await axios.post(
      `${config.URL_BACKEND_MG}global-list-table/upload-csv`,
      formData,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": "multipart/form-data", // El encabezado necesario para enviar archivos
        },
      }
    );
    return { data: res.data, res };
  } catch (error) {
    return { error };
  }
};

export default uploadCSV;
