import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { config, getBucketNameEnv } from "config.js";
import { createBucket } from "services/s3Manager/s3Manager";
import { useStoreBucketUser } from "zustandGloabalStore/bucketRoot";
import {
  FormContainer,
  WrapperForm,
  Form,
  Label,
  ContainerTerms,
  ContainerButtonCenterUser,
  Button,
  MessageError,
  MessageSuccess,
  CustomSelect,
} from "components/Forms/FormStyles";

import InputComponent from "./Input";
import fetchRoles from "hooks/fetchRoles";
import { useUserStore } from "zustandGloabalStore/user";
import saveOperator from "services/users/saveOperator";
import { useSWRConfig } from "swr";
import { urls } from "utils/urlKeys";
import { roles as dataRoles } from "utils/roles";

const FormSignUp = ({ handleClose }) => {
  const { mutate } = useSWRConfig();

  const [firstName, setFirstName] = useState({ field: "", valid: null });
  const [secondName, setSecondName] = useState({ field: "", valid: null });
  const [firstLastName, setFirstLastName] = useState({
    field: "",
    valid: null,
  });
  const [jobTitle, setJobTitle] = useState({ field: "", valid: null });
  const [email, setEmail] = useState({ field: "", valid: null });
  const [area, setArea] = useState({ field: "", valid: null });
  const [role, setRole] = useState();
  const [terms, setTerms] = useState(false);
  const [FormValidated, setFormValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    instanceId,
    instanceManager,
    role: userRole,
  } = useUserStore((state) => state);

  const [messageError, setMessageError] = useState("");

  const bucketUser = useStoreBucketUser((state) => state.bucketUser);

  const expressions = {
    firstName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    secondName: /^[a-zA-ZÀ-ÿ]{0,40}$/, // letters only
    firstLastName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    jobTitle: /^[a-zA-ZÀ-ÿ\s]{1,50}$/, // letters and spaces only
    area: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/,
    other: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/,
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const { data: roles, error } = fetchRoles();

  useEffect(() => {
    if (roles && roles.length > 0) {
      setRole(roles[0].id);
    }
  }, [roles]);

  const onChangeTerms = (e) => {
    setTerms(e.target.checked);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //check the request fields filled

    //array to store the names of the fields that are not filled

    const missingFields = [];

    //check if the fields are filled
    if (!firstName.field) {
      missingFields.push("First Name");
    }
    if (!firstLastName.field) {
      missingFields.push("Last Name");
    }
    if (!jobTitle.field) {
      missingFields.push("Job Title");
    }
    if (!email.field) {
      missingFields.push("Email");
    }
    if (!area.field) {
      missingFields.push("Area");
    }
    if (!terms) {
      setMessageError("Accept terms and conditions");
      setLoading(false);
      return;
    }
    //build the message with the missing fields
    let errorMessage = "";
    if (missingFields.length > 0) {
      errorMessage = `The following fields are required: ${missingFields.join(
        ", "
      )}`;
    }

    //update mmessageError state
    setMessageError(errorMessage);

    if (errorMessage) {
      setFormValidated(false);
      setLoading(false);
      return;
    }

    if (
      firstName.valid === "true" &&
      firstLastName.valid === "true" &&
      jobTitle.valid === "true" &&
      email.valid === "true" &&
      area.valid === "true" &&
      terms
    ) {
      const body = {
        firstName: firstName.field,
        secondName: secondName.field,
        firstLastName: firstLastName.field,
        area: area.field,
        roleId: parseInt(role),
        jobTitle: jobTitle.field,
        instanceId:
          userRole === dataRoles.decimetrixAdmin
            ? parseInt(instanceManager.id)
            : parseInt(instanceId),
        user: {
          email: email.field,
        },
      };

      const bucketNameEnv = getBucketNameEnv();

      let responseCreateBucket;
      if (bucketNameEnv === "bucket-production") {
        responseCreateBucket = await createBucket({
          bucketName: bucketUser?.instanceNameFormat,
          folderName: email.field,
        });
      } else {
        responseCreateBucket = await createBucket({
          bucketName: bucketNameEnv,
          folderName: `${bucketUser?.instanceNameFormat}/${email?.field}`,
        });
      }

      if (responseCreateBucket) {
        try {
          const res = await saveOperator(body); // Llamada a saveOperator
          await mutate(
            urls.users.getOperatorsInstanceDynamic(instanceManager.id)
          );
          await mutate(urls.users.getOperatorsInstanceDynamic(instanceId));
          setLoading(false);

          if (res.status === 409) {
            setFormValidated(false);
            const errorMessage = res.data?.message || "This email is already registered";
            setMessageError(errorMessage);
          } else if (res.status === 200 || res.status === 201) {
            handleClose();
            setFormValidated(true);
            setMessageError(null);
            setFirstName({ field: "", valid: null });
            setSecondName({ field: "", valid: null });
            setArea({ field: "", valid: null });
            setFirstLastName({ field: "", valid: null });
            setEmail({ field: "", valid: null });
          } else {
            setFormValidated(false);
            setMessageError("Fill all the fields correctly.");
          }
        } catch (error) {
          console.error("Error saving operator:", error);

          // Manejo de errores específicos
          if (error.response && error.response.status === 409) {
            const errorMessage = error.response.data?.message || "This email is already registered";
            setMessageError(errorMessage);
          } else {
            setMessageError("An unexpected error occurred while saving the operator.");
          }

          setLoading(false);
          setFormValidated(false);
        }
      } else {
        setLoading(false);
        setFormValidated(false);
        setMessageError("Error saving, try again");
      }
    }
  };

  const changeRoleUser = (event) => {
    setRole(event.target.value);
  };

  return (
    <FormContainer>
      <WrapperForm>
        <Form action="" onSubmit={onSubmit}>
          <InputComponent
            state={firstName}
            changeState={setFirstName}
            type="text"
            label="First Name *"
            name="firstName"
            legendError="The name can only contain letters."
            regularExpression={expressions.firstName}
          />
          <InputComponent
            state={secondName}
            changeState={setSecondName}
            type="text"
            label="Second Name"
            name="secondName"
            legendError="The name can only contain letters."
            regularExpression={expressions.secondName}
          />
          <InputComponent
            state={firstLastName}
            changeState={setFirstLastName}
            type="text"
            label="Last Name *"
            name="firstLastName"
            legendError="The last name can only contain letters."
            regularExpression={expressions.firstLastName}
          />
          <InputComponent
            state={jobTitle}
            changeState={setJobTitle}
            type="text"
            label="Job Title *"
            name="jobTitle"
            legendError="The job title can only contain letters and spaces.."
            regularExpression={expressions.jobTitle}
          />
          <InputComponent
            state={email}
            changeState={setEmail}
            type="email"
            label="E-mail *"
            placeholder="email@email.com"
            name="email"
            legendError="The E-mail can only contain letters, numbers and periods."
            regularExpression={expressions.email}
          />
          <CustomSelect>
            <label htmlFor="customer" style={{ margin: "10px" }}>
              Role *
            </label>
            <select
              id="customer"
              name="customer"
              onChange={changeRoleUser}
              value={role}
            >
              {!error &&
                roles &&
                roles.map((role) => (
                  <option value={role.id}>{role.role}</option>
                ))}
            </select>
          </CustomSelect>

          <InputComponent
            state={area}
            changeState={setArea}
            type="text"
            label="Area *"
            name="area"
            legendError="The area can only contain letters, numbers and periods."
            regularExpression={expressions.area}
          />

          <ContainerTerms>
            <Label>
              <input
                type="checkbox"
                name="terms"
                id="terms"
                checked={terms}
                onChange={onChangeTerms}
              />
              I accept the terms and conditions. *
            </Label>
          </ContainerTerms>
          <ContainerButtonCenterUser>
            <Button type="submit">Register</Button>
            {loading && (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
          </ContainerButtonCenterUser>
        </Form>
        {FormValidated ? (
          <MessageSuccess>
            <p>User saved successfully</p>
          </MessageSuccess>
        ) : messageError !== "" ? (
          <MessageError>
            <p>{messageError}</p>
          </MessageError>
        ) : null}
      </WrapperForm>
    </FormContainer>
  );
};

export default FormSignUp;
