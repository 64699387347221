// React
import { useCallback, useEffect, useState } from "react";
// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setFlyTo, setRowNum } from "redux/actions/digitalTwin";
// Components
import GenericTable from "components/Tables/GenericTable/GenericTable";
//Dependecies
import moment from "moment-timezone";
// Styles
import { SearchPosition } from "./styles";
// Hooks
import useGet from "hooks/useFetch";
import { useFetchComponentsLibraries } from "hooks/fetchLibraries";
import useShowDetails from "hooks/useShowDetails";
// Const
import { keyValuesComponents, headersComponents } from "./keyAndHeadersTables";
import { FROM_TABLE } from "utils/constStrings";
import { replaceDotsWithUnderscores } from "helpers/removeAccents";

/**
 * Renders a table component based on the provided props.
 *
 * @param {Object} pointLibrary - The point library object.
 * @param {function} setRows - The function to set the rows state.
 * @param {string} attributte - The attribute string.
 * @param {function} setLabels - The function to set the labels state.
 * @param {string} label - The label string.
 * @param {function} setTableVisibility - The function to set the table visibility state.
 * @param {boolean} tableVisibility - The table visibility boolean.
 * @param {function} handleClose - The function to handle closing the table.
 * @param {string} typeOfTable - The type of table string.
 * @param {string} typeOfLibrary - The type of library string.
 * @param {function} handleDrawerClose - The function to handle closing the drawer.
 * @param {function} handleDrawerOpen - The function to handle opening the drawer.
 * @param {boolean} state - The state boolean.
 * @return {JSX.Element} The rendered table component.
 */
export default function TableComponents({
  pointLibrary,
  setRows: setRowsMain,
  attributte,
  setLabels,
  label,
  setTableVisibility,
  tableVisibility,
  handleClose,
  typeOfTable,
  typeOfLibrary,
  handleDrawerClose,
  handleDrawerOpen,
  state = false,
  orderColumns,
  dataChecked,
  setDataChecked,
}) {
  const [rows, setRows] = useState([]);

  const dispatch = useDispatch();
  const dataComponentPolygon = useSelector(
    (state) => state.adminReducer.dataComponentPolygon,
    shallowEqual
  );
  const dataEventPolygon = useSelector(
    (state) => state.adminReducer.dataEventPolygon,
    shallowEqual
  );

  const rowNumber = useSelector((state) => state.digitalTwinReducer.rowNum);

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [objects] = useGet(`api/v1/admin-company/objects/${adminCompanyId}`);

  const map = useSelector(
    (state) => state.digitalTwinReducer.map,
    shallowEqual
  );
  const flyToRedux = useSelector(
    (state) => state.digitalTwinReducer.flyTo,
    shallowEqual
  );

  const { data: dataLibrary, error: errorLibrary } =
    useFetchComponentsLibraries({ id: pointLibrary });

  // Extract positions fields
  const libraryFields = dataLibrary?.[0]?.fields || [];
  const fieldOrderMap = new Map(); //order of fields

  libraryFields.forEach((field) => {
    if (field.indexPosition !== undefined &&
      field.type !== "captureMedia" &&
      field.type !== "notification" &&
      field.type !== "lineTag") {
      fieldOrderMap.set(field.alias || field.name, field.indexPosition);
    }
  });

  useEffect(() => {
    setRows([]);
  }, [pointLibrary]);

  const { handlerClickComponentId } = useShowDetails();

  const handerClickTable = (componentId) => {
    handlerClickComponentId(componentId);
    localStorage.setItem("currentId", (componentId = { componentId }));
  };

  const removeLineBreak = (text) => {
    if (typeof text === "string") return text.replace(/(\r\n|\n|\r)/gm, " ");
    if (text && isNaN(text) && typeof text !== "object") {
      return text.toString().replace(/(\r\n|\n|\r)/gm, " ");
    }
    return text;
  };

  const markPoint = useCallback(
    (id, lat, lng) => {
      if (state) {
        const data = {
          id,
          lat,
          lng,
        };
        const dataMarketPoint = JSON.stringify(data);
        localStorage.setItem("dataMarketPoint", dataMarketPoint);
        setTimeout(() => {
          const setData = {
            id: "",
            lat: "",
            lng: "",
          };
          const setDataMarketPoint = JSON.stringify(setData);
          localStorage.setItem("dataMarketPoint", setDataMarketPoint);
        }, 7000);
      } else {
        dispatch(
          setFlyTo({
            ...flyToRedux,
            object: {
              id,
              latitude: parseFloat(lat),
              longitude: parseFloat(lng),
            },
          })
        );
        setTableVisibility("none");

        setTimeout(() => {
          dispatch(
            setFlyTo({
              ...flyToRedux,
              object: {
                id: "",
                latitude: "",
                longitude: "",
              },
            })
          );
        }, 5000);
      }
    },
    [dispatch, flyToRedux, state, setTableVisibility]
  );

  const flyTo = useCallback(
    (e, id) => {
      const { value } = e.target;
      const [lng, lat] = value.split(",");
      if (state) {
        const coordinates = [lng, lat];
        const parseCoordinates = JSON.stringify(coordinates);
        localStorage.setItem("coordinates", parseCoordinates);
        markPoint(id, lat, lng);
      } else {
        map.flyTo({
          center: [parseFloat(lng), parseFloat(lat)],
          duration: 2000,
          zoom: 24,
        });
        markPoint(id, lat, lng);
        setTableVisibility("none");
      }
    },
    [map, markPoint, state, setTableVisibility]
  );

  const createRows = useCallback(
    (data) => {
      const newDataArray = data?.map((dataItem) => {
        let attributesCopy = [...dataItem.attributes];

        const res = attributesCopy.map((newData) => {
          const elm = dataLibrary?.[0]?.fields?.find((item) => {
            return (
              item?.name === newData?.name ||
              item?.alias === newData?.name ||
              item?.name === newData?.alias ||
              item?.alias === newData?.alias
            );
          });

          if (elm && elm.QAQC && !elm.alias.includes("✅")) {
            return (elm.alias = elm.alias + "✅");
          }
          if (elm) {
            if (
              elm.type !== "captureMedia" &&
              elm.type !== "notification" &&
              elm.type !== "lineTag"
            ) {
              if (elm.type === "number" || elm.type === "operation") {
                if (newData.value && newData.value[0] === "{") {
                  const valueParse = JSON.parse(newData.value);
                  if (valueParse.value === "") {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: NaN,
                      _id: newData._id,
                    };
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: valueParse.value,
                      _id: newData._id,
                    };
                  }
                } else {
                  if (newData.value && !isNaN(newData.value)) {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: parseFloat(newData.value),
                      _id: newData._id,
                    };
                  }
                }
              } else {
                if (elm.type === "date" && !newData.value === !NaN) {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: "0000-00-00",
                    _id: newData._id,
                  };
                } else {
                  if (!newData.value === !NaN || newData.value === " ") {
                    if (elm.type === "date") {
                      return {
                        name: elm.name,
                        alias: elm.alias,
                        value: "0000-00-00",
                        _id: newData._id,
                      };
                    } else {
                      return {
                        name: elm.name,
                        alias: elm.alias,
                        value: "Undefined data",
                        _id: newData._id,
                      };
                    }
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: newData.value,
                      _id: newData._id,
                    };
                  }
                }
              }
            } else {
              return {
                name: "excludeObject",
                alias: "excludeObject",
                value: "excludeObject",
                _id: "excludeObject",
              };
            }
          }
          return {
            ...newData,
            alias: newData.name,
          };
        });

        const allFields = dataLibrary?.[0]?.fields
          ?.map((elm) => {
            if (
              elm.type !== "captureMedia" &&
              elm.type !== "notification" &&
              elm.type !== "lineTag"
            ) {
              return {
                name: elm.name,
                alias: elm.alias,
                type: elm.type,
              };
            }
          })
          .filter((it) => it !== undefined);

        const matchMissingAttributes = allFields?.filter((elm) => {
          return res.every((item) => {
            return item.name !== elm.name;
          });
        });

        if (matchMissingAttributes?.length > 0) {
          matchMissingAttributes.forEach((elm) => {
            if (elm.type === "date") {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: "0000-00-00",
              });
            } else if (elm.type === "number" || elm.type === "operation") {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: NaN,
              });
            } else {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: "Undefined data",
              });
            }
          });
        }

        return { ...dataItem, attributes: res };
      });

      const rows = newDataArray.map((item) => {
        const convertedTime = moment
          .tz(item.date, "UTC")
          .utcOffset(item.timeZoneOffset || "-05:00")
          .tz(item.timeZone || "America/Bogota");
        const object = objects?.find((object) => {
          return object.id === item?.objectId;
        });
        const { locationComponent, id } = item;
        const { latitude, longitude } = locationComponent;
        const totalEvents = dataEventPolygon?.filter(
          (event) => event.pointComponentId === item.id
        )?.length;

        const row = {
          id: removeLineBreak(item.id),
          flyTo: (
            <button
              className="button"
              value={`${longitude},${latitude}`}
              onClick={(e) => flyTo(e, id)}
            >
              Fly
            </button>
          ),
          markPoint: (
            <button
              className="button"
              onClick={() => markPoint(id, latitude, longitude)}
            >
              Flash
            </button>
          ),
          typeOfComponent: removeLineBreak(item?.pointTypeComponent.name),
          objectId: item?.objectId,
          typeOfElement: removeLineBreak(object?.typeElement?.name),
          localDate: removeLineBreak(
            convertedTime.format("YYYY-MM-DD HH:mm:ss z").substring(0, 19)
          ),
          dateUTC: removeLineBreak(item.date.substring(0, 10)),
          user: removeLineBreak(
            (item.user &&
              item.user.adminCompany &&
              item.user.adminCompany.firstName +
              " " +
              item.user.adminCompany.firstLastName) ||
            (item.user &&
              item.user.operator &&
              item.user.operator.firstName +
              " " +
              item.user.operator.firstLastName) ||
            (item.user &&
              item.user.adminDecimetrix &&
              item.user.adminDecimetrix.firstName +
              " " +
              item.user.adminDecimetrix.firstLastName)
          ),
          latitude: removeLineBreak(item.locationComponent.latitude),
          longitude: removeLineBreak(item.locationComponent.longitude),
          totalEvents,
        };

        if (item.attributes.length !== 0) {
          item.attributes.forEach((object) => {
            if (object.name !== "excludeObject") {
              try {
                if (typeof JSON.parse(object.value) === "object") {
                  const value = JSON.parse(object.value);
                  row[replaceDotsWithUnderscores(object.alias)] = parseFloat(
                    value.value
                  );
                } else {
                  return (row[replaceDotsWithUnderscores(object.alias)] =
                    !isNaN(object.value)
                      ? parseFloat(object.value)
                      : object.value);
                }
              } catch (error) {
                return (row[replaceDotsWithUnderscores(object.alias)] = !isNaN(
                  object.value
                )
                  ? parseFloat(object.value)
                  : object.value);
              }
            }
          });
        }

        for (const key in row) {
          if (attributte === "") return row;
          if (!row[key] || typeof row[key] === "object") continue;
          if (label !== "all" && key !== label) continue;

          const value = isNaN(row[key]) ? row[key] : row[key].toString();
          try {
            if (value.toUpperCase().includes(attributte.toUpperCase())) {
              return row;
            }
          } catch (error) {
            console.error(attributte);
          }
        }
        return null;
      });

      const rowsFilter = rows.filter((item) => item !== null);

      return rowsFilter;
    },
    [
      attributte,
      label,
      flyTo,
      objects,
      markPoint,
      dataLibrary,
      dataEventPolygon,
      errorLibrary,
      rowNumber,
    ]
  );

  const updateLabels = useCallback(() => {
    const labels = {};
    for (const key in keyValuesComponents) {
      labels[keyValuesComponents[key]] = headersComponents[key];
    }
    setLabels(labels);
  }, [setLabels]);

  useEffect(() => {
    const headers = [];
    headersComponents.forEach((item, index) => {
      const header = {
        label: item,
        key: keyValuesComponents[index],
      };
      headers.push(header);
    });
  }, []);

  useEffect(() => {
    if (!pointLibrary) return;
    const newDataComponent = dataComponentPolygon.filter((data) => {
      return data?.pointTypeComponent?.pointLibraryComponentId === pointLibrary;
    });
    const rows = createRows(newDataComponent);

    setRows(() => [...rows]);
    setRowsMain(() => [...rows]);
    updateLabels();
  }, [
    dataComponentPolygon,
    pointLibrary,
    setRowsMain,
    updateLabels,
    dataLibrary,
    rowNumber,
  ]);

  useEffect(() => {
    dispatch(setRowNum(rows.length));
  }, [rows.length, dispatch]);
  return (
    <div className="body">
      {rows.length > 0 && (
        <SearchPosition>
          <GenericTable
            headers={headersComponents}
            rows={rows}
            orderColumns={orderColumns}
            rowsDynamic={rows}
            typeOfTable={typeOfTable}
            typeOfLibrary={typeOfLibrary}
            keyValues={keyValuesComponents}
            handlerClick={handerClickTable}
            setTableVisibility={setTableVisibility}
            tableVisibility={tableVisibility}
            handleClose={handleClose}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            style={{
              small: true,
              maxHeight: state ? 100 : 50,
              width: 55,
            }}
            digitalTwin={true}
            state={state}
            pointLibraryId={pointLibrary}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
            from={FROM_TABLE.digitalTwin}
            lines={false}
            fieldOrderMap={fieldOrderMap}
          />
        </SearchPosition>
      )}
    </div>
  );
}
