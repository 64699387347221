import useSwr from "swr";
import { urls } from "utils/urlKeys";

const FetchEvents = (libraryId) => {
  const url = urls.events.typeEventsParams(
    libraryId ? `libraryId=${libraryId}` : ""
  );

  const { data, error } = useSwr(url);

  return { data, error };
};

export default FetchEvents;
