/**
 * Creates a structured object with attributes for API submission.
 *
 * This function builds a complete request body by processing form fields
 * into a normalized attributes array with appropriate metadata.
 *
 * @function createBodyEvent
 * @param {Object} options - Configuration options
 * @param {Object} options.dataForm - Form data containing field values
 * @param {Object} options.typeEventFields - Field definitions with aliases
 * @param {Object} options.allOperators - List of available operators/users
 * @returns {Object} Structured body object ready for submission with normalized attributes
 *
 * @example
 * const bodyObject = createBodyEvent({
 *   dataForm: formValues,
 *   typeEventFields: fieldDefinitions,
 *   allOperators: operatorsList
 * });
 * // Returns a structured object with normalized attributes including aliases
 */
export const createBodyEvent = ({
  dataForm,
  typeEventFields,
  allOperators,
}) => {
  // Extract key fields from dataForm
  const keyFields = getKeyFields(dataForm);

  // Create attributes object
  const attributes = createAttributes(dataForm, keyFields);

  // Filter out attributes with empty values
  const filetAttributes = filterEmptyAttributes(attributes);

  // add alias to every field, if exists
  const objectAttributes = filetAttributes.map((item) => {
    const field = typeEventFields?.fields.find((it) => it.name === item.name);
    if (field) {
      item.alias = field?.alias || "";
    }
    if (field?.type === "delegate") {
      const userDelegate = allOperators?.find(
        (elm) => `${elm?.firstName} ${elm?.firstLastName}` === item.value
      );
      item.userId = userDelegate.userId;
    }
    return item;
  });

  const newBody = {
    attributes: objectAttributes,
  };

  return newBody;
};

/**
 * Extracts all field names from form data.
 *
 * @private
 * @function getKeyFields
 * @param {Object} dataForm - Form data containing all fields
 * @returns {Array<string>} Array of all field names from the form data
 */
const getKeyFields = (dataForm) => {
  return Object.keys(dataForm);
};

/**
 * Creates an array of attribute objects from form fields.
 *
 * @private
 * @function createAttributes
 * @param {Object} dataForm - Form data containing field values
 * @param {Array<string>} keyFields - Field names to be processed
 * @returns {Array<Object>} Array of attribute objects with name, value and userId
 */
const createAttributes = (dataForm, keyFields) => {
  return keyFields.map((key) => {
    let value = dataForm[key];
    if (typeof value === "boolean") value = value.toString();
    return {
      name: key,
      value: value === "" ? " " : value,
      userId: dataForm[`delegate-userId-${key}`],
    };
  });
};

/**
 * Filters out attributes with empty values.
 *
 * @private
 * @function filterEmptyAttributes
 * @param {Array<Object>} attributes - Array of attribute objects
 * @returns {Array<Object>} Filtered array excluding attributes with empty values
 */
const filterEmptyAttributes = (attributes) => {
  return attributes.filter((attribute) => attribute.value !== "");
};
