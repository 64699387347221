import axios from "axios";
import { s3MaganerUrls } from "./urls";

const createThermalAnalysis = async (body) => {
  const token = localStorage.getItem("token");
  const result = await axios.post(s3MaganerUrls.thermalImages.upload, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return { data };
};

export default createThermalAnalysis;
