import axios from "axios";
import { urls } from "utils/urlKeys";

const findTypeEventsTypeElements = async ({
  typeElementId,
  pointTypeEventId,
}) => {
  const token = localStorage.getItem("token");

  // Construcción de la URL base
  let queryParams = new URLSearchParams();
  if (typeElementId) queryParams.append("typeElementId", typeElementId);
  if (pointTypeEventId)
    queryParams.append("pointTypeEventId", pointTypeEventId);

  const url = urls.events.typeEventsTypeElements(queryParams.toString());

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching type events type elements:", error);
    throw error;
  }
};

export default findTypeEventsTypeElements;
