import styled from "styled-components";
import { WHITE_SECOND_COLOR } from "utils/const";

export const ContentButton = styled.div`
  margin: ${(props) => props.margin}px;
  width: 100%;
  button {
    width: 100%;
    display: flex;
    padding: ${(props) => props.padding};
    border: none;
    color: ${WHITE_SECOND_COLOR};
    border-radius: 5px;
    background-color: ${(props) =>
      props.primaryColor
        ? props.primaryColor
        : "var(--background-primary_color)"};
    align-items: center;
    justify-content: center;
  }

  span {
    width: 40px;
  }

  button:hover {
    background-color: ${(props) =>
      props.secondaryColor
        ? props.secondaryColor
        : "var(--background-secondary_color)"};
    cursor: pointer;
  }
`;
