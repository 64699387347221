import { useCallback, useEffect, useState } from "react";
import {
  DynamicColorsContainer,
  DynamicColorsFields,
  LibraryField,
  MappingLibraryContainer,
} from "./DynamicPointsColorStyles";
import { updateBackendMapSettings } from "services/updateBackendMapSettings";
import { setMapSettings } from "redux/actions/";
import { useDispatch } from "react-redux";
import { CustomizedAccordion } from "./DynamicPointsColorStyles";
import { useStyles } from "components/Accordions/AccordionStyles";
import { AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwitchSquared from "../helpers/switchSquared";
import { BACKGROUND_SECOND_GREY } from "utils/const";
import { urlsApiMg } from "utils/urlKeys";
import { mutate } from "swr";

const pointTypesColors = [
  ["object", "#ff5f7e"],
  ["component", "#251351"],
  ["event", "#810955"],
];
export default function SelectDynamicPointsColor({
  configData,
  setConfigData,
}) {
  const [checkUserConfig, setCheckUserConfig] = useState(
    configData.userConfigColors !== undefined &&
      typeof configData.userConfigColors.checkedPoints === "boolean"
      ? configData.userConfigColors.checkedPoints
      : false
  );

  const [
    optionsSelectedObjectsUserConfig,
    setOptionsSelectedObjectsUserConfig,
  ] = useState(
    configData.userConfigColors !== undefined &&
      configData.userConfigColors?.colorObjects?.length > 0
      ? configData.userConfigColors?.colorObjects
      : []
  );
  const [
    optionsSelectedComponentsUserConfig,
    setOptionsSelectedComponentsUserConfig,
  ] = useState(
    configData.userConfigColors !== undefined &&
      configData.userConfigColors.colorComponents.length > 0
      ? configData.userConfigColors.colorComponents
      : []
  );
  const [optionsSelectedEventsUserConfig, setOptionsSelectedEventsUserConfig] =
    useState(
      configData.userConfigColors !== undefined &&
        configData.userConfigColors.colorEvents.length > 0
        ? configData.userConfigColors.colorEvents
        : []
    );
  // campos por tipo de libreria (objs/evts/cmps)
  const [fieldsByLibraryObjects, setFieldsByLibraryObjects] = useState([]);
  const [fieldsByLibraryComponents, setFieldsByLibraryComponents] = useState(
    []
  );
  const [fieldsByLibraryEvents, setFieldsByLibraryEvents] = useState([]);
  // librerias por objs/evts/cmps
  const [pointLibrariesObjects, setPointLibrariesObjects] = useState([]);
  const [pointLibrariesComponents, setPointLibrariesComponents] = useState([]);
  const [pointLibrariesEvents, setPointLibrariesEvents] = useState([]);
  // campos dinamicos seleccionados por libreria por objs/evts/cmps
  const [optionsSelectedObjects, setOptionsSelectedObjects] = useState(
    configData.objectsColorsSelected !== undefined &&
      configData.objectsColorsSelected?.length > 0
      ? configData.objectsColorsSelected
      : [[0, "pointType", "object"]]
  );

  const [optionsSelectedComponents, setOptionsSelectedComponents] = useState(
    configData.componentsColorsSelected !== undefined &&
      configData.componentsColorsSelected?.length > 0
      ? configData.componentsColorsSelected
      : [[0, "pointType", "component"]]
  );

  const [optionsSelectedEvents, setOptionsSelectedEvents] = useState(
    configData.eventsColorsSelected !== undefined &&
      configData.eventsColorsSelected?.length > 0
      ? configData.eventsColorsSelected
      : [[0, "pointType", "event"]]
  );
  const dispatch = useDispatch();

  const userId = parseInt(localStorage.getItem("userId"));

  // configuracion de las opciones seleccionadas de objs/evts/cmps
  useEffect(() => {
    if (configData !== undefined) {
      if (
        configData.objectsColorsSelected !== undefined &&
        configData.objectsColorsSelected.length > 0
      ) {
        setOptionsSelectedObjects(configData.objectsColorsSelected);
      }
      if (
        configData.componentsColorsSelected !== undefined &&
        configData.componentsColorsSelected.length > 0
      ) {
        setOptionsSelectedComponents(configData.componentsColorsSelected);
      }
      if (
        configData.eventsColorsSelected !== undefined &&
        configData.eventsColorsSelected.length > 0
      ) {
        setOptionsSelectedEvents(configData.eventsColorsSelected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setear las librerias de objs/cmps/evts
  useEffect(() => {
    if (
      configData?.librariesObjects &&
      configData?.librariesObjects?.length >= 0
    ) {
      setPointLibrariesObjects(configData?.librariesObjects);
    }
    if (
      configData?.librariesComponents &&
      configData?.librariesComponents?.length >= 0
    ) {
      setPointLibrariesComponents(configData?.librariesComponents);
    }
    if (
      configData?.librariesEvents &&
      configData?.librariesEvents?.length >= 0
    ) {
      setPointLibrariesEvents(configData?.librariesEvents);
    }
  }, [
    configData?.librariesObjects,
    configData?.librariesComponents,
    configData?.librariesEvents,
  ]);

  // set fields (number, select) by libraries
  useEffect(() => {
    const auxObjects = [];
    const fieldsToSelect = ["select", "number"];
    pointLibrariesObjects.forEach((ln) => {
      auxObjects.push(parseLibrary(ln, fieldsToSelect));
    });
    setFieldsByLibraryObjects(auxObjects);

    const auxComponents = [];
    pointLibrariesComponents.forEach((ln) => {
      auxComponents.push(parseLibrary(ln, fieldsToSelect));
    });
    setFieldsByLibraryComponents(auxComponents);

    const auxEvents = [];
    pointLibrariesEvents.forEach((ln) => {
      auxEvents.push(parseLibrary(ln, fieldsToSelect));
    });
    setFieldsByLibraryEvents(auxEvents);
  }, [pointLibrariesObjects, pointLibrariesComponents, pointLibrariesEvents]);

  // updated selected fields on database (MG)
  useEffect(() => {
    const updated = {
      ...configData,
      objectsColorsSelected: optionsSelectedObjects,
      componentsColorsSelected: optionsSelectedComponents,
      eventsColorsSelected: optionsSelectedEvents,
      userConfigColors: {
        ...configData.userConfigColors,
        checkedPoints: checkUserConfig,
        colorObjects: optionsSelectedObjectsUserConfig,
        colorComponents: optionsSelectedComponentsUserConfig,
        colorEvents: optionsSelectedEventsUserConfig,
      },
    };
    const libs = {
      fieldsByLibraryObjects,
      fieldsByLibraryComponents,
      fieldsByLibraryEvents,
    };

    const updateSelectedPointColors = async () => {
      if (
        !optionsSelectedObjects.length &&
        !optionsSelectedComponents.length &&
        !optionsSelectedEvents.length
      )
        return;
      const body = {
        objectsColorsSelected: optionsSelectedObjects,
        componentsColorsSelected: optionsSelectedComponents,
        eventsColorsSelected: optionsSelectedEvents,
        userConfigColors: {
          ...configData.userConfigColors,
          checkedPoints: checkUserConfig,
          colorObjects: optionsSelectedObjectsUserConfig,
          colorComponents: optionsSelectedComponentsUserConfig,
          colorEvents: optionsSelectedEventsUserConfig,
        },
      };
      await updateBackendMapSettings({
        id: userId,
        body,
      });
      mutate(urlsApiMg.mapSettings.get);
    };
    updateSelectedPointColors();
    setConfigData(updated);
    // set state for options selected
    dispatch(
      setMapSettings({
        ...updated,
        ...libs,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkUserConfig,
    optionsSelectedObjects,
    optionsSelectedComponents,
    optionsSelectedEvents,
    optionsSelectedObjectsUserConfig,
    optionsSelectedComponentsUserConfig,
    optionsSelectedEventsUserConfig,
    fieldsByLibraryObjects,
    fieldsByLibraryComponents,
    fieldsByLibraryEvents,
  ]);

  // selects only one option per Object libraries
  const handleSelectOption = (libId, fdName, libType) => {
    let optionsSelected = [];
    let setOptionSelected = () => { };

    if (libType === "object") {
      optionsSelected = optionsSelectedObjects;
      setOptionSelected = setOptionsSelectedObjects;
    } else if (libType === "component") {
      optionsSelected = optionsSelectedComponents;
      setOptionSelected = setOptionsSelectedComponents;
    } else if (libType === "event") {
      optionsSelected = optionsSelectedEvents;
      setOptionSelected = setOptionsSelectedEvents;
    } else {
      optionsSelected = [];
      setOptionSelected = () => { };
    }

    const options = optionsSelected
      .map((it) => (it[0] === libId ? [it[0], fdName, libType] : it))
      .map((it) => JSON.stringify(it));
    // opciones parseadas a String para hacer un conjunto sin elementos repetidos
    setOptionSelected([...new Set(options)].map((it) => JSON.parse(it)));
  };

  // para default
  const checkChecked = useCallback(
    (id, name, libType) => {
      let optionsSelected = [];
      if (libType === "object") {
        optionsSelected = optionsSelectedObjects; // [ [1, "pointType", "object"], [2, "numuno", "object"] ]
      } else if (libType === "component") {
        optionsSelected = optionsSelectedComponents;
      } else if (libType === "event") {
        optionsSelected = optionsSelectedEvents;
      } else {
        optionsSelected = [];
      }
      return optionsSelected.find((it) => it[0] === id && it[1] === name)
        ? true
        : false;
    },
    [optionsSelectedObjects, optionsSelectedComponents, optionsSelectedEvents]
  );

  // para user config
  const checkCheckedUserConfig = useCallback(
    (id, name, libType) => {
      let optionsSelected = [];
      if (libType === "object") {
        optionsSelected = optionsSelectedObjectsUserConfig;
      } else if (libType === "component") {
        optionsSelected = optionsSelectedComponentsUserConfig;
      } else if (libType === "event") {
        optionsSelected = optionsSelectedEventsUserConfig;
      } else {
        optionsSelected = [];
      }
      const library = optionsSelected.find((it) => it.id === id); // looks for which library
      const field = library?.fields?.find((it) => it.name === name); // looks fot the specific field
      const state = field?.state; // check state of the field
      return state;
    },
    [
      checkUserConfig,
      optionsSelectedObjectsUserConfig,
      optionsSelectedComponentsUserConfig,
      optionsSelectedEventsUserConfig,
    ]
  );

  // handler for squared switch
  const handleCheckUserConfig = (checked) => {
    setCheckUserConfig(checked);
  };
  // handle de cambio de opciones para configuracion de usuarios en objetos
  const handleSelectObjectsOptionUserConfig = (libId, fdName) => {
    const libs = optionsSelectedObjectsUserConfig.map((lib) => {
      if (lib.id === libId) {
        const fields = lib.fields
          .map((it) => {
            if (it.name === fdName) it.state = !it.state;
            return it;
          })
          .map((it) => {
            if (it.name !== fdName) it.state = false;
            return it;
          });
        lib.fields = fields;
        return lib;
      }
      return lib;
    });
    setOptionsSelectedObjectsUserConfig(libs);
  };

  // handle de cambio de opciones para configuracion de usuarios en objetos
  const handleSelectComponentsOptionUserConfig = (libId, fdName) => {
    const libs = optionsSelectedComponentsUserConfig.map((lib) => {
      if (lib.id === libId) {
        const fields = lib.fields
          .map((it) => {
            if (it.name === fdName) it.state = !it.state;
            return it;
          })
          .map((it) => {
            if (it.name !== fdName) it.state = false;
            return it;
          });
        lib.fields = fields;
        return lib;
      }
      return lib;
    });
    setOptionsSelectedComponentsUserConfig(libs);
  };

  // handle de cambio de opciones para configuracion de usuarios en objetos
  const handleSelectEventsOptionUserConfig = (libId, fdName) => {
    const libs = optionsSelectedEventsUserConfig.map((lib) => {
      if (lib.id === libId) {
        const fields = lib.fields
          .map((it) => {
            if (it.name === fdName) it.state = !it.state;
            return it;
          })
          .map((it) => {
            if (it.name !== fdName) it.state = false;
            return it;
          });
        lib.fields = fields;
        return lib;
      }
      return lib;
    });
    setOptionsSelectedEventsUserConfig(libs);
  };

  // {libId:lib.id, fieldName:field.name, fieldType:field.type, colorIndex:indx, event}
  const handleChangeFieldColor = ({
    libId,
    fieldName,
    fieldType,
    colorIndex,
    event,
    libType,
    checkboxColor,
  }) => {
    let optionsSelected = [];
    let setOptionsSelected = () => { };
    if (libType === "object") {
      optionsSelected = optionsSelectedObjectsUserConfig;
      setOptionsSelected = setOptionsSelectedObjectsUserConfig;
    } else if (libType === "component") {
      optionsSelected = optionsSelectedComponentsUserConfig;
      setOptionsSelected = setOptionsSelectedComponentsUserConfig;
    } else if (libType === "event") {
      optionsSelected = optionsSelectedEventsUserConfig;
      setOptionsSelected = setOptionsSelectedEventsUserConfig;
    } else {
      optionsSelected = [];
      setOptionsSelected = () => { };
    }
    const color = event && event.target.value;
    const libs = optionsSelected.map((lib) => {
      if (lib.id === libId) {
        const fields = lib.fields.map((it) => {
          if (it.name === fieldName && it.type === "number") {
            const restrictions = it.numberGroupingOptions.restrictions; // take numeric restrictions
            const restriction = restrictions.at(colorIndex); // take restriction at specific index
            if (checkboxColor) restriction.state = !restriction.state; // change state of color checkbox
            if (color !== undefined) restriction.color = color; // change color
            restrictions[colorIndex] = restriction; // set restriction changed at restrictions array
            it.numberGroupingOptions.restrictions = restrictions;
            return it;
          }
          if (it.name === fieldName && it.type === "select") {
            const optionsColors = it.optionsColors;
            optionsColors[colorIndex] = color;
            it.optionsColors = optionsColors;
            return it;
          }
          return it;
        });
        lib.fields = fields;
        return lib;
      }
      return lib;
    });
    setOptionsSelected(libs);
  };

  const handleChangeFieldRestrictionValue = ({
    libId,
    fieldName,
    colorIndex,
    event,
    libType,
    valueMin = undefined,
    valueMax = undefined,
  }) => {
    let optionsSelected = [];
    let setOptionsSelected = () => { };
    if (libType === "object") {
      optionsSelected = optionsSelectedObjectsUserConfig;
      setOptionsSelected = setOptionsSelectedObjectsUserConfig;
    } else if (libType === "component") {
      optionsSelected = optionsSelectedComponentsUserConfig;
      setOptionsSelected = setOptionsSelectedComponentsUserConfig;
    } else if (libType === "event") {
      optionsSelected = optionsSelectedEventsUserConfig;
      setOptionsSelected = setOptionsSelectedEventsUserConfig;
    } else {
      optionsSelected = [];
      setOptionsSelected = () => { };
    }
    const value = event && event.target.value && parseFloat(event.target.value);
    const libs = optionsSelected.map((lib) => {
      if (lib.id === libId) {
        const fields = lib.fields.map((it) => {
          if (it.name === fieldName && it.type === "number") {
            const restrictions = it.numberGroupingOptions.restrictions; // take numeric restrictions
            const restriction = restrictions.at(colorIndex); // take restriction at specific index
            if (valueMin) restriction.valueMin = value;
            if (valueMax) restriction.valueMax = value;
            if (!valueMin && !valueMax) restriction.value = value;
            restrictions[colorIndex] = restriction; // set restriction changed at restrictions array
            it.numberGroupingOptions.restrictions = restrictions;
            return it;
          }
          return it;
        });
        lib.fields = fields;
        return lib;
      }
      return lib;
    });
    setOptionsSelected(libs);
  };

  return (
    <DynamicColorsContainer>
      <section>
        <p>
          <b>Dynamic Points Color by Category</b>
        </p>
      </section>
      <br />
      <section>
        <div
          style={{
            display: "flex",
            gap: "20px",
          }}
        >
          <p>Configuration:</p>
          <SwitchSquared
            label={["default", "user"]}
            checked={checkUserConfig}
            onChange={handleCheckUserConfig}
          />
        </div>
      </section>
      <br />
      <DynamicColorsFields>
        <div
          style={{
            width: "600px",
          }}
        >
          <AccordionLayout
            expanded={true}
            title={"Objects"}
            children={
              checkUserConfig ? (
                <MapPointLibrary
                  fieldsByLibrary={optionsSelectedObjectsUserConfig}
                  checkChecked={checkCheckedUserConfig}
                  handleSelectOption={handleSelectObjectsOptionUserConfig}
                  arrTypes={pointTypesColors}
                  libType="object"
                  checkUserConfig={checkUserConfig} // true
                  handleChangeFieldColor={handleChangeFieldColor}
                  handleChangeFieldRestrictionValue={
                    handleChangeFieldRestrictionValue
                  }
                />
              ) : (
                <MapPointLibrary
                  fieldsByLibrary={fieldsByLibraryObjects}
                  checkChecked={checkChecked}
                  handleSelectOption={handleSelectOption}
                  arrTypes={pointTypesColors}
                  libType="object"
                />
              )
            }
          />
        </div>

        <div
          style={{
            width: "600px",
          }}
        >
          <AccordionLayout
            title={"Components"}
            children={
              checkUserConfig ? (
                <MapPointLibrary
                  fieldsByLibrary={optionsSelectedComponentsUserConfig}
                  checkChecked={checkCheckedUserConfig}
                  handleSelectOption={handleSelectComponentsOptionUserConfig}
                  arrTypes={pointTypesColors}
                  libType="component"
                  checkUserConfig={checkUserConfig} // true
                  handleChangeFieldColor={handleChangeFieldColor}
                  handleChangeFieldRestrictionValue={
                    handleChangeFieldRestrictionValue
                  }
                />
              ) : (
                <MapPointLibrary
                  fieldsByLibrary={fieldsByLibraryComponents}
                  checkChecked={checkChecked}
                  handleSelectOption={handleSelectOption}
                  arrTypes={pointTypesColors}
                  libType="component"
                />
              )
            }
          />
        </div>
        <div
          style={{
            width: "600px",
          }}
        >
          <AccordionLayout
            title={"Events"}
            children={
              checkUserConfig ? (
                <MapPointLibrary
                  fieldsByLibrary={optionsSelectedEventsUserConfig}
                  checkChecked={checkCheckedUserConfig}
                  handleSelectOption={handleSelectEventsOptionUserConfig}
                  arrTypes={pointTypesColors}
                  libType="event"
                  checkUserConfig={checkUserConfig} // true
                  handleChangeFieldColor={handleChangeFieldColor}
                  handleChangeFieldRestrictionValue={
                    handleChangeFieldRestrictionValue
                  }
                />
              ) : (
                <MapPointLibrary
                  fieldsByLibrary={fieldsByLibraryEvents}
                  checkChecked={checkChecked}
                  handleSelectOption={handleSelectOption}
                  arrTypes={pointTypesColors}
                  libType="event"
                />
              )
            }
          />
        </div>
      </DynamicColorsFields>
    </DynamicColorsContainer>
  );
}

function AccordionLayout({ title, children, expanded = false }) {
  const classes = useStyles();

  return (
    <CustomizedAccordion
      className="background-2 border-color-1"
      classes={{
        content: classes.content,
        expanded: classes.expanded,
      }}
      defaultExpanded={expanded}
      style={{
        minWidth: "fit-content",
        background: BACKGROUND_SECOND_GREY,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "10px",
          width: "600px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontFamily: document.body.style.fontFamily,
            color: document.body.style.color,
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="color1" />}
        ></AccordionSummary>
      </div>
      {children}
    </CustomizedAccordion>
  );
}
// mapea la estructura general de las librerias para objs/evts/cmps
function MapPointLibrary({
  fieldsByLibrary = [],
  checkChecked = () => { },
  handleSelectOption = () => { },
  arrTypes = [],
  libType = "",
  checkUserConfig = false, // false by default
  handleChangeFieldColor = () => { },
  handleChangeFieldRestrictionValue = () => { },
}) {
  return (
    <MappingLibraryContainer>
      {fieldsByLibrary?.length >= 0 &&
        fieldsByLibrary?.map((ln) => {
          return (
            <div className="lib-container">
              <div className="library-name-container">
                <div
                  style={{
                    width: "50px",
                  }}
                >
                  Library:
                </div>{" "}
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {ln.name}
                </div>
              </div>
              <div>
                {/* opcion default - lineType */}
                <LibraryField>
                  <div className="library-names">
                    <input
                      type="checkbox"
                      value={ln.id}
                      checked={checkChecked(ln.id, "pointType", libType)}
                      onChange={() =>
                        handleSelectOption(ln.id, "pointType", libType)
                      }
                    />
                    <p>Point Type</p>
                  </div>
                  <div
                    className="color-container"
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    {arrTypes.length >= 0 &&
                      arrTypes
                        .filter((tp) => tp[0] === libType)
                        .map((item) => (
                          <div className="color-item">
                            <input type="color" value={item[1]} disabled />
                            <p>{item[0]}</p>
                          </div>
                        ))}
                  </div>
                </LibraryField>
                {ln.fields
                  .filter((it) => it.type !== "pointType")
                  .map((fd, indx) => {
                    // list of dynamic fields type select with circuit (only one)
                    return (
                      <ColorOptionElement
                        checkChecked={checkChecked}
                        handleSelectOption={handleSelectOption}
                        lib={ln}
                        field={fd}
                        optionIndex={indx}
                        key={indx}
                        libType={libType}
                        checkUserConfig={checkUserConfig}
                        handleChangeFieldColor={handleChangeFieldColor}
                        handleChangeFieldRestrictionValue={
                          handleChangeFieldRestrictionValue
                        }
                      />
                    );
                  })}
              </div>
            </div>
          );
        })}
    </MappingLibraryContainer>
  );
}

function ColorOptionElement({
  optionIndex,
  lib,
  field,
  checkChecked = () => { },
  handleSelectOption,
  libType,
  checkUserConfig,
  handleChangeFieldColor = () => { },
  handleChangeFieldRestrictionValue = () => { },
}) {
  return (
    <LibraryField>
      <div className="library-names">
        {field.type === "select" && (
          <input
            type="checkbox"
            value={field.optionsColors.at(optionIndex)}
            checked={checkChecked(lib.id, field.name, libType)}
            onChange={() => handleSelectOption(lib.id, field.name, libType)}
          />
        )}
        {field.type === "number" && (
          <input
            type="checkbox"
            value={field.numberGroupingOptions.restrictions.at(optionIndex)}
            checked={checkChecked(lib.id, field.name, libType)}
            onChange={() => handleSelectOption(lib.id, field.name, libType)}
          />
        )}
        <p>{field.alias}</p>
      </div>
      <div className="color-container">
        {field.type === "select" &&
          field.optionsColors.length !== 0 &&
          field.optionsColors.map((cl, indx) => (
            <div className="color-item">
              {checkUserConfig ? (
                <input
                  type="color"
                  value={cl}
                  onChange={(event) =>
                    handleChangeFieldColor({
                      libId: lib.id,
                      fieldName: field.name,
                      fieldType: field.type,
                      colorIndex: indx,
                      event,
                      libType,
                    })
                  }
                />
              ) : (
                <input type="color" value={cl} disabled />
              )}
              <p>{field.options[indx]}</p>
            </div>
          ))}
        {field.type === "number" &&
          field.numberGroupingOptions.restrictions.length !== 0 &&
          field.numberGroupingOptions.restrictions.map((rt, indx) => {
            return (
              <div className="color-item">
                <div className="color-item-leyend">
                  {checkUserConfig ? (
                    <div
                      style={{
                        display: "flex",
                      }}
                      className="color-item-container"
                    >
                      <input
                        type="checkbox"
                        value={
                          field?.numberGroupingOptions?.restrictions?.at(indx)
                            ?.state
                        }
                        checked={
                          field?.numberGroupingOptions?.restrictions?.at(indx)
                            ?.state
                        }
                        onChange={() =>
                          handleChangeFieldColor({
                            libId: lib.id,
                            fieldName: field.name,
                            fieldType: field.type,
                            colorIndex: indx,
                            libType,
                            event: undefined,
                            checkboxColor: true,
                          })
                        }
                      />
                      <input
                        type="color"
                        value={rt.color}
                        onChange={(event) =>
                          handleChangeFieldColor({
                            libId: lib.id,
                            fieldName: field.name,
                            fieldType: field.type,
                            colorIndex: indx,
                            event,
                            libType,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div className="color-item-container">
                      <input type="color" value={rt.color} disabled />
                    </div>
                  )}
                  <div className="label-restriction-container">
                    {rt.label} {rt.symbol}
                  </div>
                </div>
                <div className="input-number-container">
                  {rt.symbol === "><" || rt.symbol === ">=<=" ? (
                    <div className="input-number-container-doble">
                      {checkUserConfig ? (
                        <p>
                          <input
                            className="input-number-doble"
                            type="number"
                            value={
                              field?.numberGroupingOptions?.restrictions?.at(
                                indx
                              )?.valueMin
                            }
                            onChange={(event) =>
                              handleChangeFieldRestrictionValue({
                                libId: lib.id,
                                fieldName: field.name,
                                fieldType: field.type,
                                colorIndex: indx,
                                event, // this event with the value
                                libType, // "object" "event" "component"
                                valueMin: true,
                              })
                            }
                          />
                          <input
                            className="input-number-doble"
                            type="number"
                            value={
                              field?.numberGroupingOptions?.restrictions?.at(
                                indx
                              )?.valueMax
                            }
                            onChange={(event) =>
                              handleChangeFieldRestrictionValue({
                                libId: lib.id,
                                fieldName: field.name,
                                fieldType: field.type,
                                colorIndex: indx,
                                event, // this event with the value
                                libType, // "object" "event" "component"
                                valueMax: true,
                              })
                            }
                          />
                        </p>
                      ) : (
                        <p>
                          {rt.valueMin} - {rt.valueMax}
                        </p>
                      )}
                    </div>
                  ) : (
                    <p>
                      {checkUserConfig ? (
                        <input
                          className="input-number-solo"
                          type="number"
                          value={
                            field?.numberGroupingOptions?.restrictions?.at(indx)
                              ?.value
                          }
                          onChange={(event) =>
                            handleChangeFieldRestrictionValue({
                              libId: lib.id,
                              fieldName: field.name,
                              fieldType: field.type,
                              colorIndex: indx,
                              event, // this event with the value
                              libType, // "object" "event" "component"
                              valueMin: false,
                              valueMax: false,
                            })
                          }
                        />
                      ) : (
                        rt.value
                      )}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </LibraryField>
  );
}

function parseLibrary(item, types = []) {
  if (item === undefined || !types.length) return null;
  const fields =
    item.fields !== undefined
      ? item.fields.filter((fd) => types.includes(fd.type) && fd.circuit)
      : [];
  return {
    id: item.lineLibraryId || item.id,
    name: item.name,
    fields,
  };
}
