import styled from "styled-components";
import {
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_SECOND_COLOR,
  BLACK_COLOR,
  RED_COLOR,
} from "utils/const";

export const Form = styled.form`
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  color: ${BLACK_COLOR};

  .input {
    width: 100%;
    border: 1px solid;
    border-color: ${BLACK_COLOR};
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${SECOND_COLOR};
  padding: 3px 10px;
  border-radius: 3px;
  background-color: ${WHITE_SECOND_COLOR};
  align-items: flex-start;
  max-width: 400px;
  min-width: 200px;
  max-height: 300px; /* Ajusta según tus necesidades */
  overflow-y: auto; /* Muestra una barra de desplazamiento vertical si el contenido excede max-height */
  
  .buttons-views {
    border: none;
    color: ${BLACK_COLOR};
    background-color: ${WHITE_SECOND_COLOR};
    transition: 0.4s;
  }
  .buttons-views:hover {
    color: var(--background-secondary_color);
    cursor: pointer;
  }
  .button-delete:hover {
    color: ${RED_COLOR};
    cursor: pointer;
  }
  .drag-icon {
    margin-left: -10px;
  }

  .align {
    margin-right: auto; /* Alinea los botones a la izquierda */
  }

  .icon-title {
    margin-left: 8px; /* Agrega margen a la izquierda del texto */
    margin-right: 8px; /* Agrega margen a la derecha del texto */
  }
`;

export const ButtonsMaksCont = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ViewsTitle = styled.h2`
  margin-bottom: "15px";
  font-weight: "bold";
  color: ${BLACK_COLOR};
`;

export const AddNewViewContainer = styled.div`
  width: 94%;
  display: grid;
  justify-content: end;

  .button-add {
    display: grid;
    border-radius: 50%;
    background-color: var(--background-primary_color);
    border: none;
    cursor: pointer;

    :hover {
      background-color: var(--background-secondary_color);
    }
  }
`;
