import uploadCSV from "services/GlobalValuesFields/uploadCSV";

const uploadCSVGlobalValues = async (
  file,
  tableId,
  tableCategoriesId,
  groupId,
  setErrorMessage,
  setCurrentGlobalTable
) => {
  try {
    const { data, error } = await uploadCSV(
      tableId,
      tableCategoriesId,
      groupId,
      file
    );

    const category = data?.table?.tableCategories?.find(
      (category) => category._id === tableCategoriesId
    );
    const groupTable = category?.groupTables?.find(
      (groupTable) => groupTable._id === groupId
    );

    if (error) {
      console.error("Error uploading CSV:", error);
      setErrorMessage(
        "Error uploading CSV. Please check the file format or try again."
      );
    } else if (!data || data.length === 0) {
      setErrorMessage("The CSV file is empty or contains invalid rows.");
    } else {
      setErrorMessage("");

      //update global table with new rows
      setCurrentGlobalTable((current) => ({
        ...current,
        rows: [...groupTable.rows], // add the new rows to the existing rows
      }));
    }
  } catch (e) {
    console.error("Error during upload:", e);
    setErrorMessage("An unexpected error occurred. Please try again.");
  }
};

export default uploadCSVGlobalValues;
