export const orderAttributes = ({ arrayToOrder, fromArray }) => {
  const isSpecialAttribute = (item) => {
    return item.type === "lineTag" && item.value === "lineTag";
  };
  if (arrayToOrder?.some(isSpecialAttribute)) {
    const groupSize = 6;
    const groups = [];
    for (let i = 0; i < arrayToOrder.length; i += groupSize) {
      groups.push(arrayToOrder.slice(i, i + groupSize));
    }
    const sortGroup = (group) => {
      return group.sort((a, b) => {
        const getIndex = (item) => {
          if (isSpecialAttribute(item)) {
            return -1; // Siempre primero
          }
          const idx = fromArray?.[0]?.fields?.findIndex(
            (field) => field.name === item.name
          );
          return idx !== -1 ? idx : 999;
        };
        return getIndex(a) - getIndex(b);
      });
    };

    const sortedGroups = groups.map((group) => sortGroup(group));
    return sortedGroups.flat();
  } else {
    return arrayToOrder?.sort((a, b) => {
      const aIndex = fromArray?.[0]?.fields?.findIndex(
        (item) => item.name === a.name
      );
      const bIndex = fromArray?.[0]?.fields?.findIndex(
        (item) => item.name === b.name
      );
      return aIndex - bIndex;
    });
  }
};
