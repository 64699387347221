import { useState, useEffect } from "react";
import { useSWRConfig } from "swr";
// Style
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
// Icons
import { AiFillCloseCircle } from "react-icons/ai";
import SaveIcon from "@mui/icons-material/Save";
// Components
import LoadingButton from "@mui/lab/LoadingButton";
import { HeadModal } from "components/Dialogs/DialogsStyles";
// Consts
import { PRIMARY_COLOR, WHITE_COLOR } from "utils/const";
// Services
import saveObjectFile, {
  saveCaptureMedia,
} from "services/objects/saveObjectFile";
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
import updateObjectPg from "services/updateObjectPg";
import { pathFolder } from "utils/paths";
import { getPathBucket } from "helpers/getPathBucket";

import { urls } from "utils/urlKeys";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const UploadMediaObject = ({
  object,
  photosFile,
  setPhotosFile,
  openModal,
  setOpenModal,
}) => {
  const [typeObjectFile, setTypeObjectFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this element?",
    disagree: "Cancel",
    agree: "Accept",
  });

  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const { mutate } = useSWRConfig();

  useEffect(() => {
    const typeMedia = object?.fieldsCaptureMedia[0]?.name;
    setTypeObjectFile(typeMedia);
  }, [object]);

  const setObjectHandler = (e) => {
    setTypeObjectFile(e.target.value);
  };

  const handleClose = () => setOpenModal(false);

  const savePhotos = async () => {
    let body = {
      lastUpdate: new Date(),
      userUpdateId: userId,
      haveMedia: true,
    };
    await saveObject(body);
    setLoading(true);
    Promise.all(
      photosFile.map(async (file) => {
        const folderPath = getPathBucket(
          pathFolder.objects.points.pictures(typeObjectFile)
        );

        const { fileUrl } = await uploadFileToS3({ file, folderPath });

        if (typeObjectFile === null || typeObjectFile === undefined) {
          await saveObjectFile({
            objectId: object.id,
            typeObjectFileId: "3",
            urlFile: fileUrl,
          });
        } else {
          const aliasFieldMedia = object?.fieldsCaptureMedia.find(
            (item) => item.name === typeObjectFile
          )?.alias;

          await saveCaptureMedia({
            pointObjectMgId: object.mongoId,
            media: [
              {
                type: typeObjectFile,
                alias: aliasFieldMedia,
                url: fileUrl,
              },
            ],
          });
        }
      })
    ).then(() => {
      setLoading(false);
      handleClose();
      setPhotosFile([]);
      mutate(urls.objects.detail(object.id));
    });
  };

  const saveObject = async (body) => {
    try {
      await updateObjectPg(body, object.id);
      mutate(urls.objects.all);
      setContentDialog({ ...contentDialog, agree: "yes" });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        style={{
          position: "relative",
          borderRadius: "10px",
          width: "fit-content",
        }}
      >
        <HeadModal>
          <div className="title">
            <img
              src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
              alt=""
              className="neurona"
            />
            <span className="titleHeadModal">Add Picture</span>
          </div>
          <Button onClick={handleClose}>
            <AiFillCloseCircle
              style={{
                color: WHITE_COLOR,
                fontSize: 21,
                marginBottom: -4,
              }}
            />
          </Button>
        </HeadModal>
        {object?.fieldsCaptureMedia?.length > 0 && (
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              marginTop: "50px",
            }}
          >
            Please select the type of picture to upload:
            <br />
            <div>
              <select
                value={typeObjectFile}
                onChange={setObjectHandler}
                style={{
                  backgroundColor: PRIMARY_COLOR,
                  borderColor: PRIMARY_COLOR,
                  color: WHITE_COLOR,
                  padding: "5px 15px",
                  margin: "11px 11px 11px 15px",
                }}
              >
                {object?.fieldsCaptureMedia.map((item) => (
                  <option key={item.name} value={item.name}>
                    {item.alias}
                  </option>
                ))}
              </select>
            </div>
          </Typography>
        )}
        <br />
        <br />
        <LoadingButton
          sx={{
            margin: "11px",
          }}
          loadingPosition="start"
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpenModal(false);
          }}
          style={{
            backgroundColor: PRIMARY_COLOR,
            borderColor: PRIMARY_COLOR,
            color: WHITE_COLOR,
          }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          color="primary"
          onClick={() => {
            setLoading(true);
            savePhotos();
          }}
          style={{
            backgroundColor: PRIMARY_COLOR,
            borderColor: PRIMARY_COLOR,
            color: WHITE_COLOR,
          }}
        >
          Save
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default UploadMediaObject;
