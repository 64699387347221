import { createContext, useEffect, useState, useContext } from "react";
import io from "socket.io-client";
import { config } from "config.js";

export const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socketPG, setSocketPG] = useState(null);

  useEffect(() => {
    // Crear conexiones de socket
    const newSocketPG = io(config.URL_BACKEND_PG.slice(0, -1), {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    // Manejadores de eventos de conexión
    newSocketPG.on("connect", () => console.log("Conectado a PG Socket"));

    // Manejadores de errores
    newSocketPG.on("connect_error", (error) =>
      console.error("Error de conexión PG:", error)
    );

    setSocketPG(newSocketPG);

    // Limpiar la conexión cuando el componente se desmonta
    return () => {
      newSocketPG.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socketPG }}>
      {children}
    </SocketContext.Provider>
  );
};

// Hook personalizado para usar el socket de PG
export const useSocketPG = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocketPG debe ser usado dentro de un SocketProvider");
  }
  return context.socketPG;
};
