import React from "react";
import {
  ContainerIntro,
  ContainerTitle,
  HubH1,
  HubD,
  LogoCont,
} from "./HubStyles";
// import LastLoginInfo from "./LastLoginInfo";
import NeuronaIconD from "images/NeuronaIconD";
import { infoInstances } from "./InfoInstances";

const DescriptionHub = () => {
  // Extract data from contentByHost
  const { title, description, source } = infoInstances;
  return (
    <>
      <ContainerIntro className="background-1">
        <LogoCont className="miniLogo">
          <NeuronaIconD width={60} height={60} />
        </LogoCont>
        <ContainerTitle>
          <HubH1>
            Decimetrix<sup style={{ fontSize: 25 }}>®</sup>
            {source} <br />
            {title}
          </HubH1>
          <HubD>{description}</HubD>
        </ContainerTitle>
      </ContainerIntro>
    </>
  );
};

export default DescriptionHub;
