import { useEffect, useState, useCallback } from "react";
// Redux
import { useDispatch } from "react-redux";
import { setCurrentUser } from "redux/actions/index";
import { setCurrentUserParams } from "redux/actions";
// Services
import findUserSessions, { findRevoquedTokens } from "services/sessions/find";
import { moveTokenToRevoquedToken } from "services/sessions/create";
import { updateUserVerification } from "services/users/userVerification";
import { SOURCE_ID } from "utils/constStrings";

const useUserAuthenticated = ({ token }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const dispatch = useDispatch();

  const userLogout = useCallback(
    async (userId) => {
      await updateUserVerification({
        userId,
        changes: { activeSession: false },
      });
      dispatch(setCurrentUser({}));
      dispatch(setCurrentUserParams({}));
      localStorage.clear();
      setAuthenticated(false);
      setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    const userToken = token;
    if (userToken) {
      setAuthenticated(true);
      const findSessions = async () => {
        try {
          const userId = localStorage.getItem("userId");
          const revoquedToken = await findRevoquedTokens({
            token: userToken,
            sourceId: SOURCE_ID.desktop, // Flag source: 1 - TO SESSION WEB
          });
          // Is current token in rovoqued tokens ?
          if (revoquedToken.length > 0) {
            await userLogout(userId);
          } else {
            setLoading(true);
            // Find token by user id
            const userSessionTokens = await findUserSessions({
              token: userToken,
              userId,
              sourceId: SOURCE_ID.desktop, // Flag source: 1 - TO SESSION WEB
            });
            const userSession = userSessionTokens?.find(
              (userSession) => userSession.token === userToken
            );
            setLoading(false);
            // Is current token in session token ?
            if (userSession) {
              await updateUserVerification({
                userId,
                changes: { activeSession: true },
              });
              setAuthenticated(true);
              setLoading(false);
            } else {
              await moveTokenToRevoquedToken({
                token: userToken,
              });
              await userLogout(userId);
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
      findSessions();
    } else {
      setAuthenticated(false);
      setLoading(false);
    }
  }, [dispatch, token, userLogout]);

  return {
    loading,
    authenticated,
  };
};

export default useUserAuthenticated;
