import Container from "@mui/material/Container";

import Layout from "components/Layout/AdminDecimetrix";
import DescriptionHub from "./DescriptionHub";
import { TabsInstances } from "./TabsInstances";

export const HubInstances = () => {
  return (
    <>
      <Layout>
        <Container
          className="background-1"
          maxWidth="false"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <>
            <DescriptionHub />
            <TabsInstances />
          </>
        </Container>
      </Layout>
    </>
  );
};
