import axios from "axios";
import { config } from "config.js";

export const findTypeObjects = async ({ id, libraryId }) => {
  const token = localStorage.getItem("token");

  const urlParams = new URLSearchParams();

  if (id) urlParams.append("id", id);
  if (libraryId) urlParams.append("libraryId", libraryId);

  const result = await axios.get(
    `${config.URL_BACKEND_PG}api/v1/type-elements?${urlParams.toString()}`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await result.data;
  return data;
};

/**
 * Updates a type point object in the database.
 *
 * @param {Object} data - The data to update.
 * @param {number} data.id - The ID of the type point object to update.
 * @return {Promise<Object>} The updated data
 */
export const updateTypePointObjects = async ({ id, body }) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.put(
      `${config.URL_BACKEND_PG}api/v1/type-elements/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};
