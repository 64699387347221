// Dependecies
import { useSWRConfig } from "swr";
// Hooks
import useUpdateLocationPoint from "hooks/useUpdateLocationPoint";
// Style
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  ButtonSaveCoordinates,
  ContentDialogFunction,
} from "./SaveCoordinateButtonStyle";
// config
import { config } from "config.js";
import { urlKeys, urls } from "utils/urlKeys";
import OpenDialog from "components/Dialogs/OpenDialog";
import CustomButton from "./CustomButton";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import { useState } from "react";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";

export default function SaveCoordinateButton({
  from,
  id,
  longitude,
  latitude,
  oldLocation,
  setDataCopyCoordinates,
  setMovePoint,
  marks = [],
  eventsComponents = [],
  componentsObject = [],
  pointsEventRelations = [],
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const dataObject = {
    id,
    location: {
      longitude,
      latitude,
    },
  };
  const dataComponent = {
    id,
    locationComponent: {
      longitude,
      latitude,
    },
  };
  const dataEvent = {
    id,
    locationOperation: {
      longitude,
      latitude,
    },
  };
  const { mutate } = useSWRConfig();
  const { handleUpdateLocation } = useUpdateLocationPoint({
    type: from,
    point:
      from === "object"
        ? dataObject
        : from === "component"
        ? dataComponent
        : from === "event"
        ? dataEvent
        : {},
  });

  const handleSaveCoordinates = () => {
    setButtonLoading(true);
    handleUpdateLocation({
      marks,
      eventsComponents,
      componentsObject,
      oldLocation,
      pointsEventRelations,
    });
    setDataCopyCoordinates({
      lng: "",
      lat: "",
    });
    if (from === "object") {
      mutate(`${config.URL_BACKEND_PG}api/v1/objects/${id}`);
      mutate(urlKeys.objects);
      if (
        marks.length > 0 ||
        eventsComponents.length > 0 ||
        componentsObject.length > 0
      ) {
        mutate(urlKeys.components);
        mutate(urlKeys.eventsEvents);
        mutate(urlKeys.eventsComponents);
        mutate(urlKeys.eventsObjects);
        mutate(`${config.URL_BACKEND_PG}api/v1/operation?objectId=${id}`);
        mutate(urls.components.object(id));
        mutate(`${config.URL_BACKEND_PG}api/v1/point-events?objectId=${id}`);
      }
    }
    if (from === "component") {
      mutate(`${config.URL_BACKEND_PG}api/v1/point-components?id=${id}`);
      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events/components?pointComponentId=${id}`
      );
      mutate(urlKeys.components);
    }
    if (from === "event") {
      mutate(`${config.URL_BACKEND_PG}api/v1/point-events?id=${id}`);
      mutate(`${config.URL_BACKEND_PG}api/v1/point-events/components?id=${id}`);
      mutate(`${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${id}`);
      mutate(urlKeys.eventsEvents);
      mutate(urlKeys.eventsComponents);
      mutate(urlKeys.eventsObjects);
    }
    localStorage.removeItem("newLocationMove");
    setMovePoint(true);
    setButtonLoading(false);
  };

  const handleSaveCoordinatesPrueba = () => {
    setButtonLoading(true);
    handleUpdateLocation({ id: id });
    setDataCopyCoordinates({
      lng: "",
      lat: "",
    });
    if (from === "object") {
      mutate(`${config.URL_BACKEND_PG}api/v1/objects/${id}`);
      mutate(urlKeys.objects);
    }
    if (from === "component") {
      mutate(`${config.URL_BACKEND_PG}api/v1/point-components?id=${id}`);
      mutate(urlKeys.components);
    }
    if (from === "event") {
      mutate(`${config.URL_BACKEND_PG}api/v1/point-events?id=${id}`);
      mutate(`${config.URL_BACKEND_PG}api/v1/point-events/components?id=${id}`);
      mutate(`${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${id}`);
      mutate(urlKeys.eventsEvents);
      mutate(urlKeys.eventsComponents);
      mutate(urlKeys.eventsObjects);
    }
    localStorage.removeItem("newLocationMove");
    setMovePoint(true);
    setButtonLoading(false);
  };

  const confirmTypeFunction = (
    <ContentDialogFunction>
      <h1>What do you want to do?</h1>
      <div>
        <CustomButton
          primaryColor={PRIMARY_COLOR}
          secondaryColor={SECOND_COLOR}
          text={"Only move point"}
          onClick={handleSaveCoordinatesPrueba}
          isLoad={buttonLoading}
          type={"button"}
          margin={0}
        />
      </div>
      <div>
        <CustomButton
          primaryColor={PRIMARY_COLOR}
          secondaryColor={SECOND_COLOR}
          text={"Move point and relations"}
          onClick={handleSaveCoordinates}
          isLoad={buttonLoading}
          type={"button"}
          margin={0}
        />
      </div>
    </ContentDialogFunction>
  );

  return (
    <>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={() => setOpenDialog(false)}
        content={{
          title: "Move Points",
          description: confirmTypeFunction,
          disagree: "Cancel",
        }}
        maxWidth={"200px"}
        minWidth={"500px"}
      />
      <ButtonSaveCoordinates onClick={() => setOpenDialog(true)}>
        <SimpleToolTip title="Save new location" placement="left">
          <SaveAsIcon
            style={{
              color: "#282828",
              fontSize: "1.6rem",
            }}
          />
        </SimpleToolTip>
      </ButtonSaveCoordinates>
    </>
  );
}
