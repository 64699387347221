import styled from "styled-components";
import { WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";

export const GlobalFieldValue = styled.div`
  flex-direction: column;
  width: 100%;

  label {
    width: 70%;
  }
`;

export const ContainerConfigGlobalValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  padding: 15px;
  border: solid #cccccc 1px;
  border-radius: 5px;
  background: ${WHITE_COLOR};
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 10px 0;
  background: #f2f2f2;
  padding: 10px;
  border: solid 1px #cccccc;
  border-radius: 5px;

  .label {
    width: 100%;
    justify-content: start;
    border-bottom: 1px solid #cccccc;
  }

  .select {
    font-size: 1.4rem;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }

  .options {
    font-size: 1.4rem;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};

    &:hover {
      background-color: ${SECOND_COLOR};
    }
  }
`;

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 10px 0;
  padding: 14px;
  background: #ffffff;
  border-radius: 5px;
  border: solid 1px #cccccc;
  .label {
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
  }
  span {
    font-size: 2rem;
    text-align: center;
    font-weight: 900;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    border: none;
    border-radius: 5px;
  }
`;
