import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getUrlSigned } from "services/s3Manager/s3Manager";
import CustomButton from "components/Buttons/CustomButton";
import { useState } from "react";
import OpenDialog from "components/Dialogs/OpenDialog";
import { ommitBucketUrl } from "utils/urlKeys";
import { SECOND_COLOR } from "utils/const";
import { LinearProgress, Typography, Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
const ProgressBar = ({ count, totalFiles, percentage }) => {
  return (
    <Box
      sx={{
        width: "100%",
        mt: 2,
        p: 2,
        borderRadius: 1,
        boxShadow: 2,
        bgcolor: "background.paper",
        textAlign: "center",
      }}
    >
      <Typography variant="body2" color="textSecondary">
        {`Downloading ${count} de ${totalFiles}`}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          height: 10,
          borderRadius: 1,
          my: 1,
          backgroundColor: "#e0e0e0",
          "& .MuiLinearProgress-bar": {
            backgroundColor: SECOND_COLOR,
          },
        }}
      />
      <Typography variant="body2" fontWeight="bold">
        {`${Math.round(percentage)}%`}
      </Typography>
    </Box>
  );
};

const DownloadPhotos = ({ photos, infoOPoint }) => {
  const [openModal, setOpenModal] = useState(false);
  const [downloadType, setDownloadType] = useState("zip");
  const [progress, setProgress] = useState({
    count: 0,
    total: photos.length,
    percentage: 0,
  });

  const handleDownloadImage = async (photoUrl) => {
    try {
      const shouldOmitSigning = ommitBucketUrl.some((prefix) =>
        photoUrl.startsWith(prefix)
      );

      const signedUrl = shouldOmitSigning
        ? photoUrl
        : await getUrlSigned({
            s3Url: encodeURIComponent(photoUrl),
            expiration: 100,
          });

      const response = await fetch(signedUrl);

      if (response) {
        const blob = await response.blob();
        const fileName = photoUrl.split("/").pop();
        saveAs(blob, fileName);
      }
    } catch (error) {
      console.error(`Error downloading ${photoUrl}:`, error);
    }
  };

  const downloadPhotosAsZip = async (photos) => {
    const zip = new JSZip();
    let count = 0;
    setProgress({ count, total: photos.length, percentage: 0 });

    for (const photo of photos) {
      try {
        let response;
        if (!response?.ok) {
          const shouldOmitSigning = ommitBucketUrl.some((prefix) =>
            photo.url.startsWith(prefix)
          );

          const signedUrl = shouldOmitSigning
            ? photo.url
            : await getUrlSigned({
                s3Url: encodeURIComponent(photo.url),
                expiration: 100,
              });

          response = await fetch(signedUrl);
        }

        if (!response?.ok) {
          console.error(
            `Error downloading ${photo.url}: ${response.statusText}`
          );
          continue;
        }

        if (response) {
          const blob = await response.blob();
          const fileName = photo.url.split("/").pop();
          zip.file(fileName, blob);
          count++;
          setProgress({
            count,
            total: photos.length,
            percentage: (count / photos.length) * 100,
          });
        }
      } catch (error) {
        console.error("Error downloading imágenes", error);
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${infoOPoint}_photos.zip`);
      setProgress({ count: 0, total: photos.length, percentage: 0 });
      setOpenModal(false);
    });
  };

  const downloadPhotosIndividually = async () => {
    let count = 0;
    setProgress({ count, total: photos.length, percentage: 0 });

    for (const photo of photos) {
      await handleDownloadImage(photo.url);
      count++;
      setProgress({
        count,
        total: photos.length,
        percentage: (count / photos.length) * 100,
      });
      await new Promise((resolve) => setTimeout(resolve, 300));
    }

    setProgress({ count: 0, total: photos.length, percentage: 0 });
    setOpenModal(false);
  };

  const executeDownload = async () => {
    if (downloadType === "zip") {
      await downloadPhotosAsZip(photos);
    } else {
      await downloadPhotosIndividually();
    }
  };

  return (
    <>
      <CustomButton
        text={"Download Pictures"}
        onClick={() => setOpenModal(true)}
        margin={0}
        icon={<FileDownloadIcon sx={{ fontSize: "18px" }} />}
        padding={"7px 10px"}
      />

      <OpenDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        content={{
          title: "Download Pictures",
          description: (
            <div
              style={{
                width: "100%",
              }}
            >
              {progress.count > 0 && (
                <>
                  <ProgressBar
                    count={progress.count}
                    totalFiles={progress.total}
                    percentage={progress.percentage}
                  />
                  <br />
                  <br />
                </>
              )}
              <h3
                style={{
                  textAlign: "center",
                }}
              >
                How would you like to download the images?
              </h3>
              <div style={{ marginTop: "10px", textAlign: "center" }}>
                <label style={{ marginRight: "15px" }}>
                  <input
                    type="radio"
                    name="downloadType"
                    value="zip"
                    checked={downloadType === "zip"}
                    onChange={() => setDownloadType("zip")}
                    style={{ marginRight: "5px" }}
                  />
                  ZIP file (recommended)
                </label>
                <label>
                  <input
                    type="radio"
                    name="downloadType"
                    value="individual"
                    checked={downloadType === "individual"}
                    onChange={() => setDownloadType("individual")}
                    style={{ marginRight: "5px" }}
                  />
                  Individual files
                </label>
              </div>
            </div>
          ),
          agree: "Download",
          disagree: "Cancel",
        }}
        execute={async () => await executeDownload()}
      />
    </>
  );
};

export default DownloadPhotos;
