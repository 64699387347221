import { useGetLineLibraries, useGetLineTypes } from "./useGet";
import useSWR from "swr";
import { urls } from "utils/urlKeys";

function GetLinesArrays({ libraryId, lineId }) {
  const { data: linesLibrariesArray, error: errorLineLibraries } =
    useGetLineLibraries({});

  const { data: linesTypeArray, error: errorLineType } =
    useGetLineTypes(libraryId);

  const urlParams = new URLSearchParams();

  if (lineId) urlParams.append("id", lineId);

  const { data: linesArray, error: errorLines } = useSWR(
    `${urls.lines.all}&${urlParams.toString()}`
  );

  return {
    linesArray,
    errorLines,
    linesLibrariesArray,
    errorLineLibraries,
    linesTypeArray,
    errorLineType,
  };
}

export default GetLinesArrays;
