import { useEffect, useMemo, useState } from "react";
// Style
import { GeneralButton } from "components/ElementDetails/listEventTableStyle.jsx";
// Hooks
import useSwr from "swr";
import { urls } from "utils/urlKeys";

function useCreateComponentEvents({ componentId }) {
  const [rows, setRows] = useState([]);
  const [markedEvent, setMarkedEvent] = useState("");
  const seePointEventsTable = useMemo(() => rows.length > 0, [rows]);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const { data: componentEvents, error: errorComponentEvents } = useSwr(
    urls.events.detail.byComponentId(componentId)
  );

  const dataDynamicEvents = useMemo(() => {
    return componentEvents && !errorComponentEvents
      ? componentEvents.filter(
          (it) => it.adminCompanyId === parseInt(adminCompanyId)
        )
      : [];
  }, [componentEvents, errorComponentEvents, adminCompanyId]);

  const handleMark = (id) => {
    setMarkedEvent(id);
  };

  useEffect(() => {
    if (markedEvent !== "") {
      const timer = setTimeout(() => {
        setMarkedEvent("");
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [markedEvent]);

  useEffect(() => {
    if (dataDynamicEvents.length > 0) {
      const rows = dataDynamicEvents.map((item) => {
        return {
          id: item.id,
          name: item.pointTypeEvent.name,
          date: item.date,
          isGeographic: item.isGeographic,
          longitude: item.locationOperation.longitude,
          latitude: item.locationOperation.latitude,
          createdAt: item.createdAt,
          markPoint: (
            <GeneralButton onClick={() => handleMark(item.id)}>
              Flash
            </GeneralButton>
          ),
        };
      });
      setRows(rows);
    }
  }, [dataDynamicEvents]);

  return {
    eventRows: rows,
    dataDynamicEvents,
    seePointEventsTable,
    markedEvent,
    setMarkedEvent,
  };
}

export default useCreateComponentEvents;
