/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { Container } from "./StyleContentDialog";
import CustomSelect from "components/Buttons/CustomSelect";
import CustomButton from "components/Buttons/CustomButton";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import { config } from "config.js";
import { useGetLineTypes } from "components/Forms/SaveLines/hooks/useGet";
import { updateLinePg } from "services/lines/updateLine";
import updateLinePropertiesMg from "services/lines/updateLinePropertiesMg";
import ShowLinePreview from "helpers/showLinePreview";
import { useDispatch } from "react-redux";
import { setShowOperationDetails } from "redux/actions";

const ContentConvertComponent = ({
  line,
  setOpenDialog,
  setShowLineDetails,
}) => {
  const [isLoad, setIsLoad] = useState(false);
  const [select, setSelect] = useState();
  const [lineStyle, setLineStyle] = useState(null);

  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const userId = localStorage.getItem("userId");

  const pointLibraryLineId = line.lineType.lineLibraryId;

  const { data: typeLines, error: errorTypeLines } =
    useGetLineTypes(pointLibraryLineId);

  useEffect(() => {
    if (typeLines?.length > 0) {
      setLineStyle({
        nameTypeLine: typeLines[0]?.type,
        descriptionTypeLine: typeLines[0]?.description,
        lineTypeId: typeLines[0]?.id,
        color: typeLines[0]?.color,
        dasharrayWidth: typeLines[0]?.dasharrayWidth,
        dasharrayPixels: typeLines[0]?.dasharrayPixels,
      });
      setSelect(typeLines?.[0].id);
    }
  }, [typeLines, errorTypeLines]);

  useEffect(() => {
    const typeLine = typeLines?.find(
      (typeLine) => typeLine.id === parseInt(select)
    );
    setLineStyle({
      nameTypeLine: typeLine?.type,
      descriptionTypeLine: typeLine?.description,
      lineTypeId: typeLine?.id,
      color: typeLine?.color,
      dasharrayWidth: typeLine?.dasharrayWidth,
      dasharrayPixels: typeLine?.dasharrayPixels,
    });
  }, [select]);

  const convertComponent = async () => {
    let body = {
      lineTypeId: Number(select),
      updatedAt: new Date(),
      userUpdatedBy: userId,
    };
    setIsLoad(true);
    await updateLinePg(body, line?.id);
    await updateLinePropertiesMg({
      id: line?.geojsonLineId,
      body: lineStyle,
    });
    setIsLoad(false);
    dispatch(setShowOperationDetails(false));
    setShowLineDetails(false);
    setOpenDialog(false);
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const urlDataLine = `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`;
    const urlLines = `${config.URL_BACKEND_PG}api/v1/lines?id=${line?.id}`;
    mutate(urlDataLine);
    mutate(urlLines);
  };

  return (
    <>
      {!errorTypeLines && (
        <Container>
          <h2 className="title">
            <span>Library: </span>
            {line.lineType.lineLibrary.name}
            <img src={line.lineType.lineLibrary.icon} />
          </h2>

          <p>
            Convert <strong>{line.lineType.type}</strong> to another line type.
          </p>
          <br />
          <CustomSelect
            options={typeLines
              ?.slice()
              .sort((a, b) => a.type.localeCompare(b.type))
              .map((item) => {
                return {
                  name: item.type,
                  id: item.id,
                };
              })}
            label={"Select another line type:"}
            select={select}
            setSelect={setSelect}
          />
          <ShowLinePreview
            color={lineStyle?.color}
            width={lineStyle?.dasharrayWidth}
            separator={lineStyle?.dasharrayPixels}
            text={false}
          />
          <CustomButton
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECOND_COLOR}
            text={"CONVERT"}
            onClick={convertComponent}
            isLoad={isLoad}
            margin={0}
          />
        </Container>
      )}
    </>
  );
};

export default ContentConvertComponent;
