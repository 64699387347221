import { useCallback, useEffect, useState } from "react";
import {
  ContainerButtonCenter,
  FormSetUp,
  ContainerInputs,
  ContentTypes,
} from "./FormStyles";
import InputComponent from "./InputSetUp";
import CustomButton from "components/Buttons/CustomButton";
import {
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  INFORMATION_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
} from "utils/const";
// import CustomSelect from "components/Buttons/CustomSelect";
import ModalHelpButton from "components/Buttons/ModalHelpButton";
import { roles } from "utils/roles";
import { IoTrash } from "react-icons/io5";

export default function Form(props) {
  const {
    form,
    handleChange,
    buttons,
    handleRows,
    renderBeforeButtons,
    renderBeforeTitle,
    title,
    loadingCreate,
    loadingDelete,
    setTypeElementsSelect,
    typeElementsSelect,
    role,
    // setSelect,
    // select,
  } = props;

  // To change the validate of the form
  const [formValidate, setFormValidate] = useState(true);
  const [elementTag, setElementTag] = useState();
  const exception = [
    "id",
    "created_at",
    "createdAt",
    undefined,
    "undefined",
    "adminCompanyId",
    "libraryId",
    "pointLibraryId",
    "lineLibraryId",
    "regionLibraryId",
    "pointLibrary",
    "pointLibraryEventId",
    "point_library_event_id",
    "pointLibraryEvent",
    "updatedAt",
    "fields",
    "mongoId",
    "typeEventId",
    "typeLibraryId",
    "type_library_id",
    "pointLibraryComponentId",
    "point_library_component_id",
    "pointLibraryComponent",
    "PointTypeEventRelation",
    "PointTypeEventRelationWith",
    "PointTypeObjectRelation",
    "PointTypeObjectRelationWith",
    "available",
  ];

  // Action the button
  // Callback is a function that will be called when the button is clicked
  // Callback(fieldOfForm, ifFormIsValid, handlingOfRowsAfterExecuting)
  const handleClick = (e, callback) => {
    e.preventDefault();
    for (let key in form) {
      if (form[key].value === null || form[key].value === "") {
        form[key].validate = "false";
        const element = document.getElementById(`${form[key].name}`);
        setElementTag(element);
      }
    }
    callback(form, formValidate, handleRows);
  };

  // Validate the form each that form have a change}
  useEffect(() => {
    const validate = Object.values(form).every((field) => {
      if (!field.validate) return true;
      return field.validate === "true" && field.value !== "";
    });
    setFormValidate(validate);
    if (elementTag) {
      elementTag.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [form, elementTag]);

  const preButtons = useCallback(() => {
    return renderBeforeButtons?.map((render) => render);
  }, [renderBeforeButtons]);

  return (
    <FormSetUp className="background-row-2 border-color-1">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>{title}</h1>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ModalHelpButton
            style={{ color: INFORMATION_COLOR, fontSize: "4.5rem" }}
          />
          {renderBeforeTitle?.map((render) => render)}
        </div>
      </div>
      <form>
        <ContainerInputs className="background-row-1 border-color-1 color1">
          {Object.keys(form)?.map((key, index) => {
            if (exception.includes(key)) return false;
            return (
              <InputComponent
                key={index}
                field={form[key]}
                onChange={handleChange}
                title={title}
              />
            );
          })}
        </ContainerInputs>
        {preButtons()}
        {role === roles.decimetrixAdmin && typeElementsSelect.length > 0 && (
          <ContentTypes>
            <h2 className="label">Types</h2>
            {typeElementsSelect.map((type, index) => {
              return (
                <div key={index} className="card-type-chart" onClick={() => {}}>
                  <p>{type.name} </p>
                  <div className="delete-type">
                    <img
                      width="30px"
                      src={
                        type.urlIconColor
                          ? type.urlIconColor
                          : "https://static.thenounproject.com/png/79163-200.png"
                      }
                      alt="icon object library"
                    />
                    <span
                      onClick={() => {
                        setTypeElementsSelect((prevState) => {
                          const updatedArray = prevState.filter(
                            (elm) => elm.name !== type.name
                          );
                          return updatedArray;
                        });
                      }}
                    >
                      <IoTrash color="red" size={25} />
                    </span>
                  </div>
                </div>
              );
            })}
          </ContentTypes>
        )}

        <ContainerButtonCenter inputSetUp={true}>
          {buttons?.map(
            ({ label, handleClick: handleClickButton, type }, index) => {
              return (
                <CustomButton
                  key={index}
                  type={type}
                  isLoad={
                    type === "create" || type === "update"
                      ? loadingCreate
                      : loadingDelete
                  }
                  onClick={(e) => handleClick(e, handleClickButton)}
                  text={label}
                  primaryColor={
                    type === "delete" ? DELETE_COLOR : PRIMARY_COLOR
                  }
                  secondaryColor={
                    type === "delete" ? HOVER_DELETE_COLOR : SECOND_COLOR
                  }
                />
              );
            }
          )}
        </ContainerButtonCenter>
      </form>
    </FormSetUp>
  );
}
