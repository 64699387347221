import axios from "axios";
import { config } from "config.js";
import { useUserStore } from "zustandGloabalStore/user";

const findPointLibraryEvents = async (libraryId) => {
  try {
    const instanceId = useUserStore.getState().instanceId;

    const token = localStorage.getItem("token");
    let url = `${config.URL_BACKEND_PG}api/v1/point-library-events?instanceId=${instanceId}`;
    if (libraryId) url = `${url}&id=${libraryId}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export default findPointLibraryEvents;
