import { config } from "config";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMapSettings } from "redux/actions";
import {
  createBackendMapSettings,
  updateBackendMapSettings,
} from "services/updateBackendMapSettings";
import useSWR, { mutate } from "swr";

export default function MapSettingsConfigurations() {
  const dispatch = useDispatch();
  // librerias por objs
  const [pointLibrariesObjects, setPointLibrariesObjects] = useState([]);
  const [pointLibrariesComponents, setPointLibrariesComponents] = useState([]);
  const [pointLibrariesEvents, setPointLibrariesEvents] = useState([]);
  const [lineLibraries, setLineLibraries] = useState([]);
  // campos por tipo de libreria (objs)
  const [fieldsByLibraryObjects, setFieldsByLibraryObjects] = useState([]);
  const [fieldsByLibraryComponents, setFieldsByLibraryComponents] = useState(
    []
  );
  const [fieldsByLibraryEvents, setFieldsByLibraryEvents] = useState([]);
  const [fieldsByLibraryLines, setFieldsByLibraryLines] = useState([]);

  // map settings
  const [mapSettings, setMapSettingsState] = useState({});
  // http requests
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const url = `${config.URL_BACKEND_MG}map-settings?adminCompanyId=${adminCompanyId}&userId=${userId}`;
  const { data: mapSettingsDB, error: errorMapSettingsDB } = useSWR(url);

  const urlQuery = new URLSearchParams();
  urlQuery.append("adminCompanyId", adminCompanyId);

  // Define URLs
  const lineLibrariesUrl = `${config.URL_BACKEND_PG}api/v1/line-libraries?${urlQuery}`;
  const pointLibrariesUrl = `${config.URL_BACKEND_PG}api/v1/point-libraries?${urlQuery}`;
  const pointLibraryComponentsUrl = `${config.URL_BACKEND_PG}api/v1/point-library-components?${urlQuery}`;
  const pointLibraryEventsUrl = `${config.URL_BACKEND_PG}api/v1/point-library-events?${urlQuery}`;

  // Use SWR to fetch data
  const { data: libraries, error: librariesError } = useSWR(lineLibrariesUrl);
  const { data: librariesObjects, error: librariesObjectsError } =
    useSWR(pointLibrariesUrl);
  const { data: librariesComponents, error: librariesComponentsError } = useSWR(
    pointLibraryComponentsUrl
  );
  const { data: librariesEvents, error: librariesEventsError } = useSWR(
    pointLibraryEventsUrl
  );

  const defaultColorOptions = useCallback((lib = [], error, type, libType) => {
    const response =
      error === undefined && type.length > 0 && libType.length > 0
        ? lib.map((lb) => {
            if (libType === "line") {
              return [lb.id, type];
            }
            return [lb.id, type, libType];
          })
        : [];
    return response;
  }, []);

  // useEffect for setting points and line libraries
  useEffect(() => {
    if (
      librariesObjects &&
      !librariesObjectsError &&
      librariesObjects?.length >= 0
    ) {
      setPointLibrariesObjects(librariesObjects);
    }
    if (
      librariesComponents &&
      !librariesComponentsError &&
      librariesComponents?.length >= 0
    ) {
      setPointLibrariesComponents(librariesComponents);
    }
    if (
      librariesEvents &&
      !librariesEventsError &&
      librariesEvents?.length >= 0
    ) {
      setPointLibrariesEvents(librariesEvents);
    }
    if (libraries && !librariesError && libraries?.length >= 0) {
      setLineLibraries(libraries);
    }
  }, [
    libraries,
    librariesError,
    librariesObjects,
    librariesObjectsError,
    librariesComponents,
    librariesComponentsError,
    librariesEvents,
    librariesEventsError,
  ]);

  // useEffect for setting fields by libraries
  useEffect(() => {
    const auxObjects = [];
    const fieldsToSelect = ["select", "number"];
    pointLibrariesObjects.forEach((ln) => {
      auxObjects.push(parseLibrary(ln, fieldsToSelect));
    });
    setFieldsByLibraryObjects(auxObjects);

    const auxComponents = [];
    pointLibrariesComponents.forEach((ln) => {
      auxComponents.push(parseLibrary(ln, fieldsToSelect));
    });
    setFieldsByLibraryComponents(auxComponents);

    const auxEvents = [];
    pointLibrariesEvents.forEach((ln) => {
      auxEvents.push(parseLibrary(ln, fieldsToSelect));
    });
    setFieldsByLibraryEvents(auxEvents);

    const auxLines = [];
    lineLibraries.forEach((ln) => {
      auxLines.push(parseLibrary(ln, fieldsToSelect));
    });
    setFieldsByLibraryLines(auxLines);
  }, [
    pointLibrariesObjects,
    pointLibrariesComponents,
    pointLibrariesEvents,
    lineLibraries,
  ]);

  // setear como venga el map settings
  useEffect(() => {
    const setMapConfig = (mp) => {
      if (Object.keys(mp).length > 0) {
        setMapSettingsState(mp);
        return;
      }
      setMapSettingsState({});
      return;
    };

    if (
      !errorMapSettingsDB &&
      mapSettingsDB !== undefined &&
      mapSettingsDB.length > 0
    ) {
      setMapConfig(mapSettingsDB[0]);
    }
  }, [mapSettingsDB, errorMapSettingsDB]);

  //  SET MAP SETTINGS CONFIGURATION ON REDUX STATE
  useEffect(() => {
    const setMapConfig = async () => {
      const mapSettingsStatuses = getMapSettingsStattuses(mapSettings);
      // map config exists on DB so set it on mapSettings redux state
      if (
        !mapSettingsStatuses.isDynamicColorsEmpty &&
        !mapSettingsStatuses.isObjectsColorsEmpty &&
        !mapSettingsStatuses.isComponentsColorsEmpty &&
        !mapSettingsStatuses.isEventsColorsEmpty &&
        !mapSettingsStatuses.isMeasurementUnitUndefined &&
        !mapSettingsStatuses.isUserConfigColorsUndefined &&
        !mapSettingsStatuses.isCheckedLinesUndefined &&
        !mapSettingsStatuses.isCheckedPointsUndefined &&
        !mapSettingsStatuses.isUserConfigColorLinesEmpty &&
        !mapSettingsStatuses.isUserConfigColorObjectsEmpty &&
        !mapSettingsStatuses.isUserConfigColorComponentsEmpty &&
        !mapSettingsStatuses.isUserConfigColorEventsEmpty &&
        !mapSettingsStatuses.isFloatingDecimalsUndefined
      ) {
        dispatch(
          setMapSettings({
            ...mapSettings,
            libraries,
            librariesObjects,
            librariesEvents,
            librariesComponents,
            fieldsByLibraryObjects,
            fieldsByLibraryComponents,
            fieldsByLibraryEvents,
          })
        );
        return;
      }
      return;
    };

    adminCompanyId !== undefined &&
      userId !== undefined &&
      libraries !== undefined &&
      librariesObjects !== undefined &&
      librariesComponents !== undefined &&
      librariesEvents !== undefined &&
      (fieldsByLibraryObjects.length ||
        fieldsByLibraryComponents.length ||
        fieldsByLibraryEvents.length) &&
      setMapConfig();
  }, [
    userId,
    adminCompanyId,
    dispatch,
    defaultColorOptions,
    errorMapSettingsDB,
    mapSettings,
    libraries,
    librariesError,
    librariesComponents,
    librariesComponentsError,
    librariesEvents,
    librariesEventsError,
    librariesObjects,
    librariesObjectsError,
    fieldsByLibraryObjects,
    fieldsByLibraryComponents,
    fieldsByLibraryEvents,
    fieldsByLibraryLines,
  ]);

  // CREATE or UPDATE
  useEffect(() => {
    if (adminCompanyId !== undefined && userId !== undefined) {
      const dynamicColorsSelected = defaultColorOptions(
        libraries !== undefined && libraries.length > 0 ? libraries : [],
        librariesError,
        "lineType",
        "line"
      );
      const objectsColorsSelected = defaultColorOptions(
        librariesObjects !== undefined && librariesObjects.length !== 0
          ? librariesObjects
          : [],
        librariesObjectsError,
        "pointType",
        "object"
      );
      const componentsColorsSelected = defaultColorOptions(
        librariesComponents !== undefined && librariesComponents.length !== 0
          ? librariesComponents
          : [],
        librariesComponentsError,
        "pointType",
        "component"
      );
      const eventsColorsSelected = defaultColorOptions(
        librariesEvents !== undefined && librariesEvents !== 0
          ? librariesEvents
          : [],
        librariesEventsError,
        "pointType",
        "event"
      );
      const colorLines = fieldsByLibraryLines.map((lib) => {
        const auxFields = lib.fields.map((fd) => {
          if (fd.name !== "lineType") fd.state = false;
          return fd;
        });
        auxFields.push({
          name: "lineType",
          type: "lineType",
          state: true,
          color: [],
        });
        lib.fields = auxFields;
        return lib;
      });
      const colorObjects = fieldsByLibraryObjects.map((lib) => {
        const auxFields = lib.fields.map((fd) => {
          if (fd.name !== "pointType") fd.state = false;
          return fd;
        });
        auxFields.push({
          name: "pointType",
          type: "pointType",
          state: true,
          color: [],
        });
        lib.fields = auxFields;
        return lib;
      });
      const colorComponents = fieldsByLibraryComponents.map((lib) => {
        const auxFields = lib.fields.map((fd) => {
          if (fd.name !== "pointType") fd.state = false;
          return fd;
        });
        auxFields.push({
          name: "pointType",
          type: "pointType",
          state: true,
          color: [],
        });
        lib.fields = auxFields;
        return lib;
      });
      const colorEvents = fieldsByLibraryEvents.map((lib) => {
        const auxFields = lib.fields.map((fd) => {
          if (fd.name !== "pointType") fd.state = false;
          return fd;
        });
        auxFields.push({
          name: "pointType",
          type: "pointType",
          state: true,
          color: [],
        });
        lib.fields = auxFields;
        return lib;
      });
      const measurementUnit = {
        symbol: "km",
        unit: "kilometers",
      };

      const updateMapConfig = async () => {
        let body = {
          adminCompanyId,
          userId,
          userConfigColors:
            mapSettings.userConfigColors !== undefined
              ? { ...mapSettings.userConfigColors }
              : {},
          ...mapSettings,
        };
        const mapSettingsStatuses = getMapSettingsStattuses(mapSettings);
        if (mapSettingsStatuses.isDynamicColorsEmpty) {
          body.dynamicColorsSelected = dynamicColorsSelected;
        }
        if (mapSettingsStatuses.isObjectsColorsEmpty) {
          body.objectsColorsSelected = objectsColorsSelected;
        }
        if (mapSettingsStatuses.isComponentsColorsEmpty) {
          body.componentsColorsSelected = componentsColorsSelected;
        }
        if (mapSettingsStatuses.isEventsColorsEmpty) {
          body.eventsColorsSelected = eventsColorsSelected;
        }
        if (mapSettingsStatuses.isMeasurementUnitUndefined) {
          body.measurementUnit = measurementUnit;
        }
        if (mapSettingsStatuses.isFloatingDecimalsUndefined) {
          body.floatingDecimals = 4;
        }
        if (mapSettingsStatuses.isCheckedLinesUndefined) {
          body.userConfigColors.checkedLines = false;
        }
        if (mapSettingsStatuses.isCheckedPointsUndefined) {
          body.userConfigColors.checkedPoints = false;
        }
        if (mapSettingsStatuses.isUserConfigColorLinesEmpty) {
          body.userConfigColors.colorLines = colorLines;
        }
        if (mapSettingsStatuses.isUserConfigColorObjectsEmpty) {
          body.userConfigColors.colorObjects = colorObjects;
        }
        if (mapSettingsStatuses.isUserConfigColorComponentsEmpty) {
          body.userConfigColors.colorComponents = colorComponents;
        }
        if (mapSettingsStatuses.isUserConfigColorEventsEmpty) {
          body.userConfigColors.colorEvents = colorEvents;
        }
        body.settingsUpdated = true;

        await updateBackendMapSettings({
          id: userId,
          body,
        });
        mutate(url);
        dispatch(
          setMapSettings({
            ...body,
            libraries,
            librariesObjects,
            librariesEvents,
            librariesComponents,
            fieldsByLibraryObjects,
            fieldsByLibraryComponents,
            fieldsByLibraryEvents,
          })
        );
        return;
      };

      const createMapConfig = async () => {
        const body = {
          adminCompanyId,
          userId,
          settingsUpdated: true,
          dynamicColorsSelected,
          objectsColorsSelected,
          componentsColorsSelected,
          eventsColorsSelected,
          measurementUnit,
          floatingDecimals: 4,
          userConfigColors: {
            checkedPoints: false,
            checkedLines: false,
            colorLines,
            colorObjects,
            colorComponents,
            colorEvents,
          },
        };
        const { data } = await createBackendMapSettings({
          body,
        });
        mutate(url);
        if (data.status && data.status === 403) return;
        data &&
          dispatch(
            setMapSettings({
              ...data,
            })
          );

        return;
      };

      if (mapSettings === undefined || Object.keys(mapSettings).length === 0) {
        // SET MAP SETTINGS CONFIGURATIONS DOES NOT EXIST ( CREATE )
        createMapConfig();
      } else {
        // SET MAP SETTINGS CONFIGURATIONS WHEN SOMETHING IS MISSING ( UPDATE )
        updateMapConfig();
      }
      return;
    }
  }, [
    userId,
    adminCompanyId,
    dispatch,
    defaultColorOptions,
    errorMapSettingsDB,
    mapSettings,
    libraries,
    librariesError,
    librariesComponents,
    librariesComponentsError,
    librariesEvents,
    librariesEventsError,
    librariesObjects,
    librariesObjectsError,
    fieldsByLibraryObjects,
    fieldsByLibraryComponents,
    fieldsByLibraryEvents,
    fieldsByLibraryLines,
    url,
  ]);
  return <></>;
}

function parseLibrary(item, types = []) {
  if (item === undefined || !types.length) return null;
  const fields =
    item.fields !== undefined
      ? item.fields.filter((fd) => types.includes(fd.type) && fd.circuit)
      : [];
  return {
    id: item.lineLibraryId || item.id,
    name: item.name,
    fields,
  };
}

function getMapSettingsStattuses(mapSettings) {
  if (mapSettings === undefined) return undefined;
  const toReturn = {
    // default colors
    isDynamicColorsEmpty:
      mapSettings.dynamicColorsSelected === undefined ||
      mapSettings.dynamicColorsSelected.length === 0,
    isObjectsColorsEmpty:
      mapSettings.objectsColorsSelected?.length === 0 ||
      mapSettings.objectsColorsSelected === undefined,
    isComponentsColorsEmpty:
      mapSettings.componentsColorsSelected?.length === 0 ||
      mapSettings.componentsColorsSelected === undefined,
    isEventsColorsEmpty:
      mapSettings.eventsColorsSelected?.length === 0 ||
      mapSettings.eventsColorsSelected === undefined,
    // other configs
    isMeasurementUnitUndefined:
      mapSettings.measurementUnit === undefined ||
      mapSettings.measurementUnit === null,
    isFloatingDecimalsUndefined:
      mapSettings.floatingDecimals === undefined ? true : false,
    // user custom colors
    isUserConfigColorsUndefined:
      mapSettings.userConfigColors === undefined ? true : false,
    isCheckedLinesUndefined:
      mapSettings.userConfigColors?.checkedLines === undefined,
    isCheckedPointsUndefined:
      mapSettings.userConfigColors?.checkedPoints === undefined,
    isUserConfigColorLinesEmpty:
      mapSettings.userConfigColors?.colorLines?.length === 0 ||
      mapSettings.userConfigColors?.colorLines === undefined,
    isUserConfigColorObjectsEmpty:
      mapSettings.userConfigColors?.colorObjects?.length === 0 ||
      mapSettings.userConfigColors?.colorObjects === undefined,
    isUserConfigColorComponentsEmpty:
      mapSettings.userConfigColors?.colorComponents?.length === 0 ||
      mapSettings.userConfigColors?.colorComponents === undefined,
    isUserConfigColorEventsEmpty:
      mapSettings.userConfigColors?.colorEvents?.length === 0 ||
      mapSettings.userConfigColors?.colorEvents === undefined,
  };
  return toReturn;
}
