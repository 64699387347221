import { config } from "config.js";
import { useState, useEffect } from "react";

async function fetchLineTypes(id, adminCompanyId) {
  const urlParams = new URLSearchParams();
  let baseUrl = `${config.URL_BACKEND_PG}api/v1/type-lines`;

  if (id !== null && !isNaN(parseInt(id))) {
    baseUrl = `${config.URL_BACKEND_PG}api/v1/type-lines/library/${parseInt(
      id
    )}`;
  }

  if (adminCompanyId !== null && !isNaN(parseInt(adminCompanyId))) {
    urlParams.append("adminCompanyId", parseInt(adminCompanyId));
  }

  // Agregar los parámetros a la URL solo si hay alguno
  const finalUrl = urlParams.toString()
    ? `${baseUrl}?${urlParams.toString()}`
    : baseUrl;

  const token = localStorage.getItem("token");

  try {
    const response = await fetch(finalUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    if (!response.ok) {
      console.error(
        "❌ Response not OK:",
        response.status,
        response.statusText
      );
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("💥 Fetch error:", error);
  }
}

function useGetLineTypes({ id = null, adminCompanyId = null }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchLineTypes(id, adminCompanyId);
        setData(result);
      } catch (err) {
        setError(err);
      }
    };

    getData();
  }, [id, adminCompanyId]);

  return { data, error };
}

export default useGetLineTypes;
