import React, { useEffect, useRef, useState } from "react";
import {
  CardContainer,
  HubCard,
  HubContainer,
  HubH2,
  HubP,
  HubWrapper,
} from "./HubStyles";
import useGet from "hooks/useFetch";
import { useNavigate } from "react-router-dom";
import CustomTabs from "./CustomTabs";
import DescriptionHub from "./DescriptionHub";
import Layout from "components/Layout/AdminDecimetrix";
import contentCardsTutorials from "pages/PlatformSetUp/contentCardsTutorials";
import { urls } from "utils/urlKeys";

function Tutorials() {
  const cardContainerRef = useRef(null);
  const [value, setValue] = React.useState(3);

  const handleChange = (event, newValue) => {
    //event.preventDefault();
    setValue(newValue);
  };

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (value === 0) {
      navigate("/admin");
    }
    if (value === 1) {
      navigate("/settings-app");
    }
  }, [value]);

  const handleMouseDown = (event) => {
    if (!cardContainerRef.current) return;
    setIsDragging(true);
    setStartX(event.pageX - cardContainerRef.current.offsetLeft);
    setScrollLeft(cardContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!cardContainerRef.current) return;
    if (!isDragging) return;
    const x = event.pageX - cardContainerRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    cardContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const [adminCompany] = useGet(urls.adminCompany.detail);
  const [adminDecimetrix] = useGet(urls.adminDecimetrix.detail);

  return (
    <>
      <Layout>
        <DescriptionHub
          adminCompany={adminCompany}
          adminDecimetrix={adminDecimetrix}
        />
        <CustomTabs value={value} onChange={handleChange} />
        <HubContainer className="background-2 border-top-1">
          <CardContainer
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <HubWrapper ref={cardContainerRef} className="wrap-style">
              {contentCardsTutorials.map((item) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <HubCard
                    className="background-card-color"
                    key={item.id}
                    onClick={() => navigate(item.toNavigate)}
                  >
                    <HubH2>{item.title}</HubH2>
                    {item.image}
                    <HubP
                      style={{
                        fontSize:
                          item.description.length > 50 ? "13px" : "18px",
                      }}
                    >
                      {item.description}
                    </HubP>
                  </HubCard>
                </div>
              ))}
            </HubWrapper>
          </CardContainer>
        </HubContainer>
      </Layout>
    </>
  );
}

export default Tutorials;
