import { useState, useEffect } from "react";
// Hooks
import { useFetchComponentsLibraries } from "hooks/fetchLibraries";
import useFindComponents from "hooks/components/useFindComponents";
import useColumnsWithUniqueValues from "hooks/dashboard/components/useColumnsWithUniqueValues2";
// Helpers
import SelectColumnsLibrary from "pages/Dashboard/components/SelectColumnsLibrary";
import { createRowsComponents } from "pages/Dashboard/helpers/createRowsComponents";
import HandlerClickColumn from "pages/Dashboard/helpers/handlerClickColumn";
// Components
import SelectLibrary from "./SelectLibrary";
import SelectColumnLibrary from "pages/Dashboard/components/SelectColumnLibrary";
import SelectTypeOperation from "pages/Dashboard/components/SelectTypeOperation";
import CustomButton from "components/Buttons/CustomButton";
// Const
import { defaultFieldsComponents } from "utils/defaultColumns";
import { PRIMARY_COLOR } from "utils/const";
// Services
import { createChart } from "services/Dashboard/barChart";
import { useSWRConfig } from "swr";
import { addChartInAllViews } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";
import FetchComponents from "hooks/fetchComponents";
import { urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

const ComponentFormBar = ({ setOpenDialog }) => {
  const [columnsLibrary, setColumnsLibrary] = useState([]);
  const [columnsSelectedFilters, setColumnsSelectedFilters] = useState([]);
  const [columnsSelectedXAxis, setColumnsSelectedXAxis] = useState([]);
  const [columnsTypeNumber, setColumnsTypeNumber] = useState([]);
  const [loading, setLoading] = useState(false);
  const [librarySelectedId, setLibrarySelectedId] = useState(null);
  const [columnValuesFilters, setColumnValuesFilters] = useState({});
  const [componentsFormat, setComponentsFormat] = useState([]);
  const [columnValuesXAxis, setColumnValuesXAxis] = useState({});
  const [dataInputs, setDataInputs] = useState({
    librarySelected: null,
    columnY: null,
    alias: "",
    color: null,
    typeOperation: "count",
    horizontal: false,
  });
  const [columnsLibrarySelected, setColumnsLibrarySelected] = useState([]);
  const [typeComponentNames, setTypeComponentNames] = useState([]);
  const { instanceId } = useUserStore((state) => state);

  const { data: libraries, error } = useFetchComponentsLibraries({});
  const { data: typeComponents, error: errorTypeComponents } =
    FetchComponents(librarySelectedId);

  const { components } = useFindComponents();
  const { mutate } = useSWRConfig();
  const setNewCreateChart = useStoreDashboard(
    (state) => state.setNewCreateChart
  );
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);
  const filterColumnsTypeNumber = (columns) => {
    const numberFields = columns?.filter(
      (field) =>
        field.type === "number" ||
        field.type === "operation" ||
        field.type === "range"
    );
    setColumnsTypeNumber(numberFields || []);
  };

  useEffect(() => {
    // find library by library select from libraries
    if (libraries && dataInputs.librarySelected) {
      const library = libraries.find(
        (library) => library.id === dataInputs.librarySelected.value
      );

      const columnsLibrary = library?.fields;

      setColumnsLibrarySelected(columnsLibrary);

      const columnsFiltered = columnsLibrary?.filter(
        (field) =>
          field.type !== "captureMedia" &&
          field.type !== "notification" &&
          field.type !== "number" &&
          field.type !== "operation" &&
          field.type !== "range"
      );
      if (columnsFiltered && columnsFiltered.length > 0) {
        setColumnsLibrary(
          [...defaultFieldsComponents, ...columnsFiltered] || []
        );
      } else {
        setColumnsLibrary([...defaultFieldsComponents] || []);
      }
      filterColumnsTypeNumber(columnsLibrary || []);
    } else {
      setColumnsLibrary([]);
    }
  }, [libraries, dataInputs.librarySelected]);

  useEffect(() => {
    if (
      librarySelectedId &&
      !errorTypeComponents &&
      typeComponents?.length > 0
    ) {
      const names = typeComponents.map((elm) => {
        return elm.name;
      });
      setTypeComponentNames(names);
    }
  }, [typeComponents, errorTypeComponents, librarySelectedId]);

  const updateDataInputs = (newState) => {
    setDataInputs((prevState) => ({ ...prevState, ...newState }));
  };

  const handlerTypeOperation = (e) => {
    updateDataInputs({ typeOperation: e });
  };

  const handlerClickColumnY = (column) => {
    updateDataInputs({ columnY: column });
  };

  const handlerAlias = (e) => {
    updateDataInputs({ alias: e.target.value });
  };

  const handlerColor = (e) => {
    updateDataInputs({ color: e.target.value });
  };

  const handlerHorizontal = () => {
    updateDataInputs({ horizontal: !dataInputs.horizontal });
  };

  const handlerClickColumnFilters = HandlerClickColumn({
    setColumnsSelected: setColumnsSelectedFilters,
    columnValues: columnValuesFilters,
    setColumnValues: setColumnValuesFilters,
  });

  const handlerClickColumnXAxis = HandlerClickColumn({
    setColumnsSelected: setColumnsSelectedXAxis,
    columnValues: columnValuesXAxis,
    setColumnValues: setColumnValuesXAxis,
    multi: false,
  });

  useEffect(() => {
    setColumnValuesXAxis({});
  }, [columnsSelectedXAxis]);

  const {
    columnsWithValues: columnsWithValuesFilters,
    setColumnsWithValues: setColumnsWithValuesFilters,
  } = useColumnsWithUniqueValues({
    components: componentsFormat,
    columnsSelected: columnsSelectedFilters,
    handlerClickColumn: handlerClickColumnFilters,
    columnsLibrary: columnsLibrarySelected,
  });

  const {
    columnsWithValues: columnsWithValuesXAxis,
    setColumnsWithValues: setColumnsWithValuesXAxis,
  } = useColumnsWithUniqueValues({
    components: componentsFormat,
    columnsSelected: columnsSelectedXAxis,
    handlerClickColumn: handlerClickColumnXAxis,
    columnsLibrary: columnsLibrarySelected,
  });

  const handlerColumnValuesFilters = (columnName, selectedValues) => {
    setColumnValuesFilters({
      ...columnValuesFilters,
      [columnName]: selectedValues,
    });
  };

  const handlerColumnValuesXAxis = (columnName, selectedValues) => {
    setColumnValuesXAxis({
      ...columnValuesXAxis,
      [columnName]: selectedValues,
    });
  };

  const handlerLibrarySelected = (e) => {
    updateDataInputs({ librarySelected: e });
    const library = libraries.find((library) => library.id === e.value);
    setLibrarySelectedId(library.id);
    const componentsByLibraryId = components.filter(
      (component) =>
        component.pointTypeComponent.pointLibraryComponentId === e.value
    );
    const rows = createRowsComponents({
      components: componentsByLibraryId,
      library,
    });
    setComponentsFormat(rows);
    updateDataInputs({ columnY: null, columnX: null, alias: "" });
    setColumnsWithValuesFilters([]);
    setColumnsWithValuesXAxis([]);
  };

  const getValuesColumnsX = (input) => {
    // Obtener la clave del primer (y único) campo del objeto
    const key = Object.keys(input)[0];
    const elements = input[key];

    const result = elements?.map((element) => element.value) || [];

    return result;
  };

  const handlerAddChart = async () => {
    const userId = localStorage.getItem("userId");
    const urlBarChart = urlsApiMg.dashboard.barChart("components");
    const urlOrders = urlsApiMg.dashboard.orderCards;

    const columnsValuesFilter = Object.entries(columnValuesFilters).map(
      ([name, values]) => ({
        name,
        values: values.map((item) => item.value),
      })
    );

    const valuesColumnX = getValuesColumnsX(columnValuesXAxis);

    const chart = {
      from: "components",
      columnX: {
        name: columnsSelectedXAxis[0],
        values: valuesColumnX,
      },
      columnsY: dataInputs.columnY ? [dataInputs.columnY] : [],
      alias: dataInputs.alias,
      libraryId: dataInputs.librarySelected.value,
      color: dataInputs.color || PRIMARY_COLOR,
      type: dataInputs.typeOperation,
      adminCompanyId: parseInt(localStorage.getItem("adminCompanyId")),
      columnsValuesFilter,
      horizontal: dataInputs.horizontal,
      instanceId: instanceId,
    };

    setLoading(true);
    const { data } = await createChart(chart);
    if (data) {
      mutate(urlBarChart);
      await addChartInAllViews({
        userId,
        type: "components",
        body: {
          newChart: {
            id: data._id,
          },
        },
      });
      mutate(urlOrders);
      setNewCreateChart(!newCreateChart);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  return (
    <>
      {!error && libraries && (
        <SelectLibrary
          libraries={libraries}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={dataInputs.librarySelected}
        />
      )}
      {dataInputs.librarySelected && (
        <SelectTypeOperation
          handlerSelectTypeOperation={handlerTypeOperation}
        />
      )}
      {dataInputs.librarySelected &&
        columnsLibrary &&
        dataInputs.typeOperation === "sum" && (
          <SelectColumnLibrary
            title={"Select Y-Axis"}
            columnsLibrary={columnsTypeNumber}
            handlerClickColumn={handlerClickColumnY}
            columnSelected={dataInputs.columnY}
          />
        )}
      {dataInputs.librarySelected && columnsLibrary && (
        <SelectColumnsLibrary
          columnsLibrary={columnsLibrary}
          handlerClickColumn={handlerClickColumnXAxis}
          columnsSelected={columnsSelectedXAxis}
          columnsWithValues={columnsWithValuesXAxis}
          handlerColumnValues={handlerColumnValuesXAxis}
          columnValues={columnValuesXAxis}
          typeElementNames={typeComponentNames}
          title={"Select X-Axis"}
        />
      )}
      {dataInputs.librarySelected && columnsLibrary && (
        <SelectColumnsLibrary
          columnsLibrary={columnsLibrary}
          handlerClickColumn={handlerClickColumnFilters}
          columnsSelected={columnsSelectedFilters}
          columnsWithValues={columnsWithValuesFilters}
          handlerColumnValues={handlerColumnValuesFilters}
          columnValues={columnValuesFilters}
          typeElementNames={typeComponentNames}
          title={"Set filters"}
        />
      )}

      <section className="container-type-charts ">
        <div>
          <h2 className="subtitle">Horizontal</h2>
          <div className="checkbox-container">
            <input
              className="checkbox"
              type="checkbox"
              value={dataInputs.horizontal}
              onChange={handlerHorizontal}
            />
          </div>
        </div>
        <div>
          <h2 className="subtitle">Alias</h2>
          <input
            className="alias"
            type="text"
            value={dataInputs.alias}
            onChange={(e) => {
              handlerAlias(e);
            }}
            required={true}
          />
        </div>
        <div>
          <h2 className="subtitle">Color</h2>
          <input
            className="alias"
            type="color"
            value={dataInputs.color || PRIMARY_COLOR}
            onChange={(e) => {
              handlerColor(e);
            }}
          />
        </div>
      </section>
      {dataInputs.alias && (
        <CustomButton
          className="btn-add-chart"
          onClick={() => handlerAddChart()}
          isLoad={loading}
          text={"Add chart"}
          margin={"15px 0"}
        />
      )}
    </>
  );
};

export default ComponentFormBar;
