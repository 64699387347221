import styled from "styled-components";
import { BLACK_COLOR, WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";
import { Box } from "@mui/material";

export const ContainerConfigGlobalValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 15px;
  border: solid #cccccc 1px;
  border-radius: 5px;
  background: ${WHITE_COLOR};
`;

export const ContainerTable = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #cccccc;
`

export const GalleryContainerNoThumbnails = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    background-color: ${BLACK_COLOR};
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .image-gallery-content {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 0px 0px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const ImageGalleryStyleWrapper = styled.div`
  .image-gallery-left-nav {
    padding: 0px 0px !important;
  }
  .image-gallery-right-nav {
    padding: 0px 0px !important;
  }
  .image-gallery-bullet {
    margin: 5px;
  }
  .image-gallery-thumbnail-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 10px 0;
  background: #f2f2f2;
  padding: 10px;
  border: solid 1px #cccccc;
  border-radius: 5px;

  .label {
    width: 100%;
    margin-bottom: 10px;
    justify-content: start;
    border-bottom: 1px solid #cccccc;
  }

  .select {
    font-size: 1.4rem;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    margin-bottom: 15px;
    height: 35px;
  }

  .options {
    font-size: 1.4rem;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};

    &:hover {
      background-color: ${SECOND_COLOR};
    }
  }

  h2 {
    margin-bottom: 20px;
  }
`;

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 10px 0;
  padding: 14px;
  background: #ffffff;
  border-radius: 5px;
  border: solid 1px #cccccc;
  .label {
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
  }
  span {
    font-size: 2rem;
    text-align: center;
    font-weight: 900;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    border: none;
    border-radius: 5px;
  }
`;

export const ContainerSelectedLibrary = styled.div`
  width: 100%;
  margin-bottom: 10px;

  h3 {
    margin-bottom: 5px;
  }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background: white;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease;
  }

  .library-name {
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }

  .icon-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding-left: 1rem;
    border-left: 1px solid #e5e7eb;
  }
  img {
    width: 65px;
  }
`;

export const LoadingContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
});
