import { useCallback, useEffect, useMemo, useState } from "react";
import useSupercluster from "use-supercluster";
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";

// Components
import MarkerElement from "./MarkerElement";
import MarkerCluster from "./MarkerCluster";
import ComponentDetails from "components/ElementDetails/ComponentDetails/Index.jsx";
import { setDataCompoentsPolygon } from "redux/actions/admin";
import { setFilterComponent } from "redux/actions/digitalTwin";

import POINT_TYPE from "./const";
import { useStore } from "react-redux";
import getColorFromField from "helpers/getColorFromField";
import { getNumericValue } from "helpers/getNumericValue";
import { COMPONENT_CLUSTER_COLOR } from "utils/const";
/**
 * Renders the clusters of components on a map.
 *
 * @param {Object} components - an array of components
 * @param {Object} bounds - the bounds of the map
 * @param {Function} onSelectPoint - a function to handle selecting a point
 * @param {Object} viewState - the current state of the map view
 * @param {Function} setViewState - a function to set the state of the map view
 * @return {JSX.Element} the rendered clusters of components
 */
const ComponentsClusters = ({
  components,
  componentsMap,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
}) => {
  const [pointColorsByMapSettings, setPointColorsByMapSettings] = useState([]);

  const dispatch = useDispatch();
  const store = useStore();

  const state = store.getState();

  const dataComponentPolygon = state.adminReducer.dataComponentPolygon;

  const componentClusters = useSelector(
    (state) => state.digitalTwinReducer.componentClusters
  );

  const stateFilterComponent = useSelector(
    (state) => state.digitalTwinReducer.stateFilterComponent
  );

  const showDataComponentsByType = useSelector(
    (state) => state.digitalTwinReducer.showDataComponentsByType
  );

  const showDataComponents = useSelector(
    (state) => state.digitalTwinReducer.showDataComponents
  );

  const dataTable = useSelector(
    (state) => state.adminReducer.componentsFilterTable
  );

  const filterComponents = showDataComponentsByType?.filter((elm) => {
    return elm.state === true;
  });

  const mapSettingsState = useSelector(
    (state) => state.digitalTwinReducer.mapSettings
  );

  const dataCompoents = components?.filter((component) => {
    return filterComponents?.some((componentFilter) => {
      return (
        component.pointTypeComponent.id === componentFilter.id &&
        component.pointTypeComponent.pointLibraryComponentId ===
          componentFilter.libraryId &&
        componentFilter.state === true
      );
    });
  });

  const dataCompoentsMap = componentsMap?.filter((component) => {
    return filterComponents?.some((componentFilter) => {
      return (
        component.properties.component.pointTypeComponentId ===
          componentFilter.id &&
        component.properties.component.pointLibraryComponentId ===
          componentFilter.libraryId &&
        componentFilter.state === true
      );
    });
  });

  useEffect(() => {
    if (dataComponentPolygon.length !== dataCompoents.length) {
      dispatch(setDataCompoentsPolygon(dataCompoents));
    }
    dispatch(setFilterComponent(false));
  }, [
    dispatch,
    showDataComponentsByType,
    stateFilterComponent,
    showDataComponents,
    dataCompoents,
  ]);

  useEffect(() => {
    let aux = [];
    let value = 0;
    const typeColor = COMPONENT_CLUSTER_COLOR;

    if (
      mapSettingsState !== undefined &&
      Object.keys(mapSettingsState).length > 0
    ) {
      let newColor = "";
      const { componentsColorsSelected, fieldsByLibraryComponents } =
        mapSettingsState;
      aux = components.map((component) => {
        const { attributes, id, pointTypeComponent } = component;
        const { pointLibraryComponentId } = pointTypeComponent;
        const optionSelected = componentsColorsSelected?.filter(
          (it) => it[0] === pointLibraryComponentId
        )?.[0]; // tiene esta estructura [ 1, "pointType", "component" ]
        const library = fieldsByLibraryComponents?.filter(
          (it) => it.id === pointLibraryComponentId
        )?.[0];
        const libraryField = library?.fields?.filter(
          (it) => it.name === optionSelected?.[1]
        )?.[0]; // contiene la estructura definida en Fields.jsx
        const field = attributes?.filter(
          (it) => it.name === optionSelected?.[1]
        )?.[0];
        if (libraryField === undefined) {
          newColor = typeColor;
        }
        if (libraryField !== undefined && libraryField?.type === "select") {
          const colorIndex = libraryField?.options?.indexOf(field?.value);
          newColor = getColorFromField({ field: libraryField, colorIndex }); // #000000
        }
        if (libraryField !== undefined && libraryField?.type === "number") {
          // valor del campo numerico seleccionado en el map settings
          value = getNumericValue(field?.value); // 20 ... 100  ... NaN
          newColor = getColorFromField({
            field: libraryField,
            value,
            typeColor,
          }); // #000000
        }
        return {
          id,
          pointLibraryComponentId,
          optionSelected,
          field,
          value,
          newColor,
        };
      });
    }
    setPointColorsByMapSettings(aux);
  }, [components, mapSettingsState]);

  const newPoints =
    dataTable !== undefined && dataTable.length !== 0
      ? dataTable.map((component) => ({
          type: "Feature",
          properties: {
            cluster: false,
            element: {
              id: component.id,
              type: POINT_TYPE.component,
              icon: component.pointTypeComponent.icon,
              location: {
                latitude: component.locationComponent.latitude,
                longitude: component.locationComponent.longitude,
              },
              label: component?.label,
              QAQC: component?.QAQC,
            },
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(component.locationComponent.longitude),
              parseFloat(component.locationComponent.latitude),
            ],
          },
        }))
      : dataCompoentsMap;

  const { clusters } = useSupercluster({
    points: newPoints,
    bounds,
    zoom: viewState.zoom,
    options: {
      radius: componentClusters.radius || 0,
      maxZoom: componentClusters.maxZoom || 0,
    },
  });

  const handleClickComponent = useCallback(
    (componentId) => {
      const component = {
        content: <ComponentDetails componentId={componentId} />,
        title: "Component Details",
      };
      dispatch(setOperationDetails(component));
      dispatch(setShowOperationDetails(true));
    },
    [dispatch]
  );

  const renderMarkerElement = useMemo(
    () => (component) => {
      return (
        <MarkerElement
          key={component.id}
          element={component}
          zoom={viewState.zoom}
          handleClick={handleClickComponent}
        />
      );
    },
    [handleClickComponent, viewState.zoom]
  );

  const MarkersCluster = useMemo(
    () =>
      clusters.length > 0 &&
      clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        const clusterData = {
          id: cluster.id,
          location: {
            latitude,
            longitude,
          },
          pointCount,
          elementsCount: dataCompoents.length,
          type: "component",
        };
        if (isCluster)
          return (
            <MarkerCluster
              cluster={clusterData}
              onSelectPoint={onSelectPoint}
              setViewState={setViewState}
              viewState={viewState}
            />
          );
        const aux = pointColorsByMapSettings.find(
          (it) => it.id === cluster.properties.component.id
        );
        const component =
          aux !== undefined
            ? {
                ...cluster.properties.component,
                elementColor: aux.newColor,
              }
            : cluster.properties.component;
        return renderMarkerElement(component);
      }),
    [
      clusters,
      dataCompoents.length,
      onSelectPoint,
      setViewState,
      viewState,
      pointColorsByMapSettings,
      renderMarkerElement,
    ]
  );

  return MarkersCluster;
};

export default ComponentsClusters;
