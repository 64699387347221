import useSwr from "swr";
import { urls } from "utils/urlKeys";

const FetchComponents = (libraryId) => {
  const { data, error } = useSwr(
    urls.components.typeComponentsParams(libraryId ? `libraryId=${libraryId}` : "")
  );

  return { data, error };
};

export default FetchComponents;
