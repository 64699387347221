import axios from "axios";
import { config } from "config.js";

const updateBackendMapSettings = async ({ id, body }) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.patch(
      `${config.URL_BACKEND_MG}map-settings/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await response.data;

    return { data, response };
  } catch (error) {
    return { error };
  }
};

const createBackendMapSettings = async ({ body }) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${config.URL_BACKEND_MG}map-settings`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await response.data;
    return { data, response };
  } catch (error) {
    return { error };
  }
};

export { updateBackendMapSettings, createBackendMapSettings };
