const { writeFile, utils } = require("xlsx");

//const headers = [{ hola: "", como: "", estas: "" }];
const chars = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");

/**
 * Generates the Excel column header by its index
 *
 * @param {Integer} index
 * @returns {String} 1 → 'A' 2 → 'B' 26 → 'Z' 27 → 'AA'
 */
function numberToExcelHeader(index) {
  index -= 1;
  const quotient = Math.floor(index / 26);
  if (quotient > 0) {
    return numberToExcelHeader(quotient) + chars[index % 26];
  }
  return chars[index % 26];
}

/**
 * Function that generates an array of Excel headers by a number
 *
 * @param {Integer} num
 * @returns {<Array<String>>}[ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ', 'BA', 'BB' ...]
 */

function generateKeys(num) {
  if (!num || num <= 0) return [];
  return Array.from(Array(num).keys()).map((it) =>
    numberToExcelHeader(it + 1).concat("2")
  );
}


function saveFileValuesTable(arr = [], extension = "csv") {
  try {
    // Verificamos que tengamos datos
    if (!arr || !arr.length) {
      throw new Error("Array null or empty");
    }

    // Obtenemos los headers del primer objeto
    const headers = Object.keys(arr[0]);

    // Creamos las filas del CSV
    const csvRows = [
      // Primera fila: nombres de las columnas (headers)
      headers.join(","),
      // Segunda fila: tipos de datos de las columnas
      headers.map(header => arr[0][header]).join(","),
      // Tercera fila: comas dependiendo del número de columnas
      headers.map(() => "").join(","),
      // Resto de filas: convertimos cada objeto a una fila de CSV
      ...arr.slice(1).map((row) =>
        headers
          .map((header) => {
            // Escapamos las comas y las comillas dobles si existen en el valor
            const value = row[header]?.toString() || "";
            if (value.includes(",") || value.includes('"')) {
              return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
          })
          .join(",")
      ),
    ];

    // Unimos todas las filas con saltos de línea
    const csvString = csvRows.join("\n");

    // Agregamos BOM para mejor compatibilidad con Excel
    const BOM = "\uFEFF";
    const csvContentWithBOM = BOM + csvString;

    // Creamos el blob con el contenido
    const blob = new Blob([csvContentWithBOM], {
      type: "text/csv;charset=utf-8;",
    });

    // Creamos el link para descargar
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `File-${new Date().toISOString()}.${extension}`
    );
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error al generar el CSV:", error);
  }
}



export default saveFileValuesTable;
