import { useMemo, useState, useEffect } from "react";
// Hooks
import useSwr from "swr";
import { urls } from "utils/urlKeys";

const useFindComponents = () => {
  const [loading, setLoading] = useState(true);
  const { data, error } = useSwr(urls.components.all);

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  const [components] = useMemo(() => {
    return data && !error ? data : [];
  }, [data, error]);

  return { components, loading };
};

export default useFindComponents;
