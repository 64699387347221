import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { LoadingContainerDt } from "./LoadingStyle";
import DigitalTwinLogo from "images/DigitalTwinLogo.png";
import NeuronLoading from "images/NeuronLoading.png";
import neuron_DT from "images/neuron_DT.png";
import Layout from "components/Layout/AdminDecimetrix";
import { useColorHostName } from "hooks/colorHostName";

function shuffleArray(coordinates) {
  let currentIndex = coordinates.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [coordinates[currentIndex], coordinates[randomIndex]] = [
      coordinates[randomIndex],
      coordinates[currentIndex],
    ];
  }

  return coordinates;
}

export default function LoadingDecimetrix() {
  const coordinates = [
    { lng: 44.61182252685674, lat: 23.315462895476458, zoom: 8 },
    { lng: -94.91852293257102, lat: 29.394758585921494, zoom: 7 },
    { lng: -97.49035241155975, lat: 35.44305539020108, zoom: 8 },
    { lng: -73.66319586827852, lat: 4.039160353788461, zoom: 8 },
    { lng: 51.19502078774357, lat: 25.27249975471338, zoom: 7 },
    { lng: 57.14587788147239, lat: 21.298064510586585, zoom: 7 },
    { lng: -51.267317547642364, lat: -10.03215245693599, zoom: 7 },
    { lng: 54.36272001428486, lat: 24.42740374096668, zoom: 8 },
    { lng: 47.75391227841183, lat: 29.346488217143445, zoom: 8 },
    { lng: 55.31720839890545, lat: 25.25118333593136, zoom: 8 },
  ];

  let mixCoordinates = shuffleArray([...coordinates]);
  var cntCoor = 0;
  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/light-v10",
      center: [-73.68202696874948, 4.497450174329089],
      zoom: 0,
    });

    const interval = setInterval(() => {
      let testVar = mixCoordinates[cntCoor];
      map.flyTo({
        center: [testVar.lng, testVar.lat],
        duration: 7000,
        essential: true,
        zoom: testVar.zoom,
      });
      if (cntCoor < coordinates.length - 1) {
        cntCoor++;
      } else {
        cntCoor = 0;
      }
    }, 4000);

    return () => {
      clearInterval(interval);
      map.remove();
    };
  }, []);

  const { isRedDragon } = useColorHostName();

  return (
    <Layout>
      <LoadingContainerDt
        width={isRedDragon ? "44%" : "18%"}
        left={isRedDragon ? "29%" : "42%"}
        top={isRedDragon ? "5%" : "32%"}
      >
        <div
          id="map"
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: "-1",
            filter: "blur(5px)",
          }}
        />
        <div>
          <img
            src={isRedDragon ? neuron_DT : NeuronLoading}
            className="neuron"
            alt="Neuron Loading"
          />
          <img src={DigitalTwinLogo} className="logo" alt="Digital Twin Logo" />
          <p>Loading...</p>
        </div>
      </LoadingContainerDt>
    </Layout>
  );
}
