import { useState, useEffect } from "react";
// Hooks
import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import useFindObjects from "hooks/objects/useFindObjects";
// Helpers
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
// Components
import SelectLibrary from "./SelectLibrary";
import SelectColumnLibrary from "pages/Dashboard/components/SelectColumnLibrary";
import SelectTypeOperation from "pages/Dashboard/components/SelectTypeOperation";
import CustomButton from "components/Buttons/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";

// Const
import { defaultFieldsObjects } from "utils/defaultColumns";
// Services
import { createChart } from "services/Dashboard/groupDoughnutChart";
import { useSWRConfig } from "swr";
import { addChartInAllViews } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";
import SelectColumnLibraryWithValues from "./SelectColumnLibraryWithValues";
import ShowLinePreview from "helpers/showBarPreview";
import { DELETE_COLOR, HOVER_DELETE_COLOR, PRIMARY_COLOR } from "utils/const";
import generateRandomHexadecimal from "helpers/generateRandomHexadecimal";
import AddGroupDonutChart from "./AddGroupDonutChart";
import FetchTypeElements from "hooks/fetchTypeElements";
import { urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

const ObjectFormGroupDoughnut = ({ setOpenDialog }) => {
  const [columnsLibrary, setColumnsLibrary] = useState([]);
  const [columnsTypeNumber, setColumnsTypeNumber] = useState([]);
  const [loading, setLoading] = useState(false);
  const [librarySelectedId, setLibrarySelectedId] = useState(null);
  const [objectsFormat, setObjectsFormat] = useState([]);
  const [dataInputs, setDataInputs] = useState({
    alias: "",
    librarySelected: null,
    columnY: null,
    columnX: null,
    typeOperation: "count",
    category: null,
  });

  const [dataSeries, setDataSeries] = useState([]);
  const [typeElementNames, setTypeElementNames] = useState([]);
  const { instanceId } = useUserStore((state) => state);

  const updateDataInputs = (newState) => {
    setDataInputs((prevState) => ({ ...prevState, ...newState }));
  };

  const { data: libraries, error } = useFetchObjectLibraries({});

  const { data: typeElements, error: errorTypeElements } =
    FetchTypeElements(librarySelectedId);

  const { objects } = useFindObjects();
  const { mutate } = useSWRConfig();
  const setNewCreateChart = useStoreDashboard(
    (state) => state.setNewCreateChart
  );
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);

  const handlerSelectCategory = (column) => {
    updateDataInputs({ category: column });
  };

  const handlerTypeOperation = (e) => {
    updateDataInputs({ typeOperation: e });
  };
  const handlerClickColumnY = (column) => {
    updateDataInputs({ columnY: column });
  };
  const handlerClickColumnX = (column) => {
    updateDataInputs({ columnX: column });
  };

  const handlerAlias = (e) => {
    updateDataInputs({ alias: e.target.value });
  };

  const columsTypeNumber = (columns) => {
    const numberFields = columns?.filter(
      (field) =>
        field.type === "number" ||
        field.type === "operation" ||
        field.type === "range"
    );
    setColumnsTypeNumber(numberFields || []);
  };

  const handlerLibrarySelected = (e) => {
    updateDataInputs({ librarySelected: e });
    const library = libraries.find((library) => library.id === e.value);
    setLibrarySelectedId(library.id);
    const objectsByLibraryId = objects.filter(
      (object) => object.typeElement.pointLibraryId === e.value
    );
    const rows = createRowsObjects({ objects: objectsByLibraryId, library });
    setObjectsFormat(rows);
    updateDataInputs({ columnX: null, alias: "" });
  };

  useEffect(() => {
    // find library by library select from libraries
    if (libraries && dataInputs.librarySelected) {
      const library = libraries.find(
        (library) => library.id === dataInputs.librarySelected.value
      );

      const columnsLibrary = library?.fields;

      const columnsFiltered = columnsLibrary?.filter(
        (field) =>
          field.type !== "captureMedia" &&
          field.type !== "notification" &&
          field.type !== "number" &&
          field.type !== "operation" &&
          field.type !== "range"
      );
      if (columnsFiltered && columnsFiltered.length > 0) {
        setColumnsLibrary([...defaultFieldsObjects, ...columnsFiltered] || []);
      } else {
        setColumnsLibrary([...defaultFieldsObjects] || []);
      }
      columsTypeNumber(columnsLibrary || []);
    } else {
      setColumnsLibrary([]);
    }
  }, [libraries, dataInputs.librarySelected]);

  useEffect(() => {
    if (librarySelectedId && !errorTypeElements && typeElements?.length > 0) {
      const names = typeElements.map((elm) => {
        return elm.name;
      });
      setTypeElementNames(names);
    }
  }, [typeElements, errorTypeElements, librarySelectedId]);

  const handlerAddChart = async () => {
    const userId = localStorage.getItem("userId");
    const urlDonutChart = urlsApiMg.dashboard.doughnutChartGroup("objects");
    const urlOrders = urlsApiMg.dashboard.orderCards;
    const chart = {
      adminCompanyId: parseInt(localStorage.getItem("adminCompanyId")),
      type: dataInputs.typeOperation,
      from: "objects",
      libraryId: dataInputs.librarySelected.value,
      alias: dataInputs.alias,
      columnX: dataInputs.columnX,
      columnY: dataInputs.columnY,
      categories: dataInputs.category,
      datasets: dataSeries,
      instanceId: instanceId,
    };

    setLoading(true);
    const { data } = await createChart(chart);
    if (data) {
      mutate(urlDonutChart);
      await addChartInAllViews({
        userId,
        type: "objects",
        body: {
          newChart: {
            id: data._id,
          },
        },
      });
      mutate(urlOrders);
      setNewCreateChart(!newCreateChart);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  const handlerAddMainCategory = () => {
    const colors = dataInputs.columnX.values.map(() => {
      const colorRandom = generateRandomHexadecimal();
      return colorRandom;
    });
    setDataInputs((current) => ({
      ...current,
      columnX: {
        ...current.columnX,
        backgroundColor: colors,
        backgroundColorHexadecimal: colors,
      },
    }));
  };

  const handlerRemoveMainCategory = () => {
    setDataInputs((current) => ({
      ...current,
      columnX: null,
    }));
  };

  return (
    <>
      {!error && libraries && (
        <SelectLibrary
          libraries={libraries}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={dataInputs.librarySelected}
        />
      )}
      {dataInputs.librarySelected &&
        !dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
          <SelectColumnLibraryWithValues
            columnsLibrary={columnsLibrary}
            handlerClickColumn={handlerClickColumnX}
            columnSelected={dataInputs.columnX}
            data={objectsFormat}
            title={"Main Category"}
            typeElementNames={typeElementNames}
          />
        )}
      {dataInputs.librarySelected &&
        !dataInputs.columnX?.hasOwnProperty("backgroundColor") && (
          <>
            <br />
            <br />
            <CustomButton
              text={"Add Main Category"}
              onClick={handlerAddMainCategory}
              margin={0}
            />
          </>
        )}

      {dataInputs?.columnX?.backgroundColor?.length > 0 && (
        <>
          <h2 className="subtitle">Selected Main Category</h2>
          {dataInputs.columnX.values.map((elm, ind) => (
            <div key={ind} className="preview_data_time_series">
              <div className="item">
                <h3>{ind + 1}.</h3>
              </div>
              <div className="lineName">
                <h3>{elm}</h3>
              </div>
              <div className="linePreview">
                <ShowLinePreview
                  text={false}
                  width={1}
                  color={
                    dataInputs?.columnX?.backgroundColor[ind] || PRIMARY_COLOR
                  }
                />
              </div>
              <div className="delete">
                <DeleteIcon
                  sx={{
                    fontSize: "20px",
                    color: DELETE_COLOR,
                    "&:hover": {
                      cursor: "pointer",
                      color: HOVER_DELETE_COLOR,
                    },
                  }}
                  onClick={() =>
                    setDataInputs((current) => ({
                      ...current,
                      columnX: {
                        ...current.columnX,
                        values: current.columnX.values.filter(
                          (item) => item !== elm
                        ),
                        backgroundColor: current.columnX.backgroundColor.filter(
                          (item, index) => index !== ind
                        ),
                        backgroundColorHexadecimal:
                          current.columnX.backgroundColorHexadecimal.filter(
                            (item, index) => index !== ind
                          ),
                      },
                    }))
                  }
                />
              </div>
            </div>
          ))}

          {dataInputs.librarySelected &&
            dataInputs?.columnX?.values?.length > 0 && (
              <>
                <br />
                <br />
                <CustomButton
                  text={"Remove Main Category"}
                  onClick={handlerRemoveMainCategory}
                  primaryColor={DELETE_COLOR}
                  secondaryColor={HOVER_DELETE_COLOR}
                  margin={0}
                />
              </>
            )}
        </>
      )}

      {dataInputs.librarySelected &&
        dataInputs?.columnX?.values?.length > 0 &&
        dataInputs?.columnX?.backgroundColor?.length > 0 && (
          <AddGroupDonutChart
            dataInputs={dataInputs}
            columnsLibrary={columnsLibrary}
            setDataSeries={setDataSeries}
            dataSeries={dataSeries}
            handlerSelectCategory={handlerSelectCategory}
            data={objectsFormat}
            typeElementNames={typeElementNames}
          />
        )}

      {dataInputs.librarySelected && dataSeries.length > 0 && (
        <SelectTypeOperation
          handlerSelectTypeOperation={handlerTypeOperation}
        />
      )}

      {dataInputs.librarySelected &&
        columnsLibrary &&
        dataSeries.length > 0 &&
        dataInputs.typeOperation === "sum" && (
          <SelectColumnLibrary
            title={"Select Variable for Operation"}
            columnsLibrary={columnsTypeNumber}
            handlerClickColumn={handlerClickColumnY}
            columnSelected={dataInputs.columnY}
          />
        )}

      {dataInputs.librarySelected && dataSeries.length > 0 && (
        <section className="container-type-charts ">
          <div>
            <h2 className="subtitle">Title</h2>
            <input
              className="alias"
              type="text"
              value={dataInputs.alias}
              onChange={(e) => {
                handlerAlias(e);
              }}
              required={true}
            />
          </div>
        </section>
      )}

      {dataInputs.alias !== "" && dataInputs.alias !== " " && (
        <CustomButton
          className="btn-add-chart"
          onClick={() => handlerAddChart()}
          isLoad={loading}
          text={"Add chart"}
          margin={"15px 0"}
        />
      )}
    </>
  );
};

export default ObjectFormGroupDoughnut;
