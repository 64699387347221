import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "utils/const.jsx";

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-height: calc(100vh - 7rem);
  height: auto;
  padding: 5rem 0;
`;

export const Title = styled("h1")`
  font-size: 2.5rem;
  color: ${PRIMARY_COLOR};
`;

export const ContainerTables = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;
`;
