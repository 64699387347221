import Grid from "@material-ui/core/Grid";
// Redux
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
// Components
import GenericTable from "components/Tables/GenericTable/GenericTable";
import EventDetails from "components/ElementDetails/EventDetails/EventDetails";
import { WHITE_COLOR } from "utils/const";
import { FROM_TABLE } from "utils/constStrings";
import { useContext } from "react";
import { ThemeContext } from "App";

const columns = [
  "Id",
  "Mark Event",
  "Name",
  "Date",
  "Longitude",
  "Latitude",
  "Created At",
];

const idColumns = [
  "id",
  "markPoint",
  "name",
  "date",
  "longitude",
  "latitude",
  "createdAt",
];

const EventHistory = ({ eventRows, seePointEventsTable, detailId }) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const handlerClickId = (eventId) => {
    const operation = {
      content: <EventDetails eventId={eventId} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
    dispatch(setShowOperationDetails(true));
  };
  const filteredEventRows = eventRows.filter((event) => event.isGeographic);

  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      xl={12}
      style={{
        display: seePointEventsTable ? "" : "none",
      }}
    >
      <div className={`table-container ${theme === "light" ? "bb" : "bb2"}`}>
        <>
          <div className="operation-title">
            <h1
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                fontSize: "2rem",
                textAlign: "center",
                color:
                  theme === "light"
                    ? "var(--background-primary_color)"
                    : WHITE_COLOR,
              }}
            >
              <span>EVENT HISTORY</span>
            </h1>
          </div>
          <div className="operation-table">
            <div
              className={` ${theme === "light" ? "bb" : "bb3"}`}
              style={{
                width: "100%",
                padding: "1rem",
                borderRadius: "5px",
              }}
            >
              <GenericTable
                headers={columns}
                rows={filteredEventRows}
                keyValues={idColumns}
                handlerClick={handlerClickId}
                detailId={detailId}
                state={true}
                customTableWidth={"100%"}
                customHeaderWidth={"5%"}
                from={FROM_TABLE.details}
                nonLocation={true}
              />
            </div>
          </div>
        </>
      </div>
    </Grid>
  );
};

export default EventHistory;
