import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// Custom hooks
import CustomButton from "components/Buttons/CustomButton";
import GetLinesArrays from "./hooks/getLibraryArrays.jsx";
// styles
import {
  FeatureDetails,
  LineContainerForm,
  WraperTitleCardMarker,
  Form,
  LineConnectionsContainer,
} from "./LineRelationsStyles.jsx";
import BlockIcon from "@mui/icons-material/Block";
import { setGeometryLineConfig } from "redux/actions/admin.jsx";
import useViewFilterClusterMap from "hooks/useViewFilterClusterMap.jsx";
import findTypeLinesTypeObjects from "services/typeLinesTypeObjects/find.js";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";

const LineRelations = ({ setOpendDialog, handleSetLineRelations }) => {
  // Local storage
  const userId = localStorage.getItem("userId");
  // states
  const [dialogNotTypeLinesTypeObjects, setDialogNotTypeLinesTypeObjects] =
    useState(false);
  const [libraryId, setLibraryId] = useState("");
  const [lineTypeId, setLineTypeId] = useState(0);
  const [lineConfig, setLineConfig] = useState({});
  const [linePreview, setLinePreview] = useState({
    color: "#000",
    width: 1,
    separator: 1,
  });
  const [libraryLineImageUrl, setLibraryLineImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  // Custom hooks
  const {
    linesLibrariesArray: lineLibraries,
    errorLineLibraries,
    linesTypeArray: lineTypes,
    errorLineType,
  } = GetLinesArrays({ libraryId });
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();

  const { handleFilterPointsCreateLine } = useViewFilterClusterMap({
    lineTypeId,
  });

  //set library id and get the first image to display
  useEffect(() => {
    lineLibraries !== undefined &&
      lineLibraries.length > 0 &&
      !errorLineLibraries &&
      setLibraryId(parseInt(lineLibraries?.[0].id));
    lineLibraries !== undefined &&
      lineLibraries.length > 0 &&
      !errorLineLibraries &&
      setLibraryLineImageUrl(lineLibraries?.[0].icon);
  }, [lineLibraries, errorLineLibraries]);

  // set line type id and properties of the line preview
  useEffect(() => {
    lineTypes && !errorLineType && setLineTypeId(lineTypes[0]?.id);
    lineTypes &&
      !errorLineType &&
      setLinePreview({
        color: lineTypes[0]?.color,
        width: lineTypes[0]?.dasharrayWidth,
        separator: lineTypes[0]?.dasharrayPixels,
      });
  }, [lineTypes, errorLineType]);

  // listen to library id changes to chose library image
  useEffect(() => {
    lineLibraries &&
      !errorLineLibraries &&
      setLibraryLineImageUrl(
        lineLibraries?.find((it) => it.id === libraryId)?.icon
      );
  }, [libraryId, lineLibraries, errorLineLibraries]);

  // Methods
  const handleLibraryId = (e) => {
    const id = parseInt(e.target.value);
    setLibraryId(id);
    setLineTypeId(lineTypes?.[0]?.id);
    const lib = lineLibraries?.find((it) => it.id === id);
    if (lib) {
      setLinePreview({
        color: lib?.color,
        width: lib?.dasharrayWidth,
        separator: lib?.dasharrayPixels,
      });
    }
  };

  const handlerLineType = useCallback(
    (e) => {
      const lineTypePreview = lineTypes.find(
        (item) => item.id === parseInt(e.target.value)
      );
      setLineTypeId(e.target.value);
      setLinePreview({
        color: lineTypePreview?.color,
        width: lineTypePreview?.dasharrayWidth,
        separator: lineTypePreview?.dasharrayPixels,
      });
    },
    [lineTypes]
  );

  //set the generic data for the form in the dynamicFields form
  useEffect(() => {
    const setLineLibraryAndType = () => {
      const line = {
        libraryId: parseInt(libraryId),
        lineTypeId: parseInt(lineTypeId),
      };
      setLineConfig({ ...line });
    };
    lineTypeId && libraryId && setLineLibraryAndType();
  }, [lineTypeId, userId, libraryId]);

  const onSubmit = async () => {
    const typeElementAllow = await findTypeLinesTypeObjects({
      typeLineId: lineTypeId,
    });
    if (typeElementAllow.length === 0) {
      setDialogNotTypeLinesTypeObjects(true);
    } else {
      setLoading(true);
      dispatch(setGeometryLineConfig(lineConfig));
      handleFilterPointsCreateLine();
      handleSetLineRelations();
      setLoading(false);
      setOpendDialog && setOpendDialog(false);
    }
  };

  return (
    <FeatureDetails>
      <OpenDialog
        openDialog={dialogNotTypeLinesTypeObjects}
        setOpenDialog={setDialogNotTypeLinesTypeObjects}
        content={{
          title: "Line creation error",
          description: (
            <div>
              <h2>No existing relations</h2>
              <span>
                For connecting lines to objects there must exist a relationship
                beetwen the line type and objects.
              </span>
              <br />
              <span>
                this can be done in 'Configuration' &gt; 'Objects' &gt; 'type
                object=Lines'{" "}
                <a href="/object-configurations">object-configurations</a>
              </span>
            </div>
          ),
        }}
        maxWidth="800px"
        minWidth="200px"
      />
      <WraperTitleCardMarker>
        <div className="wraper-title-element">
          {libraryLineImageUrl ? (
            <img
              width="50"
              src={libraryLineImageUrl}
              alt="object-agile-icon"
            ></img>
          ) : (
            <BlockIcon />
          )}
          <div>
            <Line
              color={linePreview.color}
              width={linePreview.width}
              separator={linePreview.separator}
            />
          </div>
        </div>
      </WraperTitleCardMarker>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="select-library" className="location-details">
            Line Library
          </label>
          <select
            id="select-library"
            defaultValue={libraryId}
            onChange={handleLibraryId}
            className="custom-select"
          >
            {lineLibraries &&
              !errorLineLibraries &&
              lineLibraries.map((library) => (
                <option key={library.id} value={library.id}>
                  {library.name}
                </option>
              ))}
          </select>
          <label htmlFor="select-element" className="location-details">
            Line Type
          </label>
          <div>
            <select
              id="select-element"
              defaultValue={lineTypeId}
              onChange={handlerLineType}
              className="custom-select"
            >
              {lineTypes &&
                !errorLineType &&
                lineTypes?.map((element) => {
                  return (
                    <option key={element.id} value={element.id}>
                      {element.type}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <CustomButton
          type={"submit"}
          isLoad={loading}
          text={"Save"}
          margin={0}
        />
      </Form>
      {lineTypeId && <ShowPossibleLineConnections id={lineTypeId} />}
    </FeatureDetails>
  );
};

// To show current line aparence
const Line = (props) => {
  const { color, width, separator } = props;

  return (
    <LineContainerForm separator={separator} width={width} color={color}>
      <span className="text-line-preview">Line preview:</span>
      <div className="container">
        <span className="circle"></span>
        <span className="line"></span>
        <span className="separator"></span>
        <span className="line"></span>
        <span className="separator"></span>
        <span className="line"></span>
        <span className="circle"></span>
      </div>
    </LineContainerForm>
  );
};

const ShowPossibleLineConnections = ({ id }) => {
  const [types, setTypes] = useState();
  useLayoutEffect(() => {
    const getTypesLinesTypesObjects = async () => {
      const typeElementAllow = await findTypeLinesTypeObjects({
        typeLineId: id,
      });
      setTypes(typeElementAllow);
    };
    id && getTypesLinesTypesObjects();
  }, [id]);
  return (
    <LineConnectionsContainer>
      <div className="container-list-header">
        <h4>Possible object types for connection:</h4>
      </div>
      <div className="container-list">
        {types?.length &&
          types.map((tp) => {
            return (
              <div className="container-label">
                <label className="location-details">
                  {tp.typeElement.name}
                </label>
                <div
                  className="container-img"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    alt=""
                    src={tp.typeElement.urlIconColor}
                    style={{ marginRight: "20px" }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </LineConnectionsContainer>
  );
};

export default LineRelations;
