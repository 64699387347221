import useSupercluster from "use-supercluster";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OperationDetails from "components/ElementDetails/OperationDetails/OperationDetails";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import {
  setFilterEvents,
  setFilteredOperations,
  setOperations,
} from "redux/actions/digitalTwin";

import EventDetails from "components/ElementDetails/EventDetails/EventDetails";
import { setDataEventPolygon } from "redux/actions/admin";

import MarkerElement from "components/Clusters/MarkerElement";
import MarkerCluster from "components/Clusters/MarkerCluster";
import { useStore } from "react-redux";
import getColorFromField from "helpers/getColorFromField";
import { getNumericValue } from "helpers/getNumericValue";
import { EVENT_CLUSTER_COLOR } from "utils/const";

/**
 * Renders the map component with operation clusters and events.
 *
 * @param {Object} operations - an array of operations
 * @param {Object} events - an array of events
 * @param {Object} bounds - the boundaries of the map
 * @param {Function} onSelectPoint - callback function for selecting a point
 * @param {Object} viewState - the current view state of the map
 * @param {Function} setViewState - callback function for setting the view state of the map
 * @return {JSX.Element} the rendered map component
 */
const OperationsClusters = ({
  events,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const state = store.getState();
  const dataFilteredOperations = state.digitalTwinReducer.filteredOperations;

  const [pointColorsByMapSettings, setPointColorsByMapSettings] = useState([]);

  const showEventByInspectionType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByInspectionType
  );
  const operationClusters = useSelector(
    (state) => state.digitalTwinReducer.operationClusters
  );

  const dataEventFilterTable = useSelector(
    (state) => state.adminReducer.dataEventFilterTable
  );

  const showEventsByType = useSelector(
    (state) => state.digitalTwinReducer.showEventsByType
  );

  const stateFilterEvents = useSelector(
    (state) => state.digitalTwinReducer.stateFilterEvents
  );

  const mapSettingsState = useSelector(
    (state) => state.digitalTwinReducer.mapSettings
  );

  const applyFiltersInGenericTable = useSelector(
    (state) => state.digitalTwinReducer.applyFiltersInGenericTable
  );

  useEffect(() => {
    dispatch(setFilterEvents(false));
  }, [stateFilterEvents, dispatch]);

  useEffect(() => {
    let aux = [];
    let value = 0;
    const typeColor = EVENT_CLUSTER_COLOR;

    if (
      mapSettingsState !== undefined &&
      Object.keys(mapSettingsState).length > 0
    ) {
      let newColor = "";
      let optionSelected = null;
      let field = null;
      const { eventsColorsSelected, fieldsByLibraryEvents, userConfigColors } =
        mapSettingsState;
      const colorsEventsUserConfig = userConfigColors.colorEvents;
      if (
        eventsColorsSelected === undefined ||
        colorsEventsUserConfig === undefined
      ) {
        setPointColorsByMapSettings(aux);
        return;
      }
      aux = events.map((event) => {
        const { attributes, id, pointTypeEvent } = event;
        const { pointLibraryEventId } = pointTypeEvent;
        const library = fieldsByLibraryEvents?.filter(
          (it) => it.id === pointLibraryEventId
        )?.[0];

        // configuracion de usuario
        if (userConfigColors.checkedPoints) {
          // Evento con todas las poropiedades del esquema de field
          optionSelected = colorsEventsUserConfig
            ?.filter(
              (it) => it.id === pointLibraryEventId // formato {id, name, fields: []}
            )?.[0]
            ?.fields?.filter((it) => it.state)?.[0]; // formato es un campo dinamico
          if (optionSelected !== undefined) {
            // field del punto evento, tiene el valor buscado
            field = attributes?.filter(
              (atr) => atr.name === optionSelected.name
            )?.[0];
            if (optionSelected?.type === "select") {
              const colorIndex = optionSelected?.options?.indexOf(field?.value);
              newColor = getColorFromField({
                field: optionSelected,
                colorIndex,
              }); // #000000
            }
            if (optionSelected?.type === "number") {
              // valor del campo numerico seleccionado en el map settings
              const value = getNumericValue(field?.value); // 20 ... 100  ... NaN
              newColor = getColorFromField({
                field: optionSelected,
                value,
                typeColor: typeColor,
              }); // #000000
            }
          }
        }
        // configuracion por defecto
        if (!userConfigColors.checkedPoints) {
          optionSelected = eventsColorsSelected.filter(
            (it) => it[0] === pointLibraryEventId
          )?.[0]; // tiene esta estructura [ 1, "pointType", "event" ]

          const libraryField = library?.fields?.filter(
            (it) => it.name === optionSelected?.[1]
          )?.[0]; // contiene la estructura definida en Fields.jsx
          field = attributes?.filter(
            (it) => it.name === optionSelected?.[1]
          )?.[0];
          if (libraryField === undefined) {
            newColor = typeColor;
          }
          if (libraryField !== undefined && libraryField?.type === "select") {
            const colorIndex = libraryField?.options?.indexOf(field?.value);
            newColor = getColorFromField({ field: libraryField, colorIndex }); // #000000
          }
          if (libraryField !== undefined && libraryField?.type === "number") {
            // valor del campo numerico seleccionado en el map settings
            value = getNumericValue(field?.value); // 20 ... 100  ... NaN
            newColor = getColorFromField({
              field: libraryField,
              value,
              typeColor,
            }); // #000000
          }
        }

        return {
          id,
          pointLibraryEventId,
          optionSelected,
          field,
          value,
          newColor,
        };
      });
    }
    setPointColorsByMapSettings(aux);
  }, [events, mapSettingsState]);

  const filterEvents = showEventsByType?.filter((elm) => {
    return elm.state === true;
  });

  const allEventsTypes = events?.filter((event) => {
    return filterEvents?.some((eventFilter) => {
      return (
        event.pointTypeEvent.id === eventFilter.id &&
        event.pointTypeEvent.pointLibraryEventId === eventFilter.libraryId &&
        eventFilter.state === true
      );
    });
  });

  let idInspectionTypes = [];
  for (let key in showEventByInspectionType) {
    if (showEventByInspectionType[key] === true) {
      idInspectionTypes.push(parseInt(key));
    }
  }

  const eventToPoint = (event, type) => ({
    type: "Feature",
    properties: {
      cluster: false,
      event: {
        ...event,
        id: event.id,
        type: type,
        icon:
          type === "operation"
            ? event.typeOperation.urlIcon
            : event.pointTypeEvent.icon,
        location: {
          latitude: event.locationOperation.latitude,
          longitude: event.locationOperation.longitude,
        },
        inspectionFlir: type === "operation" ? event.inspectionFlir : undefined,
        label: event?.label,
      },
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(event.locationOperation.longitude),
        parseFloat(event.locationOperation.latitude),
      ],
    },
  });

  const filteredEvents = allEventsTypes
    ?.map((event) => {
      if (event.isGeographic) {
        return eventToPoint(event, "event");
      }
      return null;
    })
    .filter((it) => it !== null);

  const eventsPoints = events
    .map((event) => {
      if (event.isGeographic) {
        return eventToPoint(event, "event");
      }
      return null;
    })
    .filter((it) => it !== null);

  useEffect(() => {
    dispatch(setOperations(eventsPoints));
    if (applyFiltersInGenericTable) {
      dispatch(setDataEventPolygon(allEventsTypes));
    } else {
      dispatch(setDataEventPolygon(events));
    }
  }, [
    dispatch,
    eventsPoints,
    allEventsTypes,
    events,
    applyFiltersInGenericTable,
  ]);

  useEffect(() => {
    if (dataFilteredOperations.length !== filteredEvents.length) {
      dispatch(setFilteredOperations(filteredEvents));
    }
  }, [dispatch, filteredEvents]);

  const pointEvents = useMemo(() => [...filteredEvents], [filteredEvents]);

  const { clusters } = useSupercluster({
    points:
      dataEventFilterTable.length !== 0 ? dataEventFilterTable : pointEvents,
    bounds,
    zoom: viewState.zoom,
    options: {
      radius: operationClusters.radius || 0,
      // maxZoom: operationClusters.maxZoom || 0,
      maxZoom: operationClusters.maxZoom || 0,
    },
  });

  const handleClickEvent = useCallback(
    (eventId) => {
      const event = {
        content: <EventDetails eventId={eventId} />,
        title: "Event Details",
      };
      dispatch(setOperationDetails(event));
      dispatch(setShowOperationDetails(true));
    },
    [dispatch]
  );

  const renderMarkerOperation = useMemo(
    () => (event) => {
      return (
        <MarkerElement
          zoom={viewState.zoom}
          element={event}
          handleClick={() => handleClickEvent(event.id)}
        />
      );
    },
    [handleClickEvent, viewState.zoom]
  );

  const MarkersCluster = useMemo(
    () =>
      clusters.length > 0 &&
      clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        const dataCluster = {
          id: cluster.id,
          location: {
            latitude,
            longitude,
          },
          pointCount,
          elementsCount: pointEvents.length,
          type: "operation",
        };
        if (isCluster) {
          return (
            <MarkerCluster
              cluster={dataCluster}
              onSelectPoint={onSelectPoint}
              setViewState={setViewState}
              viewState={viewState}
            />
          );
        }
        const aux = pointColorsByMapSettings.find(
          (it) => it.id === cluster.properties.event.id
        );
        const event =
          aux !== undefined
            ? {
                ...cluster.properties.event,
                elementColor: aux.newColor,
              }
            : cluster.properties.event;
        return renderMarkerOperation(event);
      }),
    [
      clusters,
      pointEvents.length,
      pointColorsByMapSettings,
      renderMarkerOperation,
      onSelectPoint,
      setViewState,
      viewState,
    ]
  );

  return <>{MarkersCluster}</>;
};

export default OperationsClusters;
