export const keyValuesEvents = [
  "id",
  "flyTo",
  "markPoint",
  "report",
  "typeOfEvent",
  "relationId",
  "relationTypeName",
  "totalEvents",
  "dateUTC",
  "localDate",
  "user",
  "latitude",
  "longitude",
  "georeferenced",
];

export const headersEvents = [
  "UNIC ID",
  "Fly To",
  "Mark Event",
  "Report",
  "Type of Event",
  "Relation Id",
  "Relation Type Name",
  "Total Events",
  "Date UTC-0",
  "Local date",
  "User",
  "Latitude",
  "Longitude",
  "Georeferenced"
];

export const keyValuesComponents = [
  "id",
  "flyTo",
  "markPoint",
  "typeOfComponent",
  "objectId",
  "typeOfElement",
  "totalEvents",
  "dateUTC",
  "localDate",
  "user",
  "latitude",
  "longitude",
];

export const headersComponents = [
  "UNIC ID",
  "Fly To",
  "Mark Event",
  "Type of Component",
  "Object ID",
  "Type of Element",
  "Total Events",
  "Date UTC-0",
  "Local date",
  "User",
  "Latitude",
  "Longitude",
];

export const keyValuesObjects = [
  "id",
  "flyTo",
  "markPoint",
  "typeElementId",
  "typeElementName",
  "totalComponents",
  "totalEvents",
  "dateUTC",
  "localDate",
  "userName",
  "latitude",
  "longitude",
];

export const headersObjects = [
  "UNIC ID",
  "Fly To",
  "Mark Object",
  "Element ID",
  "Type of Element",
  "Total Components",
  "Total Events",
  "Date UTC-0",
  "Local Date",
  "User",
  "Latitude",
  "Longitude",
];

export const keyValuesLines = [
  "id",
  "flyTo",
  "markPoint",
  "typeLineName",
  "typeLineId",
  "dateUTC",
  "localDate",
  "userName",
  "initialLatitude",
  "initialLongitude",
  "finalLatitude",
  "finalLongitude",
  "distance",
  "objectIdInitial",
  "objectIdFinal",
];

export const headersLines = [
  "UNIC ID",
  "Fly To",
  "Mark Object",
  "Type of Line",
  "Line ID",
  "Date UTC-0",
  "Local Date",
  "User",
  "Initial Latitude",
  "Initial Longitude",
  "Final Latitude",
  "Final Longitude",
  "Distance",
  "Object Id Initial",
  "Object Id Final",
];
