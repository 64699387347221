// Required modules
import NavBar from "components/Admin/NavBar/NavBar";
import GenericTable from "components/Tables/GenericTable/GenericTable.jsx";
import Form from "components/Forms/Form.jsx";

// Config admin class
import ConfigAdmin from "./ConfigAdmin.js";

// Hooks
import { useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableLibraryPolygon,
  keyValuesTableLibraryPolygon,
  initialValuesFormLibraryPolygon,
} from "./dataConfigAdmin";

// Styles
import {
  ConfigAdminContainer,
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  InformationShare,
  IconAdminConfigChangeColum,
} from "./configAdminStyles";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { ContainerForm } from "components/Forms/FormStyles";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import { FROM_TABLE } from "utils/constStrings.jsx";

// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <IoIosArrowDropupCircle className="icon" />
    </IconAdminConfigChangeColum>
  );
};

export default function LibraryRegion() {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);

  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });

  const executeDialog = () => {
    setOpenDialog(() => false);
  };

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormLibraryPolygon);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [libreriesRows, setLibreriesRows] = useState([]);

  // Click  on type element ghg into table
  const handleClickLibrary = (id) => {
    configAdmin.handlerClickIntoTable(id);
  };

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableLibraryPolygon);
    configAdmin.setKeyValuesTable(keyValuesTableLibraryPolygon);
    configAdmin.setSetRows(setLibreriesRows);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin?.setEndpoint(`api/v1/region-libraries`);
    configAdmin?.fetchData();
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, []);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  return (
    <div>
      {/* <NavBar linkLogo={"admin"} /> */}
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={executeDialog}
        content={contentDialog}
      />
      <ConfigAdminContainer className="background-2 border-top-1">
        <RowAdminConfig>
          <ColumnAdminConfig style={{ gridColumn: "1" }}>
            {configAdmin && (
              <GenericTable
                headers={configAdmin.getHeadersTable()}
                rows={libreriesRows}
                keyValues={configAdmin.getKeyValuesTable()}
                handlerClick={handleClickLibrary}
                style={{
                  width: 100,
                }}
                state={true}
                configBar={true}
                from={FROM_TABLE.config}
              />
            )}
          </ColumnAdminConfig>
          <ColumnAdminConfig style={{ gridColumn: "2" }}>
            <ContainerForm
              see={seeForm}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Form
                form={form}
                handleChange={handleChangeForm}
                handleRows={handleClickLibrary}
                buttons={buttonsForm}
                renderBeforeTitle={[
                  <ButtonSeeInitalView
                    click={() => configAdmin.toggleForm()}
                  />,
                ]}
                title={"Create a new Digital Twin Library"}
              />
            </ContainerForm>

            {!seeForm && (
              <>
                <CreateNewRegionButton className="background-row-2 color1 border-color-1">
                  Create a new Digital Twin Library
                  <button onClick={() => configAdmin.toggleForm()}>
                    <span>
                      <IoMdAdd />
                    </span>
                    New Type Library
                  </button>
                </CreateNewRegionButton>
                <InformationShare className="background-row-2 border-color-1">
                  <div className="head color1">
                    Edit Existing Digital Twin Library
                  </div>
                  <div className="body color1">
                    To Edit Click on Existing Digital Twin Library ID
                  </div>
                </InformationShare>
              </>
            )}
          </ColumnAdminConfig>
        </RowAdminConfig>
      </ConfigAdminContainer>
    </div>
  );
}
