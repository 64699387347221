import { styled } from "@mui/material/styles";
import { BLACK_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../utils/const";

export const HeadModal = styled("modal")`
  background: ${({ isWarning }) =>
    isWarning ? WARNING_COLOR : "var(--background-primary_color)"};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .neurona {
    width: 34px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: ${({ isWarning }) => (isWarning ? BLACK_COLOR : WHITE_COLOR)};
    margin-right: 15px;
    margin-left: 15px;
  }

  .title {
    display: flex;
    align-items: self-end;
    justify-content: center;

    padding: 5px;
  }

  @media only screen and (min-width: 1920px) {
    //styles for desktop
    height: auto;
    position: relative;
    top: 0;
    left: 0;
  }

  @media only screen and (max-width: 600px) {
    //styles for mobile
    height: auto;
    position: relative;
    top: 0;
    left: 0;
  }
`;
