// Styles
import { useState } from "react";
import { useSelectAllFieldsColumn } from "../Hooks/useSelectAllFieldsColumn";
import { ContainerColumnSelect, selectStyle } from "./style";
import Select from "react-select";

// Constants
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";

const SelectColumnLibraryWithValues = ({
  columnsLibrary,
  handlerClickColumn,
  columnSelected,
  typeElementNames,
  title = "",
  data,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { handlerSelectedColumn, handleSelectChange, allOptions } =
    useSelectAllFieldsColumn({
      data,
      typeElementNames,
      columnsLibrary,
      setSelectedOptions,
      columnSelected: columnSelected,
      handlerSelectColumn: handlerClickColumn,
    });

  return (
    <>
      <h2 className="subtitle">{title}</h2>
      <ContainerColumnSelect>
        {columnsLibrary.map((columnLibrary) => {
          if (
            columnLibrary.name !== "localDate" &&
            columnLibrary.type !== "date"
          ) {
            return (
              <>
                <div
                  key={columnLibrary._id}
                  className="card-type-chart"
                  onClick={() => handlerSelectedColumn(columnLibrary.name)}
                  style={{
                    backgroundColor:
                      columnSelected?.name === columnLibrary.name
                        ? ICON_GREEN_COLOR
                        : WHITE_COLOR,
                  }}
                >
                  <p>{columnLibrary.alias || columnLibrary.name} </p>
                </div>
                {columnSelected?.name === columnLibrary.name && (
                  <Select
                    options={allOptions}
                    value={selectedOptions}
                    isMulti
                    onChange={handleSelectChange}
                    styles={selectStyle}
                  />
                )}
              </>
            );
          }
        })}
      </ContainerColumnSelect>
    </>
  );
};

export default SelectColumnLibraryWithValues;
