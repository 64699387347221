// React
import { useCallback, useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setFlyTo, setRowNum } from "redux/actions/digitalTwin";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
// Components
import GenericTable from "components/Tables/GenericTable/GenericTable";
import LineDetails from "components/ElementDetails/LineDetails/Index";
// Dependecies
import moment from "moment-timezone";
// Hooks
import { useGetLineLibraries } from "components/Forms/SaveLines/hooks/useGet";
// Styles
import { SearchPosition } from "./styles";
import { keyValuesLines, headersLines } from "./keyAndHeadersTables";
// Const
import { FROM_TABLE } from "utils/constStrings";
import handleExtendView from "components/Tables/GenericTable/helpers/handleExtendView";

import { fixDecimals } from "helpers/fixDecimals";
import { calculateLengthFromDistance } from "components/DigitalTwin/Lines/LinesServices";

export default function TableLineObjects({
  lineLibraryId,
  setRows: setRowsMain,
  attributte,
  setLabels,
  label,
  setTableVisibility,
  tableVisibility,
  handleClose,
  typeOfTable,
  typeOfLibrary,
  handleDrawerClose,
  handleDrawerOpen,
  state = false,
  orderColumns,
  dataChecked,
  setDataChecked,
}) {
  const [rows, setRows] = useState([]);

  const dispatch = useDispatch();
  const dataLines = useSelector((state) => state.digitalTwinReducer.dataLines);
  const map = useSelector((state) => state.digitalTwinReducer.map);
  const flyToRedux = useSelector((state) => state.digitalTwinReducer.flyTo);
  const rowNumber = useSelector((state) => state.digitalTwinReducer.rowNum);
  const mapSettings = useSelector(
    (state) => state.digitalTwinReducer.mapSettings
  );
  const { data: dataLibrary, error: errorLibrary } = useGetLineLibraries({
    id: lineLibraryId,
  });

  useEffect(() => {
    setRows([]);
  }, [lineLibraryId]);

  const handlerClickTable = (id) => {
    const line = {
      content: (
        <LineDetails
          lineId={id}
          deletedLine={() => {}}
          applyFullWidth={false}
        />
      ),
      title: "Line Details in table",
    };
    dispatch(setOperationDetails(line));
    dispatch(setShowOperationDetails(true));
  };

  const removeLineBreak = (text) => {
    if (typeof text === "string") return text.replace(/(\r\n|\n|\r)/gm, " ");
    if (text && isNaN(text) && typeof text !== "object") {
      return text.toString().replace(/(\r\n|\n|\r)/gm, " ");
    }
    return text;
  };

  const markPoint = useCallback(
    (id, lat, lng) => {
      if (state) {
        const data = {
          id,
          lat,
          lng,
        };
        const dataMarketPoint = JSON.stringify(data);
        localStorage.setItem("dataMarketPoint", dataMarketPoint);
        setTimeout(() => {
          const setData = {
            id: "",
            lat: "",
            lng: "",
          };
          const setDataMarketPoint = JSON.stringify(setData);
          localStorage.setItem("dataMarketPoint", setDataMarketPoint);
        }, 7000);
      } else {
        dispatch(
          setFlyTo({
            ...flyToRedux,
            object: {
              id,
              latitude: parseFloat(lat),
              longitude: parseFloat(lng),
            },
          })
        );
        setTableVisibility("none");
        setTimeout(() => {
          dispatch(
            setFlyTo({
              ...flyToRedux,
              object: {
                id: "",
                latitude: "",
                longitude: "",
              },
            })
          );
        }, 3000);
      }
    },
    [dispatch, flyToRedux, state, setTableVisibility]
  );

  const createRows = useCallback(
    (data) => {
      const newDataArray = data?.map((dataItem) => {
        let attributesCopy = [...dataItem.attributes];

        const res = attributesCopy.map((newData) => {
          const elm = dataLibrary?.[0]?.fields?.find((item) => {
            return (
              item?.name === newData?.name ||
              item?.alias === newData?.name ||
              item?.name === newData?.alias ||
              item?.alias === newData?.alias
            );
          });

          if (elm) {
            if (elm.type !== "captureMedia" && elm.type !== "notification") {
              if (elm.type === "number" || elm.type === "operation") {
                if (newData.value && newData.value[0] === "{") {
                  const valueParse = JSON.parse(newData.value);
                  if (valueParse.value === "") {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: NaN,
                      _id: newData._id,
                    };
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: valueParse.value,
                      _id: newData._id,
                    };
                  }
                } else {
                  if (newData.value && !isNaN(newData.value)) {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: parseFloat(newData.value),
                      _id: newData._id,
                    };
                  }
                }
              } else {
                if (elm.type === "date" && !newData.value === !NaN) {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: "0000-00-00",
                    _id: newData._id,
                  };
                } else {
                  if (!newData.value === !NaN || newData.value === " ") {
                    if (elm.type === "date") {
                      return {
                        name: elm.name,
                        alias: elm.alias,
                        value: "0000-00-00",
                        _id: newData._id,
                      };
                    } else {
                      return {
                        name: elm.name,
                        alias: elm.alias,
                        value: "Undefined data",
                        _id: newData._id,
                      };
                    }
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: newData.value,
                      _id: newData._id,
                    };
                  }
                }
              }
            }
          }
          return {
            ...newData,
            alias: newData.name,
          };
        });

        const allFields = dataLibrary?.[0]?.fields
          ?.map((elm) => {
            if (elm.type !== "captureMedia" && elm.type !== "notification") {
              return {
                name: elm.name,
                alias: elm.alias,
                type: elm.type,
              };
            }
          })
          .filter((it) => it !== undefined);

        const matchMissingAttributes = allFields?.filter((elm) => {
          return res.every((item) => {
            return item.name !== elm.name;
          });
        });

        if (matchMissingAttributes?.length > 0) {
          matchMissingAttributes.forEach((elm) => {
            if (elm.type === "date") {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: "0000-00-00",
              });
            } else if (elm.type === "number" || elm.type === "operation") {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: NaN,
              });
            } else {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: "Undefined data",
              });
            }
          });
        }

        return { ...dataItem, attributes: res };
      });
      const rows = newDataArray.map((item) => {
        const convertedTime = moment
          .tz(item.createdAt, "UTC")
          .utcOffset(item.timeZoneOffset || "-05:00")
          .tz(item.timeZone || "America/Bogota");

        const initialPointId = item.lineObjects?.[0]?.object.id;
        const finalPointId = item.lineObjects?.[1]?.object.id;
        const initialLatitude = item.lineObjects?.[0]?.object.location.latitude;
        const initialLongitude =
          item.lineObjects?.[0]?.object.location.longitude;
        const finalLatitude = item.lineObjects?.[1]?.object.location.latitude;
        const finalLongitude = item.lineObjects?.[1]?.object.location.longitude;

        const row = {
          id: removeLineBreak(item.id),
          flyTo: (
            <button
              className="button"
              onClick={() => {
                handleExtendView({
                  array: [
                    item.lineObjects?.[0]?.object,
                    item.lineObjects?.[1]?.object,
                  ],
                  map: map,
                });
                markPoint(initialPointId, initialLatitude, initialLongitude);
                setTimeout(() => {
                  markPoint(finalPointId, finalLatitude, finalLongitude);
                }, 3000);
              }}
            >
              Fly
            </button>
          ),
          markPoint: (
            <button
              className="button"
              onClick={() => {
                markPoint(initialPointId, initialLatitude, initialLongitude);
                setTimeout(() => {
                  markPoint(finalPointId, finalLatitude, finalLongitude);
                }, 3000);
              }}
            >
              Flash
            </button>
          ),
          typeLineName: removeLineBreak(item.lineType.type),
          dateUTC: removeLineBreak(item.createdAt.substring(0, 10)),
          localDate: removeLineBreak(
            convertedTime.format("YYYY-MM-DD HH:mm:ss z").substring(0, 19)
          ),
          userName: removeLineBreak(
            (item.user &&
              item.user.adminCompany &&
              item.user.adminCompany.firstName +
                " " +
                item.user.adminCompany.firstLastName) ||
              (item.user &&
                item.user.operator &&
                item.user.operator.firstName +
                  " " +
                  item.user.operator.firstLastName) ||
              (item.user &&
                item.user.adminDecimetrix &&
                item.user.adminDecimetrix.firstName +
                  " " +
                  item.user.adminDecimetrix.firstLastName)
          ),
          typeLineId: removeLineBreak(item.lineTypeId),
          initialLatitude: initialLatitude,
          initialLongitude: initialLongitude,
          finalLatitude: finalLatitude,
          finalLongitude: finalLongitude,
          distance: fixDecimals(item.feature[0].properties.distance, 4),
          objectIdInitial: item?.lineObjects?.[1]?.object?.id || NaN,
          objectIdFinal: item?.lineObjects?.[0]?.object?.id || NaN,
        };

        if (item.attributes.length !== 0) {
          item.attributes.forEach((object) => {
            try {
              if (typeof JSON.parse(object.value) === "object") {
                const value = JSON.parse(object.value);
                row[object.alias || object.name] = parseFloat(value.value);
              } else {
                return (row[object.alias || object.name] = !isNaN(object.value)
                  ? parseFloat(object.value)
                  : object.value);
              }
            } catch (error) {
              return (row[object.alias || object.name] = !isNaN(object.value)
                ? parseFloat(object.value)
                : object.value);
            }
          });
        }

        for (const key in row) {
          if (attributte === "") return row;
          if (!row[key] || typeof row[key] === "object") continue;
          if (label !== "all" && key !== label) continue;

          const value = isNaN(row[key]) ? row[key] : row[key].toString();
          try {
            if (value.toUpperCase().includes(attributte.toUpperCase())) {
              return row;
            }
          } catch (error) {
            console.error(attributte);
          }
        }
        return null;
      });

      const rowsFilter = rows.filter((item) => item !== null);

      return rowsFilter;
    },
    [attributte, label, markPoint, dataLibrary, errorLibrary]
  );

  const updateLabels = useCallback(() => {
    const labels = {};
    for (const key in keyValuesLines) {
      if (keyValuesLines[key] === "distance") {
        const { symbol } = mapSettings?.measurementUnit ?? {};
        labels[keyValuesLines[key]] = headersLines[key].concat(` (${symbol})`);
        headersLines[key] = "Distance".concat(` (${symbol})`);
      }
      labels[keyValuesLines[key]] = headersLines[key];
    }
    setLabels(labels);
  }, [setLabels]);

  useEffect(() => {
    if (!lineLibraryId) return;
    const newDataLine = dataLines.filter((data) => {
      return data?.lineType?.lineLibraryId === lineLibraryId;
    });
    const oldRows = createRows(newDataLine);
    // modification on flow for measurement unit selected by map settings
    const { unit, symbol } = mapSettings?.measurementUnit ?? {};
    let distanceTemp = 0;
    const rows = oldRows.map((row) => {
      if (row["distance"] !== undefined) {
        if (symbol !== "km") {
          distanceTemp = calculateLengthFromDistance(row.distance, unit);
          row.distance = distanceTemp;
        }
      }
      return row;
    });
    setRows(() => [...rows]);
    setRowsMain(() => [...rows]);
    updateLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataLines,
    lineLibraryId,
    setRowsMain,
    updateLabels,
    dataLibrary,
    rowNumber,
  ]);

  useEffect(() => {
    dispatch(setRowNum(rows.length));
  }, [rows.length, dispatch]);
  return (
    <div className="body">
      {rows.length > 0 && (
        <SearchPosition>
          <GenericTable
            headers={headersLines}
            rows={rows}
            orderColumns={orderColumns}
            rowsDynamic={rows}
            typeOfTable={typeOfTable}
            typeOfLibrary={typeOfLibrary}
            keyValues={keyValuesLines}
            handlerClick={handlerClickTable}
            setTableVisibility={setTableVisibility}
            tableVisibility={tableVisibility}
            handleClose={handleClose}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            style={{
              small: true,
              maxHeight: state ? 100 : 50,
              width: 55,
            }}
            digitalTwin={true}
            state={state}
            pointLibraryId={lineLibraryId}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
            from={FROM_TABLE.digitalTwin}
            lines={true}
          />
        </SearchPosition>
      )}
    </div>
  );
}
