// React
import { useCallback } from "react";
import { useEffect, useState } from "react";
// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setFlyTo, setRowNum } from "redux/actions/digitalTwin";
// Components
import GenericTable from "components/Tables/GenericTable/GenericTable";
// Hooks
import useGet from "hooks/useFetch";
import { useFetchEventLibraries } from "hooks/fetchLibraries";
import useShowDetails from "hooks/useShowDetails";
// Dependecies
import moment from "moment-timezone";
// Styles
import { SearchPosition } from "./styles";
// Consts
import { keyValuesEvents, headersEvents } from "./keyAndHeadersTables";
import { FROM_TABLE } from "utils/constStrings";
import { replaceDotsWithUnderscores } from "helpers/removeAccents";
//report
import OpenDialog from "components/Dialogs/OpenDialog";
import getEventPDFInfo from "services/getEventPDFInfo";
import ReportContainer from "pages/Dashboard/pdfCreator/ReportContainer";
import { urls } from "utils/urlKeys";

const keyValues = [];

const headersTable = [];

/**
 * Generates a function comment for the given function body in a markdown code block with the correct language syntax.
 *
 * @param {Object} props - The props object containing the function parameters.
 * @param {string} props.typeEvent - The type of event.
 * @param {function} props.setRows - The function to set the rows.
 * @param {string} props.attributte - The attribute.
 * @param {function} props.setLabels - The function to set the labels.
 * @param {string} props.label - The label.
 * @param {function} props.setTableVisibility - The function to set the table visibility.
 * @param {boolean} props.tableVisibility - The table visibility.
 * @param {string} props.typeOfTable - The type of table.
 * @param {string} props.typeOfLibrary - The type of library.
 * @param {function} props.handleClose - The function to handle close.
 * @param {function} props.handleDrawerClose - The function to handle drawer close.
 * @param {function} props.handleDrawerOpen - The function to handle drawer open.
 * @param {boolean} [props.state=false] - The state.
 * @return {JSX.Element} The JSX element.
 */
export default function TableEvents({
  typeEvent,
  setRows: setRowsMain,
  attributte,
  setLabels,
  label,
  setTableVisibility,
  tableVisibility,
  typeOfTable,
  typeOfLibrary,
  handleClose,
  handleDrawerClose,
  handleDrawerOpen,
  state = false,
  orderColumns,
  dataChecked,
  setDataChecked,
}) {
  const dataEventPolygon = useSelector(
    (state) => state.adminReducer.dataEventPolygon,
    shallowEqual
  );
  const rowNumber = useSelector((state) => state.digitalTwinReducer.rowNum);

  const role = localStorage.getItem("role");

  const map = useSelector(
    (state) => state.digitalTwinReducer.map,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [event, setEvent] = useState(null);
  const [openDialogPDF, setOpenDialogPDF] = useState(false);
  const [pdfPreviewData, setPdfPreviewData] = useState({});
  const [events, setEvents] = useState({});
  const [rows, setRows] = useState([]);
  const [isDynamicEvent, setIsDynamicEvent] = useState([]);
  const flyToRedux = useSelector(
    (state) => state.digitalTwinReducer.flyTo,
    shallowEqual
  );
  const [eventLibraries] = useGet(urls.events.libraries);

  const objects = useSelector((state) => state.adminReducer.dataObjects);

  const { data: dataLibrary, error: errorLibrary } = useFetchEventLibraries({
    id: typeEvent,
  });

  // Extract positions fields
  const libraryFields = dataLibrary?.[0]?.fields || [];
  const fieldOrderMap = new Map(); //order of fields

  libraryFields.forEach((field) => {
    if (
      field.indexPosition !== undefined &&
      field.type !== "captureMedia" &&
      field.type !== "notification" &&
      field.type !== "lineTag"
    ) {
      fieldOrderMap.set(field.alias || field.name, field.indexPosition);
    }
  });

  const { handlerClickEventId } = useShowDetails();

  const handerClickTable = (operationId, isDynamic) => {
    const data = { operationId, isDynamic };
    handlerClickEventId(operationId);
    localStorage.setItem("typeEvent", JSON.stringify(data));
  };

  const removeLineBreak = (text) => {
    try {
      if (typeof text === "string") return text.replace(/(\r\n|\n|\r)/gm, " ");
      if (text && isNaN(text) && typeof text !== "object") {
        return text.toString().replace(/(\r\n|\n|\r)/gm, " ");
      }
      return text;
    } catch (error) {
      console.error("Error during removeLineBreak:", error);
      return text;
    }
  };

  const markPoint = useCallback(
    (id, lat, lng) => {
      if (state) {
        const data = {
          id,
          lat,
          lng,
        };
        if (lat && lng) {
          const dataMarketPoint = JSON.stringify(data);
          localStorage.setItem("dataMarketPoint", dataMarketPoint);
          setTimeout(() => {
            const setData = {
              id: "",
              lat: "",
              lng: "",
            };
            const setDataMarketPoint = JSON.stringify(setData);
            localStorage.setItem("dataMarketPoint", setDataMarketPoint);
          }, 7000);
        }
      } else {
        if (lat && lng) {
          setTableVisibility("none");
          dispatch(
            setFlyTo({
              ...flyToRedux,
              operation: {
                id,
                latitude: parseFloat(lat),
                longitude: parseFloat(lng),
              },
            })
          );
          setTimeout(() => {
            dispatch(
              setFlyTo({
                ...flyToRedux,
                operation: {
                  id: "",
                  latitude: "",
                  longitude: "",
                },
              })
            );
          }, 5000);
        }
      }
    },
    [flyToRedux, dispatch, setTableVisibility]
  );

  const report = async (event, id) => {
    const infoEvent = await getEventPDFInfo(id);
    if (infoEvent && Object.keys(infoEvent).length) {
      setPdfPreviewData(infoEvent);
      setOpenDialogPDF(true);
    }
  };

  const flyTo = useCallback(
    (e, id) => {
      const { value } = e.target;
      const [lng, lat] = value.split(",");
      if (JSON.parse(lng) && JSON.parse(lat)) {
        if (state) {
          const coordinates = [lng, lat];
          const parseCoordinates = JSON.stringify(coordinates);
          localStorage.setItem("coordinates", parseCoordinates);
          markPoint(id, lat, lng);
        } else {
          map.flyTo({
            center: [parseFloat(lng), parseFloat(lat)],
            duration: 2000,
            zoom: 24,
          });
          markPoint(id, lat, lng);
        }
      }
    },
    [map, markPoint]
  );

  const createRows = useCallback(
    (data) => {
      const newDataArray = data?.map((dataItem) => {
        let attributesCopy = [...dataItem.attributes];

        const res = attributesCopy.map((newData) => {
          const elm = dataLibrary?.[0]?.fields?.find((item) => {
            return (
              item?.name === newData?.name ||
              item?.alias === newData?.name ||
              item?.name === newData?.alias ||
              item?.alias === newData?.alias
            );
          });

          if (elm) {
            if (
              elm.type !== "captureMedia" &&
              elm.type !== "notification" &&
              elm.type !== "lineTag"
            ) {
              if (elm.type === "number" || elm.type === "operation") {
                if (newData.value && newData.value[0] === "{") {
                  const valueParse = JSON.parse(newData.value);
                  if (valueParse.value === "") {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: NaN,
                      _id: newData._id,
                    };
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: valueParse.value,
                      _id: newData._id,
                    };
                  }
                } else {
                  if (newData.value && !isNaN(newData.value)) {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: parseFloat(newData.value),
                      _id: newData._id,
                    };
                  }
                }
              } else {
                if (elm.type === "date" && !newData.value === !NaN) {
                  return {
                    name: elm.name,
                    alias: elm.alias,
                    value: "0000-00-00",
                    _id: newData._id,
                  };
                } else {
                  if (!newData.value === !NaN || newData.value === " ") {
                    if (elm.type === "date") {
                      return {
                        name: elm.name,
                        alias: elm.alias,
                        value: "0000-00-00",
                        _id: newData._id,
                      };
                    } else {
                      return {
                        name: elm.name,
                        alias: elm.alias,
                        value: "Undefined data",
                        _id: newData._id,
                      };
                    }
                  } else {
                    return {
                      name: elm.name,
                      alias: elm.alias,
                      value: newData.value,
                      _id: newData._id,
                    };
                  }
                }
              }
            } else {
              return {
                name: "excludeObject",
                alias: "excludeObject",
                value: "excludeObject",
                _id: "excludeObject",
              };
            }
          }
          return {
            ...newData,
            alias: newData.name,
          };
        });

        const allFields = dataLibrary?.[0]?.fields
          ?.map((elm) => {
            if (
              elm.type !== "captureMedia" &&
              elm.type !== "notification" &&
              elm.type !== "lineTag"
            ) {
              return {
                name: elm.name,
                alias: elm.alias,
                type: elm.type,
              };
            }
          })
          .filter((it) => it !== undefined);

        const matchMissingAttributes = allFields?.filter((elm) => {
          return res.every((item) => {
            return item.name !== elm.name;
          });
        });

        if (matchMissingAttributes?.length > 0) {
          matchMissingAttributes.forEach((elm) => {
            if (elm.type === "date") {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: "0000-00-00",
              });
            } else if (elm.type === "number" || elm.type === "operation") {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: NaN,
              });
            } else {
              res.push({
                name: elm.name,
                alias: elm.alias,
                value: "Undefined data",
              });
            }
          });
        }

        return { ...dataItem, attributes: res };
      });

      const rows = newDataArray?.map((item) => {
        const isDynamic = item?.typeOperationId ? false : true;
        setIsDynamicEvent((prev) => {
          return [
            ...prev,
            {
              id: item.id,
              isDynamic,
            },
          ];
        });
        const convertedTime = moment
          .tz(item.date, "UTC")
          .utcOffset(item.timeZoneOffset || "-05:00")
          .tz(item.timeZone || "America/Bogota");

        const IdRelation = (elm) => {
          if (elm?.objectId) return `${elm.objectId} / O`;
          if (elm?.pointComponentId) return `${elm?.pointComponentId} / C`;
          if (elm?.PointEventRelationWith)
            return `${item?.PointEventRelationWith[0]?.id} / E`;
        };
        const typeOfNameRelation = (elm) => {
          if (elm?.object) return elm.object?.typeElement?.name;
          if (elm?.component) return elm.component?.typeComponent?.name;
          if (elm?.event) return elm.event?.typeEvent?.name;
        };
        const { id, locationOperation } = item;
        const { latitude, longitude } = locationOperation;
        // Find user name
        const { operator, adminCompany } = item?.user;
        const { firstName, firstLastName } = operator || adminCompany;
        const totalEvents = item?.PointEventRelation?.length;

        const row = {
          id: removeLineBreak(item?.id),
          flyTo: (
            <button
              className="button"
              value={`${longitude},${latitude}`}
              onClick={(e) => flyTo(e, item?.id)}
            >
              Fly
            </button>
          ),
          markPoint: (
            <button
              className="button"
              onClick={() => markPoint(id, latitude, longitude)}
            >
              Flash
            </button>
          ),
          report:
            role !== "Administrador de compañía" ? (
              <></>
            ) : (
              <button className="button" onClick={(e) => report(e, id)}>
                View
              </button>
            ),
          typeOfEvent: removeLineBreak(
            item?.typeOperation?.name || item?.pointTypeEvent?.name
          ),
          relationId: removeLineBreak(IdRelation(item)),
          relationTypeName: removeLineBreak(typeOfNameRelation(item)),
          dateUTC: removeLineBreak(item?.date.substring(0, 10)),
          localDate: removeLineBreak(
            convertedTime.format("YYYY-MM-DD HH:mm:ss z").substring(0, 19)
          ),
          user: removeLineBreak(firstName + " " + firstLastName),
          latitude:
            removeLineBreak(item?.locationOperation?.latitude) ||
            "Non Georeferenced",
          longitude:
            removeLineBreak(item?.locationOperation?.longitude) ||
            "Non Georeferenced",
          georeferenced: removeLineBreak(item.isGeographic.toString()),
          totalEvents,
        };

        if (item.attributes.length !== 0) {
          item.attributes.forEach((object) => {
            if (object.name !== "excludeObject") {
              try {
                if (typeof JSON.parse(object.value) === "object") {
                  const value = JSON.parse(object.value);
                  row[replaceDotsWithUnderscores(object.alias)] = parseFloat(
                    value.value
                  );
                } else {
                  return (row[replaceDotsWithUnderscores(object.alias)] =
                    !isNaN(object.value)
                      ? parseFloat(object.value)
                      : object.value);
                }
              } catch (error) {
                return (row[replaceDotsWithUnderscores(object.alias)] = !isNaN(
                  object.value
                )
                  ? parseFloat(object.value)
                  : object.value);
              }
            }
          });
        }

        if (item[event]) {
          for (const key of keyValues[parseInt(typeEvent) - 1]) {
            let value = item[event][key];
            if (value || typeof value === "boolean") {
              if (typeof value === "boolean") {
                value = value ? "Yes" : "No";
              }
              row[key] = removeLineBreak(value);
            } else {
              row[key] = "-";
            }
          }
        }

        for (const key in row) {
          if (attributte === "") return row;
          if (!row[key] || typeof row[key] === "object") continue;
          if (label !== "all" && key !== label) continue;

          const value = isNaN(row[key]) ? row[key] : row[key].toString();
          try {
            if (value.toUpperCase().includes(attributte.toUpperCase())) {
              return row;
            }
          } catch (error) {
            console.error(error);
          }
        }
        return null;
      });

      const rowsFilter = rows.filter((item) => item !== null);

      return rowsFilter;
    },
    [
      attributte,
      label,
      flyTo,
      objects,
      markPoint,
      report,
      dataLibrary,
      typeEvent,
      event,
      errorLibrary,
      rowNumber,
    ]
  );

  const updateLabels = useCallback(() => {
    const labels = {};
    let currentKeyValues = [...keyValuesEvents];
    let currentHeadersTable = [...headersEvents];

    if (keyValues[parseInt(typeEvent) - 1]) {
      currentKeyValues = [
        ...keyValuesEvents,
        ...keyValues[parseInt(typeEvent) - 1],
      ];
    }

    if (headersTable[parseInt(typeEvent) - 1]) {
      currentHeadersTable = [
        ...headersEvents,
        ...headersTable[parseInt(typeEvent) - 1],
      ];
    }

    for (const key in currentKeyValues) {
      labels[currentKeyValues[key]] = currentHeadersTable[key];
    }
    setLabels(labels);
  }, [setLabels, typeEvent]);

  useEffect(() => {
    if (!typeEvent) return;
    const newDataEvent = dataEventPolygon?.filter((data) => {
      const pointLibraryEventId = data?.pointTypeEvent?.pointLibraryEventId;
      return pointLibraryEventId === parseInt(typeEvent);
    });
    const newRows = createRows(newDataEvent);
    setRows(() => [...newRows]);
    setRowsMain(() => [...newRows]);
    updateLabels();
  }, [
    dataEventPolygon,
    rowNumber,
    typeEvent,
    setRowsMain,
    updateLabels,
    dataLibrary,
  ]);

  useEffect(() => {
    setEvent(events[typeEvent]);
    setIsDynamicEvent([]);
  }, [typeEvent, events]);

  useEffect(() => {
    if (!eventLibraries || eventLibraries.error) return;
    const newEventLibraries = {};
    for (const { id, name } of eventLibraries) {
      newEventLibraries[id] = name;
    }
    setEvents((current) => ({ ...current, ...newEventLibraries }));
  }, [eventLibraries]);

  useEffect(() => {
    dispatch(setRowNum(rows.length));
  }, [rows.length, dispatch]);

  return (
    <div className="body">
      <OpenDialog
        openDialog={openDialogPDF}
        setOpenDialog={setOpenDialogPDF}
        content={{
          title: "Report Preview",
          description: <ReportContainer data={[pdfPreviewData]} event={true} />,
        }}
        minWidth="80vw"
      />
      {rows.length > 0 && (
        <SearchPosition>
          <GenericTable
            headers={[
              ...headersEvents,
              ...(headersTable[parseInt(typeEvent) - 1]
                ? headersTable[parseInt(typeEvent) - 1]
                : []),
            ]}
            rows={rows}
            orderColumns={orderColumns}
            rowsDynamic={rows}
            isDynamicEvent={isDynamicEvent}
            keyValues={[
              ...keyValuesEvents,
              ...(keyValues[parseInt(typeEvent) - 1]
                ? keyValues[parseInt(typeEvent) - 1]
                : []),
            ]}
            handlerClick={handerClickTable}
            style={{
              small: true,
              width: 55,
              maxHeight: state ? 90 : 50,
            }}
            setTableVisibility={setTableVisibility}
            tableVisibility={tableVisibility}
            typeOfTable={typeOfTable}
            typeOfLibrary={typeOfLibrary}
            handleClose={handleClose}
            digitalTwin={true}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            state={state}
            pointLibraryId={typeEvent}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
            from={FROM_TABLE.digitalTwin}
            lines={false}
            fieldOrderMap={fieldOrderMap}
          />
        </SearchPosition>
      )}
    </div>
  );
}
