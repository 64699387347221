import axios from "axios";
import { config } from "config.js";

const updateLinePropertiesMg = async ({ id, body }) => {
  const token = localStorage.getItem("token");

  try {
    const res = await axios.patch(
      `${config.URL_BACKEND_MG}line/properties/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );

    const data = res.data;

    return { data, res };
  } catch (error) {
    console.error("Error en updateLinePropertiesMg:", error);
    throw error;
  }
};

export default updateLinePropertiesMg;
