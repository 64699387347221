import { useCallback, useEffect, useState } from "react";
import { NumberRestrictionsContainer } from "../configAdminStyles";

const restrictions = [
  {
    label: "Greater than",
    value: ">",
  },
  {
    label: "Less than",
    value: "<",
  },
  {
    label: "Greater or Equal",
    value: ">=",
  },
  {
    label: "Less or Equal",
    value: "<=",
  },
  {
    label: "Exclusive range",
    value: "><",
  },
  {
    label: "Inclusive range",
    value: ">=<=",
  },
  {
    label: "Diferent than",
    value: "!==",
  },
  {
    label: "Equal",
    value: "==",
  },
];
/**
 *
 * @param {*} param0
 * @returns
 */
function NumberRestrictions({ field, setField, update }) {
  const parseRestrictions = useCallback((rts = []) => {
    return rts.map((rt) => {
      return {
        label: rt.label,
        symbol: rt.value,
        value: 0,
        valueMin: 0,
        valueMax: 0,
        color: "#000000",
        state: false,
      };
    });
  }, []);

  const [numberRestrictions, setNumberRestrictions] = useState(
    parseRestrictions(restrictions)
  );
  const [numberValue, setNumberValue] = useState([]);
  const [numberValueMinMax, setNumberValueMinMax] = useState([-1, 0, 0]); // [0,0,0]

  useEffect(() => {
    if (update && field !== undefined) {
      let rest = parseRestrictions(restrictions); //to have predefined options
      if (
        field?.numberGroupingOptions !== undefined &&
        field?.numberGroupingOptions?.restrictions?.length &&
        field?.numberGroupingOptions?.restrictions?.length ===
          restrictions.length
      ) {
        const fieldRest = field?.numberGroupingOptions?.restrictions;
        rest = parseRestrictions(restrictions).map((item, indx) => {
          if (fieldRest.at(indx) !== undefined && fieldRest.at(indx)?.state)
            return fieldRest.at(indx); // option exists with a value (from db)
          return item; // default values
        });
      }
      setNumberRestrictions(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  useEffect(() => {
    if (numberValue.length) {
      const restriction = numberRestrictions.at(numberValue[0]);
      restriction.valueMin = 0;
      restriction.valueMax = 0;
      restriction.value = numberValue[1];
      setNumberRestrictions((current) => {
        return current.map((it, indx) => {
          if (numberValue[0] === indx) return restriction;
          return it;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberValue]);

  useEffect(() => {
    if (numberValueMinMax.length > 0 && numberValueMinMax.at(0) >= 0) {
      const restriction = numberRestrictions.at(numberValueMinMax[0]);
      if (restriction.symbol === "><" || restriction.symbol === ">=<=") {
        restriction.valueMin = numberValueMinMax.at(1);
        restriction.valueMax = numberValueMinMax.at(2);
        restriction.value = 0;
      }
      setNumberRestrictions((current) => {
        return current.map((it, indx) => {
          if (numberValueMinMax[0] === indx) return restriction;
          return it;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberValueMinMax]);

  useEffect(() => {
    setField((current) => ({
      ...current,
      numberGroupingOptions: {
        restrictions: numberRestrictions,
      },
    }));
  }, [numberRestrictions, setField]);

  // funcion para cambiar el valor de la condicion
  const handleChangeValueToCompareMinMax = ({ index, e, min, max }) => {
    // numberValueMinMax => [ 0, 0, 0]
    if (update) {
      const restriction = field?.numberGroupingOptions?.restrictions?.at(index);
      setNumberValueMinMax([
        index, // indice de restricion en el arreglo de restricciones
        min && (max === undefined || !max) && !isNaN(e.target.value)
          ? parseFloat(e.target.value)
          : restriction.valueMin,
        max && (min === undefined || !min) && !isNaN(e.target.value)
          ? parseFloat(e.target.value)
          : restriction.valueMax,
      ]);
      return;
    }
    const restriction = numberRestrictions.at(index);

    setNumberValueMinMax([
      index, // indice de restricion en el arreglo de restricciones
      min && (max === undefined || !max) && !isNaN(e.target.value)
        ? parseFloat(e.target.value)
        : restriction.valueMin,
      max && (min === undefined || !min) && !isNaN(e.target.value)
        ? parseFloat(e.target.value)
        : restriction.valueMax,
    ]);
  };
  const handleChangeValueToCompare = ({ index, e }) => {
    setNumberValue([
      index,
      !isNaN(e.target.value) ? parseFloat(e.target.value) : 0,
    ]);
  };
  const checkValueToCompare = ({ index, min, max }) => {
    if (min && (max === undefined || !max)) {
      return numberRestrictions.at(index).valueMin;
    } else if (max && (min === undefined || !min)) {
      return numberRestrictions.at(index).valueMax;
    } else {
      return numberRestrictions.at(index).value;
    }
  };

  const handleChangeCheckbox = (index) => {
    const actualState = numberRestrictions.at(index).state;
    setNumberRestrictions((current) => {
      return current.map((it, indx) => {
        return index !== indx
          ? it
          : {
              ...it,
              state: !actualState,
            };
      });
    });
  };
  const checkChecked = (index) => {
    const restriction = numberRestrictions.at(index);
    const toReturn = restriction.state ? true : false;
    return toReturn;
  };

  const handleChangeColor = (index, newColor) => {
    if (numberRestrictions.at(index).color === newColor || !newColor.length)
      return;
    setNumberRestrictions((current) => {
      return current.map((it, indx) => {
        return index !== indx
          ? it
          : {
              ...it,
              color: newColor,
            };
      });
    });
  };
  const checkColorValue = (index) => {
    return numberRestrictions.at(index).color
      ? numberRestrictions.at(index).color
      : "#000000";
  };

  return (
    <NumberRestrictionsContainer>
      <section>
        <div>Number constraints:</div>
      </section>
      {numberRestrictions &&
        numberRestrictions.map((rt, index) => {
          return (
            <div className="restriction-option">
              <div className="input-restriction-checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => handleChangeCheckbox(index, e)}
                  value={index}
                  checked={checkChecked(index)}
                />
              </div>

              <div className="input-restriction-leyend">
                <input
                  type="text"
                  value={`${rt.label} - ${rt.symbol}`}
                  disabled
                />
              </div>

              <div className="input-restriction-value">
                {rt.symbol === "><" || rt.symbol === ">=<=" ? (
                  <div>
                    <label htmlFor="greater">
                      {rt.symbol === ">=<=" ? (
                        <p style={{ fontSize: "1.25rem" }}>{">= Min"}</p>
                      ) : (
                        <p style={{ fontSize: "1.25rem" }}>{"> Min"}</p>
                      )}
                    </label>
                    <input
                      name="greater" // editar las opciones del field!
                      type="text"
                      pattern="\d*"
                      onChange={(e) =>
                        handleChangeValueToCompareMinMax({
                          index,
                          e,
                          min: true,
                        })
                      }
                      value={checkValueToCompare({ index, min: true })}
                    />
                    <label htmlFor="lesser">
                      {rt.symbol === ">=<=" ? (
                        <p style={{ fontSize: "1.25rem" }}>{"<= Max"}</p>
                      ) : (
                        <p style={{ fontSize: "1.25rem" }}>{"< Max"}</p>
                      )}
                    </label>
                    <input
                      name="lesser"
                      type="text"
                      pattern="\d*"
                      onChange={(e) =>
                        handleChangeValueToCompareMinMax({
                          index,
                          e,
                          max: true,
                        })
                      }
                      value={checkValueToCompare({ index, max: true })}
                    />
                  </div>
                ) : (
                  <input
                    type="text"
                    pattern="\d*"
                    onChange={(e) => handleChangeValueToCompare({ index, e })}
                    value={checkValueToCompare({ index })}
                  />
                )}
              </div>

              <div className="input-restriction-color">
                <input
                  type="color"
                  onChange={(e) => handleChangeColor(index, e.target.value)}
                  value={checkColorValue(index)}
                />
              </div>
            </div>
          );
        })}
    </NumberRestrictionsContainer>
  );
}

export default NumberRestrictions;
