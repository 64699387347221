import axios from "axios";
import { config } from "config.js";
import { urls } from "utils/urlKeys";
const updateGlobalConstant = async ({ id, body }) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.patch(
      urls.updateGlobalConstants(id),
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    return { error };
  }
};

export default updateGlobalConstant;
