import axios from "axios";

import { config } from "config.js";

export const createChart = async (body) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_MG}img-chart`;
  const res = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

// Delete line chart

export const deleteImgChart = async ({ id }) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_MG}img-chart/${id}`;
  const res = await axios.delete(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};
