import { useEffect, useState } from "react";
// Dependencies
import useSwr from "swr";
import { urls } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";
import { FROM_TABLE } from "utils/constStrings";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import OpenDialog from "./OpenDialog";
import Loading from "components/Lodings/LoadingV2";
import { roles } from "utils/roles";

export const keyValuesUsers = [
  "firstName",
  "lastName",
  "email",
  "accessStatus",
];

export const headersUsers = ["Name", "Last Name", "E-mail", "Access Status"];

//form for admin decimetrix aqui esta el propio formulario
export const AllowAccessInstance = ({
  openDialog,
  setOpenDialog,
  register,
}) => {
  const [rows, setRows] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  const { instanceManager } = useUserStore((state) => state);

  const { data: allAdminCompanies, error: allAdminCompaniesError } = useSwr(
    urls.adminCompany.all
  );

  const { data: allOperators, error: allOperatorsError } = useSwr(
    urls.operators.all
  );
  useEffect(() => {
    if (
      register === roles.companyAdmin &&
      !allAdminCompaniesError &&
      allAdminCompanies
    ) {
      setIsLoad(true);
      const rowsUsers = allAdminCompanies.map((elm) => {
        const instanceAllowed = elm.user.instancesUsers.find(
          (it) => it.instanceId === instanceManager.id
        );
        return {
          id: elm.user.id,
          firstName: elm.firstName,
          lastName: elm.firstLastName,
          email: elm.user.email,
          accessStatus: instanceAllowed ? true : false,
        };
      });
      setRows(rowsUsers);
      setIsLoad(false);
    }
  }, [
    allAdminCompanies,
    allAdminCompaniesError,
    instanceManager,
    register,
    roles,
  ]);

  useEffect(() => {
    if (register === roles.operario && !allOperatorsError && allOperators) {
      setIsLoad(true);
      const rowsUsers = allOperators.map((elm) => {
        const instanceAllowed = elm.user.instancesUsers.find(
          (it) => it.instanceId === instanceManager.id
        );
        return {
          id: elm.user.id,
          firstName: elm.firstName,
          lastName: elm.firstLastName,
          email: elm.user.email,
          accessStatus: instanceAllowed ? true : false,
        };
      });
      setRows(rowsUsers);
      setIsLoad(false);
    }
  }, [allOperators, allOperatorsError, instanceManager, register, roles]);

  return (
    <div>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={() => {}}
        content={{
          title: "Allow Access",
          description: (
            <div style={{ width: "100%" }}>
              {rows && (
                <GenericTable
                  keyValues={keyValuesUsers}
                  headers={headersUsers}
                  rows={rows}
                  setRows={setRows}
                  state={true}
                  configBar={true}
                  from={FROM_TABLE.allowAccessUsers}
                />
              )}
              {isLoad && <Loading />}
            </div>
          ),
          disagree: "Cancel",
        }}
      />
    </div>
  );
};
