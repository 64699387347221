import { useState, useEffect, useCallback } from "react";
// Helpers
import {
  sumValues,
  countByValues,
} from "pages/Dashboard/helpers/componentsGroupDonutChart";
import { createRowsComponents } from "pages/Dashboard/helpers/createRowsComponents";

// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";

export const useFindGroupDoughnutChartsComponents = ({
  openDialog,
  components,
  loading,
}) => {
  const [charts, setCharts] = useState(null);
  const [groupDonutCharts, setGroupDonutCharts] = useState(null);
  const [componentsFormat, setComponentsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataGroupDonutChart, error: errorDataGroupDonutChart } = useSwr(
    urlsApiMg.dashboard.doughnutChartGroup("components")
  );

  useEffect(() => {
    if (!errorDataGroupDonutChart && dataGroupDonutChart)
      setCharts(dataGroupDonutChart);
  }, [dataGroupDonutChart, errorDataGroupDonutChart, openDialog, reloadCharts]);

  useEffect(() => {
    const componentsFormat = createRowsComponents({ components });
    setComponentsFormat(componentsFormat);
  }, [components]);

  const getDataGroupDonutChart = useCallback(async () => {
    if (!loading && componentsFormat) {
      const chartsFilterCount = charts.filter(
        (chart) => chart.type === "count"
      );
      const countCharts = countByValues({
        charts: chartsFilterCount,
        components: componentsFormat,
      });

      const chartsFilterSum = charts.filter((chart) => chart.type === "sum");
      const sumCharts = sumValues({
        charts: chartsFilterSum,
        components: componentsFormat,
      });

      setGroupDonutCharts([...countCharts, ...sumCharts]);
    }
  }, [loading, componentsFormat, charts]);

  useEffect(() => {
    if (charts && components) {
      getDataGroupDonutChart();
    }
  }, [charts, components, getDataGroupDonutChart]);

  return { groupDoughnutCharts: groupDonutCharts };
};
