import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";

const ProductIterationImg = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke={SECOND_COLOR}
      stroke-width="0.155"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g data-name="Product Icons">
          {" "}
          <path
            class="cls-1"
            d="M12,2A10,10,0,1,0,22,12h0A10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-4.18-1.18l3.58-2.07h0v-4.5L7.51,10v4.52l2.75,1.59-3.46,2A8,8,0,0,1,8.08,5V9h0L12,11.28,15.9,9,12,6.77,9.24,8.36V4.47a8,8,0,0,1,10.31,4.7A8.1,8.1,0,0,1,20.06,12v.07L16.46,10h0l-3.9,2.26v4.51l3.9-2.25V11.34l3.45,2A8,8,0,0,1,12,20Z"
            fill={PRIMARY_COLOR}
          ></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default ProductIterationImg;
