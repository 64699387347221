/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
// Services
import findObjects from "services/objects/findObjects";
import findComponents from "services/findComponents";
// Hooks
import useShowDetails from "hooks/useShowDetails";
import { useUserStore } from "zustandGloabalStore/user";

/**
 * Generates a function comment for the given function body.
 *
 * @param {object} event - The event object.
 * @param {object} component - The event component.
 * @return {object} An object containing the MarkerParentEvent.
 */
const useCreateMarkerParents = ({ event = null, component = null }) => {
  const [MarkerParentEvent, setMarkerParentEvent] = useState([]);
  const [MarkerParentComponent, setMarkerParentComponent] = useState();
  const { instanceId } = useUserStore((state) => state);

  // Handler click to marker of objects, components and events
  const { handlerClickObjectId, handlerClickComponentId, handlerClickEventId } =
    useShowDetails();

  // Get object of the current event if event is related to a object
  useEffect(() => {
    const getObject = async () => {
      const objectId = event?.objectId;
      const data = await findObjects(objectId);

      if (data.user?.instancesUsers?.instanceId === parseInt(instanceId)) {
        const marker = {
          id: data?.id,
          latitude: data?.location?.latitude,
          longitude: data?.location?.longitude,
          icon: data?.typeElement?.urlIconColor,
          handlerClick: () => handlerClickObjectId(data.id),
        };
        setMarkerParentEvent([marker]);
      }
    };
    event?.objectId && getObject();
  }, [event]);

  // Get component of the current event if the event is related to a component
  useEffect(() => {
    const getComponent = async () => {
      const componentId = event?.pointComponentId;
      const components = await findComponents({ id: componentId });
      const component = components?.[0];

      if (component?.instanceId === parseInt(instanceId)) {
        const marker = {
          id: component?.id,
          latitude: component?.locationComponent?.latitude,
          longitude: component?.locationComponent?.longitude,
          icon: component?.pointTypeComponent?.icon,
          handlerClick: () => handlerClickComponentId(component.id),
        };
        setMarkerParentEvent([marker]);
      }
    };
    event?.pointComponentId && getComponent();
  }, [event]);

  // Get event of the current event if the event is related to a event
  useEffect(() => {
    const getEventParent = async () => {
      const eventParent = event?.PointEventRelationWith?.filter(
        (point) => point?.instanceId === parseInt(instanceId)
      )?.[0];
      if (eventParent) {
        const marker = {
          id: eventParent?.id,
          latitude: eventParent?.locationOperation?.latitude,
          longitude: eventParent?.locationOperation?.longitude,
          icon: eventParent?.pointTypeEvent?.icon,
          handlerClick: () => handlerClickEventId(eventParent?.id),
        };
        setMarkerParentEvent([marker]);
      }
    };
    event?.PointEventRelationWith.length > 0 && getEventParent();
  }, [event]);

  // Get event of the current component if the event is related to a component
  useEffect(() => {
    const getParentComponent = async () => {
      const objectId = component?.objectId;
      const componentParent = await findObjects(objectId);

      if (componentParent?.user?.instancesUsers[0]?.instanceId === parseInt(instanceId)) {
        const marker = {
          id: componentParent.id,
          latitude: componentParent?.location?.latitude,
          longitude: componentParent?.location?.longitude,
          icon: componentParent?.typeElement?.urlIconColor,
          handlerClick: () => handlerClickObjectId(componentParent?.id),
        };
        setMarkerParentComponent([marker]);
      }
    };

    component && getParentComponent();
  }, [component]);

  return { MarkerParentEvent, MarkerParentComponent };
};

export default useCreateMarkerParents;
