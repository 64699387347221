import styled from "styled-components";
import { WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";


export const ContainerContentDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    padding: 10px 0;
    font-weight: 500;
  }

  .select-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .select {
    background-color: ${PRIMARY_COLOR};
    border-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    padding: 5px 15px;
    border-radius: 5px;
    width: 100%;
  }
`;

export const ContainerPictures = styled.section`
  display: flex;
  width: 600px;
  height: 430px;

  img {
    width: 0px;
    flex-grow: 1;
    object-fit: cover;
    opacity: 0.9;
  }

  img:hover {
    width: 300px;
    opacity: 1;
    filter: contrast(120%);
  }
`;
