import { useState, useEffect } from "react";
import useSwr from "swr";
import { urls } from "utils/urlKeys";

const useFindObjects = () => {
  const [loading, setLoading] = useState(true);

  // Fetch objects data
  const { data, error } = useSwr(urls.objects.all);

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  const objects = data && !error ? data : [];

  return { objects: objects[0], loading };
};

export default useFindObjects;
