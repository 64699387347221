import { config } from "config.js";
import useSWR from "swr";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  BACKGROUND_SECOND_GREY,
  PRIMARY_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";

// custom style
import {
  LinePreviewFilters,
  WrapperTypeObjectTitle,
} from "../../AccordionStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowLines,
  setDataLines,
  setLineFilters,
} from "redux/actions/digitalTwin";
import { useEffect, useRef, useState } from "react";
import { urls } from "utils/urlKeys";

/**
 * This component renders the the lines filter by library or types
 * also settles the react redux state for line filters
 * @returns React component
 */
function FilterLines({ dataLines, setHasHiddenFiltersLines }) {
  const [eyeColorsLibraries, setEyeColorsLibraries] = useState([]);
  const [eyeColorsTypes, setEyeColorsTypes] = useState([]);
  const containerRef = useRef(null); // Referencia al contenedor principal
  const { data: librariesWithTypes, error: errorLibrariesWithTypes } = useSWR(
    urls.lines.libraryTypes
  );

  const dispatch = useDispatch();

  const showLines = useSelector((state) => state.digitalTwinReducer.showLines);

  /* useEffects */
  // sets initial eyes states on true
  useEffect(() => {
    if (librariesWithTypes !== undefined && librariesWithTypes.length) {
      setEyeColorsLibraries(
        //all libs
        librariesWithTypes.map((lib) => {
          return {
            libraryId: lib.id,
            // color: `${VISIBILITY_OFF_ICON}`,
            state: showLines ? true : false,
          };
        })
      );
      setEyeColorsTypes(
        //all types
        librariesWithTypes
          .map((lib) => {
            return lib.types?.map((typ) => {
              return {
                libraryId: lib.id,
                typeId: typ.id,
                // color: `${VISIBILITY_OFF_ICON}`,
                state: showLines ? true : false,
              };
            });
          })
          .flat(1)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [librariesWithTypes]);

  // enables/disables all with the parent eye
  const setShowLinesHandler = (change) => {
    dispatch(setShowLines(change));
    setEyeColorsLibraries(
      eyeColorsLibraries.map((it) => {
        const itTemp = {
          ...it,
          state: change,
        };
        return itTemp;
      })
    );
    setEyeColorsTypes(
      eyeColorsTypes.map((it) => {
        const itTemp = {
          ...it,
          state: change,
        };
        return itTemp;
      })
    );
    if (change) {
      //vuelve a poner todo en orden
      dispatch(setDataLines(dataLines));
      const filters = {
        linesLibrariesFilters: [],
        linesTypesFilters: [],
      };
      dispatch(setLineFilters(filters));
    }
  };

  // filter by libraries
  const handlerClickFilterLinesByLibrary = (state, libraryId, color) => {
    const index = eyeColorsLibraries.findIndex(
      (it) => it?.libraryId === libraryId
    );
    const toModify = eyeColorsLibraries.at(index);
    toModify.state = state;
    //change state current library eye color
    setEyeColorsLibraries((current) => [...new Set([...current, toModify])]);
    // enables/disables all library types
    setEyeColorsTypes(
      eyeColorsTypes.map((tp) => {
        if (tp?.libraryId === libraryId) {
          tp.state = state;
          return tp;
        }
        return tp;
      })
    );
    // disables parent eye state when no library visible left
    if (eyeColorsLibraries.every((it) => !it.state)) {
      setShowLinesHandler(false);
    }
    // enables parent eye state when son is chosed
    if (!showLines && state) {
      dispatch(setShowLines(true));
    }
  };

  // filter by types
  const handlerClickFilterLinesByType = (typeId, libraryId, state, color) => {
    const index = eyeColorsTypes.findIndex((it) => it?.typeId === typeId);
    const toModify = eyeColorsTypes.at(index);
    toModify.state = state;
    setEyeColorsTypes((current) => [...new Set([...current, toModify])]);

    // disables parent eye when last eye off
    const indexLib = eyeColorsLibraries.findIndex(
      (it) => it?.libraryId === libraryId
    );
    const toModifyLib = eyeColorsLibraries.at(indexLib);
    if (
      eyeColorsTypes
        .filter((it) => it?.libraryId === libraryId)
        .every((it) => !it.state)
    ) {
      toModifyLib.state = false; //ultimo ojo de tipo cierra el ojo de libreria
      //change state current library eye state
      setEyeColorsLibraries((current) => [
        ...new Set([...current, toModifyLib]),
      ]);
      return;
    }
    // enables lib eye on all lib types off and showLines off
    if (!showLines && eyeColorsLibraries.every((it) => !it.state)) {
      toModifyLib.state = true;
      setEyeColorsLibraries((current) => [
        ...new Set([...current, toModifyLib]),
      ]);
      dispatch(setShowLines(true));
    }
    //
    if (
      eyeColorsTypes
        .filter((it) => it?.libraryId === libraryId)
        .some((it) => it.state)
    ) {
      toModifyLib.state = true;
      setEyeColorsLibraries((current) => [
        ...new Set([...current, toModifyLib]),
      ]);
    }
  };

  const checkLibraryVisibility = (libraryId) => {
    const libFound = eyeColorsLibraries.find(
      (it) => it?.libraryId === libraryId
    );
    if (libFound && libFound.state) return true;
    return false;
  };

  const checkTypeVisibility = (typeId) => {
    const typeFound = eyeColorsTypes.find((it) => it?.typeId === typeId);
    if (typeFound && typeFound.state) return true;
    return false;
  };

  useEffect(() => {
    const setFilters = () => {
      const filters = {
        linesLibrariesFilters: [...eyeColorsLibraries],
        linesTypesFilters: [...eyeColorsTypes],
      };
      dispatch(setLineFilters(filters));
    };
    eyeColorsLibraries.length && eyeColorsTypes.length && setFilters();
  }, [dispatch, eyeColorsLibraries, eyeColorsTypes]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const hiddenIcons = container.querySelectorAll(".iconEyeOff");
      setHasHiddenFiltersLines(hiddenIcons.length > 0); // Hay ojos apagados
    }
  }, [eyeColorsLibraries, eyeColorsTypes, setHasHiddenFiltersLines]);

  return (
    <Accordion
      ref={containerRef}
      className="background-2"
      sx={{
        paddingLeft: "0px",
        border: "none",
        boxShadow: "none",
        background: `${BACKGROUND_SECOND_GREY}`,
      }}
      defaultExpanded={false}
    >
      <WrapperTypeObjectTitle>
        <div className="icon-title">
          {showLines ? (
            <VisibilityIcon
              sx={{
                margin: "10px 10px 10px 15px",
              }}
              className="iconEyeOn"
              onClick={() => setShowLinesHandler(false)}
            />
          ) : (
            <VisibilityOffIcon
              sx={{
                margin: "10px 15px",
              }}
              className="iconEyeOff"
              onClick={() => setShowLinesHandler(true)}
            />
          )}
          <p>Lines</p>
        </div>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="color1" />}
        ></AccordionSummary>
      </WrapperTypeObjectTitle>

      {librariesWithTypes !== undefined &&
        errorLibrariesWithTypes === undefined &&
        librariesWithTypes.map((lib) => (
          <AccordionDetails
            sx={{
              padding: "1px 1px 1px",
              marginLeft: "10px",
            }}
          >
            <Accordion
              className="background-2"
              sx={{
                paddingLeft: "5px",
                border: "none",
                boxShadow: "none",
                background: `${BACKGROUND_SECOND_GREY}`,
              }}
              defaultExpanded={false}
            >
              <WrapperTypeObjectTitle>
                <div className="icon-title">
                  {checkLibraryVisibility(lib.id) ? (
                    <VisibilityIcon
                      sx={{
                        color: "var(--background-primary_color)",
                        margin: "10px 10px 10px 15px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handlerClickFilterLinesByLibrary(
                          false,
                          lib.id,
                          `${VISIBILITY_OFF_ICON}`
                        )
                      }
                    />
                  ) : (
                    <VisibilityOffIcon
                      sx={{
                        color: `${VISIBILITY_OFF_ICON}`,
                        margin: "10px 15px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handlerClickFilterLinesByLibrary(
                          true,
                          lib.id,
                          `${PRIMARY_COLOR}`
                        )
                      }
                    />
                  )}
                  <p>{lib?.name}</p>
                </div>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="color1" />}
                ></AccordionSummary>
              </WrapperTypeObjectTitle>
              <AccordionDetails>
                {lib?.types?.map((tp) => {
                  return (
                    <WrapperTypeObjectTitle>
                      <>
                        <div className="icon-title">
                          {checkTypeVisibility(tp.id) ? (
                            <VisibilityIcon
                              sx={{
                                color: "var(--background-primary_color)",
                                margin: "10px 10px 10px 15px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handlerClickFilterLinesByType(
                                  tp.id,
                                  lib.id,
                                  false,
                                  `${VISIBILITY_OFF_ICON}`
                                )
                              }
                            />
                          ) : (
                            <VisibilityOffIcon
                              sx={{
                                color: `${VISIBILITY_OFF_ICON}`,
                                margin: "10px 15px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handlerClickFilterLinesByType(
                                  tp.id,
                                  lib.id,
                                  true,
                                  `${PRIMARY_COLOR}`
                                )
                              }
                            />
                          )}
                          <p>{tp.type}</p>
                        </div>
                        <LinePreviewFilters width={"100px"} color={tp?.color}>
                          <span className="circle"></span>
                          <span className="line"></span>
                          <span className="circle"></span>
                        </LinePreviewFilters>
                      </>
                    </WrapperTypeObjectTitle>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        ))}
    </Accordion>
  );
}

export default FilterLines;
