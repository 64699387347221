import React from "react";
import { SECOND_COLOR, PRIMARY_COLOR } from "utils/globalColorsTheme";

const EventsImg = () => {
  return (
    <svg
      viewBox="-2.4 -2.4 28.80 28.80"
      fill={SECOND_COLOR}
      stroke={SECOND_COLOR}
      strokeWidth="0.4800000000000001"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={SECOND_COLOR}
        strokeWidth="0.672"
      >
        <defs>
          <filter
            id="luminosity-noclip"
            x="4.64"
            y="4.19"
            width="14.73"
            height="12.76"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodColor={SECOND_COLOR} result="bg"></feFlood>
            <feBlend in="SourceGraphic" in2="bg"></feBlend>
          </filter>
          <mask
            id="mask"
            x="4.64"
            y="4.19"
            width="14.73"
            height="12.76"
            maskUnits="userSpaceOnUse"
          >
            <circle
              style={{ filter: "url(#luminosity-noclip)" }}
              cx="12"
              cy="12.23"
              r="3.58"
            ></circle>
          </mask>
        </defs>
        <g data-name="Product Icons">
          <circle fill={PRIMARY_COLOR} cx="18.97" cy="8.21" r="1.72"></circle>
          <circle fill={PRIMARY_COLOR} cx="5.03" cy="8.21" r="1.72"></circle>
          <circle fill={PRIMARY_COLOR} cx="12" cy="20.28" r="1.72"></circle>
          <g mask="url(#mask)">
            <rect
              fill={PRIMARY_COLOR}
              x="14.69"
              y="10.22"
              width="1.59"
              height="8.04"
              transform="matrix(0.5, -0.87, 0.87, 0.5, -4.59, 20.53)"
            ></rect>
            <rect
              fill={PRIMARY_COLOR}
              x="4.49"
              y="13.45"
              width="8.04"
              height="1.59"
              transform="translate(-5.98 6.17) rotate(-30)"
            ></rect>
            <rect
              fill={PRIMARY_COLOR}
              x="11.2"
              y="4.19"
              width="1.59"
              height="8.04"
            ></rect>
          </g>
          <circle fill={PRIMARY_COLOR} cx="12" cy="12.23" r="2.78"></circle>
          <circle fill={PRIMARY_COLOR} cx="5.03" cy="16.25" r="2.19"></circle>
          <circle fill={PRIMARY_COLOR} cx="18.97" cy="16.25" r="2.19"></circle>
          <circle fill={PRIMARY_COLOR} cx="12" cy="4.19" r="2.19"></circle>
        </g>
      </g>
      <g id="SVGRepo_iconCarrier">
        <defs>
          <filter
            id="luminosity-noclip-2"
            x="4.64"
            y="4.19"
            width="14.73"
            height="12.76"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodColor={SECOND_COLOR} result="bg"></feFlood>
            <feBlend in="SourceGraphic" in2="bg"></feBlend>
          </filter>
          <mask
            id="mask-2"
            x="4.64"
            y="4.19"
            width="14.73"
            height="12.76"
            maskUnits="userSpaceOnUse"
          >
            <circle
              style={{ filter: "url(#luminosity-noclip-2)" }}
              cx="12"
              cy="12.23"
              r="3.58"
            ></circle>
          </mask>
        </defs>
        <g data-name="Product Icons">
          <circle fill={PRIMARY_COLOR} cx="18.97" cy="8.21" r="1.72"></circle>
          <circle fill={PRIMARY_COLOR} cx="5.03" cy="8.21" r="1.72"></circle>
          <circle fill={PRIMARY_COLOR} cx="12" cy="20.28" r="1.72"></circle>
          <g mask="url(#mask-2)">
            <rect
              fill={PRIMARY_COLOR}
              x="14.69"
              y="10.22"
              width="1.59"
              height="8.04"
              transform="matrix(0.5, -0.87, 0.87, 0.5, -4.59, 20.53)"
            ></rect>
            <rect
              fill={PRIMARY_COLOR}
              x="4.49"
              y="13.45"
              width="8.04"
              height="1.59"
              transform="translate(-5.98 6.17) rotate(-30)"
            ></rect>
            <rect
              fill={PRIMARY_COLOR}
              x="11.2"
              y="4.19"
              width="1.59"
              height="8.04"
            ></rect>
          </g>
          <circle fill={PRIMARY_COLOR} cx="12" cy="12.23" r="2.78"></circle>
          <circle fill={PRIMARY_COLOR} cx="5.03" cy="16.25" r="2.19"></circle>
          <circle fill={PRIMARY_COLOR} cx="18.97" cy="16.25" r="2.19"></circle>
          <circle fill={PRIMARY_COLOR} cx="12" cy="4.19" r="2.19"></circle>
        </g>
      </g>
    </svg>
  );
};

export default EventsImg;
