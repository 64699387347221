import * as turf from "@turf/turf";

const handleExtendView = ({ array, map }) => {
  if (array.length !== 0) {
    const features = array.map((obj) => {
      if (obj?.hasOwnProperty("type") && obj.type === "Feature") {
        return obj.geometry.coordinates;
      } else {
        if (obj?.hasOwnProperty("locationComponent")) {
          return Array.of(
            obj.locationComponent.longitude,
            obj.locationComponent.latitude
          );
        } else {
          return Array.of(obj.location.longitude, obj.location.latitude);
        }
      }
    });
    const featuresCollection = turf.points(features);
    const envelopeFeatures = turf.envelope(featuresCollection);

    map?.getMap().fitBounds(envelopeFeatures.bbox, {
      padding: 100,
      duration: 500,
    });
  }
};

export default handleExtendView;
