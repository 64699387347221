import React, { useCallback, useEffect, useMemo, useState } from "react";
import MarkerCluster from "./MarkerCluster";
import { Layer, Source } from "react-map-gl";
import { OBJECT_CLUSTER_COLOR } from "utils/const";
import MarkerElement from "./MarkerElement";
import ObjectDetails from "components/ElementDetails/ObjectDetails/ObjectDetails";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  setDrawLineMode,
  setModalRelationObject,
  setPopUpRelationObject,
} from "redux/actions/digitalTwin";
import { getNumericValue } from "helpers/getNumericValue";
import getColorFromField from "helpers/getColorFromField";

const ObjectsLayer = React.memo(
  ({
    clusters,
    onSelectPoint,
    setViewState,
    viewState,
    objectsLength,
    mapRef,
  }) => {
    const dispatch = useDispatch();
    const [dataCluster, setDataCluster] = useState([]);
    const [dataLayer, setDataLayer] = useState([]);
    const [showMarkers, setShowMarkers] = useState(false);
    const [show, setShow] = useState("showPoints");
    const [pointColorsByMapSettings, setPointColorsByMapSettings] = useState(
      []
    );

    const allObjects = useSelector((state) => state.adminReducer.dataObjects);

    const { isDraw: drawAnyThing } = useSelector(
      (state) => state.digitalTwinReducer.drawAnyThing
    );

    const relationObjectModal = useSelector(
      (state) => state.digitalTwinReducer.modalRelationObject
    );

    const drawLineMode = useSelector(
      (state) => state.digitalTwinReducer.drawLineMode
    );

    const ObjetSelected = useSelector(
      (state) => state.digitalTwinReducer.objectRelationObject.objectId
    );

    const mapSettingsState = useSelector(
      (state) => state.digitalTwinReducer.mapSettings
    );

    const dataObjectsPolygon = useSelector(
      (state) => state.adminReducer.dataObjectsPolygon
    );

    const HandleClickMarker = useCallback(
      async (objectId) => {
        //Save object in the store for get information
        const objectClick =
          allObjects.find((obj) => obj.id === objectId) ||
          dataObjectsPolygon.find((obj) => obj.id === objectId);

        //If relationObjectModal.show is false, then the user is trying to see the details of the object
        if (!drawAnyThing && !relationObjectModal.show) {
          dispatch(
            setOperationDetails({
              content: <ObjectDetails objectId={objectId} />,
              title: "Objects Details",
            })
          );
          dispatch(setShowOperationDetails(true));
        } else if (drawLineMode.mode === "linerelations") {
          const { id, location } = objectClick;
          dispatch(
            setDrawLineMode({
              mode: "linerelations",
              drawStatus: true,
              show: true,
              features: drawLineMode.features,
              object: {
                id,
                location,
              },
            })
          );
        } else {
          // If relationObjectModal.show is true, then the user is trying to create a relation between two or more objects, if is true create a instance of the object relation
          const aux = {
            objectId: objectId,
            objectSelected: ObjetSelected.id,
            objectClick: objectClick,
          };

          //This is for activate the popUp to create the relation
          dispatch(setPopUpRelationObject({ showPopUp: true }));

          //This is for save the information of the object to relate and hold the instance of the object relation.
          dispatch(
            setModalRelationObject({
              ...relationObjectModal,
              ...aux,
            })
          );
        }
      },
      [
        ObjetSelected,
        allObjects,
        dispatch,
        drawAnyThing,
        relationObjectModal,
        drawLineMode,
      ]
    );

    const renderObjectMarker = useCallback(
      (object) => {
        return (
          <MarkerElement
            element={object}
            zoom={viewState.zoom}
            handleClick={() => HandleClickMarker(object.id)}
          />
        );
      },
      [HandleClickMarker, viewState.zoom]
    );

    useEffect(() => {
      const currentCluster = clusters.filter((it) => it.properties.cluster);
      const currentLayer = clusters.filter((it) => !it.properties.cluster);
      const countPointInCluster = currentCluster.reduce(
        (acc, current) => acc + current.properties.point_count,
        0
      );
      setDataCluster(currentCluster);
      setDataLayer(currentLayer);
      if (countPointInCluster > 200) {
        setShowMarkers(false);
        setShow("showPoints");
      }
      if (countPointInCluster < 201) setShowMarkers(true);
      setDataCluster(currentCluster);
      setDataLayer(currentLayer);
    }, [clusters]);

    useEffect(() => {
      const handleRender = debounce(() => {
        if (
          mapRef.current.queryRenderedFeatures({
            layers: ["point-layer"],
          }).length > 200
        ) {
          setShow("showPoints");
        }

        if (
          showMarkers &&
          mapRef.current.queryRenderedFeatures({
            layers: ["point-layer"],
          }).length > 0 &&
          mapRef.current.queryRenderedFeatures({
            layers: ["point-layer"],
          }).length < 201
        ) {
          setShow("showMarkers");
        }
      }, 200);
      if (mapRef.current) {
        mapRef.current.on("render", handleRender);
      }

      return () => {
        if (mapRef.current) {
          mapRef.current.off("render", handleRender);
        }
      };
    }, [mapRef, viewState, showMarkers]);

    useEffect(() => {
      let aux = [];
      let value = 0;
      const typeColor = OBJECT_CLUSTER_COLOR;
      if (
        mapSettingsState !== undefined &&
        Object.keys(mapSettingsState).length > 0
      ) {
        let newColor = "";
        let optionSelected = null;
        let field = null;
        const {
          objectsColorsSelected,
          fieldsByLibraryObjects,
          userConfigColors,
        } = mapSettingsState;
        const colorsObjectsUserConfig = userConfigColors?.colorObjects;
        if (
          objectsColorsSelected === undefined ||
          colorsObjectsUserConfig === undefined
        ) {
          setPointColorsByMapSettings(aux);
          return;
        }
        // mapear todos los objetos para ir cambiando los colores respecto de sus campos dinamicos
        aux = allObjects.map((object) => {
          const { attributes, id, typeElement } = object;
          const { pointLibraryId } = typeElement;
          const library = fieldsByLibraryObjects?.filter(
            (it) => it.id === pointLibraryId
          )?.[0];
          // configuracion de usuario
          if (userConfigColors.checkedPoints) {
            // objeto con todas las poropiedades del esquema de field
            optionSelected = colorsObjectsUserConfig
              ?.filter(
                (it) => it.id === pointLibraryId // formato {id, name, fields: []}
              )?.[0]
              ?.fields?.filter((it) => it.state)?.[0]; // formato es un campo dinamico
            if (optionSelected !== undefined) {
              // field del punto objeto, tiene el valor buscado
              field = attributes?.filter(
                (atr) => atr.name === optionSelected.name
              )?.[0];
              if (optionSelected?.type === "select") {
                const colorIndex = optionSelected?.options?.indexOf(
                  field?.value
                );
                // const color = optionSelected?.[0]?.optionsColors?.at(colorIndex)
                newColor = getColorFromField({
                  field: optionSelected,
                  colorIndex,
                }); // #000000
              }
              if (optionSelected?.type === "number") {
                // valor del campo numerico seleccionado en el map settings
                const value = getNumericValue(field?.value); // 20 ... 100  ... NaN
                newColor = getColorFromField({
                  field: optionSelected,
                  value,
                  typeColor: typeColor,
                }); // #000000
              }
            }
          }
          // configuracion por defecto
          if (!userConfigColors.checkedPoints) {
            optionSelected = objectsColorsSelected.filter(
              (it) => it[0] === pointLibraryId
            )?.[0]; // tiene esta estructura [ 1, "pointType", "object" ]
            const libraryField = library?.fields?.filter(
              (it) => it.name === optionSelected?.[1]
            )?.[0]; // contiene la estructura definida en Fields.jsx
            // atributos del objeto → filtra el campo dinamico por el escogido en map settings
            field = attributes?.filter(
              (it) => it.name === optionSelected?.[1]
            )?.[0];
            if (libraryField === undefined) {
              newColor = typeColor;
            }
            if (libraryField !== undefined && libraryField?.type === "select") {
              const colorIndex = libraryField?.options?.indexOf(field?.value);
              newColor = getColorFromField({ field: libraryField, colorIndex }); // #000000
            }
            if (libraryField !== undefined && libraryField?.type === "number") {
              // valor del campo numerico seleccionado en el map settings
              value = getNumericValue(field?.value); // 20 ... 100  ... NaN
              newColor = getColorFromField({
                field: libraryField,
                value,
                typeColor,
              }); // #000000
            }
          }
          return {
            id,
            pointLibraryId,
            optionSelected,
            field,
            value,
            newColor,
          };
        });
      }
      setPointColorsByMapSettings(aux);
    }, [allObjects, mapSettingsState]);

    const pointLayer = useMemo(
      () => ({
        id: "point-layer",
        type: "circle",
        source: "markers",
        paint: {
          "circle-radius": 5,
          "circle-color":
            show === "showPoints" ? OBJECT_CLUSTER_COLOR : "rgba(0, 0, 0, 0)",
        },
      }),
      [show]
    );

    return (
      <>
        <Source
          id="markers"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: dataLayer,
          }}
        >
          <Layer {...pointLayer} />
        </Source>
        {showMarkers &&
          show === "showMarkers" &&
          dataLayer.map((elm) => {
            const aux = pointColorsByMapSettings.find(
              (it) => it.id === elm.properties.object.id
            );
            if (aux)
              return renderObjectMarker({
                ...elm.properties.object,
                elementColor: aux.newColor,
              });
            return renderObjectMarker(elm.properties.object);
          })}

        {dataCluster?.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { point_count: pointCount } = cluster.properties;
          const clusterData = {
            id: cluster.id,
            location: {
              latitude,
              longitude,
            },
            pointCount,
            elementsCount: objectsLength,
            type: "object",
          };
          return (
            <MarkerCluster
              cluster={clusterData}
              onSelectPoint={onSelectPoint}
              setViewState={setViewState}
              viewState={viewState}
            />
          );
        })}
      </>
    );
  }
);

export default ObjectsLayer;
