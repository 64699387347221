// React
import React, { useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// Dependecies
import useSWR from "swr";
import { useSWRConfig } from "swr";
// Services
import updateLineMg from "services/updateLineApiMg";
// Hlpers
import { calculateLength } from "./Lines/LinesServices";
// Style
import { ButtonSaveCoordinatesLine } from "./userRoutes";
import SaveAsIcon from "@mui/icons-material/SaveAs";
// Confing
import { config } from "config";
import {
  setCancelDrawing,
  setDrawLineMode,
  setNeuronSelectTool,
  setShowLinePopUp,
} from "redux/actions/digitalTwin";
import { updateLinePg } from "services/lines/updateLine";
import moment from "moment";

function UpdateGeometryCoordinateLines({ setShowSaveLine }) {
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [currentLineType, setCurrentLineType] = useState({});
  const { data: lineTypes, error: errorLineTypes } = useSWR(
    `${config.URL_BACKEND_PG}api/v1/type-lines`
  );

  const updateGeometryLine = useSelector(
    (state) => state.adminReducer.updateGeometryLine
  );

  const controlGl  = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );

  useEffect(() => {
    lineTypes &&
      !errorLineTypes &&
      lineTypes?.forEach((type) => {
        if (type?.id === updateGeometryLine?.properties?.lineTypeId) {
          setCurrentLineType(type);
        }
      });
  }, [lineTypes, updateGeometryLine]);

  const handleUpdateLine = async () => {
    const newFeature = {
      feature: [
        {
          id: updateGeometryLine.id,
          type: updateGeometryLine.type,
          properties: {
            userId: updateGeometryLine.properties.userId,
            lineTypeId: updateGeometryLine.properties.lineTypeId,
            nameTypeLine: updateGeometryLine?.type,
            adminCompanyId: updateGeometryLine.properties.adminCompanyId,
            descriptionTypeLine: currentLineType.description,
            color: currentLineType.color,
            dashArrayWidth: currentLineType.dasharrayWidth,
            dashArrayPixels: currentLineType.dasharrayPixels,
            distance: calculateLength(
              updateGeometryLine.geometry.coordinates,
              "kilometers"
            ),
            collectionDate: updateGeometryLine.properties.collectionDate,
            source: updateGeometryLine.properties.source,
          },
          geometry: updateGeometryLine.geometry,
        },
      ],
      userUpdatedBy: updateGeometryLine.properties.userUpdatedBy,
    };
    setShowSaveLine(false);
    dispatch(setCancelDrawing(true));
    await updateLineMg(updateGeometryLine.properties.idMongo, newFeature);

    const dateUpdate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS Z");
    const indx = dateUpdate.lastIndexOf(":");
    const dateUpdateParsed = dateUpdate
      .substring(0, indx)
      .concat(dateUpdate.substring(indx + 1));
    let body = {
      updatedAt: dateUpdateParsed,
      userUpdatedBy: updateGeometryLine.properties.userUpdatedBy,
    };
    await updateLinePg(body, updateGeometryLine.properties.id);
    dispatch(
      setNeuronSelectTool({
        show: false,
        top: null,
        right: null,
      })
    );
    dispatch(setShowLinePopUp({ id: 0, show: true, event: {}, update: false }));
    dispatch(
      setDrawLineMode({
        mode: "",
        drawStatus: false,
        show: false,
        features: [],
      })
    );
    controlGl.deleteAll();
    mutate(
      `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`
    );
    mutate(`${config.URL_BACKEND_PG}api/v1/type-lines`);
  };

  return (
    <ButtonSaveCoordinatesLine onClick={() => handleUpdateLine()}>
      <SaveAsIcon />
      <div className="container-tooltip">
        <span className="tooltip">Update coordinates line</span>
      </div>
    </ButtonSaveCoordinatesLine>
  );
}

export default UpdateGeometryCoordinateLines;
