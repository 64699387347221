/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect } from "react";
// Map
import {
  Map,
  Marker,
  AttributionControl,
  NavigationControl,
} from "react-map-gl";
import DrawControl from "./DrawControl";
// Style
import Container from "@mui/material/Container";
// Components
import OpenDialog from "components/Dialogs/OpenDialog";
import CreateEvent from "./CreateEvent";
import BaseMapButton from "components/Buttons/BaseMapButton";
import MovePoint from "components/DigitalTwin/MovePoint";
import SaveCoordinateButton from "components/Buttons/SaveCoordinateButton";
// Consts
import fogStyle from "utils/fogStyle";
import { MAPBOX_STYLE } from "utils/constStrings";
// Hooks
import useShowDetails from "hooks/useShowDetails";
import useCreateLinesEventEventsRelation from "hooks/useCreateLinesEventEventsRelation";
import useCreateMarkerParents from "hooks/useCreateMarkerParents";
import useFetchPointTypeEvent from "hooks/useFetchPointTypeEvents";
// Helpers
import { ShowEventMarkers } from "helpers/ShowEventsMarker";
import ShowLinesJoin from "helpers/showLines";
import showMarkers from "helpers/showMarkers";
import { useSelector } from "react-redux";

export default function EventMap({ event, markedEvent, setMovePoint }) {
  const [viewState, setViewState] = useState({
    latitude: event.locationOperation.latitude,
    longitude: event.locationOperation.longitude,
    width: "100%",
    height: "100%",
    zoom: 20,
  });
  const settingMap = useSelector(
    (state) => state.digitalTwinReducer.settingMap
  );
  const navControlStyle = {
    right: 10,
    top: 10,
  };
  const [baseMap, setBaseMap] = useState(
    settingMap?.styleMap
      ? { id: settingMap.styleMap.id, value: settingMap.styleMap.value }
      : {
          id: MAPBOX_STYLE.streets.id,
          value: MAPBOX_STYLE.streets.value,
        }
  );
  const [feature, setFeatures] = useState({});
  const [haveObjects, setHaveObjects] = useState(false);
  // const [markers, setMarkers] = useState([]);
  const [dataCopyCoordinates, setDataCopyCoordinates] = useState({
    lng: "",
    lat: "",
  });
  const locationToMove = localStorage.getItem("newLocationMove");

  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });

  const { data: typeEventsTypeEvents } = useFetchPointTypeEvent({
    id: event.pointTypeEventId,
  });

  // Create marker to parent's event
  const { MarkerParentEvent } = useCreateMarkerParents({ event });

  // Create lines to event relations
  const { eventLines, parentEventLines } = useCreateLinesEventEventsRelation({
    event,
    parentEvent: MarkerParentEvent,
  });

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // Handler click to marker of objects, components and events
  const { handlerClickEventId } = useShowDetails();

  useEffect(() => {
    if (!feature.geometry) return;
    typeEventsTypeEvents &&
      setContentDialog({
        title: "Assign Event",
        description: (
          <CreateEvent
            feature={feature}
            pointEventId={event.id}
            closeModal={setHaveObjects}
            typeEventsTypeEvents={
              typeEventsTypeEvents[0].PointTypeEventRelationWith
            }
          />
        ),
      });
    setHaveObjects(true);
  }, [feature, typeEventsTypeEvents, event.id]);

  const handlePasteCoordinates = () => {
    const newLocationMove = localStorage.getItem("newLocationMove");
    const parseNewLocationMove = JSON.parse(newLocationMove);
    if (parseNewLocationMove) {
      setDataCopyCoordinates({
        lng: parseNewLocationMove.lng || "",
        lat: parseNewLocationMove.lat || "",
      });
    }
  };

  return (
    <Container
      maxWidth="l"
      sx={{
        width: "100%",
        height: "90%",
        padding: "0px 0px 24px 0px",
        position: "relative",
      }}
    >
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        disagree={() => {}}
        content={contentDialog}
        maxWidth={contentDialog.title === "Base Map" ? null : "500px"}
        minWidth={contentDialog.title === "Base Map" ? null : "300px"}
      />
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        style={{ width: "100%", height: 435, margin: "auto" }}
        mapStyle={baseMap.value}
        attributionControl={false}
        initialViewState={{
          latitude: viewState.latitude,
          longitude: viewState.longitude,
          zoom: viewState.zoom,
        }}
        onMove={useCallback((e) => {
          setViewState(e.viewState);
        }, [])}
        projection={"globe"}
        fog={fogStyle}
      >
        <DrawControl handleCreateEvent={setFeatures} />
        <BaseMapButton
          setHaveObjects={setHaveObjects}
          setContentDialog={setContentDialog}
          setBaseMap={setBaseMap}
          baseMap={baseMap}
          position={173}
        />

        {locationToMove !== null ? (
          dataCopyCoordinates.lng === "" && dataCopyCoordinates.lat === "" ? (
            <MovePoint
              from={"details"}
              moveNewLocation={dataCopyCoordinates}
              onPasteCoordinates={handlePasteCoordinates}
            />
          ) : (
            <SaveCoordinateButton
              from={"event"}
              id={event.id}
              longitude={dataCopyCoordinates.lng}
              latitude={dataCopyCoordinates.lat}
              oldLocation={{
                latitude: event.locationOperation.latitude,
                longitude: event.locationOperation.longitude,
              }}
              setDataCopyCoordinates={setDataCopyCoordinates}
              setMovePoint={setMovePoint}
              pointsEventRelations={event?.PointEventRelation}
            />
          )
        ) : (
          <></>
        )}
        <Marker
          key={event.id}
          longitude={event.locationOperation.longitude}
          latitude={event.locationOperation.latitude}
        >
          <div className="marker">
            <img
              src={event.pointTypeEvent.icon}
              alt="marker"
              width={30}
              height={30}
            />
          </div>
        </Marker>
        {dataCopyCoordinates.lng !== "" && dataCopyCoordinates.lat !== "" && (
          <Marker
            latitude={Number(dataCopyCoordinates.lat)}
            longitude={Number(dataCopyCoordinates.lng)}
          >
            <div className="marker">
              <img
                src={event.pointTypeEvent.icon}
                alt="marker"
                width={30}
                height={30}
                style={{
                  opacity: "0.5",
                }}
              />
            </div>
          </Marker>
        )}
        {MarkerParentEvent &&
          event &&
          showMarkers({ markers: MarkerParentEvent })}
        {eventLines && (
          <ShowLinesJoin id={"event-events-lines"} features={eventLines} />
        )}
        {parentEventLines && (
          <ShowLinesJoin id={"marker-event-line"} features={parentEventLines} />
        )}
        {event?.PointEventRelation &&
          ShowEventMarkers(
            event?.PointEventRelation?.filter(
              (point) =>
                point.adminCompanyId === parseInt(adminCompanyId) &&
                point.isGeographic === true ////
            ),
            handlerClickEventId,
            () => {},
            markedEvent
          )}

        <AttributionControl customAttribution="© Decimetrix® 2024" />
        <NavigationControl style={navControlStyle} />
      </Map>
    </Container>
  );
}
