import {
  SHOW_EVENTS_BY_INSPECTION_TYPE,
  SHOW_OPERATIONS_BY_TYPE,
  SHOW_LABELS,
  SET_SHOW_LINES,
  SET_FLY_TO,
  SET_OPERATIONS,
  SET_FILTERED_OPERATIONS,
  SET_SHOW_TRACKINGS,
  SET_DATA_EVENTS,
  SET_TABLE_DETAILS_FORWARD,
  SET_NEURON_SELECT_TOOL,
  DYNAMIC_ROW_NUM,
  GET_TABLE_ROWS_FILTER,
  GET_TABLE_ROWS_FULL,
  OPEN_SELECT_COLUMNS,
  DOWNLOAD_OPTION,
  DATA_NEW_TABLE_WINDOW,
  STATE_OBJECT_FILTER_TABLE,
  STATE_EVENT_FILTER_TABLE,
  STATE_COMPONENT_FILTER_TABLE,
  SET_DATA_COMPONENTS,
  SHOW_DATA_COMPONENTS_LIBRARY,
  SHOW_DATA_COMPONENTS_BY_TYPE,
  SHOW_DATA_COMPONENTS,
  SET_FILTER_COMPONENT,
  SET_FILTER_EVENTS,
  SHOW_EVENTS,
  SHOW_EVENTS_BY_LIBRARY,
  SHOW_EVENTS_BY_TYPE,
  STATE_FILTER_BUTTON_TABLE,
  SHOW_COMPONENT_LABELS,
  SHOW_EVENT_LABEL,
  OPEN_SELECT_ORDER_VIEW_COLUMNS,
  SET_ORIGINAL_ORDER_VIEW_COLUMNS,
  CURRENT_TABLE_VIEW,
  LOAD_DATA_POINTS,
  RELATION_POINT_LINES,
  DATA_LINES,
  CANCEL_DRAWING,
  OBJECTS_LINES_IDS,
  CURRENT_UPDATE_LINE_ID,
  SHOW_LINE_POPUP,
  RESET_STATES,
  SET_DRAWLINE_MODE,
  SETTING_MAP,
  TMP_DISTANCE_FEATURES,
  SET_OBJECT_RELATION_OBJECT,
  MODAL_RELATION_OBJECT,
  POPUP_RELATION_OBJECT,
  INFO_RELATION_OBJECT,
  DATA_RELATION_OBJECT,
  DATABASE_RELATION_OBJECT,
  SEE_OBJECT_CHILD_RELATION,
  INFO_FILTER_DATE,
  LINES_FILTERS,
  SHOW_FILTER_LINES_IN_MAP,
} from "./types";

export const setLineFilters = (payload) => {
  return {
    type: LINES_FILTERS,
    payload,
  };
};

export const setInfoFilterDate = (payload) => {
  return {
    type: INFO_FILTER_DATE,
    payload,
  };
};

export const setSeeObjectChildRelation = (payload) => {
  return {
    type: SEE_OBJECT_CHILD_RELATION,
    payload,
  };
};

export const setDatabaseRelationObject = (payload) => {
  return {
    type: DATABASE_RELATION_OBJECT,
    payload,
  };
};

export const setDataRelationObject = (payload) => {
  return {
    type: DATA_RELATION_OBJECT,
    payload,
  };
};

export const setInfoObjectRelation = (payload) => {
  return {
    type: INFO_RELATION_OBJECT,
    payload,
  };
};

export const setPopUpRelationObject = (payload) => {
  return {
    type: POPUP_RELATION_OBJECT,
    payload,
  };
};
export const setModalRelationObject = (payload) => {
  return {
    type: MODAL_RELATION_OBJECT,
    payload,
  };
};

export const setObjectRelationObject = (payload) => {
  return {
    type: SET_OBJECT_RELATION_OBJECT,
    payload,
  };
};

export const setTmpDistanceFeatures = (payload) => {
  return {
    type: TMP_DISTANCE_FEATURES,
    payload,
  };
};
export const setSettingMap = (payload) => {
  return {
    type: SETTING_MAP,
    payload,
  };
};
export const setDrawLineMode = (payload) => {
  return {
    type: SET_DRAWLINE_MODE,
    payload,
  };
};
export const resetStatesDigitalTwin = (payload) => {
  return {
    type: RESET_STATES,
    payload,
  };
};
export const setShowLinePopUp = (payload) => ({
  type: SHOW_LINE_POPUP,
  payload,
});
export const setCurrentUpdateLineId = (payload) => ({
  type: CURRENT_UPDATE_LINE_ID,
  payload,
});
export const setCancelDrawing = (payload) => ({
  type: CANCEL_DRAWING,
  payload,
});
export const setDataLines = (payload) => ({
  type: DATA_LINES,
  payload,
});
export const setRelationPointLines = (payload) => ({
  type: RELATION_POINT_LINES,
  payload,
});
export const setLoadDataPoints = (payload) => ({
  type: LOAD_DATA_POINTS,
  payload,
});
export const setCurrentTableView = (payload) => ({
  type: CURRENT_TABLE_VIEW,
  payload,
});

export const setOriginalOrderViewColumns = (payload) => ({
  type: SET_ORIGINAL_ORDER_VIEW_COLUMNS,
  payload,
});

export const setOpenSelectOrderViewColumns = (payload) => ({
  type: OPEN_SELECT_ORDER_VIEW_COLUMNS,
  payload,
});

export const setStateFilterButtonTable = (payload) => ({
  type: STATE_FILTER_BUTTON_TABLE,
  payload,
});

export const setFilterEvents = (payload) => ({
  type: SET_FILTER_EVENTS,
  payload,
});

export const setShowEvents = (payload) => ({
  type: SHOW_EVENTS,
  payload,
});

export const setShowEventsByLibrary = (payload) => ({
  type: SHOW_EVENTS_BY_LIBRARY,
  payload,
});

export const showEventsByType = (payload) => ({
  type: SHOW_EVENTS_BY_TYPE,
  payload,
});

export const setFilterComponent = (payload) => ({
  type: SET_FILTER_COMPONENT,
  payload,
});
export const setShowDataComponents = (payload) => ({
  type: SHOW_DATA_COMPONENTS,
  payload,
});
export const setShowDataComponentsByType = (payload) => ({
  type: SHOW_DATA_COMPONENTS_BY_TYPE,
  payload,
});

export const setShowDataComponentsLibrary = (payload) => ({
  type: SHOW_DATA_COMPONENTS_LIBRARY,
  payload,
});
export const setDataComponents = (payload) => ({
  type: SET_DATA_COMPONENTS,
  payload,
});
export const setStateComponentFilterTable = (payload) => ({
  type: STATE_COMPONENT_FILTER_TABLE,
  payload,
});
export const setStateObjectFilterTable = (payload) => ({
  type: STATE_OBJECT_FILTER_TABLE,
  payload,
});
export const setStateEventFilterTable = (payload) => ({
  type: STATE_EVENT_FILTER_TABLE,
  payload,
});
export const dataNewTableWindow = (payload) => ({
  type: DATA_NEW_TABLE_WINDOW,
  payload,
});
export const setDownloadOption = (payload) => ({
  type: DOWNLOAD_OPTION,
  payload,
});
export const openSelectColumns = (payload) => ({
  type: OPEN_SELECT_COLUMNS,
  payload,
});
export const getTableRowsFull = (payload) => ({
  type: GET_TABLE_ROWS_FULL,
  payload,
});
export const getTableRowsFilter = (payload) => ({
  type: GET_TABLE_ROWS_FILTER,
  payload,
});
export const setRowNum = (payload) => ({
  type: DYNAMIC_ROW_NUM,
  payload,
});

export const setShowTrackings = (payload) => ({
  type: SET_SHOW_TRACKINGS,
  payload,
});

export const setOperations = (payload) => ({
  type: SET_OPERATIONS,
  payload,
});

export const setFilteredOperations = (payload) => ({
  type: SET_FILTERED_OPERATIONS,
  payload,
});

export const setShowLines = (payload) => ({
  type: SET_SHOW_LINES,
  payload,
});

export const setFlyTo = (payload) => ({
  type: SET_FLY_TO,
  payload,
});

export const showEventsByInspectionType = (payload) => ({
  type: SHOW_EVENTS_BY_INSPECTION_TYPE,
  payload,
});

export const showLabels = (payload) => ({
  type: SHOW_LABELS,
  payload,
});

export const showComponentLabels = (payload) => ({
  type: SHOW_COMPONENT_LABELS,
  payload,
});

export const showEventLabels = (payload) => ({
  type: SHOW_EVENT_LABEL,
  payload,
});

export const showOperationsByType = (payload) => ({
  type: SHOW_OPERATIONS_BY_TYPE,
  payload,
});

export const setDataEvents = (payload) => ({
  type: SET_DATA_EVENTS,
  payload,
});

export const setTableDetailsForward = (payload) => ({
  type: SET_TABLE_DETAILS_FORWARD,
  payload,
});

export const setNeuronSelectTool = (payload) => ({
  type: SET_NEURON_SELECT_TOOL,
  payload,
});

export const setObjecstsLinesIds = (payload) => ({
  type: OBJECTS_LINES_IDS,
  payload,
});

export const showFilterLinesInMap = (payload) => ({
  type: SHOW_FILTER_LINES_IN_MAP,
  payload,
});
