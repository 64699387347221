import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  FormContainer,
  WrapperForm,
  Form,
  ContainerButtonRegister,
  Button,
  MessageError,
  MessageSuccess,
} from "components/Forms/FormStyles";

import InputComponent from "components/Forms/Input";
import { useUserStore } from "zustandGloabalStore/user";
import saveAdminCompany from "services/users/saveAdminCompany";
import { useSWRConfig } from "swr";
import { urls } from "utils/urlKeys";

const FormSignUp = ({ handleClose }) => {
  const { mutate } = useSWRConfig();

  const [firstName, setFirstName] = useState({ field: "", valid: null });
  const [secondName, setSecondName] = useState({ field: "", valid: null });
  const [firstLastName, setFirstLastName] = useState({
    field: "",
    valid: null,
  });
  const [email, setEmail] = useState({ field: "", valid: null });
  const [area, setArea] = useState({ field: "", valid: null });
  const [project, setProject] = useState({ field: "", valid: null });
  const [FormValidated, setFormValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const { adminDecimetrixId, instanceManager } = useUserStore((state) => state);

  const expressions = {
    firstName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    firstLastName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    secondName: /^[a-zA-ZÀ-ÿ]{0,40}$/, // letters only
    area: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    project: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    password: /^.{8,60}$/, // 8 a 60 characters.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //check the request fields filled

    //array to store the names of the fields that are not filled

    const missingFields = [];

    //check if the fields are filled
    if (!firstName.field) {
      missingFields.push("First Name");
    }
    if (!firstLastName.field) {
      missingFields.push("Last Name");
    }
    if (!email.field) {
      missingFields.push("Email");
    }

    if (!area.field) {
      missingFields.push("Area");
    }
    if (!project.field) {
      missingFields.push("Project");
    }

    //build the message with the missing fields

    let errorMessage = "";
    if (missingFields.length > 0) {
      errorMessage = `THE FOLLOWING FIELDS ARE REQUIRED: ${missingFields.join(
        " - "
      )}`;
    }

    //update messageError
    setMessageError(errorMessage);

    if (errorMessage) {
      setFormValidated(false);
      setLoading(false);
      return;
    }

    if (
      firstName.valid === "true" &&
      firstLastName.valid === "true" &&
      email.valid === "true" &&
      area.valid === "true" &&
      project.valid === "true"
    ) {
      const body = {
        firstName: firstName.field,
        secondName: secondName.field,
        firstLastName: firstLastName.field,
        area: area.field,
        project: project.field,
        adminDecimetrixId: adminDecimetrixId,
        user: {
          email: email.field,
        },
        company: {
          name: instanceManager.name,
        },
        instanceId: instanceManager.id,
      };

      try {
        const { res } = await saveAdminCompany(body);
        await mutate(urls.users.getAdminCompanyByInstance(instanceManager.id));
        setLoading(false);

        if (res.status === 409) {
          setFormValidated(false);
          const errorMessage = res.data?.message || "This email is already registered";
          setMessageError(errorMessage);
        } else if (res.status === 200 || res.status === 201) {
          handleClose();
          setFormValidated(true);
          setMessageError(null);
          setFirstName({ field: "", valid: null });
          setSecondName({ field: "", valid: null });
          setFirstLastName({ field: "", valid: null });
          setEmail({ field: "", valid: null });
          setArea({ field: "", valid: null });
          setProject({ field: "", valid: null });
        } else {
          setFormValidated(false);
          setMessageError("Fill all the fields correctly.");
        }
      } catch (error) {
        console.error("Error saving admin company:", error);

        // Manejo de errores específicos
        if (error.response && error.response.status === 409) {
          const errorMessage = error.response.data?.message || "This email is already registered";
          setMessageError(errorMessage);
        } else {
          setMessageError("An unexpected error occurred while saving the admin company.");
        }

        setLoading(false);
        setFormValidated(false);
      }
    } else {
      setFormValidated(false);
      setMessageError("Fill all the fields correctly.");
    }
  };

  //que las box sean responsive
  return (
    <>
      <br></br>
      <FormContainer>
        <WrapperForm style={{ marginTop: "1rem" }}>
          <Form action="" onSubmit={onSubmit}>
            <>
              <>
                <InputComponent
                  state={firstName}
                  changeState={setFirstName}
                  type="text"
                  label="First Name *"
                  name="firstName"
                  legendError="The name can only contain letters."
                  regularExpression={expressions.firstName}
                />
                <InputComponent
                  state={secondName}
                  changeState={setSecondName}
                  type="text"
                  label="Second Name"
                  name="secondName"
                  legendError="The name can only contain letters."
                  regularExpression={expressions.secondName}
                />
                <InputComponent
                  state={firstLastName}
                  changeState={setFirstLastName}
                  type="text"
                  label="Last Name *"
                  name="firstLastName"
                  legendError="The last name can only contain letters."
                  regularExpression={expressions.firstLastName}
                />
                <InputComponent
                  state={email}
                  changeState={setEmail}
                  type="email"
                  label="E-mail *"
                  placeholder="email@email.com"
                  name="email"
                  legendError="Email can only contain letters, numbers, periods, hyphens, and underscores."
                  regularExpression={expressions.email}
                />
                <InputComponent
                  state={area}
                  changeState={setArea}
                  type="text"
                  label="Area *"
                  placeholder=""
                  name="area"
                  legendError="The area can only contain letters."
                  regularExpression={expressions.area}
                />
                <InputComponent
                  state={project}
                  changeState={setProject}
                  type="project"
                  label="Project *"
                  placeholder=""
                  name="project"
                  legendError="The project can only contain letters."
                  regularExpression={expressions.project}
                />
              </>
            </>
          </Form>

          <>
            <ContainerButtonRegister>
              <Button type="submit" onClick={onSubmit}>
                Register
              </Button>
              {loading && (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
            </ContainerButtonRegister>
          </>

          {FormValidated ? (
            <MessageSuccess>
              <p>User saved successfully</p>
            </MessageSuccess>
          ) : messageError !== "" ? (
            <MessageError>
              <p>{messageError}</p>
            </MessageError>
          ) : null}
        </WrapperForm>
      </FormContainer>
    </>
  );
};

export default FormSignUp;
