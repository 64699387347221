import * as turf from "@turf/turf";

export const handleExtendView = ({ array, map }) => {
  // Extraer todas las coordenadas de las líneas
  const features =
    array.length !== 0
      ? array.map((obj) => {
          if (obj?.hasOwnProperty("type") && obj.type === "Feature") {
            return obj.geometry.coordinates; // Mantener las coordenadas en pares [lng, lat]
          } else {
            return [];
          }
        })
      : [];

  // Aplanar las coordenadas en un solo array de puntos
  const allCoordinates = features.flat();

  // Verificar que existan coordenadas
  if (allCoordinates.length === 0) return;

  // Crear una colección de puntos a partir de las coordenadas (cada punto debe ser [lng, lat])
  const points = turf.points(allCoordinates);

  // Obtener el sobre (bounding box) que cubre todas las coordenadas
  const envelopeFeatures = turf.envelope(points);

  // Ajustar el mapa a los límites del bounding box
  map?.getMap().fitBounds(envelopeFeatures.bbox, {
    padding: 100,
    duration: 500,
  });
};
