// React
import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { useEffect, useState } from "react";
// Redux
import { setFeaturesDetailsDT } from "redux/actions/admin";
import { setObjectsUnderDrawnPoint } from "redux/actions/index";
import { useDispatch } from "react-redux";
// Components
import TextInput from "../GenericInput";
import SelectInput from "../SelectInput";
// Custom hooks
import useGet from "hooks/useFetch";
// Style
import { Form } from "./DynamicFieldsStyle";
import { StepSection } from "../Events/DynamicEventStyles";
// Services
import saveObject from "services/objects/saveObject";
import bodyObjectWithFiles from "services/objects/createBodyObject";
import createObjectRelation from "services/objects/createObjectRelaction";
// Config
import useSwr from "swr";
import { config } from "config.js";
// Hooks
import CustomButton from "components/Buttons/CustomButton";
import { updateAttributesObjectMg } from "services/updateAttributesMg";
import CircularIndeterminate from "components/Lodings/LoadingV2";
import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import updateObjectPg from "services/updateObjectPg";
import { urlKeys } from "utils/urlKeys";
import { LineConteiner2 } from "pages/ConfigAdmin/typeElemts/FieldsStyle";

function Formulario({
  feature,
  objectId,
  closeModal,
  typeObjectRelationId,
  genericData,
  dataObject,
  update = false,
  setOpenDialog,
  handleUpdateData,
}) {
  // States
  const [typeElemetFields, setTypeElementFields] = useState();
  const [loading, setLoading] = useState(false);
  const [globalTableCategories, setGlobalTableCategories] = useState({});
  const [form, setForm] = useState({});

  // Custom hooks
  const [typeElement, errorTypeElement] = useGet(
    `api/v1/type-elements?id=${
      genericData?.point?.typeElementId ||
      dataObject?.typeElementId ||
      typeObjectRelationId
    }`
  );

  const { data: dataLibrary, error: errorLibrary } = useFetchObjectLibraries({
    id: dataObject?.typeElement?.pointLibraryId,
  });
  // Swr
  const { mutate } = useSWRConfig();
  // Redux
  const dispatch = useDispatch();
  // Forms
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  // Local storage
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/admin-company/operators/${adminCompanyId}`
  );
  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];

  //Get Data Global Tables
  const urlGlobalTables = `${config.URL_BACKEND_MG}global-values-fields?adminCompanyId=${adminCompanyId}`;
  const { data: globalTables, error: errorGlobalTables } =
    useSwr(urlGlobalTables);

  const handleChangeGlobalSelects = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    setForm({ ...form, [name]: value });
  };

  // Effects
  useEffect(() => {
    if (globalTables && !errorGlobalTables && globalTables.length > 0) {
      setGlobalTableCategories(globalTables[0].tableCategories);
    }
  }, [globalTables, errorGlobalTables]);

  useEffect(() => {
    typeElement && !errorTypeElement && setTypeElementFields(typeElement[0]);
  }, [typeElement, errorTypeElement]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericData?.point?.typeElementId]);

  useEffect(() => {
    if (update) {
      const existingFieldNames = dataObject?.attributes?.map(
        (attribute) => attribute.name
      );
      !errorLibrary &&
        dataLibrary[0]?.fields?.forEach((field) => {
          if (
            !existingFieldNames.includes(field.name) &&
            field.type !== "notification" &&
            field.type !== "captureMedia" &&
            field.type !== "lineTag"
          ) {
            const newAttribute = {
              name: field.name,
              alias: field.alias,
              value: "",
            };
            dataObject.attributes.push(newAttribute);
          }
        });

      if (dataObject) {
        dataObject?.attributes?.forEach((elm) => {
          if (elm.value?.[0] === "{") {
            const dataParse = JSON.parse(elm.value);
            if (dataParse.type === "number" && dataParse.value) {
              return setValue(elm.name, parseFloat(dataParse.value));
            }
          } else {
            if (elm.value === "true" || elm.value === "false") {
              return setValue(elm.name || elm.alias, JSON.parse(elm.value));
            }
            const value =
              elm?.value?.length === 0 || elm?.value === " "
                ? ""
                : !isNaN(elm.value)
                ? parseFloat(elm.value)
                : elm.value;
            return setValue(elm.name, value);
          }
        });
      }
    }
  }, [dataObject]);

  useEffect(() => {
    if (typeElemetFields && typeElemetFields.fields.length !== 0) {
      const selectGlobal = typeElemetFields.fields.filter((elm) => {
        return (
          elm.type === "select" &&
          elm.globalSelect &&
          !elm.hasOwnProperty("columnKeyGlobalSpecs")
        );
      });
      typeElemetFields.fields.forEach((elm) => {
        if (
          (elm.type === "string" || elm.type === "number") &&
          elm.globalSelect
        ) {
          const matchSelect = selectGlobal.find((item) => {
            return (
              elm?.columnKeyGlobalSpecs?.selectGlobalId === item._id ||
              elm?.columnKeyGlobalSpecs?.selectGlobalName === item.name
            );
          });
          const findCategory = globalTableCategories?.find((item) => {
            return elm.columnKeyGlobalSpecs.categoryId === item._id;
          });

          const matchTbale = findCategory?.groupTables?.find((item) => {
            return elm.columnKeyGlobalSpecs.tableId === item._id;
          });

          const row = matchTbale?.rows?.find((row) => {
            return (
              row?.Options ===
              getValues(matchSelect?.name || matchSelect?.alias)
            );
          });

          if (row) {
            return setValue(
              elm?.name || elm?.alias,
              row[elm.columnKeyGlobalSpecs.column.name]
            );
          }
        }
      });
    }
  }, [handleChangeGlobalSelects, typeElemetFields]);

  // Method
  const onSubmit = async (data) => {
    setLoading(true);
    if (update) {
      const numericFields = await typeElemetFields.fields
        ?.map((field) => {
          if (field.type === "number") {
            const elmt = document.getElementById(field.name);
            if (elmt) {
              field.value = elmt.value;
              return field;
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = await typeElemetFields.fields?.filter(
        (field) => field.type === "operation"
      );

      if (numericFields.length > 0)
        numericFields.forEach((fld) => {
          fld.value = fld.value === "" ? NaN : fld.value;
          data[fld.name] = JSON.stringify(fld);
        });
      if (expFields.length > 0)
        expFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });

      const entries = Object.entries(data);
      let attributesWithValues = dataObject.attributes.map((elm) => {
        for (const [key, value] of entries) {
          if (key === elm.name || key === elm.alias) {
            return { ...elm, value: value };
          }
        }
      });

      const fileFields = typeElemetFields.fields.filter(
        (field) => field.type === "file"
      );

      const genericData = {
        adminCompanyId: adminCompanyId,
        location: {
          latitude: dataObject.location.latitude,
          longitude: dataObject.location.longitude,
        },
        typeElementId: dataObject.typeElementId,
        userId: dataObject.userId,
      };

      const bodyObject = await bodyObjectWithFiles(
        fileFields,
        data,
        genericData
      );

      // add alias to every field, if exists
      let tmp = {};
      bodyObject?.attributes.map((item, index) => {
        tmp = attributesWithValues?.find((it) => it.name === item.name);
        if (tmp) {
          bodyObject.attributes[index].alias = tmp?.alias || "";
          bodyObject.attributes[index]._id = tmp?._id || null;
        }
        return item;
      });

      const attributes = [...bodyObject.attributes];

      const newAttributes = attributes.filter((attribute) =>
        typeElemetFields.fields.some(
          (field) => field.name === attribute.name && attribute.alias
        )
      );

      await updateAttributesObjectMg(dataObject.mongoId, newAttributes);
      const userId = localStorage.getItem("userId");
      await updateObjectPg(
        { lastUpdate: new Date(), userUpdateId: userId },
        dataObject.id
      );

      mutate(`${config.URL_BACKEND_PG}api/v1/objects/${dataObject.id}`);
      mutate(urlKeys.objects);

      handleUpdateData();
      setOpenDialog(false);
    } else {
      //get all numeric fields from the form
      const numericFields = await typeElemetFields.fields
        ?.map((field) => {
          if (field.type === "number") {
            const elmt = document.getElementById(field.name);
            if (elmt) {
              field.value = elmt.value;
              return field;
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = await typeElemetFields.fields?.filter(
        (field) => field.type === "operation"
      );

      if (numericFields.length > 0)
        numericFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });
      if (expFields.length > 0)
        expFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });

      const fileFields = typeElemetFields.fields.filter(
        (field) => field.type === "file"
      );

      let bodyObject = null;
      if (typeObjectRelationId) {
        const userId = localStorage.getItem("userId");
        const { coordinates } = feature.geometry;
        const [lng, lat] = coordinates;
        const point = {
          userId: Number(userId),
          location: {
            latitude: lat,
            longitude: lng,
          },
          adminCompanyId: adminCompanyId,
          typeElementId: typeObjectRelationId,
        };
        const genericData = {
          point: point,
          regionIds: [],
        };
        bodyObject = await bodyObjectWithFiles(fileFields, data, genericData);
      } else {
        bodyObject = await bodyObjectWithFiles(fileFields, data, genericData);
      }

      // add alias to every field, if exists
      let tmp = {};
      bodyObject?.attributes.map((item, index) => {
        tmp = typeElemetFields?.fields.find((it) => it.name === item.name);
        if (tmp) bodyObject.attributes[index].alias = tmp?.alias || "";
        return item;
      });

      const attributes = [...bodyObject.attributes];

      const newAttributes = attributes.filter((attribute) =>
        typeElemetFields.fields.some(
          (field) => field.name === attribute.name && attribute.alias
        )
      );

      const newBody = {
        ...bodyObject,
        attributes: newAttributes,
      };

      let res = null;
      if (typeObjectRelationId) {
        res = await saveObject(newBody);

        const resRelation = await createObjectRelation({
          existObjectId: objectId,
          newObjectId: res?.id,
        });

        if (resRelation) {
          closeModal(false);
          mutate(`${config.URL_BACKEND_PG}api/v1/objects/${objectId}`);
        }
      } else {
        res = await saveObject(
          {
            point: newBody,
            regionIds: genericData?.regionIds,
          },
          "objects-region"
        );
      }
      if (res) {
        mutate(
          `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`
        );
        dispatch(
          setFeaturesDetailsDT({
            geometry: {
              type: null,
            },
          })
        );
        dispatch(setObjectsUnderDrawnPoint([]));
      }
    }
    setLoading(false);
    reset();
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    setForm({ ...form, [name]: value });
  };

  if (typeElemetFields === undefined) {
    return <CircularIndeterminate />;
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {typeElemetFields && (
        <div>
          {typeElemetFields.fields?.map((field, index) => {
            if (field.type === "operation") {
              <TextInput
                key={index}
                field={field}
                register={register}
                errors={errors}
              />;
            }
            if (field.type === "select" && field.globalSelect) {
              const currentCategory = globalTableCategories.find((category) => {
                return category._id === field.globalTable.categoryId;
              });
              const selectTable = currentCategory.groupTables.find((table) => {
                return table._id === field.globalTable.tableId;
              });

              return (
                <div key={index} onChange={(e) => handleChangeGlobalSelects(e)}>
                  <SelectInput
                    key={index}
                    field={{
                      name: field.name,
                      options: selectTable.rows.map((elm) => elm.Options),
                      required: field.required,
                      type: field.type,
                      alias: field.alias || field.name,
                    }}
                    register={register}
                  />
                </div>
              );
            }
            if (field.type === "select") {
              return (
                <SelectInput key={index} field={field} register={register} />
              );
            }
            if (field.type === "currency") {
              return (
                <div key={index}>
                  <TextInput
                    field={field}
                    register={register}
                    errors={errors}
                  />
                  <SelectInput field={field} register={register} />
                </div>
              );
            }
            if (field.type === "range") {
              const step =
                field.options.length !== 0 ? parseInt(field.options[0]) : 1;
              if (Object.keys(form).length === 0 || form[field.name] === "") {
                if (!update) {
                  const middleValue =
                    Math.ceil((field.max - field.min) / 2) + field.min;
                  form[field.name] = middleValue;
                }
              }
              return (
                <div key={index} onChange={(e) => handleChange(e)}>
                  <TextInput
                    key={index}
                    field={{
                      name: field.name,
                      alias: field.alias,
                      required: field.required,
                      type: field.type,
                      step: step,
                      max: field.max,
                      min: field.min,
                    }}
                    register={register}
                    errors={errors}
                  />
                  <StepSection>
                    <b>Step: {step}</b>
                    <b>
                      {form[field.name] ? form[field.name] : field.min + step}
                    </b>
                  </StepSection>{" "}
                </div>
              );
            }
            if (field.type === "delegate") {
              if (
                form[field.name] === "" &&
                operatorsAdmin &&
                !errorOperators
              ) {
                form[
                  field.name
                ] = `${allOperators.operators[0].firstName} ${allOperators.operators[0].firstLastName}`;
              }
              return (
                <div onChange={(e) => handleChange(e)}>
                  <SelectInput
                    key={index}
                    field={{
                      name: field.name,
                      options: allOperators.operators?.map((operator) => {
                        return `${operator?.firstName} ${operator?.firstLastName}`;
                      }),
                      required: field.required,
                      type: field.type,
                      alias: field.alias || field.name,
                    }}
                    register={register}
                  />
                </div>
              );
            }
            if (field.type === "lineTag") {
              return (
                <LineConteiner2>
                  <hr />
                  <h3>{field.alias}</h3>
                  <hr />
                </LineConteiner2>
              );
            }

            if (field.type === "file") {
              return (
                <div onChange={(e) => handleChange(e)} key={index}>
                  <TextInput
                    field={{
                      name: field.name,
                      required: update ? false : field.required,
                      type: field.type,
                      possible: field.possible,
                      alias: field.alias || field.name,
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
              );
            }
            if (field.type === "captureMedia") return null;
            if (field.type === "notification") return null;
            if (field.type === "lineTag") return null;
            return (
              <TextInput
                key={index}
                field={field}
                register={register}
                errors={errors}
              />
            );
          })}
        </div>
      )}
      <CustomButton
        type={"submit"}
        isLoad={loading}
        text={update ? "Update" : "Save"}
        margin={0}
      />
    </Form>
  );
}

export default Formulario;
