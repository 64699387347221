import { config } from "config";
import useGet from "hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMapSettings } from "redux/actions";
import {
  createBackendMapSettings,
  updateBackendMapSettings,
} from "services/updateBackendMapSettings";
import useSWR from "swr";

export default function MapSettingsConfigurations() {
  const dispatch = useDispatch();
  // librerias por objs
  const [pointLibrariesObjects, setPointLibrariesObjects] = useState([]);
  const [pointLibrariesComponents, setPointLibrariesComponents] = useState([]);
  const [pointLibrariesEvents, setPointLibrariesEvents] = useState([]);
  // campos por tipo de libreria (objs)
  const [fieldsByLibraryObjects, setFieldsByLibraryObjects] = useState([]);
  const [fieldsByLibraryComponents, setFieldsByLibraryComponents] = useState(
    []
  );
  const [fieldsByLibraryEvents, setFieldsByLibraryEvents] = useState([]);
  // http requests
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const url = `${config.URL_BACKEND_MG}map-settings?adminCompanyId=${adminCompanyId}&userId=${userId}`;
  const { data: mapSettings, error: errorMapSettings } = useSWR(url);

  const urlQuery = new URLSearchParams();
  urlQuery.append("adminCompanyId", adminCompanyId);

  const [libraries, librariesError] = useGet(
    `api/v1/line-libraries?${urlQuery.toString()}`
  );
  const [librariesObjects, librariesObjectsError] = useGet(
    `api/v1/point-libraries?${urlQuery.toString()}`
  );
  const [librariesComponents, librariesComponentsError] = useGet(
    `api/v1/point-library-components?${urlQuery.toString()}`
  );
  const [librariesEvents, librariesEventsError] = useGet(
    `api/v1/point-library-events?${urlQuery.toString()}`
  );

  const defaultColorOptions = useCallback((lib = [], error, type, libType) => {
    const response =
      error === undefined && type.length > 0 && libType.length > 0
        ? lib.map((lb) => {
            if (libType === "line") {
              return [lb.id, type];
            }
            return [lb.id, type, libType];
          })
        : [];
    return response;
  }, []);

  useEffect(() => {
    if (
      librariesObjects &&
      !librariesObjectsError &&
      librariesObjects?.length >= 0
    ) {
      setPointLibrariesObjects(librariesObjects);
    }
    if (
      librariesComponents &&
      !librariesComponentsError &&
      librariesComponents?.length >= 0
    ) {
      setPointLibrariesComponents(librariesComponents);
    }
    if (
      librariesEvents &&
      !librariesEventsError &&
      librariesEvents?.length >= 0
    ) {
      setPointLibrariesEvents(librariesEvents);
    }
  }, [
    librariesObjects,
    librariesObjectsError,
    librariesComponents,
    librariesComponentsError,
    librariesEvents,
    librariesEventsError,
  ]);

  useEffect(() => {
    const auxObjects = [];
    pointLibrariesObjects.forEach((ln) => {
      auxObjects.push(parseLibrary(ln, ["select", "number"]));
    });
    setFieldsByLibraryObjects(auxObjects);

    const auxComponents = [];
    pointLibrariesComponents.forEach((ln) => {
      auxComponents.push(parseLibrary(ln, ["select", "number"]));
    });
    setFieldsByLibraryComponents(auxComponents);

    const auxEvents = [];
    pointLibrariesEvents.forEach((ln) => {
      auxEvents.push(parseLibrary(ln, ["select", "number"]));
    });
    setFieldsByLibraryEvents(auxEvents);
  }, [pointLibrariesObjects, pointLibrariesComponents, pointLibrariesEvents]);

  useEffect(() => {
    if (adminCompanyId && userId) {
      const setMapConfig = async () => {
        const hasMapSettings =
          mapSettings !== undefined && mapSettings.length > 0;
        const isDynamicColorsEmpty =
          mapSettings[0]?.dynamicColorsSelected?.length === 0 ||
          mapSettings[0]?.dynamicColorsSelected === undefined;
        const isObjectsColorsEmpty =
          mapSettings[0]?.objectsColorsSelected?.length === 0 ||
          mapSettings[0]?.objectsColorsSelected === undefined;
        const isComponentsColorsEmpty =
          mapSettings[0]?.componentsColorsSelected?.length === 0 ||
          mapSettings[0]?.componentsColorsSelected === undefined;
        const isEventsColorsEmpty =
          mapSettings[0]?.eventsColorsSelected?.length === 0 ||
          mapSettings[0]?.eventsColorsSelected === undefined;
        const isMeasurementUnit =
          mapSettings[0]?.measurementUnit === undefined ||
          mapSettings[0]?.measurementUnit === null;
        // map config exists on DB so setter in mapSettingsState
        if (
          hasMapSettings &&
          !errorMapSettings &&
          !isDynamicColorsEmpty &&
          !isObjectsColorsEmpty &&
          !isComponentsColorsEmpty &&
          !isEventsColorsEmpty &&
          !isMeasurementUnit
        ) {
          dispatch(
            setMapSettings({
              ...mapSettings[0],
              libraries,
              librariesObjects,
              librariesEvents,
              librariesComponents,
              fieldsByLibraryObjects,
              fieldsByLibraryComponents,
              fieldsByLibraryEvents,
            })
          );
          return;
        }

        if (
          (hasMapSettings && !errorMapSettings) ||
          isDynamicColorsEmpty ||
          isObjectsColorsEmpty ||
          isComponentsColorsEmpty ||
          isEventsColorsEmpty ||
          isMeasurementUnit
        ) {
          const hasLibraries =
            (libraries !== undefined && libraries.length > 0) ||
            (librariesObjects !== undefined && librariesObjects.length !== 0) ||
            (librariesComponents !== undefined &&
              librariesComponents.length !== 0) ||
            (librariesEvents !== undefined && librariesEvents !== 0);

          if (hasMapSettings && hasLibraries) {
            //update empty arrays on DB
            let body = {
              adminCompanyId,
              userId,
            };

            if (isDynamicColorsEmpty) {
              body.dynamicColorsSelected = defaultColorOptions(
                libraries,
                librariesError,
                "lineType",
                "line"
              );
            }
            if (isObjectsColorsEmpty) {
              body.objectsColorsSelected = defaultColorOptions(
                librariesObjects,
                librariesObjectsError,
                "pointType",
                "object"
              );
            }
            if (isComponentsColorsEmpty) {
              body.componentsColorsSelected = defaultColorOptions(
                librariesComponents,
                librariesComponentsError,
                "pointType",
                "component"
              );
            }
            if (isEventsColorsEmpty) {
              body.eventsColorsSelected = defaultColorOptions(
                librariesEvents,
                librariesEventsError,
                "pointType",
                "event"
              );
            }

            if (isMeasurementUnit) {
              body.measurementUnit = {
                symbol: "km",
                unit: "kilometers",
              };
            }

            await updateBackendMapSettings({
              id: userId,
              body,
            });

            dispatch(
              setMapSettings({
                ...body,
                libraries,
                librariesObjects,
                librariesEvents,
                librariesComponents,
                fieldsByLibraryObjects,
                fieldsByLibraryComponents,
                fieldsByLibraryEvents,
              })
            );

            return;
          }
        }

        if (
          // map config doesn't exist so create one and set it to mapSettingsState and redux mapSettings
          mapSettings === undefined ||
          mapSettings.length === 0
        ) {
          const body = {
            adminCompanyId,
            userId,
            dynamicColorsSelected: defaultColorOptions(
              libraries,
              librariesError,
              "lineType",
              "line"
            ),
            objectsColorsSelected: defaultColorOptions(
              librariesObjects,
              librariesObjectsError,
              "pointType",
              "object"
            ),
            componentsColorsSelected: defaultColorOptions(
              librariesComponents,
              librariesComponentsError,
              "pointType",
              "component"
            ),
            eventsColorsSelected: defaultColorOptions(
              librariesEvents,
              librariesEventsError,
              "pointType",
              "event"
            ),
            measurementUnit: { unit: "kilometers", symbol: "km" },
          };
          // creates mapSettings config on fist instance
          const { data } = await createBackendMapSettings({
            body,
          });
          dispatch(
            setMapSettings({
              ...data,
            })
          );
          return;
        }
      };

      libraries !== undefined &&
        librariesObjects !== undefined &&
        librariesComponents !== undefined &&
        librariesEvents !== undefined &&
        (fieldsByLibraryObjects.length ||
          fieldsByLibraryComponents.length ||
          fieldsByLibraryEvents.length) &&
        setMapConfig();
    }
  }, [
    userId,
    adminCompanyId,
    dispatch,
    defaultColorOptions,
    errorMapSettings,
    mapSettings,
    libraries,
    librariesError,
    librariesComponents,
    librariesComponentsError,
    librariesEvents,
    librariesEventsError,
    librariesObjects,
    librariesObjectsError,
    fieldsByLibraryObjects,
    fieldsByLibraryComponents,
    fieldsByLibraryEvents,
  ]);

  return <></>;
}

function parseLibrary(item, types = []) {
  if (item === undefined || !types.length) return null;
  const fields =
    item.fields !== undefined
      ? item.fields.filter((fd) => types.includes(fd.type) && fd.circuit)
      : [];
  return {
    id: item.lineLibraryId || item.id,
    name: item.name,
    fields,
  };
}
