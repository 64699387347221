// React
import React from "react";
import { useEffect, useMemo, useState } from "react";
// Redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  setStateObjectFilterTable,
  setStateEventFilterTable,
  setTableDetailsForward,
  showEventsByInspectionType,
  showEventsByType,
  showOperationsByType,
  setFilterComponent,
  setShowDataComponentsByType,
  setShowDataComponentsLibrary,
  setShowDataComponents,
  setShowEvents,
  setShowEventsByLibrary,
} from "redux/actions/digitalTwin";
import {
  setComponentsFilterTable,
  setDataEventFilterTable,
  setObjectsFilterTable,
  setShowDataObjects,
  showMarkerByElementWithMedia,
  showMarkerseByTypeElement,
  showMarkerseByTypeObject,
} from "redux/actions/admin";
// Components
import TableObjects from "./TableObjects";
import TableEvents from "./TableEvents";
import GenericSplitButton from "components/Buttons/GenericSplitButton";
import TableComponents from "./TableComponents";
// Hooks
import useGet from "hooks/useFetch";
import {
  useFetchObjectLibraries,
  useFetchEventLibraries,
  useFetchComponentsLibraries,
} from "hooks/fetchLibraries";

// Dependencies
import Select from "react-select";
import { writeFile, utils } from "xlsx";
// Styles
import { Container, customStyles, customStylesLibraries } from "./styles";
import { AiOutlineClose } from "react-icons/ai";
import { PRIMARY_COLOR } from "utils/const";
import TableLineObjects from "./TableLineObjects";
import getEventPDFInfo from "services/getEventPDFInfo";
import OpenDialog from "components/Dialogs/OpenDialog";
import ReportContainer from "pages/Dashboard/pdfCreator/ReportContainer";
import { CircularProgress } from "@mui/material";
const options = [
  "Export to",
  "Full xslx",
  "Full csv",
  "Full pdf",
  "Filtered xlsx",
  "Filtered csv",
  "Filtered pdf",
];

function setFiltersEventsToTrue(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      obj[prop] = true;
    }
  }
  return obj;
}

function setFilterTrue(component) {
  const components = component.map((elm) => {
    return { ...elm, color: PRIMARY_COLOR, state: true };
  });
  return components;
}

const PolygonTable = React.memo(
  ({
    setSeePolygonTable,
    state = false,
    handleDrawerClose,
    handleDrawerOpen,
    visibilityGenericTable,
    setVisibilityGenericTable,
    handlerVisibiltyTable,
  }) => {
    const dataObjectsPolygon = useSelector(
      (state) => state.adminReducer.dataObjectsPolygon,
      shallowEqual
    );
    const dataLines = useSelector(
      (state) => state.digitalTwinReducer.dataLines,
      shallowEqual
    );

    const dataEventPolygon = useSelector(
      (state) => state.adminReducer.dataEventPolygon,
      shallowEqual
    );
    const dataComponentPolygon = useSelector(
      (state) => state.adminReducer.dataComponentPolygon,
      shallowEqual
    );

    const [rows, setRows] = useState([]);
    const [dataChecked, setDataChecked] = useState([]);
    const [label, setLabel] = useState("all");
    const [labels, setLabels] = useState({});

    const { data: objectLibraries } = useFetchObjectLibraries({});
    const { data: eventLibraries } = useFetchEventLibraries({});
    const { data: componentsLibraries } = useFetchComponentsLibraries({});

    const [lineLibraries] = useGet(`api/v1/line-libraries`);

    const [pointLibrary, setPointLibrary] = useState(null);
    const [lineLibrary, setlineLibrary] = useState(null);

    const [pointComponent, setPointComponent] = useState(null);
    const [currentNameObjects, setCurrentNameObjects] = useState(null);
    const [currentNameLines, setCurrentNameLines] = useState(null);

    const [currentNameEvents, setCurrentNameEvents] = useState(null);
    const [currentNameComponents, setCurrentNameComponents] = useState(null);
    const [typeEvent, setTypeEvent] = useState("1");
    const [typeOfTable, setTypeOfTable] = useState("objects");
    const [typeOfTypeTable, setTypeOfTypeTable] = useState("points");

    const [pages, setPages] = useState([]);
    const [openDialogPDF, setOpenDialogPDF] = useState(false);

    const dispatch = useDispatch();

    const zIndexTable = useSelector(
      (state) => state.digitalTwinReducer.setTableDetailsForward,
      shallowEqual
    );
    const rowNumber = useSelector((state) => state.digitalTwinReducer.rowNum);

    const allFilterTableRows = useSelector(
      (state) => state.digitalTwinReducer.filterTableRows
    );
    const allFullTableRows = useSelector(
      (state) => state.digitalTwinReducer.fullTableRows
    );

    const handleTypeEvent = (typeEvent) => {
      setCurrentNameEvents(typeEvent.label);
      setTypeEvent(typeEvent.value);
      setLabel(() => "all");
      setVisibilityGenericTable("inline");
    };

    const handlePointLibrary = (pointLibrary) => {
      setPointLibrary(parseInt(pointLibrary.value));
      setCurrentNameObjects(pointLibrary.label);
      setVisibilityGenericTable("inline");
    };

    const handlePointComponent = (pointComponent) => {
      setPointComponent(parseInt(pointComponent.value));
      setCurrentNameComponents(pointComponent.label);
      setVisibilityGenericTable("inline");
    };

    const handleLineLibrary = (lineLibrary) => {
      setlineLibrary(parseInt(lineLibrary.value));
      setCurrentNameLines(lineLibrary.label);
      setVisibilityGenericTable("inline");
    };

    const handleClickTable = () => {
      dispatch(setTableDetailsForward(1000));
    };

    const getCurrentDate = () => {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const getFileName = () => {
      const name =
        typeOfTypeTable === "lines" && typeOfTable === "objects"
          ? currentNameLines
          : typeOfTypeTable === "points" && typeOfTable === "objects"
            ? currentNameObjects
            : typeOfTypeTable === "points" && typeOfTable === "events"
              ? currentNameEvents
              : currentNameComponents;
      return `${typeOfTypeTable}-${typeOfTable}-${name}-${getCurrentDate()}`;
    };

    const downloadTable = (extension) => {
      if (extension !== "Export to") {
        const rowsWithoutFlyTo =
          extension === "Full xslx" || extension === "Full csv"
            ? allFullTableRows.map((row) => {
              const { flyTo, markPoint, report, ...rest } = row.original;
              return rest;
            })
            : rows.map((row) => {
              const { flyTo, markPoint, report, ...rest } = row;
              return rest;
            });
        if (extension === "Full pdf") {
          const data = [];
          Promise.all(
            rowsWithoutFlyTo.map(async (row) => {
              const infoEvent = await getEventPDFInfo(row.id);
              if (infoEvent && Object.keys(infoEvent).length) {
                data.push(infoEvent);
              }
            })
          ).then(() => {
            setPages(data);
            setOpenDialogPDF(true);
          });
          return;
        } else if (extension === "Filtered pdf") {
          const data = [];
          Promise.all(
            rows.map(async (row) => {
              const infoEvent = await getEventPDFInfo(row.id);
              if (infoEvent && Object.keys(infoEvent).length) {
                data.push(infoEvent);
              }
            })
          ).then(() => {
            setPages(data);
            setOpenDialogPDF(true);
          });
          return;
        }

        const worksheet = utils.json_to_sheet([...rowsWithoutFlyTo]);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Data");
        writeFile(
          workbook,
          `Decimetrix-${getFileName()}.${extension === "Full xslx" || extension === "Filtered xlsx"
            ? "xlsx"
            : extension === "Filtered csv" || extension === "Full csv"
              ? "csv"
              : extension
          }`,
          { compression: true }
        );
      }
    };

    const showEventByInspectionType = useSelector(
      (state) => state.digitalTwinReducer.showEventsByInspectionType
    );
    const showOperationByType = useSelector(
      (state) => state.digitalTwinReducer.showOperationsByType
    );
    const showAllEventsByType = useSelector(
      (state) => state.digitalTwinReducer.showEventsByType
    );
    const showEventsByLibrary = useSelector(
      (state) => state.digitalTwinReducer.showEventsByLibrary
    );

    const showMarkersByLibrary = useSelector(
      (state) => state.adminReducer.markerByTypeObject
    );
    const showMarkersByTypeElement = useSelector(
      (state) => state.adminReducer.markerByTypeElement
    );

    const showDataComponentsByType = useSelector(
      (state) => state.digitalTwinReducer.showDataComponentsByType
    );
    const showDataComponentsLibrary = useSelector(
      (state) => state.digitalTwinReducer.showDataComponentsLibrary
    );

    const showDataComponents = useSelector(
      (state) => state.digitalTwinReducer.showDataComponents
    );

    const stateButtonTable = useSelector(
      (state) => state.digitalTwinReducer.stateFilterButtonTable
    );

    const [eventByInspectionType, setEventByInspectionType] = useState([]);
    const [operationByType, setOperationByType] = useState([]);
    const [eventByType, setEventByType] = useState([]);
    const [eventsByLibrary, setAllEventsByLibrary] = useState([]);

    const [markersByLibrary, setMarkersByLibrary] = useState([]);
    const [markersByTypeElement, setMarkersByTypeElement] = useState([]);

    const [dataComponentsByType, setDataComponentsByType] = useState([]);
    const [dataComponentsLibrary, setDataComponentsLibrary] = useState([]);
    const [dataComponents, setDataComponents] = useState([]);

    useEffect(() => {
      setEventByInspectionType(showEventByInspectionType);
      setOperationByType(showOperationByType);
      setEventByType(showAllEventsByType);
      setAllEventsByLibrary(showEventsByLibrary);

      setMarkersByLibrary(showMarkersByLibrary);
      setMarkersByTypeElement(showMarkersByTypeElement);

      setDataComponentsByType(showDataComponentsByType);
      setDataComponentsLibrary(showDataComponentsLibrary);
      setDataComponents(showDataComponents);
    }, []);

    const handleSetViewMap = () => {
      if (typeOfTypeTable === "points" && typeOfTable === "objects") {
        dispatch(setObjectsFilterTable([]));
        const env1 = setFiltersEventsToTrue(eventByInspectionType);
        const env2 = setFiltersEventsToTrue(operationByType);
        const env3 = setFilterTrue(eventByType);
        const env4 = setFilterTrue(eventsByLibrary);
        const componentsByType = setFilterTrue(dataComponentsByType);
        const componentsByLibrary = setFilterTrue(dataComponentsLibrary);
        dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
        dispatch(showEventsByInspectionType(env1));
        dispatch(showOperationsByType(env2));
        dispatch(showEventsByType(env3));
        dispatch(setShowEventsByLibrary(env4));
        dispatch(setStateObjectFilterTable(false));
        dispatch(setShowDataComponentsByType(componentsByType));
        dispatch(setShowDataComponentsLibrary(componentsByLibrary));
        dispatch(
          setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
        );
      }

      if (typeOfTypeTable === "points" && typeOfTable === "events") {
        dispatch(setDataEventFilterTable([]));
        const objects = setFilterTrue(markersByLibrary);
        const obj = setFilterTrue(markersByTypeElement);
        const componentsByType = setFilterTrue(dataComponentsByType);
        const componentsByLibrary = setFilterTrue(dataComponentsLibrary);
        dispatch(showMarkerseByTypeObject(objects));
        dispatch(showMarkerseByTypeElement(obj));
        dispatch(
          setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true })
        );
        dispatch(showMarkerByElementWithMedia(false));
        dispatch(setStateEventFilterTable(false));
        dispatch(setShowDataComponentsByType(componentsByType));
        dispatch(setShowDataComponentsLibrary(componentsByLibrary));
        dispatch(
          setShowDataComponents({ color: `${PRIMARY_COLOR}`, state: true })
        );
      }
      if (typeOfTypeTable === "points" && typeOfTable === "components") {
        dispatch(setComponentsFilterTable([]));
        const env1 = setFiltersEventsToTrue(eventByInspectionType);
        const env2 = setFiltersEventsToTrue(operationByType);
        const env3 = setFilterTrue(eventByType);
        const env4 = setFilterTrue(eventsByLibrary);
        const objects = setFilterTrue(markersByLibrary);
        const obj = setFilterTrue(markersByTypeElement);
        dispatch(setShowDataComponents({ ...dataComponents, state: true }));
        dispatch(setShowEvents({ color: `${PRIMARY_COLOR}`, state: true }));
        dispatch(showEventsByInspectionType(env1));
        dispatch(showOperationsByType(env2));
        dispatch(showEventsByType(env3));
        dispatch(setShowEventsByLibrary(env4));
        dispatch(showMarkerseByTypeObject(objects));
        dispatch(showMarkerseByTypeElement(obj));
        dispatch(
          setShowDataObjects({ color: `${PRIMARY_COLOR}`, state: true })
        );
        dispatch(setFilterComponent(true));
      }
    };

    const handleTypeOfTypeTable = (typeOfTypeTable) => {
      setTypeOfTypeTable(typeOfTypeTable.value);
      if (stateButtonTable) {
        handleSetViewMap();
      }
    };

    const handleTypeOfTable = (typeOfTable) => {
      setTypeOfTable(typeOfTable.value);
      if (stateButtonTable) {
        handleSetViewMap();
      }
    };

    const handleClose = () => {
      handleDrawerOpen();
      setSeePolygonTable(false);
      if (stateButtonTable) {
        handleSetViewMap();
      }
    };

    let defaultValues = useMemo(() => {
      return [];
    }, []);

    useEffect(() => {
      const setLibraries = () => {
        eventLibraries?.forEach((eveLib) => {
          defaultValues.push({
            value: eveLib.id,
            label: eveLib.name,
          });
        });

        setCurrentNameObjects(objectLibraries[0]?.name || null);
        setCurrentNameEvents(defaultValues[0]?.label);
        setCurrentNameComponents(componentsLibraries[0]?.name);
        setCurrentNameLines(lineLibraries[0]?.name || null);
      };
      if (
        objectLibraries &&
        eventLibraries &&
        componentsLibraries &&
        lineLibraries
      )
        setLibraries();
    }, [
      eventLibraries,
      objectLibraries,
      defaultValues,
      componentsLibraries,
      lineLibraries,
    ]);

    useEffect(() => {
      if (!pointLibrary && objectLibraries && !objectLibraries?.error) {
        setPointLibrary(parseInt(objectLibraries[0]?.id));
      }
    }, [objectLibraries, pointLibrary]);

    useEffect(() => {
      setTypeEvent(eventLibraries?.[0]?.id);
    }, [eventLibraries]);

    useEffect(() => {
      if (
        !pointComponent &&
        componentsLibraries &&
        !componentsLibraries?.error
      ) {
        setPointComponent(parseInt(componentsLibraries[0]?.id));
      }
    }, [pointComponent, componentsLibraries]);

    useEffect(() => {
      if (!lineLibrary && lineLibraries && !lineLibraries?.error) {
        setlineLibrary(parseInt(lineLibraries[0]?.id));
      }
    }, [lineLibraries, lineLibrary]);

    useEffect(() => {
      setLabel(() => "all");
      setVisibilityGenericTable("inline");
    }, [typeOfTable, typeOfTypeTable]);

    useEffect(() => {
      setRows(allFilterTableRows);
    }, [allFilterTableRows]);

    if (
      dataObjectsPolygon.length <= 0 &&
      dataEventPolygon.length <= 0 &&
      dataComponentPolygon.length <= 0 &&
      dataLines.length <= 0
    )
      return <></>;

    return (
      <Container
        className="background-2"
        zIndex={zIndexTable}
        onClick={handleClickTable}
        height={state ? 100 : null}
        display={visibilityGenericTable}
      >
        <OpenDialog
          openDialog={openDialogPDF}
          setOpenDialog={setOpenDialogPDF}
          content={{
            title: "Report Preview",
            description: <ReportContainer data={pages} event={true} />,
          }}
          minWidth="100vw"
        />
        <div className="head">
          <div className="head-right">
            <div>
              <p className="title total">
                Total: <span className="bold">{rowNumber}</span>
              </p>
            </div>
            <Select
              onChange={handleTypeOfTypeTable}
              options={[
                { value: "points", label: "Points" },
                { value: "lines", label: "Lines" },
              ]}
              value={typeOfTypeTable}
              placeholder={
                typeOfTypeTable.charAt(0).toUpperCase() +
                typeOfTypeTable.slice(1)
              }
              styles={customStylesLibraries}
              menuPlacement="auto"
            />

            {typeOfTypeTable === "points" && (
              <Select
                onChange={handleTypeOfTable}
                options={[
                  { value: "objects", label: "Objects" },
                  { value: "components", label: "Components" },
                  { value: "events", label: "Events" },
                ]}
                value={typeOfTable}
                placeholder={
                  typeOfTable.charAt(0).toUpperCase() + typeOfTable.slice(1)
                }
                styles={customStylesLibraries}
                menuPlacement="auto"
              />
            )}

            {typeOfTypeTable === "lines" && (
              <Select
                onChange={handleTypeOfTable}
                options={[{ value: "objects", label: "Objects" }]}
                value={typeOfTable}
                placeholder={
                  typeOfTable.charAt(0).toUpperCase() + typeOfTable.slice(1)
                }
                styles={customStylesLibraries}
                menuPlacement="auto"
              />
            )}

            {typeOfTypeTable === "points" && typeOfTable === "objects" && (
              <Select
                onChange={handlePointLibrary}
                options={objectLibraries?.map((library) => {
                  return {
                    value: library.id,
                    label: library.name,
                  };
                })}
                value={pointLibrary}
                placeholder={currentNameObjects ? (
                  currentNameObjects
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress size={20} style={{ marginRight: "8px" }} />
                    Loading...
                  </div>
                )

                }
                styles={customStyles}
                menuPlacement="auto"
              />
            )}

            {typeOfTypeTable === "points" && typeOfTable === "events" && (
              <Select
                onChange={handleTypeEvent}
                options={defaultValues}
                value={typeEvent}
                placeholder={currentNameEvents ? (
                  currentNameEvents
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress size={20} style={{ marginRight: "8px" }} />
                    Loading...
                  </div>
                )
                }
                styles={customStyles}
                menuPlacement="auto"
              />
            )}

            {typeOfTypeTable === "points" && typeOfTable === "components" && (
              <Select
                onChange={handlePointComponent}
                options={componentsLibraries?.map((library) => {
                  return {
                    value: library.id,
                    label: library.name,
                  };
                })}
                value={pointComponent}
                placeholder={currentNameComponents ? (
                  currentNameComponents
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress size={20} style={{ marginRight: "8px" }} />
                    Loading...
                  </div>
                )
                }
                styles={customStyles}
                menuPlacement="auto"
              />
            )}

            {typeOfTypeTable === "lines" && typeOfTable === "objects" && (
              <Select
                onChange={handleLineLibrary}
                options={lineLibraries?.map((library) => {
                  return {
                    value: library.id,
                    label: library.name,
                  };
                })}
                value={lineLibrary}
                placeholder={currentNameLines ? (
                  currentNameLines
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress size={20} style={{ marginRight: "8px" }} />
                    Loading...
                  </div>
                )
                }
                styles={customStyles}
                menuPlacement="auto"
              />
            )}

            <div className="button-container">
              <GenericSplitButton
                options={options}
                handleClick={downloadTable}
              />
            </div>

            {state ? (
              <></>
            ) : (
              <>
                {handlerVisibiltyTable}
                <span className="close" onClick={handleClose}>
                  <AiOutlineClose />
                </span>
              </>
            )}
          </div>
        </div>
        {typeOfTypeTable === "points" && typeOfTable === "objects" && (
          <TableObjects
            pointLibrary={pointLibrary}
            setRows={setRows}
            attributte={""}
            setLabels={setLabels}
            label={label}
            setTableVisibility={setVisibilityGenericTable}
            handleClose={handleClose}
            tableVisibility={visibilityGenericTable}
            state={state}
            typeOfTable={typeOfTable}
            typeOfTypeTable={typeOfTypeTable}
            typeOfLibrary={currentNameObjects}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
          />
        )}

        {typeOfTypeTable === "points" && typeOfTable === "events" && (
          <TableEvents
            typeEvent={typeEvent}
            setRows={setRows}
            attributte={""}
            setLabels={setLabels}
            label={label}
            setTableVisibility={setVisibilityGenericTable}
            handleClose={handleClose}
            tableVisibility={visibilityGenericTable}
            state={state}
            typeOfTable={typeOfTable}
            typeOfTypeTable={typeOfTypeTable}
            typeOfLibrary={currentNameEvents}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
          />
        )}

        {typeOfTypeTable === "points" && typeOfTable === "components" && (
          <TableComponents
            pointLibrary={pointComponent}
            setRows={setRows}
            attributte={""}
            setLabels={setLabels}
            label={label}
            setTableVisibility={setVisibilityGenericTable}
            handleClose={handleClose}
            tableVisibility={visibilityGenericTable}
            state={state}
            typeOfTypeTable={typeOfTypeTable} // "points", "lines"
            typeOfTable={typeOfTable} // "objects", "components", "events"
            typeOfLibrary={currentNameComponents} // <libName>
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
          />
        )}

        {typeOfTypeTable === "lines" && typeOfTable === "objects" && (
          <TableLineObjects
            lineLibraryId={lineLibrary}
            setRows={setRows}
            attributte={""}
            setLabels={setLabels}
            label={label}
            setTableVisibility={setVisibilityGenericTable}
            handleClose={handleClose}
            tableVisibility={visibilityGenericTable}
            state={state}
            typeOfTable={typeOfTable}
            typeOfTypeTable={typeOfTypeTable}
            typeOfLibrary={currentNameLines}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
          />
        )}
      </Container>
    );
  }
);
export default PolygonTable;
