import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "zustandGloabalStore/user";
// Components
import OpenDialog from "components/Dialogs/OpenDialog";
import Layout from "components/Layout/AdminDecimetrix";
// Library to Objects
import LibraryPointObjects from "pages/ConfigAdmin/LibraryPoint";
// import LibraryLine from "pages/ConfigAdmin/LibraryLine";
import LibraryLine from "pages/ConfigAdmin/LibraryLines";
import LibraryPolygon from "pages/ConfigAdmin/LibraryRegion";
// Library to Events
import LibraryPointEvents from "pages/ConfigAdmin/LibraryPoint/EventLibraries";
// Library to Components
import LibraryComponents from "pages/ConfigAdmin/LibraryPoint/ComponentLibraries";

// Styles
import {
  TitleAdminConfig,
  SelectContainerAdminConfig,
  ConfigObjectsContainer,
} from "./configAdminStyles";
import { roles } from "utils/roles";
import useSwr from "swr";
import { config } from "config";
import { ShowAllButton2 } from "pages/PlatformSetUp/Preferences/preferencesstyles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { urls } from "utils/urlKeys";

const typeToLibrary = [
  {
    id: 1,
    name: "Objects",
  },
  {
    id: 2,
    name: "Events",
  },
  {
    id: 3,
    name: "Components",
  },
];

const typeLibrary = [
  {
    id: 1,
    name: "Points",
  },
  {
    id: 2,
    name: "Lines",
  },
  {
    id: 3,
    name: "Polygons",
  },
];

const Libraries = () => {
  const navigate = useNavigate();
  const role = useUserStore.getState().role;
  const adminDecimetrixId = useUserStore.getState().adminDecimetrixId;
  const currentInstanceId = useUserStore.getState().instanceId;

  const [openDialogConfig, setOpenDialogConfig] = useState(false);
  // State to current action
  const [contentDialogConfig, setContentDialogConfig] = useState({
    title: "Error",
    description: "You don't have the credentials",
    disagree: "Accept",
  });
  const [adminDecimetrixInstances, setAdminDecimetrixInstances] = useState([]);
  const [instanceId, setInstanceId] = useState(
    currentInstanceId || null
  );
  const [library, setLibrary] = useState({
    id: "1",
    name: "Points",
  });
  const [libraryTo, setLibraryTo] = useState({
    id: "1",
    name: "Objects",
  });

  const {
    data: instancesByAdminDecimetix,
    error: errorInstancesByAdminDecimetrix,
  } = useSwr(
    adminDecimetrixId ? urls.instance.instancesByAdminDecimetrix(adminDecimetrixId) : null
  );

  useEffect(() => {
    if (
      !errorInstancesByAdminDecimetrix &&
      instancesByAdminDecimetix &&
      !instancesByAdminDecimetix.error
    ) {
      setAdminDecimetrixInstances([
        {
          id: "defaultLibrary",
          name: "Default Library",
        },
        ...instancesByAdminDecimetix.map((instance) => {
          return {
            id: instance.id,
            name: instance.name,
          };
        }),
      ]);
    }
  }, [instancesByAdminDecimetix, errorInstancesByAdminDecimetrix]);

  const navigation = (path) => {
    navigate(path);
  };

  const handleSelectIntance = (e) => {
    if (e.target.value === "defaultLibrary") {
      setInstanceId(null);
    } else {
      setInstanceId(e.target.value);
    }
  };

  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: typeLibrary.find(
        (library) => library.id === parseInt(e.target.value)
      )?.name,
    }));
  };

  const handleLibraryTo = (e) => {
    setLibraryTo((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: typeToLibrary.find(
        (libraryTo) => libraryTo.id === parseInt(e.target.value)
      )?.name,
    }));
  };

  const executeDialogConfig = () => {
    setOpenDialogConfig(() => false);
  };

  return (
    <Layout>
      <div className="background-1">
        {" "}
        <OpenDialog
          openDialog={openDialogConfig}
          setOpenDialog={setOpenDialogConfig}
          execute={executeDialogConfig}
          content={contentDialogConfig}
        />
        <ConfigObjectsContainer>
          <ShowAllButton2
            style={{ position: "absolute" }}
            onClick={() => navigation("/config-admin")}
          >
            <KeyboardArrowLeftIcon style={{ fontSize: "2rem" }} />
          </ShowAllButton2>
          {/* titulo */}
          <TitleAdminConfig>
            <h1>
              Digital Twin Libraries Configuration - {libraryTo.name} -{" "}
              {library.name}
            </h1>
            {/* <p>
            This module allows one to design, create, configure, deploy and edit
            the different libraries to be used as the digital Twin Data
            Architecture for Web & Mobile. Begin by selecting the category and
            geometry on the right.
          </p> */}
          </TitleAdminConfig>
          {/* desplegable */}
          {role === roles.decimetrixAdmin && (
            <SelectContainerAdminConfig style={{ flexDirection: "column" }}>
              <h3>Select a library from an instance</h3>
              <div>
                <p>Instances: </p>
                <select
                  name="endPoint"
                  id="endPoint"
                  onChange={handleSelectIntance}
                >
                  {adminDecimetrixInstances?.map((instance) => (
                    <option key={instance.id} value={instance.id}>
                      {instance.name}
                    </option>
                  ))}
                </select>
              </div>
            </SelectContainerAdminConfig>
          )}
          <SelectContainerAdminConfig style={{ flexDirection: "column" }}>
            {role !== roles.decimetrixAdmin && (
              <h3 className="color1">Select</h3>
            )}
            <div>
              <p style={{ paddingRight: "33px" }}>Type Library: </p>
              <select name="endPoint" id="endPoint" onChange={handleLibraryTo}>
                {typeToLibrary?.map((libraryTo) => (
                  <option key={libraryTo.id} value={libraryTo.id}>
                    {libraryTo.name}
                  </option>
                ))}
              </select>
            </div>
          </SelectContainerAdminConfig>
          <SelectContainerAdminConfig>
            <p style={{ paddingRight: "22px" }}>Type Element: </p>
            <select name="endPoint" id="endPoint" onChange={handleLibrary}>
              {typeLibrary?.map((library) => (
                <option key={library.id} value={library.id}>
                  {library.name}
                </option>
              ))}
            </select>
          </SelectContainerAdminConfig>
        </ConfigObjectsContainer>
        {library.id === "1" && libraryTo.id === "1" && (
          <LibraryPointObjects
            setOpenDialogConfig={setOpenDialogConfig}
            setContentDialogConfig={setContentDialogConfig}
            instanceId={instanceId}
            adminDecimetrixInstances={adminDecimetrixInstances}
          />
        )}
        {library.id === "1" && libraryTo.id === "2" && (
          <LibraryPointEvents
            setOpenDialogConfig={setOpenDialogConfig}
            setContentDialogConfig={setContentDialogConfig}
            instanceId={instanceId}
            adminDecimetrixInstances={adminDecimetrixInstances}
          />
        )}
        {library.id === "1" && libraryTo.id === "3" && (
          <LibraryComponents
            setOpenDialogConfig={setOpenDialogConfig}
            setContentDialogConfig={setContentDialogConfig}
            instanceId={instanceId}
            adminDecimetrixInstances={adminDecimetrixInstances}
          />
        )}
        {library.id === "2" && libraryTo.id === "1" && (
          <LibraryLine
            setOpenDialogConfig={setOpenDialogConfig}
            setContentDialogConfig={setContentDialogConfig}
            instanceId={instanceId}
            adminDecimetrixInstances={adminDecimetrixInstances}
          />
        )}
        {library.id === "2" && libraryTo.id === "2" && (
          <h1
            style={{
              textAlign: "center",
            }}
          >
            Coming soon
          </h1>
        )}
        {library.id === "3" && libraryTo.id === "1" && <LibraryPolygon />}
        {library.id === "3" && libraryTo.id === "2" && (
          <h1
            style={{
              textAlign: "center",
            }}
          >
            Coming soon
          </h1>
        )}
      </div>
    </Layout>
  );
};

export default Libraries;
