import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState, useRef } from "react";
import OpenDialog from "components/Dialogs/OpenDialog";
import { useSWRConfig } from "swr";
import {
  GalleryContainer,
  GalleryContainerNoThumbnails,
  CustomImageGalleyItem,
  ImageGalleryStyleWrapper,
} from "./PhotoGalleryStyle";
import { SECOND_COLOR, WHITE_COLOR } from "utils/const";
import saveRotationImage from "helpers/photoGallery/saveRotationImage";
import deleteImages from "helpers/photoGallery/deleteImages";
import CircularIndeterminate from "components/Lodings/LoadingV2";
import updateObjectPg from "services/updateObjectPg";
import { config } from "config.js";
import ImageZoom from "./ImageZoom";
import IsoIcon from "@mui/icons-material/Iso";
import { getUrlSigned } from "services/s3Manager/s3Manager";
import { ommitBucketUrl, urls } from "utils/urlKeys";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { nameUrl } from "helpers/isUrl";

const PhotoGalley = ({ photos, ownId, state, mongoId, type, uploadImages }) => {
  const [files, setFiles] = useState([]);
  const [iconSave, setIconSave] = useState({
    show: false,
    photoId: null,
  });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [currentIndex, setCurrentIndex] = useState({
    type: "",
    index: 0,
  });
  const [filterPosNeg, setFilterPosNeg] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const processedPhotosRef = useRef(new Set());
  const isMounted = useRef(true);
  const zoomRef = useRef(null);
  const [items, setItems] = useState([]);

  const { mutate } = useSWRConfig();

  // Cleanup effect
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Effect para procesar fotos
  useEffect(() => {
    const processPhotos = async () => {
      if (!photos.length) {
        setIsLoading(false);
        return;
      }

      // Identificar solo las fotos nuevas que no han sido procesadas
      const newPhotos = photos.filter(
        (photo) => !processedPhotosRef.current.has(photo?.id || photo._id)
      );

      if (!newPhotos.length) {
        setIsLoading(false);
        return;
      }

      try {
        const processedUrls = await Promise.all(
          newPhotos.map(async (photo) => {
            const url = photo?.url || photo.urlPhoto || photo;

            const shouldOmitSigning = ommitBucketUrl.some((prefix) =>
              url.startsWith(prefix)
            );

            const signedUrl = shouldOmitSigning
              ? url
              : await getUrlSigned({ s3Url: encodeURIComponent(url) });

            const photoId = photo?.id || photo._id;
            processedPhotosRef.current.add(photoId);

            return {
              id: photoId,
              name: nameUrl(url),
              original: signedUrl,
              thumbnail: signedUrl,
              rotation: photo?.rotation || 0,
              type: photo?.operationId
                ? "operation"
                : photo?.pointEventId
                ? "pointEventPg"
                : photo?.pointComponentId
                ? "pointComponentPg"
                : photo?._id && type === "component"
                ? "pointComponentMg"
                : photo?._id && type === "event"
                ? "pointEventMg"
                : photo?._id && type === "object"
                ? "pointObjectMg"
                : photo?._id && type === "lineObject"
                ? "line"
                : "object",
            };
          })
        );

        if (isMounted.current) {
          setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            processedUrls.forEach((newFile) => {
              const index = newFiles.findIndex((f) => f.id === newFile.id);
              if (index === -1) {
                newFiles.push(newFile);
              } else {
                newFiles[index] = newFile;
              }
            });
            return newFiles;
          });
          setItems((prevItems) => {
            const newFiles = [...prevItems];
            processedUrls.forEach((newFile) => {
              const index = newFiles.findIndex((f) => f.id === newFile.id);
              if (index === -1) {
                newFiles.push(newFile);
              } else {
                newFiles[index] = newFile;
              }
            });
            return newFiles;
          });
        }
      } catch (error) {
        console.error("Error processing photos:", error);
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    processPhotos();
  }, [photos, type]);

  const handlerRotation = async (photo) => {
    setFiles((currentFiles) => {
      return currentFiles.map((currentFile) => {
        if (currentFile.id === photo.id)
          return {
            ...currentFile,
            rotation: currentFile.rotation + 90,
          };
        return currentFile;
      });
    });
    setIconSave({ show: true, photoId: photo.id });
  };

  const handlerFilter = (photo) => {
    setFilterPosNeg((prevFilter) => !prevFilter);
  };

  const handleSaveRotation = async (item) => {
    saveRotationImage(
      files,
      uploadImages,
      setCurrentIndex,
      photos,
      item,
      mongoId
    );
    if (type === "object" && ownId) {
      const userId = localStorage.getItem("userId");
      await updateObjectPg(
        { lastUpdate: new Date(), userUpdateId: userId },
        ownId
      );
      mutate(urls.objects.detail(ownId));
    }

    setIconSave({ show: false, photoId: item.id });
  };

  const handleOpenDialogDelete = (item) => {
    setOpenDialogDelete(!openDialogDelete);
    setCurrentPhoto(item);
  };

  const handleDelete = async () => {
    setFiles((files) => files.filter((item) => item?.id !== currentPhoto?.id));
    deleteImages(currentPhoto, uploadImages, mongoId, ownId, mutate);
    handleOpenDialogDelete();
  };

  const _imageGalleryRenderer = (item) => {
    return (
      <CustomImageGalleyItem>
        <ImageZoom
          ref={zoomRef}
          alt=""
          src={item.original}
          className="image-gallery-image"
          style={{
            transform: `rotate(${
              files.filter((rotation) => rotation.id === item.id)[0]
                ?.rotation || 0
            }deg)`,
            filter: filterPosNeg ? "invert(1)" : "invert(0)",
          }}
        />{" "}
        {type === "event" &&
          typeof item?.name === "string" &&
          item.name.includes("THERMAL") && (
            <div
              style={{
                position: "absolute",
                color: WHITE_COLOR,
                fontSize: "20px",
                left: "0px",
                top: "10px",
              }}
            >
              {item.name || "Sin nombre"}
            </div>
          )}
        {/* <div className="wrapper-buttons"> */}
        {/* buttons for zoom into image */}
        {/* <div className="image-gallery-zoom"> */}
        {/* <> */}
        {/* <button
                  className="button"
                  onClick={() => zoomRef.current?.zoomIn()}
                >
                  <ZoomInIcon
                    sx={{
                      width: "40px",
                      height: "40px",
                      color: WHITE_COLOR,
                    }}
                  />
                </button>
                <button
                  className="button"
                  onClick={() => zoomRef.current?.zoomOut()}
                >
                  <ZoomOutIcon
                    sx={{
                      width: "40px",
                      height: "40px",
                      color: WHITE_COLOR,
                    }}
                  />
                </button> */}
        {/* </> */}
        {/* </div> */}
        {/* buttons for image functionalities */}
        <div className="wrapper-buttons">
          <div className="image-gallery-buttons">

            <button className="button" onClick={() => handlerRotation(item)}>
              <RotateRightIcon
                sx={{
                  width: "40px",
                  height: "40px",
                  color: WHITE_COLOR,
                }}
              />
            </button>
            <button className="button" onClick={() => handlerFilter(item)}>
              <IsoIcon
                sx={{
                  width: "40px",
                  height: "40px",
                  color: WHITE_COLOR,
                }}
              />
            </button>
            {iconSave.photoId === item.id && iconSave.show ? (
              <button
                className="button"
                onClick={() => handleSaveRotation(item)}
              >
                <CheckCircleIcon
                  sx={{
                    width: "40px",
                    height: "40px",
                    color: `${SECOND_COLOR}`,
                  }}
                />
              </button>
            ) : (
              <></>
            )}
            {/* button for delete image */}
            <button
              className="button"
              onClick={() => handleOpenDialogDelete(item)}
            >
              <DeleteIcon
                sx={{
                  width: "40px",
                  height: "40px",
                  color: "#e64b4b",
                }}
              />
            </button>
          </div>
        </div>
        {/* </div> */}
      </CustomImageGalleyItem>
    );
  };

  const _thumbnailGalleryRender = (item) => {
    return (
      <img
        className="image-gallery-thumbnail"
        src={item.original}
        style={{
          width: "100px",
          height: "100px",
          objectFit: "cover",
          transform: `rotate(${
            files.filter((file) => file.id === item.id)[0]?.rotation || 0
          }deg)`,
          padding: "0px 0px",
        }}
        alt=""
      ></img>
    );
  };

  if (isLoading) {
    return <CircularIndeterminate height={"100%"} />;
  }

  if (!files.length) {
    return null;
  }

  return (
    <>
      {state === false && photos.length === files.length ? (
        <GalleryContainerNoThumbnails>
          <ImageGalleryStyleWrapper>
            <ImageGallery
              className="image-gallery-description"
              items={items}
              showBullets={true}
              showPlayButton={false}
              showThumbnails={state}
              renderThumbInner={_thumbnailGalleryRender}
              renderItem={_imageGalleryRenderer}
            />
          </ImageGalleryStyleWrapper>
        </GalleryContainerNoThumbnails>
      ) : (
        files.length > 0 &&
        photos.length === files.length && (
          <GalleryContainer>
            {currentIndex.type === "" || currentIndex.type === "object" ? (
              <ImageGalleryStyleWrapper>
                <ImageGallery
                  className="image-gallery-description"
                  items={items}
                  showBullets={true}
                  startIndex={currentIndex.index}
                  showPlayButton={false}
                  renderItem={_imageGalleryRenderer}
                  showThumbnails={state}
                  renderThumbInner={_thumbnailGalleryRender}
                  thumbnailPosition={"left"}
                  showIndex={true}
                />
              </ImageGalleryStyleWrapper>
            ) : (
              <ImageGalleryStyleWrapper>
                <ImageGallery
                  className="image-gallery-description"
                  items={items}
                  showBullets={true}
                  startIndex={currentIndex.index}
                  showPlayButton={false}
                  renderItem={_imageGalleryRenderer}
                  showThumbnails={state}
                  renderThumbInner={_thumbnailGalleryRender}
                  thumbnailPosition={"left"}
                  showIndex={true}
                />
              </ImageGalleryStyleWrapper>
            )}
          </GalleryContainer>
        )
      )}
      <OpenDialog
        setOpenDialog={setOpenDialogDelete}
        openDialog={openDialogDelete}
        execute={handleDelete}
        content={{
          title: "Delete file",
          description: "Are you sure you want to delete this file ?",
          agree: "Delete",
          disagree: "Cancel",
        }}
      />
    </>
  );
};

export default PhotoGalley;
