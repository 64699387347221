import { useEffect, useState } from "react";
import SelectLibrary from "./SelectLibrary";
// Hooks
import { useFetchEventLibraries } from "hooks/fetchLibraries";
import useFindAllEvents from "hooks/events/useFindAllEvents";
// Components
import SelectColumnLibrary from "pages/Dashboard/components/SelectColumnLibrary";
import CustomButton from "components/Buttons/CustomButton";
import SelectTypeOperation from "pages/Dashboard/components/SelectTypeOperation";
// Services
// Const
import { defaultFieldsEvents } from "utils/defaultColumns";
// Helpers
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";
import { config } from "config";
import { useSWRConfig } from "swr";
import { addChartInAllViews } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";
import SelectTimePeriod from "./SelectTimePeriod";
import { createChart } from "services/Dashboard/timeSeriesBarChart";
import AddTimeSeriesBarChart from "./AddTimeSeriesBarChart";
import FetchEvents from "hooks/fetchEvents";

const FormTimeSeriesBarChartEvents = ({ setOpenDialog }) => {
  const [columnsLibrary, setColumnsLibrary] = useState([]);
  const [columnsByDateLibrary, setColumnsByDateLibrary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [librarySelectedId, setLibrarySelectedId] = useState(null);
  const [columnsTypeNumber, setColumnsTypeNumber] = useState([]);
  const [eventsFormat, setEventsFormat] = useState([]);
  const [dataInputs, setDataInputs] = useState({
    alias: "",
    librarySelected: null,
    columnY: null,
    columnX: null,
    typeOperation: "count",
    timePeriod: null,
    category: null,
  });
  const [dataSeries, setDataSeries] = useState([]);
  const [typeEventNames, setTypeEventNames] = useState([]);

  const updateDataInputs = (newState) => {
    setDataInputs((prevState) => ({ ...prevState, ...newState }));
  };

  const { data: libraries, error } = useFetchEventLibraries({});
  const { data: typeEvents, error: errorTypeEvents } =
    FetchEvents(librarySelectedId);

  const { events } = useFindAllEvents();
  const { mutate } = useSWRConfig();
  const setNewCreateChart = useStoreDashboard(
    (state) => state.setNewCreateChart
  );
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);

  const handlerSelectCategory = (column) => {
    updateDataInputs({ category: column });
  };

  const handlerTimePeriod = (e) => {
    updateDataInputs({ timePeriod: e });
  };
  const handlerTypeOperation = (e) => {
    updateDataInputs({ typeOperation: e });
  };

  const handlerClickColumnY = (column) => {
    updateDataInputs({ columnY: column });
  };

  const handlerClickColumnX = (column) => {
    updateDataInputs({ columnX: column });
  };

  const handlerAlias = (e) => {
    updateDataInputs({ alias: e.target.value });
  };

  const columsTypeNumber = (columns) => {
    const numberFields = columns?.filter(
      (field) =>
        field.type === "number" ||
        field.type === "operation" ||
        field.type === "range"
    );
    setColumnsTypeNumber(numberFields || []);
  };

  const handlerLibrarySelected = (e) => {
    updateDataInputs({ librarySelected: e });
    const library = libraries.find((library) => library.id === e.value);
    setLibrarySelectedId(library.id);
    const eventsByLibraryId = events.filter(
      (object) => object.pointTypeEvent.pointLibraryEventId === e.value
    );
    const rows = createRowsEvents({ events: eventsByLibraryId, library });
    setEventsFormat(rows);
    updateDataInputs({ columnY: null, columnX: null, alias: "" });
  };

  useEffect(() => {
    // find library by library select from libraries
    if (libraries && dataInputs.librarySelected) {
      const library = libraries.find(
        (library) => library.id === dataInputs.librarySelected.value
      );

      const columnsLibrary = library?.fields;
      const columnsByDateLibrary = library?.fields.filter(
        (elm) => elm.type === "date"
      );

      const columnsFiltered = columnsLibrary?.filter(
        (field) =>
          field.type !== "captureMedia" &&
          field.type !== "notification" &&
          field.type !== "number" &&
          field.type !== "operation" &&
          field.type !== "range"
      );

      if (columnsFiltered && columnsFiltered.length > 0) {
        setColumnsLibrary([...defaultFieldsEvents, ...columnsFiltered] || []);
      } else {
        setColumnsLibrary([...defaultFieldsEvents] || []);
      }

      if (columnsByDateLibrary && columnsByDateLibrary.length > 0) {
        setColumnsByDateLibrary(
          [
            {
              name: "localDate",
              alias: "Local date",
              type: "string",
            },
            ...columnsByDateLibrary,
          ] || []
        );
      } else {
        setColumnsByDateLibrary(
          [
            {
              name: "localDate",
              alias: "Local date",
              type: "string",
            },
          ] || []
        );
      }
      columsTypeNumber(columnsLibrary || []);
    } else {
      setColumnsLibrary([]);
      setColumnsByDateLibrary([]);
    }
  }, [libraries, dataInputs.librarySelected]);

  useEffect(() => {
    if (librarySelectedId && !errorTypeEvents && typeEvents?.length > 0) {
      const names = typeEvents.map((elm) => {
        return elm.name;
      });
      setTypeEventNames(names);
    }
  }, [typeEvents, errorTypeEvents, librarySelectedId]);

  const handlerAddChart = async () => {
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const userId = localStorage.getItem("userId");
    const urlLineChart = `${config.URL_BACKEND_MG}time-series-bar-chart?adminCompanyId=${adminCompanyId}&from=events`;
    const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;
    const chart = {
      adminCompanyId: parseInt(localStorage.getItem("adminCompanyId")),
      type: dataInputs.typeOperation,
      from: "events",
      libraryId: dataInputs.librarySelected.value,
      alias: dataInputs.alias,
      columnX: dataInputs.columnX,
      timeVariable: dataInputs.timePeriod,
      columnsY: dataInputs.columnY ? dataInputs.columnY : "",
      categories: dataInputs.category,
      datasets: dataSeries,
    };

    setLoading(true);
    const { data } = await createChart(chart);
    if (data) {
      mutate(urlLineChart);
      await addChartInAllViews({
        userId,
        type: "events",
        body: {
          newChart: {
            id: data._id,
          },
        },
      });
      mutate(urlOrders);
      setNewCreateChart(!newCreateChart);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  return (
    <>
      {!error && libraries && (
        <SelectLibrary
          libraries={libraries}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={dataInputs.librarySelected}
        />
      )}
      {dataInputs.librarySelected && columnsByDateLibrary && (
        <SelectColumnLibrary
          columnsLibrary={columnsByDateLibrary}
          handlerClickColumn={handlerClickColumnX}
          columnSelected={dataInputs.columnX}
          title={"Select X-Axis"}
        />
      )}
      {dataInputs.librarySelected && dataInputs.columnX && (
        <SelectTimePeriod
          handlerTimePeriod={handlerTimePeriod}
          timeSelect={dataInputs.timePeriod}
        />
      )}

      {dataInputs.librarySelected && dataInputs.timePeriod && (
        <AddTimeSeriesBarChart
          dataInputs={dataInputs}
          columnsLibrary={columnsLibrary}
          setDataSeries={setDataSeries}
          dataSeries={dataSeries}
          handlerSelectCategory={handlerSelectCategory}
          data={eventsFormat}
          typeElementNames={typeEventNames}
        />
      )}

      {dataInputs.librarySelected &&
        dataInputs.timePeriod &&
        dataSeries.length > 0 && (
          <SelectTypeOperation
            handlerSelectTypeOperation={handlerTypeOperation}
          />
        )}

      {dataInputs.librarySelected &&
        columnsLibrary &&
        dataSeries.length > 0 &&
        dataInputs.typeOperation === "sum" && (
          <SelectColumnLibrary
            title={"Select Y-Axis"}
            columnsLibrary={columnsTypeNumber}
            handlerClickColumn={handlerClickColumnY}
            columnSelected={dataInputs.columnY}
          />
        )}

      {dataInputs.librarySelected &&
        dataInputs.timePeriod &&
        dataSeries.length > 0 && (
          <section className="container-type-charts ">
            <div>
              <h2 className="subtitle">Alias</h2>
              <input
                className="alias"
                type="text"
                value={dataInputs.alias}
                onChange={(e) => {
                  handlerAlias(e);
                }}
                required={true}
              />
            </div>
          </section>
        )}

      {dataInputs.alias !== "" && dataInputs.alias !== " " && (
        <CustomButton
          className="btn-add-chart"
          onClick={() => handlerAddChart()}
          isLoad={loading}
          text={"Add chart"}
          margin={"15px 0"}
        />
      )}
    </>
  );
};

export default FormTimeSeriesBarChartEvents;
