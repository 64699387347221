import { useState, useEffect, useCallback } from "react";
// Helpers
import { sum, count } from "pages/Dashboard/helpers/eventsBarChart";
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";

const useFindBarCharts = ({ openDialog, events, loading }) => {
  const [charts, setCharts] = useState(null);
  const [barCharts, setBarCharts] = useState(null);
  const [eventsFormat, setEventsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataBarChart, error: errorDataBarChart } = useSwr(
    urlsApiMg.dashboard.barChart("events")
  );

  useEffect(() => {
    if (!errorDataBarChart && dataBarChart) setCharts(dataBarChart);
  }, [dataBarChart, errorDataBarChart, openDialog, reloadCharts]);

  useEffect(() => {
    const eventsFormat = createRowsEvents({ events });
    setEventsFormat(eventsFormat);
  }, [events]);

  const getDataBarChart = useCallback(async () => {
    if (eventsFormat && !loading) {
      const chartsFilterCount = charts.filter(
        (chart) => chart.type === "count"
      );
      const barsCountByLibrary = count({
        barCharts: chartsFilterCount,
        events: eventsFormat,
      });
      const chartsFilterSum = charts.filter((chart) => chart.type === "sum");
      const barsCountByType = sum({
        barCharts: chartsFilterSum,
        events: eventsFormat,
      });

      setBarCharts([...barsCountByLibrary, ...barsCountByType]);
    }
  }, [loading, eventsFormat, charts]);

  useEffect(() => {
    if (charts && events) {
      getDataBarChart();
    }
  }, [charts, events, getDataBarChart]);

  return { barCharts };
};

export default useFindBarCharts;
