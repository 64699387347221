import { useState } from "react";
import { Button } from "./../FormStyles";
import saveEventApiPg, { createEvent } from "services/saveEventApiPg";
import saveFlirVideo from "services/saveFlirVideo";
import { useEffect } from "react";
import findFlirVideo from "services/findFlirVideo";
import findInspectionFlir from "services/findInspectionFlir";
import updateInspectionFlir from "services/updateInspectionFlir";
import updateFlirVideo from "services/updateFlirVideo";
import { Form } from "../SaveObject/DynamicFieldsStyle";
import { fieldFormIspectionFlir } from "utils/events";
import useGet from "hooks/useFetch";
import { useForm } from "react-hook-form";
import TextInput from "../GenericInput";
import SelectInput from "../SelectInput";
import findTypeInspectionFlir from "services/findTypeInspectionFlir";
import { urls } from "utils/urlKeys";

export default function InspectionFlir({
  feature,
  objectId,
  closeModal,
  isUpdate,
  id,
  callbackUpdate,
  operationId,
}) {
  const [flirVideo, setFlirVideo] = useState({});
  const [inspectionFlir, setInspectionFlir] = useState({});
  const [TypeInspectionFlir] = useGet(urls.typeInspectionsFlir);

  const [form, setForm] = useState({
    comments: "",
    typeId: 1,
    leak: false,
  });

  const [formFlirVideo, setFormFlirVideo] = useState({
    name: "",
    distance: "",
  });

  const [typeInspection, setTypeInspection] = useState({
    name: "Inspection Type",
    options: [],
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      comments: "",
      typeId: "",
      leak: false,
      name: "",
      distance: "",
    },
  });

  const onSubmit = (data) => {
    const { name, value, checked, type } = data.target;
    if (type === "checkbox") {
      setForm({
        ...form,
        [name]: checked,
      });
    } else {
      if (
        name === "comments" ||
        name === "leak" ||
        name === "Inspection Type"
      ) {
        setForm({
          ...form,
          [name === "Inspection Type" ? "typeId" : name]: value,
        });
      } else {
        setFormFlirVideo({
          ...formFlirVideo,
          [name === "File .seq name" ? "name" : name]: value,
        });
      }
    }
  };

  const handleSelect = (data) => {
    const typeInspectionId = TypeInspectionFlir.find(
      (inspection) => inspection.type === data.target.value
    );
    setForm({
      ...form,
      typeId: data.target.value === "" ? 1 : typeInspectionId.id,
    });
  };

  const createBodyOfEvent = () => {
    const newForm = {};
    Object.keys(form).forEach((key) => {
      const value = form[key];
      if (value !== "") newForm[key] = form[key];
    });
    return newForm;
  };

  const createBodyFlirVideo = (flirEvent) => {
    const newForm = {};
    Object.keys(formFlirVideo).forEach((key) => {
      const value = formFlirVideo[key];
      if (value !== "") newForm[key] = formFlirVideo[key];
    });
    const body = {
      distance: parseInt(newForm.distance),
      ...newForm,
    };
    if (!isUpdate) body.inpectionFlirId = flirEvent.id;
    return body;
  };

  const handleMutates = () => {
    console.log("handleMutates");
  };

  const handleUpdate = async () => {
    const body = createBodyOfEvent();
    const bodyFlirVideo = createBodyFlirVideo(inspectionFlir);
    const { res } = await updateInspectionFlir(body, id);
    if (res.status !== 200) return;
    if (flirVideo?.id) {
      const { res: resFlirVideo } = await updateFlirVideo(
        bodyFlirVideo,
        flirVideo.id
      );
      if (resFlirVideo.status !== 200) return;
    }
    handleMutates();
    callbackUpdate();
  };

  const handleSave = async () => {
    if (isUpdate) {
      handleUpdate();
      return;
    }
    const newForm = createBodyOfEvent();
    const event = createEvent(feature, newForm, objectId);
    const { data: dataEvent } = await saveEventApiPg("inspection-flir", event);
    const newFormFlirVideo = createBodyFlirVideo(dataEvent);
    await saveFlirVideo(newFormFlirVideo);

    closeModal(false);
    handleMutates();
  };

  useEffect(() => {
    const getFlirVideo = async () => {
      const res = await findFlirVideo(null, id);
      const { data } = res;
      if (data[0]) {
        setFlirVideo(data[0]);
        setValue("File .seq name", data[0]?.name || "");
        setValue("distance", data[0]?.distance || 0);
        setFormFlirVideo({
          ...formFlirVideo,
          name: data[0].name,
          distance: data[0].distance,
        });
      }
    };
    if (isUpdate) {
      getFlirVideo();
    }
  }, [id, isUpdate]);

  useEffect(() => {
    const getInspectionFlir = async () => {
      const data = await findInspectionFlir(id);
      const inspectionTypes = await findTypeInspectionFlir();
      const typeInspectionName = await inspectionTypes.find(
        (inspection) => inspection.id === data?.typeId
      );
      setInspectionFlir(data);
      setValue("comments", data?.comments || "");
      setValue("Inspection Type", typeInspectionName.type || "");
      setValue("leak", data?.leak ? "on" : "");
      setForm({
        ...form,
        typeId: data.typeId,
        comments: data.comments,
        leak: data.leak,
      });
    };
    if (isUpdate) {
      getInspectionFlir();
    }
  }, [id, isUpdate]);

  if (TypeInspectionFlir !== undefined && typeInspection.options.length === 0) {
    const res = TypeInspectionFlir?.map((item) => {
      const { type } = item;
      return type;
    });
    setTypeInspection((typeInspection) => ({
      ...typeInspection,
      options: res,
    }));
  }

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <div onChange={(e) => handleSelect(e)}>
        <SelectInput field={typeInspection} register={register} />
      </div>
      {fieldFormIspectionFlir.map((elm) => {
        return (
          <div onChange={(e) => onSubmit(e)}>
            <TextInput
              key={elm.key}
              field={elm}
              register={register}
              errors={errors}
            />
          </div>
        );
      })}
      <Button style={{ padding: 0 }} type="submit">
        Save
      </Button>
      {isUpdate && <Button onClick={callbackUpdate}>Cancel</Button>}
    </Form>
  );
}
