import { useEffect, useState } from "react";
import OpenDialog from "components/Dialogs/OpenDialog";
import { LoadCsv, CustomInput } from "./LoadCSVStyle";
import { uploadCSVLines } from "services/lines/uploadCSVLines";
import checkCSVHeaders from "helpers/checkCSVFileHeaders";
import {
  LinearProgress,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import { useSocket } from "hooks/useSocket";
import { WHITE_SECOND_COLOR } from "utils/const";

export const LoadCSVLines = () => {
  const socket = useSocket();

  const [haveObjects, setHaveObjects] = useState(false);
  const [mediaMessage, setMediaMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fileName, setFileName] = useState("");

  const [saveProgressLines, setSaveProgressLines] = useState();
  const [batchCompleteLines, setBatchCompleteLines] = useState();
  const [bulkSaveCompleteLines, setBulkCompleteLines] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleSocketEvent = (event, setDataFlag) => {
      if (socket) {
        socket.on(event, (data) => {
          setDataFlag(data);
        });
        return () => {
          socket.off(event);
        };
      }
    };

    const cleanupSocketListeners = [
      handleSocketEvent("saveProgressLines", setSaveProgressLines),
      handleSocketEvent("batchCompleteLines", setBatchCompleteLines),
      handleSocketEvent("bulkSaveCompleteLines", setBulkCompleteLines),
    ];

    return () => {
      cleanupSocketListeners.forEach((cleanup) => cleanup());
    };
  }, [socket]);

  // handle file upload
  const handleFileUploadCSV = (e) => {
    const file = e.target.files[0];
    setFileName(file);
    setShowConfirm(true);
    e.target.value = "";
  };

  // read the upload file
  const loadCSVFile = async (fn) => {
    if (!fn || fn === "") return setErrorMessage(true);
    // two FileReaders, one for csonverting data to a string and check headers of csv
    // second for reading entire document and stream it to backend so it doesn't block
    const readerText = new FileReader();
    const readerArrBff = new FileReader();

    readerText.onload = async (evt) => {
      //check the length of the file to know if empty
      if (evt.target.result.length === 0) {
        setHaveObjects(true);
        setErrorMessage(true);
        setShowConfirm(false);
        return;
      }
      const rawString = evt.target.result;
      if (rawString.length === 0) {
        setHaveObjects(true);
        setErrorMessage(true);
        setShowConfirm(false);
        return;
      }
      if (
        checkCSVHeaders(rawString, [
          "typeLineId",
          "latitude",
          "longitude",
          "initialPointName",
          "initialPointType",
          "statusInitialPoint",
          "finalPointName",
          "finalPointType",
          "statusFinalPoint",
        ])
      ) {
        readerArrBff.onload = async (evt) => {
          console.log("File uploaded: ", fn.name);
          const formData = new FormData();
          formData.append("file", fn, fn.name);
          // Send data to backend and store csv fn temporarly for processing
          await uploadCSVLines(formData);
        };
        readerArrBff.readAsArrayBuffer(fileName);
      } else {
        setErrorMessage(true);
        setHaveObjects(true);
        setShowConfirm(false);
        return;
      }
    };
    readerText.readAsText(fileName, "utf-8");
  };

  const ProgressBar = ({ value }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress color="success" variant="determinate" value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography>{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const UploadProgress = ({
    saveProgressLines,
    batchCompleteLines,
    bulkSaveCompleteLines,
  }) => {
    if (!saveProgressLines && !batchCompleteLines && !bulkSaveCompleteLines) {
      return null;
    }

    let progress = 0;
    let statusText = "";

    if (saveProgressLines) {
      progress = (saveProgressLines.saved / saveProgressLines.total) * 100;
      statusText = `Saving: ${saveProgressLines.saved} / ${saveProgressLines.total}`;
    } else if (batchCompleteLines) {
      progress = (batchCompleteLines.saved / batchCompleteLines.total) * 100;
      statusText = `Batch complete: ${batchCompleteLines.saved} saved, ${batchCompleteLines.errors} errors`;
    } else if (bulkSaveCompleteLines) {
      progress = 100;
      statusText = `Upload complete: ${bulkSaveCompleteLines.saved} saved, ${bulkSaveCompleteLines.errors} errors`;
    }

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <ProgressBar value={progress} />
        <Typography variant="body2" sx={{ mt: 1 }}>
          {statusText}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    saveProgressLines !== undefined &&
      batchCompleteLines !== undefined &&
      bulkSaveCompleteLines !== undefined &&
      setIsLoading(false);
  }, [batchCompleteLines, bulkSaveCompleteLines, saveProgressLines]);

  return (
    <LoadCsv>
      <label htmlFor="csvLines" style={{ textTransform: "none" }}>
        Add Data
      </label>
      <CustomInput
        id="csvLines"
        type="file"
        name="file"
        style={{ visibility: "hidden" }}
        accept=".csv"
        onChange={handleFileUploadCSV}
      />
      <OpenDialog
        openDialog={showConfirm}
        setOpenDialog={setShowConfirm}
        execute={() => {
          setIsLoading(true);
          loadCSVFile(fileName);
        }}
        content={{
          title: "Confirm upload",
          description: (
            <div>
              <p>
                {"Are you sure you want to load this " + fileName?.name + " ?"}
              </p>
              <UploadProgress
                saveProgressLines={saveProgressLines}
                batchCompleteLines={batchCompleteLines}
                bulkSaveCompleteLines={bulkSaveCompleteLines}
              />
            </div>
          ),
          disagree: bulkSaveCompleteLines ? "Close" : "Cancel",
          agree: !isLoading ? (
            "Upload file"
          ) : (
            <CircularProgress
              style={{
                width: "20px",
                height: "20px",
                color: WHITE_SECOND_COLOR,
                marginRight: "5px",
              }}
            />
          ),
        }}
      />
      <OpenDialog
        openDialog={errorMessage}
        setOpenDialog={setErrorMessage}
        content={{
          title: "Invalid Format",
          description:
            "Cannot read the file. Please ensure that the format is valid and try again.",
          disagree: "Continue",
        }}
      />
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        content={{
          title: "No Items",
          description:
            "The file that was uploaded is empty. Please provide another one.",
          disagree: "See map",
        }}
      />
      <OpenDialog
        openDialog={mediaMessage}
        setOpenDialog={setMediaMessage}
        content={{
          title: "No Media Files",
          description:
            "The file that was uploaded does not have any media. If you want to add pictures, click on the marker icon and select add pictures option.",
          disagree: "Ok",
        }}
      />
    </LoadCsv>
  );
};
