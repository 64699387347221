import CircularProgress from "@mui/material/CircularProgress";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";
import { LoadingContainer } from "./LoadingStyle";

export default function CircularIndeterminate({ height = null }) {
  return (
    <LoadingContainer height={height}>
      <CircularProgress
        style={{
          width: "150px",
          height: "150px",
          color: PRIMARY_COLOR,
        }}
      />
      <p className="color1">Loading</p>
    </LoadingContainer>
  );
}
