import useSwr from "swr";
import { urls } from "utils/urlKeys";

/**
 * Fetches components by object ID.
 *
 * @param {string} pointTypeComponentId - The ID of the object to fetch components for.
 * @return {object} - An object {data, error} containing the fetched data and any errors that occurred.
 */
const FetchTypeEventsTypeComponents = (pointTypeComponentId) => {
  const { data, error } = useSwr(
    urls.events.typeEventsTypeComponents(
      `pointTypeComponentId=${pointTypeComponentId}`
    )
  );
  return { data, error };
};

export default FetchTypeEventsTypeComponents;
