import React from "react";

import {
  CardContainer,
  HubContainer,
  HubWrapper,
} from "pages/AdminCompany/Hub/HubStyles";

import Loading from "components/Lodings/LoadingV2";

import InstancesCards from "./InstancesCards";
import { useFetchUser } from "hooks/fetchUser";
import { useUserStore } from "zustandGloabalStore/user";

function InstancesContainer() {
  const { userId } = useUserStore((state) => state);
  const { user, loading } = useFetchUser({ userId });

  console.log("✅✅✅", user);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <HubContainer className="background-2 border-top-1">
      <CardContainer>
        <HubWrapper style={{ justifyContent: "center" }}>
          <InstancesCards instancesUser={user.instancesUsers} />
        </HubWrapper>
      </CardContainer>
    </HubContainer>
  );
}

export default InstancesContainer;
