import { useState, useEffect, useCallback } from "react";
// Helpers
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/eventsDonutChart";
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";

const useFindDoughnutCharts = ({ openDialog, events, loading }) => {
  const [charts, setCharts] = useState(null);
  const [donutCharts, setDonutCharts] = useState(null);
  const [eventsFormat, setEventsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataDonutChart, error: errorDataDonutChart } = useSwr(
    urlsApiMg.dashboard.doughnutChart("events")
  );

  useEffect(() => {
    if (!errorDataDonutChart && dataDonutChart) setCharts(dataDonutChart);
  }, [dataDonutChart, errorDataDonutChart, openDialog, reloadCharts]);

  useEffect(() => {
    const eventsFormat = createRowsEvents({ events });
    setEventsFormat(eventsFormat);
  }, [events]);

  const getDataDonutChart = useCallback(async () => {
    if (eventsFormat && !loading) {
      const countCharts = countByValues({
        charts,
        events: eventsFormat,
      });

      const sumCharts = sumValues({
        charts,
        events: eventsFormat,
      });

      setDonutCharts([...countCharts, ...sumCharts]);
    }
  }, [loading, eventsFormat, charts]);

  useEffect(() => {
    if (charts && events) {
      getDataDonutChart();
    }
  }, [charts, events, getDataDonutChart]);

  return { doughnutCharts: donutCharts };
};

export default useFindDoughnutCharts;
