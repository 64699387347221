import axios from "axios";
import { urls } from "utils/urlKeys";

async function createAccessUserToInstances({ userId, instanceId }) {
  try {
    const token = localStorage.getItem("token");
    const result = await axios.post(
      urls.instance.instanceUser,
      { userId, instanceId },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await result.data;
    return data;
  } catch (error) {
    console.error("🚩🚩Error on Create new View: ", error);
    return { error };
  }
}

export default createAccessUserToInstances;
