import { useEffect, useState } from "react";

// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";
export const useFindImgChartsComponents = ({ openDialog }) => {
  const [charts, setCharts] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataImgChart, error: errorDataImgChart } = useSwr(
    urlsApiMg.dashboard.imgChart("components")
  );

  useEffect(() => {
    if (!errorDataImgChart && dataImgChart) setCharts(dataImgChart);
  }, [dataImgChart, errorDataImgChart, openDialog, reloadCharts]);

  return { imgCharts: charts };
};
