import {
  SHOW_DRAWER_DIGITAL_TWIN,
  SHOW_CARD_MAKER_DIGITAL_TWIN,
  SET_DATA_OBJECT_DT,
  SET_PROFILE_IMAGE,
  SET_DATA_OBJECT_DT_IMAGES,
  SHOW_DRAWER_OBJECT_DT,
  SHOW_MARKERS_DIGITAL_TWIN,
  SET_MARKER_BY_TYPE_ELEMENT_ID,
  SHOW_MARKERS_BY_ACTIVITIES,
  SHOW_MARKER_BY_ELEMENT_WITH_MEDIA,
  SHOW_OPERATION_DETAILS,
  OPERATION_DETAILS,
  SET_FILTER_OBJECTS_BY_DATA,
  SET_DRAW_ANY_THING,
  SET_DRAWER_CONTROL,
  SET_UPDATE_REGION,
  SET_MAP,
  SET_OBJECTS_UNDER_DRAWN_POINT,
  SHOW_EVENTS_BY_INSPECTION_TYPE,
  SHOW_LABELS,
  SET_OPERATION_CLUSTERS,
  SET_OBJECT_CLUSTERS,
  SET_COMPONENT_CLUSTERS,
  SHOW_OPERATIONS_BY_TYPE,
  SET_SHOW_LINES,
  SET_FLY_TO,
  SET_OBJECTS_DIGITAL_TWIN,
  SET_FILTERED_OBJECTS_DIGITAL_TWIN,
  SET_OPERATIONS,
  SET_FILTERED_OPERATIONS,
  SET_SHOW_TRACKINGS,
  SET_DATA_EVENTS,
  SET_TABLE_DETAILS_FORWARD,
  SET_NEURON_SELECT_TOOL,
  DYNAMIC_ROW_NUM,
  GET_TABLE_ROWS_FILTER,
  GET_TABLE_ROWS_FULL,
  OPEN_SELECT_COLUMNS,
  DOWNLOAD_OPTION,
  DATA_NEW_TABLE_WINDOW,
  STATE_OBJECT_FILTER_TABLE,
  STATE_EVENT_FILTER_TABLE,
  STATE_COMPONENT_FILTER_TABLE,
  SET_DATA_COMPONENTS,
  SHOW_DATA_COMPONENTS_LIBRARY,
  SHOW_DATA_COMPONENTS_BY_TYPE,
  SHOW_DATA_COMPONENTS,
  SET_FILTER_COMPONENT,
  SET_FILTER_EVENTS,
  SHOW_EVENTS,
  SHOW_EVENTS_BY_LIBRARY,
  SHOW_EVENTS_BY_TYPE,
  STATE_FILTER_BUTTON_TABLE,
  SHOW_COMPONENT_LABELS,
  SHOW_EVENT_LABEL,
  SET_RADIUS_ELEMENTS_NEAR,
  OPEN_SELECT_ORDER_VIEW_COLUMNS,
  SET_ORIGINAL_ORDER_VIEW_COLUMNS,
  CURRENT_TABLE_VIEW,
  LOAD_DATA_POINTS,
  RELATION_POINT_LINES,
  DATA_LINES,
  ORIGIN_DATA_LINES,
  CANCEL_DRAWING,
  OBJECTS_LINES_IDS,
  CURRENT_UPDATE_LINE_ID,
  SHOW_LINE_POPUP,
  RESET_STATES,
  SET_DRAWLINE_MODE,
  SETTING_MAP,
  TMP_DISTANCE_FEATURES,
  SET_OBJECT_RELATION_OBJECT,
  MODAL_RELATION_OBJECT,
  POPUP_RELATION_OBJECT,
  INFO_RELATION_OBJECT,
  DATA_RELATION_OBJECT,
  DATABASE_RELATION_OBJECT,
  SEE_OBJECT_CHILD_RELATION,
  INFO_FILTER_DATE,
  M_ZOOM_VIEW,
  MAP_SETTINGS,
  LINES_FILTERS,
  SHOW_FILTER_LINES_IN_MAP,
  MESSAGE_LOADING_MAP,
} from "../actions/types";

const initialState = {
  messageLoadingMap: false,
  settingMap: {},
  currentUpdateLineId: 0,
  cancelDrawing: false,
  loadDataPoints: {
    objects: false,
    events: false,
    components: false,
  },
  dataLines: [],
  originDataLines: [],
  relationPointsLines: [],
  stateFilterButtonTable: false,
  stateFilterEvents: false,
  showEvents: {},
  showEventsByLibrary: [],
  showEventsByType: [],
  stateFilterComponent: false,
  stateObjectFilterTable: false,
  stateComponentFilterTable: false,
  stateEventFilterTable: false,
  dataNewTableWindow: [],
  optionDownload: 0,
  openSelectColumns: false,
  openSelectOrderViewColumns: false,
  originalOrderViewColumns: [],
  currentTableView: [],
  filterTableRows: [],
  fullTableRows: {},
  rowNum: 0,
  showDrawerDigitalTwin: false,
  showDrawerObjectDt: false,
  cardMarkerDigitalTwin: false,
  objectDataDT: {},
  profileImage: [],
  objectDataDTImages: [],
  showMarkersDigitalTwin: true,
  showMarkerByTypeElementId: {},
  showMarkersByActivities: {},
  showDataComponents: {},
  showDataComponentsLibrary: [],
  showDataComponentsByType: [],
  showMarkerByElementWithMedia: false,
  showOperationDetails: false,
  operationDetails: {},
  setFilterObjectsByData: {},
  objects: [],
  filteredObjects: [],
  operations: [],
  filteredOperations: [],
  drawAnyThing: {
    type: "",
    isDraw: false,
  },
  drawerControl: null,
  updateRegion: {},
  map: null,
  objectsUnderDrawnPoint: [],
  showEventsByInspectionType: {},
  mZoomView: JSON.parse(localStorage.getItem("mZoomView")) || {
    icon: true,
    color: false,
    zoom: 17,
  },
  operationClusters: JSON.parse(localStorage.getItem("operationCluster")) || {
    radius: 100,
    maxZoom: 19,
  },
  objectClusters: JSON.parse(localStorage.getItem("objectClusters")) || {
    radius: 100,
    maxZoom: 19,
  },
  componentClusters: JSON.parse(localStorage.getItem("componentClusters")) || {
    radius: 100,
    maxZoom: 19,
  },
  showLabels: false,
  showComponentLabels: false,
  showEventLabels: false,
  showOperationsByType: {},
  dataEvents: [],
  dataComponents: [],
  showLines: true,
  showTrackings: false,
  flyTo: {
    object: {
      id: "",
      latitude: "",
      longitude: "",
    },
    operation: {
      id: "",
      latitude: "",
      longitude: "",
    },
  },
  setTableDetailsForward: 1000,
  setNeuronSelectTool: {
    show: false,
    top: null,
  },
  radiusElementsNear: 10,
  objectsLinesIds: [],
  setShowLinePopUp: { id: 0, show: true, event: {}, update: false },
  drawLineMode: {
    mode: "",
    drawStatus: false,
    show: false,
    features: [],
  },
  tmpDistanceFeatures: {
    type: "FeatureCollection",
    features: [],
    click: {},
  },
  objectRelationObject: {
    objectId: "",
  },

  modalRelationObject: {
    show: false,
    typeModal: "",
  },

  popUpRelationObject: {
    showPopUp: false,
  },

  infoRelationObject: {
    showInfoObject: false,
  },

  dataRelationObject: {
    dateObject: [],
  },

  dataBaseRelationObject: {
    relationDataBase: [],
  },

  seeObjectChildRelation: {
    showChild: false,
    objectLook: "",
  },

  infoFilterDate: {
    dateFilter: [],
  },

  mapSettings: {},

  linesFilters: {
    linesLibrariesFilters: [],
    linesTypesFilters: [],
  },

  showFilterLinesInMap: false,
};

const ShowDrawerDigitalTwinReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_LOADING_MAP:
      return {
        ...state,
        messageLoadingMap: action.payload,
      };
    case LINES_FILTERS:
      return {
        ...state,
        linesFilters: action.payload,
      };
    case MAP_SETTINGS:
      return {
        ...state,
        mapSettings: action.payload,
      };
    case INFO_FILTER_DATE:
      return {
        ...state,
        infoFilterDate: action.payload,
      };

    case SEE_OBJECT_CHILD_RELATION:
      return {
        ...state,
        seeObjectChildRelation: action.payload,
      };

    case DATABASE_RELATION_OBJECT:
      return {
        ...state,
        dataBaseRelationObject: action.payload,
      };
    case DATA_RELATION_OBJECT:
      return {
        ...state,
        dataRelationObject: action.payload,
      };
    case INFO_RELATION_OBJECT:
      return {
        ...state,
        infoRelationObject: action.payload,
      };

    case POPUP_RELATION_OBJECT:
      return {
        ...state,
        popUpRelationObject: action.payload,
      };
    case MODAL_RELATION_OBJECT:
      return {
        ...state,
        modalRelationObject: action.payload,
      };
    case SET_OBJECT_RELATION_OBJECT:
      return {
        ...state,
        objectRelationObject: action.payload,
      };
    case TMP_DISTANCE_FEATURES:
      return {
        ...state,
        tmpDistanceFeatures: action.payload,
      };
    case SETTING_MAP:
      return {
        ...state,
        settingMap: action.payload,
      };
    case SET_DRAWLINE_MODE:
      return {
        ...state,
        drawLineMode: action.payload,
      };
    case SHOW_LINE_POPUP:
      return {
        ...state,
        setShowLinePopUp: action.payload,
      };
    case CURRENT_UPDATE_LINE_ID:
      return {
        ...state,
        currentUpdateLineId: action.payload,
      };
    case CANCEL_DRAWING:
      return {
        ...state,
        cancelDrawing: action.payload,
      };
    case DATA_LINES:
      return {
        ...state,
        dataLines: action.payload,
      };
    case ORIGIN_DATA_LINES:
      return {
        ...state,
        originDataLines: action.payload,
      };
    case RELATION_POINT_LINES:
      return {
        ...state,
        relationPointsLines: action.payload,
      };
    case LOAD_DATA_POINTS:
      return {
        ...state,
        loadDataPoints: action.payload,
      };
    case STATE_FILTER_BUTTON_TABLE:
      return {
        ...state,
        stateFilterButtonTable: action.payload,
      };
    case SET_FILTER_EVENTS:
      return {
        ...state,
        stateFilterEvents: action.payload,
      };
    case SHOW_EVENTS:
      return {
        ...state,
        showEvents: action.payload,
      };

    case SHOW_EVENTS_BY_LIBRARY:
      return {
        ...state,
        showEventsByLibrary: action.payload,
      };

    case SHOW_EVENTS_BY_TYPE:
      return {
        ...state,
        showEventsByType: action.payload,
      };
    case SET_FILTER_COMPONENT:
      return {
        ...state,
        stateFilterComponent: action.payload,
      };
    case SHOW_DATA_COMPONENTS:
      return {
        ...state,
        showDataComponents: action.payload,
      };
    case SHOW_DATA_COMPONENTS_BY_TYPE:
      return {
        ...state,
        showDataComponentsByType: action.payload,
      };
    case SHOW_DATA_COMPONENTS_LIBRARY:
      return {
        ...state,
        showDataComponentsLibrary: action.payload,
      };
    case SET_DATA_COMPONENTS:
      return {
        ...state,
        dataComponents: action.payload,
      };
    case STATE_OBJECT_FILTER_TABLE:
      return {
        ...state,
        stateObjectFilterTable: action.payload,
      };
    case STATE_EVENT_FILTER_TABLE:
      return {
        ...state,
        stateEventFilterTable: action.payload,
      };
    case STATE_COMPONENT_FILTER_TABLE:
      return {
        ...state,
        stateComponentFilterTable: action.payload,
      };
    case DATA_NEW_TABLE_WINDOW:
      return {
        ...state,
        dataNewTableWindow: action.payload,
      };
    case DOWNLOAD_OPTION:
      return {
        ...state,
        optionDownload: action.payload,
      };
    case OPEN_SELECT_COLUMNS:
      return {
        ...state,
        openSelectColumns: action.payload,
      };
    case OPEN_SELECT_ORDER_VIEW_COLUMNS:
      return {
        ...state,
        openSelectOrderViewColumns: action.payload,
      };
    case SET_ORIGINAL_ORDER_VIEW_COLUMNS:
      return {
        ...state,
        originalOrderViewColumns: action.payload,
      };
    case CURRENT_TABLE_VIEW:
      return {
        ...state,
        currentTableView: action.payload,
      };
    case GET_TABLE_ROWS_FILTER:
      return {
        ...state,
        filterTableRows: action.payload,
      };
    case GET_TABLE_ROWS_FULL:
      return {
        ...state,
        fullTableRows: action.payload,
      };
    case DYNAMIC_ROW_NUM:
      return {
        ...state,
        rowNum: action.payload,
      };
    case SET_OBJECTS_DIGITAL_TWIN:
      return {
        ...state,
        objects: action.payload,
      };
    case SET_FILTERED_OBJECTS_DIGITAL_TWIN:
      return {
        ...state,
        filteredObjects: action.payload,
      };
    case SET_SHOW_TRACKINGS:
      return {
        ...state,
        showTrackings: action.payload,
      };
    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.payload,
      };
    case SET_FILTERED_OPERATIONS:
      return {
        ...state,
        filteredOperations: action.payload,
      };
    case SET_SHOW_LINES:
      return {
        ...state,
        showLines: action.payload,
      };
    case SET_FLY_TO:
      return {
        ...state,
        flyTo: action.payload,
      };
    case M_ZOOM_VIEW:
      return {
        ...state,
        mZoomView: action.payload,
      };
    case SET_OPERATION_CLUSTERS:
      return {
        ...state,
        operationClusters: action.payload,
      };
    case SET_OBJECT_CLUSTERS:
      return {
        ...state,
        objectClusters: action.payload,
      };
    case SET_COMPONENT_CLUSTERS:
      return {
        ...state,
        componentClusters: action.payload,
      };
    case SET_OBJECTS_UNDER_DRAWN_POINT:
      return {
        ...state,
        objectsUnderDrawnPoint: [...action.payload],
      };
    case SET_MAP:
      return {
        ...state,
        map: action.payload,
      };
    case SET_UPDATE_REGION:
      return {
        ...state,
        updateRegion: { ...action.payload },
      };
    case SET_DRAW_ANY_THING:
      return {
        ...state,
        drawAnyThing: action.payload,
      };
    case SET_DRAWER_CONTROL:
      return {
        ...state,
        drawerControl: action.payload,
      };
    case SET_FILTER_OBJECTS_BY_DATA:
      return {
        ...state,
        setFilterObjectsByData: action.payload,
      };
    case OPERATION_DETAILS:
      return {
        ...state,
        operationDetails: action.payload,
      };
    case SHOW_OPERATION_DETAILS:
      return {
        ...state,
        showOperationDetails: action.payload,
      };
    case SHOW_MARKER_BY_ELEMENT_WITH_MEDIA:
      return {
        ...state,
        showMarkerByElementWithMedia: action.payload,
      };
    case SHOW_DRAWER_DIGITAL_TWIN:
      return {
        ...state,
        showDrawerDigitalTwin: !state.showDrawerDigitalTwin,
      };
    case SHOW_DRAWER_OBJECT_DT:
      return {
        ...state,
        showDrawerObjectDt: action.payload,
      };
    case SHOW_CARD_MAKER_DIGITAL_TWIN:
      return {
        ...state,
        cardMarkerDigitalTwin: action.payload,
      };
    case SET_DATA_OBJECT_DT:
      return {
        ...state,
        objectDataDT: action.payload,
      };
    case SET_DATA_OBJECT_DT_IMAGES:
      return {
        ...state,
        objectDataDTImages: action.payload,
      };
    case SET_PROFILE_IMAGE:
      return {
        ...state,
        profileImage: action.payload,
      };
    case SHOW_MARKERS_DIGITAL_TWIN:
      return {
        ...state,
        showMarkersDigitalTwin: !state.showMarkersDigitalTwin,
      };

    // reducer all markers
    case SET_MARKER_BY_TYPE_ELEMENT_ID:
      return {
        ...state,
        showMarkerByTypeElementId: action.payload,
      };
    case SHOW_MARKERS_BY_ACTIVITIES:
      return {
        ...state,
        showMarkersByActivities: action.payload,
      };

    case SHOW_EVENTS_BY_INSPECTION_TYPE:
      return {
        ...state,
        showEventsByInspectionType: action.payload,
      };

    case SHOW_LABELS:
      return {
        ...state,
        showLabels: action.payload,
      };

    case SHOW_COMPONENT_LABELS:
      return {
        ...state,
        showComponentLabels: action.payload,
      };

    case SHOW_EVENT_LABEL:
      return {
        ...state,
        showEventLabels: action.payload,
      };

    case SHOW_OPERATIONS_BY_TYPE:
      return {
        ...state,
        showOperationsByType: action.payload,
      };

    case SET_DATA_EVENTS:
      return {
        ...state,
        dataEvents: action.payload,
      };

    case SET_TABLE_DETAILS_FORWARD:
      return {
        ...state,
        setTableDetailsForward: action.payload,
      };

    case SET_NEURON_SELECT_TOOL:
      return {
        ...state,
        setNeuronSelectTool: action.payload,
      };

    case SET_RADIUS_ELEMENTS_NEAR:
      return {
        ...state,
        radiusElementsNear: action.payload,
      };

    case OBJECTS_LINES_IDS:
      return {
        ...state,
        objectsLinesIds: action.payload,
      };
    case RESET_STATES:
      return initialState;

    case SHOW_FILTER_LINES_IN_MAP:
      return {
        ...state,
        showFilterLinesInMap: action.payload,
      };
    default:
      return state;
  }
};

export default ShowDrawerDigitalTwinReducer;
