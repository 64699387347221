import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  CSV_OBJECTS_TEMPLATE,
  CSV_EVENTS_TEMPLATE,
  CSV_COMPONENTS_TEMPLATE,
  CSV_LINES_TEMPLATE,
} from "utils/constStrings";
import { PRIMARY_COLOR } from "utils/const";
import saveCSVTemplateFile from "utils/downloadCSVTemplate";
import CustomButton from "components/Buttons/CustomButton";
import CustomSelect from "components/Buttons/CustomSelect";

const LibrarySelector = ({
  libraryLine,
  libraryComponent,
  libraryEvent,
  libraryObject,
}) => {
  const [selectedLibraryType, setSelectedLibraryType] = useState("");
  const [selectedLibrary, setSelectedLibrary] = useState(null);

  const handleLibraryTypeChange = (value) => {
    setSelectedLibraryType(value);
    const libraries = libraryOptions[value] || [];
    // Seleccionar automáticamente la primera librería si solo hay una
    if (libraries?.length > 0) {
      setSelectedLibrary(libraries[0]);
    } else {
      setSelectedLibrary(null); // Reiniciar si hay múltiples opciones
    }
  };

  const handleLibraryChange = (value) => {
    const selectedLib = libraryOptions[selectedLibraryType].find(
      (lib) => lib.id == value
    );
    setSelectedLibrary(selectedLib);
  };

  const handleDownloadCSVTemplate = (e) => {
    e.preventDefault();
    // Filtrar los campos que no se incluirán en el template
    const auxFields =
      selectedLibrary?.fields?.filter(
        (field) =>
          field.type !== "captureMedia" &&
          field.type !== "operation" &&
          field.type !== "notification"
      ) || [];

    // Construir el CSV con el template correspondiente
    const aux = {};
    let csvFields = [];
    auxFields.forEach((field) => {
      aux[field.name] = "";
    });

    if (selectedLibraryType === "Lineas") {
      csvFields = [{ ...CSV_LINES_TEMPLATE, ...aux }];
    }
    if (selectedLibraryType === "Componentes") {
      csvFields = [{ ...CSV_COMPONENTS_TEMPLATE, ...aux }];
    }
    if (selectedLibraryType === "Eventos") {
      csvFields = [{ ...CSV_EVENTS_TEMPLATE, ...aux }];
    }
    if (selectedLibraryType === "Objetos") {
      csvFields = [{ ...CSV_OBJECTS_TEMPLATE, ...aux }];
    }
    saveCSVTemplateFile(csvFields, "csv");
  };

  // Obtener las opciones de librerías basadas en el tipo seleccionado
  const libraryOptions = {
    Lineas: libraryLine,
    Componentes: libraryComponent,
    Eventos: libraryEvent,
    Objetos: libraryObject,
  };

  useEffect(() => {
    if (!selectedLibraryType || !libraryOptions[selectedLibraryType]) {
      handleLibraryTypeChange("Lineas");
    }
  }, [selectedLibraryType, selectedLibrary]);

  return (
    <Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "300px",
        }}
      >
        <CustomSelect
          label="Select Type Library:"
          options={[
            { id: "Lineas", name: "Lines" },
            { id: "Componentes", name: "Components" },
            { id: "Eventos", name: "Events" },
            { id: "Objetos", name: "Objects" },
          ]}
          select={selectedLibraryType}
          setSelect={handleLibraryTypeChange}
        />

        {selectedLibraryType &&
          libraryOptions[selectedLibraryType].length > 0 && (
            <CustomSelect
              label="Select Library:"
              options={libraryOptions[selectedLibraryType].map((library) => ({
                id: library.id,
                name: library.name,
              }))}
              select={selectedLibrary?.id || ""}
              setSelect={handleLibraryChange}
            />
          )}

        {selectedLibrary && (
          <div
            style={{
              alignSelf: "flex-start",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            <CustomButton
              primaryColor={PRIMARY_COLOR}
              text={"Download CSV Template"}
              onClick={handleDownloadCSVTemplate}
            />
          </div>
        )}
      </div>
    </Box>
  );
};

export default LibrarySelector;
