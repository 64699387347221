import React from "react";
import { useSelector } from "react-redux";
import { Marker } from "react-map-gl";
import { useState, useEffect } from "react";
import { MarkerElementContainer } from "components/Clusters/ClustersStyle";
import MarkerLabel from "./MarkerLabel";
import POINT_TYPE from "./const";
/**
 * Render a MarkerElement with the given props.
 *
 * @param {Object} element - The element object containing id, icon and location.
 * @param {function} handleClick - The function to handle click events on the element.
 * @return {JSX.Element} The rendered MarkerElement.
 */

const MarkerElement = React.memo(({ element, handleClick, zoom }) => {
  // los datos label, haveMedia, QAQC son opcionales y solamente son usados en los marker de objetos
  const { type, icon, id, location, label, haveMedia, QAQC, elementColor } =
    element;

  const [dataFlyTo, setDataFlyTo] = useState({});

  const objetSelected = useSelector(
    (state) => state.digitalTwinReducer.objectRelationObject.objectId
  );
  //Relation beetwen objects
  const objectDataBase = useSelector(
    (state) => state.digitalTwinReducer.dataBaseRelationObject
  );

  const mZoomView = useSelector((state) => state.digitalTwinReducer.mZoomView);

  //See object child

  const seeObjectChild = useSelector(
    (state) => state.digitalTwinReducer.seeObjectChildRelation
  );

  /** 
    flyTo es de la forma:
        {
          operation: {
            id: '',
            latitude: '',
            longitude: ''
          }
          object: {
            id: '',
            latitude: '',
            longitude: ''
          }
        }
    es necesario obtener el id que no sea vacio, esto depende de a que elemento se le hace flyto en la tabla, 
    el id que no sea un string vacio se guarda en @const {number} elementId
  */
  const flyTo = useSelector((state) => state.digitalTwinReducer.flyTo);
  const showLabelsElements = useSelector(
    (state) => state.digitalTwinReducer.showLabels
  );

  const showComponentLabels = useSelector(
    (state) => state.digitalTwinReducer.showComponentLabels
  );

  const showEventLabels = useSelector(
    (state) => state.digitalTwinReducer.showEventLabels
  );

  const { isDraw: drawAnyThing, type: actionType } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );
  //const busqueda2= objectDataBase.map((object) => (
  // object.pointObject.map((element) => element.id)))

  // Verificar si el ID existe en cualquier lugar de objectDataBase
  let isRelated = false;

  if (objectDataBase && Array.isArray(objectDataBase)) {
    isRelated = objectDataBase.some(
      (obj) =>
        Array.isArray(obj.pointObject) &&
        obj.pointObject.some((point) => point.id === id)
    );
  }

  let seeObjectSon = false;

  if (seeObjectChild.showChild) {
    seeObjectSon = seeObjectChild.objectLook === id;
  }
  // Effect to handle localStorage event and update dataFlyTo
  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === "dataMarketPoint") {
        const res = JSON.parse(localStorage.getItem("dataMarketPoint"));
        setDataFlyTo(res);
      }
    };
    window.addEventListener("storage", handleStorageEvent);
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  // Effect to handle elementId and update dataFlyTo
  useEffect(() => {
    let elementId = null;
    for (const key in flyTo) {
      const id = flyTo[key].id;
      if (typeof id === "number" || (typeof id === "string" && id !== "")) {
        elementId = id;
        break;
      }
    }
    if (elementId !== null) {
      setDataFlyTo({ id: elementId });
    } else {
      setDataFlyTo({ id: null });
    }
  }, [flyTo]);

  return (
    <>
      <Marker
        latitude={Number(location.latitude)}
        longitude={Number(location.longitude)}
      >
        <MarkerElementContainer
          filter={haveMedia === undefined ? "none" : haveMedia}
          onClick={() => handleClick(id)}
          flyTo={id === dataFlyTo.id}
          drawInMap={drawAnyThing}
          actionType={actionType}
          qaqc={QAQC}
          objectRelation={objetSelected.id === id}
          objectRelationSon={isRelated}
          lookObjectSon={seeObjectSon}
          colorPoint={
            mZoomView.color && elementColor !== undefined
              ? elementColor
              : undefined
          }
        >
          {/* aqui se escoje el color que se aplica a cada punto */}
          {mZoomView.color &&
          zoom <= mZoomView.zoom &&
          type === POINT_TYPE.object ? (
            <span className="color-cluster-object"></span>
          ) : mZoomView.color &&
            zoom <= mZoomView.zoom &&
            type === POINT_TYPE.component ? (
            <span className="color-cluster-component"></span>
          ) : mZoomView.color &&
            zoom <= mZoomView.zoom &&
            type === POINT_TYPE.event ? (
            <span className="color-cluster-event"></span>
          ) : (
            <img src={icon} alt="nameplate marker" />
          )}

          {mZoomView.icon && <span className="qaqc"></span>}
          <span className="selected"></span>
          <span className="selectedObject"></span>
          <span className="selectedObjectSon"></span>
          {<span className="selectedLookObjectSon"></span>}
        </MarkerElementContainer>
      </Marker>
      {showLabelsElements && type === POINT_TYPE.object && (
        <MarkerLabel
          latitude={location.latitude}
          longitude={location.longitude}
          label={label}
        />
      )}
      {showComponentLabels && type === POINT_TYPE.component && (
        <MarkerLabel
          latitude={location.latitude}
          longitude={location.longitude}
          label={label}
        />
      )}
      {showEventLabels && type === POINT_TYPE.event && (
        <MarkerLabel
          latitude={location.latitude}
          longitude={location.longitude}
          label={label}
        />
      )}
    </>
  );
});

export default MarkerElement;
