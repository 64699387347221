import GlobalConstantsImg from "images/GlobalConstantsImg";
import NeuronaIconD from "images/NeuronaIconD";
import Settingsicon from "images/Settingsicon";

const contentCardsTools = [
  {
    id: 1,
    title: "GLOBAL CONSTANTS",
    image: <GlobalConstantsImg />,
    description: "Add or edit your global constants",
    toNavigate: "/config-globalConstants",
  },
  {
    id: 2,
    title: "GLOBAL TABLES",
    image: <NeuronaIconD />,
    description: "Add or Edit Categories of Tables and Global tables with their values",
    toNavigate: "/config-globalValues-Fields",
  },
  {
    id: 2,
    title: "STYLE PREFERENCES",
    image: <Settingsicon />,
    description: "Customize app settings and preferences",
    toNavigate: "/config-preferences",
  },
];

export default contentCardsTools;
