import Layout from "components/Layout/AdminDecimetrix";
import {
  InformationSection,
  Content,
  ConfigurationSection,
  ConfigLibrary,
  ConfigConstant,
  ContentButton,
  ContentData,
  InformationShare,
  Form,
  Title,
  ContentTablesTags,
  TableTag,
  ContentDataTag,
  Columns,
  Column,
  Space,
  GlobalContent,
  Divider,
  SelectCategoriesStyles,
  ContainerSelect,
  customSelectStyle,
  ContainerInput,
  GlobalFieldValue,
  ContainerConfigGlobalValue,
  ContainerSelectGlobalList,
  ContainerValue,
} from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "components/Buttons/CustomButton";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import TextInput from "components/Forms/GenericInput";
import useSWR from "swr";
import { useSWRConfig } from "swr";
import {
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";
import OpenDialog from "components/Dialogs/OpenDialog";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor2, StyleColor3 } from "Styles";
import SelectInput from "components/Forms/SelectInput";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import { FROM_TABLE } from "utils/constStrings";
import Select from "react-select/creatable";
import CancelIcon from "@mui/icons-material/Cancel";
import saveGlobalMeasurementTable from "services/GlobalMeasurementTable/saveGlobalMeasurementTablet";
import updateGlobalMeasurementTable from "services/GlobalMeasurementTable/updateGlobalMeasurementTable";
import { Link } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import uploadCSVGlobalMeasurementTable from "./uploadCSVGlobalMeasurementTable";
import Papa from "papaparse";
import {
  ModalContainer,
  ModalContent,
  ModalErrorList,
  ModalHeadeError,
  ModalHeader,
  SuccessMessage,
} from "../GlobalValuesFields/styles";
import moment from "moment";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { ShowAllButton2 } from "../Preferences/preferencesstyles";
import { LinearProgress, Typography, Box } from "@mui/material";
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
import { getPathBucket } from "helpers/getPathBucket";
import { formatName } from "helpers/formatName";
import { pathFolder } from "utils/paths";
import uploadCSV from "services/GlobalMeasurementTable/uploadCSV";
import { urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";
 
const ProgressBar = ({ progress }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress variant="determinate" value={progress} />
      <Typography variant="body2" color="textSecondary">
        {`Progress: ${Math.round(progress)}%`}
      </Typography>
    </Box>
  );
};

const saveCSVTemplateFile = (data, fileType) => {
  try {
    // Verificamos que tengamos datos
    if (!data || !data.length) {
      throw new Error("No hay datos para procesar");
    }

    // Obtenemos los headers del primer objeto
    const headers = Object.keys(data[0]);

    // Creamos las filas del CSV
    const csvRows = [
      // Primera fila: nombres de las columnas (headers)
      headers.join(","),
      // Resto de filas: convertimos cada objeto a una fila de CSV
      ...data.map((row) =>
        headers
          .map((header) => {
            // Escapamos las comas y las comillas dobles si existen en el valor
            const value = row[header]?.toString() || "";
            if (value.includes(",") || value.includes('"')) {
              return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
          })
          .join(",")
      ),
    ];

    // Unimos todas las filas con saltos de línea
    const csvString = csvRows.join("\n");

    // Agregamos BOM para mejor compatibilidad con Excel
    const BOM = "\uFEFF";
    const csvContentWithBOM = BOM + csvString;

    // Creamos el blob con el contenido
    const blob = new Blob([csvContentWithBOM], {
      type: "text/csv;charset=utf-8;",
    });

    // Creamos el link para descargar
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `Template-${new Date().toISOString()}.${fileType}`
    );
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error al generar el CSV:", error);
  }
};

const GlobalMeasurementTable = () => {
  const navigate = useNavigate();

  const { mutate } = useSWRConfig();
  const instanceId = useUserStore.getState().instanceId;

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEditCategory, setOpenDialogEditCategory] = useState(false);
  const [openDialogCreateTable, setOpenDialogCreateTable] = useState(false);
  const [openDialogCreateGlobalValue, setOpenDialogCreateGlobalValue] =
    useState(false);
  const [openDialogUpdateGlobalValue, setOpenDialogUpdateGlobalValue] =
    useState(false);
  const [currentGlobalTable, setCurrentGlobalTable] = useState(null);
  const [deleteTable, setDeleteTable] = useState(false);
  const [deleteGlobalValue, setDeleteGlobalValue] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const [selectDeleteIndexOption, setSelectDeleteIndexOption] = useState(null);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "",
    disagree: "Cancel",
    agree: "",
  });
  const [selectTableId, setSelectTableId] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState({
    id: null,
    name: "",
  });
  const { theme } = useContext(ThemeContext);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataGlobalTables, setDataGlobalTables] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [dataFormColumnTable, setDataFormColumnTable] = useState({
    tableName: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageFile, setErrorMessageFile] = useState(false);
  const inputFileRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState([]); // Aquí guardaremos los datos parseados del CSV
  const [errorMatchHeader, setErrorMatchHeader] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false); // Controla si mostramos el modal de confirmación
  const [showSummary, setShowSummary] = useState(false); // Controla si mostramos el modal de resumen
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [errorHeader, setErrorHeader] = useState("");
  const [columnsGlobalTable, setColumnsGlobalTable] = useState([]);
  const [currentDataTypeSelected, SetCurrentDataTypeSelected] = useState("");
  const [invalidRowsBackend, setinvalidRowsBackend] = useState([]);
  // Information module Global List Table ---------------------------------------------
  const [dataGlobalListTables, setDataGlobalListTables] = useState([]);
  const [currentGlobalListTables, setCurrentGlobalListTables] = useState({});
  const [selectedGlobalListTable, setSelectedGlobalListTable] = useState({});
  const [dataListCategories, setDataListCategories] = useState([]);
  const [currentListCategory, setCurrentListCategory] = useState(null);

  const [globalValues, setGlobalValues] = useState({
    columnSelected: "",
    columnSelectedId: null,
  });

  const { data: globalListTables, error: errorGlobalListTables } = useSWR(
    urlsApiMg.globalListTalble
  );

  useEffect(() => {
    if (
      globalListTables &&
      !errorGlobalListTables &&
      globalListTables.length > 0
    ) {
      setDataListCategories(globalListTables[0]?.tableCategories);
      if (globalListTables[0]?.tableCategories.length > 0) {
        const existingTable = globalListTables[0]?.tableCategories.filter(
          (elm) => elm.groupTables.length > 0
        );
        setDataGlobalListTables(existingTable[0]?.groupTables);
        setCurrentListCategory(existingTable[0]?._id);
        setSelectedGlobalListTable(existingTable[0]?.groupTables[0]);
        setCurrentGlobalListTables(existingTable[0]?.groupTables[0].id);
        setGlobalValues((current) => ({
          ...current,
          columnSelected:
            existingTable[0]?.groupTables[0]?.dataColumns[1]?.name,
          columnSelectedId:
            existingTable[0]?.groupTables[0]?.dataColumns[1]?._id,
        }));
        setColumnsGlobalTable([
          {
            Columns: existingTable[0]?.groupTables[0]?.dataColumns[1]?.name,
            DisplayName: existingTable[0]?.groupTables[0]?.dataColumns[1]?.name,
            Type: "select",
            Require: "true",
            Tools: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  disabled={true}
                  margin={5}
                  primaryColor={VISIBILITY_OFF_ICON}
                  secondaryColor={VISIBILITY_OFF_ICON}
                />
              </div>
            ),
          },
          {
            Columns: "Association",
            DisplayName: "Association",
            Type: "boolean",
            Require: "false",
            Tools: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  disabled={true}
                  margin={5}
                  primaryColor={VISIBILITY_OFF_ICON}
                  secondaryColor={VISIBILITY_OFF_ICON}
                />
              </div>
            ),
          },
          {
            Columns: "Synchronized",
            DisplayName: "Synchronized",
            Type: "boolean",
            Require: "false",
            Tools: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  disabled={true}
                  margin={5}
                  primaryColor={VISIBILITY_OFF_ICON}
                  secondaryColor={VISIBILITY_OFF_ICON}
                />
              </div>
            ),
          },
          {
            Columns: "MeasurementDate",
            DisplayName: "Measurement Date",
            Type: "date",
            Require: "true",
            Tools: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  disabled={true}
                  margin={5}
                  primaryColor={VISIBILITY_OFF_ICON}
                  secondaryColor={VISIBILITY_OFF_ICON}
                />
              </div>
            ),
          },
        ]);
      }
    }
  }, [globalListTables, errorGlobalListTables]);

  // Handel change data select module Global List Table ---------------------------------------------

  const handleSelectListCategory = (e) => {
    const categorySelected = dataListCategories.find(
      (elm) => elm._id === e.target.value
    );
    setCurrentListCategory(e.target.value);
    setDataGlobalListTables(categorySelected?.groupTables);
    setSelectedGlobalListTable(categorySelected?.groupTables[0]);
    setCurrentGlobalListTables(categorySelected?.groupTables[0]?._id);
    setGlobalValues((current) => ({
      ...current,
      columnSelected: categorySelected?.groupTables[0]?.dataColumns[1]?.name,
      columnSelectedId: categorySelected?.groupTables[0]?.dataColumns[1]?._id,
    }));
    setColumnsGlobalTable((current) => {
      const masterKeyColumn = {
        Columns: categorySelected?.groupTables[0]?.dataColumns[1]?.name,
        DisplayName: categorySelected?.groupTables[0]?.dataColumns[1]?.name,
        Type: "select",
        Require: "true",
        Tools: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              type="onClick"
              text={<DeleteIcon />}
              disabled={true}
              margin={5}
              primaryColor={VISIBILITY_OFF_ICON}
              secondaryColor={VISIBILITY_OFF_ICON}
            />
          </div>
        ),
      };
      return [
        masterKeyColumn,
        ...current.filter((elm) => elm.Type !== "select"),
      ];
    });
  };

  const handelSelectGlobalTable = (e) => {
    e.preventDefault();
    setCurrentGlobalListTables(e.target.value);
    const currentSelectedTable = dataGlobalListTables.find(
      (elm) => elm._id === e.target.value
    );
    setSelectedGlobalListTable(currentSelectedTable);
    setGlobalValues((current) => ({
      ...current,
      columnSelected: currentSelectedTable?.dataColumns[1]?.name,
      columnSelectedId: currentSelectedTable?.dataColumns[1]?._id,
    }));
    setColumnsGlobalTable((current) => {
      const masterKeyColumn = {
        Columns: currentSelectedTable?.dataColumns[1]?.name,
        DisplayName: currentSelectedTable?.dataColumns[1]?.name,
        Type: "select",
        Require: "true",
        Tools: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              type="onClick"
              text={<DeleteIcon />}
              disabled={true}
              margin={5}
              primaryColor={VISIBILITY_OFF_ICON}
              secondaryColor={VISIBILITY_OFF_ICON}
            />
          </div>
        ),
      };
      return [
        masterKeyColumn,
        ...current.filter((elm) => elm.Type !== "select"),
      ];
    });
  };

  const handelSelectGlobalValues = ({ event, columnSelected }) => {
    if (columnSelected) {
      const selectedColumn = selectedGlobalListTable.dataColumns.find(
        (elm) => elm.name === event.target.value
      );
      setGlobalValues((current) => ({
        ...current,
        columnSelected: selectedColumn.name,
        columnSelectedId: selectedColumn._id,
      }));
      setColumnsGlobalTable((current) => {
        const masterKeyColumn = {
          Columns: selectedColumn.name,
          DisplayName: selectedColumn.name,
          Type: "select",
          Require: "true",
          Tools: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                type="onClick"
                text={<DeleteIcon />}
                disabled={true}
                margin={5}
                primaryColor={VISIBILITY_OFF_ICON}
                secondaryColor={VISIBILITY_OFF_ICON}
              />
            </div>
          ),
        };
        return [
          masterKeyColumn,
          ...current.filter((elm) => elm.Type !== "select"),
        ];
      });
    }
  };

  // Forms by type function --------------------------------------------

  const {
    register: registerGlobalTable,
    handleSubmit: handleSubmitGlobalTable,
    formState: { errors: errorsGlobalTable },
    setValue: setValueGlobalTable,
    reset: resetInputsGlobalTable,
    setError: setErrorGlobalValue,
    clearErrors: clearErrorsGlobalValue,
    getValues: getValuesGlobalTable,
  } = useForm();

  const {
    register: registerGlobalValue,
    watch: watchGlobalValue,
    handleSubmit: handleSubmitGlobalValue,
    formState: { errors: errorsGlobalValue },
    setValue: setValueGlobalValue,
    reset: resetInputsGlobalValue,
    setError: setErrorCreateGlobalValue,
    clearErrors: clearErrorsCreateGlobalValue,
    unregister: unregisterCreateGlobalValue,
    getValues: getValuesCreateGlobalValue,
  } = useForm();

  const {
    register: registerUpdateGlobalValue,
    watch: watchUpdateGlobalValue,
    handleSubmit: handleSubmitUpdateGlobalValue,
    formState: { errors: errorsUpdateGlobalValue },
    setValue: setValueUpdateGlobalValue,
    reset: resetUpdateGlobalValue,
    setError: setErrorUpdateGlobalValue,
    clearErrors: clearErrorsUpdateGlobalValue,
    unregister: unregisterUpdateGlobalValue,
    getValues: getValuesUpdateGlobalValue,
  } = useForm();

  const clearAllDataUpdateGlobalValues = () => {
    const keys = Object.keys(getValuesUpdateGlobalValue());
    keys.forEach((key) => unregisterUpdateGlobalValue(key));
    resetUpdateGlobalValue({});
  };

  const clearAllDataCreateGlobalValues = () => {
    const keys = Object.keys(getValuesCreateGlobalValue());
    keys.forEach((key) => unregisterCreateGlobalValue(key));
    resetInputsGlobalValue({});
  };

  const url = urlsApiMg.globalMeasurementTable;
  const { data: globalMeasurementTables, error: errorGlobalMeasurementTables } =
    useSWR(url);

  useEffect(() => {
    if (
      globalMeasurementTables &&
      !errorGlobalMeasurementTables &&
      globalMeasurementTables.length > 0
    ) {
      setDataCategories(globalMeasurementTables[0]?.tableCategories);
      if (globalMeasurementTables[0]?.tableCategories.length > 0) {
        setDataGlobalTables(
          globalMeasurementTables[0]?.tableCategories[0]?.groupTables
        );
        setCurrentCategory(globalMeasurementTables[0]?.tableCategories[0]._id);
      }
    }
  }, [globalMeasurementTables, errorGlobalMeasurementTables]);

  const uniqueCategoryNames = [
    ...new Set(dataCategories?.map((elm) => elm?.name)),
  ];

  const options = uniqueCategoryNames.map((catgory) => ({
    value: catgory,
    label: <ContainerSelect>{catgory}</ContainerSelect>,
  }));

  const handleAddNewColumn = async (data) => {
    let optionExists = false;
    columnsGlobalTable.forEach((elm) => {
      if (elm.Columns === data["Column Name"]) {
        optionExists = true;
      }
    });

    if (!optionExists) {
      setDataFormColumnTable({
        tableName: data["Table Name"],
      });
      setColumnsGlobalTable((current) => [
        ...current,
        {
          Columns: data["Column Name"],
          DisplayName: data["Display Name"],
          Type: data["Data Type"],
          Require: data["Require"].toString(),
          Min: data["Min"],
          Max: data["Max"],
          Step: data["Step"],
          Tools: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CustomButton
                type="onClick"
                text={<DeleteIcon />}
                onClick={() =>
                  setColumnsGlobalTable((current) =>
                    current.filter((elm) => elm.Columns !== data["Column Name"])
                  )
                }
                margin={5}
                primaryColor={DELETE_COLOR}
                secondaryColor={HOVER_DELETE_COLOR}
              />
            </div>
          ),
        },
      ]);
      setValueGlobalTable("Column Name", "");
      setValueGlobalTable("Display Name", "");
      setValueGlobalTable("Data Type", "string");
      setValueGlobalTable("Min", 0);
      setValueGlobalTable("Max", 0);
      setValueGlobalTable("Step", 1);
      setValueGlobalTable("Require", false);
      clearErrorsGlobalValue("Column Name");
    } else {
      setErrorGlobalValue("Column Name", {
        type: "manual",
        message: `The "${data["Column Name"]}" column already exists.`,
      });
    }
  };
  const handelSaveTable = async () => {
    if (globalMeasurementTables.length === 0) {
      await saveGlobalMeasurementTable({
        instanceId,
        tableCategories: [
          {
            name: getValuesGlobalTable("selectCategory"),
            groupTables: [
              {
                name: dataFormColumnTable.tableName,
                associationGlobalListTable: {
                  tableId: currentGlobalListTables,
                  categoryId: currentListCategory,
                },
                dataColumns: columnsGlobalTable.map((elm) => {
                  return {
                    name: elm.Columns,
                    alias: elm.DisplayName,
                    dataType: elm.Type,
                    min: elm.Min,
                    max: elm.Max,
                    step: elm.Step,
                    require: JSON.parse(elm.Require),
                    masterKey:
                      elm.Columns === globalValues.columnSelected
                        ? true
                        : false,
                    dataMasterKey:
                      elm.Columns === globalValues.columnSelected
                        ? {
                            categoryId: currentListCategory,
                            tableId: selectedGlobalListTable._id,
                            column: {
                              id: globalValues.columnSelectedId,
                              name: globalValues.columnSelected,
                            },
                          }
                        : {},
                  };
                }),
                headers: columnsGlobalTable.map((elm) => elm.Columns),
                rows: [],
              },
            ],
          },
        ],
      });
    } else {
      if (selectTableId) {
        await updateGlobalMeasurementTable({
          id: globalMeasurementTables[0]._id,
          body: {
            instanceId,
            tableCategories: dataCategories.map((elm) => {
              if (elm._id === currentCategory) {
                return {
                  ...elm,
                  groupTables: dataGlobalTables.map((item) => {
                    if (item._id === selectTableId) {
                      const currentHeaders = columnsGlobalTable.map(
                        (clm) => clm.Columns
                      );
                      return {
                        ...item,
                        name: getValuesGlobalTable("Table Name"),
                        associationGlobalListTable: {
                          tableId: currentGlobalListTables,
                          categoryId: currentListCategory,
                        },
                        dataColumns: columnsGlobalTable.map((data) => {
                          return {
                            name: data.Columns,
                            alias: data.DisplayName,
                            dataType: data.Type,
                            min: data.Min,
                            max: data.Max,
                            step: data.Step,
                            require: JSON.parse(data.Require),
                            masterKey:
                              data.Columns === globalValues.columnSelected
                                ? true
                                : false,
                            dataMasterKey:
                              data.Columns === globalValues.columnSelected
                                ? {
                                    categoryId: currentListCategory,
                                    tableId: selectedGlobalListTable._id,
                                    column: {
                                      id: globalValues.columnSelectedId,
                                      name: globalValues.columnSelected,
                                    },
                                  }
                                : {},
                          };
                        }),
                        headers: currentHeaders,
                        rows: item.rows.map((option) => {
                          const filteredOption = { ...option };
                          for (let key in filteredOption) {
                            if (!currentHeaders.includes(key)) {
                              delete filteredOption[key];
                            }
                          }
                          return filteredOption;
                        }),
                      };
                    }
                    return item;
                  }),
                };
              }
              return elm;
            }),
          },
        });
      } else {
        const newCategories = dataCategories.map((elm) => {
          if (elm.name === getValuesGlobalTable("selectCategory")) {
            return {
              ...elm,
              groupTables: [
                ...elm.groupTables,
                {
                  name: dataFormColumnTable.tableName,
                  associationGlobalListTable: {
                    tableId: currentGlobalListTables,
                    categoryId: currentListCategory,
                  },
                  dataColumns: columnsGlobalTable.map((column) => {
                    return {
                      name: column.Columns,
                      alias: column.DisplayName,
                      dataType: column.Type,
                      min: column.Min,
                      max: column.Max,
                      step: column.Step,
                      require: JSON.parse(column.Require),
                      masterKey:
                        column.Columns === globalValues.columnSelected
                          ? true
                          : false,
                      dataMasterKey:
                        column.Columns === globalValues.columnSelected
                          ? {
                              categoryId: currentListCategory,
                              tableId: selectedGlobalListTable._id,
                              column: {
                                id: globalValues.columnSelectedId,
                                name: globalValues.columnSelected,
                              },
                            }
                          : {},
                    };
                  }),
                  headers: columnsGlobalTable.map((column) => column.Columns),
                  rows: [],
                },
              ],
            };
          } else {
            return elm;
          }
        });
        const categoryExists = dataCategories.some(
          (elm) => elm.name === getValuesGlobalTable("selectCategory")
        );
        if (!categoryExists) {
          newCategories.push({
            name: getValuesGlobalTable("selectCategory"),
            groupTables: [
              {
                name: dataFormColumnTable.tableName,
                associationGlobalListTable: {
                  tableId: currentGlobalListTables,
                  categoryId: currentListCategory,
                },
                dataColumns: columnsGlobalTable.map((column) => {
                  return {
                    name: column.Columns,
                    alias: column.DisplayName,
                    dataType: column.Type,
                    min: column.Min,
                    max: column.Max,
                    step: column.Step,
                    require: JSON.parse(column.Require),
                    masterKey:
                      column.Columns === globalValues.columnSelected
                        ? true
                        : false,
                    dataMasterKey:
                      column.Columns === globalValues.columnSelected
                        ? {
                            categoryId: currentListCategory,
                            tableId: selectedGlobalListTable._id,
                            column: {
                              id: globalValues.columnSelectedId,
                              name: globalValues.columnSelected,
                            },
                          }
                        : {},
                  };
                }),
                headers: columnsGlobalTable.map((column) => column.Columns),
                rows: [],
              },
            ],
          });
        }
        await updateGlobalMeasurementTable({
          id: globalMeasurementTables[0]._id,
          body: {
            instanceId,
            tableCategories: newCategories,
          },
        });
      }
    }
    setColumnsGlobalTable([]);
    setSelectTableId(null);
    resetInputsGlobalTable();
    setOpenDialogCreateTable(false);
    mutate(url);
  };

  const handelCreateGlobalValue = async (data) => {
    const masterKey = currentGlobalTable.dataColumns.find(
      (elm) => elm.masterKey
    );
    const fileFields = currentGlobalTable.dataColumns.filter(
      (clm) => clm.dataType === "file"
    );
    let status;

    if (fileFields.length > 0) {
      const fileUploads = fileFields
        .filter((fileField) => data[fileField.name])
        .map(async (fileField) => {
          const folderPath = getPathBucket(
            pathFolder.measurementData(formatName(fileField.name))
          );

          const files = data[fileField.name];
          const { fileUrl: urlFile } = await uploadFileToS3({
            file: files[0],
            folderPath,
          });
          return {
            fieldName: fileField.name,
            url: urlFile,
          };
        });

      const uploadResults = await Promise.all(fileUploads);
      uploadResults.forEach((result) => {
        data[result.fieldName] = result.url;
      });
    }

    for (let key in data) {
      if (masterKey.name === key) {
        const isAssociate = currentGlobalTable.rows.some(
          (objeto) =>
            objeto[key] === data[masterKey.name] &&
            objeto.Association === "true"
        );
        status = isAssociate;
      }

      if (key === "Association") {
        data[key] = status ? "true" : "false";
      }
      if (key === "Synchronized") {
        data[key] = "false";
      }

      if (key === "MeasurementDate") {
        data[key] =
          moment(data[key]).format("YYYY-MM-DD") +
          " " +
          moment().format("HH:mm:ss");
      }

      if (typeof data[key] === "boolean") {
        data[key] = data[key].toString();
      } else if (!isNaN(data[key])) {
        data[key] = parseFloat(data[key]);
      }
    }

    await updateGlobalMeasurementTable({
      id: globalMeasurementTables[0]._id,
      body: {
        instanceId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === currentCategory) {
            return {
              ...elm,
              groupTables: dataGlobalTables.map((elm) => {
                if (elm._id === currentGlobalTable._id) {
                  return {
                    ...elm,
                    rows: [...elm.rows, data],
                  };
                }
                return elm;
              }),
            };
          }
          return elm;
        }),
      },
    });

    setCurrentGlobalTable((current) => ({
      ...current,
      rows: [...current.rows, data],
    }));

    setDataGlobalTables((current) =>
      current.map((elm) => {
        if (elm._id === currentGlobalTable._id) {
          return {
            ...elm,
            rows: [...elm.rows, data],
          };
        }
        return elm;
      })
    );
    setOpenDialogCreateGlobalValue(false);
    resetInputsGlobalValue();
  };

  const handelUpdateGlobalValue = async (data) => {
    const fileFields = currentGlobalTable.dataColumns.filter(
      (clm) => clm.dataType === "file"
    );

    if (fileFields.length > 0) {
      const fileUploads = fileFields
        .filter((fileField) => data[fileField.name])
        .map(async (fileField) => {
          const files = data[fileField.name];

          const folderPath = getPathBucket(
            pathFolder.measurementData(formatName(fileField.name))
          );

          const { fileUrl: urlFile } = await uploadFileToS3({
            file: files[0],
            folderPath,
            isUrl: data[fileField.name],
          });

          return {
            fieldName: fileField.name,
            url: urlFile,
          };
        });

      const uploadResults = await Promise.all(fileUploads);
      uploadResults.forEach((result) => {
        data[result.fieldName] = result.url;
      });
    }

    let valueIndexOption = data["index"];
    for (let key in data) {
      if (typeof data[key] === "boolean") {
        data[key] = data[key].toString();
      } else if (!isNaN(data[key])) {
        data[key] = parseFloat(data[key]);
      }
    }
    setCurrentGlobalTable((current) => ({
      ...current,
      rows: current.rows.map((elm, index) => {
        if (index === valueIndexOption) {
          return data;
        }
        return elm;
      }),
    }));
    setDataGlobalTables((current) =>
      current.map((elm) => {
        if (elm._id === currentGlobalTable._id) {
          return {
            ...elm,
            rows: elm.rows.map((item, index) => {
              if (index === valueIndexOption) {
                return data;
              }
              return item;
            }),
          };
        }
        return elm;
      })
    );
    await updateGlobalMeasurementTable({
      id: globalMeasurementTables[0]._id,
      body: {
        instanceId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === currentCategory) {
            return {
              ...elm,
              groupTables: dataGlobalTables.map((elm) => {
                if (elm._id === currentGlobalTable._id) {
                  return {
                    ...elm,
                    rows: elm.rows.map((item, index) => {
                      if (index === valueIndexOption) {
                        return data;
                      }
                      return item;
                    }),
                  };
                }
                return elm;
              }),
            };
          }
          return elm;
        }),
      },
    });
    setOpenDialogUpdateGlobalValue(false);
    resetUpdateGlobalValue();
    clearAllDataUpdateGlobalValues();
  };

  const handelDeleteTables = async () => {
    await updateGlobalMeasurementTable({
      id: globalMeasurementTables[0]._id,
      body: {
        instanceId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === currentCategory) {
            return {
              ...elm,
              groupTables: dataGlobalTables.filter((item) => {
                return item._id !== dataDelete._id;
              }),
            };
          }
          return elm;
        }),
      },
    });
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setDataDelete({});
    setOpenDialog(false);
    mutate(url);
  };

  const handelDeleteGlobalValues = async () => {
    setCurrentGlobalTable((current) => ({
      ...current,
      rows: current.rows
        .map((item, index) => {
          if (index === selectDeleteIndexOption) {
            return null;
          }
          return item;
        })
        .filter((it) => it !== null),
    }));

    setDataGlobalTables((current) =>
      current.map((elm) => {
        if (elm._id === currentGlobalTable._id) {
          return {
            ...elm,
            rows: elm.rows
              .map((item, index) =>
                index === selectDeleteIndexOption ? null : item
              )
              .filter((it) => it !== null),
          };
        }
        return elm;
      })
    );
    await updateGlobalMeasurementTable({
      id: globalMeasurementTables[0]._id,
      body: {
        instanceId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === currentCategory) {
            return {
              ...elm,
              groupTables: dataGlobalTables.map((elm) => {
                if (elm._id === currentGlobalTable._id) {
                  return {
                    ...elm,
                    rows: elm.rows
                      .map((item, index) => {
                        if (index === selectDeleteIndexOption) {
                          return null;
                        }
                        return item;
                      })
                      .filter((it) => it !== null),
                  };
                }
                return elm;
              }),
            };
          }
          return elm;
        }),
      },
    });
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setDataDelete({});
    setOpenDialog(false);
  };

  const handelDeleteCategory = async () => {
    await updateGlobalMeasurementTable({
      id: globalMeasurementTables[0]._id,
      body: {
        instanceId,
        tableCategories: dataCategories.filter((elm) => {
          return elm._id !== categoryToEdit.id;
        }),
      },
    });
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setCategoryToEdit({ id: null, name: "" });
    setOpenDialog(false);
    mutate(url);
  };

  const handelUpdateCategory = async () => {
    await updateGlobalMeasurementTable({
      id: globalMeasurementTables[0]._id,
      body: {
        instanceId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === categoryToEdit.id) {
            return {
              ...elm,
              name: categoryToEdit.name,
            };
          }
          return elm;
        }),
      },
    });
    mutate(url);
    setOpenDialogEditCategory(false);
  };

  const handleOpenDialogCreateGlobalValue = () => {
    clearAllDataCreateGlobalValues();
    currentGlobalTable.dataColumns.forEach((elm) => {
      if (elm.dataType === "string") {
        setValueGlobalValue(elm.name, "");
      }
      if (elm.dataType === "number") {
        setValueGlobalValue(elm.name, 0);
      }
    });
    setOpenDialogCreateGlobalValue(true);
  };

  const handleOpenDialogEditGlobalValue = ({ globalValue, index }) => {
    clearAllDataUpdateGlobalValues();
    setOpenDialogUpdateGlobalValue(true);
    for (let key in globalValue) {
      setValueUpdateGlobalValue(key, globalValue[key]);
    }
    setValueUpdateGlobalValue("index", index);
  };

  const handleOpenDialogDeleteValue = ({ e, index }) => {
    e.preventDefault();
    setDeleteGlobalValue(true);
    setSelectDeleteIndexOption(index);
    setContentDialog({
      title: "Delete Global Value",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the option #<strong>{index + 1}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogDeleteTable = ({ e, table }) => {
    e.preventDefault();
    setDeleteTable(true);
    setDataDelete(table);
    setContentDialog({
      title: "Delete Global Measurement Table",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the table <strong>{table.name}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogDeleteCategory = ({ categoryId, name }) => {
    setDeleteCategory(true);
    setCategoryToEdit({ id: categoryId, name: name });
    setContentDialog({
      title: "Delete Category",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the category <strong>{name}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogUpdate = ({ e, table }) => {
    e.preventDefault();
    setValueGlobalTable("Table Name", table.name);
    setValueGlobalTable("Select Name", table.selectName);
    setValueGlobalTable("Column Name", "");
    setValueGlobalTable("Display Name", "");
    setValueGlobalTable("Data Type", "string");
    setDataFormColumnTable({
      tableName: table.name,
    });
    setSelectTableId(table._id);
    const currentCategoryListTable = dataListCategories.find((elm) => {
      return elm._id === table.dataColumns[0].dataMasterKey.categoryId;
    });
    const currentListTable = currentCategoryListTable?.groupTables.find(
      (item) => item._id === table.dataColumns[0].dataMasterKey.tableId
    );
    setDataGlobalListTables(currentCategoryListTable?.groupTables);
    setCurrentListCategory(currentCategoryListTable._id);
    setCurrentGlobalListTables(currentListTable._id);
    setSelectedGlobalListTable(currentListTable);
    setGlobalValues({
      columnSelected: table.dataColumns[0].dataMasterKey.column.name,
      columnSelectedId: table.dataColumns[0].dataMasterKey.column.id,
    });
    setColumnsGlobalTable(
      table.dataColumns.map((elm) => {
        return {
          Columns: elm.name,
          DisplayName: elm.alias,
          Type: elm.dataType,
          Require: elm.require.toString(),
          Min: elm.min,
          Max: elm.max,
          Step: elm.step,
          Tools: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CustomButton
                type="onClick"
                text={<DeleteIcon />}
                onClick={() => {
                  setColumnsGlobalTable((current) => {
                    return current.filter((item) => item.Columns !== elm.name);
                  });
                }}
                margin={5}
                disabled={
                  elm.dataType === "select" ||
                  elm.name === "MeasurementDate" ||
                  ((elm.name === "Association" ||
                    elm.name === "Synchronized") &&
                    elm.dataType === "boolean")
                    ? true
                    : false
                }
                primaryColor={
                  elm.dataType === "select" ||
                  elm.name === "MeasurementDate" ||
                  ((elm.name === "Association" ||
                    elm.name === "Synchronized") &&
                    elm.dataType === "boolean")
                    ? VISIBILITY_OFF_ICON
                    : DELETE_COLOR
                }
                secondaryColor={
                  elm.dataType === "select" ||
                  elm.name === "MeasurementDate" ||
                  ((elm.name === "Association" ||
                    elm.name === "Synchronized") &&
                    elm.dataType === "boolean")
                    ? VISIBILITY_OFF_ICON
                    : HOVER_DELETE_COLOR
                }
              />
            </div>
          ),
        };
      })
    );
    setOpenDialogCreateTable(true);
  };

  const handleInputChange = (newValue) => {
    if (getValuesGlobalTable("selectCategory") === null) {
      setValueGlobalTable("selectCategory", newValue);
    }
  };

  const handleSelectCategory = (e) => {
    const categorySelected = dataCategories.find((elm) => elm._id === e.value);
    setCurrentCategory(e.value);
    setDataGlobalTables(categorySelected?.groupTables);
    setCurrentGlobalTable(null);
  };

  const handleCreateNewTable = () => {
    const category = dataCategories?.find(
      (elm) => elm._id === currentCategory
    )?.name;
    setValueGlobalTable("selectCategory", category);
    setOpenDialogCreateTable(true);
    if (
      globalListTables &&
      !errorGlobalListTables &&
      globalListTables.length > 0
    ) {
      setDataListCategories(globalListTables[0]?.tableCategories);
      if (globalListTables[0]?.tableCategories.length > 0) {
        const existingTable = globalListTables[0]?.tableCategories.filter(
          (elm) => elm.groupTables.length > 0
        );
        setDataGlobalListTables(existingTable[0]?.groupTables);
        setCurrentListCategory(existingTable[0]?._id);
        setSelectedGlobalListTable(existingTable[0]?.groupTables[0]);
        setCurrentGlobalListTables(existingTable[0]?.groupTables[0]._id);
        setGlobalValues((current) => ({
          ...current,
          columnSelected:
            existingTable[0]?.groupTables[0]?.dataColumns[1]?.name,
          columnSelectedId:
            existingTable[0]?.groupTables[0]?.dataColumns[1]?._id,
        }));
        setColumnsGlobalTable([
          {
            Columns: existingTable[0]?.groupTables[0]?.dataColumns[1]?.name,
            DisplayName: existingTable[0]?.groupTables[0]?.dataColumns[1]?.name,
            Type: "select",
            Require: "true",
            Tools: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  disabled={true}
                  margin={5}
                  primaryColor={VISIBILITY_OFF_ICON}
                  secondaryColor={VISIBILITY_OFF_ICON}
                />
              </div>
            ),
          },
          {
            Columns: "Association",
            DisplayName: "Association",
            Type: "boolean",
            Require: "false",
            Tools: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  disabled={true}
                  margin={5}
                  primaryColor={VISIBILITY_OFF_ICON}
                  secondaryColor={VISIBILITY_OFF_ICON}
                />
              </div>
            ),
          },
          {
            Columns: "Synchronized",
            DisplayName: "Synchronized",
            Type: "boolean",
            Require: "false",
            Tools: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  disabled={true}
                  margin={5}
                  primaryColor={VISIBILITY_OFF_ICON}
                  secondaryColor={VISIBILITY_OFF_ICON}
                />
              </div>
            ),
          },
          {
            Columns: "MeasurementDate",
            DisplayName: "Measurement Date",
            Type: "date",
            Require: "true",
            Tools: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  disabled={true}
                  margin={5}
                  primaryColor={VISIBILITY_OFF_ICON}
                  secondaryColor={VISIBILITY_OFF_ICON}
                />
              </div>
            ),
          },
        ]);
      }
    }
  };
  const handleDownloadCSVTemplate = ({ e, table }) => {
    e.preventDefault();
    let csvHeaders = [];
    let selectOptionsRows = [];

    if (table?.dataColumns.length > 0) {
      const infoAux = {};
      let maxOptionsLength = 0;
      for (let column of table.dataColumns) {
        if (
          column.name !== "Id" &&
          column.name !== "Association" &&
          column.name !== "Synchronized"
        ) {
          let dataTypeInfo = column.require ? "required" : "not required";
          dataTypeInfo += `/${column.dataType}`;

          // Agregar formato para tipo "date"
          if (column.dataType === "date") {
            dataTypeInfo += " (format: DD-MM-YYYY)";
          }

          // Agregar valores min y max para tipo "range"
          if (column.dataType === "range") {
            dataTypeInfo += ` (min: ${column.min}, max: ${column.max})`;
          }

          infoAux[column.name] = dataTypeInfo;

          if (column.dataType === "select") {
            let options = [];

            table.dataColumns?.forEach((elm) => {
              if (elm._id === column._id) {
                const dataCategory = dataListCategories.find((category) => {
                  return category._id === elm.dataMasterKey.categoryId;
                });

                const selectTable = dataCategory.groupTables.find((table) => {
                  return table._id === elm.dataMasterKey.tableId;
                });

                selectTable.rows.forEach((item) => {
                  options.push(item[elm.name]);
                });
              }
            });

            options.forEach((option, index) => {
              if (!selectOptionsRows[index]) {
                selectOptionsRows[index] = {};
              }
              selectOptionsRows[index][column.name] = option;
            });

            maxOptionsLength = Math.max(maxOptionsLength, options.length);
          }
        }
      }
      csvHeaders.push({ ...infoAux });

      for (let i = 0; i < maxOptionsLength; i++) {
        const row = {};
        for (let column of table.dataColumns) {
          if (column.name !== "Association" || column.name !== "Synchronized") {
            if (column.dataType === "select") {
              row[column.name] = selectOptionsRows[i]
                ? selectOptionsRows[i][column.name] || ""
                : "";
            } else {
              row[column.name] = "";
            }
          }
        }

        csvHeaders.push(row);
      }
    }
    saveCSVTemplateFile(csvHeaders, "csv");
  };

  const handleUploadCSV = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    //const tableId = globalListTables[0]?._id;

    if (file) {
      // Leer y parsear el archivo CSV
      Papa.parse(file, {
        header: true, // This will automatically use the first row as headers
        complete: async (result) => {
          const csvHeaders = result.meta.fields;
          const tableId = globalMeasurementTables[0]?._id;
          const tableCategoriesId = currentCategory;
          const groupId = currentGlobalTable?._id;
          const upload = false;
          checkForHeaders(csvHeaders); // Validate if there are duplicates
          try {
            const { data, error } = await uploadCSV(
              tableId,
              tableCategoriesId,
              groupId,
              file,
              instanceId,
              upload
            );

            if (error) {
              setErrorMessage(`Error from server: ${error.message}`);
              return;
            }
            setFileData({
              validRows: data?.validRows,
              invalidRows: data?.invalidRows,
              errorMessagesCsv: data?.invalidRows.map(
                (error) => error.errorMessagesCsv
              ),
            });
            setFileName(file);
            setShowConfirm(true);
          } catch (error) {
            setErrorMessage(`Error from server: ${error.message}`);
          }
        },

        error: (error) => {
          setErrorMessage(`Error reading file: ${error.message}`);
          setErrorMessageFile(true);
        },
      });
    } else {
      setErrorMessage("Please select a file to upload");
      setErrorMessageFile(true);
    }
  };

  const checkForHeaders = (csvHeaders) => {
    //Base de datos dataTable
    const dataTable = dataGlobalTables?.find(
      (datas) => datas?._id === currentGlobalTable?._id
    );

    //extreaer los headers de la base de datos sin contar el id
    const dataBaseHeaders = dataTable?.headers.filter(
      (header) => header !== "Association" && header !== "Synchronized"
    );

    const headerMatch =
      csvHeaders.every((header) => dataBaseHeaders.includes(header)) &&
      dataBaseHeaders.every((header) => csvHeaders.includes(header));

    if (!headerMatch) {
      setErrorHeader(
        "The headers of the CSV do not match the headers of the database"
      );
      setErrorMatchHeader(true);
    } else {
      setErrorMatchHeader(false);
      setErrorHeader("");
    }
  };

  const confirmUpload = async () => {
    const tableId = globalMeasurementTables[0]?._id;
    const tableCategoriesId = currentCategory;
    const groupId = currentGlobalTable?._id;

    if (fileName && fileData?.validRows?.length > 0 && !errorMatchHeader) {
      setUploading(true);
      setProgress(0);
      //Agregar barra de progreso
      for (let i = 0; i <= fileData?.validRows?.length; i++) {
        await new Promise((resolve) =>
          setTimeout(() => {
            setProgress((i / fileData?.validRows?.length) * 100);
            resolve();
          }, 100)
        );
      }
      const upload = true;
      await uploadCSVGlobalMeasurementTable(
        fileName,
        tableId,
        tableCategoriesId,
        groupId,
        setErrorMessage,
        setErrorMessageFile,
        setCurrentGlobalTable,
        setinvalidRowsBackend,
        instanceId,
        upload
      );

      setShowConfirm(false);
      setUploading(false);
      setProgress(0);
      mutate(url);
      // Show the modal with the summary of uploaded data
      setShowSummary(true);
    } else {
      setErrorMessage("The CSV file is empty or contains invalid rows.");
      setErrorMessageFile(true);
      setShowConfirm(false);
      mutate(url);
    }
  };

  const handleButtonClick = () => {
    inputFileRef.current.value = "";
    inputFileRef.current.click();
  };

  const navigation = (path) => {
    navigate(path);
  };

  const prepareTableDataBackend = (invalidRows) => {
    if (!invalidRows || invalidRows.length === 0) {
      return { headers: [], rows: [] };
    }

    // Obtener headers dinámicos excluyendo 'Id' y agregar el campo "error"
    const headers = [
      ...Object.keys(invalidRows[0].row).filter((header) => header !== "Id"),
      "error",
    ];

    // Mapear filas para excluir 'Id' y agregar el mensaje de error
    const rows = invalidRows.map(({ row, errors }) => {
      const { Id, ...filteredRow } = row; // Excluir 'Id' de cada fila
      const newRow = { ...filteredRow, error: errors.join(", ") }; // Agregar mensaje de error
      return newRow;
    });
    return { headers, rows };
  };

  return (
    <Layout>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={
          deleteTable
            ? handelDeleteTables
            : deleteGlobalValue
            ? handelDeleteGlobalValues
            : deleteCategory
            ? handelDeleteCategory
            : ""
        }
        content={contentDialog}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        openDialog={openDialogEditCategory}
        setOpenDialog={setOpenDialogEditCategory}
        toExecuteOnClose={() => {}}
        execute={handelUpdateCategory}
        content={{
          title: "Edit Name Category",
          disagree: "Cancel",
          agree: "Update",
          description: (
            <ContainerInput>
              <label>Name Category</label>
              <input
                type="text"
                value={categoryToEdit.name}
                onChange={(e) =>
                  setCategoryToEdit((current) => ({
                    ...current,
                    name: e.target.value,
                  }))
                }
              />
            </ContainerInput>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        openDialog={openDialogCreateTable}
        setOpenDialog={setOpenDialogCreateTable}
        toExecuteOnClose={() => {
          setSelectTableId(null);
          resetInputsGlobalTable();
          setOpenDialogCreateTable(false);
        }}
        content={{
          title: "Create Global Measurement Table",
          description: (
            <div
              style={{
                width: "100%",
              }}
            >
              <Form
                style={{ background: StyleColor3(theme) }}
                onSubmit={handleSubmitGlobalTable(handleAddNewColumn)}
                onChange={() =>
                  SetCurrentDataTypeSelected(getValuesGlobalTable("Data Type"))
                }
              >
                <Divider>
                  <span className="divider-title">Table Information</span>
                </Divider>
                <label
                  style={{
                    marginBottom: "5px",
                    fontSize: "1.6rem",
                  }}
                >
                  Choose or create a category
                </label>
                <Select
                  {...registerGlobalTable("selectCategory")}
                  options={options}
                  isClearable
                  isSearchable
                  onChange={(selectedOption) => {
                    setValueGlobalTable(
                      "selectCategory",
                      selectedOption?.value
                    );
                  }}
                  onInputChange={handleInputChange}
                  placeholder="Type to add or select from list"
                  styles={SelectCategoriesStyles}
                  defaultInputValue={
                    dataCategories.find((elm) => elm._id === currentCategory)
                      ?.name || null
                  }
                />
                <TextInput
                  field={{
                    name: "Table Name",
                    type: "string",
                    required: true,
                  }}
                  register={registerGlobalTable}
                  errors={errorsGlobalTable}
                />

                <br />
                <Divider>
                  <span className="divider-title">Master Key</span>
                </Divider>
                <br />

                <GlobalFieldValue>
                  <ContainerConfigGlobalValue>
                    <ContainerSelectGlobalList>
                      <h2>Oficial Global List Table Config</h2>
                      {dataListCategories && dataListCategories.length > 0 ? (
                        <>
                          <label className="label">Select Category</label>
                          <select
                            className="select"
                            value={currentListCategory}
                            onChange={(e) => handleSelectListCategory(e)}
                          >
                            {dataListCategories?.map((option, index) => {
                              return (
                                <option key={index} value={option._id}>
                                  {option.name}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      ) : (
                        <ContainerValue>
                          <label className="label">
                            First Create Category In Module
                            <Link
                              to="/config-global-list-table"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                marginLeft: "5px",
                              }}
                            >
                              Global List Table
                            </Link>
                          </label>
                        </ContainerValue>
                      )}
                      {dataGlobalListTables &&
                      dataGlobalListTables.length > 0 ? (
                        <>
                          <label className="label">Global List Table</label>
                          <select
                            id={currentGlobalListTables?._id}
                            className="select"
                            value={currentGlobalListTables || ""}
                            onChange={handelSelectGlobalTable}
                          >
                            {dataGlobalListTables.map((option, index) => {
                              return (
                                <option key={index} value={option._id}>
                                  {option.name}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      ) : (
                        <ContainerValue>
                          <label className="label">
                            First Create Table In Module
                            <Link
                              to="/config-global-list-table"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                marginLeft: "5px",
                              }}
                            >
                              Global List Table
                            </Link>
                          </label>
                        </ContainerValue>
                      )}
                      {selectedGlobalListTable &&
                      selectedGlobalListTable?.dataColumns?.length >= 2 &&
                      selectedGlobalListTable?.dataColumns.find(
                        (elm) => elm.uniqueValues
                      ) ? (
                        <>
                          <label className="label">Select Master Key</label>
                          <select
                            id={globalValues.columnSelectedId}
                            className="select"
                            value={globalValues.columnSelected}
                            onChange={(e) =>
                              handelSelectGlobalValues({
                                event: e,
                                columnSelected: e.target.value,
                              })
                            }
                          >
                            {selectedGlobalListTable?.dataColumns?.map(
                              (elm, index) => {
                                if (index !== 0 && elm.uniqueValues) {
                                  return (
                                    <option key={elm._id} value={elm.name}>
                                      {elm.name}
                                    </option>
                                  );
                                }
                                return null;
                              }
                            )}
                          </select>
                        </>
                      ) : (
                        <ContainerValue>
                          <label className="label">
                            First Create Columns With Unique Values In Module
                            <Link
                              to="/config-global-list-table"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                marginLeft: "5px",
                              }}
                            >
                              Global List Table
                            </Link>
                          </label>
                        </ContainerValue>
                      )}
                    </ContainerSelectGlobalList>
                  </ContainerConfigGlobalValue>
                </GlobalFieldValue>
                <br />
                {columnsGlobalTable.length > 0 && (
                  <>
                    <Divider>
                      <span className="divider-title">Columns</span>
                    </Divider>
                    <TextInput
                      field={{
                        name: "Column Name",
                        type: "string",
                        regularExpression: /^[a-zA-Z0-9]+$/,
                        required: false,
                      }}
                      register={registerGlobalTable}
                      errors={errorsGlobalTable}
                    />
                    <TextInput
                      field={{
                        name: "Display Name",
                        type: "string",
                        required: false,
                      }}
                      register={registerGlobalTable}
                      errors={errorsGlobalTable}
                    />
                    <TextInput
                      field={{
                        name: "Require",
                        type: "check",
                        required: false,
                      }}
                      register={registerGlobalTable}
                      errors={errorsGlobalTable}
                    />
                    <SelectInput
                      field={{
                        name: "Data Type",
                        options: [
                          "string",
                          "number",
                          "date",
                          "boolean",
                          "range",
                          "file",
                        ],
                        required: false,
                      }}
                      register={registerGlobalTable}
                    />
                    {currentDataTypeSelected === "range" && (
                      <>
                        <TextInput
                          field={{
                            name: "Min",
                            type: "number",
                            required: true,
                          }}
                          register={registerGlobalTable}
                          errors={errorsGlobalTable}
                        />
                        <TextInput
                          field={{
                            name: "Max",
                            type: "number",
                            required: true,
                          }}
                          register={registerGlobalTable}
                          errors={errorsGlobalTable}
                        />
                        <TextInput
                          field={{
                            name: "Step",
                            type: "number",
                            required: true,
                          }}
                          register={registerGlobalTable}
                          errors={errorsGlobalTable}
                        />
                      </>
                    )}
                  </>
                )}
                <br />
                <CustomButton
                  type={"submit"}
                  text={"Add Column"}
                  margin={0}
                  primaryColor={
                    columnsGlobalTable.length > 0
                      ? PRIMARY_COLOR
                      : VISIBILITY_OFF_ICON
                  }
                  secondaryColor={
                    columnsGlobalTable.length > 0
                      ? SECOND_COLOR
                      : VISIBILITY_OFF_ICON
                  }
                  disabled={columnsGlobalTable.length > 0 ? false : true}
                />
              </Form>
              <br />
              {columnsGlobalTable.length > 0 && (
                <>
                  <Divider>
                    <span className="divider-title">Table Preview</span>
                  </Divider>
                  <GenericTable
                    headers={Object.keys(columnsGlobalTable[0])}
                    rows={columnsGlobalTable}
                    keyValues={Object.keys(columnsGlobalTable[0])}
                    handlerClick={() => {}}
                    state={true}
                    configBar={true}
                    customTableWidth={"100%"}
                    customHeaderWidth={"5%"}
                    from={FROM_TABLE.details}
                  />
                </>
              )}
              <br />
              <CustomButton
                type={"button"}
                text={"Save"}
                margin={10}
                onClick={handelSaveTable}
                primaryColor={
                  columnsGlobalTable.length > 4
                    ? PRIMARY_COLOR
                    : VISIBILITY_OFF_ICON
                }
                secondaryColor={
                  columnsGlobalTable.length > 4
                    ? SECOND_COLOR
                    : VISIBILITY_OFF_ICON
                }
                disabled={columnsGlobalTable.length > 4 ? false : true}
              />
            </div>
          ),
        }}
        maxWidth={"800px"}
        minWidth={"600px"}
      />
      <OpenDialog
        openDialog={openDialogCreateGlobalValue}
        setOpenDialog={setOpenDialogCreateGlobalValue}
        toExecuteOnClose={() => {}}
        content={{
          title: "Create Global Value",
          description: (
            <Form onSubmit={handleSubmitGlobalValue(handelCreateGlobalValue)}>
              <Title
                style={{
                  color: StyleColor2(theme),
                }}
              >
                Create Global Value
              </Title>
              {currentGlobalTable?.dataColumns?.map((elm) => {
                if (elm.dataType === "select") {
                  const dataCategory = dataListCategories.find((category) => {
                    return category._id === elm.dataMasterKey.categoryId;
                  });
                  const selectTable = dataCategory.groupTables.find((table) => {
                    return table._id === elm.dataMasterKey.tableId;
                  });
                  return (
                    <SelectInput
                      field={{
                        name: elm.name,
                        alias: elm.alias,
                        options: selectTable.rows.map((item) => item[elm.name]),
                        required: elm.required,
                        type: elm.type,
                        listTableRowId: selectTable.rows.map(
                          (item) => item["Id"]
                        ),
                        setListTableRowId: setValueGlobalValue,
                      }}
                      register={registerGlobalValue}
                      errors={errorsGlobalValue}
                    />
                  );
                }
                return (
                  <TextInput
                    field={{
                      name: elm.name,
                      alias: elm.alias,
                      type: elm.dataType,
                      min: elm.min,
                      max: elm.max,
                      step: elm.step,
                      required: elm.require,
                      noTouch:
                        elm.name === "Association" ||
                        elm.name === "Synchronized"
                          ? true
                          : false,
                    }}
                    register={registerGlobalValue}
                    value={watchGlobalValue(elm.name)}
                    from={"globalMeasurementTable"}
                    errors={errorsGlobalValue}
                  />
                );
              })}
              <CustomButton type={"submit"} text={"Create"} margin={0} />
            </Form>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        key={currentGlobalTable?._id}
        openDialog={openDialogUpdateGlobalValue}
        setOpenDialog={setOpenDialogUpdateGlobalValue}
        toExecuteOnClose={() => {}}
        content={{
          title: "Update Global Value",
          description: (
            <Form
              key={currentGlobalTable?._id}
              onSubmit={handleSubmitUpdateGlobalValue(handelUpdateGlobalValue)}
            >
              <Title
                style={{
                  color: StyleColor2(theme),
                }}
              >
                Update Global Value
              </Title>
              {currentGlobalTable?.dataColumns?.map((elm) => {
                if (elm.dataType === "select") {
                  const dataCategory = dataListCategories.find((category) => {
                    return category._id === elm.dataMasterKey.categoryId;
                  });
                  const selectTable = dataCategory.groupTables.find((table) => {
                    return table._id === elm.dataMasterKey.tableId;
                  });
                  return (
                    <SelectInput
                      field={{
                        name: elm.name,
                        alias: elm.alias,
                        options: selectTable.rows.map((item) => item[elm.name]),
                        required: elm.required,
                        type: elm.type,
                      }}
                      register={registerUpdateGlobalValue}
                      errors={errorsUpdateGlobalValue}
                    />
                  );
                }
                if (
                  elm.name !== "Association" &&
                  elm.name !== "Synchronized" &&
                  elm.name !== "MeasurementDate"
                ) {
                  return (
                    <TextInput
                      field={{
                        name: elm.name,
                        alias: elm.alias,
                        type: elm.dataType,
                        required: elm.require,
                        min: elm.min,
                        max: elm.max,
                        step: elm.step,
                      }}
                      register={registerUpdateGlobalValue}
                      value={watchUpdateGlobalValue(elm.name)}
                      from={"globalMeasurementTable"}
                      errors={errorsUpdateGlobalValue}
                    />
                  );
                }
              })}
              <CustomButton type={"submit"} text={"Update"} margin={0} />
            </Form>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <Content>
        <InformationSection className="background-1" style={{ border: "none" }}>
          <div
            style={{
              width: "25%",
            }}
          >
            <h1 className="title-description">
              Measurement <br /> Data Integration <br /> Configuration
            </h1>
          </div>
          <div
            style={{
              width: "75%",
            }}
          >
            {" "}
            <p className="description">
              This module integrates dynamic measurements from external sources
              with assets or devices. First, establish an association between
              the asset or device in the Global List Tables Module and the
              Digital Twin, synchronizing values and creating a measurement
              event.
              <br />
              <br />
              For example, daily production data for oil, gas, and water can be
              assigned to each well, viewed in the digital twin module, and
              visualized as KPIs or graphs in the Dashboards module. This
              unified data source enhances decision-making, safety,
              profitability, and operational excellence.
            </p>
          </div>
        </InformationSection>
        <ShowAllButton2
          style={{ marginTop: "10px" }}
          onClick={() => navigation("/setUp-admin")}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: "2rem" }} />
        </ShowAllButton2>
        <GlobalContent className="background-2 border-top-1">
          <ConfigurationSection>
            <ConfigLibrary className="background-2">
              <ContentButton>
                <div className="select-categories">
                  <CustomButton
                    text={"+ Create New Global Measurement Table"}
                    margin={0}
                    type={"onClick"}
                    onClick={() => handleCreateNewTable()}
                    primaryColor={PRIMARY_COLOR}
                    secondaryColor={SECOND_COLOR}
                  />
                </div>
                <div className="select-categories">
                  <label>Choose group category</label>
                  <div
                    style={{
                      width: "60%",
                    }}
                  >
                    <Select
                      onChange={handleSelectCategory}
                      isDisabled={
                        dataCategories && dataCategories.length > 0
                          ? false
                          : true
                      }
                      options={
                        dataCategories &&
                        dataCategories.length > 0 &&
                        dataCategories?.map((option) => {
                          return {
                            value: option._id,
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    width: "100%",
                                    marginLeft: "-1px",
                                  }}
                                >
                                  {option.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    width: "80px",
                                  }}
                                >
                                  <CancelIcon
                                    sx={{
                                      color: "red",
                                      width: "15px",
                                      height: "auto",
                                      cursor: "pointer",
                                      transition: "color 0.3s",
                                      "&:hover": {
                                        color: "darkred",
                                      },
                                    }}
                                    onClick={() =>
                                      handleOpenDialogDeleteCategory({
                                        categoryId: option._id,
                                        name: option.name,
                                      })
                                    }
                                  />
                                  <EditIcon
                                    sx={{
                                      color: PRIMARY_COLOR,
                                      width: "15px",
                                      height: "auto",
                                      cursor: "pointer",
                                      transition: "color 0.3s",
                                      "&:hover": {
                                        color: SECOND_COLOR,
                                      },
                                    }}
                                    onClick={() => {
                                      setCategoryToEdit({
                                        id: option._id,
                                        name: option.name,
                                      });
                                      setOpenDialogEditCategory(true);
                                    }}
                                  />
                                </div>
                              </div>
                            ),
                          };
                        })
                      }
                      value={currentCategory}
                      placeholder={
                        dataCategories && dataCategories.length > 0
                          ? dataCategories?.find(
                              (elm) => elm._id === currentCategory
                            )?.name
                          : "There are no categories"
                      }
                      styles={customSelectStyle}
                      menuPlacement="auto"
                    />
                  </div>
                </div>
              </ContentButton>
              <ContentData>
                {dataGlobalTables?.length !== 0 ? (
                  <Columns paddingLeft={30}>
                    <Column width={10} textAling={"start"}>
                      Id
                    </Column>
                    <Column width={90} textAling={"start"}>
                      Table Name
                    </Column>
                  </Columns>
                ) : (
                  <Space />
                )}
                {dataGlobalTables?.length === 0 ? (
                  <InformationShare>
                    <div className="head">Edit Existing List Table</div>
                    <div className="body">
                      To Edit Click on + New Global Measurement Table
                    </div>
                  </InformationShare>
                ) : (
                  <ContentTablesTags>
                    {dataGlobalTables?.map((item, index) => (
                      <TableTag
                        className="background-row-1 border-color-1"
                        currentGlobalTableId={currentGlobalTable?._id}
                        item={item}
                        textAling={"start"}
                      >
                        <div>
                          <CustomButton
                            type="onClick"
                            text={index + 1}
                            onClick={() => {
                              setCurrentGlobalTable(item);
                              resetInputsGlobalValue();
                            }}
                            margin={0}
                            primaryColor={
                              currentGlobalTable?._id === item._id
                                ? SECOND_COLOR
                                : PRIMARY_COLOR
                            }
                          />
                        </div>
                        <ContentDataTag className="background-2" width={90}>
                          {item.name}
                        </ContentDataTag>
                        <div>
                          <CustomButton
                            type="onClick"
                            text={<DeleteIcon />}
                            onClick={(e) =>
                              handleOpenDialogDeleteTable({
                                e: e,
                                table: item,
                              })
                            }
                            margin={0}
                            primaryColor={DELETE_COLOR}
                            secondaryColor={HOVER_DELETE_COLOR}
                          />
                        </div>
                        <div>
                          <CustomButton
                            type="onClick"
                            text={
                              <Tooltip title="CSV TEMPLATE">
                                <FileDownloadIcon />
                              </Tooltip>
                            }
                            onClick={(e) =>
                              handleDownloadCSVTemplate({
                                e: e,
                                table: item,
                              })
                            }
                            margin={0}
                          />
                        </div>
                        <div>
                          <CustomButton
                            type="onClick"
                            text={<EditIcon />}
                            onClick={(e) =>
                              handleOpenDialogUpdate({
                                e: e,
                                table: item,
                              })
                            }
                            margin={0}
                          />
                        </div>
                      </TableTag>
                    ))}
                  </ContentTablesTags>
                )}
              </ContentData>
            </ConfigLibrary>
            <ConfigConstant className="background-2">
              <ContentButton className="background-row-2 border-color-1">
                <h1> {`Create new values for the table: `}</h1>{" "}
                <h1
                  style={{
                    color: PRIMARY_COLOR,
                    marginLeft: "5px",
                  }}
                >
                  {currentGlobalTable?.name || ""}
                </h1>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      width: "49%",
                    }}
                  >
                    <input
                      type="file"
                      accept=".csv"
                      ref={inputFileRef}
                      style={{ display: "none" }} // hidde input
                      onChange={handleUploadCSV}
                    />
                    <CustomButton
                      text={"+ New Global Value"}
                      margin={0}
                      type={"onClick"}
                      onClick={handleOpenDialogCreateGlobalValue}
                      disabled={currentGlobalTable ? false : true}
                      primaryColor={
                        currentGlobalTable ? PRIMARY_COLOR : VISIBILITY_OFF_ICON
                      }
                      secondaryColor={
                        currentGlobalTable ? SECOND_COLOR : VISIBILITY_OFF_ICON
                      }
                    />
                  </div>

                  <div
                    style={{
                      width: "49%",
                    }}
                  >
                    <CustomButton
                      text={"+ Upload CSV"}
                      margin={0}
                      type={"onClick"}
                      onClick={handleButtonClick}
                      disabled={currentGlobalTable ? false : true}
                      primaryColor={
                        currentGlobalTable ? PRIMARY_COLOR : VISIBILITY_OFF_ICON
                      }
                      secondaryColor={
                        currentGlobalTable ? SECOND_COLOR : VISIBILITY_OFF_ICON
                      }
                    />
                  </div>
                </div>
              </ContentButton>

              {/* Modal de confirmación */}
              <OpenDialog
                openDialog={showConfirm}
                setOpenDialog={setShowConfirm}
                execute={() => confirmUpload()}
                content={{
                  title: "Confirm upload",
                  description: (
                    <>
                      <ModalContainer>
                        <ModalContent>
                          <ModalHeader>
                            Are you sure you want to upload this file?
                          </ModalHeader>

                          {errorHeader ? (
                            <ModalErrorList>{errorHeader}</ModalErrorList>
                          ) : (
                            <>
                              <p>
                                <strong>Filename:</strong> {fileName?.name}
                              </p>
                              <br />
                              <p>
                                <strong>Total Valid Rows:</strong>{" "}
                                {fileData?.validRows?.length}
                              </p>
                              <br />
                              <p>
                                <strong>Total Invalid Rows:</strong>{" "}
                                {fileData?.invalidRows?.length}
                              </p>
                              <ul
                                style={{ listStyleType: "none", padding: 0 }}
                              ></ul>

                              {
                                <div>
                                  {fileData?.errorMessagesCsv?.length > 0 ? (
                                    <>
                                      <ModalHeadeError>
                                        Some errors have been found with this
                                        file
                                      </ModalHeadeError>
                                      <ModalErrorList>
                                        <ul>
                                          {fileData?.errorMessagesCsv?.[0]?.map(
                                            (error, index) => (
                                              <li
                                                style={{ fontSize: "1.4rem" }}
                                                key={index}
                                              >
                                                {error}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </ModalErrorList>
                                    </>
                                  ) : (
                                    <SuccessMessage>
                                      <strong style={{ fontSize: "1.8rem" }}>
                                        The file is ready to be uploaded!
                                      </strong>
                                    </SuccessMessage>
                                  )}
                                  <div style={{ marginTop: "10px" }}>
                                    {uploading && (
                                      <ProgressBar progress={progress} />
                                    )}
                                  </div>
                                </div>
                              }
                            </>
                          )}
                        </ModalContent>
                      </ModalContainer>
                    </>
                  ),
                  agree: "Confirm Upload",
                  disagree: "Cancel",
                }}
              />

              {errorMessage ? (
                <OpenDialog
                  openDialog={errorMessageFile}
                  setOpenDialog={setErrorMessageFile}
                  content={{
                    title: "Error",
                    description: (
                      <>
                        <ModalContainer>
                          <ModalContent>
                            <ModalHeader>Error</ModalHeader>
                            <p>{errorMessage}</p>
                          </ModalContent>
                        </ModalContainer>
                      </>
                    ),
                    disagree: "Close",
                  }}
                />
              ) : (
                <>
                  <OpenDialog
                    openDialog={showSummary}
                    setOpenDialog={setShowSummary}
                    content={{
                      title: "Upload Summary",
                      description: (
                        <>
                          <ModalContainer>
                            <ModalContent>
                              <ModalHeader>
                                Summary of Uploaded Data
                              </ModalHeader>
                              <p
                                style={{ fontSize: "1.6rem", color: "#0e4d45" }}
                              >
                                <strong>Total Rows Uploaded:</strong>{" "}
                                {fileData?.validRows?.length}
                              </p>

                              {prepareTableDataBackend(invalidRowsBackend).rows
                                ?.length > 0 ? (
                                <>
                                  <ModalHeadeError>
                                    Rows Not Uploaded (Duplicates, Incorrect
                                    Data Type or Empty):
                                  </ModalHeadeError>

                                  <GenericTable
                                    headers={
                                      prepareTableDataBackend(
                                        invalidRowsBackend
                                      ).headers
                                    }
                                    rows={
                                      prepareTableDataBackend(
                                        invalidRowsBackend
                                      ).rows
                                    }
                                    keyValues={
                                      prepareTableDataBackend(
                                        invalidRowsBackend
                                      ).headers
                                    }
                                    handlerClick={() => {}}
                                    state={true}
                                    customTableWidth={"100%"}
                                    customHeaderWidth={"5%"}
                                    from={FROM_TABLE.details}
                                  />
                                </>
                              ) : (
                                <SuccessMessage>
                                  <strong style={{ fontSize: "1.8rem" }}>
                                    All rows were uploaded successfully!
                                  </strong>
                                </SuccessMessage>
                              )}
                            </ModalContent>
                          </ModalContainer>
                        </>
                      ),
                      disagree: "Close",
                    }}
                  />
                </>
              )}

              <ContentData
                className="background-row-2 border-color-1"
                style={{ borderRadius: "5px", padding: "10px" }}
              >
                {currentGlobalTable ? (
                  <GenericTable
                    headers={currentGlobalTable.dataColumns
                      .map((elm) => elm.alias)
                      .concat(["Tools"])}
                    rows={currentGlobalTable.rows.map((elm, index) => {
                      return {
                        ...elm,
                        Tools: (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                width: "20px",
                              }}
                            >
                              <CustomButton
                                type="onClick"
                                text={<EditIcon />}
                                onClick={(e) =>
                                  handleOpenDialogEditGlobalValue({
                                    globalValue: elm,
                                    index,
                                  })
                                }
                                margin={0}
                              />
                            </div>
                            <div
                              style={{
                                width: "20px",
                              }}
                            >
                              <CustomButton
                                type="onClick"
                                text={<DeleteIcon />}
                                onClick={(e) =>
                                  handleOpenDialogDeleteValue({
                                    e,
                                    index: index,
                                  })
                                }
                                margin={0}
                                primaryColor={DELETE_COLOR}
                                secondaryColor={HOVER_DELETE_COLOR}
                              />
                            </div>
                          </div>
                        ),
                      };
                    })}
                    keyValues={currentGlobalTable.headers.concat(["Tools"])}
                    handlerClick={() => {}}
                    state={true}
                    customTableWidth={"100%"}
                    customHeaderWidth={"5%"}
                    from={FROM_TABLE.details}
                  />
                ) : (
                  <InformationShare>
                    <div className="head color1">
                      Edit Existing values for the table
                    </div>
                    <div className="body color1">
                      To Edit Click on + New Global Value
                    </div>
                  </InformationShare>
                )}
              </ContentData>
            </ConfigConstant>
          </ConfigurationSection>
        </GlobalContent>
      </Content>
    </Layout>
  );
};

export default GlobalMeasurementTable;
