import styled, { keyframes } from "styled-components";
import {
  WHITE_BACKGROUND,
  BLACK_COLOR,
  BORDER_GREY,
  PRIMARY_COLOR_TRANS,
  WHITE_COLOR,
  BACKGROUND_SECOND_GREY,
} from "utils/const";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";

export const ContainerContentTable = styled.div`
  border: 1px solid ${BORDER_GREY};
  border-radius: 5px;
  margin-top: 1rem;
  background-color: ${WHITE_BACKGROUND};
  padding: 2rem;
  width: 100%;
  height: 80%;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    margin: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #c2c9ca;
  border-radius: 5px;
  position: relative;
  padding: 0 0.5rem;
  background: ${WHITE_COLOR};
  height: 38px;
  padding-top: 2px;
`;

export const NavigateContainer = styled.div`
  .arrowPage {
    margin: 1px;
    width: 15px;
    height: auto;
  }

  .changeElement {
    margin-bottom: auto;
    display: flex;
    margin: 1px;
  }

  .changeElement.disabled {
    color: #9d8f8f;
    background-color: #f0f0f0;
    border: solid 1px #9d8f8f;
    border-radius: 100%;
    cursor: auto;
  }

  .changeElement.activate {
    color: ${BLACK_COLOR};
    background-color: #d1d1d1;
    border: solid 1px #9d8f8f;
    border-radius: 100%;
    :hover {
      background-color: var(--background-secondary_color);
      color: ${WHITE_COLOR};
      border: solid 1px var(--background-secondary_color);
    }
  }
`;
export const FooterConteiner = styled.div`
  position: static;
  bottom: 0;
  display: flex;
  background: #f9f9f9;

  margin-top: auto;
  border-radius: 0 0 5px 5px;
`;

export const ContentTable = styled.div`
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 14px;
  max-height: ${(props) => props.maxHeight}vh;
  overflow-x: auto;
  background: ${WHITE_COLOR};
  /* color: ${BLACK_COLOR}; */

  .tableCell {
    min-width: 50px;
    border: solid #ffffff 1px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    outline: none;
    background-color: ${WHITE_COLOR};
    height: 37px;
    &::placeholder {
      text-align: center;
      color: #9f9b9b;
      font-size: 14px;
    }
    &:focus {
      border: 2px solid #326ae4;
    }
  }

  .tableCell2 {
    min-width: 90px;
    max-width: 120px;
    height: 37px;
    justify-content: space-around;
    border: solid #bdc1c2 1px;
    border-radius: 5px;
    padding: 5px;
    outline: none;
    background-color: rgba(165, 162, 162, 0);
    background-color: ${WHITE_COLOR};
    &::placeholder {
      text-align: center;
      color: #aaa6a6;
      font-size: 14px;
    }
    &:focus {
      border: 2px solid #346eea;
    }
  }

  table {
    width: fit-content;
    border-spacing: 0px;
  }
  thead {
    position: sticky;
    top: 0;
  }
  tbody {
    margin-top: 32px;
  }

  tr {
    width: fit-content;
    height: fit-content;
  }

  td {
    padding: 0.25rem;
    height: 40px;
    text-align: center;
  }

  th {
    position: relative;
    font-weight: bold;
    text-align: center;
    padding-bottom: 8px;
    border: none;
    background-color: #9bc4cb;
    font-size: 1.8rem;
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -4px;
    height: 100%;
    width: 7px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: var(--background-primary_color);
    opacity: 1;
  }

  select {
    border: none;
    padding: 0;
    height: 20px;
    outline: none;
    cursor: pointer;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }
`;

export const ArrowPagination = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonInfo = styled.div`
  cursor: pointer;
  .container-tooltip {
    position: relative;
  }

  .tooltip {
    display: block;
    position: absolute;
    border-radius: 3px;
    width: 160px;
    padding: 0px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 5px;
    padding-bottom: 3px;
    transform: ${(props) => props.transform};
    border: solid 2px ${PRIMARY_COLOR};
    background-color: ${PRIMARY_COLOR_TRANS};
    color: ${WHITE_COLOR};
    text-align: center;
    z-index: 999;
    visibility: visible;
    opacity: 1;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .buttonTooltip {
    position: absolute;
    margin-top: -5px;
    border-radius: 50%;
    font-size: 0.8rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 3px;
    padding-right: 3px;
    right: 0;
    background-color: ${WHITE_COLOR};
    color: var(--background-primary_color);
    border: none;
  }
  .buttonTooltip:active {
    border: none;
    background-color: #1889ca;
  }
  .buttonTooltip:hover {
    transform: scale(1.5);
    background-color: ${WHITE_COLOR};
    color: var(--background-primary_color);
    cursor: pointer;
  }
`;

export const ContainerTable = styled.div`
  border: 1px solid ${BORDER_GREY};
  border-radius: 5px;
  background-color: ${WHITE_BACKGROUND};
  /* color: ${BLACK_COLOR}; */
  padding: 1rem;

  p {
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
`;

export const ContainerSearchTable = styled.div`
  display: flex;
  border: 1px solid ${BORDER_GREY};
  border-radius: 5px;
  margin: 1rem;
  background: ${BACKGROUND_SECOND_GREY};
`;

export const SearchTable = styled.div`
  display: flex;
  margin: 1rem;
  justify-content: space-between;
  margin-right: 34%;

  .container-inputs {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .container-tools {
    width: ${({ containerToolsWidth }) => containerToolsWidth};
  }

  @media (min-width: 1200px) and (max-width: 1875px) {
    margin-right: 33%;
  }

  @media (min-width: 0px) and (max-width: 1844px) {
    justify-content: start;
    margin-right: 0%;
  }

  .input {
    /* flex-grow: 1; */
    border-radius: 5px;
    font-size: 15px;
    padding: 0.8rem;
    outline: none;
    border: 1px solid #cccccc;
    height: 36px;
    width: 95%;
  }

  .input:hover {
    border-color: #b3b5b5;
  }

  .input:focus {
    border: 2px solid #2d69ec;
  }
  .check {
    width: 2rem;
    height: 2rem;
    margin-top: 5px;
    margin-left: 5px;
  }
  button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    background-color: ${WHITE_COLOR};
    color: var(--background-primary_color);
    cursor: pointer;

    .container-tooltip {
      position: relative;
    }

    .tooltip {
      display: block;
      position: absolute;
      border-radius: 5px;
      width: 90px;
      padding: 1px;
      margin-top: 2px;
      transform: translate(-65%, 10px);
      border: solid 2px ${PRIMARY_COLOR};
      background-color: ${PRIMARY_COLOR_TRANS};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
      visibility: hidden;
    }

    .tooltipOpenWindow {
      display: block;
      position: absolute;
      border-radius: 5px;
      width: 100px;
      padding: 1px;
      margin-top: 2px;
      transform: translate(-25%, 10px);
      border: solid 2px ${PRIMARY_COLOR};
      background-color: ${PRIMARY_COLOR_TRANS};
      color: ${WHITE_COLOR};
      text-align: center;
      z-index: 999;
      visibility: hidden;
    }

    &:hover .tooltip {
      opacity: 1;
      visibility: visible;
      transition-delay: 1s;
    }
    &:hover .tooltipOpenWindow {
      opacity: 1;
      visibility: visible;
      transition-delay: 1s;
    }
  }
  button:active {
    /* border: solid 2px ${WHITE_COLOR}; */
  }

  button:hover {
    color: var(--background-secondary_color);
  }
`;

export const Change = styled.button`
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);

  .container-tooltip {
    position: relative;
  }

  .tooltip {
    display: block;
    position: absolute;
    border-radius: 5px;
    width: 80px;
    padding: 1px;
    transform: translate(-35%, 5px);
    border: solid 2px ${PRIMARY_COLOR};
    background-color: ${PRIMARY_COLOR_TRANS};
    color: ${WHITE_COLOR};
    text-align: center;
    z-index: 999;
    visibility: hidden;
  }
  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
    transition-delay: 1s;
  }
`;

export const Divider = styled.div`
  width: 100%;
`;

export const DateFilterContentStyle = styled("div")`
  display: flex;
  justify-content: space-evenly;

  .contentButtons {
    width: fit-content;
    display: flex;
    justify-content: space-between;
  }

  .resetButton {
    display: flex;
    align-items: center;
    width: fit-content;
    border: none;
    background-color: #9bc4cb;
    margin: 0;
    cursor: pointer;
  }
`;

export const ContainerChangeGeoreference = styled.div`
  padding: 1.5rem;
  max-width: 48rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .description {
    margin-bottom: 1rem;
    color: #374151;
    line-height: 1.5;
  }

  .checkbox-group {
    display: flex;
    gap: 2rem;
    margin: 1.5rem 0;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  .checkbox-input {
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &:checked {
      accent-color: #2563eb;
    }
  }

  .checkbox-text {
    font-size: 1.5rem;
  }

  .info-text {
    margin-bottom: 0.5rem;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      color: #1f2937;
    }
  }

  .warning-icon {
    color: #d97706;
  }
`;
export const getSelectStyles = (rowCount) => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      position: "relative",
      borderRadius: "5px",
      marginLeft: "20px",
      marginRight: "20px",
      minWidth: "200px",
    }),
    menuList: (baseStyles, state) => ({
      ...baseStyles,
      maxHeight: `${
        rowCount === 1
          ? "75px"
          : rowCount === 2
          ? "90px"
          : rowCount === 3
          ? "100px"
          : "200px"
      }`,
      marginTop: "2px",
      fontSize: "15px",
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      textAlign: "left",
      minWidth: "200px",
      paddingLeft: "10px",
      fontWeight: "normal",
      maxHeight: `${
        rowCount === 1
          ? "75px"
          : rowCount === 2
          ? "90px"
          : rowCount === 3
          ? "100px"
          : "200px"
      }`,
      marginTop: "2px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
      fontWeight: "normal",
    }),
  };
};

const pulseAnimation = keyframes`
  0%, 100% { 
    color: ${PRIMARY_COLOR}; 
  }
  50% { 
    color: ${SECOND_COLOR}; 
  }
`;

export const AnimatedWorkspacesIcon = styled(WorkspacesIcon).attrs({
  fontSize: "large",
})`
  && {
    font-size: 3rem !important;
    animation: ${pulseAnimation} 2s ease-in-out infinite;
  }
`;
