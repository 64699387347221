import axios from "axios";
import { config } from "config.js";

export const cancelBulkDeleteLines = async () => {
  const token = localStorage.getItem("token");
  const url = `${config.URL_BACKEND_PG}api/v1/lines/bulk-delete/cancel`;

  const response = await axios.post(url, null, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  return response.data;
};
