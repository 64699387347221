import axios from "axios";
import { urls } from "utils/urlKeys";

const findTypeObjectsTypeObjects = async ({
  pointTypeObjectRelationId,
  pointTypeObjectId,
}) => {
  const token = localStorage.getItem("token");
  const urlParams = new URLSearchParams();

  if (pointTypeObjectRelationId)
    urlParams.append("pointTypeObjectRelationId", pointTypeObjectRelationId);
  if (pointTypeObjectId)
    urlParams.append("pointTypeObjectId", pointTypeObjectId);

  const result = await axios.get(
    urls.objects.typeObjectsTypeObjects(urlParams.toString()),
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );

  const data = await result.data;
  return data;
};

export default findTypeObjectsTypeObjects;
