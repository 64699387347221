export const isValidCoordinate = (coord) => {
  const [lon, lat] = coord;
  return (
    lon !== undefined &&
    lat !== undefined &&
    !isNaN(lon) &&
    !isNaN(lat) &&
    lat >= -90 &&
    lat <= 90 &&
    lon >= -180 &&
    lon <= 180
  );
};
