import timeZoneOffset from "services/timeZoneOffset";
import { useUserStore } from "zustandGloabalStore/user";

/**
 * Creates a structured object with point data and attributes for API submission.
 *
 * This function builds a complete request body by combining form data, location information,
 * and processing form fields into a normalized attributes array.
 *
 * @function createBodyObject
 * @param {Object} options - Configuration options
 * @param {Object} options.dataForm - Form data containing field values
 * @param {number|null} options.typeObjectRelationId - ID of the related object type
 * @param {Object} options.dataPoint - Geographic point data with coordinates
 * @param {Object} options.genericData - Additional data for generic objects
 * @param {Function} options.getValues - Function to retrieve form values
 * @param {Object} options.typeElemetFields - Field definitions with aliases
 * @param {Object} options.allOperators - List of available operators/users
 * @returns {Object} Structured body object ready for submission
 *
 * @example
 * const bodyObject = createBodyObject({
 *   dataForm: formValues,
 *   typeObjectRelationId: 123,
 *   dataPoint: { geometry: { coordinates: [-73.935242, 40.730610] } },
 *   genericData: { point: { name: "Sample Point" } },
 *   getValues: form.getValues,
 *   typeElemetFields: fieldDefinitions,
 *   allOperators: operatorsList
 * });
 * // Returns a structured object with location, metadata and normalized attributes
 */
export const createBodyObject = ({
  dataForm,
  typeObjectRelationId,
  dataPoint,
  genericData,
  getValues,
  typeElemetFields,
  allOperators,
}) => {
  const instanceId = useUserStore.getState().instanceId;
  const userId = useUserStore.getState().userId;

  // Create initial body object
  const data = typeObjectRelationId
    ? {
      userId: Number(userId),
      location: {
        latitude: dataPoint.geometry.coordinates[1],
        longitude: dataPoint.geometry.coordinates[0],
      },
      instanceId: instanceId,
      typeElementId: typeObjectRelationId,
    }
    : { ...genericData.point };

  const body = {
    dataSourceId: 2, // desktop source
    date: new Date(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneOffset: timeZoneOffset(),
    typeElementId: parseInt(getValues("Type element")),
    ...data,
  };

  // Extract key fields from dataForm
  const keyFields = getKeyFields(dataForm);

  // Create attributes object
  const attributes = createAttributes(dataForm, keyFields);

  // Filter out attributes with empty values
  const filetAttributes = filterEmptyAttributes(attributes);

  // add alias to every field, if exists
  const objectAttributes = filetAttributes.map((item) => {
    const field = typeElemetFields?.fields.find((it) => it.name === item.name);
    if (field) {
      item.alias = field?.alias || "";
    }
    if (field?.type === "delegate") {
      const userDelegate = allOperators.find(
        (elm) => `${elm?.firstName} ${elm?.firstLastName}` === item.value
      );
      item.userId = userDelegate.userId;
    }
    return item;
  });

  // Combine attributes with initial body object
  const newBody = {
    ...body,
    attributes: objectAttributes,
  };

  return newBody;
};

/**
 * Extracts relevant fields from form data, excluding system fields.
 *
 * @private
 * @function getKeyFields
 * @param {Object} dataForm - Form data containing all fields
 * @returns {Array<string>} Array of field names to be processed as attributes
 */
const getKeyFields = (dataForm) => {
  const exceptions = [
    "Object type",
    "Type element",
    "Field baseline",
    "Field equipment name",
    "Comments",
  ];

  return Object.keys(dataForm).filter((key) => !exceptions.includes(key));
};

/**
 * Creates an array of attribute objects from form fields.
 *
 * @private
 * @function createAttributes
 * @param {Object} dataForm - Form data containing field values
 * @param {Array<string>} keyFields - Field names to be processed
 * @returns {Array<Object>} Array of attribute objects with name, value and userId
 */
const createAttributes = (dataForm, keyFields) => {
  return keyFields.map((key) => {
    let value = dataForm[key];
    if (typeof value === "boolean") value = value.toString();
    return {
      name: key,
      value: value === "" ? " " : value,
      userId: dataForm[`delegate-userId-${key}`],
    };
  });
};

/**
 * Filters out attributes with empty values.
 *
 * @private
 * @function filterEmptyAttributes
 * @param {Array<Object>} attributes - Array of attribute objects
 * @returns {Array<Object>} Filtered array with non-empty values
 */
const filterEmptyAttributes = (attributes) => {
  return attributes.filter((attribute) => attribute.value !== "");
};
