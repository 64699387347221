import {
  CardContainer,
  ContainerInformationCard,
  ResizeBottom,
  ResizeRight,
} from "./indexStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  COMPONENT_CLUSTER_COLOR,
  EVENT_CLUSTER_COLOR,
  GRAY_SECOND,
  GREY_TRANS,
  OBJECT_CLUSTER_COLOR,
} from "utils/const";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import { updateOrderCard } from "services/Dashboard/orderCards";
import { useSWRConfig } from "swr";
import { config } from "config";
import handleAddGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridColumn";
import handleRemoveGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridColumn";
import handleRemoveGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridRow";
import handleAddGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridRow";
import CarbonSummaryKpi from "icons/CarbonSummaryKpi";
import PhChartBar from "icons/PhChartBar";
import CarbonChartBarStacked from "icons/CarbonChartBarStacked";
import CarbonChartTimeSeriesBar from "icons/CarbonChartTimeSeriesBar";
import CarbonChartLineSmooth from "icons/CarbonChartLineSmooth";
import CarbonChartLineStacked from "icons/CarbonChartLineStacked";
import BxsDoughnutChart from "icons/BxsDoughnutChart";
import BxsGroupDoughnutChart from "icons/BxsGroupDoughnutChart";
import MaterialSymbolsTextSnippetOutline from "icons/MaterialSymbolsTextSnippetOutline";
import { useEffect, useState } from "react";

export const InformationCard = ({
  dataChart,
  currentStyle,
  template,
  index,
  setCurrentTemplate,
  responsiveWidth = null,
  responsiveHeight = null,
  restore = false,
  orderId,
  currentOrderName,
  currentOrderViews,
  setOpenDialogRestoreChart,
  setCurrentOrderViews,
  currentPositionId,
  handlerDeleteChartOnView,
  setDeleteChartDialog,
}) => {
  const role = localStorage.getItem("role");
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;
  const { mutate } = useSWRConfig();
  const { setDragAndDroll } = useStoreDashboard();
  const chartsIconsShowOnOff = useStoreDashboard(
    (state) => state.chartsIconsShowOnOff
  );

  const [currentChart, setCurrentChart] = useState({
    alias: "",
    typeChart: "",
    value: "",
    icon: null,
    from: "",
  });

  const chartConfigMap = {
    kpiChart: {
      typeChart: "KPI Chart",
      icon: <CarbonSummaryKpi color={"#494949"} />,
    },
    barChart: {
      typeChart: "Bar Chart",
      icon: <PhChartBar color={"#494949"} />,
    },
    groupBarChart: {
      typeChart: "Group Bar Chart",
      icon: <CarbonChartBarStacked color={"#494949"} />,
    },
    timeSeriesBarChart: {
      typeChart: "Time Series Bar Chart",
      icon: <CarbonChartTimeSeriesBar color={"#494949"} />,
    },
    lineChart: {
      typeChart: "Line Chart",
      icon: <CarbonChartLineSmooth color={"#494949"} />,
    },
    timeSeriesChart: {
      typeChart: "Time Series Line Chart",
      icon: <CarbonChartLineStacked color={"#494949"} />,
    },
    doughnutChart: {
      typeChart: "Doughnut Chart",
      icon: <BxsDoughnutChart color={"#494949"} />,
    },
    groupDoughnutChart: {
      typeChart: "Group Doughnut Chart",
      icon: <BxsGroupDoughnutChart color={"#494949"} />,
    },
    textChart: {
      typeChart: "Text Chart",
      icon: <MaterialSymbolsTextSnippetOutline color={"#494949"} />,
    },
  };

  useEffect(() => {
    if (dataChart?.chartType && chartConfigMap[dataChart.chartType]) {
      const { typeChart, icon } = chartConfigMap[dataChart.chartType];
      setCurrentChart({
        alias:
          dataChart.chartType === "textChart"
            ? dataChart?.sections[0].title ||
              dataChart?.sections[0].subtitle ||
              dataChart?.sections[0].body
            : dataChart?.alias,
        typeChart,
        value: dataChart.chartType,
        icon,
        from:
          dataChart?.from === "allTypes"
            ? dataChart?.fromType
            : dataChart?.from,
      });
    }
  }, [dataChart]);

  const handleRestoreChart = async () => {
    await updateOrderCard({
      id: orderId,
      body: {
        name: currentOrderName,
        position: [
          ...currentOrderViews,
          {
            id: dataChart._id,
            sizeChart: template.sizeChart,
          },
        ],
        _id: currentPositionId,
      },
      type: dataChart.from,
    });
    setCurrentOrderViews((current) => [
      ...current,
      {
        id: dataChart._id,
        sizeChart: template.sizeChart,
      },
    ]);
    mutate(urlOrders);
    setOpenDialogRestoreChart(false);
  };

  return (
    <CardContainer
      key={dataChart._id}
      width={
        template?.index
          ? currentStyle.width
          : responsiveWidth
          ? `${currentStyle.width - responsiveWidth}px`
          : `${currentStyle.width}px`
      }
      height={
        template?.index
          ? currentStyle.height
          : responsiveHeight
          ? `${currentStyle.height - responsiveHeight}px`
          : `${currentStyle.height}px`
      }
      borderColor={
        dataChart.from === "allTypes" && dataChart.fromType === "objects"
          ? OBJECT_CLUSTER_COLOR
          : dataChart.from === "allTypes" && dataChart.fromType === "events"
          ? EVENT_CLUSTER_COLOR
          : dataChart.from === "allTypes" && dataChart.fromType === "components"
          ? COMPONENT_CLUSTER_COLOR
          : dataChart.fromType === "objects"
          ? OBJECT_CLUSTER_COLOR
          : dataChart.fromType === "events"
          ? EVENT_CLUSTER_COLOR
          : dataChart.fromType === "components"
          ? COMPONENT_CLUSTER_COLOR
          : GRAY_SECOND
      }
    >
      {role === "Administrador de compañía" &&
        !restore &&
        chartsIconsShowOnOff && (
          <button
            className="delete-card-button"
            onClick={() =>
              currentOrderName === "Design New View"
                ? setDeleteChartDialog(true)
                : handlerDeleteChartOnView()
            }
          >
            <span>
              <CloseIcon
                sx={{
                  color: GREY_TRANS,
                }}
              />
            </span>
          </button>
        )}

      {role === "Administrador de compañía" &&
        restore &&
        chartsIconsShowOnOff && (
          <button
            className="restore-card-button"
            onClick={() => handleRestoreChart()}
          >
            <RestartAltIcon
              sx={{
                color: GREY_TRANS,
              }}
            />
          </button>
        )}

      {template && (
        <>
          <ResizeRight>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleAddGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleRemoveGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeRight>
          <ResizeBottom>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleRemoveGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleAddGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeBottom>
        </>
      )}
      <ContainerInformationCard>
        <span>{currentChart.icon}</span>
        <h1>
          {currentChart.alias.split(" ").length > 8 ||
          currentChart.alias.split(" ").some((word) => word.length > 10)
            ? currentChart.alias
                .split(" ")
                .slice(0, 8)
                .map((word) =>
                  word.length > 10 ? word.slice(0, 10) + "..." : word
                )
                .join(" ") + "..."
            : currentChart.alias}
        </h1>

        <p>
          {currentChart.typeChart} of {currentChart.from}
        </p>
      </ContainerInformationCard>
    </CardContainer>
  );
};
