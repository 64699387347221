// React
import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { useEffect, useState } from "react";
// Redux
import { setFeaturesDetailsDT } from "redux/actions/admin";
import { setObjectsUnderDrawnPoint } from "redux/actions/index";
import { useDispatch } from "react-redux";
// Components
import TextInput from "../GenericInput";
import SelectInput from "../SelectInput";
// Custom hooks
import useGet from "hooks/useFetch";
// Style
import { Form } from "./DynamicFieldsStyle";
// Services
import saveObject from "services/objects/saveObject";
import createObjectRelation from "services/objects/createObjectRelaction";
// Config
import useSwr from "swr";
// Hooks
import CustomButton from "components/Buttons/CustomButton";
import { updateAttributesObjectMg } from "services/updateAttributesMg";
import CircularIndeterminate from "components/Lodings/LoadingV2";
import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import updateObjectPg from "services/updateObjectPg";
import { urls, urlsApiMg } from "utils/urlKeys";
import { LineConteiner2 } from "pages/ConfigAdmin/typeElemts/FieldsStyle";
import updateAssociationGlobalMeasurementTable from "services/GlobalMeasurementTable/updateAssociationGlobalMeasurementTable";
import { useSelector } from "react-redux";
import { setMessageLoadingMap } from "redux/actions/digitalTwin";
import { bodyObjectWithFiles } from "services/objects/createBodyObjectWithFiles";
import { createBodyObject } from "services/objects/createBodyObject";
import { useUserStore } from "zustandGloabalStore/user";

function Formulario({
  feature,
  objectId,
  closeModal,
  typeObjectRelationId,
  genericData,
  dataObject,
  update = false,
  setOpenDialog,
  handleUpdateData,
}) {
  // States
  const [typeElemetFields, setTypeElementFields] = useState();
  const [dataListTables, setDataListTables] = useState([]);
  const [dataMeasurementTables, setDataMeasurementTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [globalTableCategories, setGlobalTableCategories] = useState({});
  const [dataSelectedAssociate, setDataSelectedAssociate] = useState([]);
  const [useSelectAutocomplete, setUseSelectAutocomplete] = useState(false);

  const instanceId = useUserStore((state) => state.instanceId);

  const controlGl = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );

  // Custom hooks
  const [typeElement, errorTypeElement] = useGet(
    urls.objects.typeObjectsParams(
      `id=${genericData?.point?.typeElementId ||
      dataObject?.typeElementId ||
      typeObjectRelationId
      }`
    )
  );

  const { data: dataLibrary, error: errorLibrary } = useFetchObjectLibraries({
    id: dataObject?.typeElement?.pointLibraryId,
  });
  // Swr
  const { mutate } = useSWRConfig();
  // Redux
  const dispatch = useDispatch();
  // Forms
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    urls.users.getOperators
  );
  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];

  //Get Data Global Tables
  const { data: globalTables, error: errorGlobalTables } = useSwr(
    urlsApiMg.globalValuesFields
  );

  const { data: globalListTables, error: errorGlobalListTables } = useSwr(
    urlsApiMg.globalListTalble
  );

  const { data: globalMeasurementTables, error: errorGlobalMeasurementTables } =
    useSwr(urlsApiMg.globalMeasurementTable);

  const handlerChangeGlobalMeasurement = ({
    e,
    categoryId,
    tableId,
    categoryListTableId,
    listTableId,
    masterKey,
    inputName,
    onChange,
  }) => {
    const { value } = e.target;
    setValue(`${inputName}-globalMeasurementTable`, {
      value,
      categoryId,
      tableId,
      masterKey,
    });
    if (onChange) {
      typeElemetFields.fields.forEach((field) => {
        if (
          field.type === "select" &&
          field.hasOwnProperty("globalMeasurementTable") &&
          Object.keys(field.globalMeasurementTable).length > 0
        ) {
          const currentCategoryMeasurementTable = dataMeasurementTables.find(
            (category) => {
              return category._id === field.globalMeasurementTable?.categoryId;
            }
          );
          const selectedMeasurementTable =
            currentCategoryMeasurementTable?.groupTables?.find((table) => {
              return table._id === field.globalMeasurementTable.tableId;
            });

          const currentCategoryListTable = dataListTables.find((category) => {
            return (
              category._id ===
              selectedMeasurementTable.associationGlobalListTable?.categoryId
            );
          });

          if (
            field.globalMeasurementTable.optionsSelectFromListTable
              .associationListTableId === listTableId
          ) {
            const currentMeasumentTable =
              currentCategoryMeasurementTable?.groupTables?.find((table) => {
                return table._id === field.globalMeasurementTable.tableId;
              });

            const currentListTable = currentCategoryListTable?.groupTables.find(
              (table) => {
                return table._id === listTableId;
              }
            );

            const masterKey = currentMeasumentTable?.dataColumns?.find(
              (elm) => elm.masterKey
            );

            const selectedOptionValue = dataObject?.attributes?.find((elm) => {
              return elm.name === field.name;
            })?.value;

            const currentRowSelected = currentListTable.rows.find((elm) => {
              return elm[masterKey.name] === selectedOptionValue;
            });

            if (currentRowSelected) {
              const statusDataSelected = currentMeasumentTable?.rows
                ?.map((row, index) => {
                  if (row["listTableRowId"] === currentRowSelected["Id"]) {
                    return {
                      ...row,
                      index,
                      currentCategoryMeasurementTable,
                      currentMeasumentTable,
                    };
                  }
                })
                .filter((it) => it !== undefined);
              setDataSelectedAssociate((current) => {
                const newItems = statusDataSelected.filter(
                  (newItem) =>
                    !current.some(
                      (currentItem) =>
                        currentItem.currentMeasumentTable._id ===
                        newItem.currentMeasumentTable._id
                    )
                );

                return [...current, ...newItems];
              });
            } else {
              setDataSelectedAssociate([]);
            }
          }
        }
      });
    }
  };

  const handlerSetByGlobalListTable = ({ e }) => {
    const { value } = e.target;
    typeElemetFields.fields.forEach((field) => {
      if (
        field.type === "select" &&
        field.hasOwnProperty("globalMeasurementTable") &&
        Object.keys(field.globalMeasurementTable).length > 0
      ) {
        const currentCategoryMeasurementTable = dataMeasurementTables.find(
          (category) => {
            return category._id === field.globalMeasurementTable?.categoryId;
          }
        );
        const selectedMeasurementTable =
          currentCategoryMeasurementTable?.groupTables?.find((table) => {
            return table._id === field.globalMeasurementTable.tableId;
          });

        const currentCategoryListTable = dataListTables.find((category) => {
          return (
            category._id ===
            selectedMeasurementTable.associationGlobalListTable?.categoryId
          );
        });

        const currentListTable = currentCategoryListTable?.groupTables.find(
          (table) => {
            return (
              table._id ===
              selectedMeasurementTable.associationGlobalListTable?.tableId
            );
          }
        );
        if (
          field.globalMeasurementTable.optionsSelectFromListTable
            .associationListCategoryId === currentCategoryListTable._id &&
          field.globalMeasurementTable.optionsSelectFromListTable
            .associationListTableId === currentListTable._id &&
          field.globalMeasurementTable.categoryId ===
          currentCategoryMeasurementTable._id &&
          field.globalMeasurementTable.tableId === selectedMeasurementTable._id
        ) {
          const currentRowSelected = currentListTable.rows.find(
            (elm) => elm.Id === value
          );
          const masterKey = selectedMeasurementTable?.dataColumns?.find(
            (elm) => elm.masterKey
          );
          if (currentRowSelected) {
            setValue(`${field.alias}-globalMeasurementTable`, {
              value: currentRowSelected[masterKey.name],
              categoryId: field.globalMeasurementTable.categoryId,
              tableId: field.globalMeasurementTable.tableId,
              masterKey,
            });
            setValue(field.name, currentRowSelected[masterKey.name]);
          }
        }
      }
    });
  };

  // Effects
  useEffect(() => {
    if (globalTables && !errorGlobalTables && globalTables.length > 0) {
      setGlobalTableCategories(globalTables[0].tableCategories);
    }
  }, [globalTables, errorGlobalTables]);

  useEffect(() => {
    typeElement && !errorTypeElement && setTypeElementFields(typeElement[0]);
  }, [typeElement, errorTypeElement]);

  useEffect(() => {
    if (
      !errorGlobalListTables &&
      globalListTables &&
      globalListTables.length > 0
    ) {
      setDataListTables(globalListTables[0].tableCategories);
    }
  }, [globalListTables, errorGlobalListTables]);

  useEffect(() => {
    if (
      !errorGlobalMeasurementTables &&
      globalMeasurementTables &&
      globalMeasurementTables.length > 0
    ) {
      setDataMeasurementTables(globalMeasurementTables[0].tableCategories);
    }
  }, [globalMeasurementTables, errorGlobalMeasurementTables]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericData?.point?.typeElementId]);

  useEffect(() => {
    if (update && dataObject) {
      const existingFieldNames = dataObject?.attributes?.map(
        (attribute) => attribute.name
      );
      !errorLibrary &&
        dataLibrary[0]?.fields?.forEach((field) => {
          if (
            !existingFieldNames.includes(field.name) &&
            field.type !== "notification" &&
            field.type !== "captureMedia" &&
            field.type !== "lineTag"
          ) {
            const newAttribute = {
              name: field.name,
              alias: field.alias,
              value: "",
              userId: null,
            };
            dataObject.attributes.push(newAttribute);
          }
        });

      dataObject?.attributes?.forEach((elm) => {
        if (elm.value?.[0] === "{") {
          const dataParse = JSON.parse(elm.value);

          if (dataParse.type === "number" && dataParse.value) {
            return setValue(elm.name, parseFloat(dataParse.value));
          }
        } else if (elm.value === "true" || elm.value === "false") {
          return setValue(elm.name || elm.alias, JSON.parse(elm.value));
        } else {
          const value =
            elm?.value?.length === 0 || elm?.value === " "
              ? ""
              : !isNaN(elm.value)
                ? parseFloat(elm.value)
                : elm.value;
          return setValue(elm.name, value);
        }
      });
    }
  }, [dataObject, typeElemetFields, operatorsAdmin, errorOperators]);

  useEffect(() => {
    if (typeElemetFields && typeElemetFields.fields.length !== 0) {
      const selectGlobal = typeElemetFields.fields.filter((elm) => {
        return (
          elm.type === "select" &&
          elm.globalSelect &&
          !elm.hasOwnProperty("columnKeyGlobalSpecs")
        );
      });
      typeElemetFields.fields.forEach((elm) => {
        if (
          (elm.type === "string" ||
            elm.type === "number" ||
            elm.type === "file" ||
            elm.type === "date") &&
          elm.globalSelect
        ) {
          const matchSelect = selectGlobal.find((item) => {
            return (
              elm?.columnKeyGlobalSpecs?.selectGlobalId === item._id ||
              elm?.columnKeyGlobalSpecs?.selectGlobalName === item.name
            );
          });
          const findCategory = globalTableCategories?.find((item) => {
            return elm.columnKeyGlobalSpecs.categoryId === item._id;
          });

          const matchTbale = findCategory?.groupTables?.find((item) => {
            return elm.columnKeyGlobalSpecs.tableId === item._id;
          });

          const row = matchTbale?.rows?.find((row) => {
            return (
              row?.Options ===
              getValues(matchSelect?.name || matchSelect?.alias)
            );
          });

          if (row) {
            return setValue(
              elm?.name || elm?.alias,
              row[elm.columnKeyGlobalSpecs.column.name]
            );
          }
        }
      });
    }
  }, [useSelectAutocomplete, globalTableCategories, typeElemetFields]);

  useEffect(() => {
    if (typeElemetFields && typeElemetFields.fields.length !== 0) {
      typeElemetFields.fields?.map((field, index) => {
        if (
          field.type === "select" &&
          field.hasOwnProperty("globalMeasurementTable") &&
          Object.keys(field.globalMeasurementTable).length > 0
        ) {
          const currentCategoryMeasurementTable = dataMeasurementTables.find(
            (category) => {
              return category._id === field.globalMeasurementTable?.categoryId;
            }
          );
          const selectedMeasurementTable =
            currentCategoryMeasurementTable?.groupTables.find((table) => {
              return table._id === field.globalMeasurementTable.tableId;
            });

          const currentCategoryListTable = dataListTables.find((category) => {
            return (
              category._id ===
              selectedMeasurementTable.associationGlobalListTable?.categoryId
            );
          });

          const currentListTable = currentCategoryListTable?.groupTables.find(
            (table) => {
              return (
                table._id ===
                selectedMeasurementTable.associationGlobalListTable?.tableId
              );
            }
          );

          const masterKey = selectedMeasurementTable?.dataColumns.find(
            (elm) => elm.masterKey
          );

          const optionsToCreate = [
            ...new Set(
              selectedMeasurementTable?.rows
                .filter((row) => row.Association === "false")
                .map((row) => row[masterKey.name])
            ),
          ];

          if (optionsToCreate.length > 0) {
            handlerChangeGlobalMeasurement({
              e: {
                target: {
                  name: field.name,
                  value: optionsToCreate[0],
                },
              },
              categoryId: currentCategoryMeasurementTable?._id,
              tableId: selectedMeasurementTable?._id,
              categoryListTableId: currentCategoryListTable?._id,
              listTableId: currentListTable?._id,
              masterKey: masterKey.name,
              inputName: field.alias || field.name,
              onChange: false,
            });
          }
        }
      });
    }
  }, [typeElemetFields, dataMeasurementTables]);

  useEffect(() => {
    if (!update && typeElemetFields && typeElemetFields.fields.length !== 0) {
      typeElemetFields.fields?.map((field, index) => {
        if (
          field.type === "select" &&
          field.hasOwnProperty("globalMeasurementTable") &&
          Object.keys(field.globalMeasurementTable).length > 0
        ) {
          const currentCategoryMeasurementTable = dataMeasurementTables.find(
            (category) => {
              return category._id === field.globalMeasurementTable?.categoryId;
            }
          );
          const selectedMeasurementTable =
            currentCategoryMeasurementTable?.groupTables.find((table) => {
              return table._id === field.globalMeasurementTable.tableId;
            });
          const masterKey = selectedMeasurementTable?.dataColumns.find(
            (elm) => elm.masterKey
          );

          const currentCategoryListTable = dataListTables.find((category) => {
            return (
              category._id ===
              selectedMeasurementTable.associationGlobalListTable?.categoryId
            );
          });

          const currentListTable = currentCategoryListTable?.groupTables.find(
            (table) => {
              return (
                table._id ===
                selectedMeasurementTable.associationGlobalListTable?.tableId
              );
            }
          );
          if (
            field?.globalMeasurementTable?.optionsSelectFromListTable
              ?.associationListCategoryId === currentCategoryListTable?._id &&
            field?.globalMeasurementTable?.optionsSelectFromListTable
              ?.associationListTableId === currentListTable?._id
          ) {
            const availableOptions = selectedMeasurementTable.rows.filter(
              (elm) => elm["Association"] === "false"
            );
            setValue(`${field.alias}-globalMeasurementTable`, {
              value: update
                ? selectedMeasurementTable.rows[0][masterKey.name]
                : availableOptions[0][masterKey.name],
              categoryId: field.globalMeasurementTable.categoryId,
              tableId: field.globalMeasurementTable.tableId,
              masterKey,
            });

            setValue(
              field.name,
              update
                ? selectedMeasurementTable.rows[0][masterKey.name]
                : availableOptions[0][masterKey.name]
            );
          }
        }
      });
    }
  }, [typeElemetFields, dataMeasurementTables, dataListTables, update]);

  // Method
  const onSubmit = async (data) => {
    setLoading(true);
    if (update) {
      const numericFields = await typeElemetFields.fields
        ?.map((field) => {
          if (field.type === "number") {
            const elmt = document.getElementById(field.name);
            if (elmt) {
              field.value = elmt.value;
              return field;
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = await typeElemetFields.fields?.filter(
        (field) => field.type === "operation"
      );

      if (numericFields.length > 0)
        numericFields.forEach((fld) => {
          fld.value = fld.value === "" ? NaN : fld.value;
          data[fld.name] = JSON.stringify(fld);
        });
      if (expFields.length > 0)
        expFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });

      const fileFields = typeElemetFields.fields.filter(
        (field) => field.type === "file"
      );

      await bodyObjectWithFiles({ fileFields, getValues, data });

      const bodyObject = createBodyObject({
        dataForm: data,
        genericData: {
          instanceId: instanceId,
          location: {
            latitude: dataObject.location.latitude,
            longitude: dataObject.location.longitude,
          },
          typeElementId: dataObject.typeElementId,
          userId: dataObject.userId,
        },
        getValues,
        typeElemetFields,
        allOperators,
      });

      const attributes = [...bodyObject.attributes];

      const newAttributes = attributes
        .map((attribute) => {
          const matchingField = typeElemetFields.fields.find(
            (field) => field.name === attribute.name && field.globalSelect
          );
          if (matchingField) {
            return {
              ...attribute,
              autocomplete: matchingField.globalSelect,
              columnKeyGlobalSpecs:
                matchingField.type === "select"
                  ? {
                    categoryId: matchingField.globalTable.categoryId,
                    tableId: matchingField.globalTable.tableId,
                    column: {
                      name: "Options",
                    },
                    selectGlobalName: matchingField.name,
                    globalKey: data[matchingField.name],
                    pointLibrary: {
                      id: typeElemetFields.pointLibrary.id,
                      mongoId: typeElemetFields.pointLibrary.mongoId,
                    },
                    typeElementId:
                      genericData?.point?.typeElementId ||
                      dataObject?.typeElementId ||
                      typeObjectRelationId,
                  }
                  : {
                    ...matchingField.columnKeyGlobalSpecs,
                    globalKey:
                      data[
                      matchingField.columnKeyGlobalSpecs.selectGlobalName
                      ],
                    pointLibrary: {
                      id: typeElemetFields.pointLibrary.id,
                      mongoId: typeElemetFields.pointLibrary.mongoId,
                    },
                    typeElementId:
                      genericData?.point?.typeElementId ||
                      dataObject?.typeElementId ||
                      typeObjectRelationId,
                  },
            };
          } else {
            return attribute;
          }
        })
        .filter((attribute) =>
          typeElemetFields.fields.some(
            (field) => field.name === attribute.name && attribute.alias
          )
        );

      const dataMesurementTable = [];

      for (let key in data) {
        if (typeof data[key] === "object") {
          dataMesurementTable.push(data[key]);
        }
      }

      if (dataMesurementTable.length > 0) {
        await Promise.all(
          dataMesurementTable.map(async (item) => {
            const currentCategoryMeasurementTable = dataMeasurementTables.find(
              (category) => category._id === item?.categoryId
            );

            const selectedMeasurementTable =
              currentCategoryMeasurementTable?.groupTables.find(
                (table) => table._id === item.tableId
              );

            if (!selectedMeasurementTable) {
              return;
            }

            // Encontrar todos los índices donde coinciden los valores seleccionados
            const matchingIndices = selectedMeasurementTable.rows.reduce(
              (indices, elm, index) => {
                if (elm[item.masterKey.name] === item.value) {
                  indices.push(index);
                }
                return indices;
              },
              []
            );
            // Actualizar su propiedad de association a true para cada índice encontrado.
            if (matchingIndices.length > 0) {
              await Promise.all(
                matchingIndices.map((index) =>
                  updateAssociationGlobalMeasurementTable({
                    id: globalMeasurementTables[0]._id,
                    categoryId: currentCategoryMeasurementTable._id,
                    tableId: selectedMeasurementTable._id,
                    body: {
                      indexRow: index,
                      status: "true",
                    },
                  })
                )
              );
            }
            // Cambiar a false la propiedad de Associate del objeto guardado (esto en caso de no estar sincronizado)
            if (dataSelectedAssociate.length > 0) {
              await Promise.all(
                dataSelectedAssociate.map((elm) => {
                  if (
                    elm.currentCategoryMeasurementTable._id ===
                    currentCategoryMeasurementTable._id &&
                    elm.currentMeasumentTable._id ===
                    selectedMeasurementTable._id
                  ) {
                    updateAssociationGlobalMeasurementTable({
                      id: globalMeasurementTables[0]._id,
                      categoryId: elm.currentCategoryMeasurementTable._id,
                      tableId: elm.currentMeasumentTable._id,
                      body: {
                        indexRow: elm.index,
                        status: "false",
                      },
                    });
                  }
                })
              );
            }

            return;
          })
        );
      }
      await updateAttributesObjectMg(dataObject.mongoId, newAttributes);
      const userId = localStorage.getItem("userId");
      await updateObjectPg(
        { lastUpdate: new Date(), userUpdateId: userId },
        dataObject.id
      );

      mutate(urls.objects.detail(dataObject.id));
      mutate(urls.objects.all);

      handleUpdateData();
      setOpenDialog(false);
    } else {
      //get all numeric fields from the form
      const numericFields = await typeElemetFields.fields
        ?.map((field) => {
          if (field.type === "number") {
            const elmt = document.getElementById(field.name);
            if (elmt) {
              field.value = elmt.value;
              return field;
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = await typeElemetFields.fields?.filter(
        (field) => field.type === "operation"
      );

      if (numericFields.length > 0)
        numericFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });
      if (expFields.length > 0)
        expFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });

      const fileFields = typeElemetFields.fields.filter(
        (field) => field.type === "file"
      );

      await bodyObjectWithFiles({ fileFields, getValues, data });

      const bodyObject = createBodyObject({
        dataForm: data,
        typeObjectRelationId,
        dataPoint: feature,
        genericData: genericData,
        getValues,
        typeElemetFields,
        allOperators,
      });

      const attributes = [...bodyObject.attributes];

      const newAttributes = attributes
        .map((attribute) => {
          const matchingField = typeElemetFields.fields.find(
            (field) => field.name === attribute.name && field.globalSelect
          );
          if (matchingField) {
            return {
              ...attribute,
              autocomplete: matchingField.globalSelect,
              columnKeyGlobalSpecs:
                matchingField.type === "select"
                  ? {
                    categoryId: matchingField.globalTable.categoryId,
                    tableId: matchingField.globalTable.tableId,
                    column: {
                      name: "Options",
                    },
                    selectGlobalName: matchingField.name,
                    globalKey: data[matchingField.name],
                    pointLibrary: {
                      id: typeElemetFields.pointLibrary.id,
                      mongoId: typeElemetFields.pointLibrary.mongoId,
                    },
                    typeElementId:
                      genericData?.point?.typeElementId ||
                      dataObject?.typeElementId ||
                      typeObjectRelationId,
                  }
                  : {
                    ...matchingField.columnKeyGlobalSpecs,
                    globalKey:
                      data[
                      matchingField.columnKeyGlobalSpecs.selectGlobalName
                      ],
                    pointLibrary: {
                      id: typeElemetFields.pointLibrary.id,
                      mongoId: typeElemetFields.pointLibrary.mongoId,
                    },
                    typeElementId:
                      genericData?.point?.typeElementId ||
                      dataObject?.typeElementId ||
                      typeObjectRelationId,
                  },
            };
          } else {
            return attribute;
          }
        })
        .filter((attribute) =>
          typeElemetFields.fields.some(
            (field) => field.name === attribute.name && attribute.alias
          )
        );

      const newBody = {
        ...bodyObject,
        attributes: newAttributes,
      };
      const dataMesurementTable = [];

      for (let key in data) {
        if (typeof data[key] === "object") {
          dataMesurementTable.push(data[key]);
        }
      }

      if (dataMesurementTable.length > 0) {
        await Promise.all(
          dataMesurementTable.map(async (item) => {
            const currentCategoryMeasurementTable = dataMeasurementTables.find(
              (category) => category._id === item?.categoryId
            );

            const selectedMeasurementTable =
              currentCategoryMeasurementTable?.groupTables.find(
                (table) => table._id === item.tableId
              );

            if (!selectedMeasurementTable) {
              return;
            }

            // Encontrar todos los índices donde coinciden los valores seleccionados
            const matchingIndices = selectedMeasurementTable.rows.reduce(
              (indices, elm, index) => {
                if (elm[item.masterKey.name] === item.value) {
                  indices.push(index);
                }
                return indices;
              },
              []
            );
            Promise.all(
              matchingIndices.map((index) =>
                updateAssociationGlobalMeasurementTable({
                  id: globalMeasurementTables[0]._id,
                  categoryId: currentCategoryMeasurementTable._id,
                  tableId: selectedMeasurementTable._id,
                  body: {
                    indexRow: index,
                    status: "true",
                  },
                })
              )
            );
            return;
          })
        );
      }
      let res = null;
      if (typeObjectRelationId) {
        res = await saveObject(newBody);
        const resRelation = await createObjectRelation({
          existObjectId: objectId,
          newObjectId: res?.id,
        });
        if (resRelation) {
          closeModal(false);
          mutate(urls.objects.detail(objectId));
        }
      } else {
        res = await saveObject(
          {
            point: newBody,
            regionIds: genericData?.regionIds,
          },
          "objects-region"
        );
      }
      if (res) {
        mutate(urls.objects.all);
        dispatch(
          setFeaturesDetailsDT({
            geometry: {
              type: null,
            },
          })
        );
        dispatch(setObjectsUnderDrawnPoint([]));
        dispatch(setMessageLoadingMap(true));
        if (controlGl && typeof controlGl.deleteAll === "function") {
          controlGl?.deleteAll();
        }
      }
    }
    setLoading(false);
    reset();
  };

  if (typeElemetFields === undefined) {
    return <CircularIndeterminate />;
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {typeElemetFields && (
        <div>
          {typeElemetFields.fields?.map((field, index) => {
            if (field.type === "operation") {
              <TextInput
                key={index}
                field={field}
                register={register}
                errors={errors}
              />;
            }
            if (
              field.type === "select" &&
              field.hasOwnProperty("globalMeasurementTable") &&
              Object.keys(field.globalMeasurementTable).length > 0
            ) {
              const currentCategoryMeasurementTable =
                dataMeasurementTables.find((category) => {
                  return (
                    category._id === field.globalMeasurementTable?.categoryId
                  );
                });
              const selectedMeasurementTable =
                currentCategoryMeasurementTable?.groupTables?.find((table) => {
                  return table._id === field.globalMeasurementTable.tableId;
                });

              const currentCategoryListTable = dataListTables?.find(
                (category) => {
                  return (
                    category._id ===
                    selectedMeasurementTable.associationGlobalListTable
                      ?.categoryId
                  );
                }
              );
              const currentListTable =
                currentCategoryListTable?.groupTables.find((table) => {
                  return (
                    table._id ===
                    selectedMeasurementTable.associationGlobalListTable?.tableId
                  );
                });

              if (
                field?.globalMeasurementTable?.optionsSelectFromListTable
                  ?.associationListCategoryId ===
                currentCategoryListTable?._id &&
                field?.globalMeasurementTable?.optionsSelectFromListTable
                  ?.associationListTableId === currentListTable?._id &&
                field?.globalMeasurementTable?.categoryId ===
                currentCategoryMeasurementTable?._id &&
                field?.globalMeasurementTable?.tableId ===
                selectedMeasurementTable?._id
              ) {
                const masterKey = selectedMeasurementTable?.dataColumns?.find(
                  (elm) => elm.masterKey
                );

                const selectedOption = dataObject?.attributes?.find((elm) => {
                  return elm.name === field?.name;
                })?.value;

                const optionsToCreate = [
                  ...new Set(
                    selectedMeasurementTable?.rows
                      ?.filter((row) => row.Association === "false")
                      ?.map((row) => {
                        return row[masterKey.name];
                      })
                  ),
                ];
                if (update && selectedOption) {
                  optionsToCreate.push(selectedOption);
                }

                let isSynchronized = false;
                if (update && selectedOption) {
                  const synchronizedData =
                    selectedMeasurementTable?.rows?.filter(
                      (row) =>
                        row[masterKey.name] === selectedOption &&
                        row.Synchronized === "true"
                    );
                  if (synchronizedData?.length > 0) {
                    isSynchronized = true;
                  }
                }

                return (
                  <div
                    key={index}
                    onChange={(e) => {
                      if (e.target.name === field.name) {
                        const value = e.target.value;
                        const currentCategoryMeasurementTable =
                          dataMeasurementTables.find((category) => {
                            return (
                              category._id ===
                              field.globalMeasurementTable?.categoryId
                            );
                          });
                        const selectedMeasurementTable =
                          currentCategoryMeasurementTable?.groupTables?.find(
                            (table) => {
                              return (
                                table._id ===
                                field.globalMeasurementTable.tableId
                              );
                            }
                          );

                        const currentCategoryListTable = dataListTables.find(
                          (category) => {
                            return (
                              category._id ===
                              selectedMeasurementTable
                                .associationGlobalListTable?.categoryId
                            );
                          }
                        );

                        const currentListTable =
                          currentCategoryListTable?.groupTables.find(
                            (table) => {
                              return (
                                table._id ===
                                selectedMeasurementTable
                                  .associationGlobalListTable?.tableId
                              );
                            }
                          );
                        const currentMasterKey =
                          selectedMeasurementTable?.dataColumns?.find(
                            (elm) => elm.masterKey
                          );

                        handlerChangeGlobalMeasurement({
                          e: {
                            target: {
                              name: e.target.name,
                              value: e.target.value,
                              rowListTableId: currentListTable.rows.find(
                                (item) => {
                                  return item[currentMasterKey.name] === value;
                                }
                              )["Id"],
                            },
                          },
                          categoryId: currentCategoryMeasurementTable._id,
                          tableId: selectedMeasurementTable?._id,
                          listTableId: currentListTable._id,
                          masterKey: currentMasterKey.name,
                          inputName: field.alias || field.name,
                          onChange: true,
                        });

                        handlerSetByGlobalListTable({
                          e: {
                            target: {
                              name: field.name,
                              value: currentListTable.rows.find((item) => {
                                return item[currentMasterKey.name] === value;
                              })["Id"],
                            },
                          },
                        });
                      }
                    }}
                  >
                    <SelectInput
                      key={index}
                      field={{
                        name: field.name,
                        options: optionsToCreate.map((elm) => {
                          return elm;
                        }),
                        required: field.required,
                        type: field.type,
                        alias: field.alias || field.name,
                      }}
                      register={register}
                      errors={errors}
                      warningGlobalOptions={optionsToCreate.length === 0}
                      disabledSelectGlobalMesurement={isSynchronized}
                    />
                  </div>
                );
              }
            }
            if (field.type === "select" && field.globalSelect) {
              const currentCategory = globalTableCategories?.find(
                (category) => {
                  return category._id === field.globalTable.categoryId;
                }
              );
              const selectTable = currentCategory?.groupTables.find((table) => {
                return table._id === field.globalTable.tableId;
              });

              return (
                <div
                  key={index}
                  onChange={() =>
                    setUseSelectAutocomplete(!useSelectAutocomplete)
                  }
                >
                  <SelectInput
                    key={index}
                    field={{
                      name: field.name,
                      options: selectTable.rows.map((elm) => elm.Options),
                      required: field.required,
                      type: field.type,
                      alias: field.alias || field.name,
                    }}
                    register={register}
                  />
                </div>
              );
            }
            if (field.type === "select") {
              return (
                <SelectInput key={index} field={field} register={register} />
              );
            }
            if (field.type === "currency") {
              return (
                <div key={index}>
                  <TextInput
                    field={field}
                    register={register}
                    errors={errors}
                  />
                  <SelectInput field={field} register={register} />
                </div>
              );
            }
            if (field.type === "range") {
              return (
                <div key={index}>
                  <TextInput
                    key={index}
                    field={{
                      name: field.name,
                      alias: field.alias,
                      required: field.required,
                      type: field.type,
                      step:
                        field.options.length !== 0
                          ? parseFloat(field.options[0])
                          : 1,
                      max: field.max,
                      min: field.min,
                    }}
                    value={watch(field.name)}
                    register={register}
                    errors={errors}
                  />
                </div>
              );
            }
            if (field.type === "delegate") {
              return (
                <div>
                  <SelectInput
                    key={index}
                    field={{
                      name: field.name,
                      options: allOperators?.map((operator) => {
                        return `${operator?.firstName} ${operator?.firstLastName}`;
                      }),
                      required: field.required,
                      type: field.type,
                      alias: field.alias || field.name,
                    }}
                    register={register}
                  />
                </div>
              );
            }
            if (field.type === "lineTag") {
              return (
                <LineConteiner2>
                  <hr />
                  <h3>{field.alias}</h3>
                  <hr />
                </LineConteiner2>
              );
            }

            if (field.type === "file") {
              return (
                <div>
                  <TextInput
                    field={{
                      ...field,
                      name: field.name,
                      required: update ? false : field.required,
                      type: field.type,
                      possible: field.possible,
                      alias: field.alias || field.name,
                      warning: field.globalSelect ? true : false,
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
              );
            }
            if (field.type === "captureMedia") return null;
            if (field.type === "notification") return null;
            if (field.type === "lineTag") return null;
            return (
              <TextInput
                key={index}
                field={{
                  ...field,
                  warning: field.globalSelect ? true : false,
                }}
                register={register}
                errors={errors}
              />
            );
          })}
        </div>
      )}
      <CustomButton
        type={"submit"}
        isLoad={loading}
        text={update ? "Update" : "Save"}
        margin={0}
      />
    </Form>
  );
}

export default Formulario;
