export const pathFolder = {
  objects: {
    points: {
      files: (fileFieldName) => `objects/points/files/web/${fileFieldName}`,
      pictures: (photoType) => `objects/points/pictures/web/${photoType}`,
    },
    lines: {
      files: (fileFieldName) => `objects/lines/files/web/${fileFieldName}`,
      pictures: (photoType) => `objects/lines/pictures/web/${photoType}`,
    },
  },
  events: {
    points: {
      files: (fileFieldName) => `events/points/files/web/${fileFieldName}`,
      pictures: (photoType) => `events/points/pictures/web/${photoType}`,
    },
  },
  components: {
    points: {
      files: (fileFieldName) => `components/points/files/web/${fileFieldName}`,
      pictures: (photoType) => `components/points/pictures/web/${photoType}`,
    },
  },
  icons: "icons",
  dashboard: {
    imageTextChart: "dashboard/imageTextChart",
  },
  standarsCatalogs: (fileFieldName) =>
    `standarsCatalogs/files/web/${fileFieldName}`,
  multinameAsset: (fileFieldName) =>
    `multinameAsset/files/web/${fileFieldName}`,
  measurementData: (fileFieldName) =>
    `measurementData/files/web/${fileFieldName}`,
};
