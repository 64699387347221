import {
  CardContainer,
  CardBar,
  ResizeRight,
  ResizeBottom,
} from "./indexStyle";
import CloseIcon from "@mui/icons-material/Close";
import { GREY_TRANS } from "utils/const";
// Store
import useStoreDashboard from "pages/Dashboard/Store";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Line } from "react-chartjs-2";
import { updateOrderCard } from "services/Dashboard/orderCards";
import { useSWRConfig } from "swr";
import { config } from "config";
import handleAddGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridColumn";
import handleRemoveGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridColumn";
import handleRemoveGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridRow";
import handleAddGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridRow";
import { createRgbaColor } from "pages/Dashboard/helpers/createRgbaColor";
import { useSetCurrentStyle } from "./Hooks/useSetCurrentStyle";
import { fixedDecimalNumber } from "pages/Dashboard/helpers/fixedDecimalNumber";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function TimeSeriesChart({
  dataChart,
  currentStyle,
  template,
  setCurrentOrderViews,
  index,
  setCurrentTemplate,
  responsiveWidth = null,
  responsiveHeight = null,
  currentPositionId,
  restore = false,
  orderId,
  currentOrderName,
  currentOrderViews,
  setOpenDialogRestoreChart,
  handlerDeleteChartOnView,
  setDeleteChartDialog,
}) {
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const role = localStorage.getItem("role");
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;
  const { mutate } = useSWRConfig();
  const { setDragAndDroll } = useStoreDashboard();
  const chartsIconsShowOnOff = useStoreDashboard(
    (state) => state.chartsIconsShowOnOff
  );

  const handleRestoreChart = async () => {
    await updateOrderCard({
      id: orderId,
      body: {
        name: currentOrderName,
        position: [
          ...currentOrderViews,
          {
            id: dataChart._id,
            sizeChart: template.sizeChart,
          },
        ],
        _id: currentPositionId,
      },
      type: dataChart.from,
    });
    setCurrentOrderViews((current) => [
      ...current,
      {
        id: dataChart._id,
        sizeChart: template.sizeChart,
      },
    ]);
    mutate(urlOrders);
    setOpenDialogRestoreChart(false);
  };

  const { currentStyleLineDataChart } = useSetCurrentStyle({ dataChart });

  const bgColor = {
    id: "bgColor",
    beforeDraw: (chart, options) => {
      const { ctx, width, height } = chart;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  return (
    <CardContainer
      width={
        template?.index
          ? currentStyle.width
          : responsiveWidth
          ? `${currentStyle.width - responsiveWidth}px`
          : `${currentStyle.width}px`
      }
      height={
        template?.index
          ? currentStyle.height
          : responsiveHeight
          ? `${currentStyle.height - responsiveHeight}px`
          : `${currentStyle.height}px`
      }
    >
      {role === "Administrador de compañía" &&
        !restore &&
        chartsIconsShowOnOff && (
          <button
            className="delete-card-button"
            onClick={() =>
              currentOrderName === "Design New View"
                ? setDeleteChartDialog(true)
                : handlerDeleteChartOnView()
            }
          >
            <span>
              <CloseIcon
                sx={{
                  color: GREY_TRANS,
                }}
              />
            </span>
          </button>
        )}

      {role === "Administrador de compañía" &&
        restore &&
        chartsIconsShowOnOff && (
          <button
            className="restore-card-button"
            onClick={() => handleRestoreChart()}
          >
            <RestartAltIcon
              sx={{
                color: GREY_TRANS,
              }}
            />
          </button>
        )}

      {template && (
        <>
          <ResizeRight>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleAddGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleRemoveGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeRight>
          <ResizeBottom>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleRemoveGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleAddGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeBottom>
        </>
      )}

      <CardBar position={template ? "absolute" : "relative"}>
        <Line
          className="chart-dashboard-element-array"
          data={currentStyleLineDataChart.data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            layout: {
              padding: {
                left: 10,
                right: 20,
                top: 10,
                bottom: 10,
              },
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset?.datalabels?.displayTotal;
                },
                align: function (context) {
                  return context.dataset?.datalabels?.positionTotal;
                },
                anchor: function (context) {
                  return context.dataset?.datalabels?.positionTotal;
                },
                formatter: (value, context) => {
                  const dataset = context.dataset;
                  const dataIndex = context.dataIndex;
                  const totalDataPoints = context.dataset.data.length;
                  if (
                    !currentStyleLineDataChart.currentDisplayShowCerosTotal &&
                    value === 0
                  ) {
                    return null;
                  }
                  if (
                    dataset.hasOwnProperty("constantValue") &&
                    dataset.constantValue !== null
                  ) {
                    if (dataIndex === totalDataPoints - 1) {
                      return dataset.constantValue === 0
                        ? null
                        : dataset.constantValue;
                    } else {
                      return null;
                    }
                  }
                  return fixedDecimalNumber({
                    number: value,
                    numDecimals: dataset.datalabels?.numDecimals,
                  });
                },
                color: function (context) {
                  return context.dataset?.datalabels?.colorTotal;
                },
                backgroundColor: function (context) {
                  return context.dataset?.datalabels?.backgroundColorTotal;
                },
                borderWidth: 1,
                borderColor: function (context) {
                  return context.dataset?.datalabels?.backgroundColorTotal;
                },
                borderRadius: 3,
                font: function (context) {
                  return {
                    size: context.dataset?.datalabels?.fontSizeTotal,
                  };
                },
                offset: function (context) {
                  return context.dataset?.datalabels?.offsetTotal;
                },
              },
              title: {
                display: true,
                color: dataChart.chartStyle.colorTitle,
                text: dataChart.alias,
                font: {
                  size: dataChart.chartStyle.fontSizeTitle,
                  family: "Montserrat",
                },
              },
              padding: {
                top: 20,
              },
              legend: {
                display: true,
                onClick: function (e, legendItem, legend) {
                  const index = legendItem.datasetIndex;
                  const ci = legend.chart;
                  const meta = ci.getDatasetMeta(index);

                  // Toggle the visibility
                  meta.hidden =
                    meta.hidden === null
                      ? !ci.data.datasets[index].hidden
                      : null;

                  // We hid a dataset ... rerender the chart
                  ci.update();
                },
                labels: {
                  generateLabels: function (chart) {
                    return currentStyleLineDataChart.data.datasets.map(
                      (dataset, i) => {
                        const meta = chart.getDatasetMeta(i);
                        return {
                          text: dataset.alias,
                          fillStyle: dataset.borderColor,
                          hidden: meta.hidden,
                          lineCap: dataset.borderCapStyle,
                          lineDash: dataset.borderDash,
                          lineDashOffset: dataset.borderDashOffset,
                          lineJoin: dataset.borderJoinStyle,
                          lineWidth: dataset.borderWidth,
                          strokeStyle: dataset.borderColor,
                          pointStyle: dataset.pointStyle,
                          datasetIndex: i,
                          fontStyle: meta.hidden ? "italic" : "normal",
                          fontColor: meta.hidden ? "#999" : "#666",
                        };
                      }
                    );
                  },
                },
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: function (context) {
                    return `${context.dataset.alias} : ${context.raw}`;
                  },
                },
                titleFont: {
                  size: dataChart.chartStyle.tooltip.fontSizeTitle,
                },
                bodyFont: {
                  size: dataChart.chartStyle.tooltip.fontSizeBody,
                },
                mode: "nearest",
                intersect: true,
                titleAlign: "center",
                displayColors: false,
              },
            },
            animation: {
              duration: 1500,
              easing: "easeInOutQuart",
            },
            scales: {
              x: {
                grid: {
                  display: dataChart.chartStyle.scales.x.displayAxis,
                  color: createRgbaColor({
                    hexadecimalColor: dataChart.chartStyle.scales.x.axisColor,
                  }),
                },
                ticks: {
                  callback: function (value) {
                    let label = this.getLabelForValue(value);
                    return label.trim().split(" ");
                  },
                  maxRotation: 0,
                  minRotation: 0,
                  font: {
                    size: dataChart.chartStyle.scales.x.labelsFontSize,
                  },
                  color: dataChart.chartStyle.scales.x.labelsColor,
                  textStrokeWidth: 6,
                  padding: dataChart.chartStyle.scales.x.paddingAxis,
                },
                title: {
                  display: dataChart.chartStyle.scales.x.titleAxis.displayTitle,
                  text: dataChart.chartStyle.scales.x.titleAxis.text
                    ? dataChart.chartStyle.scales.x.titleAxis.text
                    : dataChart.columnX.name,
                  font: {
                    size: dataChart.chartStyle.scales.x.titleAxis.fontSizeTitle,
                  },
                  color: dataChart.chartStyle.scales.x.titleAxis.colorTitle,
                  padding: {
                    top: -dataChart.chartStyle.scales.x.paddingAxis,
                  },
                },
              },
              y: {
                beginAtZero: dataChart.chartStyle.scales.y.beginAtZero,
                min: dataChart.chartStyle.scales.y.minValueAxis,
                max: dataChart.chartStyle.scales.y.maxValueAxis,
                grid: {
                  display: dataChart.chartStyle.scales.y.displayAxis,
                  color: createRgbaColor({
                    hexadecimalColor: dataChart.chartStyle.scales.y.axisColor,
                  }),
                },
                ticks: {
                  callback: function (value) {
                    let label = this.getLabelForValue(value);
                    return label.trim().split(" ");
                  },
                  maxRotation: 0,
                  minRotation: 0,
                  stepSize: dataChart.chartStyle.scales.y.stepSizeY,
                  font: {
                    size: dataChart.chartStyle.scales.y.labelsFontSize,
                  },
                  color: dataChart.chartStyle.scales.y.labelsColor,
                  padding: dataChart.chartStyle.scales.y.paddingAxis,
                },
                title: {
                  display: dataChart.chartStyle.scales.y.titleAxis.displayTitle,
                  text: dataChart.chartStyle.scales.y.titleAxis.text
                    ? dataChart.chartStyle.scales.y.titleAxis.text
                    : dataChart.type === "count"
                    ? "Count"
                    : dataChart.columnsY[0],
                  font: {
                    size: dataChart.chartStyle.scales.y.titleAxis.fontSizeTitle,
                  },
                  color: dataChart.chartStyle.scales.y.titleAxis.colorTitle,
                  padding: {
                    bottom: -dataChart.chartStyle.scales.y.paddingAxis,
                  },
                },
              },
            },
          }}
          plugins={[bgColor]}
        />
      </CardBar>
    </CardContainer>
  );
}
