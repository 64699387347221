import axios from "axios";
import { config } from "config.js";

const token = localStorage.getItem("token");

export const deleteLinesByIds = async (data) => {
  // ids = [23, 345, 456]
  // relations = true (only objects relations or lines)
  const { ids, relations } = data;
  const url = `${config.URL_BACKEND_PG}api/v1/lines/bulk-delete`;
  const response = await axios.delete(url, {
    data: {
      ids: ids,
      relations,
    },
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  return response.data;
};
