import { useGetLineLibraries, useGetLineTypes } from "./useGet";
import { config } from "config.js";
import useSWR from "swr";

function GetLinesArrays({ adminCompanyId, libraryId, lineId }) {
  const { data: linesLibrariesArray, error: errorLineLibraries } =
    useGetLineLibraries({ adminCompanyId });

  const { data: linesTypeArray, error: errorLineType } =
    useGetLineTypes(libraryId);

  const urlParams = new URLSearchParams();
  if (adminCompanyId) urlParams.append("adminCompanyId", adminCompanyId);
  if (lineId) urlParams.append("id", lineId);
  const url = `${config.URL_BACKEND_PG}api/v1/lines?${urlParams.toString()}`;
  const { data: linesArray, error: errorLines } = useSWR(url);

  return {
    linesArray,
    errorLines,
    linesLibrariesArray,
    errorLineLibraries,
    linesTypeArray,
    errorLineType,
  };
}

export default GetLinesArrays;
