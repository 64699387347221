import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  createBackendPreferences,
  updateBackendPreferences,
} from "services/updateBackendPreferences";
import useSwr, { useSWRConfig } from "swr";
import {
  SECOND_COLOR_TRANS,
  COLOR_TEXT_RD,
  PRIMARY_COLOR_RD,
  COLOR_TEXT_GD,
} from "utils/const";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import { useColorHostName } from "hooks/colorHostName";
import { urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

const ThemeContext = createContext();

const getColorName = (color) => {
  const colorNames = {
    [PRIMARY_COLOR]: "Green Dragon",
    "#f7a21b": "Orange Dragon",
    "#d43b3b": "Red Dragon",
    "#575757": "White Dragon",
    [PRIMARY_COLOR_RD]: "Red Dragon",
  };
  return colorNames[color] || "Decimetrix";
};

export const useTheme = () => useContext(ThemeContext);

export const ThemeData = ({ children }) => {
  const [primaryColor, setPrimaryColor] = useState(PRIMARY_COLOR);
  const [secondaryColor, setSecondaryColor] = useState(SECOND_COLOR);
  const [transColor, setTransColor] = useState(SECOND_COLOR_TRANS);
  const [colorName, setColorName] = useState(getColorName(primaryColor));
  const { instanceId, userId } = useUserStore((state) => state);

  const { isRedDragon, color } = useColorHostName();
  const { mutate } = useSWRConfig();

  const url = urlsApiMg.configPreferences; //get with instance id
  const { data: configData, error: errorConfigData } = useSwr(url);

  const updateTheme = useCallback(
    (theme, secondaryTheme, transTheme, titlesColor) => {
      document.body.style.setProperty("--background-primary_color", theme);
      document.body.style.setProperty(
        "--background-secondary_color",
        secondaryTheme
      );
      document.body.style.setProperty("--background-trans_color", transTheme);
      document.body.style.setProperty("--color-titles", titlesColor);

      setPrimaryColor(theme);
      setSecondaryColor(secondaryTheme);
      setTransColor(transTheme);
      setColorName(getColorName(theme));
    },
    []
  );

  useEffect(() => {
    const initializeTheme = async () => {
      if (!instanceId || !userId) {
        const defaultTheme = isRedDragon
          ? color
          : {
            primaryColor: PRIMARY_COLOR,
            secondaryColor: SECOND_COLOR,
            transColor: SECOND_COLOR_TRANS,
            titlesColor: COLOR_TEXT_RD,
          };
        updateTheme(
          defaultTheme.primaryColor,
          defaultTheme.secondaryColor,
          defaultTheme.transColor,
          defaultTheme.titlesColor
        );
        return;
      }

      if (!errorConfigData && configData?.length > 0) {
        const { theme, secondaryTheme, transTheme, titlesColor } =
          configData[0].theme;
        updateTheme(theme, secondaryTheme, transTheme, titlesColor);
      } else {
        const { data } = await createBackendPreferences({
          body: {
            instanceId,
            userId,
            theme: {
              theme: PRIMARY_COLOR,
              secondaryTheme: SECOND_COLOR,
              transTheme: SECOND_COLOR_TRANS,
              titlesColor: isRedDragon ? COLOR_TEXT_RD : COLOR_TEXT_GD,
            },
          },
        });

        if (data.theme) {
          updateTheme(
            data.theme.theme,
            data.theme.secondaryTheme,
            data.theme.transTheme,
            data.theme.titlesColor
          );
        }
      }
    };

    initializeTheme();
  }, [
    instanceId,
    userId,
    configData,
    errorConfigData,
    isRedDragon,
    color,
    updateTheme,
  ]);

  const handleThemeColor = async (primary, secondary, trans, titlesColor) => {
    try {
      updateTheme(primary, secondary, trans, COLOR_TEXT_RD);

      await updateBackendPreferences({
        id: configData[0]._id,
        body: {
          ...configData[0],
          theme: {
            theme: primary,
            secondaryTheme: secondary,
            transTheme: trans,
            titlesColor: titlesColor,
          },
        },
      });
      mutate(url);
    } catch (error) {
      console.error("Failed to update theme color:", error);
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        handleThemeColor,
        primaryColor,
        secondaryColor,
        transColor,
        colorName,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
