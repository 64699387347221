import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import TextInput from "../GenericInput";
import { useForm } from "react-hook-form";
import CustomButton from "components/Buttons/CustomButton";
import CircularIndeterminate from "components/Lodings/LoadingV2";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ContainerValue,
  ContainerSelect,
  GalleryContainerNoThumbnails,
  ImageGalleryStyleWrapper,
  LoadingContainer,
  ContainerSelectedLibrary,
  ContainerConfigGlobalValue,
  ContainerTable,
} from "./ThermalAnalysisStyles";
import { ommitBucketUrl, urls } from "utils/urlKeys";
import { getUrlSigned } from "services/s3Manager/s3Manager";
import { createPointEventNoLocation } from "services/saveEventNoLocation";
import useSWR from "swr";
import { config } from "config.js";
import { useFetchEventLibraries } from "hooks/fetchLibraries";
import savePointEventApiPg from "services/savePointEvent";
import { saveCaptureMedia } from "services/uploadFilePointEvent";
import { useSWRConfig } from "swr";
import createThermalAnalysis from "services/s3Manager/createThermalAnalysis";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import { FROM_TABLE } from "utils/constStrings";
import { nameUrl } from "helpers/isUrl";

const columns = [
  "Name",
  "Threshold Value",
  "Min Value",
  "Max Value",
  "Delta Value",
  "Hot Spots",
  "Severity Value",
  "Picture",
];
const idColumns = [
  "name",
  "thresholdValue",
  "minValue",
  "maxValue",
  "deltaValue",
  "hotSpots",
  "severity",
  "picture",
];

export const ThermalAnalysis = ({
  thermalPictures = [],
  objectId,
  setOpenDialog,
}) => {
  const { mutate } = useSWRConfig();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [thermalImagesAnalyzed, setThermalImagesAnalyzed] = useState([]);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [libraryEventSelected, setLibraryEventSelected] = useState(null);
  const [currentTypeEvent, setCurrentTypeEvent] = useState(null);
  const [libraryEvents, setLibraryEvents] = useState([]);
  const [dataTypeEvents, setDataTypeEvents] = useState([]);
  const [rows, setRows] = useState([]);
  const [bodyEvent, setBodyEvent] = useState([]);

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlTypeEvents =
    libraryEventSelected &&
    `${config.URL_BACKEND_PG}api/v1/point-type-events?libraryId=${libraryEventSelected}&adminCompanyId=${adminCompanyId}`;

  const { data: typeEvents, error: typeEventsError } = useSWR(urlTypeEvents);

  const { data: dataEventLibrary, error: errorEventLibrary } =
    useFetchEventLibraries({
      id: null,
    });

  useEffect(() => {
    if (!errorEventLibrary && dataEventLibrary && dataEventLibrary.length > 0) {
      setLibraryEvents(dataEventLibrary);
      setLibraryEventSelected(parseFloat(dataEventLibrary[0].id));
    }
  }, [dataEventLibrary, errorEventLibrary]);

  useEffect(() => {
    if (!typeEventsError && typeEvents && typeEvents.length > 0) {
      setDataTypeEvents(typeEvents);
      setCurrentTypeEvent(typeEvents[0].id);
    }
  }, [typeEvents, libraryEventSelected, typeEventsError]);

  useEffect(() => {
    const processUrls = async () => {
      try {
        const processedItems = await Promise.all(
          thermalPictures.map(async (url, index) => {
            const shouldOmitSigning = ommitBucketUrl.some((prefix) =>
              url.startsWith(prefix)
            );
            const signedUrl = shouldOmitSigning
              ? url
              : await getUrlSigned({ s3Url: encodeURIComponent(url) });

            return {
              url: url,
              original: signedUrl,
              thumbnail: signedUrl,
              originalAlt: `Image ${index + 1}`,
              thumbnailAlt: `Thumbnail ${index + 1}`,
            };
          })
        );

        setItems(processedItems);
        setIsLoading(false);
      } catch (error) {
        console.error("Error processing images:", error);
        setIsLoading(false);
      }
    };

    processUrls();
  }, [thermalPictures]);

  const handelSubmitAnalyzePictures = async (data) => {
    setIsLoading(true);
    const { data: response } = await createThermalAnalysis({
      thresholdValue: data.thresholdValue,
      thermalPictures: items.map((item) => item.url),
    });
    if (response.length > 0) {
      const processedItems = await Promise.all(
        response.map(async (elm, index) => {
          const shouldOmitSigning = ommitBucketUrl.some((prefix) =>
            elm.url.startsWith(prefix)
          );
          const signedUrl = shouldOmitSigning
            ? elm.url
            : await getUrlSigned({ s3Url: encodeURIComponent(elm.url) });

          setRows((current) => [
            ...current,
            {
              ...elm,
              picture: elm.url,
              hotSpots: JSON.stringify(elm.hotSpots),
            },
          ]);
          setBodyEvent((current) => [
            ...current,
            {
              name: `Picture ${nameUrl(elm.url)}`,
              type: "lineTag",
              alias: `Picture ${nameUrl(elm.url)}`,
              value: "lineTag",
            },
            {
              name: "thresholdValue",
              value: elm.thresholdValue.toString(),
              alias: "Thres Hold Value",
            },
            {
              name: "minTemperature",
              value: elm.minValue.toString(),
              alias: "MIn Temperature",
            },
            {
              name: "maxTemperature",
              value: elm.maxValue.toString(),
              alias: "Max Temperature",
            },
            {
              name: "deltaValue",
              value: elm.deltaValue.toString(),
              alias: "Delta Value",
            },
            {
              name: "severity",
              value: elm.severity.toString(),
              alias: "Severity",
            },
          ]);
          return {
            url: elm.url,
            original: signedUrl,
            thumbnail: signedUrl,
            originalAlt: `Image ${index + 1}`,
            thumbnailAlt: `Thumbnail ${index + 1}`,
          };
        })
      );
      setThermalImagesAnalyzed(processedItems);
      setIsLoading(false);
    }
  };

  const handelSelectEeventLibrary = (e) => {
    e.preventDefault();
    setLibraryEventSelected(parseFloat(e.target.value));
  };

  const handelSelectTypeEevent = (e) => {
    e.preventDefault();
    setCurrentTypeEvent(parseFloat(e.target.value));
  };

  const handelSubmitCreateEvent = async () => {
    try {
      setIsLoading(true);
      // Crear el evento sin ubicación
      const dataNoLocation = createPointEventNoLocation(
        bodyEvent,
        objectId,
        null,
        currentTypeEvent
      );

      const { data: event } = await savePointEventApiPg(dataNoLocation);

      if (event && event.mongoId) {
        // Guardar las imágenes y actualizar la caché en paralelo
        await Promise.all([
          saveCaptureMedia({
            pointEventMgId: event.mongoId,
            media: thermalImagesAnalyzed.map((item) => ({
              type: "generalPhoto",
              alias: "General",
              url: item.url,
            })),
          }),
          mutate(
            `${config.URL_BACKEND_PG}api/v1/point-events?objectId=${objectId}`
          ),
        ]);
      }
      setOpenDialog(false);
    } catch (error) {
      console.error("Error al crear el evento:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularIndeterminate />
      </LoadingContainer>
    );
  }

  if (thermalPictures.length === 0) {
    return (
      <div>
        <h1>There is no thermal information for this object</h1>
      </div>
    );
  }
  return (
    <>
      {thermalImagesAnalyzed.length > 0 ? (
        <>
          <ContainerConfigGlobalValue>
            <GalleryContainerNoThumbnails>
              <ImageGalleryStyleWrapper>
                <ImageGallery
                  items={thermalImagesAnalyzed}
                  showBullets={thermalImagesAnalyzed.length > 1}
                  showPlayButton={false}
                  showThumbnails={true}
                  thumbnailPosition={"bottom"}
                  showIndex={thermalImagesAnalyzed.length > 1}
                />
              </ImageGalleryStyleWrapper>
            </GalleryContainerNoThumbnails>
            <br />
            <ContainerTable>
              <GenericTable
                headers={columns}
                rows={rows}
                keyValues={idColumns}
                handlerClick={() => {}}
                state={true}
                customTableWidth={"100%"}
                customHeaderWidth={"5%"}
                from={FROM_TABLE.details}
                nonLocation={false}
              />
            </ContainerTable>
            <br />
            <ContainerSelect>
              {libraryEvents && libraryEvents.length > 0 ? (
                <>
                  <label className="label">Library Events</label>
                  <select
                    className="select"
                    value={libraryEventSelected}
                    onChange={handelSelectEeventLibrary}
                  >
                    {libraryEvents.map((option, index) => {
                      return (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                </>
              ) : (
                <ContainerValue>
                  <label className="label">
                    First Create A New Library Event
                  </label>
                </ContainerValue>
              )}
              {dataTypeEvents && dataTypeEvents.length > 0 ? (
                <>
                  <label className="label">Select type event</label>
                  <select
                    className="select"
                    value={currentTypeEvent}
                    onChange={handelSelectTypeEevent}
                  >
                    {dataTypeEvents.map((option, index) => {
                      return (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                </>
              ) : (
                <ContainerValue>
                  <label className="label">First Create A New Type Event</label>
                </ContainerValue>
              )}
            </ContainerSelect>
            <ContainerSelect>
              <InfoEvent
                title={"Library Selected"}
                dataEvent={libraryEvents}
                eventSelected={libraryEventSelected}
                from={"library"}
              />
              <InfoEvent
                title={"Type Event Selected"}
                dataEvent={dataTypeEvents}
                eventSelected={currentTypeEvent}
                from={"type"}
              />
            </ContainerSelect>
            <CustomButton
              type={"submit"}
              onClick={() => handelSubmitCreateEvent()}
              isLoad={isLoading}
              text={"Create Event"}
              margin={0}
            />
          </ContainerConfigGlobalValue>
        </>
      ) : (
        <div>
          <GalleryContainerNoThumbnails>
            <ImageGalleryStyleWrapper>
              <ImageGallery
                items={items}
                showBullets={thermalPictures.length > 1}
                showPlayButton={false}
                showThumbnails={true}
                thumbnailPosition={"bottom"}
                showIndex={thermalPictures.length > 1}
              />
            </ImageGalleryStyleWrapper>
          </GalleryContainerNoThumbnails>
          <form onSubmit={handleSubmit(handelSubmitAnalyzePictures)}>
            <TextInput
              field={{
                type: "number",
                name: "thresholdValue",
                alias: "Threshold Value",
                required: true,
              }}
              register={register}
              errors={errors}
            />
            <CustomButton
              type={"submit"}
              isLoad={isLoading}
              text={"Analyzing thermal data"}
              margin={0}
            />
          </form>
        </div>
      )}
    </>
  );
};

const InfoEvent = ({ dataEvent = [], eventSelected, title, from }) => {
  const selectedEvent = dataEvent?.find((elm) => elm.id === eventSelected);

  return (
    <ContainerSelectedLibrary>
      <h3>{title}</h3>
      <div className="card">
        <div className="library-name ">
          {selectedEvent?.name ? (
            selectedEvent?.name
          ) : (
            <label className="label">
              First Create
              <Link
                to={
                  from === "library"
                    ? "/library-configurations"
                    : "/config-actions-events"
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  marginLeft: "5px",
                }}
              >
                {from === "library" ? "Event Libraries" : "Events Types"}
              </Link>
            </label>
          )}
        </div>
        <div className="icon-container">
          <img src={selectedEvent?.icon} alt={selectedEvent?.name} />
        </div>
      </div>
    </ContainerSelectedLibrary>
  );
};
