import axios from "axios";
import { urls } from "utils/urlKeys";

/**
 * Finds users by admin company ID.
 *
 * @param {Object} param - The parameter object.
 * @return {Object} - The data of the found users.
 */
export const findUsersByInstanceId = async () => {
  const token = localStorage.getItem("token");
  const result = await axios.get(urls.instance.getSimpleInfoUsers, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};
