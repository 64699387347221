import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import {
  FormContainer,
  ImagePreviewContainer,
  VisuallyHiddenInput,
} from "./InstanceFormStyles";

export const NewInstanceForm = ({ formData, setFormData, errorMessage }) => {
  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        image: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <FormContainer>
      <TextField
        fullWidth
        label="Instance Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        variant="outlined"
        sx={{
          "& .MuiInputBase-root": { color: PRIMARY_COLOR },
          "& .MuiInputLabel-root": { color: PRIMARY_COLOR },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: PRIMARY_COLOR,
          },
        }}
      />

      <TextField
        fullWidth
        // multiline
        rows={3}
        label="Description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        variant="outlined"
        sx={{
          "& .MuiInputBase-root": { color: PRIMARY_COLOR },
          "& .MuiInputLabel-root": { color: PRIMARY_COLOR },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: PRIMARY_COLOR,
          },
        }}
      />

      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        sx={{
          backgroundColor: "var(--background-primary_color)",
          "&:hover": {
            backgroundColor: SECOND_COLOR,
          },
        }}
      >
        Upload Image
        <VisuallyHiddenInput
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
        />
      </Button>

      {imagePreview && (
        <ImagePreviewContainer>
          <img
            src={imagePreview}
            alt="Preview"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </ImagePreviewContainer>
      )}
      {errorMessage && (
        <div style={{ color: "red", marginTop: "10px" }}>
          {errorMessage.split("\n").map((line, index) => (
            <p key={index} style={{ margin: 0 }}>
              {line}
            </p>
          ))}
        </div>
      )}
    </FormContainer>
  );
};
