import useGet from "hooks/useFetch";
import { useEffect, useState } from "react";
import {
  DynamicColorsContainer,
  DynamicColorsFields,
  LibraryField,
} from "./DynamicLinesColorStyles";
import { updateBackendMapSettings } from "services/updateBackendMapSettings";
import { setMapSettings } from "redux/actions/";
import { useDispatch } from "react-redux";
import {
  getMeasurementUnitSymbol,
  getMeasurementUnits,
} from "utils/measurement";

export default function SelectDynamicLinesColor({ configData, setConfigData }) {
  const [fieldsByLibrary, setFieldsByLibrary] = useState([]);
  const [lineLibraries, setLineLibraries] = useState([]);
  const [typeLines, setTypeLines] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);

  const dispatch = useDispatch();

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = parseInt(localStorage.getItem("userId"));

  const urlQuery = new URLSearchParams();
  urlQuery.append("adminCompanyId", adminCompanyId);
  const [libraries, librariesError] = useGet(
    `api/v1/line-libraries?${urlQuery.toString()}`
  );

  const urlQueryLineTypes = new URLSearchParams();
  urlQueryLineTypes.append("adminCompanyId", adminCompanyId);
  const [typeLinesDB, typeLinesErrorDB] = useGet(
    `api/v1/type-lines/colors?${urlQueryLineTypes.toString()}`
  );

  useEffect(() => {
    if (
      configData !== undefined &&
      configData.dynamicColorsSelected !== undefined &&
      configData.dynamicColorsSelected.length > 0
    ) {
      setOptionsSelected(configData.dynamicColorsSelected);
    }
  }, [configData]);

  useEffect(() => {
    // set libraries for use
    if (libraries && !librariesError && libraries?.length >= 0) {
      setLineLibraries(libraries);
    }
  }, [libraries, librariesError]);

  useEffect(() => {
    const aux = [];
    // set fields by libraries
    lineLibraries.forEach((ln) => {
      aux.push(parseLibrary(ln, ["select", "number"]));
    });
    setFieldsByLibrary(aux);
  }, [lineLibraries]);

  useEffect(() => {
    if (typeLinesDB && !typeLinesErrorDB && typeLinesDB?.length >= 0) {
      setTypeLines(typeLinesDB.map((it) => parseTypes(it)).filter((it) => it));
    }
  }, [typeLinesDB, typeLinesErrorDB]);

  // updated selected fields on database (MG)
  useEffect(() => {
    const updated = {
      ...configData,
      dynamicColorsSelected: optionsSelected,
    };
    const updateDynamicColors = async () => {
      if (!optionsSelected.length) return;
      await updateBackendMapSettings({
        id: userId,
        body: { dynamicColorsSelected: optionsSelected },
      });
    };
    updateDynamicColors();
    setConfigData(updated);
    // set state for options selected
    dispatch(setMapSettings({ ...updated, fieldsByLibrary }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsSelected]);

  useEffect(() => {
    const updateDynamicColors = async () => {
      const { measurementUnit } = configData;
      await updateBackendMapSettings({
        id: userId,
        body: {
          measurementUnit,
        },
      });
    };
    updateDynamicColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData.measurementUnit]);

  const handleSelectOption = (libId, fdName) => {
    // selects only one option per library
    const options = optionsSelected
      .map((it) => (it[0] === libId ? [it[0], fdName] : it))
      .map((it) => JSON.stringify(it));
    setOptionsSelected([...new Set(options)].map((it) => JSON.parse(it)));
  };

  const checkChecked = (id, name) => {
    return optionsSelected.find((it) => it[0] === id && it[1] === name)
      ? true
      : false;
  };

  const handleChangeMeasurementUnit = (e) => {
    const value = e.target.value;
    if (!configData && !Object.keys(configData).length) return;
    const unitSymbol = getMeasurementUnitSymbol(value);
    setConfigData((current) => ({
      ...current,
      measurementUnit: unitSymbol,
    }));
    dispatch(
      setMapSettings({
        ...configData,
        measurementUnit: unitSymbol,
      })
    );
  };

  return (
    <DynamicColorsContainer>
      {/* measurements units */}
      <section>
        <b>Measurement units:</b>
        <div className="body-item">
          <div className="select-input">
            <select
              className="input-field select"
              name=""
              onChange={handleChangeMeasurementUnit}
              value={configData?.measurementUnit?.unit || "kilometers"}
            >
              {getMeasurementUnits().map(({ symbol, unit }, index) => (
                <option key={index} value={unit}>
                  {unit} - {symbol}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>

      <section>
        <p>
          <b>Dynamic Lines Color by Category</b>
        </p>
      </section>
      <br />
      <DynamicColorsFields>
        {fieldsByLibrary?.length >= 0 &&
          fieldsByLibrary?.map((ln) => {
            return (
              <div>
                <div className="library-name-container">
                  <div
                    style={{
                      width: "50px",
                    }}
                  >
                    Library:
                  </div>{" "}
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    {ln.name}
                  </div>
                </div>
                <div>
                  <LibraryField>
                    <div className="library-names">
                      <input
                        type="checkbox"
                        value={ln.id}
                        checked={checkChecked(ln.id, "lineType")}
                        onChange={() => handleSelectOption(ln.id, "lineType")}
                      />
                      <p>Line Type</p>
                    </div>
                    <div
                      className="color-container"
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      {typeLines.length >= 0 &&
                        typeLines
                          .filter((tp) => tp[0] === ln.id)
                          .map((item) => (
                            <div className="color-item">
                              <input type="color" value={item[2]} disabled />
                              <p>{item[1]}</p>
                            </div>
                          ))}
                    </div>
                  </LibraryField>
                  {ln.fields.map((fd, indx) => {
                    // list of dynamic fields type select with circuit (only one)
                    return (
                      <ColorOptionElement
                        checkChecked={checkChecked}
                        handleSelectOption={handleSelectOption}
                        lib={ln}
                        field={fd}
                        optionIndex={indx}
                        key={indx}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
      </DynamicColorsFields>
    </DynamicColorsContainer>
  );
}

function ColorOptionElement({
  optionIndex,
  lib,
  field,
  checkChecked,
  handleSelectOption,
}) {
  return (
    <LibraryField>
      <div className="library-names">
        <input
          type="checkbox"
          value={field.optionsColors.at(optionIndex)}
          checked={checkChecked(lib.id, field.name)}
          onChange={() => handleSelectOption(lib.id, field.name)}
        />
        <p>{field.alias}</p>
      </div>
      <div
        className="color-container"
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        {field.type === "select" &&
          field.optionsColors.length !== 0 &&
          field.optionsColors.map((cl, indx) => (
            <div className="color-item">
              <input type="color" value={cl} disabled />
              <p>{field.options[indx]}</p>
            </div>
          ))}
        {field.type === "number" &&
          field.numberGroupingOptions.restrictions.length !== 0 &&
          field.numberGroupingOptions.restrictions.map((rt, indx) => {
            if (!rt.state) return <></>;
            return (
              <div className="color-item">
                <input type="color" value={rt.color} disabled />
                <p>{rt.label}</p>
                {rt.symbol === "><" || rt.symbol === ">=<=" ? (
                  <p>
                    {rt.valueMin} - {rt.valueMax}
                  </p>
                ) : (
                  <p>{rt.value}</p>
                )}
              </div>
            );
          })}
      </div>
    </LibraryField>
  );
}

function parseLibrary(item, types = []) {
  if (item === undefined || !types.length) return null;
  const fields =
    item.fields !== undefined
      ? item.fields.filter((fd) => types.includes(fd.type) && fd.circuit)
      : [];
  return {
    id: item.lineLibraryId || item.id,
    name: item.name,
    fields,
  };
}
/**
 * parses the next structure
 * {
  "id": 1,
  "type": "powerline",
  "color": "#e01b24",
  "adminCompanyId": 1,
  "lineLibraryId": 1
}
 * @param {*} item item from endpoint /type-lines/colors
 * @returns [1, "powerline", "#e01b24"]
 */
function parseTypes(item) {
  if (item === undefined) return null;
  return [item.lineLibraryId, item.type, item.color];
}
