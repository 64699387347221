import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Custom hooks
// Components
import DynamicFields from "./DynamicFields";
// Styles
import { FeatureDetails } from "./indexStyle";

import DetailTypeIformation from "components/ElementDetails/DetailTypeInformation.jsx";
import useGetLineLibraries from "./hooks/getLineLibraries.jsx";
import useGetLineTypes from "./hooks/getLineTypes.jsx";

const ParametrizedLines = () => {
  // Selectors
  //Get line
  const geometryLine = useSelector(
    (state) => state.adminReducer.setGeometryLine
  );

  // Get Line config
  /**
   * geometryLineConfig => {libraryId, lineTypeId}
   */
  const geometryLineConfig = useSelector(
    (state) => state.adminReducer.setGeometryLineConfig
  );

  // States
  const [libraryId, setLibraryId] = useState(geometryLineConfig.libraryId);

  const [genericData, setGenericData] = useState();
  const [linePreview, setLinePreview] = useState({
    color: "#000",
    width: 1,
    separator: 1,
  });
  const [libraryLineImageUrl, setLibraryLineImageUrl] = useState("");
  const [libraryAndTypeNames, setLibraryAndTypeNames] = useState({
    libraryName: "",
    typeName: "",
  });
  // Local storage
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  // Custom hooks
  const { data: lineLibraries, error: errorLineLibraries } =
    useGetLineLibraries({ id: null });

  const { data: lineTypes, error: errorLineType } = useGetLineTypes({
    id: libraryId,
  });

  // use effects for setting library id and line type id
  useEffect(() => {
    const setLibrary = (id) => {
      setLibraryId(id);
      setLibraryLineImageUrl(lineLibraries?.find((it) => it.id === id).icon);
    };
    lineLibraries &&
      !errorLineLibraries &&
      geometryLineConfig?.libraryId &&
      setLibrary(geometryLineConfig?.libraryId);
  }, [
    lineLibraries,
    errorLineLibraries,
    geometryLineConfig?.libraryId,
    geometryLineConfig,
  ]);

  useEffect(() => {
    const setType = (id) => {
      const currentLineType = lineTypes.find(
        (line) => line?.id === parseInt(id)
      );

      setLinePreview({
        color: currentLineType?.color,
        width: currentLineType?.dasharrayWidth,
        separator: currentLineType?.dasharrayPixels,
      });
    };
    lineTypes && !errorLineType && setType(geometryLineConfig?.lineTypeId);
  }, [lineTypes, errorLineType, geometryLineConfig?.lineTypeId]);

  //set the generic data for the form in the dynamicFields form
  useEffect(() => {
    const setLine = () => {
      const line = {
        userId: userId,
        name: "no name",
        description: "no description",
        type: "geojson",
        feature: [
          {
            id: geometryLine.id,
            type: geometryLine.type,
            properties: {
              ...geometryLine.properties,
              userId,
              lineTypeId: geometryLineConfig.lineTypeId,
              adminCompanyId,
            },
            geometry: { ...geometryLine.geometry },
          },
        ],
        adminCompanyId: adminCompanyId,
        libraryId: geometryLineConfig.libraryId,
        lineTypeId: geometryLineConfig.lineTypeId,
      };
      setGenericData({
        ...line,
      });
    };
    geometryLine?.geometry?.coordinates && setLine();
  }, [adminCompanyId, geometryLine, geometryLineConfig, userId]);

  useEffect(() => {
    if (lineLibraries && lineTypes) {
      if (lineLibraries.length && lineTypes.length) {
        const findLib = lineLibraries?.find(
          (it) => it.id === geometryLineConfig.libraryId
        );
        if (findLib)
          setLibraryAndTypeNames((curr) => ({
            ...curr,
            libraryName: findLib?.name,
          }));
        const findType = lineTypes?.find(
          (it) => it.id === geometryLineConfig.lineTypeId
        );
        if (findLib)
          setLibraryAndTypeNames((curr) => ({
            ...curr,
            typeName: findType?.type,
          }));
      }
    }
  }, [lineLibraries, lineTypes, geometryLineConfig]);

  return (
    <FeatureDetails>
      <DetailTypeIformation
        typeName={libraryAndTypeNames.typeName}
        typeLibrary={libraryAndTypeNames.libraryName}
        iconLibrary={libraryLineImageUrl}
        type={"Line"}
        dataLine={linePreview}
      />
      {lineLibraries?.length > 0 && lineTypes?.length > 0 && (
        <DynamicFields genericData={genericData} />
      )}
    </FeatureDetails>
  );
};

export default ParametrizedLines;
