const createDefaultColumns = (headers, keyValues, rows, fieldOrderMap) => {
  const allValues = [];
  rows?.forEach((elm) => {
    for (let key in elm) {
      if (keyValues.includes(key) === false) {
        allValues.push(key);
      }
    }
  });
  const uniqueArray = [...new Set(allValues)]; //const uniqueArray = [...new Set(allValues)].sort((a, b) => a.localeCompare(b));

  if (fieldOrderMap?.size > 0) {
    uniqueArray.sort((a, b) => {
      const orderA = fieldOrderMap.has(a) ? fieldOrderMap.get(a) : Infinity;
      const orderB = fieldOrderMap.has(b) ? fieldOrderMap.get(b) : Infinity;
      return orderA - orderB;
    });
  }

  const allHeaders = headers.concat(uniqueArray);
  const allKeyValues = keyValues.concat(uniqueArray);
  const columnsFormat = [
    {
      header: null,
      footer: (props) => props.column.id,
      id: ` `,
      columns: allHeaders.map((clum, index) => {
        return {
          accessorKey: `${allKeyValues[index]}`,
          cell: (info) => info.getValue(),
          header: () => <span>{clum}</span>,
          footer: (props) => props.column.id,
        };
      }),
    },
  ];
  return { columnsFormat, allKeyValues };
};

export default createDefaultColumns;
