/**
 * Formats a company name to a URL-friendly string.
 * - Converts to lowercase.
 * - Removes accents and special characters.
 * - Replaces "ñ" with "n".
 * - Replaces spaces and non-alphanumeric characters with "-".
 * - Ensures no multiple consecutive hyphens.
 * - Removes leading and trailing hyphens.
 *
 * @param {string} name - The company name to format.
 * @returns {string} - The formatted company name.
 */
export const formatCompanyName = (name) => {
  return name
    .normalize("NFD") // Decomposes accented characters into base character + diacritic
    .replace(/[\u0300-\u036f]/g, "") // Removes diacritic marks (accents)
    .replace(/ñ/g, "n") // Replaces "ñ" with "n"
    .replace(/[^a-zA-Z0-9]/g, "-") // Replaces non-alphanumeric characters with "-"
    .toLowerCase() // Converts to lowercase
    .replace(/-+/g, "-") // Replaces multiple hyphens with a single one
    .replace(/^-|-$/g, ""); // Removes leading and trailing hyphens
};
