import { useState } from "react";
import { Button } from "./../FormStyles";
import saveEventApiPg, { createEvent } from "services/saveEventApiPg";
import { useEffect } from "react";
import findReparation from "services/findReparation";
import updateReparation from "services/updateReparation";
import { useForm } from "react-hook-form";
import TextInput from "../GenericInput";
import { Form } from "../SaveObject/DynamicFieldsStyle";
import { reparationField } from "utils/events";

export default function Reparation({
  feature,
  objectId,
  closeModal,
  isUpdate,
  id,
  callbackUpdate,
  operationId,
}) {
  const [form, setForm] = useState({
    repair: false,
    comments: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      repair: false,
      comments: "",
    },
  });

  const onSubmit = (data) => {
    const { name, value, checked, type } = data.target;
    if (type === "checkbox") {
      setForm({
        ...form,
        [name === "Repair" ? "repair" : name]: checked,
      });
    } else {
      setForm({
        ...form,
        [name === "Comments" ? "comments" : name]: value,
      });
    }
  };

  const createBodyOfEvent = () => {
    const newForm = {};
    Object.keys(form).forEach((key) => {
      newForm[key] = form[key];
    });
    return newForm;
  };

  const handleMutate = () => {};

  const handleUpdate = async () => {
    const body = createBodyOfEvent();
    const { res } = await updateReparation(body, id);
    if (res?.status !== 200) return;
    callbackUpdate();
    handleMutate();
  };

  const handleSave = async () => {
    if (isUpdate) {
      handleUpdate();
      return;
    }
    const newForm = createBodyOfEvent();
    const event = createEvent(feature, newForm, objectId);
    await saveEventApiPg("reparation", event);
    closeModal(false);
    handleMutate();
  };

  useEffect(() => {
    const getData = async () => {
      const data = await findReparation(id);
      setValue("Repair", data.repair || "");
      setValue("Comments", data.comments || "");
      setForm((current) => ({
        ...current,
        comments: data.comments || "",
        repair: data.repair || false,
      }));
    };
    if (isUpdate) getData();
  }, [isUpdate, id]);

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      {reparationField.map((elm) => {
        return (
          <div onChange={(e) => onSubmit(e)}>
            <TextInput
              key={elm.key}
              field={elm}
              register={register}
              errors={errors}
            />
          </div>
        );
      })}
      <Button type="submit" style={{ padding: 0 }}>
        Save
      </Button>
      {isUpdate && <Button onClick={callbackUpdate}>Cancel</Button>}
    </Form>
  );
}
