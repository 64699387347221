import { useEffect, useState } from "react";
import useSWR from "swr";
import {
  ContainerConfigGlobalValue,
  ContainerSelectGlobalList,
  ContainerSelectedLibrary,
  ContainerValue,
  GlobalFieldValue,
} from "./SynchronizeObjectsStyle";
import { Link } from "react-router-dom";
import {
  useFetchEventLibraries,
  useFetchObjectLibraries,
} from "hooks/fetchLibraries";
import CustomButton from "components/Buttons/CustomButton";
import { useSelector } from "react-redux";
import { createPointEventNoLocation } from "services/saveEventNoLocation";
import savePointEventApiPg from "services/savePointEvent";
import updateSyncGlobalMeasurementTable from "services/GlobalMeasurementTable/updateSyncGlobalMeasurementTable";
import { VISIBILITY_OFF_ICON } from "utils/const";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";

import { urls, urlsApiMg } from "utils/urlKeys";
export const SynchronizeObjects = ({
  setOpenDialogSyncObjects,
  setOpenAlert,
}) => {
  const [dataGlobalMeasurementTables, setDataGlobalMeasurementTables] =
    useState([]);
  const [currentGlobalMeasurementTables, setCurrentGlobalMeasurementTables] =
    useState({});
  const [dataMeasurementCategories, setDataMeasurementCategories] = useState(
    []
  );
  const [currentMeasurementCategory, setCurrentMeasurementCategory] =
    useState(null);

  const [libraryEventsAssociate, setLibraryEventsAssociate] = useState([]);
  const [libraryEventSelected, setLibraryEventSelected] = useState(null);

  const [dataTypeEvents, setDataTypeEvents] = useState([]);
  const [currentTypeEvent, setCurrentTypeEvent] = useState(null);

  const [dataObjectLibraries, setDataObjectLibraries] = useState([]);

  const [loadingSyncObjects, setLoadingSyncObjects] = useState(false);

  const dataObjects = useSelector((state) => state.adminReducer.dataObjects);

  const { data: globalMeasurementTables, error: errorGlobalMeasurementTables } =
    useSWR(urlsApiMg.globalMeasurementTable);

  const { data: dataEventLibrary, error: errorEventLibrary } =
    useFetchEventLibraries({
      id: null,
    });

  const { data: dataObjectLibrary, error: errorObjectLibrary } =
    useFetchObjectLibraries({
      id: null,
    });

  const urlTypeEvents =
    libraryEventSelected &&
    urls.events.typeEventsParams(`libraryId=${libraryEventSelected}`);

  const { data: typeEvents, error: typeEventsError } = useSWR(urlTypeEvents);

  useEffect(() => {
    if (
      globalMeasurementTables &&
      !errorGlobalMeasurementTables &&
      globalMeasurementTables.length > 0
    ) {
      setDataMeasurementCategories(globalMeasurementTables[0]?.tableCategories);
      if (globalMeasurementTables[0]?.tableCategories.length > 0) {
        if (globalMeasurementTables[0]?.tableCategories.length > 0) {
          const existingTable =
            globalMeasurementTables[0]?.tableCategories.filter(
              (elm) => elm.groupTables.length > 0
            );
          if (existingTable.length > 0) {
            setDataGlobalMeasurementTables(existingTable[0]?.groupTables);
            setCurrentMeasurementCategory(existingTable[0]?._id);
            setCurrentGlobalMeasurementTables(
              existingTable[0]?.groupTables[0]._id
            );
          }
        }
      }
    }
  }, [globalMeasurementTables, errorGlobalMeasurementTables]);

  useEffect(() => {
    if (!errorEventLibrary && dataEventLibrary && dataEventLibrary.length > 0) {
      const matchAssociateLibraries = dataEventLibrary.filter((lib) => {
        return (
          lib?.globalMeasurementTable?.measurementCategoryId ===
            currentMeasurementCategory &&
          lib?.globalMeasurementTable?.measurementTableId ===
            currentGlobalMeasurementTables
        );
      });
      setLibraryEventsAssociate(matchAssociateLibraries);
      if (matchAssociateLibraries.length > 0) {
        setLibraryEventSelected(parseFloat(matchAssociateLibraries[0].id));
      }
    }
  }, [dataEventLibrary, errorEventLibrary, currentGlobalMeasurementTables]);

  useEffect(() => {
    if (
      !errorObjectLibrary &&
      dataObjectLibrary &&
      dataObjectLibrary.length > 0
    ) {
      setDataObjectLibraries(dataObjectLibrary);
    }
  }, [dataObjectLibrary, errorObjectLibrary]);

  useEffect(() => {
    if (!typeEventsError && typeEvents && typeEvents.length > 0) {
      setDataTypeEvents(typeEvents);
      setCurrentTypeEvent(typeEvents[0].id);
    } else {
      setDataTypeEvents([]);
      setCurrentTypeEvent(null);
    }
  }, [
    typeEvents,
    libraryEventSelected,
    typeEventsError,
    currentGlobalMeasurementTables,
  ]);

  const handleSelectListCategory = (e) => {
    const categorySelected = dataMeasurementCategories.find(
      (elm) => elm._id === e.target.value
    );
    setCurrentMeasurementCategory(e.target.value);
    setDataGlobalMeasurementTables(categorySelected?.groupTables);
    setCurrentGlobalMeasurementTables(categorySelected?.groupTables[0]?._id);
    const matchAssociateLibraries = dataEventLibrary.filter((lib) => {
      return (
        lib?.globalMeasurementTable?.measurementCategoryId === e.target.value &&
        lib?.globalMeasurementTable?.measurementTableId ===
          categorySelected?.groupTables[0]._id
      );
    });
    if (matchAssociateLibraries.length > 0) {
    }
  };

  const handelSelectGlobalTable = (e) => {
    e.preventDefault();
    setCurrentGlobalMeasurementTables(e.target.value);
    const currentSelectedTable = dataGlobalMeasurementTables.find(
      (elm) => elm._id === e.target.value
    );
    const matchAssociateLibraries = dataEventLibrary.filter((lib) => {
      return (
        lib?.globalMeasurementTable?.measurementCategoryId ===
          currentMeasurementCategory &&
        lib?.globalMeasurementTable?.measurementTableId ===
          currentSelectedTable._id
      );
    });
    if (matchAssociateLibraries.length > 0) {
    }
  };

  const handelSelectEeventLibrary = (e) => {
    e.preventDefault();
    setLibraryEventSelected(parseFloat(e.target.value));
  };

  const handelSelectTypeEevent = (e) => {
    e.preventDefault();
    setCurrentTypeEvent(parseFloat(e.target.value));
  };

  const handelSyncObjects = async () => {
    try {
      const matchAssociateLibraries = dataEventLibrary.find((lib) => {
        return lib?.id === libraryEventSelected;
      });

      const currentCategoryMeasurementTable = dataMeasurementCategories.find(
        (category) => {
          return (
            category._id ===
            matchAssociateLibraries.globalMeasurementTable.measurementCategoryId
          );
        }
      );

      const selectedMeasurementTable =
        currentCategoryMeasurementTable.groupTables.find((table) => {
          return (
            table._id ===
            matchAssociateLibraries.globalMeasurementTable.measurementTableId
          );
        });

      const masterKey = selectedMeasurementTable.dataColumns.find(
        (elm) => elm.masterKey
      );

      const filterLibraries = dataObjectLibraries.filter((library) => {
        return (
          library.fields &&
          library.fields.length > 0 &&
          library.fields.find((field) => {
            return (
              field.hasOwnProperty("globalMeasurementTable") &&
              Object.keys(field.globalMeasurementTable).length > 0
            );
          })
        );
      });

      const filterFieldsCreatedFromEventLibrary = filterLibraries
        .flatMap((elm) => elm.fields)
        .filter(
          (item) =>
            item.globalMeasurementTable &&
            Object.keys(item.globalMeasurementTable).length > 0 &&
            item.globalMeasurementTable.associatedLibrary ===
              matchAssociateLibraries?.id
        )
        .map((elm) => elm.name);

      const filterDataObject = dataObjects.filter((obj) => {
        return obj.attributes.some((elm) => {
          return filterFieldsCreatedFromEventLibrary.includes(elm.name);
        });
      });
      const processData = async () => {
        try {
          setLoadingSyncObjects(true);
          const promises = filterDataObject.flatMap((obj) =>
            selectedMeasurementTable.rows
              .map((elm, ind) => {
                const matches = obj.attributes.some(
                  (att) =>
                    filterFieldsCreatedFromEventLibrary.includes(att.name) &&
                    elm[masterKey.name] === att.value
                );

                if (matches) {
                  return { index: ind, row: elm };
                } else {
                  return { index: ind, row: null };
                }
              })
              .filter((item) => item.row !== null)
              .map(async (elm) => {
                if (
                  elm.row.Association === "true" &&
                  elm.row.Synchronized === "false"
                ) {
                  const attributesToCreate = [];

                  const {
                    [masterKey.name]: removed1,
                    Association: removed2,
                    ...restElm
                  } = elm.row;

                  matchAssociateLibraries.fields.forEach((lib) => {
                    if (restElm[lib.name] !== undefined) {
                      attributesToCreate.push({
                        name: lib.name,
                        value: `${restElm[lib.name]}`,
                        alias: lib.alias,
                      });
                    }
                  });

                  const event = createPointEventNoLocation(
                    attributesToCreate,
                    obj.id,
                    null,
                    currentTypeEvent
                  );
                  await savePointEventApiPg(event);
                  await updateSyncGlobalMeasurementTable({
                    id: globalMeasurementTables[0]._id,
                    categoryId:
                      matchAssociateLibraries.globalMeasurementTable
                        .measurementCategoryId,
                    tableId:
                      matchAssociateLibraries.globalMeasurementTable
                        .measurementTableId,
                    body: {
                      indexRow: parseInt(elm.index),
                    },
                  });
                  return;
                }
              })
          );

          await Promise.all(promises);
          setLoadingSyncObjects(false);
          setOpenAlert({
            open: true,
            severity: "success",
            message: "Successfully synchronized",
          });
          setOpenDialogSyncObjects(false);
        } catch (error) {
          setOpenAlert({
            open: true,
            severity: "error",
            message: "Unsuccessful synchronization",
          });
          console.error("Error processing the data:", error);
          throw error;
        }
      };
      await processData();
    } catch (error) {
      setOpenAlert({
        open: true,
        severity: "error",
        message: "Unsuccessful synchronization",
      });
      console.error("Error handelSyncObjects:", error);
      throw error;
    }
  };

  return (
    <>
      <GlobalFieldValue>
        <ContainerConfigGlobalValue>
          <ContainerSelectGlobalList>
            <h2>Table of Global Measurement</h2>
            {dataMeasurementCategories &&
            dataMeasurementCategories.length > 0 ? (
              <>
                <label className="label">Select Category</label>
                <select
                  className="select"
                  value={currentMeasurementCategory}
                  onChange={(e) => handleSelectListCategory(e)}
                >
                  {dataMeasurementCategories?.map((option, index) => {
                    return (
                      <option key={index} value={option._id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  First Create Category In Module
                  <Link
                    to="/config-global-measurement-table"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    Global Measurement Table
                  </Link>
                </label>
              </ContainerValue>
            )}
            {dataGlobalMeasurementTables &&
            dataGlobalMeasurementTables.length > 0 ? (
              <>
                <label className="label">Global Measurement Table</label>
                <select
                  id={currentGlobalMeasurementTables?._id}
                  className="select"
                  value={currentGlobalMeasurementTables || ""}
                  onChange={handelSelectGlobalTable}
                >
                  {dataGlobalMeasurementTables.map((option, index) => {
                    return (
                      <option key={index} value={option._id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  First Create Table In Module
                  <Link
                    to="/config-global-measurement-table"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    Global Measurement Table
                  </Link>
                </label>
              </ContainerValue>
            )}
            {libraryEventsAssociate && libraryEventsAssociate.length > 0 ? (
              <>
                <label className="label">
                  {`Library Events created from ${
                    dataGlobalMeasurementTables.find(
                      (elm) => elm._id === currentGlobalMeasurementTables
                    )?.name
                  }`}
                </label>
                <select
                  className="select"
                  value={libraryEventSelected}
                  onChange={handelSelectEeventLibrary}
                >
                  {libraryEventsAssociate.map((option, index) => {
                    return (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  First Create A New Library From Module Global Measurement
                  Table
                </label>
              </ContainerValue>
            )}
            {dataTypeEvents && dataTypeEvents.length > 0 ? (
              <>
                <label className="label">Select type event</label>
                <select
                  className="select"
                  value={currentTypeEvent}
                  onChange={handelSelectTypeEevent}
                >
                  {dataTypeEvents.map((option, index) => {
                    return (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  First Create A New Library From Module Global Measurement
                  Table
                </label>
              </ContainerValue>
            )}
          </ContainerSelectGlobalList>
          <ContainerSelectGlobalList>
            <InfoEvent
              title={"Library Selected"}
              dataEvent={libraryEventsAssociate}
              eventSelected={libraryEventSelected}
              from={"library"}
            />
            <InfoEvent
              title={"Type Event Selected"}
              dataEvent={dataTypeEvents}
              eventSelected={currentTypeEvent}
              from={"type"}
            />
          </ContainerSelectGlobalList>
          <CustomButton
            text={"Sync Objects"}
            margin={0}
            type={"button"}
            onClick={async () => {
              await handelSyncObjects();
            }}
            isLoad={loadingSyncObjects}
            primaryColor={
              currentTypeEvent ? PRIMARY_COLOR : VISIBILITY_OFF_ICON
            }
            secondaryColor={
              currentTypeEvent ? SECOND_COLOR : VISIBILITY_OFF_ICON
            }
            disabled={currentTypeEvent ? false : true}
          />
        </ContainerConfigGlobalValue>
      </GlobalFieldValue>
    </>
  );
};

const InfoEvent = ({ dataEvent = [], eventSelected, title, from }) => {
  const selectedEvent = dataEvent?.find((elm) => elm.id === eventSelected);

  return (
    <ContainerSelectedLibrary>
      <h3>{title}</h3>
      <div className="card">
        <div className="library-name ">
          {selectedEvent?.name ? (
            selectedEvent?.name
          ) : (
            <label className="label">
              First Create
              <Link
                to={
                  from === "library"
                    ? "/library-configurations"
                    : "/config-actions-events"
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  marginLeft: "5px",
                }}
              >
                {from === "library" ? "Event Libraries" : "Events Types"}
              </Link>
            </label>
          )}
        </div>
        <div className="icon-container">
          <img src={selectedEvent?.icon} alt={selectedEvent?.name} />
        </div>
      </div>
    </ContainerSelectedLibrary>
  );
};
