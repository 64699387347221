import { useEffect, useState } from "react";
import useSWR from "swr";
import CustomButton from "components/Buttons/CustomButton";
import {
  ContainerConfigGlobalValue,
  ContainerSelectGlobalList,
  ContainerValue,
  GlobalFieldValue,
} from "./CreateFromMeasurementTableStyle";
import { Link } from "react-router-dom";
import { FieldItems } from "./FieldItems";
import { urlsApiMg } from "utils/urlKeys";

export const CreateFromMeasurementTable = ({
  setFields,
  setDialogMeasurementTable,
  setFromGlobalMeasurementTable,
  handleChange,
}) => {
  const [dataGlobalMeasurementTables, setDataGlobalMeasurementTables] =
    useState([]);
  const [currentGlobalMeasurementTables, setCurrentGlobalMeasurementTables] =
    useState({});
  const [dataMeasurementCategories, setDataMeasurementCategories] = useState(
    []
  );
  const [currentMeasurementCategory, setCurrentMeasurementCategory] =
    useState(null);
  const [globalMeasurementColumns, setGlobalMeasurementColumns] = useState([]);

  const { data: globalMeasurementTables, error: errorGlobalMeasurementTables } =
    useSWR(urlsApiMg.globalMeasurementTable);

  useEffect(() => {
    if (
      globalMeasurementTables &&
      !errorGlobalMeasurementTables &&
      globalMeasurementTables.length > 0
    ) {
      setDataMeasurementCategories(globalMeasurementTables[0]?.tableCategories);
      if (globalMeasurementTables[0]?.tableCategories.length > 0) {
        if (globalMeasurementTables[0]?.tableCategories.length > 0) {
          const existingTable =
            globalMeasurementTables[0]?.tableCategories.filter(
              (elm) => elm.groupTables.length > 0
            );
          if (existingTable.length > 0) {
            setDataGlobalMeasurementTables(existingTable[0]?.groupTables);
            setCurrentMeasurementCategory(existingTable[0]?._id);
            setCurrentGlobalMeasurementTables(
              existingTable[0]?.groupTables[0]._id
            );
            setGlobalMeasurementColumns(
              existingTable[0]?.groupTables[0]?.dataColumns.filter(
                (elm) =>
                  elm.masterKey !== true &&
                  elm.name !== "Association" &&
                  elm.name !== "Synchronized"
              )
            );
          }
        }
      }
    }
  }, [globalMeasurementTables, errorGlobalMeasurementTables]);

  const handleSelectListCategory = (e) => {
    const categorySelected = dataMeasurementCategories.find(
      (elm) => elm._id === e.target.value
    );
    setCurrentMeasurementCategory(e.target.value);
    setDataGlobalMeasurementTables(categorySelected?.groupTables);
    setCurrentGlobalMeasurementTables(categorySelected?.groupTables[0]?._id);
    setGlobalMeasurementColumns(
      categorySelected?.groupTables[0]?.dataColumns.filter(
        (elm) =>
          elm.masterKey !== true &&
          elm.name !== "Association" &&
          elm.name !== "Synchronized"
      )
    );
  };

  const handelSelectGlobalTable = (e) => {
    e.preventDefault();
    setCurrentGlobalMeasurementTables(e.target.value);
    const currentSelectedTable = dataGlobalMeasurementTables.find(
      (elm) => elm._id === e.target.value
    );
    setGlobalMeasurementColumns(
      currentSelectedTable?.dataColumns.filter(
        (elm) =>
          elm.masterKey !== true &&
          elm.name !== "Association" &&
          elm.name !== "Synchronized"
      )
    );
  };

  const handelSaveFields = () => {
    if (globalMeasurementColumns.length > 0) {
      globalMeasurementColumns.forEach((elm) => {
        setFields((current) => {
          const exists = current.some((field) => field.name === elm.name);
          if (!exists) {
            handleChange({
              target: {
                name: "fields",
                value: [
                  ...current,
                  {
                    name: elm.name,
                    type: elm.dataType,
                    required: elm.require,
                    circuit: false,
                    options: [],
                    optionsColors: [],
                    possible: [],
                    min: 0,
                    max: 1,
                    operation: "",
                    alias: elm.alias || elm.name,
                    mobile: true, // para qué es....
                    tags: [],
                    globalSelect: false,
                    globalTable: {},
                    columnKeyGlobalSpecs: {},
                    onPDF: { state: false, where: "" },
                  },
                ],
              },
            });

            return [
              ...current,
              {
                name: elm.name,
                type: elm.dataType,
                required: elm.require,
                circuit: false,
                options: [],
                optionsColors: [],
                possible: [],
                min: 0,
                max: 1,
                operation: "",
                alias: elm.alias || elm.name,
                mobile: true, // para qué es....
                tags: [],
                globalSelect: false,
                globalTable: {},
                columnKeyGlobalSpecs: {},
                onPDF: { state: false, where: "" },
              },
            ];
          }
          return current;
        });
      });
      setDialogMeasurementTable((current) => ({
        ...current,
        open: false,
      }));

      const currentSelectedTable = dataGlobalMeasurementTables.find(
        (elm) => elm._id === currentGlobalMeasurementTables
      );

      setFromGlobalMeasurementTable({
        measurementTableId: currentGlobalMeasurementTables,
        measurementCategoryId: currentMeasurementCategory,
        associationListTableId:
          currentSelectedTable.associationGlobalListTable.tableId,
        associationListCategoryId:
          currentSelectedTable.associationGlobalListTable.categoryId,
      });
    }
  };

  return (
    <>
      <GlobalFieldValue>
        <ContainerConfigGlobalValue>
          <ContainerSelectGlobalList>
            <h2>Table of Global Measurement</h2>
            {dataMeasurementCategories &&
            dataMeasurementCategories.length > 0 ? (
              <>
                <label className="label">Select Category</label>
                <select
                  className="select"
                  value={currentMeasurementCategory}
                  onChange={(e) => handleSelectListCategory(e)}
                >
                  {dataMeasurementCategories?.map((option, index) => {
                    return (
                      <option key={index} value={option._id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  First Create Category In Module
                  <Link
                    to="/config-global-measurement-table"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    Global Measurement Table
                  </Link>
                </label>
              </ContainerValue>
            )}
            {dataGlobalMeasurementTables &&
            dataGlobalMeasurementTables.length > 0 ? (
              <>
                <label className="label">Global Measurement Table</label>
                <select
                  id={currentGlobalMeasurementTables?._id}
                  className="select"
                  value={currentGlobalMeasurementTables || ""}
                  onChange={handelSelectGlobalTable}
                >
                  {dataGlobalMeasurementTables.map((option, index) => {
                    return (
                      <option key={index} value={option._id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  First Create Table In Module
                  <Link
                    to="/config-global-measurement-table"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    Global Measurement Table
                  </Link>
                </label>
              </ContainerValue>
            )}
          </ContainerSelectGlobalList>

          {globalMeasurementColumns &&
            globalMeasurementColumns.length > 0 &&
            globalMeasurementColumns.map((elm, index) => {
              return (
                <FieldItems
                  field={elm}
                  index={index}
                  setState={setGlobalMeasurementColumns}
                />
              );
            })}
        </ContainerConfigGlobalValue>
        <CustomButton
          text={"Save"}
          margin={0}
          onClick={handelSaveFields}
          type="button"
        />
      </GlobalFieldValue>
    </>
  );
};
