// Style
import LayersIcon from "@mui/icons-material/Layers";
import { ButtonBaseMap, ContainerStyle } from "./BaseMapButtonStyle";
// Const
import { MAPBOX_STYLE } from "utils/constStrings";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { useEffect, useState } from "react";
import useSWR from "swr";
import createOrUpdateSettingMap from "services/createOrUpdateSettingMap";
import { useContext } from "react";
import { ThemeContext } from "App";
import { BLACK_COLOR, WHITE_COLOR } from "utils/const";
import { Typography } from "@mui/material";
import { urls } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

export default function BaseMapButton({
  setHaveObjects,
  setContentDialog,
  baseMap,
  setBaseMap,
  position,
}) {
  const [currentStyleMap, setCurrentStyleMap] = useState([]);
  const { theme } = useContext(ThemeContext);

  const { data: styleMapAdminCompany, error: errorStyleMapAdminCompany } =
    useSWR(urls.styleMap.all);
  const { instanceId } = useUserStore.getState();


  useEffect(() => {
    if (
      !errorStyleMapAdminCompany &&
      styleMapAdminCompany &&
      styleMapAdminCompany.length > 0
    ) {
      setCurrentStyleMap(styleMapAdminCompany);
    }
  }, [styleMapAdminCompany, errorStyleMapAdminCompany]);

  const ContentBaseMap = (changeBaseMap) => ({
    title: "Base Map",
    description: (
      <ContainerStyle>
        <div className="types-base-map">
          {Object.values(MAPBOX_STYLE).map((style) => (
            <div key={style.id} className={"type-base-map"}>
              <SimpleToolTip title={"Select Default Map"} placement={"top"}>
                <img
                  onClick={() => handleSelectDefaultMap(style)}
                  src={style.img}
                  alt={style.alt}
                  className={
                    style.id === baseMap.id ? "current-type-base-map" : ""
                  }
                />
              </SimpleToolTip>
              <Typography
                style={{
                  color:
                    theme === "light" ? document.body.style.color : WHITE_COLOR,
                  fontFamily: document.body.style.fontFamily,
                }}
              >
                {style.name}
              </Typography>
              <button
                className="select-base-map"
                value={style.value}
                onClick={() => changeBaseMap(style)}
              >
                View
              </button>
            </div>
          ))}
          {currentStyleMap.length > 0 &&
            currentStyleMap.map((style) => (
              <div key={style.id} className={"type-base-map"}>
                <SimpleToolTip title={"Select Default Map"} placement={"top"}>
                  <img
                    onClick={() => handleSelectDefaultMap(style)}
                    src={style.img}
                    alt={style.id}
                    className={
                      style.id === baseMap.id ? "current-type-base-map" : ""
                    }
                  />
                </SimpleToolTip>
                <span
                  className="description"
                  style={{
                    color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                  }}
                >
                  {style.name}
                </span>
                <button
                  className="select-base-map"
                  value={style.value}
                  onClick={() => changeBaseMap(style)}
                >
                  View
                </button>
              </div>
            ))}
        </div>
      </ContainerStyle>
    ),
  });

  const changeBaseMap = (style) => {
    setBaseMap({
      id: baseMap.id,
      value: style.value,
    });
    setHaveObjects(false);
  };

  const handleBaseMap = () => {
    setHaveObjects(true);
    setContentDialog(ContentBaseMap(changeBaseMap));
  };

  const handleSelectDefaultMap = async (style) => {
    await createOrUpdateSettingMap({
      userId: parseInt(localStorage.getItem("userId")),
      instanceId: instanceId ? instanceId : null,
      styleMap: {
        id: style.id,
        value: style.value,
      },
    });
    setBaseMap({
      id: style.id,
      value: style.value,
    });
    setHaveObjects(false);
  };

  return (
    <>
      <SimpleToolTip title="Change Base Map" placement="left">
        <ButtonBaseMap onClick={handleBaseMap} top={position}>
          <LayersIcon style={{ color: "#282828", fontSize: "1.6rem" }} />
        </ButtonBaseMap>
      </SimpleToolTip>
    </>
  );
}
