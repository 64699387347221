import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Divider, DividerTitle } from "./TableStyle";
import Loading from "components/Lodings/LoadingV2";
// Services
import { userVerification } from "services/users/userVerification";
import { FROM_TABLE } from "utils/constStrings";
import { roles } from "utils/roles";
import GenericTable from "../GenericTable/GenericTable";
export const keyValuesUsers = [
  "firstName",
  "secondName",
  "lastName",
  "email",
  "instance",
  "role",
  "area",
  "activeSession",
  "confirmationToken",
  "actions",
  "noSessions",
  "userLogOut",
  "noSessionsMobile",
  "userLogOutMobile",
  "createdAt",
];

export const headersUsers = [
  "Name",
  "Second Name",
  "Last Name",
  "E-mail",
  "Instance",
  "Role",
  "Area",
  "Session",
  "State",
  "Actions",
  "No Sessions Web",
  "User Log Out",
  "No Sessions Mobile",
  "User Log Out Mobile",
  "Created At",
];

const createRowsOperators = (operators) => {
  return Promise.all(
    operators.map((operator) => {
      return userVerification(operator.user.email).then((res) => {
        return {
          id: operator.userId,
          firstName: operator.firstName,
          secondName: operator.secondName,
          lastName: operator.firstLastName,
          email: operator.user.email,
          instance: operator.instance.name,
          role: operator.role?.role,
          area: operator.area,
          // canDelete: operator.user.objects.length === 0, Esto es re inecesario, para saber si se puede elimar el operario traia en la respuesta de la peticion todos los objetos para saber si tiene objetos recolectados en el mapa
          canDelete: true, // por ahora lo dejo en true pero hay que enviar la respuesta desde backend haciendo un count con un query a la db
          activeSession: res.activeSession ? "Online" : "Offline ",
          confirmationToken: operator.user.userVerification.confirmationToken
            ? "E-Mail pending of verification"
            : "Verified e-mail",
          createdAt: operator.createdAt.substring(0, 10),
          isEnable: res.isEnable,
          noSessions: res.noSessions,
          noSessionsMobile: res.noSessionsMobile,
        };
      });
    })
  );
};

const createRowsAdminCompanies = (adminCompanies) => {
  return Promise.all(
    adminCompanies.map((adminCompany) => {
      return userVerification(adminCompany.user.email).then((res) => {
        return {
          id: adminCompany.userId,
          firstName: adminCompany.firstName,
          secondName: adminCompany.secondName,
          lastName: adminCompany.firstLastName,
          email: adminCompany.user.email,
          instance: adminCompany.company.name,
          role: adminCompany.user.userType.role,
          area: adminCompany.area,
          // canDelete: adminCompany.user.objects.length === 0, Esto es re inecesario, para saber si se puede elimar el operario traia en la respuesta de la peticion todos los objetos para saber si tiene objetos recolectados en el mapa
          canDelete: true, // por ahora lo dejo en true pero hay que enviar la respuesta desde backend haciendo un count con un query a la db
          activeSession: res.activeSession ? "Online" : "Offline ",
          confirmationToken: adminCompany.user.userVerification
            .confirmationToken
            ? "E-Mail pending of verification"
            : "Verified e-mail",
          createdAt: adminCompany.createdAt.substring(0, 10),
          isEnable: res.isEnable,
          noSessions: res.noSessions,
          noSessionsMobile: res.noSessionsMobile,
        };
      });
    })
  );
};

export default function EnhancedTable({
  usersAdminByInstanceId,
  usersOperatorByInstanceId,
}) {
  const [rowsOperators, setRowsOperators] = useState(null);
  const [rowsAdminCompanies, setRowsAdminCompanies] = useState(null);
  const [isLoadingAAdmins, setIsLoadingAdmind] = useState(true);
  const [isLoadingOperators, setIsLoadingOperators] = useState(true);
  const [valuesNoSessionsAdmin, setValuesNoSessionsAdmin] = useState({});
  const [valuesNoSessionsMobileAdmin, setValuesNoSessionsMobileAdmin] =
    useState({});
  const [valuesNoSessionsOperator, setValuesNoSessionsOperator] = useState({});
  const [valuesNoSessionsMobileOperator, setValuesNoSessionsMobileOperator] =
    useState({});

  useEffect(() => {
    if (usersOperatorByInstanceId) {
      setIsLoadingOperators(true);
      createRowsOperators(usersOperatorByInstanceId).then((operatorsRows) => {
        operatorsRows.forEach(({ id, noSessions, noSessionsMobile }) => {
          setValuesNoSessionsOperator((prevValues) => ({
            ...prevValues,
            [id]: noSessions,
          }));
          setValuesNoSessionsMobileOperator((prevValues) => ({
            ...prevValues,
            [id]: noSessionsMobile,
          }));
        });
        setRowsOperators(operatorsRows?.length > 0 ? operatorsRows : []);
        setIsLoadingOperators(false);
      });
    }
  }, [usersOperatorByInstanceId]);

  useEffect(() => {
    if (usersAdminByInstanceId) {
      setIsLoadingAdmind(true);
      createRowsAdminCompanies(usersAdminByInstanceId).then((adminsRows) => {
        adminsRows.forEach(({ id, noSessions, noSessionsMobile }) => {
          setValuesNoSessionsAdmin((prevValues) => ({
            ...prevValues,
            [id]: noSessions,
          }));
          setValuesNoSessionsMobileAdmin((prevValues) => ({
            ...prevValues,
            [id]: noSessionsMobile,
          }));
        });
        setRowsAdminCompanies(adminsRows?.length > 0 ? adminsRows : []);
        setIsLoadingAdmind(false);
      });
    }
  }, [usersAdminByInstanceId]);

  return (
    <>
      <Divider>
        <DividerTitle>Admin Company Users</DividerTitle>
      </Divider>
      {rowsAdminCompanies && (
        <GenericTable
          keyValues={keyValuesUsers}
          headers={headersUsers}
          rows={rowsAdminCompanies}
          setRows={setRowsAdminCompanies}
          valuesNoSessions={valuesNoSessionsAdmin}
          setValuesNoSessions={setValuesNoSessionsAdmin}
          valuesNoSessionsMobile={valuesNoSessionsMobileAdmin}
          setValuesNoSessionsMobile={setValuesNoSessionsMobileAdmin}
          state={true}
          configBar={true}
          from={FROM_TABLE.users}
          register={roles.companyAdmin}
        />
      )}
      {isLoadingAAdmins && <Loading />}

      <Divider>
        <DividerTitle>Operators Users</DividerTitle>
      </Divider>
      {rowsOperators && (
        <GenericTable
          keyValues={keyValuesUsers}
          headers={headersUsers}
          rows={rowsOperators}
          setRows={setRowsOperators}
          valuesNoSessions={valuesNoSessionsOperator}
          setValuesNoSessions={setValuesNoSessionsOperator}
          valuesNoSessionsMobile={valuesNoSessionsMobileOperator}
          setValuesNoSessionsMobile={setValuesNoSessionsMobileOperator}
          state={true}
          configBar={true}
          from={FROM_TABLE.users}
          register={roles.operario}
        />
      )}
      {isLoadingOperators && <Loading />}
    </>
  );
}
