import axios from "axios";
import { config } from "config.js";
const URL_BASE_PG = config.URL_BACKEND_PG;

export const getInfoBucketUser = async ({ userId }) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(`${URL_BASE_PG}api/v1/users/bucket/${userId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Error getting user info", error);
  }
};
