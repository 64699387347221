import axios from "axios";
import { s3MaganerUrls } from "./urls";

// Get authentication token from browser storage
const TOKEN = localStorage.getItem("token");

/**
 * Preconfigured Axios instance for S3 API communication
 * @constant {Object} apiClient
 * @property {string} baseURL - API base URL from config
 * @property {Object} headers - Default headers including Authorization Bearer token
 *
 * @example
 * apiClient.get('/some-endpoint') // Uses preconfigured settings
 */
const apiClient = axios.create({
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
});

/**
 * Creates a new storage bucket with optional folder
 * @async
 * @function createBucket
 * @param {Object} params - Creation parameters
 * @param {string} params.bucketName - Required. Name of the bucket to create
 * @param {string} [params.folderName] - Optional. Path to create initial folder
 * @param {string} [params.region="us-east-1"] - Optional. AWS region for bucket creation
 * @returns {Promise<Object>} API response data
 * @throws {Error} API request failures
 *
 * @example
 * // Create bucket without folder
 * await createBucket({ bucketName: "my-bucket" });
 *
 * @example
 * // Create bucket with folder in specific region
 * await createBucket({
 *   bucketName: "my-bucket",
 *   folderName: "initial-folder",
 *   region: "eu-west-1"
 * });
 */
export const createBucket = async ({
  bucketName,
  folderName = null,
  region = "us-east-1",
}) => {
  try {
    const { data } = await apiClient.post(s3MaganerUrls.buckets.create, {
      bucket_name: bucketName,
      folder_name: folderName,
      ...(region !== "us-east-1" && { region }),
    });
    return data;
  } catch (error) {
    console.error("Bucket creation failed:", error);
    throw error; // Rethrow for error handling in calling code
  }
};

/**
 * Creates a new folder within an existing bucket
 * @async
 * @function createFolder
 * @param {Object} params - Creation parameters
 * @param {string} params.bucketName - Required. Target bucket name
 * @param {string} params.folderName - Required. Path of the folder to create
 * @param {string} [params.region="us-east-1"] - Optional. AWS region if different from default
 * @returns {Promise<Object>} API response data
 * @throws {Error} API request failures
 *
 * @example
 * // Create folder in default region
 * await createFolder({
 *   bucketName: "existing-bucket",
 *   folderName: "new-folder"
 * });
 *
 * @note The function name contains a typo (createFolder) - consider renaming to createFolder
 */
export const createFolder = async ({
  folderName,
  bucketName,
  region = "us-east-1",
}) => {
  try {
    const { data } = await apiClient.post(s3MaganerUrls.folder.create, {
      folder_name: folderName,
      bucket_name: bucketName,
      ...(region !== "us-east-1" && { region }),
    });
    return data;
  } catch (error) {
    console.error("Folder creation failed:", error);
    throw error; // Rethrow for error handling in calling code
  }
};

/**
 * Uploads a file to a specified folder path in S3 storage.
 * If `file` is not a File object, it returns the value of `isUrl` instead.
 *
 * @async
 * @function uploadFile
 * @param {Object} params - Upload parameters
 * @param {string} params.folderPath - The target folder path in the bucket
 * @param {File|string} params.file - The file object to upload, or a string (URL) if isUrl is provided
 * @param {Object} [params.isUrl] - URL to return if file is not provided as a File object
 * @returns {Promise<Object|string>} API response data or the `isUrl` value if file is not a File
 * @throws {Error} Throws an error if upload fails
 *
 * @example
 * // Upload a file from an input element
 * const fileInput = document.querySelector("#fileInput").files[0];
 * await uploadFile({ folderPath: "uploads/documents", file: fileInput });
 *
 * @example
 * // Return a predefined URL instead of uploading a file
 * const uploadedUrl = await uploadFile({ folderPath: "uploads/documents", file: "not-a-file", isUrl: "https://example.com/image.jpg" });
 * console.log(uploadedUrl); // Outputs: {fileUrl: "https://example.com/image.jpg", fileUrlSigned: "https://example.com/image.jpg"}
 */
export const uploadFileToS3 = async ({ folderPath, file, isUrl = null }) => {
  if (!folderPath || typeof folderPath !== "string") {
    throw new Error("Invalid folder path: It must be a non-empty string.");
  }

  if (!(file instanceof File)) {
    return isUrl; // Return the isUrl value if file is not a valid File object
  }

  try {
    // Create FormData object for multipart/form-data request
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folder_path", folderPath);

    // Perform the API request
    const { data } = await apiClient.post(s3MaganerUrls.file.upload, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Verify response contains the file_url
    if (!data?.file_url) {
      throw new Error("Invalid response: Missing file_url in response data");
    }

    return { fileUrl: data.file_url, fileUrlSigned: data.presigned_url };
  } catch (error) {
    if (error.response) {
      console.error("File upload failed:", error.response.data);
      throw new Error(
        `Upload failed: ${error.response.data.message || "Unknown error"}`
      );
    } else if (error.request) {
      console.error("No response from server:", error.request);
      throw new Error("Upload failed: No response from server.");
    } else {
      console.error("Unexpected error:", error.message);
      throw new Error(`Upload failed: ${error.message}`);
    }
  }
};

/**
 * Generates a pre-signed URL for accessing S3 objects through an API endpoint.
 *
 * @param {Object} params - The parameters for generating the signed URL
 * @param {string} params.s3Url - The original S3 object URL to be signed
 * @param {number} [params.expiration=60] - URL expiration time in seconds (defaults to 60)
 * @returns {Promise<string>} A pre-signed URL that provides temporary access to the S3 object
 * @throws {Error} If the API request fails or if invalid parameters are provided
 *
 * @example
 * try {
 *   const signedUrl = await getUrlSigned({
 *     s3Url: 'https://my-bucket.s3.amazonaws.com/path/to/file.pdf',
 *     expiration: 300 // 5 minutes
 *   });
 *   console.log('Signed URL:', signedUrl);
 * } catch (error) {
 *   console.error('Failed to generate signed URL:', error);
 * }
 */
export const getUrlSigned = async ({ s3Url, expiration = 60 }) => {
  // Input validation
  if (!s3Url) {
    throw new Error("s3Url is required");
  }

  if (typeof expiration !== "number" || expiration <= 0) {
    throw new Error("expiration must be a positive number");
  }

  try {
    // Construct query parameters
    const params = new URLSearchParams();
    params.append("s3_url", s3Url);
    params.append("expiration", expiration.toString());

    // Make API request to get signed URL
    const { data } = await apiClient.get(
      s3MaganerUrls.signedUrl.get(params.toString()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Verify response contains the signed URL
    if (!data?.presigned_url) {
      throw new Error(
        "Invalid response: Missing presigned_url in response data"
      );
    }

    return data.presigned_url;
  } catch (error) {
    // Log the error with additional context
    console.error("Failed to generate signed URL:", {
      error,
      s3Url,
      expiration,
    });

    // Rethrow with a more informative message
    throw new Error(
      `Failed to generate signed URL: ${error.message || "Unknown error"}`
    );
  }
};
