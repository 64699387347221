import { useDispatch, useSelector } from "react-redux";
import {
  setMarkerPointGroup,
  setStateFilterButtonTable,
} from "redux/actions/digitalTwin";

import handleExtendView from "../components/Tables/GenericTable/helpers/handleExtendView";
import { useEffect, useState } from "react";

export default function useMarkerPointsGroup({
  setButtonViewMarkersGroup = () => {},
  dataMarkerPoints,
}) {
  const dispatch = useDispatch();
  const [filterMarkerPoints, setFilterMarkerPoints] = useState([]);
  const [fullMarkerPoints, setFullMarkerPoints] = useState([]);

  const allFullTableRows = useSelector(
    (state) => state.digitalTwinReducer.fullTableRows
  );
  const currentMap = useSelector((state) => state.digitalTwinReducer.map);

  useEffect(() => {
    if (allFullTableRows.length > 0 && dataMarkerPoints.length > 0) {
      setFilterMarkerPoints(
        dataMarkerPoints
          .map((elm) => {
            if (!isNaN(elm.longitude) && !isNaN(elm.latitude)) {
              return {
                id: elm.id,
                location: {
                  longitude: elm.longitude,
                  latitude: elm.latitude,
                },
              };
            }
            return null;
          })
          .filter((it) => it)
      );
      setFullMarkerPoints(
        allFullTableRows
          .map((elm) => {
            if (
              !isNaN(elm.original.longitude) &&
              !isNaN(elm.original.latitude)
            ) {
              return {
                id: elm.original.id,
                location: {
                  longitude: elm.original.longitude,
                  latitude: elm.original.latitude,
                },
              };
            }
            return null;
          })
          .filter((it) => it)
      );
    }
  }, [dataMarkerPoints, allFullTableRows]);

  const viewMarkerPoints = () => {
    setButtonViewMarkersGroup(false);
    dispatch(setStateFilterButtonTable(true));
    dispatch(setMarkerPointGroup(filterMarkerPoints.map((elm) => elm.id)));
    handleExtendView({ array: filterMarkerPoints, map: currentMap });
  };

  const setViewMarkerPoints = () => {
    setButtonViewMarkersGroup(true);
    dispatch(setStateFilterButtonTable(false));
    dispatch(setMarkerPointGroup([]));
    handleExtendView({ array: fullMarkerPoints, map: currentMap });
  };

  return {
    setViewMarkerPoints,
    viewMarkerPoints,
  };
}
