import React, { useEffect, useRef, useState } from "react";
import useGet from "hooks/useFetch";
import { useNavigate } from "react-router-dom";
import Layout from "components/Layout/AdminDecimetrix";
import DescriptionHub from "pages/AdminCompany/Hub/DescriptionHub";
import CustomTabs from "pages/AdminCompany/Hub/CustomTabs";
import {
  HubH2,
  HubP,
  CardContainer,
  HubCard,
  HubContainer,
  HubWrapper,
} from "pages/AdminCompany/Hub/HubStyles";
import contentCardsTools from "./contentCardsTools";
import AvailableModules from "pages/AdminCompany/Hub/AvailableModules";
import { ShowAllButton2 } from "./Preferences/preferencesstyles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import TutorialsHub from "pages/AdminCompany/Hub/TutotialsHub";
import { urls } from "utils/urlKeys";

function ContencCards({ hideCards }) {
  const cardContainerRef = useRef(null);
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (value === 0) {
      localStorage.setItem("selectedSettingsCategory", "");
    } else if (value === 1) {
      localStorage.setItem('selectedSettingsCategory', "Platform Set Up");
    }
  }, [value]);

  const handleMouseDown = (event) => {
    if (!cardContainerRef.current) return;
    setIsDragging(true);
    setStartX(event.pageX - cardContainerRef.current.offsetLeft);
    setScrollLeft(cardContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!cardContainerRef.current) return;
    if (!isDragging) return;
    const x = event.pageX - cardContainerRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    cardContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const [adminCompany] = useGet(urls.adminCompany.detail);

  const navigation = (path) => {
    navigate(path);
    localStorage.setItem("selectedSettingsCategory", "");
  };

  return (
    <>
      <Layout>
        <DescriptionHub adminCompany={adminCompany} />
        <CustomTabs value={value} onChange={handleChange} />
        <HubContainer className="background-2 border-top-1">
          <CardContainer
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {!hideCards && value == 1 && (
              <>
                <HubWrapper
                  ref={cardContainerRef}
                  style={{ justifyContent: "center" }}
                >
                  {contentCardsTools.map((item) => {
                    const setTitle = item.title.split(" ");
                    return (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <HubCard
                          className="background-card-color"
                          key={item.id}
                          onClick={() => navigate(item.toNavigate)}
                        >
                          <HubH2>
                            {setTitle.map((elm, index) => {
                              if (index === 2) {
                                return (
                                  <>
                                    <br />
                                    {elm}
                                  </>
                                );
                              } else {
                                return <>{` ${elm} `}</>;
                              }
                            })}
                          </HubH2>
                          {item.image}
                          <HubP>{item.description}</HubP>
                        </HubCard>
                      </div>
                    );
                  })}
                </HubWrapper>
              </>
            )}
            {value === 0 && <AvailableModules />}
            {value === 3 && <TutorialsHub />}
          </CardContainer>
        </HubContainer>
      </Layout>
    </>
  );
}

export default ContencCards;
