import { useState, useEffect, useCallback } from "react";
// Helpers
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/componentsLineChart";
import { createRowsComponents } from "pages/Dashboard/helpers/createRowsComponents";
// hooks
import useFindObjects from "hooks/objects/useFindObjects";
import useSwr from "swr";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import { urlsApiMg } from "utils/urlKeys";

const useFindLineCharts = ({ openDialog, components, loading }) => {
  const [charts, setCharts] = useState(null);
  const [lineCharts, setLineCharts] = useState(null);
  const [componentsFormat, setComponentsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataLineChart, error: errorDataLineChart } = useSwr(
    urlsApiMg.dashboard.lineChart("components")
  );
  const { objects } = useFindObjects();

  useEffect(() => {
    if (!errorDataLineChart && dataLineChart) setCharts(dataLineChart);
  }, [dataLineChart, errorDataLineChart, openDialog, reloadCharts]);

  useEffect(() => {
    const componentsFormat = createRowsComponents({ components, objects });
    setComponentsFormat(componentsFormat);
  }, [components, objects]);

  const getDataBarChart = useCallback(async () => {
    if (!loading && componentsFormat) {
      const lineCountsvalues = countByValues({
        charts,
        components: componentsFormat,
      });

      const lineSumValues = sumValues({
        charts,
        components: componentsFormat,
      });

      setLineCharts([...lineCountsvalues, ...lineSumValues]);
    }
  }, [loading, componentsFormat, charts]);

  useEffect(() => {
    if (charts && components) {
      getDataBarChart();
    }
  }, [charts, components, getDataBarChart]);

  return { lineCharts };
};

export default useFindLineCharts;
