import { useState, useEffect } from "react";
// Components
import CustomButton from "components/Buttons/CustomButton";
import ImgChartIcon from "icons/ImgChart";
// Style
import { ContainerFormImg } from "./style";
// Services
import { addChartInAllViews } from "services/Dashboard/orderCards";
import { createChart } from "services/Dashboard/imgChart";
import { useSWRConfig } from "swr";
import { getPathBucket } from "helpers/getPathBucket";
import { pathFolder } from "utils/paths";
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
import { urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

const FormImgChart = ({ from, setOpenDialog }) => {
  const { mutate } = useSWRConfig();
  const [loadingAddChart, setLoadingAddChart] = useState(false);
  const [dataChart, setDataChart] = useState({
    chartType: "imgChart",
    img: "",
    tempImage: null,
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const { instanceId } = useUserStore((state) => state);

  useEffect(() => {
    if (dataChart.tempImage && dataChart.tempImage instanceof File) {
      const objectUrl = URL.createObjectURL(dataChart.tempImage);
      setPreviewUrl(objectUrl);

      // Limpia la URL del objeto cuando el componente se desmonte o cuando tempImage cambie
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [dataChart.tempImage]);

  const handlerAddImageChart = async () => {
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const userId = localStorage.getItem("userId");
    const urlChartText = urlsApiMg.dashboard.imgChart(from);
    const urlOrders = urlsApiMg.dashboard.orderCards;

    setLoadingAddChart(true);

    const folderPath = getPathBucket(pathFolder.dashboard.imageChart);
    const { fileUrl } = await uploadFileToS3({
      file: dataChart.tempImage,
      folderPath,
    });

    const body = {
      adminCompanyId: parseInt(adminCompanyId),
      userId: parseInt(userId),
      chartType: "imgChart",
      img: fileUrl,
      from: from,
      instanceId: instanceId,
    };

    const { data: responseData } = await createChart(body);

    if (responseData) {
      mutate(urlChartText);
      await addChartInAllViews({
        userId,
        type: from,
        body: {
          newChart: {
            id: responseData._id,
          },
        },
      });
      mutate(urlOrders);
    }
    setLoadingAddChart(false);
    setOpenDialog(false);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setDataChart((current) => ({
        ...current,
        tempImage: file,
      }));
    }
  };

  return (
    <>
      <ContainerFormImg>
        <div className="image-upload-container">
          <h2 className="subtitle">Upload image</h2>
          <label htmlFor="image-upload" className="image-upload-label">
            {previewUrl ? (
              <img src={previewUrl} alt="Preview" className="image-preview" />
            ) : (
              <>
                <ImgChartIcon />
                <span>Click to upload image</span>
              </>
            )}
          </label>
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="image-upload-input"
          />
        </div>
      </ContainerFormImg>
      <CustomButton
        className="btn-add-chart"
        onClick={() => handlerAddImageChart()}
        isLoad={loadingAddChart}
        text={"Add Image chart"}
        margin={"15px 0"}
      />
    </>
  );
};

export default FormImgChart;
