import { config } from "config.js";
import axios from "axios";
import { format } from "date-fns";
import { CREATED_FROM } from "utils/constStrings";

const URl_BASE_PG = config.URL_BACKEND_PG;

export const logoutUser = async () => {
  try {
    const date = new Date();
    const lastLoginTimestamp = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${URl_BASE_PG}api/v1/auth/logout`,
      { lastLogin: lastLoginTimestamp, from: CREATED_FROM.desktop }, // Flag from: "Desttop" - TO CLOSE SESSION WEB
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
