import { useEffect, useState } from "react";
import SelectLibrary from "./SelectLibrary";
// Hooks
import { useFetchComponentsLibraries } from "hooks/fetchLibraries";
import useFindComponents from "hooks/components/useFindComponents";
import useFindObjects from "hooks/objects/useFindObjects";

// Components
import SelectColumnLibrary from "pages/Dashboard/components/SelectColumnLibrary";
import CustomButton from "components/Buttons/CustomButton";
import SelectTypeOperation from "pages/Dashboard/components/SelectTypeOperation";

// Services
import { createChart } from "services/Dashboard/groupBarChart";
// Helpers
import { createRowsComponents } from "pages/Dashboard/helpers/createRowsComponents";
import { useSWRConfig } from "swr";
import { addChartInAllViews } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";
import { defaultFieldsComponents } from "utils/defaultColumns";
import AddGroupBarChart from "./AddGroupBarChart";
import SelectColumnLibraryWithValues from "./SelectColumnLibraryWithValues";
import FetchComponents from "hooks/fetchComponents";
import { urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

const FormGroupBarChartCompoents = ({ setOpenDialog }) => {
  const [columnsLibrary, setColumnsLibrary] = useState([]);
  // const [columnsByDateLibrary, setColumnsByDateLibrary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [librarySelectedId, setLibrarySelectedId] = useState(null);
  const [columnsTypeNumber, setColumnsTypeNumber] = useState([]);
  const [componentsFormat, setComponentsFormat] = useState([]);
  const [dataInputs, setDataInputs] = useState({
    alias: "",
    librarySelected: null,
    columnY: null,
    columnX: null,
    typeOperation: "count",
    category: null,
  });
  const [dataSeries, setDataSeries] = useState([]);
  const [typeComponentNames, setTypeComponentNames] = useState([]);
  const { instanceId } = useUserStore((state) => state);

  const updateDataInputs = (newState) => {
    setDataInputs((prevState) => ({ ...prevState, ...newState }));
  };

  const { data: libraries, error } = useFetchComponentsLibraries({});
  const { data: typeComponents, error: errorTypeComponents } =
    FetchComponents(librarySelectedId);

  const { components } = useFindComponents();

  const { objects } = useFindObjects();

  const { mutate } = useSWRConfig();

  const setNewCreateChart = useStoreDashboard(
    (state) => state.setNewCreateChart
  );
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);

  const handlerSelectCategory = (column) => {
    updateDataInputs({ category: column });
  };

  const handlerTypeOperation = (e) => {
    updateDataInputs({ typeOperation: e });
  };

  const handlerClickColumnY = (column) => {
    updateDataInputs({ columnY: column });
  };

  const handlerClickColumnX = (column) => {
    updateDataInputs({ columnX: column });
  };

  const handlerAlias = (e) => {
    updateDataInputs({ alias: e.target.value });
  };

  const columsTypeNumber = (columns) => {
    const numberFields = columns?.filter(
      (field) =>
        field.type === "number" ||
        field.type === "operation" ||
        field.type === "range"
    );
    setColumnsTypeNumber(numberFields || []);
  };

  const handlerLibrarySelected = (e) => {
    updateDataInputs({ librarySelected: e });
    const library = libraries.find((library) => library.id === e.value);
    setLibrarySelectedId(library.id);
    const componentsByLibraryId = components.filter(
      (component) =>
        component.pointTypeComponent.pointLibraryComponentId === e.value
    );
    const rows = createRowsComponents({
      components: componentsByLibraryId,
      library,
      objects,
    });
    setComponentsFormat(rows);
    updateDataInputs({ columnY: null, columnX: null, alias: "" });
  };

  useEffect(() => {
    // find library by library select from libraries
    if (libraries && dataInputs.librarySelected) {
      const library = libraries.find(
        (library) => library.id === dataInputs.librarySelected.value
      );

      const columnsLibrary = library?.fields;

      const columnsFiltered = columnsLibrary?.filter(
        (field) =>
          field.type !== "captureMedia" &&
          field.type !== "notification" &&
          field.type !== "number" &&
          field.type !== "operation" &&
          field.type !== "range"
      );

      if (columnsFiltered && columnsFiltered.length > 0) {
        setColumnsLibrary(
          [...defaultFieldsComponents, ...columnsFiltered] || []
        );
      } else {
        setColumnsLibrary([...defaultFieldsComponents] || []);
      }

      columsTypeNumber(columnsLibrary || []);
    } else {
      setColumnsLibrary([]);
    }
  }, [libraries, dataInputs.librarySelected]);

  useEffect(() => {
    if (
      librarySelectedId &&
      !errorTypeComponents &&
      typeComponents?.length > 0
    ) {
      const names = typeComponents.map((elm) => {
        return elm.name;
      });
      setTypeComponentNames(names);
    }
  }, [typeComponents, errorTypeComponents, librarySelectedId]);

  const handlerAddChart = async () => {
    const userId = localStorage.getItem("userId");
    const urlLineChart = urlsApiMg.dashboard.barChartGroup("components");
    const urlOrders = urlsApiMg.dashboard.orderCards;
    const chart = {
      adminCompanyId: parseInt(localStorage.getItem("adminCompanyId")),
      type: dataInputs.typeOperation,
      from: "components",
      libraryId: dataInputs.librarySelected.value,
      alias: dataInputs.alias,
      columnX: dataInputs.columnX,
      columnsY: dataInputs.columnY ? dataInputs.columnY : "",
      categories: dataInputs.category,
      datasets: dataSeries,
      instanceId: instanceId,
    };

    setLoading(true);
    const { data } = await createChart(chart);
    if (data) {
      mutate(urlLineChart);
      await addChartInAllViews({
        userId,
        type: "components",
        body: {
          newChart: {
            id: data._id,
          },
        },
      });
      mutate(urlOrders);
      setNewCreateChart(!newCreateChart);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  return (
    <>
      {!error && libraries && (
        <SelectLibrary
          libraries={libraries}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={dataInputs.librarySelected}
        />
      )}
      {dataInputs.librarySelected && (
        <SelectColumnLibraryWithValues
          columnsLibrary={columnsLibrary}
          handlerClickColumn={handlerClickColumnX}
          columnSelected={dataInputs.columnX}
          typeElementNames={typeComponentNames}
          data={componentsFormat}
          title={"Select X-Axis (Main Category)"}
        />
      )}

      {dataInputs.librarySelected && (
        <AddGroupBarChart
          dataInputs={dataInputs}
          columnsLibrary={columnsLibrary}
          setDataSeries={setDataSeries}
          dataSeries={dataSeries}
          handlerSelectCategory={handlerSelectCategory}
          data={componentsFormat}
          typeElementNames={typeComponentNames}
        />
      )}

      {dataInputs.librarySelected && dataSeries.length > 0 && (
        <SelectTypeOperation
          handlerSelectTypeOperation={handlerTypeOperation}
        />
      )}

      {dataInputs.librarySelected &&
        columnsLibrary &&
        dataSeries.length > 0 &&
        dataInputs.typeOperation === "sum" && (
          <SelectColumnLibrary
            title={"Select Y-Axis Variable for Operation"}
            columnsLibrary={columnsTypeNumber}
            handlerClickColumn={handlerClickColumnY}
            columnSelected={dataInputs.columnY}
          />
        )}

      {dataInputs.librarySelected && dataSeries.length > 0 && (
        <section className="container-type-charts ">
          <div>
            <h2 className="subtitle">Title</h2>
            <input
              className="alias"
              type="text"
              value={dataInputs.alias}
              onChange={(e) => {
                handlerAlias(e);
              }}
              required={true}
            />
          </div>
        </section>
      )}

      {dataInputs.alias !== "" && dataInputs.alias !== " " && (
        <CustomButton
          className="btn-add-chart"
          onClick={() => handlerAddChart()}
          isLoad={loading}
          text={"Add chart"}
          margin={"15px 0"}
        />
      )}
    </>
  );
};

export default FormGroupBarChartCompoents;
