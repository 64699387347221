import useSwr from "swr";
import { urls } from "utils/urlKeys";

/**
 * Fetches a point type event by its ID.
 *
 * @param {number} id - The ID of the point type event.
 * @return {Object} An object containing the fetched data and any error that occurred.
 */
const useFetchPointTypeEvent = ({ id, libraryId }) => {
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("id", id);
  if (libraryId) urlParams.append("libraryId", libraryId);
  const url = urls.events.typeEventsParams(urlParams.toString());
  const { data, error } = useSwr(url);
  return { data, error };
};

export default useFetchPointTypeEvent;
