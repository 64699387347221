import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Magic strings
const TYPE_ELEMENT = {
  OBJECT: "objects",
  COMPONENT: "components",
  EVENT: "events",
  LINE: "lines",
};

export const useFilteredData = ({ typeOfTable }) => {
  const [itemsFiltered, setItemsFiltered] = useState([]);

  const allFilterTableRows = useSelector(
    (state) => state.digitalTwinReducer.filterTableRows
  );

  const allDataObjects = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );

  const allDataComponents = useSelector(
    (state) => state.adminReducer.dataComponentPolygon
  );

  const allDataEvents = useSelector(
    (state) => state.adminReducer.dataEventPolygon
  );

  const allDataLines = useSelector(
    (state) => state.digitalTwinReducer.originDataLines
  );

  useEffect(() => {
    // Get objects data with mongoId field
    let newItemsData;
    if (typeOfTable === TYPE_ELEMENT.OBJECT)
      newItemsData = allDataObjects.filter((object) =>
        allFilterTableRows?.some((data) => data.id === object.id)
      );

    if (typeOfTable === TYPE_ELEMENT.COMPONENT)
      newItemsData = allDataComponents.filter((component) =>
        allFilterTableRows?.some((data) => data.id === component.id)
      );

    if (typeOfTable === TYPE_ELEMENT.EVENT)
      newItemsData = allDataEvents.filter((event) =>
        allFilterTableRows?.some((data) => data.id === event.id)
      );

    if (typeOfTable === TYPE_ELEMENT.LINE)
      newItemsData = allDataLines.filter((line) =>
        allFilterTableRows?.some((data) => data.id === line.id)
      );

    setItemsFiltered(newItemsData);
  }, [
    allFilterTableRows,
    allDataObjects,
    allDataComponents,
    allDataEvents,
    allDataLines,
    typeOfTable,
  ]);

  return { itemsFiltered };
};
