// React
import { useRef } from "react";
import { useCallback, useState, useEffect } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails } from "redux/actions";
// Consts
import fogStyle from "utils/fogStyle";
import { MAPBOX_STYLE } from "utils/constStrings";
// Styles
import Container from "@mui/material/Container";
// Components
import {
  Map,
  Marker,
  AttributionControl,
  NavigationControl,
} from "react-map-gl";
import DrawControl from "./DrawControl";
import OpenDialog from "components/Dialogs/OpenDialog";
import CreateEvent from "./CreateEvent";
import OperationDetails from "components/ElementDetails/OperationDetails/OperationDetails";
import EventDetails from "components/ElementDetails/EventDetails/EventDetails";
import BaseMapButton from "components/Buttons/BaseMapButton";
import MovePoint from "components/DigitalTwin/MovePoint";
import SaveCoordinateButton from "components/Buttons/SaveCoordinateButton";
// Hooks
import FetchTypeEventsTypeComponents from "hooks/FetchTypeEventsTypeComponents";
import FetchEventsByComponentId from "hooks/fetchEventsByComponentId";
import useCreateLinesComponentEventsRelation from "hooks/useCreateLinesComponentEventsRelation";
import useCreateMarkerParents from "hooks/useCreateMarkerParents";
// Helpers
import { ShowEventMarkers } from "helpers/ShowEventsMarker";
import ShowLinesJoin from "helpers/showLines";
import showMarkers from "helpers/showMarkers";
import { useUserStore } from "zustandGloabalStore/user";

export default function ComponentMap({ component, markedEvent, setMovePoint }) {
  const { instanceId } = useUserStore((state) => state);

  const [viewState, setViewState] = useState({
    latitude: component?.locationComponent.latitude,
    longitude: component?.locationComponent.longitude,
    width: "100%",
    height: "100%",
    zoom: 20,
  });
  const settingMap = useSelector(
    (state) => state.digitalTwinReducer.settingMap
  );

  const navControlStyle = {
    right: 10,
    top: 10,
  };
  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });
  const [baseMap, setBaseMap] = useState(
    settingMap?.styleMap
      ? { id: settingMap.styleMap.id, value: settingMap.styleMap.value }
      : {
        id: MAPBOX_STYLE.streets.id,
        value: MAPBOX_STYLE.streets.value,
      }
  );
  const [haveObjects, setHaveObjects] = useState(false);
  const [feature, setFeatures] = useState({});
  const [events, setEvents] = useState([]);
  // const [eventLines, setEventLines] = useState([]);
  const mapRef = useRef();

  const { data: dataEvents, error: errorEvents } = FetchEventsByComponentId(
    component?.id
  );
  useEffect(() => {
    dataEvents &&
      setEvents(
        dataEvents.filter(
          (it) =>
            it.instanceId === parseInt(instanceId) &&
            it.isGeographic === true
        )
      );
  }, [dataEvents, instanceId]);

  // Dispatch to redux
  const dispatch = useDispatch();

  // const [objectMarker, setObjectMarker] = useState([]);
  const [dataCopyCoordinates, setDataCopyCoordinates] = useState({
    lng: "",
    lat: "",
  });
  const locationToMove = localStorage.getItem("newLocationMove");

  const { data: typeEventsTypeComponents } = FetchTypeEventsTypeComponents(
    component?.pointTypeComponentId
  );

  const { MarkerParentComponent } = useCreateMarkerParents({ component });

  const { componenEventLines, parentComponentLines } =
    useCreateLinesComponentEventsRelation({
      component,
      parentComponent: MarkerParentComponent,
      componentEvents: events,
    });

  const handleClickDynamicEvent = (eventId) => {
    const event = {
      content: (
        <EventDetails
          eventId={eventId}
          fromObject={true}
          fromComponent={true}
        />
      ),
      title: "Event Details",
    };
    dispatch(setOperationDetails(event));
  };

  const handleClickMarker = (operationId) => {
    const operation = {
      content: <OperationDetails operationId={operationId} fromObject={true} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
  };

  const handlePasteCoordinates = () => {
    const newLocationMove = localStorage.getItem("newLocationMove");
    const parseNewLocationMove = JSON.parse(newLocationMove);
    if (parseNewLocationMove) {
      setDataCopyCoordinates({
        lng: parseNewLocationMove.lng || "",
        lat: parseNewLocationMove.lat || "",
      });
    }
  };

  useEffect(() => {
    if (!feature.geometry) return;
    setContentDialog({
      title: "Assign Event",
      description: (
        <CreateEvent
          feature={feature}
          pointComponentId={component?.id}
          closeModal={setHaveObjects}
          typeEventsTypeComponents={typeEventsTypeComponents}
        />
      ),
    });
    setHaveObjects(true);
  }, [feature, typeEventsTypeComponents, component?.id]);

  return (
    <Container
      maxWidth="l"
      sx={{
        width: "100%",
        height: "90%",
        padding: "0px 0px 24px 0px",
        position: "relative",
      }}
    >
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        disagree={() => { }}
        content={contentDialog}
        maxWidth={contentDialog.title === "Base Map" ? null : "500px"}
        minWidth={contentDialog.title === "Base Map" ? null : "300px"}
      />
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        style={{ width: "100%", height: 435, margin: "auto" }}
        mapStyle={baseMap.value}
        attributionControl={false}
        ref={mapRef}
        initialViewState={{
          latitude: viewState.latitude,
          longitude: viewState.longitude,
          zoom: viewState.zoom,
        }}
        onMove={useCallback((e) => {
          setViewState(e.viewState);
        }, [])}
        projection={"globe"}
        fog={fogStyle}
      >
        <DrawControl handleCreateComponent={setFeatures} />
        <BaseMapButton
          setHaveObjects={setHaveObjects}
          setContentDialog={setContentDialog}
          setBaseMap={setBaseMap}
          baseMap={baseMap}
          position={173}
        />
        {locationToMove !== null ? (
          dataCopyCoordinates.lng === "" && dataCopyCoordinates.lat === "" ? (
            <MovePoint
              from={"details"}
              moveNewLocation={dataCopyCoordinates}
              onPasteCoordinates={handlePasteCoordinates}
            />
          ) : (
            <SaveCoordinateButton
              from={"component"}
              id={component?.id}
              oldLocation={{
                latitude: component?.locationComponent.latitude,
                longitude: component?.locationComponent.longitude,
              }}
              longitude={dataCopyCoordinates.lng}
              latitude={dataCopyCoordinates.lat}
              setDataCopyCoordinates={setDataCopyCoordinates}
              setMovePoint={setMovePoint}
              pointsEventRelations={events}
            />
          )
        ) : (
          <></>
        )}
        <Marker
          key={component.id}
          longitude={component.locationComponent.longitude}
          latitude={component.locationComponent.latitude}
        >
          <div className="marker">
            <img
              src={component?.pointTypeComponent.icon}
              alt="marker"
              width={30}
              height={30}
            />
          </div>
        </Marker>
        {dataCopyCoordinates.lng !== "" && dataCopyCoordinates.lat !== "" && (
          <Marker
            latitude={Number(dataCopyCoordinates.lat)}
            longitude={Number(dataCopyCoordinates.lng)}
          >
            <div className="marker">
              <img
                src={component?.pointTypeComponent.icon}
                alt="marker"
                width={30}
                height={30}
                style={{
                  opacity: "0.5",
                }}
              />
            </div>
          </Marker>
        )}
        {MarkerParentComponent &&
          component &&
          showMarkers({ markers: MarkerParentComponent })}
        {componenEventLines && (
          <ShowLinesJoin
            id={"component-events-lines"}
            features={componenEventLines}
          />
        )}
        {parentComponentLines && (
          <ShowLinesJoin
            id={"marker-component-line"}
            features={parentComponentLines}
          />
        )}
        {events &&
          !errorEvents &&
          ShowEventMarkers(
            events,
            handleClickDynamicEvent,
            handleClickMarker,
            markedEvent
          )}
        <AttributionControl customAttribution="© Decimetrix® 2024" />
        <NavigationControl style={navControlStyle} />
      </Map>
    </Container>
  );
}
