import { useState, useEffect, useCallback } from "react";

// Helpers
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";

// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/eventsGroupBarChart";
import { urlsApiMg } from "utils/urlKeys";

export const useFindGroupBarChartEvent = ({ openDialog, events, loading }) => {
  const [charts, setCharts] = useState(null);
  const [groupBarCharts, setGroupBarChart] = useState(null);
  const [eventsFormat, setEventsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataGroupBarChart, error: errorDataGroupBarChart } = useSwr(
    urlsApiMg.dashboard.barChartGroup("events")
  );

  useEffect(() => {
    if (!errorDataGroupBarChart && dataGroupBarChart)
      setCharts(dataGroupBarChart);
  }, [dataGroupBarChart, errorDataGroupBarChart, openDialog, reloadCharts]);

  useEffect(() => {
    const eventsFormat = createRowsEvents({ events });
    setEventsFormat(eventsFormat);
  }, [events]);

  const getDataGroupBarChart = useCallback(async () => {
    if (!loading && eventsFormat) {
      const barCountsvalues = countByValues({
        charts,
        events: eventsFormat,
      });

      const barSumValues = sumValues({
        charts,
        events: eventsFormat,
      });

      setGroupBarChart([...barCountsvalues, ...barSumValues]);
    }
  }, [loading, eventsFormat, charts]);

  useEffect(() => {
    if (charts && events) {
      getDataGroupBarChart();
    }
  }, [charts, events, getDataGroupBarChart]);

  return { groupBarCharts };
};
