import CloseIcon from "@mui/icons-material/Close";
import { GREY_TRANS } from "utils/const";
import {
  CardContainer,
  SectionContainer,
  BodyDescription,
  DeleteButton,
  Title,
  Subtitle,
  Image,
  ImageDescription,
  ResizeRight,
  ResizeBottom,
  RestoreButton,
} from "./indexStyle";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import { updateOrderCard } from "services/Dashboard/orderCards";
import { useSWRConfig } from "swr";
import { config } from "config";
import handleAddGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridColumn";
import handleRemoveGridColumn from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridColumn";
import handleRemoveGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/removeGridRow";
import handleAddGridRow from "pages/Dashboard/helpers/HandlesResizeChartTemplate/addGridRow";

const TextChart = ({
  dataChart,
  currentStyle,
  template,
  index,
  setCurrentTemplate,
  responsiveWidth = null,
  responsiveHeight = null,
  restore = false,
  orderId,
  currentOrderName,
  currentOrderViews,
  setOpenDialogRestoreChart,
  setCurrentOrderViews,
  currentPositionId,
  handlerDeleteChartOnView,
  setDeleteChartDialog,
}) => {
  const role = localStorage.getItem("role");
  const adminCompanyId = parseInt(localStorage.getItem("adminCompanyId"));
  const userId = parseInt(localStorage.getItem("userId"));
  const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;
  const { mutate } = useSWRConfig();
  const { sections } = dataChart;
  const { setDragAndDroll } = useStoreDashboard();
  const chartsIconsShowOnOff = useStoreDashboard(
    (state) => state.chartsIconsShowOnOff
  );

  const handleRestoreChart = async () => {
    await updateOrderCard({
      id: orderId,
      body: {
        name: currentOrderName,
        position: [
          ...currentOrderViews,
          {
            id: dataChart._id,
            sizeChart: template.sizeChart,
          },
        ],
        _id: currentPositionId,
      },
      type: dataChart.from,
    });
    setCurrentOrderViews((current) => [
      ...current,
      {
        id: dataChart._id,
        sizeChart: template.sizeChart,
      },
    ]);
    mutate(urlOrders);
    setOpenDialogRestoreChart(false);
  };

  return (
    <CardContainer
      key={dataChart._id}
      backgroundColor={dataChart?.backgroundColor}
      borderColor={dataChart?.borderColor}
      width={
        template?.index
          ? currentStyle.width
          : responsiveWidth
          ? `${currentStyle.width - responsiveWidth}px`
          : `${currentStyle.width}px`
      }
      height={
        template?.index
          ? currentStyle.height
          : responsiveHeight
          ? `${currentStyle.height - responsiveHeight}px`
          : `${currentStyle.height}px`
      }
    >
      {role === "Administrador de compañía" &&
        !restore &&
        chartsIconsShowOnOff && (
          <DeleteButton
            right={3}
            onClick={() =>
              currentOrderName === "Design New View"
                ? setDeleteChartDialog(true)
                : handlerDeleteChartOnView()
            }
          >
            {" "}
            <CloseIcon
              sx={{
                color: GREY_TRANS,
                fontSize: "22px",
              }}
            />
          </DeleteButton>
        )}

      {role === "Administrador de compañía" &&
        restore &&
        chartsIconsShowOnOff && (
          <RestoreButton
            className="restore-card-button"
            onClick={() => handleRestoreChart()}
          >
            <RestartAltIcon
              sx={{
                color: GREY_TRANS,
              }}
            />
          </RestoreButton>
        )}

      {template && (
        <>
          <ResizeRight>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleAddGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleRemoveGridColumn({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeRight>
          <ResizeBottom>
            <div className="hover-area">
              <div className="hidden-div">
                <span
                  onClick={() => {
                    handleRemoveGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <LastPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    handleAddGridRow({
                      index,
                      setDragAndDroll,
                      setCurrentTemplate,
                    });
                  }}
                >
                  <FirstPageIcon
                    sx={{
                      color: "#03030353",
                      transition: "transform 0.3s ease-in-out",
                      transform: "rotate(-90deg)",
                      "&:hover": {
                        transform: "rotate(-90deg) scale(1.5)",
                      },
                    }}
                  />
                </span>
              </div>
            </div>
          </ResizeBottom>
        </>
      )}

      {/* Map sections */}
      {sections.map((section) => {
        const { style } = section;
        return (
          <SectionContainer
            className="chart-dashboard-element-array"
            key={section._id}
          >
            <Title
              fontSize={style?.titleFontSize}
              color={style?.titleFontColor}
              backgroundColor={style?.titleBackgroundColor}
              display={section.title ? "block" : "none"}
            >
              {section.title}
            </Title>
            <Subtitle
              fontSize={style?.subtitleFontSize}
              color={style?.subtitleFontColor}
              backgroundColor={style?.subtitleBackgroundColor}
              display={section.subtitle ? "block" : "none"}
            >
              {section.subtitle}
            </Subtitle>
            <BodyDescription
              fontSize={style?.bodyFontSize}
              color={style?.bodyFontColor}
              backgroundColor={style?.bodyBackgroundColor}
              display={section.body ? "block" : "none"}
            >
              {section.body}
            </BodyDescription>
            {section.image && (
              <Image
                src={section.image}
                alt={section.imageTitle || "image card"}
                display={section.image ? "block" : "none"}
                width={style?.imageWidth}
              />
            )}
            {/* Image description */}
            <ImageDescription
              fontSize={style?.imageFontSize}
              color={style?.imageFontColor}
              display={section.imageTitle ? "block" : "none"}
            >
              {section.imageTitle}
            </ImageDescription>
          </SectionContainer>
        );
      })}
    </CardContainer>
  );
};

export default TextChart;
