import { useState, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GRAY_SECOND } from "utils/const";
import { DragButton } from "./style";
// Components
import KpiChart from "components/Charts/KpiChart";
import TextChart from "components/Charts/TextChart";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import BarChart from "components/Charts/BarChart";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { LineChart } from "components/Charts/Line";
import DonutChart from "components/Charts/DonutChart";
import { getWrapperTemplate } from "../helpers/getWrapperTemplate";
import OpenDialog from "components/Dialogs/OpenDialog";
import PreviewKpiChart from "../../../components/Charts/KpiChart/PreviewKpiChart";
import PreviewLineChart from "components/Charts/Line/PreviewLineChart";
import PreviewTextChart from "components/Charts/TextChart/PreviewTextChart";
import PreviewBarChart from "components/Charts/BarChart/PreviewBarChart";
import PreviewDonutChart from "components/Charts/DonutChart/PreviewDonutChart";
import CustomButton from "components/Buttons/CustomButton";
import useStoreDashboard from "../Store";
import { deleteChartDoughnut } from "services/Dashboard/doughnutChart";
import { deleteChartBar } from "services/Dashboard/barChart";
import { deleteChartKpi } from "services/Dashboard/kpiChart";
import { deleteChartLine } from "services/Dashboard/lineChart";
import { deleteTimeSeriesChart } from "services/Dashboard/timeSeriesChart";
import { deleteTimeSeriesBarChart } from "services/Dashboard/timeSeriesBarChart";
import { deleteChartText } from "services/Dashboard/deleteChart";

import { deleteChartId } from "services/Dashboard/orderCards";
import { useSWRConfig } from "swr";
import { TimeSeriesChart } from "components/Charts/TimeSeriesChart";
import PreviewTimeSeriesChart from "components/Charts/TimeSeriesChart/PreviewTimeSeriesChart";
import { GroupBarChart } from "components/Charts/GroupBarChart";
import PreviewGroupBarChart from "components/Charts/GroupBarChart/PreviewGroupBarChart";
import { deleteChartBarGroup } from "services/Dashboard/groupBarChart";
import PreviewTimeSeriesBarChart from "components/Charts/TimeSeriesBarChart/PreviewTimeSeriesBarChart";
import { TimeSeriesBarChart } from "components/Charts/TimeSeriesBarChart";
import ImgChart from "components/Charts/ImgChart";
import { deleteImgChart } from "services/Dashboard/imgChart";
import { deleteChartGroupDoughnut } from "services/Dashboard/groupDoughnutChart";
import PreviewGroupDonutChart from "components/Charts/GroupDonutChart/PreviewGroupDonutChart";
import GroupDonutChart from "components/Charts/GroupDonutChart";
import { InformationCard } from "components/Charts/InformationCard";
import { urlsApiMg } from "utils/urlKeys";

const calculateMaxHeight = ({
  template,
  index,
  exclude = null,
  heightResponsive,
}) => {
  const templateWithSize = getWrapperTemplate({
    template,
    index,
    size: true,
  });

  if (template) {
    if (templateWithSize?.index) {
      const gridRowStart = getWrapperTemplate({
        template,
        index,
        size: false,
      }).gridRowStart;

      if (gridRowStart[5] !== exclude) {
        return (
          gridRowStart[5] * (template.sizeChart.height - heightResponsive) +
          gridRowStart[5] * 10 -
          10
        );
      } else {
        return gridRowStart[5] * template.sizeChart.height - heightResponsive;
      }
    } else {
      return template.sizeChart.height - heightResponsive;
    }
  }
};

const calculateMaxWidth = ({
  template,
  index,
  exclude = null,
  widthResponsive,
}) => {
  const templateWithSize = getWrapperTemplate({
    template,
    index,
    size: true,
  });

  if (template) {
    if (templateWithSize?.index) {
      const gridColumnStart = getWrapperTemplate({
        template,
        index,
        size: false,
      }).gridColumnStart;
      if (gridColumnStart[5] !== exclude) {
        return (
          gridColumnStart[5] * (template.sizeChart.width - widthResponsive) +
          gridColumnStart[5] * 10 -
          10
        );
      } else {
        return (
          gridColumnStart[5] * (template.sizeChart.width - widthResponsive)
        );
      }
    } else {
      return template.sizeChart.width - widthResponsive;
    }
  }
};

const chartTypeMappings = {
  textChart: {
    urlType: "charts-text",
    deleteFunction: deleteChartText,
  },
  doughnutChart: {
    urlType: "doughnut-chart",
    deleteFunction: deleteChartDoughnut,
  },
  kpiChart: {
    urlType: "kpi-chart",
    deleteFunction: deleteChartKpi,
  },
  barChart: {
    urlType: "bar-chart",
    deleteFunction: deleteChartBar,
  },
  lineChart: {
    urlType: "line-chart",
    deleteFunction: deleteChartLine,
  },
  timeSeriesChart: {
    urlType: "time-series-chart",
    deleteFunction: deleteTimeSeriesChart,
  },
  timeSeriesBarChart: {
    urlType: "time-series-bar-chart",
    deleteFunction: deleteTimeSeriesBarChart,
  },
  groupBarChart: {
    urlType: "bar-chart-group",
    deleteFunction: deleteChartBarGroup,
  },
  imgChart: {
    urlType: "img-chart",
    deleteFunction: deleteImgChart,
  },
  groupDoughnutChart: {
    urlType: "group-doughnut-chart",
    deleteFunction: deleteChartGroupDoughnut,
  },
};

const SortableItem = ({
  allDataKpiCharts,
  item,
  setCurrentOrderViews,
  currentOrderName,
  dragActive,
  index,
  template,
  setCurrentTemplate,
  currentPositionId,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item._id });
  const { mutate } = useSWRConfig();
  const urlOrders = urlsApiMg.dashboard.orderCards;
  const [responsiveWidth, setResponsiveWidth] = useState(0);
  const [responsiveHeight, setResponsiveHeight] = useState(0);
  const setReloadCharts = useStoreDashboard((state) => state.setReloadCharts);
  const chartsIconsShowOnOff = useStoreDashboard(
    (state) => state.chartsIconsShowOnOff
  );

  useEffect(() => {
    const updateColumns = () => {
      if (window.innerWidth >= 3000) {
        setResponsiveWidth(10);
        setResponsiveHeight(3);
      } else if (window.innerWidth >= 2800) {
        setResponsiveWidth(12);
        setResponsiveHeight(4);
      } else if (window.innerWidth >= 2600) {
        setResponsiveWidth(14);
        setResponsiveHeight(5);
      } else if (window.innerWidth >= 2400) {
        setResponsiveWidth(15);
        setResponsiveHeight(5);
      } else if (window.innerWidth >= 2200) {
        setResponsiveWidth(20);
        setResponsiveHeight(10);
      } else if (window.innerWidth >= 2100) {
        setResponsiveWidth(30);
        setResponsiveHeight(15);
      } else if (window.innerWidth >= 2000) {
        setResponsiveWidth(40);
        setResponsiveHeight(20);
      } else if (window.innerWidth >= 1900) {
        setResponsiveWidth(50);
        setResponsiveHeight(25);
      } else if (window.innerWidth >= 1800) {
        setResponsiveWidth(60);
        setResponsiveHeight(25);
      } else if (window.innerWidth >= 1700) {
        setResponsiveWidth(70);
        setResponsiveHeight(30);
      } else if (window.innerWidth >= 1600) {
        setResponsiveWidth(80);
        setResponsiveHeight(30);
      } else if (window.innerWidth >= 1500) {
        setResponsiveWidth(90);
        setResponsiveHeight(30);
      } else if (window.innerWidth >= 1400) {
        setResponsiveWidth(105);
        setResponsiveHeight(35);
      } else if (window.innerWidth >= 1300) {
        setResponsiveWidth(120);
        setResponsiveHeight(35);
      } else if (window.innerWidth >= 1200) {
        setResponsiveWidth(138);
        setResponsiveHeight(35);
      } else if (window.innerWidth >= 1100) {
        setResponsiveWidth(148);
        setResponsiveHeight(40);
      } else if (window.innerWidth >= 1000) {
        setResponsiveWidth(160);
        setResponsiveHeight(45);
      } else if (window.innerWidth >= 900) {
        setResponsiveWidth(175);
        setResponsiveHeight(55);
      } else if (window.innerWidth >= 800) {
        setResponsiveWidth(190);
        setResponsiveHeight(70);
      } else if (window.innerWidth >= 700) {
        setResponsiveWidth(205);
        setResponsiveHeight(80);
      } else if (window.innerWidth >= 600) {
        setResponsiveWidth(215);
        setResponsiveHeight(80);
      } else if (window.innerWidth >= 500) {
        setResponsiveWidth(220);
        setResponsiveHeight(80);
      } else {
        setResponsiveWidth(210);
        setResponsiveHeight(105);
      }
    };
    updateColumns();
    window.addEventListener("resize", updateColumns);
    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  const templateStyle = {
    ...getWrapperTemplate({ template, index, size: false }),
    display: "flex",
    boxSizing: "border-box",
    transform:
      "translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))",
    transformOrigin: "0 0",
    touchAction: "manipulation",

    transform: CSS.Transform.toString(transform),
    transition,
    maxWidth: `${calculateMaxWidth({
      template,
      index,
      exclude: "1",
      widthResponsive: responsiveWidth,
    })}px`,
    maxHeight: `${calculateMaxHeight({
      template,
      index,
      exclude: "1",
      heightResponsive: responsiveHeight,
    })}px`,
    minWidth: `${calculateMaxWidth({
      template,
      index,
      exclude: "1",
      widthResponsive: responsiveWidth,
    })}px`,
    minHeight: `${calculateMaxHeight({
      template,
      index,
      exclude: "1",
      heightResponsive: responsiveHeight,
    })}px`,
    border: "none",
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.5 : 1,
    borderRadius: "5px",
    position: "relative",
  };

  // const sortableStyle = {
  //   transform: CSS.Transform.toString(transform),
  //   transition,
  //   width: "100%",
  //   border: "none",
  //   zIndex: isDragging ? "100" : "auto",
  //   opacity: isDragging ? 0.5 : 1,
  //   borderRadius: "5px",
  //   position: "relative",
  // };

  // const [sizeCard, setSizeCard] = useState(false);

  const [currentStyle, setCurrentStyle] = useState({
    width: item.sizeChart.width,
    height: item.sizeChart.height,
  });

  const [chartEditDialog, setChartEditDialog] = useState(false);
  const [deleteChartDialog, setDeleteChartDialog] = useState(false);
  const [currentChart, setCurrentChart] = useState({});

  useEffect(() => {
    setCurrentStyle({
      width: item.sizeChart.width,
      height: item.sizeChart.height,
    });
  }, [item]);

  useEffect(() => {
    if (!dragActive) {
      setCurrentOrderViews((current) => {
        return current.map((elm) => {
          if (elm.id === item._id) {
            return {
              ...elm,
              sizeChart: {
                width: currentStyle.width,
                height: currentStyle.height,
              },
            };
          } else {
            return elm;
          }
        });
      });
    }
  }, [currentStyle.width, currentStyle.height]);

  const handleEditChart = ({ chart }) => {
    setChartEditDialog(true);
    setCurrentChart(chart);
  };

  const handlerDeleteChart = async () => {
    const getUrlType = (chartType) =>
      chartTypeMappings[chartType]?.urlType || null;
    const getDeleteFunction = (chartType) =>
      chartTypeMappings[chartType]?.deleteFunction || null;

    const urlType = getUrlType(item.chartType);
    const urlChart = urlsApiMg.dashboard.chartType(
      urlType,
      item.from === "allTypes" ? item.fromType : item.from
    );

    const deleteFunction = getDeleteFunction(item.chartType);
    const { data } = deleteFunction
      ? await deleteFunction({ id: item._id })
      : { data: null };

    const { data: dataDeleteChart } = await deleteChartId({
      userId: item.userId,
      type: item.from,
      fromType: item?.fromType,
      body: {
        chartId: item._id,
      },
    });
    mutate(urlOrders);
    mutate(urlChart);

    if (data && dataDeleteChart) {
      setReloadCharts();
    }
    setDeleteChartDialog(false);
  };

  const handlerDeleteChartOnView = async () => {
    const { data: dataDeleteChart } = await deleteChartId({
      userId: item.userId,
      type: item.from,
      body: {
        chartId: item._id,
        deleteChartInViewId: currentPositionId,
      },
    });
    setCurrentOrderViews((current) =>
      current.filter((elm) => elm.id !== item._id)
    );
    if (dataDeleteChart) {
      mutate(urlOrders);
      setReloadCharts();
    }
  };

  const formatChartType = (type) => {
    if (type === undefined || !type.length) return "";
    return type === "kpiChart"
      ? "KPI Chart"
      : type === "lineChart"
      ? "Line Chart"
      : type === "timeSeriesChart"
      ? "Time Series Chart"
      : type === "timeSeriesBarChart"
      ? "Time Series Bar Chart"
      : type === "groupBarChart"
      ? "Group Bar Chart"
      : type === "textChart"
      ? "Text Chart"
      : type === "barChart"
      ? "Bar Chart"
      : type === "doughnutChart"
      ? "Doughnut Chart"
      : type === "groupDoughnutChart"
      ? "Group Doughnut Chart"
      : "";
  };

  return (
    <>
      {currentStyle.width &&
        currentStyle.height &&
        (template ? (
          <li
            style={{
              ...templateStyle,
              textAlign: "-webkit-match-parent",
              unicodeBidi: "isolate",
            }}
            id="chart"
          >
            <OpenDialog
              openDialog={deleteChartDialog}
              setOpenDialog={setDeleteChartDialog}
              content={{
                title: "Delete Chart In All Views ⚠",
                description: (
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        marginBottom: "28px",
                      }}
                    >
                      If you delete this chart from the design view, it will be
                      deleted in all existing views.
                    </p>
                    <CustomButton
                      text="Delete Chart"
                      margin={0}
                      onClick={handlerDeleteChart}
                    />
                  </div>
                ),
              }}
              minWidth="30%"
              maxWidth="30%"
            />
            <OpenDialog
              openDialog={chartEditDialog}
              setOpenDialog={setChartEditDialog}
              content={{
                title: "Chart Edit Mode - ".concat(
                  formatChartType(item.chartType)
                ),
                description:
                  item.chartType === "kpiChart" ? (
                    <PreviewKpiChart
                      allDataKpiCharts={allDataKpiCharts}
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ) : item.chartType === "lineChart" ? (
                    <PreviewLineChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ) : item.chartType === "timeSeriesChart" ? (
                    <PreviewTimeSeriesChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ) : item.chartType === "timeSeriesBarChart" ? (
                    <PreviewTimeSeriesBarChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ) : item.chartType === "groupBarChart" ? (
                    <PreviewGroupBarChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ) : item.chartType === "groupDoughnutChart" ? (
                    <PreviewGroupDonutChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ) : item.chartType === "textChart" ? (
                    <PreviewTextChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ) : item.chartType === "barChart" ? (
                    <PreviewBarChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ) : (
                    <PreviewDonutChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={calculateMaxHeight({
                        template,
                        index,
                        heightResponsive: responsiveHeight,
                      })}
                      maxWidth={calculateMaxWidth({
                        template,
                        index,
                        widthResponsive: responsiveWidth,
                      })}
                    />
                  ),
              }}
              minWidth="80%"
              maxWidth="90%"
              height="90%"
            />
            <span
              ref={setNodeRef}
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                width: "100%",
                height: "100%",
              }}
            >
              {chartsIconsShowOnOff && (
                <DragButton
                  cursor={"grab"}
                  ml={3}
                  backgroundColor={item?.chartStyle?.backgroundColor}
                  {...listeners}
                  {...attributes}
                >
                  <DragIndicatorIcon
                    sx={{
                      color: item?.chartStyle?.secondColor,
                      fontSize: "2rem",
                    }}
                  />
                </DragButton>
              )}

              {item.chartType !== "imgChart" && chartsIconsShowOnOff && (
                <DragButton
                  ml={22}
                  backgroundColor={item?.chartStyle?.backgroundColor}
                  cursor={"pointer"}
                  onClick={() => handleEditChart({ chart: item })}
                >
                  <EditNoteIcon
                    sx={{
                      color: item?.chartStyle?.secondColor,
                      fontSize: "1.8rem",
                      mt: "1px",
                    }}
                  />
                </DragButton>
              )}

              {currentOrderName === "Design New View" &&
                item.chartType !== "imgChart" && (
                  <InformationCard
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}

              {currentOrderName !== "Design New View" &&
                item.chartType === "kpiChart" && (
                  <KpiChart
                    allDataKpiCharts={allDataKpiCharts}
                    key={item._id}
                    dataKpi={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {currentOrderName !== "Design New View" &&
                item.chartType === "textChart" && (
                  <TextChart
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {currentOrderName !== "Design New View" &&
                item.chartType === "barChart" && (
                  <BarChart
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {currentOrderName !== "Design New View" &&
                item.chartType === "lineChart" && (
                  <LineChart
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {currentOrderName !== "Design New View" &&
                item.chartType === "doughnutChart" && (
                  <DonutChart
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {currentOrderName !== "Design New View" &&
                item.chartType === "groupDoughnutChart" && (
                  <GroupDonutChart
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {currentOrderName !== "Design New View" &&
                item.chartType === "timeSeriesChart" && (
                  <TimeSeriesChart
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {currentOrderName !== "Design New View" &&
                item.chartType === "timeSeriesBarChart" && (
                  <TimeSeriesBarChart
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {currentOrderName !== "Design New View" &&
                item.chartType === "groupBarChart" && (
                  <GroupBarChart
                    key={item._id}
                    dataChart={item}
                    borderColor={GRAY_SECOND}
                    currentStyle={currentStyle}
                    index={index}
                    setCurrentOrderViews={setCurrentOrderViews}
                    template={getWrapperTemplate({
                      template,
                      index,
                      size: true,
                    })}
                    setCurrentTemplate={setCurrentTemplate}
                    responsiveWidth={responsiveWidth}
                    responsiveHeight={responsiveHeight}
                    currentPositionId={currentPositionId}
                    currentOrderName={currentOrderName}
                    handlerDeleteChartOnView={handlerDeleteChartOnView}
                    setDeleteChartDialog={setDeleteChartDialog}
                  />
                )}
              {item.chartType === "imgChart" && (
                <ImgChart
                  key={item._id}
                  dataChart={item}
                  borderColor={GRAY_SECOND}
                  currentStyle={currentStyle}
                  index={index}
                  setCurrentOrderViews={setCurrentOrderViews}
                  template={getWrapperTemplate({
                    template,
                    index,
                    size: true,
                  })}
                  setCurrentTemplate={setCurrentTemplate}
                  responsiveWidth={responsiveWidth}
                  responsiveHeight={responsiveHeight}
                  currentPositionId={currentPositionId}
                  currentOrderName={currentOrderName}
                  handlerDeleteChartOnView={handlerDeleteChartOnView}
                  setDeleteChartDialog={setDeleteChartDialog}
                />
              )}
            </span>
          </li>
        ) : (
          <div>
            {/* <OpenDialog
              openDialog={chartEditDialog}
              setOpenDialog={setChartEditDialog}
              content={{
                title: "Preview Chart Editing",
                description:
                  item.chartType === "kpiChart" ? (
                    <PreviewKpiChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={currentStyle.height}
                      maxWidth={currentStyle.width}
                    />
                  ) : item.chartType === "lineChart" ? (
                    <PreviewLineChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={currentStyle.height}
                      maxWidth={currentStyle.width}
                    />
                  ) : item.chartType === "textChart" ? (
                    <PreviewTextChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxWidth={currentStyle.width}
                      maxHeight={currentStyle.height}
                    />
                  ) : item.chartType === "barChart" ? (
                    <PreviewBarChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={currentStyle.height}
                      maxWidth={currentStyle.width}
                    />
                  ) : (
                    <PreviewDonutChart
                      dataChart={currentChart}
                      setChartEditDialog={setChartEditDialog}
                      maxHeight={currentStyle.height}
                      maxWidth={currentStyle.width}
                    />
                  ),
              }}
              minWidth="90%"
              maxWidth="100%"
            />
            {currentStyle.width && currentStyle.height && (
              <ResizeItem
                item={item}
                setCurrentStyle={setCurrentStyle}
                currentStyle={currentStyle}
                sizeCard={sizeCard}
              >
                <div ref={setNodeRef} style={sortableStyle}>
                  <DragButton
                    cursor={"grab"}
                    ml={3}
                    backgroundColor={item?.chartStyle?.backgroundColor}
                    {...listeners}
                    {...attributes}
                  >
                    <DragIndicatorIcon
                      sx={{
                        color: item?.chartStyle?.secondColor,
                        fontSize: "2rem",
                      }}
                    />
                  </DragButton>
                  <DragButton
                    ml={22}
                    backgroundColor={item?.chartStyle?.backgroundColor}
                    cursor={"pointer"}
                    onClick={() => setSizeCard(!sizeCard)}
                  >
                    <CropIcon
                      sx={{
                        color: item?.chartStyle?.secondColor,
                        fontSize: "1.8rem",
                        mt: "1px",
                      }}
                    />
                  </DragButton>
                  <DragButton
                    ml={sizeCard ? 108 : 42}
                    backgroundColor={item?.chartStyle?.backgroundColor}
                    cursor={"pointer"}
                    onClick={() => handleEditChart({ chart: item })}
                  >
                    <EditNoteIcon
                      sx={{
                        color: item?.chartStyle?.secondColor,
                        fontSize: "1.8rem",
                        mt: "1px",
                      }}
                    />
                  </DragButton>

                  {item.chartType === "kpiChart" && (
                    <KpiChart
                      key={item._id}
                      dataKpi={item}
                      borderColor={GRAY_SECOND}
                      currentStyle={currentStyle}
                      setCurrentOrderViews={setCurrentOrderViews}
                      
                      currentPositionId={currentPositionId}
                    />
                  )}
                  {item.chartType === "textChart" && (
                    <TextChart
                      key={item._id}
                      dataChart={item}
                      borderColor={GRAY_SECOND}
                      currentStyle={currentStyle}
                      setCurrentOrderViews={setCurrentOrderViews}
                      
                      currentPositionId={currentPositionId}
                    />
                  )}
                  {item.chartType === "barChart" && (
                    <BarChart
                      key={item._id}
                      dataChart={item}
                      borderColor={GRAY_SECOND}
                      currentStyle={currentStyle}
                      setCurrentOrderViews={setCurrentOrderViews}
                      
                      currentPositionId={currentPositionId}
                    />
                  )}
                  {item.chartType === "lineChart" && (
                    <LineChart
                      key={item._id}
                      dataChart={item}
                      borderColor={GRAY_SECOND}
                      currentStyle={currentStyle}
                      setCurrentOrderViews={setCurrentOrderViews}
                      
                      currentPositionId={currentPositionId}
                    />
                  )}
                  {item.chartType === "doughnutChart" && (
                    <DonutChart
                      key={item._id}
                      dataChart={item}
                      borderColor={GRAY_SECOND}
                      currentStyle={currentStyle}
                      setCurrentOrderViews={setCurrentOrderViews}
                      
                      currentPositionId={currentPositionId}
                    />
                  )}
                </div>
              </ResizeItem>
            )} */}
          </div>
        ))}
    </>
  );
};

export default SortableItem;
