import { ContainerPreviewChart, CardBarPreview } from "./indexStyle";
import CustomButton from "components/Buttons/CustomButton";
import { Bar } from "react-chartjs-2";
import { useSetCurrentStyle } from "./Hooks/useSetCurrentStyle";
import { createRgbaColor } from "pages/Dashboard/helpers/createRgbaColor";
import { useState } from "react";
import { getGradient } from "helpers/getGradient";
import {
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  THIRD_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";
import { fixedDecimalNumber } from "pages/Dashboard/helpers/fixedDecimalNumber";

function isHexadecimal(color) {
  const hexPattern = /^#([A-Fa-f0-9]{3}){1,2}$|^#([A-Fa-f0-9]{6})$/;
  return hexPattern.test(color);
}

const PreviewGroupBarChart = ({
  dataChart,
  setChartEditDialog,
  maxHeight,
  maxWidth,
}) => {
  const {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
    handleCreateConstantLine,
    handleDeleteConstantLine,
    constantLine,
    setConstantLine,
    allStacks,
    setAllStacks,
    currentStackName,
    setCurrentStackName,
    updateAllStacks,
  } = useSetCurrentStyle({ dataChart, setChartEditDialog });

  const [currentSelectLine, setCurrentSelectLine] = useState(null);

  return (
    <ContainerPreviewChart heightChart={maxHeight} widthChart={maxWidth}>
      <div className="section-chart-button">
        <div className="preview-chart">
          <div className="chart">
            <CardBarPreview>
              <Bar
                data={{
                  labels: currentStyleLineDataChart.data.labels,
                  datasets: currentStyleLineDataChart.data.datasets.map(
                    (item) => {
                      if (
                        item.hasOwnProperty("colorsByLabel") &&
                        item.colorsByLabel.length > 0
                      ) {
                        return {
                          ...item,
                          data: item.data.map((data) => {
                            return typeof data === "object"
                              ? data?.value
                              : data;
                          }),
                          backgroundColor: item.dataGradient.display
                            ? item.colorsByLabelGradient
                            : item.colorsByLabel,
                        };
                      }
                      if (item?.opacity) {
                        return {
                          ...item,
                          backgroundColor: isHexadecimal(item.backgroundColor)
                            ? createRgbaColor({
                                hexadecimalColor: item.backgroundColor,
                                opacity: item.opacity,
                              })
                            : item.backgroundColor.match(/^rgba/)
                            ? item.backgroundColor.replace(
                                /(\d+,\s*\d+,\s*\d+,\s*)(\d?\.?\d+)/,
                                `$1${item.opacity}`
                              )
                            : item.backgroundColor,
                        };
                      }
                      return item;
                    }
                  ),
                }}
                options={{
                  responsive: true,
                  indexAxis: currentStyleLineDataChart.currentHorizontalBar
                    ? "y"
                    : "x",
                  layout: {
                    padding: {
                      left: 10,
                      right: 20,
                      top: 10,
                      bottom: 10,
                    },
                  },
                  plugins: {
                    datalabels: {
                      display: function (context) {
                        return context.dataset?.datalabels?.displayTotal;
                      },
                      align: function (context) {
                        return context.dataset?.datalabels?.positionTotal;
                      },
                      anchor: function (context) {
                        return context.dataset?.datalabels?.positionTotal;
                      },
                      formatter: (value, context) => {
                        const dataset = context.dataset;
                        const dataIndex = context.dataIndex;
                        const totalDataPoints = context.dataset.data.length;
                        if (
                          !currentStyleLineDataChart.currentDisplayShowCerosTotal &&
                          value === 0
                        ) {
                          return null;
                        }
                        if (dataset.constantValue !== null) {
                          if (dataIndex === totalDataPoints - 1) {
                            return dataset.constantValue === 0
                              ? null
                              : dataset.constantValue;
                          } else {
                            return null;
                          }
                        }
                        return fixedDecimalNumber({
                          number: value,
                          numDecimals: dataset.datalabels?.numDecimals,
                        });
                      },
                      color: function (context) {
                        return context.dataset?.datalabels?.colorTotal;
                      },
                      backgroundColor: function (context) {
                        return context.dataset?.datalabels
                          ?.backgroundColorTotal;
                      },
                      borderWidth: 1,
                      borderColor: function (context) {
                        return context.dataset?.datalabels
                          ?.backgroundColorTotal;
                      },
                      borderRadius: 3,
                      font: function (context) {
                        return {
                          size: context.dataset?.datalabels?.fontSizeTotal,
                        };
                      },
                      offset: function (context) {
                        return context.dataset?.datalabels?.offsetTotal;
                      },
                    },
                    title: {
                      display: true,
                      color: currentStyleLineDataChart.currentTitleColor,
                      text: currentStyleLineDataChart.currentNameLineChart,
                      font: {
                        size: currentStyleLineDataChart.currentTitleFontSize,
                        family: "Montserrat",
                      },
                      padding: {
                        top: 20,
                      },
                    },
                    legend: {
                      display: true,
                      onClick: function (e, legendItem, legend) {
                        const index = legendItem.datasetIndex;
                        const ci = legend.chart;
                        const meta = ci.getDatasetMeta(index);
                        meta.hidden =
                          meta.hidden === null
                            ? !ci.data.datasets[index].hidden
                            : null;
                        ci.update();
                      },
                      labels: {
                        generateLabels: function (chart) {
                          return currentStyleLineDataChart.data.datasets.map(
                            (dataset, i) => {
                              const meta = chart.getDatasetMeta(i);
                              return {
                                text: dataset.alias,
                                fillStyle: dataset.backgroundColor,
                                hidden: meta.hidden,
                                lineCap: dataset.borderCapStyle,
                                lineDash: dataset.borderDash,
                                lineDashOffset: dataset.borderDashOffset,
                                lineJoin: dataset.borderJoinStyle,
                                lineWidth: dataset.borderWidth,
                                strokeStyle: dataset.borderColor,
                                pointStyle: dataset.pointStyle,
                                datasetIndex: i,
                                fontStyle: meta.hidden ? "italic" : "normal",
                                fontColor: meta.hidden ? "#999" : "#666",
                              };
                            }
                          );
                        },
                      },
                    },
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        label: function (context) {
                          return `${context.dataset.alias} : ${context.raw}`;
                        },
                      },
                      titleFont: {
                        size: currentStyleLineDataChart.currentTitleTooltipFontSize,
                      },
                      bodyFont: {
                        size: currentStyleLineDataChart.currentBodyTooltipFontSize,
                      },
                      mode: "nearest",
                      intersect: true,
                      titleAlign: "center",
                      displayColors: false,
                    },
                  },
                  animation: {
                    duration: 1500,
                    easing: "easeInOutQuart",
                  },
                  scales: {
                    x: {
                      grid: {
                        display: currentStyleLineDataChart.currentDisplayAxisX,
                        color: createRgbaColor({
                          hexadecimalColor:
                            currentStyleLineDataChart?.currentAxisXColor,
                        }),
                      },
                      ticks: {
                        callback: function (value) {
                          let label = this.getLabelForValue(value);
                          return label.trim().split(" ");
                        },
                        maxRotation: 0,
                        minRotation: 0,
                        font: {
                          size: currentStyleLineDataChart.currentLabelsXFontSize,
                        },
                        color: currentStyleLineDataChart.currentLabelsXColor,
                        padding: currentStyleLineDataChart.currentPaddingAxisX,
                      },
                      title: {
                        display: currentStyleLineDataChart.currentDisplayTitleX,
                        text: currentStyleLineDataChart.currentTitleAxisX,
                        font: {
                          size: currentStyleLineDataChart.currentTitleXFontSize,
                        },
                        color: currentStyleLineDataChart.currentTitleXColor,
                        padding: {
                          top: -currentStyleLineDataChart.currentPaddingAxisX,
                        },
                      },
                    },
                    y: {
                      beginAtZero:
                        currentStyleLineDataChart.currentStatusBeginAtZero,
                      min: currentStyleLineDataChart.currentMinValueAxis,
                      max: currentStyleLineDataChart.currentMaxValueAxis,
                      grid: {
                        display: currentStyleLineDataChart.currentDisplayAxisY,
                        color: createRgbaColor({
                          hexadecimalColor:
                            currentStyleLineDataChart?.currentAxisYColor,
                        }),
                      },
                      ticks: {
                        callback: function (value) {
                          let label = this.getLabelForValue(value);
                          return label.trim().split(" ");
                        },
                        maxRotation: 0,
                        minRotation: 0,
                        stepSize: currentStyleLineDataChart?.currentStepSizeY,
                        font: {
                          size: currentStyleLineDataChart.currentLabelsYFontSize,
                        },
                        color: currentStyleLineDataChart.currentLabelsYColor,
                        padding: currentStyleLineDataChart.currentPaddingAxisY,
                      },
                      title: {
                        display: currentStyleLineDataChart.currentDisplayTitleY,
                        text: currentStyleLineDataChart.currentTitleAxisY,
                        font: {
                          size: currentStyleLineDataChart.currentTitleYFontSize,
                        },
                        color: currentStyleLineDataChart.currentTitleYColor,
                        padding: {
                          bottom:
                            -currentStyleLineDataChart.currentPaddingAxisY,
                        },
                      },
                    },
                  },
                }}
              />
            </CardBarPreview>
          </div>
        </div>
        <div className="container-update">
          <CustomButton
            text={"Update Chart"}
            margin={0}
            onClick={handleUpdateChart}
          />
        </div>
      </div>
      <div className="editing-tools">
        <div className="groupe-styles">
          <div className="sections">
            <h3>Title</h3>
            <div className="section-style">
              <input
                placeholder="Title Name"
                className="input-title"
                type="text"
                value={currentStyleLineDataChart.currentNameLineChart}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentNameLineChart: e.target.value,
                  }))
                }
              />
            </div>
            <br />
            <div className="section-style">
              <p>Title Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyleLineDataChart.currentTitleColor}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentTitleColor: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Title Size</p>
              <div>
                <h4>{currentStyleLineDataChart.currentTitleFontSize} px</h4>
                <input
                  value={currentStyleLineDataChart.currentTitleFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={60}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentTitleFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Data Series</h3>
            <div className="section-style">
              <p>Select Data Series</p>
              <select
                value={currentSelectLine}
                onChange={(e) => setCurrentSelectLine(e.target.value)}
              >
                {[
                  { _id: "select", alias: "Select" },
                  ...currentStyleLineDataChart.data.datasets,
                ].map((elm, index) => {
                  return (
                    <>
                      <option key={index} value={elm._id}>
                        {elm.alias}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="section-style">
              <p>Horizontal Bar</p>
              <input
                checked={currentStyleLineDataChart.currentHorizontalBar}
                type="checkbox"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentHorizontalBar:
                      !currentStyleLineDataChart.currentHorizontalBar,
                  }))
                }
              />
            </div>
            {constantLine.create && (
              <>
                <div className="section-style">
                  <p>Reference Line</p>
                  <br />
                  <br />
                  <input
                    placeholder="Reference Line Name"
                    className="input-title"
                    type="text"
                    value={constantLine.label}
                    onChange={(e) => {
                      setConstantLine((current) => ({
                        ...current,
                        label: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="section-style">
                  <p>Reference Line Color</p>
                  <input
                    className="alias"
                    type="color"
                    value={constantLine.borderColorHexadecimal}
                    onChange={(e) => {
                      setConstantLine((current) => ({
                        ...current,
                        borderColorHexadecimal: e.target.value,
                        borderColor: createRgbaColor({
                          hexadecimalColor: e.target.value,
                          opacity: 0.1,
                        }),
                      }));
                    }}
                  />
                </div>
                <div className="section-style">
                  <p>Reference Line Value</p>
                  <input
                    value={constantLine.constantValue}
                    type="number"
                    onChange={(e) => {
                      setConstantLine((current) => ({
                        ...current,
                        constantValue: parseFloat(e.target.value),
                      }));
                    }}
                  />
                </div>
                <div className="section-style">
                  <CustomButton
                    text={`Create ${constantLine?.label}`}
                    disabled={
                      constantLine.label === "" ||
                      constantLine.constantValue === 0
                        ? true
                        : false
                    }
                    primaryColor={
                      constantLine.label === "" ||
                      constantLine.constantValue === 0
                        ? VISIBILITY_OFF_ICON
                        : SECOND_COLOR
                    }
                    secondaryColor={
                      constantLine.label === "" ||
                      constantLine.constantValue === 0
                        ? VISIBILITY_OFF_ICON
                        : THIRD_COLOR
                    }
                    margin={0}
                    onClick={() => handleCreateConstantLine()}
                  />
                </div>
              </>
            )}
            <div className="section-style">
              <CustomButton
                text={constantLine.create ? "Cancel" : "Create Reference Line"}
                primaryColor={constantLine.create ? DELETE_COLOR : THIRD_COLOR}
                secondaryColor={
                  constantLine.create ? HOVER_DELETE_COLOR : SECOND_COLOR
                }
                margin={0}
                onClick={() =>
                  setConstantLine({
                    create: !constantLine.create,
                    label: "",
                    borderColor: PRIMARY_COLOR,
                    borderColorHexadecimal: PRIMARY_COLOR,
                    constantValue: 0,
                  })
                }
              />
            </div>
            {currentSelectLine &&
              currentSelectLine !== "select" &&
              currentStyleLineDataChart.data.datasets.map((dataset, index) => {
                if (dataset._id === currentSelectLine) {
                  if (dataset.type === "line") {
                    return (
                      <>
                        <div key={index} className="section-style">
                          <p>Series Name</p>
                          <br />
                          <br />
                          <input
                            placeholder="Series Name"
                            className="input-title"
                            type="text"
                            value={dataset.alias}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        alias: e.target.value,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <br />
                        <div className="divider">
                          <span className="divider-title">
                            Reference Line Options
                          </span>
                        </div>
                        <div className="section-style">
                          <p>Show Line</p>
                          <input
                            className="alias"
                            checked={dataset.showLine}
                            type="checkbox"
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        showLine: !dataset.showLine,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="section-style">
                          <p>Show Series</p>
                          <input
                            className="alias"
                            checked={!dataset.hidden}
                            type="checkbox"
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        hidden: !dataset.hidden,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>

                        <div className="section-style">
                          <p>Fill Out</p>
                          <input
                            className="alias"
                            checked={dataset.fill}
                            type="checkbox"
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        fill: !dataset.fill,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="section-style">
                          <p>Reference Value</p>
                          <input
                            value={dataset.constantValue}
                            type="number"
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        data: new Array(elm.data.length).fill(
                                          !parseFloat(e.target.value)
                                            ? 0
                                            : parseFloat(e.target.value)
                                        ),
                                        constantValue: !parseFloat(
                                          e.target.value
                                        )
                                          ? 0
                                          : parseFloat(e.target.value),
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="section-style">
                          <p>Border Color</p>
                          <input
                            className="alias"
                            type="color"
                            value={dataset.borderColor}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        borderColor: e.target.value,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="section-style">
                          <p>Fill Color</p>
                          <input
                            className="alias"
                            type="color"
                            value={dataset.backgroundColorHexadecimal}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        backgroundColorHexadecimal:
                                          e.target.value,
                                        backgroundColor: createRgbaColor({
                                          hexadecimalColor: e.target.value,
                                        }),
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="section-style">
                          <p>Line Width</p>
                          <div>
                            <h4>{dataset.borderWidth} px</h4>
                            <input
                              value={dataset.borderWidth}
                              type="range"
                              step={1}
                              min={1}
                              max={15}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          borderWidth: e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="section-style">
                          <p>Line Dash Type</p>
                          <div>
                            <h4>{dataset.borderDash[0]} px</h4>
                            <input
                              value={dataset.borderDash[0]}
                              type="range"
                              step={1}
                              min={0}
                              max={50}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          borderDash:
                                            e.target.value === 0
                                              ? []
                                              : [
                                                  e.target.value,
                                                  e.target.value,
                                                ],
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="section-style">
                          <p>Line Dash Offset</p>
                          <div>
                            <h4>{dataset.borderDashOffset} px</h4>
                            <input
                              value={dataset.borderDashOffset}
                              type="range"
                              step={1}
                              min={-50}
                              max={50}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          borderDashOffset: e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="section-style">
                          <p>Line Dash End Style</p>
                          <select
                            value={dataset.borderCapStyle}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        borderCapStyle: e.target.value,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          >
                            {["butt", "round", "square"].map((elm, index) => {
                              return (
                                <option key={index} value={elm}>
                                  {elm}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="section-style">
                          <p>Border Join Style</p>
                          <select
                            value={dataset.borderJoinStyle}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        borderJoinStyle: e.target.value,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          >
                            {["miter", "round", "bevel"].map((elm, index) => {
                              return (
                                <option key={index} value={elm}>
                                  {elm}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="divider">
                          <span className="divider-title">Values Total</span>
                        </div>

                        <div className="sections">
                          <div className="section-color-conditional">
                            <p>View Total</p>
                            <div>
                              <input
                                checked={dataset.datalabels.displayTotal}
                                type="checkbox"
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            datalabels: {
                                              ...elm.datalabels,
                                              displayTotal:
                                                !elm.datalabels.displayTotal,
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                              <input
                                className="alias"
                                type="color"
                                value={dataset.datalabels.colorTotal}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            datalabels: {
                                              ...elm.datalabels,
                                              displayTotal: true,
                                              colorTotal: e.target.value,
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {(currentStyleLineDataChart.currentDisplayTotal ||
                          dataset.datalabels.displayTotal) && (
                          <>
                            <div className="sections">
                              <div className="section-style">
                                <p>Total Size</p>
                                <div>
                                  <h4>
                                    {currentStyleLineDataChart.currentDisplayTotal
                                      ? currentStyleLineDataChart.currentTotalFontSize
                                      : dataset.datalabels.fontSizeTotal}{" "}
                                    px
                                  </h4>
                                  <input
                                    value={
                                      currentStyleLineDataChart.currentDisplayTotal
                                        ? currentStyleLineDataChart.currentTotalFontSize
                                        : dataset.datalabels.fontSizeTotal
                                    }
                                    type="range"
                                    step={1}
                                    min={10}
                                    max={50}
                                    onChange={(e) => {
                                      setCurrentStyleLineDataChart(
                                        (current) => {
                                          const newDatasets =
                                            current.data.datasets.map((elm) => {
                                              if (
                                                elm._id === currentSelectLine
                                              ) {
                                                return {
                                                  ...elm,
                                                  datalabels: {
                                                    ...elm.datalabels,
                                                    fontSizeTotal:
                                                      e.target.value,
                                                  },
                                                };
                                              }
                                              return elm;
                                            });
                                          return {
                                            ...current,
                                            data: {
                                              ...current.data,
                                              datasets: newDatasets,
                                            },
                                          };
                                        }
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="sections">
                              <div className="section-style">
                                <p>Position Total</p>
                                <select
                                  value={dataset.datalabels.positionTotal}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              datalabels: {
                                                ...elm.datalabels,
                                                positionTotal: e.target.value,
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                >
                                  <option value="end">Top</option>
                                  <option value="center">Center</option>
                                  <option value="start">Down</option>
                                </select>
                              </div>
                            </div>
                            <div className="sections">
                              <div className="section-color-conditional">
                                <p>Total Background</p>
                                <div>
                                  <input
                                    checked={
                                      dataset.datalabels.displayBackgroundTotal
                                    }
                                    type="checkbox"
                                    onChange={(e) => {
                                      setCurrentStyleLineDataChart(
                                        (current) => {
                                          const newDatasets =
                                            current.data.datasets.map((elm) => {
                                              if (
                                                elm._id === currentSelectLine
                                              ) {
                                                return {
                                                  ...elm,
                                                  datalabels: {
                                                    ...elm.datalabels,
                                                    displayBackgroundTotal:
                                                      !elm.datalabels
                                                        .displayBackgroundTotal,
                                                    backgroundColorTotal: elm
                                                      .datalabels
                                                      .displayBackgroundTotal
                                                      ? "rgba(255, 255, 255, 0)"
                                                      : "#2df137",
                                                  },
                                                };
                                              }
                                              return elm;
                                            });
                                          return {
                                            ...current,
                                            data: {
                                              ...current.data,
                                              datasets: newDatasets,
                                            },
                                          };
                                        }
                                      );
                                    }}
                                  />
                                  <input
                                    className="alias"
                                    type="color"
                                    value={
                                      dataset.datalabels.backgroundColorTotal
                                    }
                                    onChange={(e) => {
                                      setCurrentStyleLineDataChart(
                                        (current) => {
                                          const newDatasets =
                                            current.data.datasets.map((elm) => {
                                              if (
                                                elm._id === currentSelectLine
                                              ) {
                                                return {
                                                  ...elm,
                                                  datalabels: {
                                                    ...elm.datalabels,
                                                    displayBackgroundTotal: true,
                                                    backgroundColorTotal:
                                                      e.target.value,
                                                  },
                                                };
                                              }
                                              return elm;
                                            });
                                          return {
                                            ...current,
                                            data: {
                                              ...current.data,
                                              datasets: newDatasets,
                                            },
                                          };
                                        }
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="section-style">
                              <p>Offset</p>
                              <div>
                                <h4>{dataset.datalabels.offsetTotal} px</h4>
                                <input
                                  value={dataset.datalabels.offsetTotal}
                                  type="range"
                                  step={1}
                                  min={-100}
                                  max={100}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              datalabels: {
                                                ...elm.datalabels,
                                                offsetTotal: e.target.value,
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="section-style">
                              <p>Num Decimals</p>
                              <input
                                value={dataset.datalabels.numDecimals}
                                type="number"
                                min={0}
                                max={100}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            datalabels: {
                                              ...elm.datalabels,
                                              numDecimals: parseFloat(
                                                e.target.value
                                              ),
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                                placeholder={"Decimals"}
                              />
                            </div>
                          </>
                        )}

                        {dataset.constantValue && (
                          <CustomButton
                            text={`Delete ${dataset.alias}`}
                            primaryColor={DELETE_COLOR}
                            secondaryColor={HOVER_DELETE_COLOR}
                            onClick={() =>
                              handleDeleteConstantLine({ id: dataset._id })
                            }
                            margin={0}
                          />
                        )}
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div key={index} className="section-style">
                          <p>Series Name</p>
                          <br />
                          <br />
                          <input
                            placeholder="Series Name"
                            className="input-title"
                            type="text"
                            value={dataset.alias}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        alias: e.target.value,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <br />
                        <div className="divider">
                          <span className="divider-title">
                            BarGroup Options
                          </span>
                        </div>
                        <div className="section-style">
                          <p>Show Series</p>
                          <input
                            className="alias"
                            checked={!dataset.hidden}
                            type="checkbox"
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        hidden: !dataset.hidden,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="section-style">
                          <p>Border Color</p>
                          <input
                            className="alias"
                            type="color"
                            value={dataset.borderColor}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        borderColor: e.target.value,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="section-style">
                          <p>Line Border Width</p>
                          <div>
                            <h4>{dataset.borderWidth} px</h4>
                            <input
                              value={dataset.borderWidth}
                              type="range"
                              step={1}
                              min={1}
                              max={15}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          borderWidth: e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="section-style">
                          <p>Fill Color</p>
                          <input
                            className="alias"
                            type="color"
                            value={dataset.backgroundColorHexadecimal}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        backgroundColorHexadecimal:
                                          e.target.value,
                                        backgroundColor: createRgbaColor({
                                          hexadecimalColor: e.target.value,
                                          opacity: dataset.opacity,
                                        }),
                                        colorsHexadecimalByLabel:
                                          currentStyleLineDataChart.data.labels.map(
                                            (item) => {
                                              return (item = e.target.value);
                                            }
                                          ),
                                        colorsByLabel:
                                          currentStyleLineDataChart.data.labels.map(
                                            (item) => {
                                              return (item = createRgbaColor({
                                                hexadecimalColor:
                                                  e.target.value,
                                                opacity: dataset.opacity,
                                              }));
                                            }
                                          ),
                                        colorsByLabelGradient: function (
                                          context
                                        ) {
                                          const chart = context.chart;
                                          const { ctx, chartArea } = chart;
                                          if (!chartArea) {
                                            return;
                                          }

                                          return currentStyleLineDataChart.data.labels.map(
                                            (color) => {
                                              return getGradient({
                                                ctx,
                                                chartArea,
                                                color: e.target.value,
                                                colorStop:
                                                  elm.dataGradient.colorStop,
                                              });
                                            }
                                          );
                                        },
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        {!dataset.dataGradient.display && (
                          <div className="section-style">
                            <p>Opacity</p>
                            <div>
                              <h4>{dataset?.opacity}</h4>
                              <input
                                value={dataset?.opacity}
                                type="range"
                                step={0.1}
                                min={0}
                                max={1}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            opacity: e.target.value,
                                            backgroundColor: isHexadecimal(
                                              dataset.backgroundColor
                                            )
                                              ? createRgbaColor({
                                                  hexadecimalColor:
                                                    dataset.backgroundColor,
                                                  opacity: e.target.value,
                                                })
                                              : dataset.backgroundColor.match(
                                                  /^rgba/
                                                )
                                              ? dataset.backgroundColor.replace(
                                                  /(\d+,\s*\d+,\s*\d+,\s*)(\d?\.?\d+)/,
                                                  `$1${e.target.value}`
                                                )
                                              : dataset.backgroundColor,
                                            colorsByLabel:
                                              dataset.colorsByLabel.map(
                                                (item) => {
                                                  return (item = isHexadecimal(
                                                    item.backgroundColor
                                                  )
                                                    ? createRgbaColor({
                                                        hexadecimalColor: item,
                                                        opacity: e.target.value,
                                                      })
                                                    : item.match(/^rgba/)
                                                    ? item.replace(
                                                        /(\d+,\s*\d+,\s*\d+,\s*)(\d?\.?\d+)/,
                                                        `$1${e.target.value}`
                                                      )
                                                    : item);
                                                }
                                              ),
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <div className="section-style">
                          <p>Gradient</p>
                          <input
                            className="alias"
                            checked={dataset.dataGradient.display}
                            type="checkbox"
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      if (e.target.checked) {
                                        return {
                                          ...elm,
                                          dataGradient: {
                                            ...elm.dataGradient,
                                            display: true,
                                          },
                                          backgroundColor: function (context) {
                                            const chart = context.chart;
                                            const { ctx, chartArea } = chart;
                                            if (!chartArea) {
                                              return;
                                            }
                                            return getGradient({
                                              ctx,
                                              chartArea,
                                              color:
                                                elm.backgroundColorHexadecimal,
                                              colorStop:
                                                elm.dataGradient.colorStop,
                                            });
                                          },
                                          opacity: null,
                                        };
                                      } else {
                                        return {
                                          ...elm,
                                          dataGradient: {
                                            ...elm.dataGradient,
                                            display: false,
                                          },
                                          backgroundColor: createRgbaColor({
                                            hexadecimalColor:
                                              elm.backgroundColorHexadecimal,
                                            opacity: dataset.opacity,
                                          }),
                                          opacity: "0.5",
                                        };
                                      }
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>

                        {dataset.dataGradient.display && (
                          <>
                            <div className="section-style">
                              <p>Level Top</p>
                              <div>
                                <h4>{dataset.dataGradient.colorStop[0]} px</h4>
                                <input
                                  value={dataset.dataGradient.colorStop[0]}
                                  type="range"
                                  step={0.1}
                                  min={0}
                                  max={1}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            const newColorStop = [
                                              ...elm.dataGradient.colorStop,
                                            ];
                                            newColorStop[0] = parseFloat(
                                              e.target.value
                                            );
                                            return {
                                              ...elm,
                                              dataGradient: {
                                                ...elm.dataGradient,
                                                colorStop: newColorStop,
                                              },
                                              backgroundColor: function (
                                                context
                                              ) {
                                                const chart = context.chart;
                                                const { ctx, chartArea } =
                                                  chart;
                                                if (!chartArea) {
                                                  return;
                                                }
                                                return getGradient({
                                                  ctx,
                                                  chartArea,
                                                  color:
                                                    dataset.backgroundColorHexadecimal,
                                                  colorStop:
                                                    dataset.dataGradient
                                                      .colorStop,
                                                });
                                              },
                                              colorsByLabelGradient: function (
                                                context
                                              ) {
                                                const chart = context.chart;
                                                const { ctx, chartArea } =
                                                  chart;
                                                if (!chartArea) {
                                                  return;
                                                }

                                                return elm.colorsHexadecimalByLabel.map(
                                                  (color) => {
                                                    return getGradient({
                                                      ctx,
                                                      chartArea,
                                                      color: color,
                                                      colorStop: newColorStop,
                                                    });
                                                  }
                                                );
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="section-style">
                              <p>Level Med Top</p>
                              <div>
                                <h4>{dataset.dataGradient.colorStop[1]} px</h4>
                                <input
                                  value={dataset.dataGradient.colorStop[1]}
                                  type="range"
                                  step={0.1}
                                  min={0}
                                  max={1}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            const newColorStop = [
                                              ...elm.dataGradient.colorStop,
                                            ];
                                            newColorStop[1] = parseFloat(
                                              e.target.value
                                            );
                                            return {
                                              ...elm,
                                              dataGradient: {
                                                ...elm.dataGradient,
                                                colorStop: newColorStop,
                                              },
                                              backgroundColor: function (
                                                context
                                              ) {
                                                const chart = context.chart;
                                                const { ctx, chartArea } =
                                                  chart;
                                                if (!chartArea) {
                                                  return;
                                                }
                                                return getGradient({
                                                  ctx,
                                                  chartArea,
                                                  color:
                                                    dataset.backgroundColorHexadecimal,
                                                  colorStop:
                                                    dataset.dataGradient
                                                      .colorStop,
                                                });
                                              },

                                              colorsByLabelGradient: function (
                                                context
                                              ) {
                                                const chart = context.chart;
                                                const { ctx, chartArea } =
                                                  chart;
                                                if (!chartArea) {
                                                  return;
                                                }

                                                return elm.colorsHexadecimalByLabel.map(
                                                  (color) => {
                                                    return getGradient({
                                                      ctx,
                                                      chartArea,
                                                      color: color,
                                                      colorStop: newColorStop,
                                                    });
                                                  }
                                                );
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="section-style">
                              <p>Level med Bottom</p>
                              <div>
                                <h4>{dataset.dataGradient.colorStop[2]} px</h4>
                                <input
                                  value={dataset.dataGradient.colorStop[2]}
                                  type="range"
                                  step={0.1}
                                  min={0}
                                  max={1}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          const newColorStop = [
                                            ...elm.dataGradient.colorStop,
                                          ];
                                          newColorStop[2] = parseFloat(
                                            e.target.value
                                          );
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              dataGradient: {
                                                ...elm.dataGradient,
                                                colorStop: newColorStop,
                                              },
                                              backgroundColor: function (
                                                context
                                              ) {
                                                const chart = context.chart;
                                                const { ctx, chartArea } =
                                                  chart;
                                                if (!chartArea) {
                                                  return;
                                                }
                                                return getGradient({
                                                  ctx,
                                                  chartArea,
                                                  color:
                                                    dataset.backgroundColorHexadecimal,
                                                  colorStop:
                                                    dataset.dataGradient
                                                      .colorStop,
                                                });
                                              },

                                              colorsByLabelGradient: function (
                                                context
                                              ) {
                                                const chart = context.chart;
                                                const { ctx, chartArea } =
                                                  chart;
                                                if (!chartArea) {
                                                  return;
                                                }

                                                return elm.colorsHexadecimalByLabel.map(
                                                  (color) => {
                                                    return getGradient({
                                                      ctx,
                                                      chartArea,
                                                      color: color,
                                                      colorStop: newColorStop,
                                                    });
                                                  }
                                                );
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="section-style">
                              <p>Level Bottom</p>
                              <div>
                                <h4>{dataset.dataGradient.colorStop[3]} px</h4>
                                <input
                                  value={dataset.dataGradient.colorStop[3]}
                                  type="range"
                                  step={0.1}
                                  min={0}
                                  max={1}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          const newColorStop = [
                                            ...elm.dataGradient.colorStop,
                                          ];
                                          newColorStop[3] = parseFloat(
                                            e.target.value
                                          );
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              dataGradient: {
                                                ...elm.dataGradient,
                                                colorStop: newColorStop,
                                              },
                                              backgroundColor: function (
                                                context
                                              ) {
                                                const chart = context.chart;
                                                const { ctx, chartArea } =
                                                  chart;
                                                if (!chartArea) {
                                                  return;
                                                }
                                                return getGradient({
                                                  ctx,
                                                  chartArea,
                                                  color:
                                                    dataset.backgroundColorHexadecimal,
                                                  colorStop:
                                                    dataset.dataGradient
                                                      .colorStop,
                                                });
                                              },

                                              colorsByLabelGradient: function (
                                                context
                                              ) {
                                                const chart = context.chart;
                                                const { ctx, chartArea } =
                                                  chart;
                                                if (!chartArea) {
                                                  return;
                                                }

                                                return elm.colorsHexadecimalByLabel.map(
                                                  (color) => {
                                                    return getGradient({
                                                      ctx,
                                                      chartArea,
                                                      color: color,
                                                      colorStop: newColorStop,
                                                    });
                                                  }
                                                );
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {dataset?.colorsByLabel.length > 0 && (
                          <>
                            <div className="divider">
                              <span className="divider-title">
                                Colors By Main Category
                              </span>
                            </div>
                            {currentStyleLineDataChart.data.labels.map(
                              (elm, index) => {
                                return (
                                  <div className="section-style">
                                    <p>{elm}</p>
                                    <input
                                      className="alias"
                                      type="color"
                                      value={
                                        dataset.colorsHexadecimalByLabel[index]
                                      }
                                      onChange={(e) => {
                                        setCurrentStyleLineDataChart(
                                          (current) => {
                                            const newDatasets =
                                              current.data.datasets.map(
                                                (elm) => {
                                                  if (
                                                    elm._id ===
                                                    currentSelectLine
                                                  ) {
                                                    const newColorsByLabel = [
                                                      ...elm.colorsByLabel,
                                                    ];
                                                    const newColorsHexadecimalByLabel =
                                                      [
                                                        ...elm.colorsHexadecimalByLabel,
                                                      ];
                                                    newColorsByLabel[index] =
                                                      createRgbaColor({
                                                        hexadecimalColor:
                                                          e.target.value,
                                                        opacity:
                                                          dataset.opacity,
                                                      });

                                                    newColorsHexadecimalByLabel[
                                                      index
                                                    ] = e.target.value;

                                                    return {
                                                      ...elm,
                                                      colorsByLabel:
                                                        newColorsByLabel,
                                                      colorsHexadecimalByLabel:
                                                        newColorsHexadecimalByLabel,
                                                      colorsByLabelGradient:
                                                        function (context) {
                                                          const chart =
                                                            context.chart;
                                                          const {
                                                            ctx,
                                                            chartArea,
                                                          } = chart;
                                                          if (!chartArea) {
                                                            return;
                                                          }

                                                          return newColorsHexadecimalByLabel.map(
                                                            (color) => {
                                                              return getGradient(
                                                                {
                                                                  ctx,
                                                                  chartArea,
                                                                  color: color,
                                                                  colorStop:
                                                                    elm
                                                                      .dataGradient
                                                                      .colorStop,
                                                                }
                                                              );
                                                            }
                                                          );
                                                        },
                                                    };
                                                  }
                                                  return elm;
                                                }
                                              );
                                            return {
                                              ...current,
                                              data: {
                                                ...current.data,
                                                datasets: newDatasets,
                                              },
                                            };
                                          }
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                        <br />

                        <div className="divider">
                          <span className="divider-title">Stacks</span>
                        </div>
                        {allStacks &&
                          Object.keys(allStacks).map((key) => (
                            <div className="section-stacks">
                              <div key={key} className="stacks-types">
                                <section>
                                  <p>{key}</p>
                                </section>
                                <input
                                  name={key}
                                  checked={key === dataset.stack}
                                  type="checkbox"
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            if (e.target.checked) {
                                              return {
                                                ...elm,
                                                stack: e.target.name,
                                              };
                                            } else {
                                              return {
                                                ...elm,
                                                stack: dataset._id,
                                              };
                                            }
                                          }
                                          return elm;
                                        });

                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                    e.target.checked
                                      ? setAllStacks((current) =>
                                          updateAllStacks(current, {
                                            stack: e.target.name,
                                            alias: dataset.alias,
                                          })
                                        )
                                      : setAllStacks((current) =>
                                          updateAllStacks(
                                            current,
                                            {
                                              stack: e.target.name,
                                              alias: dataset.alias,
                                            },
                                            null,
                                            null,
                                            e.target.name
                                          )
                                        );
                                  }}
                                />
                              </div>
                              <ul>
                                {allStacks[key].map((item, index) =>
                                  item === dataset.alias ? (
                                    <li className="select-text" key={index}>
                                      {item}
                                    </li>
                                  ) : (
                                    <li key={index}>{item}</li>
                                  )
                                )}
                              </ul>
                            </div>
                          ))}
                        <br />
                        <div className="sections">
                          <div className="section-style">
                            <div className="section-stacks-button">
                              <input
                                placeholder="Stack Name"
                                className="input-title"
                                type="text"
                                value={currentStackName}
                                onChange={(e) => {
                                  setCurrentStackName(e.target.value);
                                }}
                              />
                              <button
                                onClick={() => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            stack: currentStackName,
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                  setAllStacks((current) =>
                                    updateAllStacks(
                                      current,
                                      dataset,
                                      currentStackName,
                                      dataset.alias
                                    )
                                  );
                                  setCurrentStackName("");
                                }}
                              >
                                Add Stack
                              </button>
                            </div>
                          </div>
                        </div>
                        <br />

                        <div className="divider">
                          <span className="divider-title">Values Total</span>
                        </div>

                        <div className="sections">
                          <div className="section-color-conditional">
                            <p>View Total</p>
                            <div>
                              <input
                                checked={dataset.datalabels.displayTotal}
                                type="checkbox"
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            datalabels: {
                                              ...elm.datalabels,
                                              displayTotal:
                                                !elm.datalabels.displayTotal,
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                              <input
                                className="alias"
                                type="color"
                                value={dataset.datalabels.colorTotal}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            datalabels: {
                                              ...elm.datalabels,
                                              displayTotal: true,
                                              colorTotal: e.target.value,
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {(currentStyleLineDataChart.currentDisplayTotal ||
                          dataset.datalabels.displayTotal) && (
                          <>
                            <div className="sections">
                              <div className="section-style">
                                <p>Total Size</p>
                                <div>
                                  <h4>
                                    {currentStyleLineDataChart.currentDisplayTotal
                                      ? currentStyleLineDataChart.currentTotalFontSize
                                      : dataset.datalabels.fontSizeTotal}{" "}
                                    px
                                  </h4>
                                  <input
                                    value={
                                      currentStyleLineDataChart.currentDisplayTotal
                                        ? currentStyleLineDataChart.currentTotalFontSize
                                        : dataset.datalabels.fontSizeTotal
                                    }
                                    type="range"
                                    step={1}
                                    min={10}
                                    max={50}
                                    onChange={(e) => {
                                      setCurrentStyleLineDataChart(
                                        (current) => {
                                          const newDatasets =
                                            current.data.datasets.map((elm) => {
                                              if (
                                                elm._id === currentSelectLine
                                              ) {
                                                return {
                                                  ...elm,
                                                  datalabels: {
                                                    ...elm.datalabels,
                                                    fontSizeTotal:
                                                      e.target.value,
                                                  },
                                                };
                                              }
                                              return elm;
                                            });
                                          return {
                                            ...current,
                                            data: {
                                              ...current.data,
                                              datasets: newDatasets,
                                            },
                                          };
                                        }
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="sections">
                              <div className="section-style">
                                <p>Position Total</p>
                                <select
                                  value={dataset.datalabels.positionTotal}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              datalabels: {
                                                ...elm.datalabels,
                                                positionTotal: e.target.value,
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                >
                                  <option value="end">Top</option>
                                  <option value="center">Center</option>
                                  <option value="start">Down</option>
                                </select>
                              </div>
                            </div>
                            <div className="sections">
                              <div className="section-color-conditional">
                                <p>Total Background</p>
                                <div>
                                  <input
                                    checked={
                                      dataset.datalabels.displayBackgroundTotal
                                    }
                                    type="checkbox"
                                    onChange={(e) => {
                                      setCurrentStyleLineDataChart(
                                        (current) => {
                                          const newDatasets =
                                            current.data.datasets.map((elm) => {
                                              if (
                                                elm._id === currentSelectLine
                                              ) {
                                                return {
                                                  ...elm,
                                                  datalabels: {
                                                    ...elm.datalabels,
                                                    displayBackgroundTotal:
                                                      !elm.datalabels
                                                        .displayBackgroundTotal,
                                                    backgroundColorTotal: elm
                                                      .datalabels
                                                      .displayBackgroundTotal
                                                      ? "rgba(255, 255, 255, 0)"
                                                      : "#2df137",
                                                  },
                                                };
                                              }
                                              return elm;
                                            });
                                          return {
                                            ...current,
                                            data: {
                                              ...current.data,
                                              datasets: newDatasets,
                                            },
                                          };
                                        }
                                      );
                                    }}
                                  />
                                  <input
                                    className="alias"
                                    type="color"
                                    value={
                                      dataset.datalabels.backgroundColorTotal
                                    }
                                    onChange={(e) => {
                                      setCurrentStyleLineDataChart(
                                        (current) => {
                                          const newDatasets =
                                            current.data.datasets.map((elm) => {
                                              if (
                                                elm._id === currentSelectLine
                                              ) {
                                                return {
                                                  ...elm,
                                                  datalabels: {
                                                    ...elm.datalabels,
                                                    displayBackgroundTotal: true,
                                                    backgroundColorTotal:
                                                      e.target.value,
                                                  },
                                                };
                                              }
                                              return elm;
                                            });
                                          return {
                                            ...current,
                                            data: {
                                              ...current.data,
                                              datasets: newDatasets,
                                            },
                                          };
                                        }
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="section-style">
                              <p>Offset</p>
                              <div>
                                <h4>{dataset.datalabels.offsetTotal} px</h4>
                                <input
                                  value={dataset.datalabels.offsetTotal}
                                  type="range"
                                  step={1}
                                  min={-100}
                                  max={100}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              datalabels: {
                                                ...elm.datalabels,
                                                offsetTotal: e.target.value,
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="section-style">
                              <p>Num Decimals</p>
                              <input
                                value={dataset.datalabels.numDecimals}
                                type="number"
                                min={0}
                                max={100}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            datalabels: {
                                              ...elm.datalabels,
                                              numDecimals: parseFloat(
                                                e.target.value
                                              ),
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                                placeholder={"Number"}
                              />
                            </div>
                          </>
                        )}
                      </>
                    );
                  }
                }
              })}
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Total</h3>
            <div className="section-color-conditional">
              <p>View Total</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayTotal}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTotal:
                        !currentStyleLineDataChart.currentDisplayTotal,
                      currentDisplayBackgroundTotal: false,
                      currentTotalBackground: "rgba(255, 255, 255, 0)",
                      currentTotalFontColor:
                        currentStyleLineDataChart.currentDisplayTotal
                          ? "#EEEEEE"
                          : "#000000",
                      data: {
                        ...current.data,
                        datasets: current.data.datasets.map((elm) => {
                          if (elm.type !== "line") {
                            return {
                              ...elm,
                              datalabels: {
                                ...elm.datalabels,
                                displayTotal:
                                  !currentStyleLineDataChart.currentDisplayTotal,
                                colorTotal: elm.datalabels.displayTotal
                                  ? "#EEEEEE"
                                  : "#000000",
                                displayBackgroundTotal: false,
                                backgroundColorTotal: "rgba(255, 255, 255, 0)",
                              },
                            };
                          }
                          return elm;
                        }),
                      },
                    }))
                  }
                />
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentTotalFontColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTotal: true,
                      currentTotalFontColor: e.target.value,
                      data: {
                        ...current.data,
                        datasets: current.data.datasets.map((elm) => {
                          if (elm.type !== "line") {
                            return {
                              ...elm,
                              datalabels: {
                                ...elm.datalabels,
                                displayTotal: true,
                                colorTotal: e.target.value,
                              },
                            };
                          }
                          return elm;
                        }),
                      },
                    }))
                  }
                />
              </div>
            </div>

            {currentStyleLineDataChart.currentDisplayTotal && (
              <>
                <div className="section-style">
                  <p>Total Size</p>
                  <div>
                    <h4>{currentStyleLineDataChart.currentTotalFontSize} px</h4>
                    <input
                      value={currentStyleLineDataChart.currentTotalFontSize}
                      type="range"
                      step={1}
                      min={10}
                      max={50}
                      onChange={(e) =>
                        setCurrentStyleLineDataChart((current) => ({
                          ...current,
                          currentTotalFontSize: e.target.value,
                          data: {
                            ...current.data,
                            datasets: current.data.datasets.map((elm) => {
                              return {
                                ...elm,
                                datalabels: {
                                  ...elm.datalabels,
                                  fontSizeTotal: e.target.value,
                                },
                              };
                            }),
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="section-style">
                  <p>Position Total</p>
                  <select
                    value={currentStyleLineDataChart.currentTotalPosition}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentTotalPosition: e.target.value,
                        data: {
                          ...current.data,
                          datasets: current.data.datasets.map((elm) => {
                            return {
                              ...elm,
                              datalabels: {
                                ...elm.datalabels,
                                positionTotal: e.target.value,
                              },
                            };
                          }),
                        },
                      }))
                    }
                  >
                    <option value="end">Top</option>
                    <option value="center">Center</option>
                    <option value="start">Down</option>
                  </select>
                </div>
                <div className="section-color-conditional">
                  <p>Total Background</p>
                  <div>
                    <input
                      checked={
                        currentStyleLineDataChart.currentDisplayBackgroundTotal
                      }
                      type="checkbox"
                      onChange={(e) =>
                        setCurrentStyleLineDataChart((current) => ({
                          ...current,
                          currentDisplayBackgroundTotal:
                            !currentStyleLineDataChart.currentDisplayBackgroundTotal,
                          currentTotalBackground:
                            currentStyleLineDataChart.currentDisplayBackgroundTotal
                              ? "rgba(255, 255, 255, 0)"
                              : "#2df137",
                          data: {
                            ...current.data,
                            datasets: current.data.datasets.map((elm) => {
                              return {
                                ...elm,
                                datalabels: {
                                  ...elm.datalabels,
                                  displayBackgroundTotal:
                                    !elm.datalabels.displayBackgroundTotal,
                                  backgroundColorTotal: elm.datalabels
                                    .displayBackgroundTotal
                                    ? "rgba(255, 255, 255, 0)"
                                    : "#2df137",
                                },
                              };
                            }),
                          },
                        }))
                      }
                    />
                    <input
                      className="alias"
                      type="color"
                      value={currentStyleLineDataChart.currentTotalBackground}
                      onChange={(e) =>
                        setCurrentStyleLineDataChart((current) => ({
                          ...current,
                          currentDisplayBackgroundTotal: true,
                          currentTotalBackground: e.target.value,
                          data: {
                            ...current.data,
                            datasets: current.data.datasets.map((elm) => {
                              return {
                                ...elm,
                                datalabels: {
                                  ...elm.datalabels,
                                  displayBackgroundTotal: true,
                                  backgroundColorTotal: e.target.value,
                                },
                              };
                            }),
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="section-style">
                  <p>Show Ceros</p>
                  <input
                    checked={
                      currentStyleLineDataChart.currentDisplayShowCerosTotal
                    }
                    type="checkbox"
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentDisplayShowCerosTotal:
                          !currentStyleLineDataChart.currentDisplayShowCerosTotal,
                      }))
                    }
                  />
                </div>
                <div className="section-style">
                  <p>Num Decimals</p>
                  <input
                    value={currentStyleLineDataChart.currentTotalNumDecimals}
                    type="number"
                    min={0}
                    max={100}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentTotalNumDecimals: e.target.value,
                        data: {
                          ...current.data,
                          datasets: current.data.datasets.map((elm) => {
                            return {
                              ...elm,
                              datalabels: {
                                ...elm.datalabels,
                                numDecimals: parseFloat(e.target.value),
                              },
                            };
                          }),
                        },
                      }))
                    }
                    placeholder={"Decimals"}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Y Axis</h3>
            <div className="section-style">
              <input
                placeholder="Axis Name"
                className="input-title"
                type="text"
                value={currentStyleLineDataChart.currentTitleAxisY}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentTitleAxisY: e.target.value,
                  }))
                }
              />
            </div>
            <br />
            <div className="section-color-conditional">
              <p>View Title</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayTitleY}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTitleY:
                        !currentStyleLineDataChart.currentDisplayTitleY,
                      currentTitleYColor:
                        currentStyleLineDataChart.currentDisplayTitleY
                          ? "#EEEEEE"
                          : "#000000",
                    }))
                  }
                />

                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentTitleYColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTitleY: true,
                      currentTitleYColor: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            {currentStyleLineDataChart.currentDisplayTitleY && (
              <div className="section-style">
                <p>Title Size</p>
                <div>
                  <h4>{currentStyleLineDataChart.currentTitleYFontSize} px</h4>
                  <input
                    value={currentStyleLineDataChart.currentTitleYFontSize}
                    type="range"
                    step={1}
                    min={10}
                    max={40}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentTitleYFontSize: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            )}
            <div className="section-style">
              <p>Labels Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyleLineDataChart.currentLabelsYColor}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentLabelsYColor: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Labels Size</p>
              <div>
                <h4>{currentStyleLineDataChart.currentLabelsYFontSize} px</h4>
                <input
                  value={currentStyleLineDataChart.currentLabelsYFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={40}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentLabelsYFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Labels Offset</p>
              <div>
                <h4>{currentStyleLineDataChart.currentPaddingAxisY} px</h4>
                <input
                  value={currentStyleLineDataChart.currentPaddingAxisY}
                  type="range"
                  step={1}
                  min={5}
                  max={100}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentPaddingAxisY: parseFloat(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-color-conditional">
              <p>View Axis</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayAxisY}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayAxisY:
                        !currentStyleLineDataChart.currentDisplayAxisY,
                      currentAxisYColor:
                        currentStyleLineDataChart.currentDisplayAxisY
                          ? "#EEEEEE"
                          : "#000000",
                    }))
                  }
                />
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentAxisYColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayAxisY: true,
                      currentAxisYColor: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Origin At Zero</p>
              <input
                checked={currentStyleLineDataChart.currentStatusBeginAtZero}
                type="checkbox"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentStatusBeginAtZero:
                      !currentStyleLineDataChart.currentStatusBeginAtZero,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Min Axis Value</p>
              <input
                value={currentStyleLineDataChart.currentMinValueAxis}
                type="number"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentMinValueAxis: parseFloat(e.target.value),
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Max Axis Value</p>
              <input
                value={currentStyleLineDataChart.currentMaxValueAxis}
                type="number"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentMaxValueAxis: parseFloat(e.target.value),
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Step Size</p>
              <input
                value={currentStyleLineDataChart.currentStepSizeY}
                type="number"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentStepSizeY: parseFloat(e.target.value),
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>X Axis</h3>
            <div className="section-style">
              <input
                placeholder="Axis Name"
                className="input-title"
                type="text"
                value={currentStyleLineDataChart.currentTitleAxisX}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentTitleAxisX: e.target.value,
                  }))
                }
              />
            </div>
            <br />
            <div className="section-color-conditional">
              <p>View Title</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayTitleX}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTitleX:
                        !currentStyleLineDataChart.currentDisplayTitleX,
                      currentTitleXColor:
                        currentStyleLineDataChart.currentDisplayTitleX
                          ? "#EEEEEE"
                          : "#000000",
                    }))
                  }
                />
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentTitleXColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTitleX: true,
                      currentTitleXColor: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            {currentStyleLineDataChart.currentDisplayTitleX && (
              <div className="section-style">
                <p>Title Size</p>
                <div>
                  <h4>{currentStyleLineDataChart.currentTitleXFontSize} px</h4>
                  <input
                    value={currentStyleLineDataChart.currentTitleXFontSize}
                    type="range"
                    step={1}
                    min={10}
                    max={40}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentTitleXFontSize: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            )}
            <div className="section-style">
              <p>Labels Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyleLineDataChart.currentLabelsXColor}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentLabelsXColor: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Labels Size</p>
              <div>
                <h4>{currentStyleLineDataChart.currentLabelsXFontSize} px</h4>
                <input
                  value={currentStyleLineDataChart.currentLabelsXFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={40}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentLabelsXFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Labels Offset</p>
              <div>
                <h4>{currentStyleLineDataChart.currentPaddingAxisX} px</h4>
                <input
                  value={currentStyleLineDataChart.currentPaddingAxisX}
                  type="range"
                  step={1}
                  min={5}
                  max={100}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentPaddingAxisX: parseFloat(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-color-conditional">
              <p>View Axis X</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayAxisX}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayAxisX:
                        !currentStyleLineDataChart.currentDisplayAxisX,
                      currentAxisXColor:
                        currentStyleLineDataChart.currentDisplayAxisX
                          ? "#EEEEEE"
                          : "#000000",
                    }))
                  }
                />
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentAxisXColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayAxisX: true,
                      currentAxisXColor: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Tooltip</h3>
            <div className="section-style">
              <p>Title Size</p>
              <div>
                <h4>
                  {currentStyleLineDataChart.currentTitleTooltipFontSize} px
                </h4>
                <input
                  value={currentStyleLineDataChart.currentTitleTooltipFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={40}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentTitleTooltipFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Body Size</p>
              <div>
                <h4>
                  {currentStyleLineDataChart.currentBodyTooltipFontSize} px
                </h4>
                <input
                  value={currentStyleLineDataChart.currentBodyTooltipFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={40}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentBodyTooltipFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerPreviewChart>
  );
};

export default PreviewGroupBarChart;
