import { useState, useEffect, useCallback } from "react";
// Hooks
import useFindObjects from "hooks/objects/useFindObjects";
// Helpers
import { sum, count } from "pages/Dashboard/helpers/componentsBarChart";
import { createRowsComponents } from "pages/Dashboard/helpers/createRowsComponents";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";

const useFindBarCharts = ({ openDialog, components, loading }) => {
  const [charts, setCharts] = useState(null);
  const [barCharts, setBarCharts] = useState(null);
  const [componentsFormat, setComponentsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataBarChart, error: errorDataBarChart } = useSwr(
    urlsApiMg.dashboard.barChart("components")
  );

  const { objects } = useFindObjects();

  useEffect(() => {
    if (!errorDataBarChart && dataBarChart) setCharts(dataBarChart);
  }, [dataBarChart, errorDataBarChart, openDialog, reloadCharts]);

  useEffect(() => {
    const componentsFormat = createRowsComponents({ components, objects });
    setComponentsFormat(componentsFormat);
  }, [components, objects]);

  const getDataBarChart = useCallback(async () => {
    if (componentsFormat && !loading) {
      const chartsFilterCount = charts.filter(
        (chart) => chart.type === "count"
      );
      const countCharts = count({
        barCharts: chartsFilterCount,
        components: componentsFormat,
      });
      const chartsFilterSum = charts.filter((chart) => chart.type === "sum");
      const barCharts = sum({
        barCharts: chartsFilterSum,
        components: componentsFormat,
      });

      setBarCharts([...countCharts, ...barCharts]);
    }
  }, [loading, componentsFormat, charts]);

  useEffect(() => {
    if (charts && components) {
      getDataBarChart();
    }
  }, [charts, components, getDataBarChart]);

  return { barCharts };
};

export default useFindBarCharts;
