import { useState } from "react";
import { useSWRConfig } from "swr";
import { useDispatch } from "react-redux";
import { setShowOperationDetails } from "redux/actions";
// Components
import CustomizedButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
// Services
import deleteComponent from "services/components/deleteComponent";
// Colors
import { DELETE_COLOR, HOVER_DELETE_COLOR } from "utils/const";
import DeleteIcon from "@mui/icons-material/Delete";
import { urlKeys } from "utils/urlKeys";
import { setMessageLoadingMap } from "redux/actions/digitalTwin";

const DeleteButton = ({ pointComponentId }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this component?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const handleClick = () => setOpenDialog(true);

  const handlerDeleteComponent = async () => {
    const { res } = await deleteComponent(pointComponentId); 
    if (res.status === 200) {
      dispatch(setMessageLoadingMap(true));
      setOpenDialog(false);
      dispatch(setShowOperationDetails(false));
      mutate(urlKeys.components);
    } else {
      setOpenDialog(true);
      setContentDialog({
        title: "",
        description: "❌ Error to delete component, try again.",
        disagree: "Cancel",
      });
    }
  };
  const shouldShowTooltip = true;

  return (
    <div>
      <CustomizedButton
        text={<DeleteIcon />}
        primaryColor={DELETE_COLOR}
        secondaryColor={HOVER_DELETE_COLOR}
        onClick={handleClick}
        margin={"10px 0"}
        showTooltip={shouldShowTooltip}
        tooltipTitle="Delete"
      />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={handlerDeleteComponent}
        content={contentDialog}
      />
    </div>
  );
};

export default DeleteButton;
