import axios from "axios";
import { urls } from "utils/urlKeys";

const findTypeComponentsTypeElements = async ({
  typeElementId,
  pointTypeComponentId,
}) => {
  const token = localStorage.getItem("token");

  // Construcción de la URL base
  let queryParams = new URLSearchParams();
  if (typeElementId) queryParams.append("typeElementId", typeElementId);
  if (pointTypeComponentId)
    queryParams.append("pointTypeComponentId", pointTypeComponentId);

  const url = urls.components.typeComponentsTypeElements(
    queryParams.toString()
  );

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching type components type elements:", error);
    throw error;
  }
};

export default findTypeComponentsTypeElements;
