import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
// Mapbox
import Map, {
  Marker,
  NavigationControl,
  AttributionControl,
  Source,
  Layer,
} from "react-map-gl";
import DraweControl from "./DrawControl";
// Style
import "mapbox-gl/dist/mapbox-gl.css";
import Container from "@mui/material/Container";
import { ImgIconOperation, MapCard } from "./MapViewElements";
import { NamePlateMarker } from "./MapViewElements";
import OperationDetails from "components/ElementDetails/OperationDetails/OperationDetails";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
// Consts
import fogStyle from "utils/fogStyle";
import {
  EVENT_CLUSTER_COLOR,
  COMPONENT_CLUSTER_COLOR,
  OBJECT_CLUSTER_COLOR,
} from "utils/const";
import { MAPBOX_STYLE } from "utils/constStrings";
// Component
import EventDetails from "components/ElementDetails/EventDetails/EventDetails";
import ObjectDetails from "components/ElementDetails/ObjectDetails/ObjectDetails";
import SelectType from "./SelectType";
import OpenDialog from "components/Dialogs/OpenDialog";
import ComponentDetails from "components/ElementDetails/ComponentDetails/Index.jsx";
import BaseMapButton from "components/Buttons/BaseMapButton";
import SaveCoordinateButton from "components/Buttons/SaveCoordinateButton";
import MovePoint from "components/DigitalTwin/MovePoint";
// Hooks
import FetchComponentsByObjectId from "hooks/fetchComponentsByObjectId";
import useSwr from "swr";
// Config
import { config } from "config.js";
// Helpers
import { createLineString } from "helpers/createLineString";
import { ShowEventMarkers } from "helpers/ShowEventsMarker";
import ShowLinesJoin from "helpers/showLines";
import showMarkers from "helpers/showMarkers";
// Services
import { findEventsByComponentId } from "services/findEvents";
import { urls } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

const MapView = ({
  id,
  eventId,
  typeOperation,
  object,
  location,
  urlIcon,
  typeObjectId,
  markedEvent,
  otherMarkers,
  typeElementId,
  setMovePoint,
  setCurrentObjectId,
}) => {
  const dispatch = useDispatch();
  const [marks, setMarks] = useState([]);
  const [operation, setOperation] = useState([]);
  const [anemometer, setAnemometer] = useState([]);
  const [eventsComponents, setEventsComponents] = useState([]);
  const token = localStorage.getItem("token");

  const { instanceId } = useUserStore((state) => state);

  const navControlStyle = {
    right: 10,
    top: 10,
  };
  const [haveObjects, setHaveObjects] = useState(false);
  const [feature, setFeatures] = useState({});
  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });

  const [dataLineStrings, setDataLineStrings] = useState();

  const [componentsObject, setComponentsObject] = useState([]);

  const [objectRelationMarkers, setObjectRelationMarkers] = useState([]);
  const [parentObject, setParentObject] = useState(null);
  const [parentObjectLocation, setParentObjectLine] = useState(null);

  const { isDraw: drawAnyThing } = useSelector(
    (state) => state.digitalTwinReducer.drawAnyThing
  );

  const settingMap = useSelector(
    (state) => state.digitalTwinReducer.settingMap
  );
  const [baseMap, setBaseMap] = useState(
    settingMap?.styleMap
      ? { id: settingMap.styleMap.id, value: settingMap.styleMap.value }
      : {
          id: MAPBOX_STYLE.streets.id,
          value: MAPBOX_STYLE.streets.value,
        }
  );
  // old events (operations)
  const urlMarks = `${config.URL_BACKEND_PG}api/v1/operation?objectId=${object.id}`;
  const { data: dataMarks } = useSwr(urlMarks);

  // Block to obtain the information of the dynamic events
  const urlDynamicEvents = `${config.URL_BACKEND_PG}api/v1/point-events?objectId=${object.id}`;
  const { data: dataDynamic, error: errorDataDynamics } =
    useSwr(urlDynamicEvents);

  // Block to obtain the information of the dynamic components
  const { data: dataComponents } = FetchComponentsByObjectId(object.id);

  const eventsComponentes = useCallback(async () => {
    if (eventId !== undefined) return;
    const allEvents = await Promise.all(
      dataComponents?.map(async (component) => {
        if (component.instanceId !== parseInt(instanceId)) return null;
        const events = await findEventsByComponentId({
          pointComponentId: component.id,
        });
        const filteredEvents = events.filter(
          (event) => event.isGeographic === true
        );
        const eventsComponentsLocation = filteredEvents?.map((event) => {
          return {
            ...event,
            componentLocation: {
              latitude: component.locationComponent.latitude,
              longitude: component.locationComponent.longitude,
            },
          };
        });
        return eventsComponentsLocation;
      })
    );
    const flattenedArray = allEvents
      .flat()
      .filter((it) => it !== null)
      .filter((it) => it.instanceId === parseInt(instanceId));
    setEventsComponents(flattenedArray);
  }, [dataComponents, eventId, object.id, instanceId]);

  const dataDynamicEvents = useMemo(() => {
    return dataDynamic && !errorDataDynamics
      ? dataDynamic.filter(
          (evt) =>
            evt.instanceId === parseInt(instanceId) && evt.isGeographic === true
        )
      : [];
  }, [dataDynamic, errorDataDynamics]);

  // Find type events to type elements
  const { data: dataTypeEvents } = useSwr(
    urls.events.typeEventsTypeElements(`typeElementId=${typeElementId}`)
  );
  // Find type components to type elements
  const { data: dataTypeComponents } = useSwr(
    urls.components.typeComponentsTypeElements(`typeElementId=${typeElementId}`)
  );
  // Find type objects to type objects
  const { data: typeObjects } = useSwr(
    urls.objects.typeObjectsTypeObjects(`pointTypeObjectId=${typeElementId}`)
  );

  // Merge old events with dynamic events
  useEffect(() => {
    if (dataMarks) {
      let allMarks = [...dataMarks.filter((evt) => evt.isGeographic === true)];
      if (dataDynamicEvents?.length) {
        const filteredDynamicEvents = dataDynamicEvents.filter(
          (evt) => evt.isGeographic === true
        );
        allMarks = [...dataMarks, ...filteredDynamicEvents];
        allMarks.map((evt) => {
          if (evt.hasOwnProperty("PointEventRelation")) {
            evt.PointEventRelation.forEach((son) => {
              if (son.isGeographic === true) {
                allMarks.push(son);
              }
            });
          }
          return null;
        });
      }
      setMarks([...allMarks]);
      setOperation([...allMarks]);
    }
    if (dataComponents) {
      setComponentsObject(
        dataComponents.filter((e) => e.instanceId === parseInt(instanceId))
      );
      eventsComponentes();
    }
  }, [dataMarks, dataDynamicEvents, dataComponents, eventsComponentes]);

  useEffect(() => {
    if (typeOperation !== undefined) {
      typeOperation.name === "Inspección flir" &&
        axios
          .get(
            `${config.URL_BACKEND_PG}api/v1/inspection-flir/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;
            setAnemometer(res.operationAnemometer);
          })
          .catch((err) => {
            console.log(err);
            setAnemometer([]);
          });

      typeOperation.name === "LEAK" &&
        axios
          .get(`${config.URL_BACKEND_PG}api/v1/leak/anemometer/${eventId}`, {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          })
          .then((response) => {
            const res = response.data;

            setAnemometer(res.operationAnemometer);
          });
      typeOperation.name === "INSPECTION SNIFFER" &&
        axios
          .get(
            `${config.URL_BACKEND_PG}api/v1/inspection-sniffer/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;
            setAnemometer(res.operationAnemometer);
          });
      typeOperation.name === "REPERATION" &&
        axios
          .get(
            `${config.URL_BACKEND_PG}api/v1/reparation/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;

            setAnemometer(res.operationAnemometer);
          });
    }
  }, [object.id, eventId, typeOperation, token]);

  // When click in some operation chnage view in the modal
  const handleClickMarker = (operationId) => {
    const operation = {
      content: <OperationDetails operationId={operationId} fromObject={true} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
  };

  const handleClickDynamicEvent = (eventId) => {
    const event = {
      content: <EventDetails eventId={eventId} fromObject={true} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(event));
  };

  const handlerClickObjectId = useCallback(
    (objectId) => {
      setCurrentObjectId(objectId);
      const object = {
        content: <ObjectDetails objectId={objectId} />,
        title: "Objects Details Relation",
      };
      if (!drawAnyThing) {
        dispatch(setOperationDetails(object));
        dispatch(setShowOperationDetails(true));
      }
    },
    [dispatch, drawAnyThing]
  );

  const handleClickComponent = (componentId) => {
    const component = {
      content: <ComponentDetails componentId={componentId} fromObject={true} />,
      title: "Component Details",
    };
    dispatch(setOperationDetails(component));
  };

  // TODO: Crear custom hooks de los useEffects

  useEffect(() => {
    const objectsRelation = object.PointObjectRelation?.map(
      (objectRelation) => {
        return {
          id: objectRelation.id,
          icon: objectRelation.typeElement.urlIconColor,
          latitude: objectRelation.location.latitude,
          longitude: objectRelation.location.longitude,
          handlerClick: () => handlerClickObjectId(objectRelation.id, "object"),
          marked: markedEvent,
          QAQC: object.QAQC,
        };
      }
    );
    setObjectRelationMarkers(objectsRelation);
  }, [object]);

  useEffect(() => {
    const parentObject = object.PointObject?.[0];
    if (parentObject) {
      const marker = {
        id: parentObject.id,
        icon: parentObject.typeElement.urlIconColor,
        latitude: parentObject.location.latitude,
        longitude: parentObject.location.longitude,
        handlerClick: () => handlerClickObjectId(parentObject.id, "object"),
        marked: markedEvent,
        QAQC: object.QAQC,
      };
      setParentObject([marker]);
    } else {
      setParentObject([]);
    }
  }, [object.id]);

  useEffect(() => {
    const parentObject = object.PointObject?.[0];
    const createMarkerLine = () => {
      const firstLocation = [
        parentObject.location.longitude,
        parentObject.location.latitude,
      ];
      const secondLocation = [
        object.location.longitude,
        object.location.latitude,
      ];
      const lineString = createLineString(
        [firstLocation, secondLocation],
        OBJECT_CLUSTER_COLOR
      );
      setParentObjectLine([lineString]);
    };
    if (parentObject) {
      createMarkerLine();
    } else {
      setParentObjectLine([]);
    }
  }, [object.id]);

  useEffect(() => {
    const typeOperations = [
      ["inspection-sniffer", "inspectionSniffer"],
      ["inspection-flir", "inspectionFlir"],
      ["leak", "leak"],
      ["reparation", "Reparation"],
    ];

    const getSniffers = async (objectOperation) => {
      const { typeOperationId } = objectOperation;
      const [path, type] = typeOperations[typeOperationId - 1];
      const { id } = objectOperation[type];
      const { data } = await axios.get(
        `${config.URL_BACKEND_PG}api/v1/${path}/anemometer/${id}`
      );

      const { operationAnemometer } = data;
      setAnemometer((currentAnemometers) => [
        ...currentAnemometers,
        ...operationAnemometer,
      ]);
    };

    marks?.map((object) => {
      if (!object?.operations) return null;
      object?.operations?.map((objectOperation) =>
        getSniffers(objectOperation)
      );
      return null;
    });
  }, [marks]);

  // Show components markers of object in the map
  const showComponentsMarkers = () => {
    return componentsObject?.map((component, index) => {
      const icon = component?.pointTypeComponent?.icon;
      return (
        <div
          key={`${component?.pointTypeComponent?.name}-${component.id}-${index}`}
        >
          {componentsObject && (
            <Marker
              latitude={Number(component.locationComponent.latitude)}
              longitude={Number(component.locationComponent.longitude)}
              style={{
                cursor: "pointer",
              }}
            >
              <NamePlateMarker
                id={component.id}
                onClick={() => handleClickComponent(component.id)}
                marked={markedEvent === component.id}
                qaqc={component.QAQC}
              >
                <ImgIconOperation
                  id={component.id}
                  src={icon}
                  alt="component marker"
                  style={{
                    cursor: "pointer",
                  }}
                ></ImgIconOperation>
                <span className="qaqc"></span>
                <span className="selected"></span>
              </NamePlateMarker>
              <p>{component.id}</p>
            </Marker>
          )}
        </div>
      );
    });
  };

  // get all dataLineStrings of the Object GHG
  useEffect(() => {
    // Lines to events
    const fetchData = async () => {
      try {
        const data = [];
        marks?.map(async (event) => {
          if (id !== undefined) return;
          if (event.objectId === null) {
            const idParentEvent = event.PointEventRelations.pointEventId;
            const searchParentEvent = marks.find(
              (elm) => elm.id === idParentEvent
            );
            if (searchParentEvent) {
              const objectLocation = [
                searchParentEvent.locationOperation.longitude,
                searchParentEvent.locationOperation.latitude,
              ];
              const locationOperation = [
                event.locationOperation.longitude,
                event.locationOperation.latitude,
              ];
              const geojsonLineString = createLineString(
                [objectLocation, locationOperation],
                EVENT_CLUSTER_COLOR
              );
              data.push(geojsonLineString);
            }
          } else {
            const objectLocation = [location.longitude, location.latitude];

            const locationOperation = [
              event.locationOperation.longitude,
              event.locationOperation.latitude,
            ];
            const geojsonLineString = createLineString(
              [objectLocation, locationOperation],
              EVENT_CLUSTER_COLOR
            );
            data.push(geojsonLineString);
          }
        });
        setDataLineStrings(data);
      } catch (error) {
        console.error("error:", error);
      }
    };
    marks && fetchData();

    // Lines to components
    const linesToComponents = () => {
      try {
        if (eventId === undefined) {
          const data = [];
          componentsObject?.map(async (component) => {
            const objectLocation = [location.longitude, location.latitude];

            const locationComponent = [
              component.locationComponent.longitude,
              component.locationComponent.latitude,
            ];
            const geojsonLineString = createLineString(
              [objectLocation, locationComponent],
              COMPONENT_CLUSTER_COLOR
            );
            data.push(geojsonLineString);
          });
          setDataLineStrings((prev) => [...prev, ...data]);
        }
      } catch (error) {
        console.error("error:", error);
      }
    };
    componentsObject && linesToComponents();

    //Lines to events of components
    const linesToEventsComponents = () => {
      try {
        if (eventId === undefined) {
          const data = [];
          eventsComponents?.map(async (eventComponent) => {
            const componentLocation = [
              eventComponent.componentLocation.longitude,
              eventComponent.componentLocation.latitude,
            ];

            const eventLocation = [
              eventComponent.locationOperation.longitude,
              eventComponent.locationOperation.latitude,
            ];

            const geojsonLineString = createLineString(
              [componentLocation, eventLocation],
              EVENT_CLUSTER_COLOR
            );
            data.push(geojsonLineString);
          });
          setDataLineStrings((prev) => [...prev, ...data]);
        }
      } catch (error) {
        console.error("error:", error);
      }
    };
    eventsComponents && linesToEventsComponents();

    const linesToObjectsObjects = () => {
      try {
        if (eventId === undefined) {
          const data = [];
          const objectRelations = object.PointObjectRelation;

          objectRelations?.map(async (objectRelation) => {
            const objectLocation = [
              object.location.longitude,
              object.location.latitude,
            ];
            const ObjectRelationLocation = [
              objectRelation.location.longitude,
              objectRelation.location.latitude,
            ];
            const geojsonLineString = createLineString(
              [objectLocation, ObjectRelationLocation],
              OBJECT_CLUSTER_COLOR
            );
            data.push(geojsonLineString);
          });
          setDataLineStrings((prev) => [...prev, ...data]);
        }
      } catch (error) {
        console.error("error:", error);
      }
    };
    object && linesToObjectsObjects();
  }, [
    id,
    eventId,
    location.longitude,
    location.latitude,
    componentsObject,
    marks,
    eventsComponents,
    object,
  ]);

  // show event anemometer markers
  const showAnemometeresOfEvent = () => {
    return anemometer?.map((object, index) => {
      return (
        <div key={index}>
          {object.anemometer !== null && (
            <Marker
              latitude={Number(object?.anemometer?.locationAnemometer.latitude)}
              longitude={Number(
                object?.anemometer?.locationAnemometer.longitude
              )}
            >
              <NamePlateMarker id={anemometer.id}>
                <img
                  id={anemometer.id}
                  src={
                    "https://icons-greendragon.s3.us-east-2.amazonaws.com/type_elements_events/anemometerIconMap.png"
                  }
                  alt="nameplate marker"
                ></img>
              </NamePlateMarker>
            </Marker>
          )}
        </div>
      );
    });
  };

  const showOthersMarkers = () => {
    return otherMarkers?.map((marker, index) => {
      const firstLocation = [location.longitude, location.latitude];
      const secondLocation = [marker.longitude, marker.latitude];
      const lineString = createLineString([firstLocation, secondLocation]);

      return (
        <>
          <div key={index}>
            <Source type="geojson" data={lineString} key={index}>
              <Layer
                key={index}
                id={index}
                type="line"
                paint={{
                  "line-color": EVENT_CLUSTER_COLOR,
                  "line-width": 3,
                  "line-dasharray": [2, 2],
                }}
              />
            </Source>
          </div>
          <Marker
            latitude={Number(marker.latitude)}
            longitude={Number(marker.longitude)}
            onClick={() => handlerClickObjectId(marker.id)}
          >
            <NamePlateMarker>
              <img
                src={marker.urlIcon}
                alt="nameplate marker"
                style={{
                  cursor: "pointer",
                }}
              ></img>
            </NamePlateMarker>
          </Marker>
        </>
      );
    });
  };

  const [dataCopyCoordinates, setDataCopyCoordinates] = useState({
    lng: "",
    lat: "",
  });
  const locationToMove = localStorage.getItem("newLocationMove");

  const handlePasteCoordinates = () => {
    const newLocationMove = localStorage.getItem("newLocationMove");
    const parseNewLocationMove = JSON.parse(newLocationMove);
    if (parseNewLocationMove) {
      setDataCopyCoordinates({
        lng: parseNewLocationMove.lng || "",
        lat: parseNewLocationMove.lat || "",
      });
    }
  };

  useEffect(() => {
    if (haveObjects) return;
    setFeatures({});
  }, [haveObjects]);

  useEffect(() => {
    if (!feature.geometry) return;
    setContentDialog({
      title: "Assign components, events or objects",
      description: (
        <SelectType
          feature={feature}
          objectId={object.id}
          setHaveObjects={setHaveObjects}
          typeEvents={dataTypeEvents}
          typeComponents={dataTypeComponents}
          typeObjects={typeObjects}
        />
      ),
    });
    setHaveObjects(true);
  }, [feature, object.id, dataTypeEvents, dataTypeComponents, typeObjects]);

  return (
    <Container
      maxWidth="l"
      sx={{
        width: "100%",
        height: "90%",
        padding: "0px 0px 24px 0px",
      }}
    >
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        disagree={() => {}}
        content={contentDialog}
        maxWidth={contentDialog.title === "Base Map" ? null : "500px"}
        minWidth={contentDialog.title === "Base Map" ? null : "300px"}
      />
      <MapCard>
        <Map
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            latitude: location.latitude,
            longitude: location.longitude,
            zoom: 20,
          }}
          style={{ width: "100%", height: 435, margin: "auto" }}
          mapStyle={baseMap.value}
          attributionControl={false}
          projection={"globe"}
          fog={fogStyle}
        >
          {object.id && !otherMarkers && (
            <DraweControl handleCreateEvent={setFeatures} />
          )}
          {/* Button layer map */}
          <BaseMapButton
            setHaveObjects={setHaveObjects}
            setContentDialog={setContentDialog}
            setBaseMap={setBaseMap}
            baseMap={baseMap}
            position={eventId ? 104 : 173}
          />
          {locationToMove !== null ? (
            dataCopyCoordinates.lng === "" && dataCopyCoordinates.lat === "" ? (
              <MovePoint
                from={"details"}
                moveNewLocation={dataCopyCoordinates}
                onPasteCoordinates={handlePasteCoordinates}
              />
            ) : (
              <SaveCoordinateButton
                from={"object"}
                id={object.id}
                oldLocation={{
                  latitude: location.latitude,
                  longitude: location.longitude,
                }}
                longitude={dataCopyCoordinates.lng}
                latitude={dataCopyCoordinates.lat}
                setDataCopyCoordinates={setDataCopyCoordinates}
                setMovePoint={setMovePoint}
                marks={marks}
                eventsComponents={eventsComponents}
                componentsObject={componentsObject}
              />
            )
          ) : (
            <></>
          )}
          <Marker
            latitude={Number(location.latitude)}
            longitude={Number(location.longitude)}
          >
            <NamePlateMarker qaqc={object.QAQC}>
              <img src={urlIcon} alt="nameplate marker"></img>
              <span className="qaqc"></span>
            </NamePlateMarker>
          </Marker>
          {dataCopyCoordinates.lng !== "" && dataCopyCoordinates.lat !== "" && (
            <Marker
              latitude={Number(dataCopyCoordinates.lat)}
              longitude={Number(dataCopyCoordinates.lng)}
            >
              <NamePlateMarker>
                <img
                  src={urlIcon}
                  alt="nameplate marker"
                  className="newPosition"
                ></img>
              </NamePlateMarker>
            </Marker>
          )}
          {otherMarkers && showOthersMarkers()}
          <AttributionControl customAttribution="© Decimetrix® 2024" />
          <NavigationControl style={navControlStyle} />
          {typeObjectId &&
            marks?.length > 0 &&
            operation?.length > 0 &&
            ShowEventMarkers(
              marks,
              handleClickDynamicEvent,
              handleClickMarker,
              markedEvent
            )}
          {eventsComponents.length > 0 &&
            ShowEventMarkers(
              eventsComponents,
              handleClickDynamicEvent,
              handleClickMarker,
              markedEvent
            )}
          {dataLineStrings && (
            <ShowLinesJoin id={"connection-lines"} features={dataLineStrings} />
          )}
          {parentObjectLocation && (
            <ShowLinesJoin
              id={"connection-lines-parent-object"}
              features={parentObjectLocation}
            />
          )}
          {anemometer.length > 0 ? showAnemometeresOfEvent() : null}
          {componentsObject.length > 0 &&
            typeObjectId &&
            showComponentsMarkers()}
          {objectRelationMarkers &&
            showMarkers({
              markers: objectRelationMarkers,
            })}
          {parentObject &&
            showMarkers({
              markers: parentObject,
            })}
        </Map>
      </MapCard>
    </Container>
  );
};

export default MapView;
