// Styles
import { selectStyle, ContainerColumnSelect } from "./style";
// Constants
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";
// Components
import Select from "react-select";
import {
  defaultFieldsObjects,
  defaultFieldsComponents,
  defaultFieldsEvents,
} from "utils/defaultColumns";

const SelectColumnsLibrary = ({
  columnsLibrary,
  handlerClickColumn,
  columnsSelected,
  columnsWithValues,
  handlerColumnValues,
  columnValues,
  typeElementNames,
  title = "",
}) => {
  return (
    <>
      <h2 className="subtitle">{title}</h2>
      <ContainerColumnSelect>
        {columnsLibrary.map((columnLibrary) => (
          <>
            <div
              key={columnLibrary._id}
              className="card-type-chart"
              onClick={() => handlerClickColumn(columnLibrary.name)}
              style={{
                backgroundColor: columnsSelected.includes(columnLibrary.name)
                  ? ICON_GREEN_COLOR
                  : WHITE_COLOR,
              }}
            >
              <p>{columnLibrary.alias || columnLibrary.name} </p>
            </div>
            {/* Select */}
            <>
              {columnsWithValues.map((columnWithValues) => {
                if (columnWithValues.columnName === columnLibrary.name)
                  return (
                    <Select
                      key={columnWithValues.columnName}
                      onChange={(selectedValues) =>
                        handlerColumnValues(
                          columnWithValues.columnName,
                          selectedValues
                        )
                      }
                      options={
                        columnWithValues.columnName ===
                          defaultFieldsObjects[1].name ||
                        columnWithValues.columnName ===
                          defaultFieldsComponents[0].name ||
                        columnWithValues.columnName ===
                          defaultFieldsEvents[0].name
                          ? typeElementNames.map((value) => {
                              return {
                                value: value,
                                label: value,
                              };
                            })
                          : columnWithValues.values.map((value) => {
                              return {
                                value: value,
                                label: value,
                              };
                            })
                      }
                      value={columnValues[columnWithValues.columnName]}
                      placeholder={"Select value"}
                      styles={selectStyle}
                      menuPlacement="auto"
                      isMulti
                    />
                  );
                return <></>;
              })}
            </>
          </>
        ))}
      </ContainerColumnSelect>
    </>
  );
};

export default SelectColumnsLibrary;
