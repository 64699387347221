import { useState } from "react";
import CustomButton from "components/Buttons/CustomButton";
import GenericDialog from "components/Dialogs/GenericDialog";
import ContentDialog from "./ContentDialog";
import { WHITE_BACKGROUND } from "utils/const";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

const ConvertButton = ({ pointEvent }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const shouldShowTooltip = true;
  return (
    <div>
      <CustomButton
        text={<ChangeCircleIcon />}
        onClick={handleOpenDialog}
        margin={"10px 0"}
        primaryColor={"#3f51b5"}
        secondaryColor={"#1084f0"}
        showTooltip={shouldShowTooltip}
        tooltipTitle="Convert"
      />
      <GenericDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        content={{
          title: "Convert Events",
          description: "Convert one event type to another event type",
          content: (
            <ContentDialog
              pointEvent={pointEvent}
              setOpenDialog={setOpenDialog}
            />
          ),
        }}
        style={{
          margin: 0,
          padding: 0,
          width: "50%",
          maxWidth: "none",
          background: `${WHITE_BACKGROUND}`,
        }}
      />
    </div>
  );
};

export default ConvertButton;
