import axios from "axios";
import { config } from "../../config";


const filterLineForPg = ({ mode, bodyline, objectsLineIds }) => {
  if (mode === "freeline")
    return {
      userId: parseInt(bodyline.userId),
      instanceId: parseInt(bodyline.instanceId),
      lineTypeId: parseInt(bodyline.lineTypeId),
      geojsonLineId: bodyline.geojsonLineId,
      adminCompanyId: bodyline.adminCompanyId ? parseInt(bodyline.adminCompanyId) : null,
      bodyMg: {
        userId: bodyline.userId,
        type: bodyline.type,
        feature: bodyline.feature,
        attributes: bodyline.attributes,
      }
    };
  return {
    userId: bodyline.userId,
    instanceId: parseInt(bodyline.instanceId),
    lineTypeId: bodyline.lineTypeId,
    geojsonLineId: bodyline.geojsonLineId,
    adminCompanyId: bodyline.adminCompanyId ? parseInt(bodyline.adminCompanyId) : null,
    objectsIds: objectsLineIds,
    bodyMg: {
      userId: bodyline.userId,
      type: bodyline.type,
      feature: bodyline.feature,
      attributes: bodyline.attributes,
    }
  };
};

export const saveLineApiPg = async (body, objectsLineIds) => {
  const token = localStorage.getItem("token");
  try {
    const dataFilteredPg = filterLineForPg({
      bodyline: body,
      objectsLineIds,
    });

    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/lines/objects`,
      dataFilteredPg,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export const saveFreeLineApiPg = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const dataFilteredPg = filterLineForPg({
      mode: "freeline",
      bodyline: body,
    });

    const res = await axios.post(
      `${config.URL_BACKEND_PG}api/v1/lines`,
      dataFilteredPg,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};
