import { useCallback, useState, useEffect } from "react";
import Map, {
  NavigationControl,
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
} from "react-map-gl";
import { useSWRConfig } from "swr";
import GeocoderControl from "./geocoder-control";
// Clusters
import ObjectsCluster from "../Clusters/Objects";
import OperationsCluster from "../Clusters/Operations";
import ComponentClustere from "../Clusters/Components";
// Components
import OperationDialog from "components/Dialogs/OperationDialog";
import Trackings from "./Trackings";
import Regions from "./Regions";

// Config
import { config } from "config.js";
import { useDispatch } from "react-redux";
import { setNeuronSelectTool } from "redux/actions/digitalTwin";
import {
  setDrawAnyThing,
  setOperationDetails,
  setShowOperationDetails,
} from "redux/actions";
import { useSelector } from "react-redux";
import DrawControl from "./DrawControl";
import HandlerDrawerControl from "./HandlerDrawerControl";
import useSWR from "swr";
import Lines from "./Lines/Lines";
import LineTools from "./Lines/LineTools";
import CustomToolTipButton from "./CustomToolTipButton";
import CreateRelationObjects from "components/Forms/SaveObject/CreateRelationObjects";
import DialogRelatedObjects from "components/Forms/SaveObject/DialogRelatedObjects";
import { StyleDrawControl } from "./styleDrawControl";
// Hooks
import { useSocket } from "hooks/useSocket";
import { urlsApiMg, urls } from "utils/urlKeys";
import ObjectDetails from "components/ElementDetails/ObjectDetails/ObjectDetails";
import MapSettingsConfigurations from "./MapSettingsConfiguration";

const MapDigital = ({
  baseMap,
  setCopyLocation,
  setCopyCoordinates,
  copyCoordinates,
  setMoveNewLocation,
  setMovePoint,
  movePoint,
  mapRef,
  setOpenDialogRelationLines,
  trackings,
  filterObjects,
  filterObjectsMap,
  filterEvents,
  filterComponents,
  filterComponentsMap,
  currentId,
  setCurrentId,
  currentDataEvent,
  setCurrentDataEvent,
  allLines,
}) => {
  const socket = useSocket();
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const showTrackings = useSelector(
    (state) => state.digitalTwinReducer.showTrackings
  );
  const [moveEnd, setMoveEnd] = useState(false);

  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);

  const [viewState, setViewState] = useState({
    latitude: 4.81500179,
    longitude: -75.725484,
    width: "100vw",
    height: "100vh",
    zoom: 5,
  });

  const [popUpLines, setPopUpLines] = useState({});
  const [popUpRegions, setPopUpRegions] = useState({});
  const [relationPointsLines, setRelationPointsLines] = useState([{}]);

  // definition of controllers
  const { onCreate, onSelect, onUpdate, onDelete } = HandlerDrawerControl();

  const { data: regionsData, error: errorRegions } = useSWR(
    urlsApiMg.regions.all
  );

  const dataRegions = regionsData && !errorRegions ? regionsData : [];
  const [flagUpdateObj, setFlagUpdateObj] = useState("");
  const [flagUpdateCmp, setFlagUpdateCmp] = useState("");
  const [flagUpdateEvt, setFlagUpdateEvt] = useState("");
  const [flagUpdateLines, setFlagUpdateLines] = useState("");

  useEffect(() => {
    const handleSocketEvent = (event, setDataFlag) => {
      if (socket) {
        socket.on(event, (data) => {
          setDataFlag(data);
        });
        return () => {
          socket.off(event);
        };
      }
    };

    const cleanupSocketListeners = [
      handleSocketEvent("newObject", setFlagUpdateObj),
      handleSocketEvent("deleteObject", setFlagUpdateObj),
      handleSocketEvent("newComponent", setFlagUpdateCmp),
      handleSocketEvent("deleteComponent", setFlagUpdateCmp),
      handleSocketEvent("newEvent", setFlagUpdateEvt),
      handleSocketEvent("deleteEvent", setFlagUpdateEvt),
      handleSocketEvent("newLine", setFlagUpdateLines),
    ];

    return () => {
      cleanupSocketListeners.forEach((cleanup) => cleanup());
    };
  }, [socket]);

  // Mutate data on flag updates
  useEffect(() => {
    mutate(urls.objects.all);
  }, [mutate, flagUpdateObj]);

  useEffect(() => {
    mutate(urls.components.all);
  }, [mutate, flagUpdateCmp]);

  useEffect(() => {
    mutate(urls.events.objects);
    mutate(urls.events.components);
    mutate(urls.events.events);
  }, [mutate, flagUpdateEvt]);

  useEffect(() => {
    mutate(urls.lines.all);
  }, [mutate, flagUpdateLines]);

  const modifyNavigationControlStyles = () => {
    const elementsWithIconClass = document.querySelectorAll(
      ".mapboxgl-ctrl-icon"
    );

    elementsWithIconClass.forEach((element) => {
      element.removeAttribute("title");
    });

    const buttons = document.querySelectorAll(".mapbox-gl-draw_ctrl-draw-btn");

    buttons.forEach((button) => {
      button.removeAttribute("title");
    });
  };
  useEffect(() => {
    modifyNavigationControlStyles();
    const observer = new MutationObserver(modifyNavigationControlStyles);
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  //Activate the instance to show object relation
  const relationObjectModal = useSelector(
    (state) => state.digitalTwinReducer.modalRelationObject
  );

  //Show info of all related objects
  const infoRelationObject = useSelector(
    (state) => state.digitalTwinReducer.infoRelationObject
  );
  // Geocoder function for coordinates
  const coordinatesGeocoder = useCallback((query) => {
    // Match anything which looks like
    // decimal degrees coordinate pair.
    const matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    if (!matches) {
      return null;
    }

    function coordinateFeature(lng, lat) {
      return {
        center: [lng, lat],
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
        place_name: "Lat: " + lat + " Lng: " + lng,
        place_type: ["coordinate"],
        properties: {},
        type: "Feature",
      };
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
      // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));
    }

    if (coord2 < -90 || coord2 > 90) {
      // must be lat, lng
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    if (geocodes.length === 0) {
      // else could be either lng, lat or lat, lng
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes;
  }, []);

  const onSelectPoint = useCallback((longitude, latitude) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: 2000,
      zoom: 16,
    });
  }, []);

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const handlePopUpRegion = useCallback((lngLat, features, type) => {
    const [feature] = features;
    const { layer } = feature;
    const { id } = layer;
    const { lat, lng } = lngLat;
    setPopUpRegions((current) => ({
      popUp: {
        latitude: lat,
        longitude: lng,
        id,
        type,
      },
      region: {
        ...current.region,
        ...feature,
      },
    }));
  }, []);

  const handlePopUpLine = useCallback((lngLat, features) => {
    const { lat, lng } = lngLat;
    const { object } = features[0].properties;
    const line = JSON.parse(object);
    const { id, properties } = line;
    setPopUpLines((current) => ({
      popUp: {
        id,
        latitude: lat,
        longitude: lng,
      },
      line: {
        ...current.line,
        ...properties,
        id,
      },
    }));
  }, []);

  const handleRightClickMap = useCallback(
    (e) => {
      const { lngLat, features } = e;
      if (features && features.length > 0) {
        const [feature] = features;
        const { layer } = feature;
        if (layer.type === "fill") {
          handlePopUpRegion(lngLat, features, "rightClick");
        }
      }
    },
    [handlePopUpRegion]
  );

  const [showPopUp, setShowPopUp] = useState(false);

  const [openInfo, setOpenInfo] = useState(true);
  // a left click is made inside the map
  const handleMapClick = useCallback(
    (e) => {
      const { lngLat, features } = e;

      if (copyCoordinates) {
        setCopyLocation(lngLat);
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = JSON.stringify(lngLat);
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        navigator.permissions.query({ name: "clipboard-read" });
        navigator.clipboard.writeText(tempTextArea.value);
        dispatch(setNeuronSelectTool({ show: false, top: null, right: null }));
        dispatch(setDrawAnyThing({ isDraw: false, type: "" }));
        setCopyCoordinates(false);
      }

      if (movePoint) {
        setMoveNewLocation(lngLat);
        const currentLocationMove = JSON.stringify(lngLat);
        localStorage.setItem("newLocationMove", currentLocationMove);
        dispatch(setNeuronSelectTool({ show: false, top: null, right: null }));
        dispatch(setDrawAnyThing({ isDraw: false, type: "" }));
        setMovePoint(false);
      }

      if (features && features.length > 0) {
        const [feature] = features;
        const { layer } = feature;
        if (layer.type === "line") {
          handlePopUpLine(lngLat, features);
        }
        if (layer.type === "fill") {
          handlePopUpRegion(lngLat, features, "click");
        }
      }
    },
    [copyCoordinates, movePoint, dispatch, handlePopUpLine, handlePopUpRegion]
  );

  const waitForPageLoad = useCallback(() => {
    if (document.readyState === "complete") {
      modifyNavigationControlStyles();
    } else {
      setTimeout(waitForPageLoad, 100);
    }
  }, []);

  useEffect(() => {
    waitForPageLoad();
    window.addEventListener("beforeunload", () => {});

    return () => {
      window.removeEventListener("beforeunload", () => {});
    };
  }, [waitForPageLoad]);

  const onMapLoad = useCallback(() => {
    const map = mapRef.current.getMap();

    map.on("click", "point-layer", (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["point-layer"],
      });

      if (!features.length || features.length > 1) {
        return;
      }
      const dataPoint = JSON.parse(features[0].properties.object);
      dispatch(
        setOperationDetails({
          content: <ObjectDetails objectId={dataPoint.id} />,
          title: "Objects Details",
        })
      );
      dispatch(setShowOperationDetails(true));
    });

    map.on("mouseenter", "point-layer", () => {
      map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", "point-layer", () => {
      map.getCanvas().style.cursor = "";
    });
  }, []);

  return (
    <Map
      {...viewState}
      mapboxAccessToken={config.MAPBOX_TOKEN}
      // mapboxAccessToken={mapboxAccessToken}
      style={{ width: "100%", margin: "auto" }}
      mapStyle={baseMap.value}
      onClick={handleMapClick}
      onContextMenu={handleRightClickMap}
      interactiveLayerIds={interactiveLayerIds}
      onMoveEnd={() => setMoveEnd(!moveEnd)}
      onMove={(e) => setViewState(e.viewState)}
      onLoad={onMapLoad}
      ref={mapRef}
      attributionControl={false}
      cursor={copyCoordinates || movePoint ? "crosshair" : null}
      //projection={"globe"}
      //fog={fogStyle}
    >
      <GeocoderControl
        mapboxAccessToken={config.MAPBOX_TOKEN}
        position="top-right"
        placeholder={"Try: Longitude, Latitude"}
        localGeocoder={coordinatesGeocoder}
      />
      <NavigationControl />

      <CustomToolTipButton title="Zoom in" placement="right" translateY={63} />
      <CustomToolTipButton title="Zoom out" placement="right" translateY={93} />
      <CustomToolTipButton
        title="Reset bearing to north"
        placement="right"
        translateY={122}
      />
      <CustomToolTipButton
        title="Enter fullscreen"
        placement="right"
        translateY={160}
      />
      <CustomToolTipButton
        title="Location"
        placement="right"
        translateY={200}
      />
      <CustomToolTipButton
        title="LineString tool (l)"
        placement="right"
        translateY={240}
      />
      <CustomToolTipButton
        title="Polygon tool (p)"
        placement="right"
        translateY={269}
      />
      <CustomToolTipButton
        title="Marker tool (m)"
        placement="right"
        translateY={298}
      />
      <CustomToolTipButton title="Delete" placement="right" translateY={327} />

      <DrawControl
        position="bottom-right"
        displayControlsDefault={false}
        userProperties={true}
        copyCoordinates={copyCoordinates}
        setCopyCoordinates={setCopyCoordinates}
        setCopyLocation={setCopyLocation}
        setOpenDialogRelationLines={setOpenDialogRelationLines}
        relationPointsLines={relationPointsLines}
        setRelationPointsLines={setRelationPointsLines}
        styles={StyleDrawControl}
        controls={{
          polygon: false,
          line_string: true,
          point: true,
          trash: true,
        }}
        onSelect={onSelect}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />

      {dataRegions && (
        <Regions
          layerIds={interactiveLayerIds}
          setLayerIds={setInteractiveLayerIds}
          dataRegions={dataRegions}
          popUpRegions={popUpRegions}
        />
      )}

      {relationObjectModal?.show && relationObjectModal?.objectId && (
        <CreateRelationObjects
          openPopUp={showPopUp}
          setOpenPopUp={setShowPopUp}
          pointObjectRelationId={relationObjectModal.objectSelected}
          pointObjectId={relationObjectModal.objectId}
          ObjectClick={relationObjectModal.objectClick}
        />
      )}
      {infoRelationObject.showInfoObject && (
        <DialogRelatedObjects openInfo={openInfo} setOpenInfo={setOpenInfo} />
      )}

      <Lines
        allLines={allLines}
        popUpLines={popUpLines}
        setPopUpLines={setPopUpLines}
        layerIds={interactiveLayerIds}
        setLayerIds={setInteractiveLayerIds}
      />
      {/* Line tools menu shown when line icon is clicked */}
      <LineTools />

      {showTrackings && trackings && <Trackings trackings={trackings} />}

      <AttributionControl
        style={{ color: "black" }}
        customAttribution="© Decimetrix® 2024"
      />
      <FullscreenControl />
      <GeolocateControl
        ref={useCallback((ref) => {
          if (ref) {
            ref.trigger();
          }
        }, [])}
        trackUserLocation={true}
      />
      <ScaleControl position="bottom-right" />

      {/* map setting configuration on loading map */}
      <MapSettingsConfigurations />
      {filterObjects && (
        <ObjectsCluster
          objects={filterObjects}
          objectsMap={filterObjectsMap}
          onSelectPoint={onSelectPoint}
          bounds={bounds}
          viewState={viewState}
          setViewState={setViewState}
          setLayerIds={setInteractiveLayerIds}
          mapRef={mapRef}
        />
      )}
      <OperationsCluster
        events={filterEvents}
        onSelectPoint={onSelectPoint}
        bounds={bounds}
        viewState={viewState}
        setViewState={setViewState}
      />
      <ComponentClustere
        components={filterComponents}
        componentsMap={filterComponentsMap}
        onSelectPoint={onSelectPoint}
        bounds={bounds}
        viewState={viewState}
        setViewState={setViewState}
      />
      <OperationDialog
        style={{
          margin: 0,
          padding: 0,
          width: {
            xs: "100%",
            sm: "95%",
            md: "93%",
            lg: "90%",
          },
          height: {
            xs: "100%",
            sm: "95%",
          },
          maxWidth: "none",
        }}
        minimized={true}
        currentId={currentId}
        handle={setCurrentId}
        dataEvent={currentDataEvent}
        handleEvent={setCurrentDataEvent}
      />
    </Map>
  );
};

export default MapDigital;
