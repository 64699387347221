import TextInput from "components/Forms/GenericInput";
import SelectInput from "components/Forms/SelectInput";
import { LineConteiner2 } from "pages/ConfigAdmin/typeElemts/FieldsStyle";
import { useEffect, useState } from "react";
import useSwr from "swr";
import { config } from "config.js";

const FormPreviewObjects = ({ fields }) => {
  const [globalTableCategories, setGlobalTableCategories] = useState(null);
  const [dataListTables, setDataListTables] = useState([]);
  const [dataMeasurementTables, setDataMeasurementTables] = useState([]);

  //Get Data Global Tables
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const urlGlobalTables = `${config.URL_BACKEND_MG}global-values-fields?adminCompanyId=${adminCompanyId}`;
  const { data: globalTables, error: errorGlobalTables } =
    useSwr(urlGlobalTables);

  const urlListTables = `${config.URL_BACKEND_MG}global-list-table?adminCompanyId=${adminCompanyId}`;
  const { data: globalListTables, error: errorGlobalListTables } =
    useSwr(urlListTables);

  const urlMeasurementTable = `${config.URL_BACKEND_MG}global-measurement-table?adminCompanyId=${adminCompanyId}`;
  const { data: globalMeasurementTables, error: errorGlobalMeasurementTables } =
    useSwr(urlMeasurementTable);

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/admin-company/operators/${adminCompanyId}`
  );
  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];

  useEffect(() => {
    if (globalTables && !errorGlobalTables && globalTables.length > 0) {
      setGlobalTableCategories(globalTables[0].tableCategories);
    }
  }, [globalTables, errorGlobalTables]);

  useEffect(() => {
    if (
      !errorGlobalListTables &&
      globalListTables &&
      globalListTables.length > 0
    ) {
      setDataListTables(globalListTables[0].tableCategories);
    }
  }, [globalListTables, errorGlobalListTables]);

  useEffect(() => {
    if (
      !errorGlobalMeasurementTables &&
      globalMeasurementTables &&
      globalMeasurementTables.length > 0
    ) {
      setDataMeasurementTables(globalMeasurementTables[0].tableCategories);
    }
  }, [globalMeasurementTables, errorGlobalMeasurementTables]);

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        {fields && (
          <div>
            {fields?.map((field, index) => {
              if (field.type === "operation") {
                return <TextInput key={index} field={field} />;
              }
              if (
                field.type === "select" &&
                field.hasOwnProperty("globalMeasurementTable") &&
                Object.keys(field.globalMeasurementTable).length > 0 &&
                dataMeasurementTables &&
                dataListTables
              ) {
                const currentCategoryMeasurementTable =
                  dataMeasurementTables.find((category) => {
                    return (
                      category._id === field.globalMeasurementTable?.categoryId
                    );
                  });
                const selectedMeasurementTable =
                  currentCategoryMeasurementTable?.groupTables?.find(
                    (table) => {
                      return table._id === field.globalMeasurementTable.tableId;
                    }
                  );

                const currentCategoryListTable = dataListTables?.find(
                  (category) => {
                    return (
                      category._id ===
                      selectedMeasurementTable?.associationGlobalListTable
                        ?.categoryId
                    );
                  }
                );
                const currentListTable =
                  currentCategoryListTable?.groupTables.find((table) => {
                    return (
                      table._id ===
                      selectedMeasurementTable?.associationGlobalListTable
                        ?.tableId
                    );
                  });

                if (
                  field?.globalMeasurementTable?.optionsSelectFromListTable
                    ?.associationListCategoryId ===
                    currentCategoryListTable?._id &&
                  field?.globalMeasurementTable?.optionsSelectFromListTable
                    ?.associationListTableId === currentListTable?._id &&
                  field?.globalMeasurementTable?.categoryId ===
                    currentCategoryMeasurementTable?._id &&
                  field?.globalMeasurementTable?.tableId ===
                    selectedMeasurementTable?._id
                ) {
                  const masterKey = selectedMeasurementTable?.dataColumns?.find(
                    (elm) => elm.masterKey
                  );

                  const optionsToCreate = [
                    ...new Set(
                      selectedMeasurementTable?.rows
                        ?.filter((row) => row.Association === "false")
                        ?.map((row) => {
                          return row[masterKey.name];
                        })
                    ),
                  ];

                  let isSynchronized = false;
                  return (
                    <div key={index}>
                      <SelectInput
                        key={index}
                        field={{
                          name: field.name,
                          options: optionsToCreate.map((elm) => {
                            return elm;
                          }),
                          required: field.required,
                          type: field.type,
                          alias: field.alias || field.name,
                        }}
                        warningGlobalOptions={optionsToCreate.length === 0}
                        disabledSelectGlobalMesurement={isSynchronized}
                      />
                    </div>
                  );
                }
              }
              if (
                field.type === "select" &&
                field.globalSelect &&
                globalTableCategories
              ) {
                const currentCategory = globalTableCategories.find(
                  (category) => {
                    return category._id === field.globalTable.categoryId;
                  }
                );
                const selectTable = currentCategory?.groupTables.find(
                  (table) => {
                    return table._id === field.globalTable.tableId;
                  }
                );

                return (
                  <div key={index}>
                    <SelectInput
                      key={index}
                      field={{
                        name: field.name,
                        options: selectTable.rows.map((elm) => elm.Options),
                        required: field.required,
                        type: field.type,
                        alias: field.alias || field.name,
                      }}
                    />
                  </div>
                );
              }
              if (field.type === "select") {
                return <SelectInput key={index} field={field} />;
              }
              if (field.type === "currency") {
                return (
                  <div key={index}>
                    <TextInput field={field} />
                    <SelectInput field={field} />
                  </div>
                );
              }
              if (field.type === "range") {
                const step =
                  field.options.length !== 0 ? parseInt(field.options[0]) : 1;
                return (
                  <div key={index}>
                    <TextInput
                      field={{
                        name: field.name,
                        alias: field.alias,
                        required: field.required,
                        type: field.type,
                        step: step,
                        max: field.max,
                        min: field.min,
                      }}
                    />
                  </div>
                );
              }
              if (field.type === "delegate") {
                return (
                  <div>
                    <SelectInput
                      key={index}
                      field={{
                        name: field.name,
                        options: allOperators.operators?.map((operator) => {
                          return `${operator?.firstName} ${operator?.firstLastName}`;
                        }),
                        required: field.required,
                        type: field.type,
                        alias: field.alias || field.name,
                      }}
                    />
                  </div>
                );
              }
              if (field.type === "lineTag") {
                return (
                  <LineConteiner2 key={index}>
                    <hr />
                    <h3>{field.alias}</h3>
                    <hr />
                  </LineConteiner2>
                );
              }
              if (field.type === "file") {
                return (
                  <div key={index}>
                    <TextInput
                      field={{
                        ...field,
                        name: field.name,
                        required: field.required,
                        type: field.type,
                        possible: field.possible,
                        alias: field.alias || field.name,
                      }}
                    />
                  </div>
                );
              }
              if (
                field.type === "captureMedia" ||
                field.type === "notification"
              )
                return null;
              return (
                <TextInput
                  key={index}
                  field={{
                    ...field,
                    noTouch: true,
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default FormPreviewObjects;
