import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
/**
 * ImageZoom Component
 *
 * A React component that provides zoom and pan functionality for images.
 * Features:
 * - Mouse wheel zoom with smooth transitions
 * - Click and drag panning when zoomed
 * - Automatic position reset when zoomed out completely
 * - Contained within its boundaries
 *
 * @param {Object} props
 * @param {string} props.src - The source URL of the image
 * @param {string} props.alt - Alt text for the image
 * @param {Object} [props.style] - Additional styles for the container
 * @param {string} [props.className] - Additional classes for the image
 */

const ImageZoom = forwardRef(({ src, alt, style, className }, ref) => {
  // Configuration constants
  const MIN_SCALE = 1; // Minimum zoom level
  const MAX_SCALE = 4; // Maximum zoom level
  const ZOOM_SPEED = 0.1; // Zoom speed multiplier
  // State for tracking zoom level and position
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  // Refs for accessing DOM elements
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useImperativeHandle(ref, () => ({
    zoomIn: () => updateScale(1),
    zoomOut: () => updateScale(-1),
  }));

  const updateScale = (delta) => {
    setScale((prevScale) => {
      const newScale = Math.min(
        Math.max(prevScale + delta * ZOOM_SPEED, MIN_SCALE),
        MAX_SCALE
      );
      return newScale;
    });
  };

  // Reset position when scale returns to minimum
  useEffect(() => {
    if (scale <= MIN_SCALE) {
      setPosition({ x: 0, y: 0 });
    }
  }, [scale]);

  /**
   * Handles mouse wheel events for zooming
   * @param {WheelEvent} e - The wheel event
   */

  const handleWheel = (e) => {
    if (e === undefined) return;
    e.stopPropagation();
    const container = containerRef.current;
    const rect = container.getBoundingClientRect();

    // Check if mouse is over the image
    const isInsideImage =
      e.clientX >= rect.left &&
      e.clientX <= rect.right &&
      e.clientY >= rect.top &&
      e.clientY <= rect.bottom;
    if (!isInsideImage) return;

    // Calculate relative mouse position
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;

    // Determine zoom direction and calculate new scale
    const delta = e.deltaY < 0 ? 1 : -1;
    const newScale = Math.min(
      Math.max(scale + delta * ZOOM_SPEED, MIN_SCALE),
      MAX_SCALE
    );
    if (newScale !== scale) {
      setScale(newScale);
      // Update position only if zoomed in
      if (newScale > MIN_SCALE) {
        const scaleRatio = newScale / scale;
        const newX = position.x - (x - 0.5) * (scaleRatio - 1) * 100;
        const newY = position.y - (y - 0.5) * (scaleRatio - 1) * 100;
        setPosition({
          x: Math.max(Math.min(newX, 50), -50),

          y: Math.max(Math.min(newY, 50), -50),
        });
      }
    }
  };

  /**
   * Handles the start of a drag operation
   * @param {MouseEvent} e - The mouse event
   */
  const handleMouseDown = (e) => {
    if (scale > MIN_SCALE) {
      e.stopPropagation();
      setIsDragging(true);
      // Store initial click position and current image position
      setDragStart({
        x: e.clientX,
        y: e.clientY,
        initialX: position.x,
        initialY: position.y,
      });
    }
  };

  /**
   * Handles the drag operation
   * @param {MouseEvent} e - The mouse event
   */
  const handleMouseMove = (e) => {
    if (isDragging && scale > MIN_SCALE) {
      e.stopPropagation();
      const container = containerRef.current;
      const { width, height } = container.getBoundingClientRect();

      // Calculate displacement from initial click position
      const deltaX = ((e.clientX - dragStart.x) / width) * 100;
      const deltaY = ((e.clientY - dragStart.y) / height) * 100;

      // Apply displacement to initial image position
      const newX = dragStart.initialX + deltaX;
      const newY = dragStart.initialY + deltaY;

      // Limit movement based on current scale
      const maxOffset = 50 * (scale - 1);
      setPosition({
        x: Math.max(Math.min(newX, maxOffset), -maxOffset),

        y: Math.max(Math.min(newY, maxOffset), -maxOffset),
      });
    }
  };

  /**
   * Handles the end of a drag operation
   */
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Set up event listeners for drag operations and scroll prevention
  useEffect(() => {
    const container = containerRef.current;
    // Prevent page scroll when zoomed in
    const preventScroll = (e) => {
      if (scale > MIN_SCALE) {
        e.preventDefault();
      }
    };

    if (container) {
      container.addEventListener("wheel", preventScroll, { passive: false });
    }

    // Add global mouse event listeners when zoomed in
    if (scale > MIN_SCALE) {
      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("mousemove", handleMouseMove);
    }

    // Cleanup event listeners
    return () => {
      if (container) {
        container.removeEventListener("wheel", preventScroll);
      }
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [scale, isDragging]);

  // Dynamic styles for the image based on current state
  const imageStyles = {
    transform: `scale(${scale}) translate(${position.x}%, ${position.y}%)`,
    transition: isDragging ? "none" : "transform 0.3s ease-out",
    cursor: scale > MIN_SCALE ? (isDragging ? "grabbing" : "grab") : "default",
    transformOrigin: "50% 50%",
    userSelect: "none",
    width: "100%",
    height: "auto",
    position: "relative",
    maxWidth: "100%",
  };

  return (
    <div
      ref={containerRef}
      id="image-photo-gallery-id"
      style={{
        display: "flex",
        overflow: "hidden",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "auto",
        ...style,
      }}
      className="flex justify-center items-center"
      onWheel={handleWheel}
      onMouseDown={handleMouseDown}
    >
      <img
        src={src}
        ref={imageRef}
        alt={alt}
        style={imageStyles}
        className={className}
        draggable={false}
      />
    </div>
  );
});

export default ImageZoom;
