import axios from "axios";
import { urls } from "utils/urlKeys";

const findTypeLinesTypeObjects = async ({ typeLineId, typeElementId }) => {
  const token = localStorage.getItem("token");
  const urlParams = new URLSearchParams();

  if (typeLineId) urlParams.append("typeLineId", typeLineId);
  if (typeElementId) urlParams.append("typeElementId", typeElementId);

  const result = await axios.get(
    urls.lines.typeLinesTypeObjects(`&${urlParams.toString()}`),
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );

  const data = await result.data;
  return data;
};

export default findTypeLinesTypeObjects;
