import { config } from "config.js";
const backendPg = config.URL_BACKEND_PG;
const adminCompanyId = config.ADMIN_COMPANY_ID;

export const urlKeys = {
  objects: `${backendPg}api/v1/admin-company/objects-web/${adminCompanyId}`,
  eventsObjects: `${backendPg}api/v1/point-events?adminCompanyId=${adminCompanyId}`,
  eventsComponents: `${backendPg}api/v1/point-events/components?adminCompanyId=${adminCompanyId}`,
  eventsEvents: `${backendPg}api/v1/point-events/relation?adminCompanyId=${adminCompanyId}`,
  components: `${backendPg}api/v1/point-components?adminCompanyId=${adminCompanyId}`,
  componentsWeb: `${backendPg}api/v1/point-components/components-web?adminCompanyId=${adminCompanyId}`,
  lines: `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`,
};

export const urlKeysDetail = ({ detailId }) => {
  return {
    urlObjects: `${config.URL_BACKEND_PG}api/v1/objects/${detailId}`,
    urlComponents: `${config.URL_BACKEND_PG}api/v1/point-components?id=${detailId}`,
    urlEventsObjects: `${config.URL_BACKEND_PG}api/v1/point-events?id=${detailId}`,
    urlEventsComponents: `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${detailId}`,
    urlEventsEvents: `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${detailId}`,
    urlDynamicComponentEvents: `${config.URL_BACKEND_PG}api/v1/point-events/components?pointComponentId=${detailId}`,
    urlDynamicEventsObjects: `${config.URL_BACKEND_PG}api/v1/point-events?objectId=${detailId}`,
    urlDynamicEventsEvents: `${config.URL_BACKEND_PG}api/v1/point-events/all?id=${detailId}`,
  };
};
