import { selectStyle, ContainerColumnSelect } from "./style";
import { ICON_GREEN_COLOR, WHITE_COLOR } from "utils/const";
import Select from "react-select";
import { useState, useCallback, useMemo, useEffect } from "react";
import {
  defaultFieldsObjects,
  defaultFieldsComponents,
  defaultFieldsEvents,
} from "utils/defaultColumns";
function updateColumnsWithValues({
  columnsValues,
  columnsLibrary,
  columnSelected,
}) {
  const matchingLibraryColumn = columnsLibrary.find(
    (libColumn) =>
      libColumn.name === columnSelected && libColumn.type === "select"
  );

  if (matchingLibraryColumn) {
    return matchingLibraryColumn.options.map((elm) => {
      return {
        value: elm,
        label: elm,
      };
    });
  }

  return columnsValues.map((elm) => {
    return {
      value: elm,
      label: elm,
    };
  });
}

const SelectCategoryLineTimeSerious = ({
  columnsLibrary,
  handlerSelectCategory,
  dataInputs,
  data,
  setSelectedOptions,
  selectedOptions,
  typeElementNames,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(dataInputs.category);

  const allOptions = useMemo(() => {
    if (!selectedCategory) return [];
    let uniqueValues = [];

    if (
      selectedCategory === defaultFieldsObjects[1].name ||
      selectedCategory === defaultFieldsComponents[0].name ||
      selectedCategory === defaultFieldsEvents[0].name
    ) {
      uniqueValues = typeElementNames;
    } else {
      const valuesObjects = data
        .map((item) => {
          const attribute = item.attributes.find(
            (attr) => attr.name === selectedCategory
          );
          return attribute;
        })
        .filter(Boolean);

      uniqueValues = [
        ...new Set(
          valuesObjects.map((item) => {
            try {
              const value = JSON.parse(item.value);
              return value.hasOwnProperty("value") ? value.value : item.value;
            } catch {
              return item.value;
            }
          })
        ),
      ];
    }

    const newColumnsWithValues = updateColumnsWithValues({
      columnSelected: selectedCategory,
      columnsValues: uniqueValues,
      columnsLibrary,
    });

    return newColumnsWithValues;
  }, [data, selectedCategory]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedOptions(allOptions);
    }
  }, [selectedCategory, allOptions]);

  const handleCategoryClick = useCallback(
    (columnName) => {
      if (selectedCategory === columnName) {
        setSelectedCategory(null);
        handlerSelectCategory(null);
        setSelectedOptions([]);
      } else {
        setSelectedCategory(columnName);
        handlerSelectCategory(columnName);
      }
    },
    [handlerSelectCategory]
  );

  const handleSelectChange = (newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions || []);
  };

  return (
    <>
      <ContainerColumnSelect>
        {columnsLibrary.map((columnLibrary) => {
          if (
            columnLibrary.name !== "localDate" &&
            columnLibrary.type !== "date"
          ) {
            return (
              <>
                <div
                  key={columnLibrary._id}
                  className="card-type-chart"
                  onClick={() => handleCategoryClick(columnLibrary.name)}
                  style={{
                    backgroundColor:
                      dataInputs.category === columnLibrary.name
                        ? ICON_GREEN_COLOR
                        : WHITE_COLOR,
                  }}
                >
                  <p>{columnLibrary.alias || columnLibrary.name} </p>
                </div>
                {dataInputs.category === columnLibrary.name && (
                  <Select
                    options={allOptions}
                    value={selectedOptions}
                    isMulti
                    onChange={handleSelectChange}
                    styles={selectStyle}
                  />
                )}
              </>
            );
          }
        })}
      </ContainerColumnSelect>
    </>
  );
};

export default SelectCategoryLineTimeSerious;
