// Readux
import { useSelector } from "react-redux";
import { openSelectColumns } from "redux/actions/digitalTwin";
import { useDispatch } from "react-redux";
// Helpers
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";
// Dependencies
import Select from "react-select";
import { customStyles } from "components/Drawers/DrawerPolygonTable/styles";

const SelectVisivilityColumns = ({ table }) => {
  const dispatch = useDispatch();
  const onpenSelect = useSelector(
    (state) => state.digitalTwinReducer.openSelectColumns
  );
  const options = table?.getAllLeafColumns().map((column) => ({
    value: column.id,
    label: column.id,
    column: column,
  }));
  const handleOpenClose = (bolean) => {
    dispatch(openSelectColumns(bolean));
  };

  return (
    <Select
      menuPlacement="auto"
      options={[{ value: "Toggle All", label: "Toggle All" }, ...options]}
      isMulti
      closeMenuOnSelect={false}
      menuIsOpen={onpenSelect}
      onMenuClose={() => handleOpenClose(false)}
      onMenuOpen={() => handleOpenClose(true)}
      placeholder="Column visibility"
      components={{
        Option: ({ label, data }) => (
          <div>
            <div>
              <input
                style={{
                  marginLeft: "5px",
                }}
                type="checkbox"
                checked={
                  data.value === "Toggle All"
                    ? table?.getIsAllColumnsVisible()
                    : data?.column.getIsVisible()
                }
                onChange={
                  data.value === "Toggle All"
                    ? table?.getToggleAllColumnsVisibilityHandler()
                    : data?.column.getToggleVisibilityHandler()
                }
              />{" "}
              {capitalizeFirstLetter(label)}
            </div>
          </div>
        ),
      }}
      styles={{
        ...customStyles,
        valueContainer: (styles) => ({
          ...styles,
          padding: "1px 2px",
          width: "24%",
        }),
        control: (styles) => {
          return {
            ...styles,
            width: "auto",
          };
        },
      }}
    />
  );
};

export default SelectVisivilityColumns;
