import axios from "axios";
import { urlsApiMg } from "utils/urlKeys";

const deleteViewTableColumns = async ({
  nameView,
  typeOfLibrary,
  typeOfTable,
}) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      urlsApiMg.orderColumns(
        `name=${nameView}&typeOfLibrary=${typeOfLibrary}&type=${typeOfTable}`
      ),
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    console.error(error);
  }
};

export default deleteViewTableColumns;
