// Required modules
import GenericTable from "components/Tables/GenericTable/GenericTable.jsx";
import Form from "components/Forms/Form.jsx";
import MessagesDialog from "components/Dialogs/MessagesDialog.jsx";
// Config admin class
import ConfigAdmin from "../ConfigAdmin.js";

// Hooks
import { useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableLibraryPoint,
  keyValuesTableLibraryPoint,
  initialValuesFormLibraryPoint,
  fromLibrary,
} from "../dataConfigAdmin";

// Styles
import {
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  InformationShare,
  IconAdminConfigChangeColum,
  TableAdminConfig,
} from "../configAdminStyles";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { ContainerForm } from "components/Forms/FormStyles";
import Fields from "pages/ConfigAdmin/typeElemts/Fields.jsx";
import { FROM_TABLE, TYPE_FIELDS, TYPE_POINTS } from "utils/constStrings.jsx";
import { roles } from "utils/roles.jsx";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import { DuplicateLibrary } from "./DuplicateLibrary.jsx";
import createPointTypeObject from "services/objects/createPointTypeObject.js";
// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <IoIosArrowDropupCircle className="icon" />
    </IconAdminConfigChangeColum>
  );
};

export default function ObjectLibraries({
  setOpenDialogConfig,
  setContentDialogConfig,
  adminCompanyId,
  adminsInfo,
}) {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [select] = useState(1); // id: 1 -> default parametrized library
  const [typeElementsSelect, setTypeElementsSelect] = useState([]);

  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      agree: "Close",
    },
  });

  const role = localStorage.getItem("role");

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormLibraryPoint);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);

  // State button loadingCreate
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
  });

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [libreriesRows, setLibreriesRows] = useState([]);

  // Click  on type element ghg into table
  const handleClickLibrary = (id) => {
    configAdmin.handlerClickIntoTable(id);
  };
  // Click on enable or disable library
  const handlerClickToggleLibrary = (id) => {
    configAdmin.handlerClickToggleLibrary(id);
  };

  // Set type library id
  // useEffect(() => {
  // setSelect(form?.typeLibraryId?.value);
  // label={form[key].label}
  // }, [setSelect, form]);

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableLibraryPoint);
    configAdmin.setKeyValuesTable(keyValuesTableLibraryPoint);
    configAdmin.setSetRows(setLibreriesRows);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetLoadingCreate(setLoadingCreate);
    configAdmin.setSetLoadingDelete(setLoadingDelete);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin.setCreateTypeElements(createPointTypeObject);
    configAdmin?.setEndpoint(
      adminCompanyId && adminCompanyId !== "defaultLibrary"
        ? `api/v1/point-libraries?adminCompanyId=${adminCompanyId}&`
        : `api/v1/point-libraries`
    );
    configAdmin?.setDialog(setOpenDialogConfig, setContentDialogConfig);
    configAdmin.setFromLibrary(fromLibrary.pointObjects);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, [setOpenDialogConfig, setContentDialogConfig, adminCompanyId]);

  // Set library in config admin
  useEffect(() => {
    configAdmin?.setEndpointFetch(
      adminCompanyId && adminCompanyId !== "defaultLibrary"
        ? `api/v1/point-libraries?adminCompanyId=${adminCompanyId}&`
        : `api/v1/point-libraries`
    );
    configAdmin?.fetchData();
  }, [configAdmin, adminCompanyId]);
  // Set type library
  useEffect(() => {
    configAdmin?.setSelect(select);
  }, [configAdmin, select]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  useEffect(() => {
    configAdmin && configAdmin.setsetTypeElementsSelect(typeElementsSelect);
    configAdmin && configAdmin.setsetAdminCompanyId(adminCompanyId);
  }, [typeElementsSelect, configAdmin, adminCompanyId]);

  useEffect(() => {
    const alert = configAdmin?.getAlertStatus();
    if (alert?.open) {
      setOpenAlert({
        open: alert.open,
        severity: alert.severity,
      });
    }
  }, [configAdmin?.getAlertStatus, configAdmin]);

  return (
    <>
      <RowAdminConfig>
        <OpenDialog
          openDialog={dialog?.open}
          setOpenDialog={setDialog}
          execute={() => {}}
          content={{
            title: "Select library and types to duplicate",
            description: (
              <DuplicateLibrary
                adminCompanyId={adminCompanyId}
                adminsInfo={adminsInfo}
                pointObject={true}
                configAdmin={configAdmin}
                handleChangeForm={handleChangeForm}
                setDialog={setDialog}
                setTypeElementsSelect={setTypeElementsSelect}
              />
            ),
          }}
          minWidth={"500px"}
        />
        <TableAdminConfig>
          {configAdmin && (
            <GenericTable
              headers={configAdmin.getHeadersTable()}
              rows={libreriesRows}
              keyValues={configAdmin.getKeyValuesTable()}
              handlerClick={handleClickLibrary}
              handlerClickToggleLibrary={handlerClickToggleLibrary}
              style={{
                width: 100,
              }}
              state={true}
              configBar={true}
              from={FROM_TABLE.config}
            />
          )}
        </TableAdminConfig>
        <ColumnAdminConfig>
          {seeForm ? (
            <ContainerForm see={seeForm}>
              <Form
                form={form}
                // setSelect={setSelect}
                // select={select}
                handleChange={handleChangeForm}
                handleRows={handleClickLibrary}
                buttons={buttonsForm}
                loadingCreate={loadingCreate}
                loadingDelete={loadingDelete}
                renderBeforeTitle={[
                  <ButtonSeeInitalView
                    click={() => configAdmin.toggleForm()}
                  />,
                ]}
                title={"Create a new Digital Twin Library"}
                renderBeforeButtons={[
                  <Fields
                    typeFields={TYPE_FIELDS.typeToLibrary}
                    form={form}
                    handleChange={handleChangeForm}
                    from={TYPE_POINTS.object}
                  />,
                ]}
                typeElementsSelect={typeElementsSelect}
                setTypeElementsSelect={setTypeElementsSelect}
                role={role}
              />
            </ContainerForm>
          ) : (
            <>
              <CreateNewRegionButton
                className="background-row-2 color1 border-color-1"
                margin={role === roles.decimetrixAdmin ? "7px 0" : "13px 0"}
              >
                Create a new Digital Twin Library
                <button onClick={() => configAdmin.toggleForm()}>
                  <span>
                    <IoMdAdd />
                  </span>
                  New Type Library
                </button>
                {role === roles.decimetrixAdmin &&
                  adminCompanyId !== "defaultLibrary" && (
                    <button
                      onClick={() =>
                        setDialog((current) => ({
                          ...current,
                          open: true,
                        }))
                      }
                    >
                      <span>
                        <IoMdAdd />
                      </span>
                      Duplicate Library from another Admin company{" "}
                    </button>
                  )}
              </CreateNewRegionButton>
              <InformationShare className="background-row-2 border-color-1">
                <div className="body color1">
                  To Edit Click on Existing Digital Twin Library ID
                </div>
              </InformationShare>
            </>
          )}
        </ColumnAdminConfig>
      </RowAdminConfig>
      {openAlert.open && (
        <MessagesDialog
          open={openAlert.open}
          severity={openAlert?.severity}
          message={configAdmin?.messageShow}
          handleClose={() => setOpenAlert(false)}
        />
      )}
    </>
  );
}
