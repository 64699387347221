import { useEffect, useState } from "react";
import { OperationsTooltipContainer } from "./BasicOperationsStyles";
import { fixDecimals } from "helpers/fixDecimals";
import {
  useFetchComponentsLibraries,
  useFetchEventLibraries,
  useFetchObjectLibraries,
  useFetchObjectLineLibraries,
} from "hooks/fetchLibraries";
import { useSelector } from "react-redux";
import { TYPE_ELEMENT } from "utils/constStrings";

function BasicOperationsTable({
  headerName,
  typeOfTable,
  isLine,
  pointLibraryId,
}) {
  const [dataToShow, setDataToShow] = useState({});
  const [fields, setFields] = useState([]);

  const allFilterTableRows = useSelector(
    (state) => state.digitalTwinReducer.filterTableRows
  );

  // Logic to determine which hook to use
  let useFetchHook;
  if (typeOfTable === TYPE_ELEMENT.OBJECT && !isLine) {
    useFetchHook = useFetchObjectLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.COMPONENT) {
    useFetchHook = useFetchComponentsLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.EVENT) {
    useFetchHook = useFetchEventLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.OBJECT && isLine) {
    useFetchHook = useFetchObjectLineLibraries;
  }

  const { data: dataLibrary, error: dataLibraryError } = useFetchHook({
    id: pointLibraryId,
  });

  useEffect(() => {
    if (dataLibrary && dataLibrary.length && !dataLibraryError) {
      // choose between complete data or filtered
      const extractedNumFields =
        typeOfTable === TYPE_ELEMENT.OBJECT && isLine
          ? extractNumericValues({
              rows: allFilterTableRows, // allFilterTableRows === rows length → no filters
              dataLibrary,
              optionalFields: ["distance"],
            })
          : extractNumericValues({
              rows: allFilterTableRows, // allFilterTableRows === rows length → no filters
              dataLibrary,
            });
      setFields(extractedNumFields);
    }
  }, [dataLibrary, allFilterTableRows, dataLibraryError, isLine, typeOfTable]);

  useEffect(() => {
    const fieldData = fields.filter(
      (it) => it.name === headerName || it.alias === headerName
    );
    let temp = {};
    if (fieldData.length > 0) {
      const { values } = fieldData?.at(0);
      const { count, numericalCount, nonNumericalCount } = values.reduce(
        (acc, item) => {
          if (
            (typeof item !== "number" && item === "Math Error") ||
            isNaN(item)
          ) {
            acc.nonNumericalCount++;
          } else {
            acc.numericalCount++;
          }
          acc.count++;
          return acc;
        },
        { count: 0, numericalCount: 0, nonNumericalCount: 0 }
      );
      temp.count = count;
      temp.numCount = numericalCount;
      temp.noNumCount = nonNumericalCount;

      const { max, min, sum } = values.reduce(
        (acc, num) => {
          if (isNaN(num)) return acc;
          return {
            max: Math.max(acc.max, num),
            min: Math.min(acc.min, num),
            sum: typeof num === "number" ? acc.sum + num : acc.sum + 0,
          };
        },
        { max: -Infinity, min: Infinity, sum: 0 }
      );
      temp.max = max;
      temp.min = min;
      temp.sum = sum;
      temp.avg = sum / numericalCount;
    }
    setDataToShow(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return fields.length && Object.keys(dataToShow).length ? (
    <OperationsTooltipContainer>
      <div className="class-title">Operations</div>
      <div className="class-operation-container">
        <span className="class-operation">
          <div>Count:</div>
          <div>{dataToShow?.count}</div>
        </span>
        <span className="class-operation">
          <div>Numerical count:</div>
          <div>{dataToShow?.numCount}</div>
        </span>
        <span className="class-operation">
          <div>No numerical count:</div>
          <div>{dataToShow?.noNumCount}</div>
        </span>
        <span className="class-operation">
          <div>Min:</div>
          <div>{fixDecimals(dataToShow?.min, 4)}</div>
        </span>
        <span className="class-operation">
          <div>Max:</div>
          <div>{fixDecimals(dataToShow?.max, 4)}</div>
        </span>
        <span className="class-operation">
          <div>Average:</div>
          <div>{fixDecimals(dataToShow?.avg, 4)}</div>
        </span>
        <span className="class-operation">
          <div>Sum:</div>
          <div>{fixDecimals(dataToShow?.sum, 4)}</div>
        </span>
      </div>
    </OperationsTooltipContainer>
  ) : (
    <></>
  );
}

// helpers
const extractNumericValues = ({
  rows = [],
  dataLibrary = [],
  optionalFields = [],
}) => {
  // generate the schema of library fields to work with filtering field types for 'number' or 'operation'
  const schema =
    dataLibrary?.[0]?.fields?.filter(
      (fd) => fd.type === "number" || fd.type === "operation"
    ) || [];
  if (optionalFields.length) {
    optionalFields.forEach((optional) => {
      schema.push({
        name: optional,
        alias: optional,
        type: "number",
      });
    });
  }
  // if no esquema, return empty array so execution continues
  if (!schema.length) return [];
  const numFieldValues = schema.reduce((acc, { alias }) => {
    acc[alias] = [];
    return acc;
  }, {});
  // cycle rows from data and get the values by field alias identificator
  rows.forEach((point) => {
    schema.forEach(({ alias }) => {
      const value = point[alias];
      numFieldValues[alias].push(value); // all values even NaN or "Math Error"
      // if (!isNaN(value)) numFieldValues[alias].push(value);
    });
  });
  // retrieve names with values
  return schema.map(({ name, alias }) => ({
    name,
    alias,
    values: numFieldValues[name] ?? numFieldValues[alias],
  }));
};

export default BasicOperationsTable;
