import axios from "axios";
import { urls } from "utils/urlKeys";
const deleteAdminCompany = async (id, instanceId) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    urls.users.deleteAdminCompany(id, instanceId),
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default deleteAdminCompany;
