import { useState, useEffect } from "react";
import { urls } from "utils/urlKeys";

const adminCompanyId = localStorage.getItem("adminCompanyId");

async function fetchLineLibraries(id) {
  try {
    const urlParams = new URLSearchParams();
    const baseUrl = urls.lines.libraries;

    if (id && !isNaN(parseInt(id))) {
      urlParams.append("id", parseInt(id));
    }

    const finalUrl = urlParams.toString()
      ? `${baseUrl}&${urlParams.toString()}`
      : baseUrl;

    const token = localStorage.getItem("token");

    const response = await fetch(finalUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    if (!response.ok) {
      console.error(`🚩Error fetching data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("🚨 Error in fetchLineLibraries:", error);
  }
}

function useGetLineLibraries({ id = null } = {}) {
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const shouldFetch =
      id === null && adminCompanyId === null
        ? true
        : id !== null || adminCompanyId !== null;

    if (!shouldFetch) {
      return;
    }

    const getData = async () => {
      setIsLoading(true);
      try {
        const result = await fetchLineLibraries(id);
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();

    return () => {
      console.log("🧹 Cleanup function called");
    };
  }, [id]);

  return { data, error, isLoading };
}

export default useGetLineLibraries;
