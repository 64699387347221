// Style
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
// Helpers
import { isUrl, nameUrl, handleClickLink } from "helpers/isUrl";
import { formatFloat } from "helpers/formatFloat";
import isoDateToString from "helpers/isoDateToString";
import { orderAttributes } from "components/ElementDetails/helpers";
// Hooks
import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import OperationInfo from "../OperationInfo";
import EditIcon from "@mui/icons-material/Edit";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  BLACK_COLOR,
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
} from "utils/const";
import useUpdateLocationPoint from "hooks/useUpdateLocationPoint";
import { useContext } from "react";
import { ThemeContext } from "App";
import { LineTag } from "pages/ConfigAdmin/typeElemts/FieldsStyle";

export default function TableObject({ object }) {
  const { data: dataLibrary, error: errorLibrary } = useFetchObjectLibraries({
    id: object?.typeElement?.pointLibraryId,
  });
  const { theme } = useContext(ThemeContext);

  const {
    data: { updateLongitude, updateLatitude, updateLocation },
    setData: { setUpdateLongitude, setUpdateLatitude, setUpdateLocation },
    handleUpdateLocation,
    handleCancelUpdate,
  } = useUpdateLocationPoint({ type: "object", point: object });

  const reduceAttributes = () => {
    if (errorLibrary) return [];

    object?.attributes?.forEach((attribute) => {
      const matchAlias = dataLibrary?.[0]?.fields?.find(
        (fields) => fields.name === attribute.name
      );
      if (matchAlias) {
        if (matchAlias.QAQC && !matchAlias.alias.includes("✅")) {
          return (attribute.alias = matchAlias.alias + "✅");
        }
        return (attribute.alias = matchAlias.alias);
      }
    });

    const filteredAttributes = object?.attributes?.map((item) => {
      let newItem = { ...item };
      try {
        if (typeof JSON.parse(item.value) === "object")
          newItem.value = JSON.parse(item.value);
      } catch (error) {
        return item;
      }
      return newItem;
    });

    const lineTagAttributes =
      dataLibrary?.[0]?.fields?.filter((field) => field.type === "lineTag") ||
      [];

    const combinedAttributes = [...filteredAttributes, ...lineTagAttributes];

    const newOrderAttributes = orderAttributes({
      // arrayToOrder: filteredAttributes,
      arrayToOrder: combinedAttributes,
      fromArray: dataLibrary,
    });

    return newOrderAttributes;
  };

  return (
    <div className="main-currency-table">
      <div className="currency-table--container">
        <table>
          <tr
            style={{
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
              color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
            }}
          >
            <td>Source</td>
            <td className="table__right">
              {object.dataSourceId === 1 ? (
                <p>Mobile</p>
              ) : object.dataSourceId === 2 ? (
                <p>Desktop</p>
              ) : object.dataSourceId === 3 ? (
                <p>CSV File</p>
              ) : null}
            </td>
          </tr>
          <tr
            style={{
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
              color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
            }}
          >
            <td>Created By</td>
            <td className="table__right">
              {" "}
              {object.user.operator?.firstName}{" "}
              {object.user.operator?.firstLastName}
              {object.user.adminCompany?.firstName}{" "}
              {object.user.adminCompany?.firstLastName}
            </td>
          </tr>
          <tr
            style={{
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
              color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
            }}
          >
            <td>Collection Date</td>
            <td className="table__right">{isoDateToString(object.date)}</td>
          </tr>
          <tr
            style={{
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
              color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
            }}
          >
            <td>
              <div>
                Longitude
                {updateLongitude && (
                  <>
                    <button
                      style={{ marginLeft: "1rem" }}
                      onClick={() =>
                        handleUpdateLocation({ coordinate: "longitude" })
                      }
                    >
                      <DoneIcon
                        sx={{
                          width: "2rem",
                          color: PRIMARY_COLOR,
                          "&:hover": {
                            color: SECOND_COLOR,
                          },
                        }}
                      />
                    </button>
                    <button
                      onClick={() =>
                        handleCancelUpdate({ coordinate: "longitude" })
                      }
                    >
                      <ClearIcon
                        sx={{
                          width: "2rem",
                          color: DELETE_COLOR,
                          "&:hover": {
                            color: HOVER_DELETE_COLOR,
                          },
                        }}
                      />
                    </button>
                  </>
                )}
              </div>
            </td>
            <td className="table__right">
              {!updateLongitude ? (
                <>
                  {updateLocation.longitude}
                  <button onClick={() => setUpdateLongitude(true)}>
                    <EditIcon
                      sx={{
                        width: "1.5rem",
                        color: "var(--background-primary_color)",
                        "&:hover": {
                          color: "var(--background-secondary_color)",
                        },
                      }}
                    />
                  </button>
                </>
              ) : (
                <input
                  type="number"
                  step="any"
                  value={updateLocation.longitude}
                  onChange={(e) =>
                    setUpdateLocation((prev) => ({
                      ...prev,
                      longitude: e.target.value,
                    }))
                  }
                />
              )}
            </td>
          </tr>
          <tr
            style={{
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
              color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
            }}
          >
            <td>
              <div>
                Latitude
                {updateLatitude && (
                  <>
                    <button style={{ marginLeft: "1rem" }}>
                      <DoneIcon
                        sx={{
                          width: "2rem",
                          color: PRIMARY_COLOR,
                          "&:hover": {
                            color: SECOND_COLOR,
                          },
                        }}
                        onClick={() =>
                          handleUpdateLocation({ coordinate: "latitude" })
                        }
                      />
                    </button>
                    <button
                      onClick={() =>
                        handleCancelUpdate({ coordinate: "latitude" })
                      }
                    >
                      <ClearIcon
                        sx={{
                          width: "2rem",
                          color: DELETE_COLOR,
                          "&:hover": {
                            color: HOVER_DELETE_COLOR,
                          },
                        }}
                      />
                    </button>
                  </>
                )}
              </div>
            </td>
            <td className="table__right">
              {!updateLatitude ? (
                <>
                  {updateLocation.latitude}
                  <button onClick={() => setUpdateLatitude(true)}>
                    <EditIcon
                      sx={{
                        width: "1.5rem",
                        color: "var(--background-primary_color)",
                        "&:hover": {
                          color: "var(--background-secondary_color)",
                        },
                      }}
                    />
                  </button>
                </>
              ) : (
                <input
                  type="number"
                  step="any"
                  value={updateLocation.latitude}
                  onChange={(e) =>
                    setUpdateLocation((prev) => ({
                      ...prev,
                      latitude: e.target.value,
                    }))
                  }
                />
              )}
            </td>
          </tr>
          <tr
            style={{
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
              color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
            }}
          >
            <td>Object ID</td>
            <td className="table__right">{object.id}</td>
          </tr>

          {object.lastUpdate !== null && (
            <>
              {object?.userUpdate?.adminCompany !== null ? (
                <tr
                  style={{
                    background:
                      theme === "light"
                        ? BACKGROUND_GREY
                        : BACKGROUND_SECOND_DARK,
                    color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                  }}
                >
                  <td>Update By</td>
                  <td className="table__right">
                    {" "}
                    {object?.userUpdate?.adminCompany.firstName}{" "}
                    {object?.userUpdate?.adminCompany.firstLastName}
                  </td>
                </tr>
              ) : object?.userUpdate?.adminDecimetrix !== null ? (
                <tr
                  style={{
                    background:
                      theme === "light"
                        ? BACKGROUND_GREY
                        : BACKGROUND_SECOND_DARK,
                    color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                  }}
                >
                  <td>Update By</td>
                  <td className="table__right">
                    {" "}
                    {object?.userUpdate?.adminDecimetrix.firstName}{" "}
                    {object?.userUpdate?.adminDecimetrix.firstLastName}
                  </td>
                </tr>
              ) : object?.userUpdate?.operator ? (
                <tr
                  style={{
                    background:
                      theme === "light"
                        ? BACKGROUND_GREY
                        : BACKGROUND_SECOND_DARK,
                    color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                  }}
                >
                  <td>Update By</td>
                  <td className="table__right">
                    {" "}
                    {object?.userUpdate?.operator.firstName}{" "}
                    {object?.userUpdate?.operator.firstLastName}
                  </td>
                </tr>
              ) : null}
              <tr
                style={{
                  background:
                    theme === "light"
                      ? BACKGROUND_GREY
                      : BACKGROUND_SECOND_DARK,
                  color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                }}
              >
                <td>Last Update</td>
                <td className="table__right">
                  {isoDateToString(object.lastUpdate)}
                </td>
              </tr>
            </>
          )}
          {object &&
            reduceAttributes()?.map((item, index) => {
              if (item?.type === "lineTag") {
                return (
                  <tr
                    key={`lineTag-${index}`}
                    style={{
                      background: theme === "light" ? "#f9f9f9" : "#121212",
                      color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                    }}
                  >
                    <td colSpan={2}>
                      <h3
                        style={{
                          color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                          padding: "0.5rem",
                          margin: "0.5rem 0",
                          marginTop: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        {item.alias || item.name}
                      </h3>
                      <LineTag />
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr
                    key={`attr-${index}`}
                    style={{
                      background:
                        theme === "light"
                          ? BACKGROUND_GREY
                          : BACKGROUND_SECOND_DARK,
                      color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                    }}
                  >
                    <td className="table__left">
                      {item.alias || item.name}
                      {item?.value?.operation && (
                        <OperationInfo content={item?.value} />
                      )}
                    </td>
                    <td className="table__right">
                      {isUrl(item?.value) ? (
                        <a
                          className="link-style"
                          onClick={(e) => handleClickLink(e, item?.value)}
                        >
                          {nameUrl(item?.value)}
                        </a>
                      ) : item?.value === null ? (
                        ""
                      ) : typeof item?.value !== "object" ? (
                        item?.value
                      ) : (
                        formatFloat(item?.value?.value, 6)
                      )}
                      {item?.value === null &&
                        Array.isArray(item?.valueMultiSelect) &&
                        item?.valueMultiSelect.length > 0 && (
                          <div>
                            {item?.valueMultiSelect
                              .filter(
                                (val) =>
                                  typeof val === "string" && val.trim() !== ""
                              )
                              .join(", ")}
                          </div>
                        )}
                    </td>
                  </tr>
                );
              }
            })}
        </table>
      </div>
    </div>
  );
}
