import axios from "axios";
import { config } from "config.js";

const token = localStorage.getItem("token");

const axiosMg = axios.create({
  baseURL: `${config.URL_BACKEND_MG}group-doughnut-chart`,
  headers: {
    Authorization: `Bearer ${token} : ""`,
  },
});

export const createChart = async (body) => {
  const res = await axiosMg.post("/", body);
  const data = await res.data;
  return { data, res };
};

export const updateChart = async ({ id, body }) => {
  const res = await axiosMg.patch(`/${id}`, body);
  const data = await res.data;
  return { data, res };
};

// Delete kpi chart

export const deleteChartGroupDoughnut = async ({ id }) => {
  const res = await axiosMg.delete(`/${id}`);
  const data = await res.data;
  return { data, res };
};
