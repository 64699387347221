// React
import { useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { useEffect, useState } from "react";
// Redux
import { setGeometryLine, setGeometryLineConfig } from "redux/actions/admin";
import {
  setDrawLineMode,
  setMessageLoadingMap,
  setNeuronSelectTool,
  setObjecstsLinesIds,
} from "redux/actions/digitalTwin";
// import { setObjectsUnderDrawnPoint } from "redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
// Components
import TextInput from "../GenericInput";
import SelectInput from "../SelectInput";
import CircularIndeterminate from "components/Lodings/LoadingV2";
import CustomButton from "components/Buttons/CustomButton";
// Custom hooks
import useViewFilterClusterMap from "hooks/useViewFilterClusterMap";
// import useGet from "hooks/useFetch";
// Style
import { Form } from "./DynamicFieldsStyle";
import { StepSection } from "../Events/DynamicEventStyles";
// Services
import { saveLineApiPg } from "services/lines/saveLine";
import bodyLineWithFiles from "services/lines/createBodyLine";
import useSwr from "swr";
// Config
import { config } from "config.js";
import { calculateLength } from "components/DigitalTwin/Lines/LinesServices";
import { CREATED_FROM } from "utils/constStrings";
import { setDrawAnyThing } from "redux/actions";
import moment from "moment";
import { updateLinePg } from "services/lines/updateLine";
import useGetLineLibraries from "./hooks/getLineLibraries";
import useGetLineTypes from "./hooks/getLineTypes";
import { urls, urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

function Formulario({
  genericData,
  dataLine,
  update = false,
  setOpenDialog,
  handleUpdateData,
}) {
  // Local storage
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");
  const { instanceId } = useUserStore((state) => state);
  // States
  const [typeLineElements, setTypeLinesElements] = useState();
  const [loading, setLoading] = useState(false);
  const [globalTableCategories, setGlobalTableCategories] = useState(null);

  const [circularIndeterminate, setCircularIndeterminate] = useState(false);

  // Custom hooks
  const { data: lineLibraries, error: errorLineLibraries } =
    useGetLineLibraries({ id: null });

  const { data: lineTypes, error: errorLineType } = useGetLineTypes({
    id: genericData?.libraryId,
  });

  //Get Data Global Tables
  const { data: globalTables, error: errorGlobalTables } = useSwr(
    urlsApiMg.globalValuesFields
  );

  // Swr
  const { mutate } = useSWRConfig();
  // Redux
  const dispatch = useDispatch();
  // CustomHooks
  const { handleSetFilterPoints } = useViewFilterClusterMap({});

  // Forms
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();

  const handleChangeGlobalSelects = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    setForm({ ...form, [name]: value });
  };

  const objectsLineIds = useSelector(
    (state) => state.digitalTwinReducer.objectsLinesIds
  );

  const controlGl = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );

  // Effects
  useEffect(() => {
    const lineLibWithFields = lineLibraries?.find(
      (lib) =>
        lib.id === genericData?.libraryId ||
        lib.id === dataLine?.lineType?.lineLibraryId
    );
    if (lineLibWithFields) {
      setTypeLinesElements(lineLibWithFields);
    }
  }, [
    lineTypes,
    errorLineType,
    genericData?.libraryId,
    lineLibraries,
    dataLine,
  ]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericData?.line?.typeElementId]);

  useEffect(() => {
    if (update) {
      const existingFieldNames = dataLine?.attributes?.map(
        (attribute) => attribute.name
      );
      !errorLineLibraries &&
        typeLineElements?.fields?.forEach((field) => {
          if (
            !existingFieldNames.includes(field.name) &&
            field.type !== "notification" &&
            field.type !== "captureMedia"
          ) {
            const newAttribute = {
              name: field.name,
              alias: field.alias,
              value: "",
              userId: null
            };
            dataLine.attributes.push(newAttribute);
          }
        });

      if (dataLine) {
        dataLine?.attributes?.map((elm) => {
          if (elm.value?.[0] === "{") {
            const dataParse = JSON.parse(elm.value);
            if (dataParse.type === "number" && dataParse.value) {
              return setValue(elm.name, parseFloat(dataParse.value));
            }
          } else {
            if (elm.value === "true" || elm.value === "false") {
              return setValue(elm.name || elm.alias, JSON.parse(elm.value));
            }
            const value =
              elm?.value?.length === 0 || elm?.value === " "
                ? ""
                : !isNaN(elm.value)
                  ? parseFloat(elm.value)
                  : elm.value;
            setValue(elm.name, value);
          }
          return null;
        });
      }
    }
  }, [dataLine, update]);

  useEffect(() => {
    if (typeLineElements && typeLineElements.fields.length !== 0) {
      const selectGlobal = typeLineElements.fields.filter((elm) => {
        return (
          elm.type === "select" &&
          elm.globalSelect &&
          !elm.hasOwnProperty("columnKeyGlobalSpecs")
        );
      });
      typeLineElements.fields.forEach((elm) => {
        if (
          (elm.type === "string" ||
            elm.type === "number" ||
            elm.type === "file" ||
            elm.type === "date") &&
          elm.globalSelect &&
          globalTableCategories
        ) {
          const matchSelect = selectGlobal.find((item) => {
            return (
              elm?.columnKeyGlobalSpecs?.selectGlobalId === item._id ||
              elm?.columnKeyGlobalSpecs?.selectGlobalName === item.name
            );
          });
          const findCategory = globalTableCategories?.find((item) => {
            return elm.columnKeyGlobalSpecs.categoryId === item._id;
          });

          const matchTbale = findCategory?.groupTables?.find((item) => {
            return elm.columnKeyGlobalSpecs.tableId === item._id;
          });

          const row = matchTbale?.rows?.find((row) => {
            return (
              row?.Options ===
              getValues(matchSelect?.name || matchSelect?.alias)
            );
          });

          if (row) {
            return setValue(
              elm?.name || elm?.alias,
              row[elm.columnKeyGlobalSpecs.column.name]
            );
          }
        }
      });
    }
  }, [
    handleChangeGlobalSelects,
    globalTableCategories,
    typeLineElements,
    globalTables,
    errorGlobalTables,
  ]);

  // Effects
  useEffect(() => {
    if (!errorGlobalTables && globalTables && globalTables.length > 0) {
      setGlobalTableCategories(globalTables[0].tableCategories);
    }
  }, [globalTables, errorGlobalTables]);

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    urls.users.getOperators
  );
  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];
  const [form, setForm] = useState({});

  // Method
  const onSubmit = async (data) => {
    setLoading(true);
    if (update) {
      const numericFields = await typeLineElements.fields
        ?.map((field) => {
          if (field.type === "number") {
            const elmt = document.getElementById(field.name);
            if (elmt) {
              field.value = elmt.value;
              return field;
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = await typeLineElements.fields?.filter(
        (field) => field.type === "operation"
      );

      if (numericFields.length > 0)
        numericFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });
      if (expFields.length > 0)
        expFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });

      const entries = Object.entries(data);
      let attributesWithValues = entries.map(([key, value]) => {
        const existingAttribute = dataLine.attributes.find(
          (attr) => attr.name === key || attr.alias === key
        );
        const typeLineField = typeLineElements.fields.find(
          (field) => field.name === key
        );

        if (typeLineField?.type === "delegate") {
          const userDelegate = allOperators.find(
            (elm) => `${elm?.firstName} ${elm?.firstLastName}` === value
          );
          return {
            name: key,
            value: value,
            alias: typeLineField ? typeLineField.alias : key,
            userId: userDelegate.userId,
            [`delegate-userId-${key}`]: userDelegate.userId,
          };
        }
        if (existingAttribute) {
          return {
            ...existingAttribute,
            value: value,
            alias: typeLineField
              ? typeLineField.alias
              : existingAttribute.alias,
          };
        }
        // Si no existe en dataLine.attributes, creamos un nuevo objeto
        return {
          name: key,
          value: value,
          alias: typeLineField ? typeLineField.alias : key,
        };
      });

      const fileFields = typeLineElements.fields.filter(
        (field) => field.type === "file"
      );

      const bodyObject = await bodyLineWithFiles(fileFields, data);

      // Combinar attributesWithValues con bodyObject.attributes
      let combinedAttributes = [...attributesWithValues];
      bodyObject.attributes.forEach((attr) => {
        const index = combinedAttributes.findIndex(
          (item) => item.name === attr.name
        );
        if (index !== -1) {
          combinedAttributes[index] = { ...combinedAttributes[index], ...attr };
        } else {
          combinedAttributes.push(attr);
        }
      });

      const newAttributes = combinedAttributes
        .map((attribute) => {
          const matchingField = typeLineElements.fields.find(
            (field) => field.name === attribute.name && field.globalSelect
          );
          if (matchingField) {
            return {
              ...attribute,
              autocomplete: matchingField.globalSelect,
              columnKeyGlobalSpecs:
                matchingField.type === "select"
                  ? {
                    categoryId: matchingField.globalTable.categoryId,
                    tableId: matchingField.globalTable.tableId,
                    column: {
                      name: "Options",
                    },
                    selectGlobalName: matchingField.name,
                    globalKey: data[matchingField.name],
                    pointLibrary: {
                      id: typeLineElements.lineLibraryId,
                      mongoId: null,
                    },
                    typeElementId: genericData?.lineTypeId,
                  }
                  : {
                    ...matchingField.columnKeyGlobalSpecs,
                    globalKey:
                      data[
                      matchingField.columnKeyGlobalSpecs.selectGlobalName
                      ],
                    pointLibrary: {
                      id: typeLineElements.lineLibraryId,
                      mongoId: null,
                    },
                    typeElementId: genericData?.lineTypeId,
                  },
            };
          } else {
            return attribute;
          }
        })
        .filter((attribute) =>
          typeLineElements.fields.some(
            (field) => field.name === attribute.name && attribute.alias
          )
        );

      const dateUpdate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS Z");
      const indx = dateUpdate.lastIndexOf(":");
      const dateUpdateParsed = dateUpdate
        .substring(0, indx)
        .concat(dateUpdate.substring(indx + 1));

      let body = {
        updatedAt: dateUpdateParsed,
        userUpdatedBy: userId,
        attributes: newAttributes,
      };

      await updateLinePg(body, dataLine?.id);

      handleUpdateData();

      mutate(`${config.URL_BACKEND_PG}api/v1/lines?id=${dataLine.id}`);
      setOpenDialog(false);
    } else {
      //get all numeric fields from the form
      const numericFields = await typeLineElements.fields
        ?.map((field) => {
          if (field.type === "number") {
            const elmt = document.getElementById(field.name);
            if (elmt) {
              field.value = elmt.value;
              return field;
            }
          }
          return null;
        })
        .filter((it) => it !== null);
      // get all the expression fields
      const expFields = await typeLineElements?.fields?.filter(
        (field) => field.type === "operation"
      );
      // stringify the operation fields
      if (numericFields?.length > 0)
        numericFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });
      if (expFields?.length > 0)
        expFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });

      const fileFields = typeLineElements?.fields?.filter(
        (field) => field.type === "file"
      );

      const bodyObject = await bodyLineWithFiles(fileFields, data, genericData);

      // add alias to every field, if exists
      let tmp = {};
      bodyObject?.attributes?.map((item, index) => {
        tmp = typeLineElements?.fields.find((it) => it.name === item.name);
        if (tmp) bodyObject.attributes[index].alias = tmp?.alias || "";
        if (tmp?.type === "delegate") {
          const userDelegate = allOperators.find(
            (elm) => `${elm?.firstName} ${elm?.firstLastName}` === item.value
          );
          bodyObject.attributes[index].userId = userDelegate.userId;
          bodyObject.attributes[index][`delegate-userId-${item.name}`] = userDelegate.userId;
        }
        return item;
      });

      const attributes =
        bodyObject.attributes !== undefined ? [...bodyObject.attributes] : [];

      const newAttributes = attributes
        .map((attribute) => {
          const matchingField = typeLineElements.fields.find(
            (field) => field.name === attribute.name && field.globalSelect
          );
          if (matchingField) {
            return {
              ...attribute,
              autocomplete: matchingField.globalSelect,
              columnKeyGlobalSpecs:
                matchingField.type === "select"
                  ? {
                    categoryId: matchingField.globalTable.categoryId,
                    tableId: matchingField.globalTable.tableId,
                    column: {
                      name: "Options",
                    },
                    selectGlobalName: matchingField.name,
                    globalKey: data[matchingField.name],
                    pointLibrary: {
                      id: typeLineElements.lineLibraryId,
                      mongoId: null,
                    },
                    typeElementId: genericData?.lineTypeId,
                  }
                  : {
                    ...matchingField.columnKeyGlobalSpecs,
                    globalKey:
                      data[
                      matchingField.columnKeyGlobalSpecs.selectGlobalName
                      ],
                    pointLibrary: {
                      id: typeLineElements.lineLibraryId,
                      mongoId: null,
                    },
                    typeElementId: parseInt(genericData?.lineTypeId),
                  },
            };
          } else {
            return attribute;
          }
        })
        .filter((attribute) =>
          typeLineElements.fields.some(
            (field) => field.name === attribute.name && attribute.alias
          )
        );

      const currentLineType = lineTypes.find((type) => {
        return type.id === parseInt(genericData.lineTypeId);
      });

      const { color, dasharrayWidth, dasharrayPixels } = currentLineType;
      bodyObject.feature[0].properties = {
        ...bodyObject.feature[0].properties,
        lineTypeId: currentLineType?.id,
        nameTypeLine: currentLineType?.type,
        descriptionTypeLine: currentLineType.description,
        color,
        dasharrayWidth,
        dasharrayPixels,
        distance: calculateLength({
          coordinates: bodyObject.feature[0].geometry.coordinates,
          unit: "kilometers",
          floatingDecimals: 10, // 10 because of max number of decimals on calculation
        }),
        collectionDate: new Date(),
        source: CREATED_FROM.desktop,
      };
      const newBody = {
        ...bodyObject,
        attributes: newAttributes,
        instanceId: instanceId ? instanceId : null,
      };

      const res = await saveLineApiPg(newBody, objectsLineIds);
      if (res) {
        mutate(urls.lines.all);
        dispatch(setGeometryLine({}));
        dispatch(setGeometryLineConfig({}));
        dispatch(setObjecstsLinesIds([]));
        dispatch(
          setDrawAnyThing({
            isDraw: false,
            type: "",
          })
        );
        controlGl.deleteAll();
        dispatch(
          setDrawLineMode({
            mode: "",
            drawStatus: false,
            show: false,
            features: [],
          })
        );
        dispatch(
          setNeuronSelectTool({
            show: false,
            top: null,
            right: null,
          })
        );
        dispatch(setMessageLoadingMap(true));
        controlGl.trash();
      }
    }
    handleSetFilterPoints();
    setLoading(false);
    reset();
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (typeLineElements === undefined) {
      setCircularIndeterminate(true);
      return;
    }
    setCircularIndeterminate(false);
  }, [typeLineElements]);

  return circularIndeterminate ? (
    <CircularIndeterminate />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {typeLineElements?.fields && (
        <div>
          {typeLineElements.fields?.map((field, index) => {
            if (field.type === "operation") {
              <TextInput
                key={index}
                field={field}
                register={register}
                errors={errors}
              />;
            }
            if (field.type === "select" && field.globalSelect) {
              const currentCategory = globalTableCategories?.find(
                (category) => {
                  return category._id === field.globalTable.categoryId;
                }
              );
              const selectTable = currentCategory?.groupTables.find((table) => {
                return table._id === field.globalTable.tableId;
              });

              return (
                <div key={index} onChange={(e) => handleChangeGlobalSelects(e)}>
                  <SelectInput
                    key={index}
                    field={{
                      name: field.name,
                      options: selectTable?.rows?.map((elm) => elm.Options),
                      required: field.required,
                      type: field.type,
                      alias: field.alias || field.name,
                    }}
                    register={register}
                  />
                </div>
              );
            }
            if (field.type === "select") {
              return (
                <SelectInput key={index} field={field} register={register} />
              );
            }
            if (field.type === "currency") {
              return (
                <div key={index}>
                  <TextInput
                    field={field}
                    register={register}
                    errors={errors}
                  />
                  <SelectInput field={field} register={register} />
                </div>
              );
            }
            if (field.type === "range") {
              return (
                <div key={index} onChange={(e) => handleChange(e)}>
                  <TextInput
                    key={index}
                    field={{
                      name: field.name,
                      alias: field.alias,
                      required: field.required,
                      type: field.type,
                      step:
                        field.options.length !== 0
                          ? parseFloat(field.options[0])
                          : 1,
                      max: field.max,
                      min: field.min,
                    }}
                    value={watch(field.name)}
                    register={register}
                    errors={errors}
                  />
                </div>
              );
            }
            if (field.type === "delegate") {
              if (
                form[field.name] === "" &&
                operatorsAdmin &&
                !errorOperators
              ) {
                form[
                  field.name
                ] = `${allOperators?.[0]?.firstName} ${allOperators?.[0]?.firstLastName}`;
              }
              return (
                <div onChange={(e) => handleChange(e)}>
                  <SelectInput
                    key={index}
                    field={{
                      name: field.name,
                      options: allOperators.map((operator) => {
                        return `${operator?.firstName} ${operator?.firstLastName}`;
                      }),
                      required: field.required,
                      type: field.type,
                      alias: field.alias || field.name,
                    }}
                    register={register}
                  />
                </div>
              );
            }
            if (field.type === "file") {
              return (
                <div onChange={(e) => handleChange(e)} key={index}>
                  <TextInput
                    field={{
                      ...field,
                      name: field.name,
                      required: update ? false : field.required,
                      type: field.type,
                      possible: field.possible,
                      alias: field.alias || field.name,
                      warning: field.globalSelect ? true : false,
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
              );
            }
            if (field.type === "captureMedia") return null;
            if (field.type === "notification") return null;
            return (
              <TextInput
                key={index}
                field={{
                  ...field,
                  warning: field.globalSelect ? true : false,
                }}
                register={register}
                errors={errors}
              />
            );
          })}
        </div>
      )}
      <CustomButton
        type={"submit"}
        isLoad={loading}
        text={update ? "Update" : "Save"}
        margin={0}
      />
    </Form>
  );
}

export default Formulario;
