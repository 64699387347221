import { useState, useEffect, useCallback } from "react";

// Helpers
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/objectsTimeSeriesChart";
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";

export const useFindTimeSeriesChartObjects = ({
  openDialog,
  objects,
  loading,
}) => {
  const [charts, setCharts] = useState(null);
  const [timeSeriesCharts, setTimeSeriesChart] = useState(null);
  const [objectsFormat, setObjectsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataLineChart, error: errorDataLineChart } = useSwr(
    urlsApiMg.dashboard.timeSeriesChart("objects")
  );

  useEffect(() => {
    if (!errorDataLineChart && dataLineChart) setCharts(dataLineChart);
  }, [dataLineChart, errorDataLineChart, openDialog, reloadCharts]);

  useEffect(() => {
    const objectsFormat = createRowsObjects({ objects });
    setObjectsFormat(objectsFormat);
  }, [objects]);

  const getDataTimeSeriesChart = useCallback(async () => {
    if (!loading && objectsFormat) {
      const lineCountsvalues = countByValues({
        charts,
        objects: objectsFormat,
      });

      const timeSeriesSumValues = sumValues({
        charts,
        objects: objectsFormat,
      });

      setTimeSeriesChart([...lineCountsvalues, ...timeSeriesSumValues]);
    }
  }, [loading, objectsFormat, charts]);

  useEffect(() => {
    if (charts && objects) {
      getDataTimeSeriesChart();
    }
  }, [charts, objects, getDataTimeSeriesChart]);

  return { timeSeriesCharts };
};
