import { parse, parseISO, isBefore, isAfter } from "date-fns";
import { useCallback, useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useFilterEventsByDateAndUser = ({ events, users }) => {
  const [filterEvents, setFilterEvents] = useState([]);

  const dateFilterObject = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );

  const { endDate: endDateString, startDate: startDateString } =
    dateFilterObject;

  const endDate = useMemo(
    () => parse(endDateString, "yyyy-MM-dd", new Date()),
    [endDateString]
  );
  const startDate = useMemo(
    () => parse(startDateString, "yyyy-MM-dd", new Date()),
    [startDateString]
  );

  const filterEventsByDateAndUser = useCallback(() => {
    const newEvents =
      (events.length > 0 &&
        events.filter((event) => {
          const { userId } = event;
          const { date: dateEventString } = event;
          const dateEvent = parseISO(dateEventString, "yyyy-MM-dd", new Date());
          const isInRange =
            !isBefore(dateEvent, startDate) && !isAfter(dateEvent, endDate);
          const isShowUSer = users && users[userId]?.isShow;
          const isShow = isInRange && isShowUSer;
          return isShow;
        })) ||
      [];
    setFilterEvents(newEvents);
  }, [events, startDate, endDate, users]);

  useEffect(() => {
    filterEventsByDateAndUser();
  }, [filterEventsByDateAndUser]);

  return {
    filterEvents,
  };
};

export default useFilterEventsByDateAndUser;
