import { useEffect, useState } from "react";
import OpenDialog from "components/Dialogs/OpenDialog";
import { LoadCsv, CustomInput } from "./LoadCSVStyle";
import checkCSVHeaders from "helpers/checkCSVFileHeaders";
import {
  LinearProgress,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import { useSocket } from "hooks/useSocket";
import { WHITE_SECOND_COLOR } from "utils/const";
import { uploadCSVEventsNotRelated } from "services/Events/uploadCSVEventsNotRelated";
import { useUserStore } from "zustandGloabalStore/user";

export const LoadCSVEventsNotRelated = () => {
  const socket = useSocket();

  const [haveObjects, setHaveObjects] = useState(false);
  const [mediaMessage, setMediaMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fileName, setFileName] = useState("");

  const [saveProgressLines, setSaveProgressLines] = useState();
  const [batchCompleteLines, setBatchCompleteLines] = useState();
  const [bulkSaveCompleteLines, setBulkCompleteLines] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const { instanceId } = useUserStore((state) => state);

  useEffect(() => {
    const handleSocketEvent = (event, setDataFlag) => {
      if (socket) {
        socket.on(event, (data) => {
          setDataFlag(data);
        });
        return () => {
          socket.off(event);
        };
      }
    };

    const cleanupSocketListeners = [
      handleSocketEvent("saveProgressEventsNoRelated", setSaveProgressLines),
      handleSocketEvent("batchCompleteEventsNoRelated", setBatchCompleteLines),
      handleSocketEvent(
        "bulkSaveCompleteEventsNoRelated",
        setBulkCompleteLines
      ),
    ];

    return () => {
      cleanupSocketListeners.forEach((cleanup) => cleanup());
    };
  }, [socket]);

  // handle file upload
  const handleFileUploadCSV = (e) => {
    const file = e.target.files[0];
    setFileName(file);
    setShowConfirm(true);
    e.target.value = "";
  };

  // read the upload file
  const loadCSVFile = async (fn) => {
    if (!fn || fn === "") return setErrorMessage(true);
    // two FileReaders, one for csonverting data to a string and check headers of csv
    // second for reading entire document and stream it to backend so it doesn't block
    const readerText = new FileReader();
    const readerArrBff = new FileReader();

    readerText.onload = async (evt) => {
      //check the length of the file to know if empty
      if (evt.target.result.length === 0) {
        setHaveObjects(true);
        setErrorMessage(true);
        setShowConfirm(false);
        return;
      }
      const rawString = evt.target.result;
      if (rawString.length === 0) {
        setHaveObjects(true);
        setErrorMessage(true);
        setShowConfirm(false);
        return;
      }
      if (
        checkCSVHeaders(rawString, [
          "pointTypeEventId",
          "longitude",
          "latitude",
          "isGeographic",
        ])
      ) {
        readerArrBff.onload = async (evt) => {
          console.log("File uploaded: ", fn.name);
          const formData = new FormData();
          formData.append("file", fn, fn.name);
          // add intance id to formData
          formData.append("instanceId", instanceId);

          // Send data to backend and store csv fn temporarly for processing
          await uploadCSVEventsNotRelated(formData);
        };
        readerArrBff.readAsArrayBuffer(fileName);
      } else {
        setErrorMessage(true);
        setHaveObjects(true);
        setShowConfirm(false);
        return;
      }
    };
    readerText.readAsText(fileName, "utf-8");
  };

  const ProgressBar = ({ value }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress color="success" variant="determinate" value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography>{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const UploadProgress = ({
    saveProgressEvents = undefined,
    batchCompleteEvents = undefined,
    bulkSaveCompleteEvents = undefined,
  }) => {
    let progress = 0;
    let statusText = "";

    if (saveProgressEvents !== undefined) {
      progress = (saveProgressEvents.saved / saveProgressEvents.total) * 100;
      statusText = `Saving: ${saveProgressEvents.saved} / ${saveProgressEvents.total}`;
    }
    if (batchCompleteEvents !== undefined) {
      progress = (batchCompleteEvents.saved / batchCompleteEvents.total) * 100;
      statusText = `Batch complete: ${batchCompleteEvents.saved} saved, ${batchCompleteEvents.errors} errors`;
    }
    if (
      bulkSaveCompleteEvents !== undefined &&
      bulkSaveCompleteEvents.saved === bulkSaveCompleteEvents.total
    ) {
      progress = 100;
      statusText = `Upload complete: ${bulkSaveCompleteEvents.saved} saved, ${bulkSaveCompleteEvents.errors} errors`;
      setIsLoading(false);
    }

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <ProgressBar value={progress} />
        <Typography variant="body2" sx={{ mt: 1 }}>
          {statusText}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    saveProgressLines !== undefined &&
      batchCompleteLines !== undefined &&
      bulkSaveCompleteLines !== undefined &&
      setIsLoading(false);
  }, [batchCompleteLines, bulkSaveCompleteLines, saveProgressLines]);

  return (
    <LoadCsv>
      <label htmlFor="csvEventsNotRelated" style={{ textTransform: "none" }}>
        Add Data
      </label>
      <CustomInput
        id="csvEventsNotRelated"
        type="file"
        name="file"
        style={{ visibility: "hidden" }}
        accept=".csv"
        onChange={handleFileUploadCSV}
      />
      <OpenDialog
        openDialog={showConfirm}
        setOpenDialog={setShowConfirm}
        execute={() => {
          setIsLoading(true);
          loadCSVFile(fileName);
        }}
        content={{
          title: "Confirm upload",
          description: (
            <div>
              <p>
                {"Are you sure you want to load this " + fileName?.name + " ?"}
              </p>
              <UploadProgress
                saveProgressEvents={saveProgressLines}
                batchCompleteEvents={batchCompleteLines}
                bulkSaveCompleteEvents={bulkSaveCompleteLines}
              />
            </div>
          ),
          disagree: bulkSaveCompleteLines ? "Close" : "Cancel",
          agree: !isLoading && <LoadingUpload isLoading={isLoading} />,
        }}
      />
      <OpenDialog
        openDialog={errorMessage}
        setOpenDialog={setErrorMessage}
        content={{
          title: "Invalid Format",
          description:
            "Cannot read the file. Please ensure that the format is valid and try again.",
          disagree: "Continue",
        }}
      />
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        content={{
          title: "No Items",
          description:
            "The file that was uploaded is empty. Please provide another one.",
          disagree: "See map",
        }}
      />
      <OpenDialog
        openDialog={mediaMessage}
        setOpenDialog={setMediaMessage}
        content={{
          title: "No Media Files",
          description:
            "The file that was uploaded does not have any media. If you want to add pictures, click on the marker icon and select add pictures option.",
          disagree: "Ok",
        }}
      />
    </LoadCsv>
  );
};

const LoadingUpload = ({ isLoading }) => {
  return isLoading ? (
    <CircularProgress
      style={{
        width: "20px",
        height: "20px",
        color: WHITE_SECOND_COLOR,
        marginRight: "5px",
      }}
    />
  ) : (
    "Upload file"
  );
};
