import {
  Container,
  Panel,
  PanelSettingsCategories,
  PanelSettingsConfigContainer,
  PanelSettingsContainer,
  PanelSettingsLines,
  PanelSettingsSDA,
} from "./styles";
import { AiFillSetting } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOperationClusters,
  setObjectClusters,
  setComponentClusters,
  setRadiusElementsNear,
  setMZoomView,
  setMapSettings,
} from "redux/actions";
import GenericDialog from "components/Dialogs/GenericDialog";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  WHITE_COLOR,
} from "utils/const";
import { SECOND_COLOR } from "utils/globalColorsTheme";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { useContext } from "react";
import { ThemeContext } from "App";
import ObjectIcon from "images/ObjectIcon";
import ComponentIcon from "images/ComponentIcon";
import EventIcon from "images/EventIcon";

import SelectDynamicLinesColor from "./dynamicLinesColor/DynamicLinesColor";
import SelectDynamicPointsColor from "./dynamicPointsColor/DynamicPointsColor";
import {
  CustomizedTypography,
  CustomizeToggleButtonGroup,
  ButtonInfo,
} from "./../../DigitalTwin/MapDrawerStyle";
import AddIcon from "@mui/icons-material/Add";
import { LoadCSVObjects } from "components/Uploads/loadCSV/LoadCSVObjects";
import List from "@mui/material/List";
import { LoadCSVLines } from "components/Uploads/loadCSV/LoadCSVLines";
import OpenDialog from "components/Dialogs/OpenDialog";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { INFORMATION_COLOR } from "utils/const";
import { DynamicColorsContainer } from "./dynamicLinesColor/DynamicLinesColorStyles";
import { Button as ButtonMui } from "@mui/material";

import { LoadCSVObjectsWithCmpEvtRelations } from "components/Uploads/loadCSV/LoadCSVObjectsWithCmpEvtRelations";
import { LoadCSVEventsNotRelated } from "components/Uploads/loadCSV/LoadCSVEventsNotRelated";
import LibrarySelector from "./LibrarySelector";
import CustomButton from "components/Buttons/CustomButton";
import { SynchronizeObjects } from "components/Forms/SaveObject/SynchronizeObjects";
import { AnimatedWorkspacesIcon } from "components/Tables/GenericTable/GenericTableStyle";
import { updatePointConfigMapSettingsMg } from "services/updateBackendMapSettings";
import useSWR, { useSWRConfig } from "swr";
import { config } from "config.js";
import { useUserStore } from "zustandGloabalStore/user";
export default function FloatingSetting() {
  const { instanceId, userId } = useUserStore((state) => state);
  const { mutate } = useSWRConfig();
  const { theme } = useContext(ThemeContext);
  const [seePanel, setSeePanel] = useState(false);
  const [configData, setConfigData] = useState({});
  const [pointConfig, setPointConfigMg] = useState({});

  const dispatch = useDispatch();

  const mZoomView = useSelector((state) => state.digitalTwinReducer.mZoomView);

  const operationClusters = useSelector(
    (state) => state.digitalTwinReducer.operationClusters
  );
  const objectClusters = useSelector(
    (state) => state.digitalTwinReducer.objectClusters
  );

  const componentClusters = useSelector(
    (state) => state.digitalTwinReducer.componentClusters
  );
  const radiusElementsNear = useSelector(
    (state) => state.digitalTwinReducer.radiusElementsNear
  );
  const mapSettingsState = useSelector(
    (state) => state.digitalTwinReducer.mapSettings
  );

  const { data: dataPointConfig, error: errorPointConfig } = useSWR(
    `${config.URL_BACKEND_MG}map-settings/point-config?userId=${userId}&instanceId=${instanceId}`
  );

  useEffect(() => {
    if (!errorPointConfig && dataPointConfig) {
      setPointConfigMg(dataPointConfig);
    }
  }, [dataPointConfig, errorPointConfig]);

  useEffect(() => {
    const setMapConfig = async () => {
      // map config already exists on a redux state ?
      if (
        mapSettingsState !== undefined &&
        Object.keys(mapSettingsState).length
      ) {
        setConfigData(mapSettingsState);
        return;
      }
    };
    setMapConfig();
  }, [mapSettingsState]);

  /* Handlers */
  const handle50MZoomView = (e) => {
    let newValues;
    if (e.target.name === "icon") {
      newValues = {
        ...mZoomView,
        color: false,
        icon: true,
      };
    } else if (e.target.name === "color") {
      newValues = {
        ...mZoomView,
        color: true,
        icon: false,
      };
    } else {
      newValues = {
        ...mZoomView,
        zoom: e.target.value,
      };
    }

    localStorage.setItem("mZoomView", JSON.stringify(newValues));
    dispatch(setMZoomView(newValues));
  };
  const handleOperationClusters = (e) => {
    const newValues = {
      ...operationClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("operationCluster", JSON.stringify(newValues));
    dispatch(setOperationClusters(newValues));
  };

  const handleObjectClusters = (e) => {
    const newValues = {
      ...objectClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("objectClusters", JSON.stringify(newValues));
    dispatch(setObjectClusters(newValues));
  };

  const handleComponentClusters = (e) => {
    const newValues = {
      ...componentClusters,
      [e.target.name]: parseInt(e.target.value),
    };
    localStorage.setItem("componentClusters", JSON.stringify(newValues));
    dispatch(setComponentClusters(newValues));
  };

  const handleRadiusElementsNear = (e) => {
    const sanitizedValue = parseInt(e.target.value);
    dispatch(setRadiusElementsNear(sanitizedValue));
  };

  const handleMarkerPointFlashColor = async (e) => {
    await updatePointConfigMapSettingsMg({
      type: e.target.name,
      body: {
        property: "markPointFlashColor",
        value: e.target.value,
      }, 
    });
    mutate(
      `${config.URL_BACKEND_MG}map-settings/point-config?userId=${userId}&instanceId=${instanceId}`
    );
  };

  const handleSeePanel = (status) => {
    setSeePanel(() => status);
  };

  return (
    <>
      <Container className="background-2 border-color-1">
        {!seePanel && (
          <SimpleToolTip title="Map Settings" placement="left">
            <span
              className="icon-settings"
              onClick={() => handleSeePanel(true)}
            >
              <AiFillSetting />
            </span>
          </SimpleToolTip>
        )}
      </Container>

      <GenericDialog
        openDialog={seePanel}
        setOpenDialog={setSeePanel}
        style={{
          background:
            theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
        }}
        maxWidth={"60vw"}
        minWidth={"60vw"}
        // style={{ background: `${WHITE_BACKGROUND}` }}
        content={{
          title: "Tools & Map Tools",
          content: (
            <SettingsPanelLayout
              props={{
                theme,
                objectClusters,
                operationClusters,
                componentClusters,
                mZoomView,
                radiusElementsNear,
                handle50MZoomView,
                handleOperationClusters,
                handleObjectClusters,
                handleComponentClusters,
                handleRadiusElementsNear,
                handleMarkerPointFlashColor,
                configData,
                setConfigData,
                pointConfig,
              }}
            />
          ),
        }}
      />
    </>
  );
}

const SettingsPanelLayout = ({ props }) => {
  const [option, setOption] = useState(0);
  const [messageCSVLines, setMessageCSVLines] = useState(false);
  const mapSettingsState = useSelector(
    (state) => state.digitalTwinReducer.mapSettings
  );
  //Libraries
  const libraryLine = mapSettingsState?.libraries;
  const libraryComponent = mapSettingsState?.librariesComponents;
  const libraryEvent = mapSettingsState?.librariesEvents;
  const libraryObject = mapSettingsState?.librariesObjects;
  const [openDialogSyncObjects, setOpenDialogSyncObjects] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleChangeOption = (opcn) => {
    if (opcn === undefined || opcn === null) setOption(0);
    setOption(opcn);
  };

  const handleLoadObjects = () => {
    return <LoadCSVObjects />;
  };
  return (
    <PanelSettingsContainer id="panelsettingscontainer">
      {/* Categories to select */}
      <PanelSettingsCategories>
        <ul>
          <li
            onClick={() => handleChangeOption(0)}
            style={{
              backgroundColor: option === 0 ? SECOND_COLOR : WHITE_COLOR,
            }}
          >
            Points
          </li>
          <li
            onClick={() => handleChangeOption(1)}
            style={{
              backgroundColor: option === 1 ? SECOND_COLOR : WHITE_COLOR,
            }}
          >
            Lines
          </li>
          <li
            onClick={() => handleChangeOption(2)}
            style={{
              backgroundColor: option === 2 ? SECOND_COLOR : WHITE_COLOR,
            }}
          >
            Imports
          </li>
          <li
            onClick={() => handleChangeOption(3)}
            style={{
              backgroundColor: option === 3 ? SECOND_COLOR : WHITE_COLOR,
            }}
          >
            SDA
          </li>
        </ul>
      </PanelSettingsCategories>
      {/* Configurations by Category */}
      <PanelSettingsConfigContainer>
        {option !== undefined && option === 0 ? (
          <PanelSettings props={props} />
        ) : option === 1 ? (
          <PanelSettingsLines>
            <SelectDynamicLinesColor
              configData={props.configData}
              setConfigData={props.setConfigData}
            />
          </PanelSettingsLines>
        ) : option === 2 ? (
          <>
            <PanelSettingsImports
              handleLoadObjects={handleLoadObjects}
              setMessageCSVLines={setMessageCSVLines}
              messageCSVLines={messageCSVLines}
            />
            <DynamicColorsContainer>
              <section>
                <p>
                  <b>DOWNLOAD CSV TEMPLATE</b>
                  <br />
                  <br />
                  In this section, you can download a CSV template tailored to
                  the selected library. This template provides the fields and
                  structure needed to upload specific objects, components, lines
                  or events. Once completed with the appropriate data, you can
                  upload your csv file.
                  <br />
                </p>
                <br />
                {/**AQUI VA LA INTERFAZ DE LAS LIBRERIAS */}
                <LibrarySelector
                  libraryLine={libraryLine}
                  libraryComponent={libraryComponent}
                  libraryEvent={libraryEvent}
                  libraryObject={libraryObject}
                />
              </section>
              <hr />
              <br />
              <section>
                <p>
                  <b>IMPORT FIELDS</b>
                  <br />
                  <br />
                  In this module, you can easily upload CSV files to add objects
                  and lines directly to the map. When you upload the file, the
                  system will process the information contained in it and
                  automatically place the objects (as points) and lines (as
                  relationships between objects) in their corresponding
                  locations on the map.
                  <br />
                </p>
              </section>
              <hr />
              <br />
              <List
                sx={{
                  height: "100%",
                  position: "bottom",
                }}
                subheader={<li />}
              >
                <CustomizeToggleButtonGroup
                  size="medium"
                  fullWidth={false}
                  aria-label="text view"
                  style={{ marginTop: "5px" }}
                >
                  <CustomizedTypography
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                    }}
                  >
                    CSV Objects
                  </CustomizedTypography>

                  <ButtonMui
                    className="color1 border-color-1"
                    onClick={handleLoadObjects}
                    style={{
                      borderRadius: "5px",
                      margin: "-8px 0px 10px 10px",
                    }}
                    sx={{
                      fontSize: "1.4rem",
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                      width: {
                        xs: 120,
                        sm: 150,
                      },
                      border: "solid 1px gray",
                      background: "white",
                    }}
                  >
                    <AddIcon className="color1" />
                    <LoadCSVObjects />
                  </ButtonMui>
                </CustomizeToggleButtonGroup>
                <div style={{ height: "10px" }}></div>
                <br />
                <CustomizeToggleButtonGroup
                  size="medium"
                  fullWidth={false}
                  aria-label="text view"
                  style={{ marginTop: "5px" }}
                >
                  <CustomizedTypography
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                    }}
                  >
                    CSV Lines
                  </CustomizedTypography>
                  <ButtonMui
                    className="color1 border-color-1"
                    style={{
                      borderRadius: "5px",
                      border: "solid 1px gray",
                      background: "white",
                      margin: "-8px 0px 10px 18px",
                    }}
                    sx={{
                      fontSize: "1.4rem",
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                      width: {
                        xs: 120,
                        sm: 150,
                      },
                    }}
                  // onClick={handleLoadLines}
                  >
                    <AddIcon className="color1" />
                    <LoadCSVLines />
                  </ButtonMui>
                </CustomizeToggleButtonGroup>
                <div style={{ height: "10px" }}></div>
                <br />
                <CustomizeToggleButtonGroup
                  size="medium"
                  fullWidth={false}
                  aria-label="text view"
                  style={{ marginTop: "5px" }}
                >
                  <CustomizedTypography
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                    }}
                  >
                    CSV Objects with Components and Events relations
                  </CustomizedTypography>
                  <ButtonMui
                    className="color1 border-color-1"
                    style={{
                      borderRadius: "5px",
                      border: "solid 1px gray",
                      background: "white",
                      margin: "-8px 0px 10px 18px",
                    }}
                    sx={{
                      fontSize: "1.4rem",
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                      width: {
                        xs: 120,
                        sm: 150,
                      },
                    }}
                  // onClick={handleLoadLines}
                  >
                    <AddIcon className="color1" />
                    <LoadCSVObjectsWithCmpEvtRelations />
                  </ButtonMui>
                </CustomizeToggleButtonGroup>
                <div style={{ height: "10px" }}></div>
                <br />
                <CustomizeToggleButtonGroup
                  size="medium"
                  fullWidth={false}
                  aria-label="text view"
                  style={{ marginTop: "5px" }}
                >
                  <CustomizedTypography
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                    }}
                  >
                    CSV Events (No relations with objects, components or events)
                  </CustomizedTypography>
                  <ButtonMui
                    className="color1 border-color-1"
                    style={{
                      borderRadius: "5px",
                      border: "solid 1px gray",
                      background: "white",
                      margin: "-8px 0px 10px 18px",
                    }}
                    sx={{
                      fontSize: "1.4rem",
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                      width: {
                        xs: 120,
                        sm: 150,
                      },
                    }}
                  >
                    <AddIcon className="color1" />
                    <LoadCSVEventsNotRelated />
                  </ButtonMui>
                </CustomizeToggleButtonGroup>
              </List>
            </DynamicColorsContainer>
          </>
        ) : option === 3 ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "1.3rem",
                padding: "10px",
                marginTop: "1rem",
                gap: "1rem",
                boxSizing: "border-box",
              }}
            >
              <section>
                <p>
                  <b>SYNCHRONIZE MESUAREMENTS WITH OBJECT</b>
                  <br />
                  <br />
                  In this section, you can link measurements to specific objects
                  within the system. This module allows you to upload
                  measurement data and associate it with the corresponding
                  objects seamlessly. By doing so, you ensure accurate tracking
                  and integration of measurements with their respective objects
                  for streamlined analysis and reporting.
                  <br />
                </p>
                <br />
                <PanelSettingsSDA>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      maxWidth: "300px",
                    }}
                  >
                    <CustomButton
                      text="Synchronize objects"
                      margin={0}
                      type={"button"}
                      onClick={() => setOpenDialogSyncObjects(true)}
                    />
                  </div>
                </PanelSettingsSDA>

                <OpenDialog
                  openDialog={openDialogSyncObjects}
                  setOpenDialog={setOpenDialogSyncObjects}
                  content={{
                    title: "Synchronize objects",
                    description: (
                      <SynchronizeObjects
                        setOpenDialogSyncObjects={setOpenDialogSyncObjects}
                        setOpenAlert={setOpenAlert}
                      />
                    ),
                  }}
                  minWidth="680px"
                />

                <br />
              </section>
            </div>
          </>
        ) : (
          <></>
        )}
      </PanelSettingsConfigContainer>
    </PanelSettingsContainer>
  );
};

function PanelSettings({ props }) {
  const { configData, setConfigData } = props;
  return (
    <Panel
      style={{
        color:
          props.theme === "light" ? document.body.style.color : WHITE_COLOR,
      }}
    >
      <div className="body">
        <section>
          <p>
            <b>Radius: </b>
            Determines the maximum distance at which points are grouped in a
            cluster.
          </p>
          <br />
          <p>
            <b>Max zoom: </b>
            Sets the maximum zoom level on the map at which clusters are
            ungrouped into individual points. The maximum zoom value is 23.
          </p>
        </section>
        <div className="body-items">
          <div className="object-clusters-title">
            <p>Object Clusters:</p>
            <span className="color-cluster-object"></span>
          </div>
          <div className="items">
            <div className="body-item">
              <label className="label">
                Radius:
                <input
                  type="number"
                  name="radius"
                  value={props.objectClusters.radius}
                  onChange={props.handleObjectClusters}
                />
              </label>
            </div>
            <div className="body-item">
              <label className="label">
                Max Zoom:
                <input
                  type="number"
                  name="maxZoom"
                  value={props.objectClusters.maxZoom}
                  onChange={props.handleObjectClusters}
                  onInput={(e) => {
                    if (e.target.value > 23) e.target.value = 23;
                  }}
                  min={1}
                  max={23}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="body-items">
          <div className="object-clusters-title">
            <p>Event Clusters:</p>
            <span className="color-cluster-event"></span>
          </div>
          <div className="items">
            <div className="body-item">
              <label className="label">
                Radius:
                <input
                  type="number"
                  name="radius"
                  value={props.operationClusters.radius}
                  onChange={props.handleOperationClusters}
                />
              </label>
            </div>
            <div className="body-item">
              <label className="label">
                Max Zoom:
                <input
                  type="number"
                  name="maxZoom"
                  value={props.operationClusters.maxZoom}
                  onChange={props.handleOperationClusters}
                  onInput={(e) => {
                    if (e.target.value > 23) e.target.value = 23;
                  }}
                  min={1}
                  max={23}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="body-items">
          <div className="object-clusters-title">
            <p>Component Clusters:</p>
            <span className="color-cluster-component"></span>
          </div>
          <div className="items">
            <div className="body-item">
              <label className="label">
                Radius:
                <input
                  type="number"
                  name="radius"
                  value={props.componentClusters.radius}
                  onChange={props.handleComponentClusters}
                />
              </label>
            </div>
            <div className="body-item">
              <label className="label">
                Max Zoom:
                <input
                  type="number"
                  name="maxZoom"
                  value={props.componentClusters.maxZoom}
                  onChange={props.handleComponentClusters}
                  onInput={(e) => {
                    if (e.target.value > 23) e.target.value = 23;
                  }}
                  min={1}
                  max={23}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="body-items">
          <div className="object-clusters-title">
            <p>Mark Point Flash Color </p>
            <span className="mark-point-flash">
              <AnimatedWorkspacesIcon />
            </span>
          </div>
          <div className="items">
            <div className="container-input-color-flash">
              <label className="label-flash-color">Object:</label>
              <input
                type="color"
                name="object"
                value={props.pointConfig.object.markPointFlashColor}
                onChange={props.handleMarkerPointFlashColor}
              />
            </div>
            <div className="container-input-color-flash">
              <label className="label-flash-color">Component:</label>
              <input
                type="color"
                name="component"
                value={props.pointConfig.component.markPointFlashColor}
                onChange={props.handleMarkerPointFlashColor}
              />
            </div>
            <div className="container-input-color-flash">
              <label className="label-flash-color">Event:</label>

              <input
                type="color"
                name="event"
                value={props.pointConfig.event.markPointFlashColor}
                onChange={props.handleMarkerPointFlashColor}
              />
            </div>
          </div>
        </div>

        <div className="body-items">
          <div className="object-clusters-title">
            <p>50m Zoom View</p>
          </div>
          <div className="body-item">
            <label className="label">
              Zoom:
              <input
                type="number"
                name="zoom"
                value={parseInt(props.mZoomView.zoom)}
                onChange={props.handle50MZoomView}
                onInput={(e) => {
                  if (e.target.value > 23) e.target.value = 23;
                }}
                min={1}
                max={23}
              />
            </label>
          </div>
          <div className="items">
            <div className="body-item">
              <label className="label">
                <div className="container-icons">
                  <div className="preview-point-icon">
                    <p> Object:</p>
                    <ObjectIcon width={40} height={40} />
                  </div>
                  <div className="preview-point-icon">
                    <p>Component:</p>

                    <ComponentIcon width={40} height={40} />
                  </div>
                  <div className="preview-point-icon">
                    <p>Event:</p>

                    <EventIcon width={40} height={40} />
                  </div>
                </div>
                <input
                  type="checkbox"
                  name="icon"
                  checked={props.mZoomView.icon}
                  onChange={props.handle50MZoomView}
                  min={1}
                  max={23}
                  style={{
                    transform: "scale(0.2)",
                  }}
                />
              </label>
            </div>
            <div className="body-item">
              <label className="label">
                <div className="container-icons">
                  <div className="preview-point-color">
                    <p> Object:</p>
                    <span className="color-cluster-object"></span>
                  </div>
                  <div className="preview-point-color">
                    <p>Component:</p>
                    <span className="color-cluster-component"></span>
                  </div>
                  <div className="preview-point-color">
                    <p>Event:</p>
                    <span className="color-cluster-event"></span>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name="color"
                  checked={props.mZoomView.color}
                  onChange={props.handle50MZoomView}
                  style={{
                    transform: "scale(0.2)",
                  }}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="body-items">
          <div className="object-clusters-title">
            <p>Radius Elements Near:</p>
          </div>
          <div className="items">
            <div className="body-item">
              <label className="label">
                Radius (m):
                <input
                  type="number"
                  name="radius"
                  value={props.radiusElementsNear}
                  onChange={props.handleRadiusElementsNear}
                />
              </label>
            </div>
          </div>
        </div>
        {props.mZoomView.color && (
          <SelectDynamicPointsColor
            configData={configData}
            setConfigData={setConfigData}
          />
        )}
      </div>
    </Panel>
  );
}

function PanelSettingsImports({
  handleLoadObjects,
  setMessageCSVLines,
  messageCSVLines,
}) {
  return (
    <>
      <DynamicColorsContainer>
        <section>
          <p>
            <b>IMPORT FIELDS</b>
            <br />
            <br />
            In this module, you can easily upload CSV files to add objects and
            lines directly to the map. When you upload the file, the system will
            process the information contained in it and automatically place the
            objects (as points) and lines (as relationships between objects) in
            their corresponding locations on the map.
            <br />
          </p>
        </section>
        <hr />

        <br />
        <List
          sx={{
            height: "100%",
            position: "bottom",
          }}
          subheader={<li />}
        >
          <CustomizeToggleButtonGroup
            size="medium"
            fullWidth={false}
            aria-label="text view"
            style={{
              marginTop: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CustomizeToggleButtonGroup
              size="medium"
              fullWidth={false}
              aria-label="text view"
              style={{ marginTop: "5px" }}
            >
              <CustomizedTypography
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  fontFamily: document.body.style.fontFamily,
                  color: document.body.style.color,
                }}
              >
                CSV Objects
              </CustomizedTypography>

              <ButtonMui
                className="color1 border-color-1"
                onClick={handleLoadObjects}
                style={{
                  borderRadius: "5px",
                  margin: "-8px 0px 10px 10px",
                }}
                sx={{
                  fontSize: "1.4rem",
                  fontFamily: document.body.style.fontFamily,
                  color: document.body.style.color,
                  width: {
                    xs: 120,
                    sm: 150,
                  },
                  border: "solid 1px gray",
                  background: "white",
                }}
              >
                <AddIcon className="color1" />
                <LoadCSVObjects />
              </ButtonMui>
            </CustomizeToggleButtonGroup>
            <div style={{ height: "10px" }}></div>
            <br />

            <CustomizeToggleButtonGroup
              size="medium"
              fullWidth={false}
              aria-label="text view"
              style={{ marginTop: "5px" }}
            >
              <CustomizedTypography
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  fontFamily: document.body.style.fontFamily,
                  color: document.body.style.color,
                }}
              >
                CSV Lines
                <ButtonInfo
                  onClick={() => setMessageCSVLines(!messageCSVLines)}
                  transform={"translate(0%, -50px)"}
                >
                  <ContactSupportIcon
                    sx={{
                      fontSize: "1.6rem",
                      color: `${INFORMATION_COLOR}`,
                      ml: "-3px",
                      mb: "15px",
                    }}
                  />
                  {messageCSVLines && (
                    <OpenDialog
                      openDialog={messageCSVLines}
                      setOpenDialog={setMessageCSVLines}
                      content={{
                        title: "Help CSV lines format",
                        description: (
                          <div>
                            <p>
                              Please refer to{" "}
                              <a target="_blank" href="/library-configurations">
                                Library configurations.
                              </a>
                            </p>
                            <p>
                              Select a library you want by clicking the id in
                              the table on the left.
                            </p>
                            <p>
                              Click on "CSV Template" button for downloading
                              empty file with lines CSV file headings.
                            </p>
                          </div>
                        ),
                        disagree: "Close",
                      }}
                    />
                  )}
                </ButtonInfo>
              </CustomizedTypography>
              <ButtonMui
                className="color1 border-color-1"
                style={{
                  borderRadius: "5px",
                  border: "solid 1px gray",
                  background: "white",
                  margin: "-8px 0px 10px 10px",
                }}
                sx={{
                  fontSize: "1.4rem",
                  fontFamily: document.body.style.fontFamily,
                  color: document.body.style.color,
                  width: {
                    xs: 120,
                    sm: 150,
                  },
                }}
              // onClick={handleLoadLines}
              >
                <AddIcon className="color1" />
                <LoadCSVLines />
              </ButtonMui>
            </CustomizeToggleButtonGroup>
            <div style={{ height: "10px" }}></div>
            <br />
            <CustomizeToggleButtonGroup
              size="medium"
              fullWidth={false}
              aria-label="text view"
              style={{ marginTop: "5px" }}
            >
              <CustomizedTypography
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  fontFamily: document.body.style.fontFamily,
                  color: document.body.style.color,
                }}
              >
                CSV Objects with Components and Events relations
              </CustomizedTypography>
              <ButtonMui
                className="color1 border-color-1"
                style={{
                  borderRadius: "5px",
                  border: "solid 1px gray",
                  background: "white",
                  margin: "-8px 0px 10px 18px",
                }}
                sx={{
                  fontSize: "1.4rem",
                  fontFamily: document.body.style.fontFamily,
                  color: document.body.style.color,
                  width: {
                    xs: 120,
                    sm: 150,
                  },
                }}
              // onClick={handleLoadLines}
              >
                <AddIcon className="color1" />
                <LoadCSVObjectsWithCmpEvtRelations />
              </ButtonMui>
            </CustomizeToggleButtonGroup>
            <div style={{ height: "10px" }}></div>
            <br />
            <CustomizeToggleButtonGroup
              size="medium"
              fullWidth={false}
              aria-label="text view"
              style={{ marginTop: "5px" }}
            >
              <CustomizedTypography
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  fontFamily: document.body.style.fontFamily,
                  color: document.body.style.color,
                }}
              >
                CSV Events (No relations with objects, components or events)
              </CustomizedTypography>
              <ButtonMui
                className="color1 border-color-1"
                style={{
                  borderRadius: "5px",
                  border: "solid 1px gray",
                  background: "white",
                  margin: "-8px 0px 10px 18px",
                }}
                sx={{
                  fontSize: "1.4rem",
                  fontFamily: document.body.style.fontFamily,
                  color: document.body.style.color,
                  width: {
                    xs: 120,
                    sm: 150,
                  },
                }}
              >
                <AddIcon className="color1" />
                <LoadCSVEventsNotRelated />
              </ButtonMui>
            </CustomizeToggleButtonGroup>
          </CustomizeToggleButtonGroup>
        </List>
      </DynamicColorsContainer>
    </>
  );
}
