import Layout from "components/Layout/AdminDecimetrix";
import {
  InformationSection,
  Content,
  ConfigurationSection,
  ConfigLibrary,
  ConfigConstant,
  ContentButton,
  ContentData,
  InformationShare,
  Form,
  Title,
  ContentMoveTags,
  MoveTag,
  ContentDataTag,
  Columns,
  Column,
  Space,
  GlobalContent,
  Divider,
  SelectCategoriesStyles,
  ContainerSelect,
  customSelectStyle,
  ContainerInput,
} from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "components/Buttons/CustomButton";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import TextInput from "components/Forms/GenericInput";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useSWR from "swr";
import { useSWRConfig } from "swr";
import { config } from "config.js";
import {
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";
import OpenDialog from "components/Dialogs/OpenDialog";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor2, StyleColor3 } from "Styles";
import SelectInput from "components/Forms/SelectInput";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import saveGlobalTable from "services/GlobalValuesFields/saveGlobalTablet";
import updateGlobalTable from "services/GlobalValuesFields/updateGlobalTable";
import { CSV_GLOBAL_VALUES_TEMPLATE, FROM_TABLE } from "utils/constStrings";
import saveCSVTemplateFile from "utils/downloadCSVTemplate";
import Select from "react-select/creatable";
import CancelIcon from "@mui/icons-material/Cancel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import uploadCSVGlobalValues from "./uploadCSVGlobalValues";

const GlobalValuesFields = () => {
  const { mutate } = useSWRConfig();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEditCategory, setOpenDialogEditCategory] = useState(false);
  const [openDialogCreateTable, setOpenDialogCreateTable] = useState(false);
  const [openDialogCreateGlobalValue, setOpenDialogCreateGlobalValue] =
    useState(false);
  const [openDialogUpdateGlobalValue, setOpenDialogUpdateGlobalValue] =
    useState(false);
  const [currentGlobalTable, setCurrentGlobalTable] = useState(null);
  const [deleteTable, setDeleteTable] = useState(false);
  const [deleteGlobalValue, setDeleteGlobalValue] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const [selectDeleteOption, setSelectDeleteOption] = useState(null);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "",
    disagree: "Cancel",
    agree: "",
  });
  const [selectTableId, setSelectTableId] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState({
    id: null,
    name: "",
  });
  const { theme } = useContext(ThemeContext);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataGlobalTables, setDataGlobalTables] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  //const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const inputFileRef = useRef(null); // Ref para el input de archivo
  const [dataFormColumnTable, setDataFormColumnTable] = useState({
    tableName: "",
  });
  const [columnsGlobalTable, setColumnsGlobalTable] = useState([
    {
      Columns: "Options",
      Type: "string",
      Tools: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <CustomButton
            type="onClick"
            text={<DeleteIcon />}
            disabled={true}
            margin={5}
            primaryColor={VISIBILITY_OFF_ICON}
            secondaryColor={VISIBILITY_OFF_ICON}
          />
        </div>
      ),
    },
  ]);

  const {
    register: registerGlobalTable,
    handleSubmit: handleSubmitGlobalTable,
    formState: { errors: errorsGlobalTable },
    setValue: setValueGlobalTable,
    reset: resetInputsGlobalTable,
    setError: setErrorGlobalValue,
    clearErrors: clearErrorsGlobalValue,
    getValues: getValuesGlobalTable,
  } = useForm();

  const {
    register: registerGlobalValue,
    handleSubmit: handleSubmitGlobalValue,
    formState: { errors: errorsGlobalValue },
    setValue: setValueGlobalValue,
    reset: resetInputsGlobalValue,
    setError: setErrorCreateGlobalValue,
    clearErrors: clearErrorsCreateGlobalValue,
  } = useForm();

  const {
    register: registerUpdateGlobalValue,
    handleSubmit: handleSubmitUpdateGlobalValue,
    formState: { errors: errorsUpdateGlobalValue },
    setValue: setValueUpdateGlobalValue,
    setError: setErrorUpdateGlobalValue,
    clearErrors: clearErrorsUpdateGlobalValue,
  } = useForm();

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const url = `${config.URL_BACKEND_MG}global-values-fields?adminCompanyId=${adminCompanyId}`;
  const { data: globalTables, error: errorGlobalTables } = useSWR(url);

  useEffect(() => {
    if (globalTables && !errorGlobalTables && globalTables.length > 0) {
      setDataCategories(globalTables[0]?.tableCategories);
      if (globalTables[0]?.tableCategories.length > 0) {
        setDataGlobalTables(globalTables[0]?.tableCategories[0]?.groupTables);
        setCurrentCategory(globalTables[0]?.tableCategories[0]._id);
      }
    }
  }, [globalTables, errorGlobalTables]);

  const uniqueCategoryNames = [
    ...new Set(dataCategories?.map((elm) => elm?.name)),
  ];

  const options = uniqueCategoryNames.map((catgory) => ({
    value: catgory,
    label: <ContainerSelect>{catgory}</ContainerSelect>,
  }));

  const handleAddNewColumn = async (data) => {
    let optionExists = false;
    columnsGlobalTable.forEach((elm) => {
      if (elm.Columns === data["Column Name"]) {
        optionExists = true;
      }
    });

    if (!optionExists) {
      setDataFormColumnTable({
        tableName: data["Table Name"],
        selectName: data["Select Name"],
      });
      setColumnsGlobalTable((current) => [
        ...current,
        {
          Columns: data["Column Name"],
          Type: data["Data Type"],
          Tools: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CustomButton
                type="onClick"
                text={<DeleteIcon />}
                onClick={() =>
                  setColumnsGlobalTable((current) =>
                    current.filter((elm) => elm.Columns !== data["Column Name"])
                  )
                }
                margin={5}
                primaryColor={DELETE_COLOR}
                secondaryColor={HOVER_DELETE_COLOR}
              />
            </div>
          ),
        },
      ]);
      setValueGlobalTable("Column Name", "");
      setValueGlobalTable("Data Type", "string");
      clearErrorsGlobalValue("Column Name");
    } else {
      setErrorGlobalValue("Column Name", {
        type: "manual",
        message: `The "${data["Column Name"]}" column already exists.`,
      });
    }
  };
  const handelSaveTable = async () => {
    if (globalTables.length === 0) {
      await saveGlobalTable({
        adminCompanyId,
        tableCategories: [
          {
            name: getValuesGlobalTable("selectCategory"),
            groupTables: [
              {
                name: dataFormColumnTable.tableName,
                dataColumns: columnsGlobalTable.map((elm) => {
                  return {
                    name: elm.Columns,
                    dataType: elm.Type,
                  };
                }),
                headers: columnsGlobalTable.map((elm) => elm.Columns),
                rows: [],
              },
            ],
          },
        ],
      });
    } else {
      if (selectTableId) {
        await updateGlobalTable({
          id: globalTables[0]._id,
          body: {
            adminCompanyId,
            tableCategories: dataCategories.map((elm) => {
              if (elm._id === currentCategory) {
                return {
                  ...elm,
                  groupTables: dataGlobalTables.map((item) => {
                    if (item._id === selectTableId) {
                      const currentHeaders = columnsGlobalTable.map(
                        (clm) => clm.Columns
                      );
                      return {
                        ...item,
                        name: getValuesGlobalTable("Table Name"),

                        dataColumns: columnsGlobalTable.map((data) => {
                          return {
                            name: data.Columns,
                            dataType: data.Type,
                          };
                        }),
                        headers: currentHeaders,
                        rows: item.rows.map((option) => {
                          const filteredOption = { ...option };
                          for (let key in filteredOption) {
                            if (!currentHeaders.includes(key)) {
                              delete filteredOption[key];
                            }
                          }
                          return filteredOption;
                        }),
                      };
                    }
                    return item;
                  }),
                };
              }
              return elm;
            }),
          },
        });
      } else {
        const newCategories = dataCategories.map((elm) => {
          if (elm.name === getValuesGlobalTable("selectCategory")) {
            return {
              ...elm,
              groupTables: [
                ...elm.groupTables,
                {
                  name: dataFormColumnTable.tableName,
                  dataColumns: columnsGlobalTable.map((column) => {
                    return {
                      name: column.Columns,
                      dataType: column.Type,
                    };
                  }),
                  headers: columnsGlobalTable.map((column) => column.Columns),
                  rows: [],
                },
              ],
            };
          } else {
            return elm;
          }
        });
        const categoryExists = dataCategories.some(
          (elm) => elm.name === getValuesGlobalTable("selectCategory")
        );
        if (!categoryExists) {
          newCategories.push({
            name: getValuesGlobalTable("selectCategory"),
            groupTables: [
              {
                name: dataFormColumnTable.tableName,
                dataColumns: columnsGlobalTable.map((column) => {
                  return {
                    name: column.Columns,
                    dataType: column.Type,
                  };
                }),
                headers: columnsGlobalTable.map((column) => column.Columns),
                rows: [],
              },
            ],
          });
        }
        await updateGlobalTable({
          id: globalTables[0]._id,
          body: {
            adminCompanyId,
            tableCategories: newCategories,
          },
        });
      }
    }
    setColumnsGlobalTable([
      {
        Columns: "Options",
        Type: "string",
        Tools: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CustomButton
              type="onClick"
              text={<DeleteIcon />}
              disabled={true}
              margin={5}
              primaryColor={DELETE_COLOR}
              secondaryColor={HOVER_DELETE_COLOR}
            />
          </div>
        ),
      },
    ]);
    setSelectTableId(null);
    resetInputsGlobalTable();
    setOpenDialogCreateTable(false);
    mutate(url);
  };

  const handelCreateGlobalValue = async (data) => {
    let optionExists = false;
    currentGlobalTable.rows.forEach((row) => {
      if (row.Options === data.Options) {
        optionExists = true;
      }
    });
    if (!optionExists) {
      await updateGlobalTable({
        id: globalTables[0]._id,
        body: {
          adminCompanyId,
          tableCategories: dataCategories.map((elm) => {
            if (elm._id === currentCategory) {
              return {
                ...elm,
                groupTables: dataGlobalTables.map((elm) => {
                  if (elm._id === currentGlobalTable._id) {
                    return {
                      ...elm,
                      rows: [...elm.rows, data],
                    };
                  }
                  return elm;
                }),
              };
            }
            return elm;
          }),
        },
      });

      setCurrentGlobalTable((current) => ({
        ...current,
        rows: [...current.rows, data], // Updtae table in real time
      }));
      setOpenDialogCreateGlobalValue(false);
      mutate(url);
      clearErrorsCreateGlobalValue("Options");
      resetInputsGlobalValue();
    } else {
      setErrorCreateGlobalValue("Options", {
        type: "manual",
        message: `The "${data.Options}" option already exists.`,
      });
    }
  };

  const handelUpdateGlobalValue = async (data) => {
    let optionExists = false;
    let valueOption = data["id"];

    currentGlobalTable.rows
      .filter((elm) => elm.Options !== valueOption)
      .forEach((row) => {
        if (row.Options === data.Options) {
          optionExists = true;
        }
      });

    if (!optionExists) {
      delete data.id;
      setCurrentGlobalTable((current) => ({
        ...current,
        rows: current.rows.map((elm) => {
          if (elm.Options === valueOption) {
            return data;
          }
          return elm;
        }),
      }));
      await updateGlobalTable({
        id: globalTables[0]._id,
        body: {
          adminCompanyId,
          tableCategories: dataCategories.map((elm) => {
            if (elm._id === currentCategory) {
              return {
                ...elm,
                groupTables: dataGlobalTables.map((elm) => {
                  if (elm._id === currentGlobalTable._id) {
                    return {
                      ...elm,
                      rows: elm.rows.map((item) => {
                        if (item.Options === valueOption) {
                          return data;
                        }
                        return item;
                      }),
                    };
                  }
                  return elm;
                }),
              };
            }
            return elm;
          }),
        },
      });
      setOpenDialogUpdateGlobalValue(false);
      mutate(url);
      clearErrorsUpdateGlobalValue("Options");
    } else {
      setErrorUpdateGlobalValue("Options", {
        type: "manual",
        message: `The "${data.Options}" option already exists.`,
      });
    }
  };

  const handelDeleteTables = async () => {
    await updateGlobalTable({
      id: globalTables[0]._id,
      body: {
        adminCompanyId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === currentCategory) {
            return {
              ...elm,
              groupTables: dataGlobalTables.filter((item) => {
                return item._id !== dataDelete._id;
              }),
            };
          }
          return elm;
        }),
      },
    });
    setCurrentGlobalTable(null);
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setDataDelete({});
    setOpenDialog(false);
    mutate(url);
  };

  const handelDeleteGlobalValues = async () => {
    await updateGlobalTable({
      id: globalTables[0]._id,
      body: {
        adminCompanyId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === currentCategory) {
            return {
              ...elm,
              groupTables: dataGlobalTables.map((elm) => {
                if (elm._id === currentGlobalTable._id) {
                  return {
                    ...elm,
                    rows: elm.rows
                      .map((item) => {
                        if (item.Options === selectDeleteOption) {
                          return null;
                        }
                        return item;
                      })
                      .filter((it) => it !== null),
                  };
                }
                return elm;
              }),
            };
          }
          return elm;
        }),
      },
    });
    setCurrentGlobalTable((current) => ({
      ...current,
      rows: current.rows
        .map((item) => {
          if (item.Options === selectDeleteOption) {
            return null;
          }
          return item;
        })
        .filter((it) => it !== null),
    }));
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setDataDelete({});
    setOpenDialog(false);
    mutate(url);
  };

  const handelDeleteCategory = async () => {
    await updateGlobalTable({
      id: globalTables[0]._id,
      body: {
        adminCompanyId,
        tableCategories: dataCategories.filter((elm) => {
          return elm._id !== categoryToEdit.id;
        }),
      },
    });
    setDataCategories((current) => {
      return current.filter((elm) => {
        return elm._id !== categoryToEdit.id;
      });
    });
    setDataGlobalTables([]);
    setCurrentCategory(null);
    setCurrentGlobalTable(null);
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setCategoryToEdit({ id: null, name: "" });
    setOpenDialog(false);
    mutate(url);
  };

  const handelUpdateCategory = async () => {
    await updateGlobalTable({
      id: globalTables[0]._id,
      body: {
        adminCompanyId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === categoryToEdit.id) {
            return {
              ...elm,
              name: categoryToEdit.name,
            };
          }
          return elm;
        }),
      },
    });
    mutate(url);
    setOpenDialogEditCategory(false);
  };

  const onDragConstantLibrary = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedItems = [...dataGlobalTables];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);
    setDataGlobalTables((current) => ({
      ...current,
      globalTables: updatedItems,
    }));
  };

  const handleOpenDialogCreateGlobalValue = () => {
    currentGlobalTable.dataColumns.forEach((elm, index) => {
      if (elm.dataType === "string") {
        setValueGlobalValue(elm.name, "");
      }
      if (elm.dataType === "number") {
        setValueGlobalValue(elm.name, 0);
      }
    });
    setOpenDialogCreateGlobalValue(true);
  };

  // Manejar la selección del archivo CSV
  /*const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };*/

  const handleUploadCSV = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const tableId = globalTables[0]?._id;
    const tableCategoriesId = currentCategory;
    const groupId = currentGlobalTable?._id;

    if (file) {
      uploadCSVGlobalValues(
        file,
        tableId,
        tableCategoriesId,
        groupId,
        setErrorMessage,
        setCurrentGlobalTable
      );
      mutate(url);
    } else {
      setErrorMessage("Please select a file to upload");
    }
    mutate(url);
  };

  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleOpenDialogEditGlobalValue = ({ globalValue }) => {
    setOpenDialogUpdateGlobalValue(true);
    for (let key in globalValue) {
      setValueUpdateGlobalValue(key, globalValue[key]);
    }
    setValueUpdateGlobalValue("id", globalValue["Options"]);
  };

  const handleOpenDialogDeleteValue = ({ e, option }) => {
    e.preventDefault();
    setDeleteGlobalValue(true);
    setSelectDeleteOption(option);
    setContentDialog({
      title: "Delete Global Value",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the option <strong>{option}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogDeleteTable = ({ e, table }) => {
    e.preventDefault();
    setDeleteTable(true);
    setDataDelete(table);
    setContentDialog({
      title: "Delete Global Table",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the table <strong>{table.name}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogDeleteCategory = ({ categoryId, name }) => {
    setDeleteCategory(true);
    setCategoryToEdit({ id: categoryId, name: name });
    setContentDialog({
      title: "Delete Category",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the category <strong>{name}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogUpdate = ({ e, table }) => {
    e.preventDefault();
    setSelectTableId(table._id);
    setValueGlobalTable("Table Name", table.name);
    setValueGlobalTable("Select Name", table.selectName);
    setValueGlobalTable("Column Name", "");
    setValueGlobalTable("Data Type", "string");
    setDataFormColumnTable({
      tableName: table.name,
      selectName: table.selectName,
    });
    setColumnsGlobalTable(
      table.dataColumns.map((elm) => {
        return {
          Columns: elm.name,
          Type: elm.dataType,
          Tools: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CustomButton
                type="onClick"
                text={<DeleteIcon />}
                onClick={() => {
                  setColumnsGlobalTable((current) => {
                    return current.filter((item) => item.Columns !== elm.name);
                  });
                }}
                margin={5}
                disabled={elm.name === "Options" ? true : false}
                primaryColor={
                  elm.name === "Options" ? VISIBILITY_OFF_ICON : DELETE_COLOR
                }
                secondaryColor={
                  elm.name === "Options"
                    ? VISIBILITY_OFF_ICON
                    : HOVER_DELETE_COLOR
                }
              />
            </div>
          ),
        };
      })
    );
    setOpenDialogCreateTable(true);
  };

  const handleInputChange = (newValue) => {
    if (getValuesGlobalTable("selectCategory") === null) {
      setValueGlobalTable("selectCategory", newValue);
    }
  };

  const handleSelectCategory = (e) => {
    const categorySelected = dataCategories.find((elm) => elm._id === e.value);
    setCurrentCategory(e.value);
    setDataGlobalTables(categorySelected?.groupTables);
    setCurrentGlobalTable(null);
  };

  const handleCreateNewTable = () => {
    const category = dataCategories?.find(
      (elm) => elm._id === currentCategory
    )?.name;
    setValueGlobalTable("selectCategory", category);
    setOpenDialogCreateTable(true);
  };

  const handleDownloadCSVTemplate = (e) => {
    e.preventDefault();

    const dataTable = dataGlobalTables.find((data) => {
      if (data?._id == currentGlobalTable?._id) {
        return data;
      }
    });
    const auxHeader = dataTable?.headers;
    let csvHeaders = [];
    if (dataGlobalTables[0]?.headers.length > 0) {
      const aux = {};
      for (let k in auxHeader) {
        aux[auxHeader[k]] = "";
      }
      csvHeaders = [{ ...aux }];
    } else {
      csvHeaders = [{ ...CSV_GLOBAL_VALUES_TEMPLATE }];
    }
    saveCSVTemplateFile(csvHeaders, "csv");
  };

  return (
    <Layout>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={
          deleteTable
            ? handelDeleteTables
            : deleteGlobalValue
            ? handelDeleteGlobalValues
            : deleteCategory
            ? handelDeleteCategory
            : ""
        }
        content={contentDialog}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        openDialog={openDialogEditCategory}
        setOpenDialog={setOpenDialogEditCategory}
        toExecuteOnClose={() => {}}
        execute={handelUpdateCategory}
        content={{
          title: "Edit Name Category",
          disagree: "Cancel",
          agree: "Update",
          description: (
            <ContainerInput>
              <label>Name Category</label>
              <input
                type="text"
                value={categoryToEdit.name}
                onChange={(e) =>
                  setCategoryToEdit((current) => ({
                    ...current,
                    name: e.target.value,
                  }))
                }
              />
            </ContainerInput>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        openDialog={openDialogCreateTable}
        setOpenDialog={setOpenDialogCreateTable}
        toExecuteOnClose={() => {
          setColumnsGlobalTable([
            {
              Columns: "Options",
              Type: "string",
              Tools: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    type="onClick"
                    text={<DeleteIcon />}
                    disabled={true}
                    margin={5}
                    primaryColor={DELETE_COLOR}
                    secondaryColor={HOVER_DELETE_COLOR}
                  />
                </div>
              ),
            },
          ]);
          setSelectTableId(null);
          resetInputsGlobalTable();
          setOpenDialogCreateTable(false);
        }}
        content={{
          title: "Create Global Table",
          description: (
            <div>
              <Form
                style={{ background: StyleColor3(theme) }}
                onSubmit={handleSubmitGlobalTable(handleAddNewColumn)}
              >
                <Divider>
                  <span className="divider-title">Table Information</span>
                </Divider>
                <TextInput
                  field={{
                    name: "Table Name",
                    type: "string",
                    required: true,
                  }}
                  register={registerGlobalTable}
                  errors={errorsGlobalTable}
                />
                <label
                  style={{
                    marginBottom: "5px",
                    fontSize: "1.6rem",
                  }}
                >
                  Choose or create a category
                </label>
                <Select
                  {...registerGlobalTable("selectCategory")}
                  options={options}
                  isClearable
                  isSearchable
                  onChange={(selectedOption) => {
                    setValueGlobalTable(
                      "selectCategory",
                      selectedOption?.value
                    );
                  }}
                  onInputChange={handleInputChange}
                  placeholder="Type to add or select from list"
                  styles={SelectCategoriesStyles}
                  defaultInputValue={
                    dataCategories.find((elm) => elm._id === currentCategory)
                      ?.name || null
                  }
                />

                <br />
                <Divider>
                  <span className="divider-title">Columns</span>
                </Divider>
                <TextInput
                  field={{
                    name: "Column Name",
                    type: "string",
                    required: false,
                  }}
                  register={registerGlobalTable}
                  errors={errorsGlobalTable}
                />
                <SelectInput
                  field={{
                    name: "Data Type",
                    options: ["string", "number"],
                    required: false,
                  }}
                  register={registerGlobalTable}
                />
                <br />
                <CustomButton type={"submit"} text={"Add Column"} margin={0} />
              </Form>
              <br />
              <Divider>
                <span className="divider-title">Table Preview</span>
              </Divider>
              <br />
              <GenericTable
                headers={Object.keys(columnsGlobalTable[0])}
                rows={columnsGlobalTable}
                keyValues={Object.keys(columnsGlobalTable[0])}
                handlerClick={() => {}}
                state={true}
                configBar={true}
                customTableWidth={"100%"}
                customHeaderWidth={"5%"}
                from={FROM_TABLE.details}
              />
              <br />
              <CustomButton
                type={"button"}
                text={"Save"}
                margin={10}
                onClick={handelSaveTable}
              />
            </div>
          ),
        }}
        maxWidth={"800px"}
        minWidth={"600px"}
      />
      <OpenDialog
        openDialog={openDialogCreateGlobalValue}
        setOpenDialog={setOpenDialogCreateGlobalValue}
        toExecuteOnClose={() => {}}
        content={{
          title: "Create Global Value",
          description: (
            <Form onSubmit={handleSubmitGlobalValue(handelCreateGlobalValue)}>
              <Title
                style={{
                  color: StyleColor2(theme),
                }}
              >
                Create Global Value
              </Title>
              {currentGlobalTable?.dataColumns?.map((elm) => {
                return (
                  <TextInput
                    field={{
                      name: elm.name,
                      type: elm.dataType,
                      required: true,
                    }}
                    register={registerGlobalValue}
                    errors={errorsGlobalValue}
                  />
                );
              })}
              <CustomButton type={"submit"} text={"Create"} margin={0} />
            </Form>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        openDialog={openDialogUpdateGlobalValue}
        setOpenDialog={setOpenDialogUpdateGlobalValue}
        toExecuteOnClose={() => {}}
        content={{
          title: "Update Global Value",
          description: (
            <Form
              onSubmit={handleSubmitUpdateGlobalValue(handelUpdateGlobalValue)}
            >
              <Title
                style={{
                  color: StyleColor2(theme),
                }}
              >
                Update Global Value
              </Title>
              {currentGlobalTable?.dataColumns?.map((elm) => {
                return (
                  <TextInput
                    field={{
                      name: elm.name,
                      type: elm.dataType,
                      required: true,
                    }}
                    register={registerUpdateGlobalValue}
                    errors={errorsUpdateGlobalValue}
                  />
                );
              })}
              <CustomButton type={"submit"} text={"Update"} margin={0} />
            </Form>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <Content>
        <InformationSection className="background-1" style={{ border: "none" }}>
          <h1>
            Digital Twin <br /> Global Tables <br /> Configuration
          </h1>
          <p>
            This module allows users to create and manage tables of equipment
            specifications or standard parameters that are seamlessly integrated
            into the Configuration of the Platform and the Digital Twin Module.
            Users can define equipment types, assign unique names, and input key
            specifications into a global table.
            <br />
            <br />
            Here, users can consult, edit, or add categories, as well as design
            or view tables and their corresponding values. To get started,
            simply add a new table or select an existing category to view and
            manage current tables.
          </p>
        </InformationSection>
        <GlobalContent className="background-2 border-top-1">
          <ConfigurationSection>
            <ConfigLibrary className="background-2">
              <ContentButton>
                <div className="select-categories">
                  <CustomButton
                    text={"+ Create New Global Table"}
                    margin={0}
                    type={"onClick"}
                    onClick={() => handleCreateNewTable()}
                    primaryColor={"#017371"}
                    secondaryColor={"#4cb7a5"}
                  />
                </div>
                <div className="select-categories">
                  <label>Choose group category</label>
                  <div
                    style={{
                      width: "60%",
                    }}
                  >
                    <Select
                      onChange={handleSelectCategory}
                      isDisabled={
                        dataCategories && dataCategories.length > 0
                          ? false
                          : true
                      }
                      options={
                        dataCategories &&
                        dataCategories.length > 0 &&
                        dataCategories?.map((option) => {
                          return {
                            value: option._id,
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    width: "100%",
                                    marginLeft: "-1px",
                                  }}
                                >
                                  {option.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    width: "80px",
                                  }}
                                >
                                  <CancelIcon
                                    sx={{
                                      color: "red",
                                      width: "15px",
                                      height: "auto",
                                      cursor: "pointer",
                                      transition: "color 0.3s",
                                      "&:hover": {
                                        color: "darkred",
                                      },
                                    }}
                                    onClick={() =>
                                      handleOpenDialogDeleteCategory({
                                        categoryId: option._id,
                                        name: option.name,
                                      })
                                    }
                                  />
                                  <EditIcon
                                    sx={{
                                      color: PRIMARY_COLOR,
                                      width: "15px",
                                      height: "auto",
                                      cursor: "pointer",
                                      transition: "color 0.3s",
                                      "&:hover": {
                                        color: SECOND_COLOR,
                                      },
                                    }}
                                    onClick={() => {
                                      setCategoryToEdit({
                                        id: option._id,
                                        name: option.name,
                                      });
                                      setOpenDialogEditCategory(true);
                                    }}
                                  />
                                </div>
                              </div>
                            ),
                          };
                        })
                      }
                      value={currentCategory}
                      placeholder={
                        dataCategories && dataCategories.length > 0
                          ? dataCategories?.find(
                              (elm) => elm._id === currentCategory
                            )?.name
                          : "There are no categories"
                      }
                      styles={customSelectStyle}
                      menuPlacement="auto"
                    />
                  </div>
                </div>
              </ContentButton>
              <ContentData>
                {dataGlobalTables?.length !== 0 ? (
                  <Columns paddingLeft={30}>
                    <Column width={10} textAling={"start"}>
                      Id
                    </Column>
                    <Column width={90} textAling={"start"}>
                      Table Name
                    </Column>
                  </Columns>
                ) : (
                  <Space />
                )}
                {dataGlobalTables?.length === 0 ? (
                  <InformationShare>
                    <div className="head">
                      Edit Existing Table Global Values Fields
                    </div>
                    <div className="body">
                      To Edit Click on + New Global Table
                    </div>
                  </InformationShare>
                ) : (
                  <DragDropContext onDragEnd={onDragConstantLibrary}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {(provided) => (
                        <ContentMoveTags
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {dataGlobalTables?.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={item._id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <MoveTag
                                  className="background-row-1 border-color-1"
                                  ref={provided.innerRef}
                                  currentGlobalTableId={currentGlobalTable?._id}
                                  item={item}
                                  textAling={"start"}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={index + 1}
                                      onClick={() => {
                                        setCurrentGlobalTable(item);
                                        resetInputsGlobalValue();
                                      }}
                                      margin={0}
                                    />
                                  </div>
                                  <ContentDataTag
                                    className="background-2"
                                    width={90}
                                  >
                                    {item.name}
                                  </ContentDataTag>
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={<DeleteIcon />}
                                      onClick={(e) =>
                                        handleOpenDialogDeleteTable({
                                          e: e,
                                          table: item,
                                        })
                                      }
                                      margin={0}
                                      primaryColor={DELETE_COLOR}
                                      secondaryColor={HOVER_DELETE_COLOR}
                                    />
                                  </div>
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={
                                        <Tooltip title="CSV TEMPLATE">
                                          <FileDownloadIcon />
                                        </Tooltip>
                                      }
                                      onClick={(e) =>
                                        handleDownloadCSVTemplate(e)
                                      }
                                      margin={0}
                                    />
                                  </div>
                                  <div>
                                    <CustomButton
                                      type="onClick"
                                      text={<EditIcon />}
                                      onClick={(e) =>
                                        handleOpenDialogUpdate({
                                          e: e,
                                          table: item,
                                        })
                                      }
                                      margin={0}
                                    />
                                  </div>
                                </MoveTag>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ContentMoveTags>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </ContentData>
            </ConfigLibrary>
            <ConfigConstant className="background-2">
              <ContentButton className="background-row-2 border-color-1">
                <h1> {`Create new values for the table: `}</h1>{" "}
                <h1
                  style={{
                    color: "#017371",
                    marginLeft: "5px",
                  }}
                >
                  {currentGlobalTable?.name || ""}
                </h1>
                <CustomButton
                  text={"+ New Global Value"}
                  margin={0}
                  type={"onClick"}
                  onClick={handleOpenDialogCreateGlobalValue}
                  disabled={currentGlobalTable ? false : true}
                  primaryColor={
                    currentGlobalTable ? PRIMARY_COLOR : VISIBILITY_OFF_ICON
                  }
                  secondaryColor={
                    currentGlobalTable ? SECOND_COLOR : VISIBILITY_OFF_ICON
                  }
                />
                <input
                  type="file"
                  accept=".csv"
                  ref={inputFileRef}
                  style={{ display: "none" }} // hidde input
                  onChange={handleUploadCSV}
                />
                <CustomButton
                  text={"+ Upload CSV"}
                  margin={0}
                  type={"onClick"}
                  onClick={handleButtonClick}
                  disabled={currentGlobalTable ? false : true}
                  primaryColor={
                    currentGlobalTable ? PRIMARY_COLOR : VISIBILITY_OFF_ICON
                  }
                  secondaryColor={
                    currentGlobalTable ? SECOND_COLOR : VISIBILITY_OFF_ICON
                  }
                />
              </ContentButton>

              <ContentData
                className="background-row-2 border-color-1"
                style={{ borderRadius: "5px", padding: "10px" }}
              >
                {currentGlobalTable ? (
                  <GenericTable
                    headers={currentGlobalTable.headers.concat(["Tools"])}
                    rows={currentGlobalTable.rows.map((elm, index) => {
                      return {
                        ...elm,
                        Tools: (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                width: "20px",
                              }}
                            >
                              <CustomButton
                                type="onClick"
                                text={<EditIcon />}
                                onClick={(e) =>
                                  handleOpenDialogEditGlobalValue({
                                    globalValue: elm,
                                  })
                                }
                                margin={0}
                              />
                            </div>
                            <div
                              style={{
                                width: "20px",
                              }}
                            >
                              <CustomButton
                                type="onClick"
                                text={<DeleteIcon />}
                                onClick={(e) =>
                                  handleOpenDialogDeleteValue({
                                    e,
                                    option: elm.Options,
                                  })
                                }
                                margin={0}
                                primaryColor={DELETE_COLOR}
                                secondaryColor={HOVER_DELETE_COLOR}
                              />
                            </div>
                          </div>
                        ),
                      };
                    })}
                    keyValues={currentGlobalTable.headers.concat(["Tools"])}
                    handlerClick={() => {}}
                    state={true}
                    customTableWidth={"100%"}
                    customHeaderWidth={"5%"}
                    from={FROM_TABLE.details}
                  />
                ) : (
                  <InformationShare>
                    <div className="head color1">
                      Edit Existing values for the table
                    </div>
                    <div className="body color1">
                      To Edit Click on + New Global Value
                    </div>
                  </InformationShare>
                )}
                {errorMessage && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {errorMessage}
                  </div>
                )}
              </ContentData>
            </ConfigConstant>
          </ConfigurationSection>
        </GlobalContent>
      </Content>
    </Layout>
  );
};

export default GlobalValuesFields;
