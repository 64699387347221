import { useState, useEffect, useRef } from "react";
import { useSWRConfig } from "swr";
// Components
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"; 
import OpenDialog from "components/Dialogs/OpenDialog";
import ContentDialog from "./ContentDialog";
// Services
import { saveCaptureMedia } from "services/lines/uploadFileLines";
import { pathFolder } from "utils/paths";
import { getPathBucket } from "helpers/getPathBucket";
import { Box } from "@mui/material";
import CustomButton from "components/Buttons/CustomButton";
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
import { ProgressBar } from "components/ElementDetails/PhotoGalley/ProgressBar";
import { urls } from "utils/urlKeys";

const AddPicture = ({ line, uploadImages }) => {
  const [photosFile, setPhotosFile] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [typeLineMedia, setTypeLineMedia] = useState(null);
  const [dialogNoCaptureMedia, setDialogNoCaptureMedia] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);

  const abortControllerRef = useRef(null);

  const { mutate } = useSWRConfig();

  const inputRef = useRef();

  useEffect(() => {
    const typeMedia = line?.fieldsCaptureMedia?.[0]?.name;
    setTypeLineMedia(typeMedia);
  }, [line]);

  const uploadFile = async (e) => {
    const files = e.target.files;
    let size = 0;
    Object.keys(files).forEach((key) => {
      size += files[key].size;
    });
    setTotalSize(size);

    Object.keys(files).forEach((key) => {
      setPhotosFile((current) => [...current, files[key]]);
    });
    Object.keys(files).forEach((key) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[key]);
      reader.onload = () => {
        setPhotos((current) => [reader.result, ...current]);
      };
    });
    setOpenModal(true);
    setTotalFiles(files.length);
  };

  const cancelUpload = () => {
    setIsCancelled(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setOpenModal(false);
    setPhotosFile([]);
    setPhotos([]);
    setUploadedSize(0);
    setUploadProgress(0);
    setIsUploading(false);
  };

  const savePhotos = async () => {
    try {
      setIsUploading(true);
      setIsCancelled(false);
      setUploadProgress(0);
      setUploadedSize(0);

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const folderPath = getPathBucket(
        pathFolder.objects.lines.pictures(typeLineMedia)
      );

      for (let i = 0; i < photosFile.length; i++) {
        if (isCancelled) {
          console.log("Upload cancelled");
          break;
        }

        const file = photosFile[i];
        try {
          const { fileUrl } = await uploadFileToS3({
            file,
            folderPath,
            signal,
          });

          if (isCancelled) break;

          setUploadedSize((prev) => prev + file.size);

          const aliasFieldMedia = line?.fieldsCaptureMedia.find(
            (item) => item.name === typeLineMedia
          )?.alias;

          await saveCaptureMedia({
            lineObjectMgId: line?._id || line?.geojsonLineId,
            media: [
              {
                type: typeLineMedia,
                alias: aliasFieldMedia,
                url: fileUrl,
              },
            ],
          });

          setUploadProgress((prev) => prev + 1);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Upload aborted");
            break;
          } else {
            console.error("Error uploading file:", error);
          }
        }
      }

      if (!isCancelled) {
        setOpenModal(false);
        setTotalFiles(0);
        setUploadedSize(0);
        setPhotosFile([]);
        setPhotos([]);
        mutate(urls.lines.all);
        mutate(urls.lines.detail(line.id));
        uploadImages();
      }
    } catch (error) {
      console.error("Error saving photos:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <CustomButton
        text={"Add Pictures"}
        onClick={() => document.getElementById("files").click()}
        margin={0}
        icon={<AddAPhotoIcon sx={{ fontSize: "18px" }} />}
        padding={"7px 10px"}
      />
      {!dialogNoCaptureMedia && (
        <input
          id="files"
          style={{ visibility: "hidden", position: "absolute" }}
          accept="image/*"
          type="file"
          name="file"
          multiple
          onChange={uploadFile}
          ref={inputRef}
        />
      )}

      <OpenDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        content={{
          title: "Upload pictures",
          description: (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0,
                }}
              >
                {isUploading && (
                  <ProgressBar
                    count={uploadProgress}
                    totalFiles={totalFiles}
                    porcentaje={(uploadProgress * 100) / totalFiles}
                    uploadedSize={uploadedSize}
                    totalSize={totalSize}
                  />
                )}
                <ContentDialog
                  pictures={photos}
                  line={line}
                  typeLineMedia={typeLineMedia}
                  setTypeLineMedia={setTypeLineMedia}
                />
              </Box>
            </>
          ),
          agree: "Save",
          disagree: "Cancel",
        }}
        execute={savePhotos}
        disableBackdropClick={isUploading}
        disableEscapeKeyDown={isUploading}
        toExecuteOnClose={cancelUpload}
      />
      <OpenDialog
        openDialog={dialogNoCaptureMedia}
        setOpenDialog={setDialogNoCaptureMedia}
        content={{
          title: "Error 'captureMedia' field",
          description: (
            <div>
              <h2>Field 'CaptureMedia' is missing</h2>
              <span>
                For uploading images the library '
                {line?.lineType?.lineLibrary?.name}' needs at least one
                'captureMedia' field.
              </span>
              <br />
              <span>
                this can be done in 'Configuration' &gt; 'Libraries'{" "}
                <a href="/library-configurations">library-configurations</a>
              </span>
            </div>
          ),
          disagree: "Close",
        }}
      />
    </>
  );
};

export default AddPicture;
