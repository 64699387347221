// Required modules
import GenericTable from "components/Tables/GenericTable/GenericTable.jsx";
import Form from "components/Forms/Form.jsx";
import MessagesDialog from "components/Dialogs/MessagesDialog.jsx";
// Config admin class
import ConfigAdmin from "../ConfigAdmin.js";

// Hooks
import { useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";

// Necessary data
import {
  headersTableLibraryPoint,
  keyValuesTableLibraryPoint,
  initialValuesFormLibraryPoint,
  fromLibrary,
} from "../dataConfigAdmin";

// Styles
import {
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  InformationShare,
  IconAdminConfigChangeColum,
  ConfigAdminContainer,
  TableAdminConfig,
} from "../configAdminStyles";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { ContainerForm } from "components/Forms/FormStyles";
import Fields from "pages/ConfigAdmin/typeElemts/Fields.jsx";
import { FROM_TABLE, TYPE_FIELDS, TYPE_POINTS } from "utils/constStrings.jsx";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import { DuplicateLibrary } from "./DuplicateLibrary.jsx";
import { roles } from "utils/roles.jsx";
import createPointTypeComponent from "services/components/createPointTypeComponent.js";
import { useUserStore } from "zustandGloabalStore/user.jsx";
import { urls } from "utils/urlKeys.jsx";
// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <IoIosArrowDropupCircle className="icon" />
    </IconAdminConfigChangeColum>
  );
};

export default function ComponentLibraries({
  setOpenDialogConfig,
  setContentDialogConfig,
  instanceId,
  adminDecimetrixInstances,
}) {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [typeElementsSelect, setTypeElementsSelect] = useState([]);

  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      agree: "Close",
    },
  });

  const role = useUserStore.getState().role;

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormLibraryPoint);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);
  // State button loadingCreate
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
  });

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [libreriesRows, setLibreriesRows] = useState([]);

  // Click  on type element into table
  const handleClickLibrary = (id) => {
    configAdmin.handlerClickIntoTable(id);
    configAdmin.setEndpoint(urls.components.allLibrariesByToken);
  };

  // Click on enable or disable library
  const handlerClickToggleLibrary = (id) => {
    configAdmin.handlerClickToggleLibrary(id);
    configAdmin.setEndpoint(urls.components.allLibrariesByToken);
  };

  const handleClickCreateLibrary = () => {
    configAdmin.toggleForm();
    configAdmin.setEndpoint(urls.components.librariesByInstanceId(instanceId));
  };

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableLibraryPoint);
    configAdmin.setKeyValuesTable(keyValuesTableLibraryPoint);
    configAdmin.setSetRows(setLibreriesRows);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetLoadingCreate(setLoadingCreate);
    configAdmin.setSetLoadingDelete(setLoadingDelete);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin.setCreateTypeElements(createPointTypeComponent);
    configAdmin?.setEndpoint(
      instanceId && instanceId !== "defaultLibrary"
        ? urls.components.librariesByInstanceId(instanceId)
        : urls.components.allLibrariesByToken
    );
    configAdmin?.setDialog(setOpenDialogConfig, setContentDialogConfig);
    configAdmin.setFromLibrary(fromLibrary.pointComponents);
    configAdmin?.fetchData();
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, [setOpenDialogConfig, setContentDialogConfig, instanceId]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  useEffect(() => {
    configAdmin && configAdmin.setsetTypeElementsSelect(typeElementsSelect);
    configAdmin && configAdmin.setsetInstanceId(instanceId);
  }, [typeElementsSelect, configAdmin, instanceId]);

  //alerts
  useEffect(() => {
    const alert = configAdmin?.getAlertStatus();
    if (alert?.open) {
      setOpenAlert({
        open: alert.open,
        severity: alert.severity,
      });
    }
  }, [configAdmin?.getAlertStatus, configAdmin]);

  return (
    <>
      <ConfigAdminContainer className="background-2 border-top-1">
        <RowAdminConfig>
          <OpenDialog
            openDialog={dialog?.open}
            setOpenDialog={setDialog}
            execute={() => {}}
            content={{
              title: "Select library and types to duplicate",
              description: (
                <DuplicateLibrary
                  instanceId={instanceId}
                  adminDecimetrixInstances={adminDecimetrixInstances}
                  pointComponent={true}
                  configAdmin={configAdmin}
                  handleChangeForm={handleChangeForm}
                  setDialog={setDialog}
                  setTypeElementsSelect={setTypeElementsSelect}
                />
              ),
            }}
            minWidth={"500px"}
          />
          <TableAdminConfig>
            {configAdmin && (
              <GenericTable
                headers={configAdmin.getHeadersTable()}
                rows={libreriesRows}
                keyValues={configAdmin.getKeyValuesTable()}
                handlerClick={handleClickLibrary}
                handlerClickToggleLibrary={handlerClickToggleLibrary}
                style={{
                  width: 100,
                }}
                state={true}
                configBar={true}
                from={FROM_TABLE.config}
              />
            )}
          </TableAdminConfig>
          <ColumnAdminConfig>
            {seeForm ? (
              <ContainerForm see={seeForm}>
                <Form
                  form={form}
                  // setSelect={setSelect}
                  // select={select}
                  handleChange={handleChangeForm}
                  handleRows={handleClickLibrary}
                  buttons={buttonsForm}
                  loadingCreate={loadingCreate}
                  loadingDelete={loadingDelete}
                  renderBeforeTitle={[
                    <ButtonSeeInitalView
                      click={() => configAdmin.toggleForm()}
                    />,
                  ]}
                  renderBeforeButtons={[
                    <Fields
                      typeFields={TYPE_FIELDS.typeToLibrary}
                      form={form}
                      handleChange={handleChangeForm}
                      from={TYPE_POINTS.component}
                    />,
                  ]}
                  title={"Create a new Digital Twin Library"}
                  typeElementsSelect={typeElementsSelect}
                  setTypeElementsSelect={setTypeElementsSelect}
                  role={role}
                />
              </ContainerForm>
            ) : (
              <>
                <CreateNewRegionButton
                  className="background-row-2 color1 border-color-1"
                  margin={role === roles.decimetrixAdmin ? "7px 0" : "13px 0"}
                >
                  Create a new Digital Twin Library
                  <button onClick={() => handleClickCreateLibrary()}>
                    <span>
                      <IoMdAdd />
                    </span>
                    New Type Library
                  </button>
                  {role === roles.decimetrixAdmin &&
                    instanceId !== "defaultLibrary" && (
                      <button
                        onClick={() =>
                          setDialog((current) => ({
                            ...current,
                            open: true,
                          }))
                        }
                      >
                        <span>
                          <IoMdAdd />
                        </span>
                        Duplicate Library from another Admin company{" "}
                      </button>
                    )}
                </CreateNewRegionButton>
                <InformationShare className="background-row-2 border-color-1">
                  <div className="body color1">
                    To Edit Click on Existing Digital Twin Library ID
                  </div>
                </InformationShare>
              </>
            )}
          </ColumnAdminConfig>
        </RowAdminConfig>
      </ConfigAdminContainer>
      {openAlert.open && (
        <MessagesDialog
          open={openAlert.open}
          severity={openAlert?.severity}
          message={configAdmin?.messageShow}
          handleClose={() => setOpenAlert(false)}
        />
      )}
    </>
  );
}
