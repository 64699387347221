import { config } from "config.js";

export const s3MaganerUrls = {
  buckets: {
    create: `${config.URL_API_S3}api/bucket/create`,
  },
  folder: {
    create: `${config.URL_API_S3}api/folder/create`,
  },
  file: {
    upload: `${config.URL_API_S3}api/file/upload`,
  },
  signedUrl: {
    get: (queryParams) =>
      `${config.URL_API_S3}api/presigned/generate?${queryParams}`,
  },
};
