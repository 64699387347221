import {
  CustomContainer,
  BannerDescription,
  BannerTitle,
  LogoDecimetrix,
  NeuronImage,
} from "./ForgotPasswordElemts";

// hooks
import { useColorHostName } from "hooks/colorHostName";
import { useContentData } from "hooks/useContentData";

export default function BannerDecimetrix() {
  const { color, isRedDragon } = useColorHostName();
  const contentData = useContentData({ isRedDragon });
  const { image, title, subtitle, description } = contentData;

  const neuronUrl =
    "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png";

  return (
    <CustomContainer>
      <NeuronImage src={neuronUrl} />

      <LogoDecimetrix className="form-image" src={image} alt="logo" />
      <BannerTitle colorTitle={color.primaryColor}>
        {title} <br /> {subtitle}
      </BannerTitle>
      <BannerDescription>{description}</BannerDescription>
    </CustomContainer>
  );
}
