import { getUrlSigned } from "services/s3Manager/s3Manager";

export const isUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

export const nameUrl = (url) => {
  const nameUrl = url?.split("/");
  return nameUrl?.[nameUrl?.length - 1] || nameUrl?.[nameUrl?.length - 2];
};

/**
 * Handles the click event for a link that requires a signed URL from S3.
 * Prevents the default navigation behavior, retrieves a signed URL, and opens it in a new tab.
 *
 * @param {Event} event - The click event triggered by the user.
 * @param {string} s3Url - The S3 URL of the private resource to be accessed.
 */

export const handleClickLink = async (event, s3Url) => {
  event.preventDefault(); // Prevents the default link navigation behavior

  try {
    // Request a signed URL for the given S3 resource
    const signedUrl = await getUrlSigned({ s3Url });

    if (signedUrl) {
      // Open the signed URL in a new tab with security attributes
      window.open(signedUrl, "_blank", "noopener,noreferrer");
    }
  } catch (error) {
    console.error("Error retrieving the signed URL:", error);
  }
};
