// Styles
import Box from "@mui/material/Box";
// React
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
// Dependencies
import useSwr from "swr";
// Services
import { userVerification } from "services/users/userVerification";
// Const
import GenericTable from "../GenericTable/GenericTable";
import { FROM_TABLE } from "utils/constStrings";
// Components
import Loading from "components/Lodings/LoadingV2";
import { urls } from "utils/urlKeys";
import { roles } from "utils/roles";

export const keyValuesUsers = [
  "firstName",
  "secondName",
  "lastName",
  "email",
  "company",
  "role",
  "area",
  "activeSession",
  "confirmationToken",
  "actions",
  "noSessions",
  "userLogOut",
  "noSessionsMobile",
  "userLogOutMobile",
  "createdAt",
];

export const headersUsers = [
  "Name",
  "Second Name",
  "Last Name",
  "E-mail",
  "Instance",
  "Role",
  "Area",
  "Session",
  "State",
  "Actions",
  "No Sessions Web",
  "User Log Out",
  "No Sessions Mobile",
  "User Log Out Mobile",
  "Created At",
];

const createRows = (operators) => {
  return Promise.all(
    operators.map((operator) => {
      return userVerification(operator.user.email).then((res) => {
        return {
          id: operator.userId,
          firstName: operator.firstName,
          secondName: operator.secondName,
          lastName: operator.firstLastName,
          email: operator.user.email,
          company: operator?.instance?.name,
          role: operator.role?.role,
          area: operator.area,
          // canDelete: operator.user.objects.length === 0, Esto es re inecesario, para saber si se puede elimar el operario traia en la respuesta de la peticion todos los objetos para saber si tiene objetos recolectados en el mapa
          canDelete: true, // por ahora lo dejo en true pero hay que enviar la respuesta desde backend haciendo un count con un query a la db
          activeSession: res.activeSession ? "Online" : "Offline ",
          confirmationToken: operator.user.userVerification.confirmationToken
            ? "E-Mail pending of verification"
            : "Verified e-mail",
          createdAt: operator.createdAt.substring(0, 10),
          isEnable: res.isEnable,
          noSessions: res.noSessions,
          noSessionsMobile: res.noSessionsMobile,
        };
      });
    })
  );
};

export default function ListOperators() {
  const [rows, setRows] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [valuesNoSessions, setValuesNoSessions] = useState({});
  const [valuesNoSessionsMobile, setValuesNoSessionsMobile] = useState({});

  const { data: instanceOperators, error } = useSwr(urls.users.getOperators);

  useEffect(() => {
    if (!error && instanceOperators) {
      setIsLoad(true);
      createRows(instanceOperators).then((operatorsRows) => {
        operatorsRows.forEach(({ id, noSessions, noSessionsMobile }) => {
          setValuesNoSessions((prevValues) => ({
            ...prevValues,
            [id]: noSessions,
          }));
          setValuesNoSessionsMobile((prevValues) => ({
            ...prevValues,
            [id]: noSessionsMobile,
          }));
        });
        setRows(operatorsRows?.length > 0 ? operatorsRows : []);
        setIsLoad(false);
      });
    }
  }, [instanceOperators, error]);

  return (
    <Box sx={{ width: "100%" }}>
      {rows && (
        <GenericTable
          keyValues={keyValuesUsers}
          headers={headersUsers}
          rows={rows}
          setRows={setRows}
          valuesNoSessions={valuesNoSessions}
          setValuesNoSessions={setValuesNoSessions}
          valuesNoSessionsMobile={valuesNoSessionsMobile}
          setValuesNoSessionsMobile={setValuesNoSessionsMobile}
          state={true}
          configBar={true}
          from={FROM_TABLE.users}
          register={roles.operario}
        />
      )}
      {isLoad && <Loading />}
    </Box>
  );
}
