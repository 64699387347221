// Styles
import {
  ContainerContentTable,
  ContainerTable,
  ContentTable,
  Divider,
} from "./GenericTableStyle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { RED_COLOR, SECOND_COLOR } from "utils/const";
// Dependencies
import { flexRender } from "@tanstack/react-table";
// Helpers
import GenericFilterTable from "./helpers/components/Filter/GenericFilterTable";
import { FooterTools } from "./helpers/components/FooterTools";
import { InputSearchTable } from "./helpers/components/InputSearchTable";
import { useContext } from "react";
import { ThemeContext } from "App";
export const ConfigTable = ({
  style,
  table,
  useFilter,
  filterValue,
  setFilterCheckBox,
  filterCheckBox,
  handlerClick,
  handlerClickToggleLibrary,
  columnResizeMode,
  dynamicValues,
  state,
  handleGlobalFilterChange,
  buttonFilters,
  handlerSetValue,
  setUseFilter,
  setMessageInfoFilter,
  messageInfoFilter,
}) => {
  return (
    <div
      style={{
        marginTop: "0px",
        height: "100%",
      }}
    >
      <ContainerTable className="background-row-2 color1 border-color-1">
        <p>Filters for Existing Libraries</p>
        <InputSearchTable
          state={state}
          handleGlobalFilterChange={handleGlobalFilterChange}
          table={table}
          useFilter={useFilter}
          setUseFilter={setUseFilter}
          buttonFilters={buttonFilters}
          handlerSetValue={handlerSetValue}
        />
      </ContainerTable>
      <Divider />
      <ContainerContentTable
        className="background-row-2 border-color-1"
        style={{ flex: 1, borderRadius: "5px" }}
      >
        <ContentTable
          className="background-row-2 color1"
          maxHeight={style?.maxHeight}
          style={{
            borderRadius: "5px 5px 0 0",
          }}
        >
          <table
            {...{
              style: {
                width: "100%",
              },
            }}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  style={{
                    width: "100%",
                    fontFamily: document.body.style.fontFamily,
                  }}
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className="background-table-color"
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                        style: {
                          width: "100vw",
                        },
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <ExpandLessIcon />,
                              desc: <ExpandMoreIcon />,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                          {header.column.getCanFilter() && useFilter ? (
                            <div style={{ marginTop: "5px" }}>
                              <GenericFilterTable
                                column={header.column}
                                table={table}
                                setValue={filterValue}
                                setFilterCheckBox={setFilterCheckBox}
                                filterCheckBox={filterCheckBox}
                              />
                            </div>
                          ) : null}
                        </>
                      )}

                      <div
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`,
                          style: {
                            transform:
                              columnResizeMode === "onEnd" &&
                              header.column.getIsResizing()
                                ? `translateX(${
                                    table.getState().columnSizingInfo
                                      .deltaOffset
                                  }px)`
                                : "",
                          },
                        }}
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  style={{ fontFamily: document.body.style.fontFamily }}
                  key={row.id}
                  className={
                    index % 2 !== 0
                      ? "background-row-1 color1"
                      : "background-row-2 color1"
                  }
                >
                  {row.getVisibleCells().map((cell, index) =>
                    cell.getContext().column.columnDef.header().props
                      .children === "Id" ? (
                      <td
                        {...{
                          key: cell.id,
                          style: {
                            minWidth: `${cell.column.getSize()}px`,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() =>
                          handlerClick(
                            row.original.id,
                            dynamicValues[row.original.id]
                          )
                        }
                      >
                        {flexRender(cell.getValue(), cell.getContext())}
                      </td>
                    ) : cell.getContext().column.columnDef.header().props
                        .children === "Available" ? (
                      <td
                        {...{
                          key: cell.id,
                          style: {
                            minWidth: `${cell.column.getSize()}px`,
                            cursor: "pointer",
                            fontSize: "3.5rem",
                          },
                        }}
                        onClick={() =>
                          handlerClickToggleLibrary(
                            row.original.id,
                            dynamicValues[row.original.id]
                          )
                        }
                      >
                        {flexRender(cell.getValue(), cell.getContext()) ===
                        true ? (
                          <ToggleOnIcon
                            fontSize="inherit"
                            sx={{
                              color: SECOND_COLOR,
                            }}
                          />
                        ) : (
                          <ToggleOffIcon
                            fontSize="inherit"
                            sx={{
                              color: RED_COLOR,
                            }}
                          />
                        )}
                      </td>
                    ) : (
                      <td
                        {...{
                          key: cell.id,
                          style: {
                            minWidth: `${cell.column.getSize()}px`,
                          },
                        }}
                      >
                        {flexRender(cell.getValue(), cell.getContext()) ===
                        null ? (
                          <HorizontalRuleIcon />
                        ) : (
                          flexRender(cell.getValue(), cell.getContext())
                        )}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="h-2" />
        </ContentTable>
        <FooterTools table={table} />
      </ContainerContentTable>
    </div>
  );
};
