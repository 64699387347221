import { LinearProgress, Typography, Box } from "@mui/material";
import { SECOND_COLOR } from "utils/globalColorsTheme";

const formatSize = (bytes) => {
  if (bytes === 0) return "0 B";

  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export const ProgressBar = ({
  count,
  totalFiles,
  porcentaje,
  uploadedSize = 0,
  totalSize = 0,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        mt: 2,
        p: 2,
        borderRadius: 1,
        boxShadow: 2,
        bgcolor: "background.paper",
        textAlign: "center",
      }}
    >
      <Typography variant="body2" color="textSecondary">
        {`Uploading ${count} of ${totalFiles} files  (${formatSize(
          uploadedSize
        )} of ${formatSize(totalSize)})`}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={porcentaje}
        sx={{
          height: 10,
          borderRadius: 1,
          my: 1,
          backgroundColor: "#e0e0e0", 
          "& .MuiLinearProgress-bar": {
            backgroundColor: SECOND_COLOR,
          },
        }}
      />
      <Typography variant="body2" fontWeight="bold">
        {`${Math.round(porcentaje)}%`}
      </Typography>
    </Box>
  );
};
