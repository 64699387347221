import axios from "axios";
import { config } from "config.js";

const uploadCSV = async (tableId, tableCategoriesId, groupId, file) => {
  try {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file); // Agregar el archivo CSV
    formData.append("tableId", tableId); // Agregar el ID de la tabla
    formData.append("tableCategoriesId", tableCategoriesId); // Agregar el ID de la categoría
    formData.append("groupId", groupId);

    const res = await axios.post(
      `${config.URL_BACKEND_MG}global-values-fields/upload-csv`,
      formData,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": "multipart/form-data", // El encabezado necesario para enviar archivos
        },
      }
    );

    return { data: res.data, res };
  } catch (error) {
    return { error };
  }
};

export default uploadCSV;
