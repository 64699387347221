import axios from "axios";
import { urls } from "utils/urlKeys";

async function createInstance(body) {
  try {
    const token = localStorage.getItem("token");
    const result = await axios.post(urls.instance.all, body, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    const data = await result;
    return data;
  } catch (error) {
    console.error("🚩🚩Error on Create new View: ", error);
    return { error };
  }
}

export default createInstance;
