import { useEffect, useState } from "react";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";
const useFindTextCharts = ({ openDialog }) => {
  const [charts, setCharts] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataTextChart, error: errorDataTextChart } = useSwr(
    urlsApiMg.dashboard.chartText("components")
  );

  useEffect(() => {
    if (!errorDataTextChart && dataTextChart) setCharts(dataTextChart);
  }, [dataTextChart, errorDataTextChart, openDialog, reloadCharts]);

  return { textCharts: charts };
};

export default useFindTextCharts;
