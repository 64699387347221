import { useState, useEffect } from "react";
// Constants
import { WHITE_SECOND_COLOR } from "utils/const";
// Styles
import { ContainerDashboard } from "./indexStyle";
// Hooks
import useFindObjects from "hooks/objects/useFindObjects";
import useFindComponents from "hooks/components/useFindComponents";
import useFindAllEvents from "hooks/events/useFindAllEvents";
// Components
import Layout from "components/Layout/AdminDecimetrix";
import ChartsTabs from "./ChartsTabs";
import useSwr from "swr";
import MessagesDialog from "components/Dialogs/MessagesDialog";
import { ToolBar } from "./components/ToolBar";
import useStoreDashboard from "./Store";
import { urlsApiMg } from "utils/urlKeys";

const Dashboard = () => {
  const [filterObjects, setFilterObjects] = useState([]);
  const [filterComponents, setFilterComponents] = useState([]);
  const [filterEvents, setFilterEvents] = useState([]);
  const [typeChart, setTypeChart] = useState("allTypes");
  const [currentOrderName, setCurrentOrderName] = useState("Design New View");
  const [currentTemplate, setCurrentTemplate] = useState({
    name: "createTemplate",
    gridTemplateColumns: "repeat(var(--col-count, 8), 1fr)",
    chartsPosition: [],
    sizeChart: {
      width: 250,
      height: 180,
    },
  });
  const [currentBackGround, setCurrentBackGround] =
    useState(WHITE_SECOND_COLOR);
  const [privateViews, setPrivateViews] = useState([]);
  const [publicViews, setPublicViews] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentOrderViews, setCurrentOrderViews] = useState([]);
  const [currentPositionId, setCurrentPositionId] = useState(0);
  const [orderId, setOrderId] = useState();
  const [resetDefaultView, setResetDefaultView] = useState(true);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [useSelectViews, setUseSelectViews] = useState(false);
  const [useSelectTypes, setUseSelectTypes] = useState(false);
  const [useCalendar, setUseCalendar] = useState(false);
  const [useTemplates, setUseTemplates] = useState(false);
  const [openDialogSettings, setOpenDialogSettings] = useState(false);
  const [openDialogRestoreChart, setOpenDialogRestoreChart] = useState(false);
  const [openToolPrintPDF, setOpenToolPrintPDF] = useState(false);
  // Fetch all data
  const { components, loading: loadingComponents } = useFindComponents();
  const { events, loading: loadingEvents } = useFindAllEvents();
  const { objects, loading } = useFindObjects();
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);

  const { data: dataViewOrderCards, error: errorDataViewOrderCards } = useSwr(
    urlsApiMg.dashboard.orderCards
  );

  useEffect(() => {
    if (
      !errorDataViewOrderCards &&
      dataViewOrderCards &&
      dataViewOrderCards.length
    ) {
      const privateViews = dataViewOrderCards[0].orders[typeChart].filter(
        (view) => {
          return view.typeView === "private";
        }
      );

      const publicViews = dataViewOrderCards[0].orders[typeChart].filter(
        (view) => {
          return view.typeView === "public";
        }
      );

      const defaultView = [...privateViews, ...publicViews].find(
        (elm) => elm.defaultView === true
      );

      if (defaultView && resetDefaultView) {
        setCurrentOrderName(defaultView.name);
        setCurrentPositionId(defaultView._id);
        setCurrentOrderViews(defaultView.position);
        setCurrentTemplate(defaultView.template);
        setCurrentBackGround(defaultView.backgrounColor);
      }
      setPrivateViews(privateViews);
      setPublicViews(publicViews);
      setOrderId(dataViewOrderCards[0]._id);
    }
  }, [
    dataViewOrderCards,
    errorDataViewOrderCards,
    typeChart,
    resetDefaultView,
    newCreateChart,
  ]);

  const handleCloseTools = () => {
    setUseSelectViews(false);
    setUseCalendar(false);
    setUseSelectTypes(false);
    setUseTemplates(false);
    setOpenToolPrintPDF(false);
  };

  return (
    <Layout>
      {openAlert.open && (
        <MessagesDialog
          open={openAlert.open}
          severity={openAlert.severity}
          message={openAlert.message}
          handleClose={() => setOpenAlert(false)}
        />
      )}
      <ContainerDashboard backgrounColor={currentBackGround}>
        {!loading && !loadingComponents && !loadingEvents && (
          <ToolBar
            privateViews={privateViews}
            publicViews={publicViews}
            setResetDefaultView={setResetDefaultView}
            setCurrentOrderViews={setCurrentOrderViews}
            setCurrentPositionId={setCurrentPositionId}
            setCurrentOrderName={setCurrentOrderName}
            setTypeChart={setTypeChart}
            orderId={orderId}
            currentOrderName={currentOrderName}
            currentOrderViews={currentOrderViews}
            currentPositionId={currentPositionId}
            typeChart={typeChart}
            setOpenAlert={setOpenAlert}
            useSelectViews={useSelectViews}
            useSelectTypes={useSelectTypes}
            useCalendar={useCalendar}
            setUseSelectViews={setUseSelectViews}
            setUseSelectTypes={setUseSelectTypes}
            setUseCalendar={setUseCalendar}
            components={components}
            events={events}
            objects={objects}
            setFilterObjects={setFilterObjects}
            setFilterComponents={setFilterComponents}
            setFilterEvents={setFilterEvents}
            openDialogSettings={openDialogSettings}
            setOpenDialogSettings={setOpenDialogSettings}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            setCurrentTemplate={setCurrentTemplate}
            currentTemplate={currentTemplate}
            setUseTemplates={setUseTemplates}
            useTemplates={useTemplates}
            setOpenDialogRestoreChart={setOpenDialogRestoreChart}
            openToolPrintPDF={openToolPrintPDF}
            setOpenToolPrintPDF={setOpenToolPrintPDF}
            currentBackGround={currentBackGround}
            setCurrentBackGround={setCurrentBackGround}
          />
        )}
        <section onClick={() => handleCloseTools()}>
          <ChartsTabs
            openDialog={openDialog}
            objects={filterObjects}
            loading={loading}
            components={filterComponents}
            loadingComponents={loadingComponents}
            events={filterEvents}
            loadingEvents={loadingEvents}
            typeChart={typeChart}
            privateViews={privateViews}
            publicViews={publicViews}
            currentOrderName={currentOrderName}
            setCurrentOrderViews={setCurrentOrderViews}
            currentPositionId={currentPositionId}
            currentOrderViews={currentOrderViews}
            currentTemplate={currentTemplate}
            setCurrentTemplate={setCurrentTemplate}
            openDialogRestoreChart={openDialogRestoreChart}
            setOpenDialogRestoreChart={setOpenDialogRestoreChart}
            orderId={orderId}
            openToolPrintPDF={openToolPrintPDF}
            setOpenToolPrintPDF={setOpenToolPrintPDF}
          />
        </section>
      </ContainerDashboard>
    </Layout>
  );
};

export default Dashboard;
