import { useEffect, useMemo, useState } from "react";
import MapDigitalTwin from "components/DigitalTwin/MapDigitalTwin";
import { setDataObjects, setShowDataObjects } from "redux/actions/admin";
import {
  setDataEvents,
  setDataComponents,
  setShowDataComponents,
  setShowEvents,
  setSettingMap,
  setOriginDataLines,
  setMessageLoadingMap,
} from "redux/actions/digitalTwin";
import { useDispatch, useStore } from "react-redux";
import useSwr from "swr";
import calculateCenter from "helpers/map/calculateCenter";
import Layout from "components/Layout/AdminDecimetrix";
// Helpers
import setDispatchFilterNewObjectsByType from "helpers/filters/setDipatchFilterObjects";
import setDispatchFilterNewEventsByType from "helpers/filters/setDispatchFilterNewEventsByType";
import setDispatchFilterNewComponentsByType from "helpers/filters/setDispatchFilterComponents";

import {
  setLabelsToObjects,
  setLabelsToComponents,
  setLabelsToEvents,
} from "./setLabels";
// Hooks
import {
  useFetchObjectLibraries,
  useFetchComponentsLibraries,
  useFetchEventLibraries,
} from "hooks/fetchLibraries";

import { config } from "config.js";
import { PRIMARY_COLOR } from "utils/const";
import LoadingDecimetrix from "components/Lodings/LoadingDecimetrix";
import { useSelector } from "react-redux";
import {
  calculateLength,
  calculateLengthFromDistance,
} from "components/DigitalTwin/Lines/LinesServices";

/**
 * DigitalTwinAdmin component.
 * Fetches and displays data related to the admin's company digital twin.
 */
const DigitalTwinAdmin = () => {
  const [currentObjects, setCurrentObjects] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [changeData, setChangeData] = useState(false);
  const [lines, setLines] = useState([]);
  // Get the admin company ID from local storage
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");

  // State variables
  const [centerLocations, setCenterLocations] = useState({});
  const dispatch = useDispatch();
  const store = useStore();

  // Fetch objects data
  const urlSettingMap = `${config.URL_BACKEND_PG}api/v1/setting-map?userId=${userId}`;
  const { data: currentSettingsMap, error: errorSettingsMap } =
    useSwr(urlSettingMap);
  const settingMap =
    currentSettingsMap && !errorSettingsMap ? currentSettingsMap : [];

  // Fetch objects data
  const url = `${config.URL_BACKEND_PG}api/v1/admin-company/objects-web/${adminCompanyId}`;
  const { data, error } = useSwr(url);
  const objects = data && !error ? data : [];

  // Fetch events data
  const urlEvents = `${config.URL_BACKEND_PG}api/v1/point-events?adminCompanyId=${adminCompanyId}`;
  const { data: allDataEvents, error: errorEvents } = useSwr(urlEvents);
  const eventsObjects = allDataEvents && !errorEvents ? allDataEvents : null;

  // Fetch component events data
  const urlComponentEvents = `${config.URL_BACKEND_PG}api/v1/point-events/components?adminCompanyId=${adminCompanyId}`;
  const { data: dataComponentEvents, error: errorComponentEvents } =
    useSwr(urlComponentEvents);
  const eventsComponents =
    dataComponentEvents && !errorComponentEvents ? dataComponentEvents : null;

  // Fetch components data
  const urlComponents = `${config.URL_BACKEND_PG}api/v1/point-components/components-web?adminCompanyId=${adminCompanyId}`;
  const { data: allDataComponents, error: errorComponents } =
    useSwr(urlComponents);
  const components =
    allDataComponents && !errorComponents ? allDataComponents : [];
  // Fetch events relation data
  const urlEventsRelation = `${config.URL_BACKEND_PG}api/v1/point-events/relation?adminCompanyId=${adminCompanyId}`;
  const { data: dataEventsRelation, error: errorEventsRelation } =
    useSwr(urlEventsRelation);
  const eventsRelation =
    dataEventsRelation && !errorEventsRelation ? dataEventsRelation : null;

  // Fetch Data Lines
  const { data: dataLines, error: errorDataLines } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`
  );
  // redux states
  const mapSettingsState = useSelector(
    (state) => state.digitalTwinReducer.mapSettings
  );

  useEffect(() => {
    const getLines = () => {
      // Validar si no hay datos o hay un error
      if (!dataLines || dataLines.length === 0 || errorDataLines) {
        setLines([]);
        return;
      }
      const { unit } = mapSettingsState?.measurementUnit ?? {};

      let auxLine = {};

      const parsedLinesDistance = dataLines.map((line) => {
        auxLine = { ...line };

        // Validar estructura del objeto antes de modificar
        if (
          Array.isArray(auxLine.feature) &&
          auxLine.feature[0]?.geometry?.coordinates &&
          auxLine.feature[0]?.properties
        ) {
          const newDistance = calculateLengthFromDistance({
            distance: calculateLength({
              coordinates: auxLine.feature[0].geometry.coordinates,
              unit,
              floatingDecimals: mapSettingsState?.floatingDecimals,
            }),
            unit: mapSettingsState?.measurementUnit?.unit,
            floatingDecimals: mapSettingsState?.floatingDecimals,
          });
          auxLine.feature[0].properties.distance = newDistance;
          return auxLine;
        }
        return line;
      });

      setLines(parsedLinesDistance);
    };
    getLines(dataLines);
  }, [
    dataLines,
    errorDataLines,
    mapSettingsState?.floatingDecimals,
    mapSettingsState?.measurementUnit,
  ]);

  // Fetch trackings data
  const { data: dataTrackings, error: errorTrackings } = useSwr(
    `${config.URL_BACKEND_MG}routes?adminCompanyId=${adminCompanyId}`
  );
  const trackings = dataTrackings && !errorTrackings ? dataTrackings : [];

  const dataAllEvents = useMemo(() => {
    if (eventsObjects && eventsComponents && eventsRelation)
      return [...eventsObjects, ...eventsComponents, ...eventsRelation];
    return [];
  }, [eventsObjects, eventsComponents, eventsRelation]);

  const {
    showEventsByType,
    showDataComponentsLibrary,
    showDataComponentsByType,
    dataEvents,
    dataComponents,
    originDataLines,
  } = store.getState().digitalTwinReducer;

  const { markerByTypeObject, dataObjects } = store.getState().adminReducer;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          dataObjects.length === 0 ||
          objects.length === 0 ||
          dataObjects.length < objects[0].length ||
          dataObjects.length > objects[0].length
        ) {
          if (objects.length === 0) {
            dispatch(setDataObjects([]));
            dispatch(setMessageLoadingMap(false));
          }
          if (data && objects && objects.length > 0 && objects[0].length > 0) {
            const center = calculateCenter(objects[0]);
            setCenterLocations({
              latitude: center[0],
              longitude: center[1],
            });
            setChangeData(!changeData);
            dispatch(setMessageLoadingMap(false));
            dispatch(setDataObjects(objects[0]));
            if (
              markerByTypeObject.length === 0
              // objects[0].length > currentObjects
            ) {
              setDispatchFilterNewObjectsByType(objects[0], dispatch);
              dispatch(
                setShowDataObjects({ state: true, color: `${PRIMARY_COLOR}` })
              );
            }
          }
        }
        if (
          dataComponents.length === 0 ||
          components.length === 0 ||
          dataComponents.length < components[0].length ||
          dataComponents.length > components[0].length
        ) {
          if (components.length === 0) {
            dispatch(setDataComponents([]));
            dispatch(setMessageLoadingMap(false));
          }
          if (components && components.length > 0 && components[0].length > 0) {
            setChangeData(!changeData);
            dispatch(setDataComponents(components[0]));
            dispatch(setMessageLoadingMap(false));
            if (showDataComponentsLibrary.length === 0) {
              dispatch(
                setShowDataComponents({
                  state: true,
                  color: `${PRIMARY_COLOR}`,
                })
              );
              if (showDataComponentsByType.length === 0) {
                setDispatchFilterNewComponentsByType(components[0], dispatch);
              }
            }
          }
        }
        if (
          dataEvents.length === 0 ||
          dataAllEvents.length === 0 ||
          Object.keys(dataEvents).length < dataAllEvents.length ||
          Object.keys(dataEvents).length > dataAllEvents.length
        ) {
          if (dataAllEvents.length === 0) {
            dispatch(setDataEvents([]));
            dispatch(setMessageLoadingMap(false));
          }
          if (dataAllEvents && dataAllEvents.length > 0) {
            // Set all data events
            setChangeData(!changeData);
            dispatch(setDataEvents(dataAllEvents));
            dispatch(setMessageLoadingMap(false));
            // Set data to filter by new event type
            if (showEventsByType.length === 0) {
              dispatch(
                setShowEvents({ state: true, color: `${PRIMARY_COLOR}` })
              );
              setDispatchFilterNewEventsByType(dataAllEvents, dispatch);
            }
          }
        }
        if (
          originDataLines.length === 0 ||
          originDataLines.length < lines.length
        ) {
          if (lines && lines.length > 0) {
            setChangeData(!changeData);
            dispatch(setOriginDataLines(lines));
            dispatch(setMessageLoadingMap(false));
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingData(
          !data ||
          !allDataEvents ||
          !dataComponentEvents ||
          !allDataComponents ||
          !dataEventsRelation ||
          !dataTrackings ||
          !dataLines
        );
      }
    };
    dispatch(setSettingMap(settingMap[0]));
    setCurrentObjects(objects[0]?.length);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    objects,
    objects[0],
    components[0],
    components,
    dataAllEvents,
    lines,
    dispatch,
    showEventsByType,
    showDataComponentsLibrary,
    showDataComponentsByType,
    markerByTypeObject,
    currentObjects,
    dataEvents,
    dataComponents,
    dataObjects,
    originDataLines,
    store.getState().digitalTwinReducer,
    store.getState().adminReducer,
    setShowDataComponents,
    setDataEvents,
    setDataObjects,
    setDataComponents,
    setOriginDataLines,
    changeData,
  ]);

  const { data: objectLibraries } = useFetchObjectLibraries({ id: null });
  const { data: componentLibraries } = useFetchComponentsLibraries({
    id: null,
  });
  const { data: eventLibraries } = useFetchEventLibraries({ id: null });

  setLabelsToObjects({ objects, objectLibraries });
  setLabelsToComponents({ components, componentLibraries });
  setLabelsToEvents({
    events: dataAllEvents ? dataAllEvents : [],
    eventLibraries: eventLibraries ? eventLibraries : [],
  });

  if (loadingData) {
    return <LoadingDecimetrix />;
  } else {
    return (
      <Layout>
        <MapDigitalTwin
          centerLocations={centerLocations}
          objects={objects?.length && objects[0]?.length ? objects[0] : []}
          objectsMap={objects?.length && objects[1]?.length ? objects[1] : []}
          trackings={trackings}
          events={dataAllEvents ? dataAllEvents : []}
          components={
            components?.length && components[0]?.length ? components[0] : []
          }
          componentsMap={
            components?.length && components[1]?.length ? components[1] : []
          }
          lines={lines}
          settingMap={settingMap[0]}
        />
      </Layout>
    );
  }
};

export default DigitalTwinAdmin;
