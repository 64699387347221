/**
 * Function that extracts the selected dynamic field types specified on params object
 *
 * default fieldsToExtract = ["number", "operation", "range"]
 *
 * @param {dataLibrary[<Object>]} dataLibrary - [obj, evt, cmp, line] array of one library definition
 * @param {fieldsToExtract[<String>]} fieldsToExtract - array of strings specifying the type of dynamic fields to retrieve
 * @returns Array of dynamic fields filtered by an array of specific types (strings)
 */

export const extractFields = ({
  dataLibrary = [],
  fieldsToExtract = ["number", "operation", "range"],
}) => {
  // generate the schema of library fields to work with filtering field types for 'number' or 'operation'
  const schema =
    dataLibrary?.[0]?.fields?.filter((fd) =>
      fieldsToExtract.includes(fd.type)
    ) || [];
  if (fieldsToExtract.includes("distance")) {
    schema.push({
      name: "distance",
      alias: "Distance",
      type: "number",
    });
  }
  // if no esquema, return empty array so execution continues
  if (!schema.length) return [];
  // retrieve names with values
  return schema.map(({ name, alias }) => ({
    name,
    alias,
  }));
};
