import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { ommitBucketUrl, urls } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";
import Layout from "components/Layout/AdminDecimetrix";
import {
  CardContainer,
  HubCard,
  HubContainer,
  HubH2,
  HubP,
  HubWrapper,
} from "./InstanceManagerStyles";
import { getUrlSigned, uploadFileToS3 } from "services/s3Manager/s3Manager";
import { useNavigate } from "react-router-dom";
import AddInstance from "images/AddInstance";
import OpenDialog from "components/Dialogs/OpenDialog";
import { NewInstanceForm } from "components/Forms/Instance/InstanceForm";
import { getPathBucket } from "helpers/getPathBucket";
import { pathFolder } from "utils/paths";
import createInstance from "services/Instances/createInstance";
import { useSWRConfig } from "swr";
import { set } from "date-fns";

export const InstanceManager = () => {
  const cardContainerRef = useRef(null);
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const { setInstanceManager, adminDecimetrixId } = useUserStore(
    (state) => state
  );
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
  });
  const [allInstances, setAllInstances] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    data: dataInstanceByAdminDecimetrixId,
    error: errorInstanceByAdminDecimetrixId,
  } = useSWR(`${urls.instance.instancesByAdminDecimetrix(adminDecimetrixId)}`);

  useEffect(() => {
    if (
      !errorInstanceByAdminDecimetrixId &&
      dataInstanceByAdminDecimetrixId?.length > 0
    ) {
      const newInstancesSigned = {
        id: "add-instance",
        name: "Add Instance",
        instanceId: "add-instance",
        description: "Add a new instance",
      };
      setAllInstances(
        dataInstanceByAdminDecimetrixId.concat(newInstancesSigned)
      );
    }
  }, [errorInstanceByAdminDecimetrixId, dataInstanceByAdminDecimetrixId]);

  const handleChangeInstance = ({ instanceId, instanceName }) => {
    setInstanceManager({ id: instanceId, name: instanceName });
    navigate("/admin-companies");
  };

  const handleMouseDown = (event) => {
    if (!cardContainerRef.current) return;
    setIsDragging(true);
    setStartX(event.pageX - cardContainerRef.current.offsetLeft);
    setScrollLeft(cardContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!cardContainerRef.current) return;
    if (!isDragging) return;
    const x = event.pageX - cardContainerRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    cardContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleCreateInstance = async () => {
    try {
      const folderPath = getPathBucket(pathFolder.instances);
      const { fileUrl } = await uploadFileToS3({
        file: formData.image,
        folderPath,
      });

      const { data } = await createInstance({
        name: formData.name,
        description: formData.description,
        adminDecimetrixId: adminDecimetrixId,
        urlImage: fileUrl,
      });

      console.log("Response data:", data);

      // Verificar si el backend devuelve un error en el cuerpo de la respuesta
      if (data.error && data.statusCode === 400) {
        setErrorMessage(data.message); // Mostrar el mensaje de error del backend
        return; // Salir de la función sin cerrar la ventana
      }

      // Si no hay errores, continuar con la lógica de éxito
      await mutate(
        `${urls.instance.instancesByAdminDecimetrix(adminDecimetrixId)}`
      );
      await mutate(`${urls.instance.all}`);
      setFormData({
        name: "",
        description: "",
        image: null,
      });
      setErrorMessage(""); // Limpiar mensaje de error si la creación es exitosa
      setOpenDialog(false); // Cerrar la ventana solo si no hay errores
    } catch (error) {
      console.error("Error creating instance:", error);

      // Manejar errores inesperados (como problemas de red)
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Ocurrió un error inesperado. Por favor, inténtalo de nuevo.");
      }
    }
  }; 

  return (
    <Layout>
      <OpenDialog
        openDialog={openDialog} 
        setOpenDialog={setOpenDialog}
        execute={async () => await handleCreateInstance()}
        toExecuteOnClose={() => setErrorMessage("")}
        content={{
          title: "Create New Instance",
          description: (
            <>
              <NewInstanceForm formData={formData} setFormData={setFormData} errorMessage={errorMessage} />

            </>
          ),
          disagree: "Cancel",
          agree: "Create",
        }}
      />
      <HubContainer className="background-2 border-top-1">
        <CardContainer
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <HubWrapper ref={cardContainerRef} className="wrap-style">
            {allInstances.map((item) => (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <HubCard
                  className="background-card-color"
                  key={item.id}
                  onClick={() => {
                    if (item.id === "add-instance") {
                      setOpenDialog(true);
                    } else {
                      handleChangeInstance({
                        instanceId: item.id,
                        instanceName: item.name,
                      });
                    }
                  }}
                >
                  <HubH2>{item.name}</HubH2>
                  <br />
                  {item.id === "add-instance" ? (
                    <AddInstance style={{ width: "200px" }} />
                  ) : (
                    <img width="200px" src={item.urlImage} />
                  )}
                  <br />

                  <HubP>{item.description}</HubP>
                </HubCard>
              </div>
            ))}
          </HubWrapper>
        </CardContainer>
      </HubContainer>
    </Layout>
  );
};
