import { useEffect, useState } from "react";
import { Button } from "./../FormStyles";
import saveEventApiPg, { createEvent } from "services/saveEventApiPg";
import { useForm } from "react-hook-form";
import findLeak from "services/findLeak";
import updateLeak from "services/updateLeak";
import { fieldFormLeak } from "utils/events";
import { Form } from "../SaveObject/DynamicFieldsStyle";
import TextInput from "../GenericInput";

export default function Leak({
  feature,
  objectId,
  closeModal,
  callbackUpdate,
  isUpdate,
  id,
  operationId,
}) {
  const [form, setForm] = useState({
    ppm: "",
    grH: "",
    comments: "",
    leak: false,
    label: "",
    ltMin: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      ppm: 0,
      grH: 0,
      comments: "",
      leak: false,
      label: "",
      ltMin: 0,
    },
  });

  const onSubmit = (data) => {
    const { name, value, checked, type } = data.target;
    if (type === "checkbox") {
      setForm({
        ...form,
        [name === "Leak" ? "leak" : name]: checked,
      });
    } else {
      setForm({
        ...form,
        [name === "Comments"
          ? "comments"
          : name === "PPM"
          ? "ppm"
          : name === "Gr/H"
          ? "grH"
          : name === "Lt/Min"
          ? "ltMin"
          : name === "Tag"
          ? "label"
          : name]: value,
      });
    }
  };

  const createBodyOfEvent = () => {
    const newForm = {};
    Object.keys(form).forEach((key) => {
      const value = form[key];
      if (value !== "") newForm[key] = value;
    });
    return newForm;
  };

  const handleMutate = () => {};

  const handleUpdate = async () => {
    const body = createBodyOfEvent();
    const { res } = await updateLeak(body, id);
    if (res?.status !== 200) return;
    handleMutate();
    callbackUpdate();
  };

  const handleSave = async () => {
    if (isUpdate) {
      handleUpdate();
      return;
    }
    const newForm = createBodyOfEvent();
    const event = createEvent(feature, newForm, objectId);
    await saveEventApiPg("leak", event);
    closeModal(false);
    handleMutate();
  };

  useEffect(() => {
    const getData = async () => {
      const data = await findLeak(id);
      setValue("PPM", data.ppm);
      setValue("Gr/H", data.grH);
      setValue("Leak", data.leak);
      setValue("Lt/Min", data.ltMin);
      setValue("Comments", data.comments);
      setValue("Tag", data.label);
      setForm((current) => ({
        ...current,
        ppm: data.ppm || "",
        grH: data.grH || "",
        comments: data.comments || "",
        leak: data.leak || false,
        label: data.label || "",
        ltMin: data.ltMin || "",
      }));
    };
    if (isUpdate) getData();
  }, [id, isUpdate]);

  return (
    <div>
      <Form onSubmit={handleSubmit(handleSave)}>
        {fieldFormLeak.map((elm) => {
          return (
            <div onChange={(e) => onSubmit(e)}>
              <TextInput
                key={elm.key}
                field={elm}
                register={register}
                errors={errors}
              />
            </div>
          );
        })}
        <Button type="submit" style={{ padding: 0 }}>
          Save
        </Button>
        {isUpdate && <Button onClick={callbackUpdate}>Cancel</Button>}
      </Form>
    </div>
  );
}
