import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { LibToolTip } from "./ObjectDetailsElements";
// Hooks
import useCountComponentsToOneObject from "hooks/useCountComponentsToOneObject";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  INFORMATION_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "utils/const";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import { useContext } from "react";
import { ThemeContext } from "App";

const getStyle = (theme) => ({
  color: theme === "light" ? document.body.style.color : WHITE_COLOR,
  background: theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
});

const QuantityComponents = ({ objectId, setShowDeleteButton }) => {
  const { theme } = useContext(ThemeContext);
  const colorStyle = getStyle(theme);

  const { amountComponents, totalAmountComponents } =
    useCountComponentsToOneObject({
      objectId,
      setShowDeleteButton,
    });

  return (
    <>
      {amountComponents?.length > 0 && (
        <div className="main-currency-table">
          <p
            className="currency-table--title"
            style={{
              color:
                theme === "light"
                  ? "var(--background-primary_color)"
                  : WHITE_COLOR,
            }}
          >
            Components
          </p>
          <div className="currency-table--container">
            <table>
              {amountComponents.map((it, index) => {
                return (
                  <tr key={it.id || index} style={colorStyle}>
                    <td className="table__bottom-left">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {it.typeName}
                        <SimpleToolTip
                          title={`Library: ${it.libName}`}
                          placement="right"
                        >
                          <LocalLibraryIcon
                            sx={{
                              fontSize: "1.8rem",
                              color: INFORMATION_COLOR,
                            }}
                          />
                        </SimpleToolTip>
                      </div>
                    </td>
                    <td className="table__bottom-right table__right">
                      {it.quantity}
                    </td>
                  </tr>
                );
              })}
              <tr style={colorStyle}>
                <td className="table__bottom-left">
                  <LibToolTip>
                    <b>Total components</b>
                  </LibToolTip>
                </td>
                <td className="table__bottom-right table__right">
                  <b>{totalAmountComponents}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default QuantityComponents;
