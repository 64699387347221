import { create } from "zustand";
/**
 * Zustand store for managing company root bucket configuration
 * @namespace useStoreBucketUser
 * @property {Object|null} bucketUser - Bucket configuration object or null
 * @property {Function} setBucketUser - Function to update the bucketUser state
 *
 * @example
 * // bucketUser object structure:
    {
        "instanceId": 7,
        "instanceName": "Instance Field 1",
        "email": "xsb10244@kisoq.com",
        "instanceNameFormat": "instance-instance-field-1",
        "bucketName": "instance-instance-field-1/xsb10244@kisoq.com"
    },
 *
 * @example
 * // Component usage:
 * const { bucketUser, setBucketUser } = useStoreBucketUser();
 *
 * // Updating state:
 * setBucketUser({
        "instanceId": 7,
        "instanceName": "Instance Field 1",
        "email": "xsb10244@kisoq.com",
        "instanceNameFormat": "instance-instance-field-1",
        "bucketName": "instance-instance-field-1/xsb10244@kisoq.com"
    });
 *
 */
export const useStoreBucketUser = create((set) => ({
  bucketUser: null,

  setBucketUser: (payload) => set(() => ({ bucketUser: payload })),
}));
