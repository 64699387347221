import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// custom style
import { WrapperTypeElementTitle } from "./AccordionStyles";
import OpenDialog from "components/Dialogs/OpenDialog";
import {
  ButtonsContainer,
  ButtonsMaksCont,
  ViewsTitle,
} from "./AccordionBookmarksViewsStyle";
// components
import DraggableList from "components/DragDrop/DraggableList";
// Services
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import findFilterDate from "services/DatesFilter/findFilterDate";
import { useDispatch } from "react-redux";
import deleteFilterDate from "services/DatesFilter/deleteFilterDate";
import { setFilterObjectsByData } from "redux/actions";
import dayjs from "dayjs";

const dateFormatter = (date, addDay = false) => {
  let formattedDate = dayjs(date);
  if (addDay) {
    formattedDate = formattedDate.add(1, 'day');
  }
  return formattedDate.format("YYYY-MM-DD");
};

const BookmarksFilterDate = ({
  setLocalStartDate,
  setLocalEndDate,
  updateDates,
  handleRestartDates,
}) => {
  const dispatch = useDispatch();
  const [publicDates, setPublicDates] = useState([]);
  const [privateDates, setPrivateDates] = useState([]);

  //Dates for the search
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = localStorage.getItem("userId");
  const rol = localStorage.getItem("role");

  //Search all the filters saved
  const handleFindDates = async () => {
    try {
      let res;

      if (rol === "Operario") {
        const data = await findFilterDate({
          adminCompanyId: adminCompanyId,
        });

        const privates = data.private.filter(
          (item) => item.userId === parseInt(userId)
        );
        res = { private: privates, public: data.public };
        setPublicDates(data.public);
        setPrivateDates(privates);
      } else {
        const data = await findFilterDate({ userId: userId });
        res = data;
        setPublicDates(data.public);
        setPrivateDates(data.private);
      }

      return res;
    } catch {
      console.error("Error saving date");
    }
  };

  const handleOnDragPublicFilter = async (result) => {
    if (!result.destination) return;
    const listItems = [...publicDates];
    const [reorderedItem] = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, reorderedItem);
    setPublicDates(listItems);
  };

  const handleOnDragPrivateFilter = async (result) => {
    if (!result.destination) return;
    const listItems = [...privateDates];
    const [reorderedItem] = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, reorderedItem);
    setPrivateDates(listItems);
  };

  //Delete filter
  const handleDeleteFilter = async (id) => {
    try {
      await deleteFilterDate(id);
      handleFindDates();
      handleRestartDates();
    } catch {
      console.error("Error deleting date");
    }
  };

  //Execute the function to search the filters
  const handleSearchDates = (iniDate, endDate) => {
    const iniDates = dateFormatter(iniDate, true);
    const endDates = dateFormatter(endDate);
    setLocalStartDate(dayjs(iniDates));
    setLocalEndDate(dayjs(endDates));
    dispatch(
      setFilterObjectsByData({
        startDate: iniDates,
        endDate: endDates,
      })
    );
  };

  useEffect(() => {
    handleFindDates();
  }, [dispatch, userId, updateDates]);

  return (
    <>
      <List
        sx={{
          height: "100%",
          position: "bottom",
        }}
        subheader={<li />}
      >
        <AccordionDetails>
          <ViewsTitle style={{ marginBottom: "15px" }}>
            Public Dates ({publicDates?.length})
          </ViewsTitle>
          {publicDates && (
            <DraggableList
              items={publicDates}
              handleOnDrag={handleOnDragPublicFilter}
              itemContent={(item) => (
                <WrapperTypeElementTitle>
                  <ButtonsContainer className="background-2 border-color-1 align">
                    <DragIndicatorIcon className="color2 drag-icon" />
                    <ButtonsMaksCont>
                      <button
                        onClick={() =>
                          handleSearchDates(item.initialDate, item.finalDate)
                        }
                        className="buttons-views color1"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <OpenInNewIcon />
                      </button>
                      <div className="icon-title">
                        <p>{item.name}</p>
                      </div>
                      {rol === "Administrador de compañía" && (
                        <button
                          onClick={() => handleDeleteFilter(item.id)}
                          className="buttons-views button-delete color1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                    </ButtonsMaksCont>
                  </ButtonsContainer>
                </WrapperTypeElementTitle>
              )}
            />
          )}
          <ViewsTitle style={{ marginBottom: "15px" }}>
            Private Dates ({privateDates?.length})
          </ViewsTitle>

          {privateDates && (
            <DraggableList
              items={privateDates}
              handleOnDrag={handleOnDragPrivateFilter}
              itemContent={(item) => (
                <WrapperTypeElementTitle>
                  <ButtonsContainer className="background-2 border-color-1 align">
                    <DragIndicatorIcon className="color2 drag-icon" />
                    <ButtonsMaksCont>
                      <button
                        onClick={() =>
                          handleSearchDates(item.initialDate, item.finalDate)
                        }
                        className="buttons-views color1"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <OpenInNewIcon />
                      </button>
                      <div className="icon-title">
                        <p>{item.name}</p>
                      </div>
                      <button
                        onClick={() => handleDeleteFilter(item.id)}
                        className="buttons-views button-delete color1"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <DeleteIcon />
                      </button>
                    </ButtonsMaksCont>
                  </ButtonsContainer>
                </WrapperTypeElementTitle>
              )}
            />
          )}
        </AccordionDetails>
      </List>
    </>
  );
};

export default BookmarksFilterDate;
