import { useEffect, useState } from "react";
import { PRIMARY_COLOR } from "utils/const";
import SelectLibrary from "./SelectLibrary";
// Hooks
import { useFetchObjectLibraries } from "hooks/fetchLibraries";
import useColumnsWithUniqueValues from "hooks/dashboard/objects/useColumnsWithUniqueValues2";
import useFindObjects from "hooks/objects/useFindObjects";
// Components
import SelectColumnLibrary from "pages/Dashboard/components/SelectColumnLibrary";
import CustomButton from "components/Buttons/CustomButton";
import SelectTypeOperation from "pages/Dashboard/components/SelectTypeOperation";
import SelectColumnsLibrary from "pages/Dashboard/components/SelectColumnsLibrary";
// Services
import { createChart } from "services/Dashboard/lineChart";
// Const
import { defaultFieldsObjects } from "utils/defaultColumns";
// Helpers
import HandlerClickColumn from "pages/Dashboard/helpers/handlerClickColumn";
import { createRowsObjects } from "pages/Dashboard/helpers/createRowsObjects";
import { useSWRConfig } from "swr";
import { addChartInAllViews } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";
import FetchTypeElements from "hooks/fetchTypeElements";
import { urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

const FormLineChart = ({ setOpenDialog }) => {
  const [columnsLibrary, setColumnsLibrary] = useState([]);
  const [cumulativeLine, setCumulativeLine] = useState({
    value: false,
    color: PRIMARY_COLOR,
  });
  const [loading, setLoading] = useState(false);
  const [librarySelectedId, setLibrarySelectedId] = useState(null);
  const [columnsTypeNumber, setColumnsTypeNumber] = useState([]);
  const [columnsSelected, setColumnsSelected] = useState([]);
  const [columnValues, setColumnValues] = useState({});
  const [objectsFormat, setObjectsFormat] = useState([]);
  const [dataInputs, setDataInputs] = useState({
    librarySelected: null,
    columnY: null,
    columnX: null,
    alias: "",
    color: null,
    typeOperation: "count",
  });
  const [columnsLibrarySelected, setColumnsLibrarySelected] = useState([]);
  const [typeElementNames, setTypeElementNames] = useState([]);
  const { instanceId } = useUserStore((state) => state);

  const updateDataInputs = (newState) => {
    setDataInputs((prevState) => ({ ...prevState, ...newState }));
  };

  const { data: libraries, error } = useFetchObjectLibraries({});

  const { data: typeElements, error: errorTypeElements } =
    FetchTypeElements(librarySelectedId);

  const { objects } = useFindObjects();
  const { mutate } = useSWRConfig();
  const setNewCreateChart = useStoreDashboard(
    (state) => state.setNewCreateChart
  );
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);
  const handlerTypeOperation = (e) => {
    updateDataInputs({ typeOperation: e });
  };

  const handlerClickColumnY = (column) => {
    updateDataInputs({ columnY: column });
  };

  const handlerClickColumnX = (column) => {
    updateDataInputs({ columnX: column });
  };

  const handlerAlias = (e) => {
    updateDataInputs({ alias: e.target.value });
  };

  const handlerColor = (e) => {
    updateDataInputs({ color: e.target.value });
  };

  const columsTypeNumber = (columns) => {
    const numberFields = columns?.filter(
      (field) =>
        field.type === "number" ||
        field.type === "operation" ||
        field.type === "range"
    );
    setColumnsTypeNumber(numberFields || []);
  };

  const handlerClickColumn = HandlerClickColumn({
    setColumnsSelected,
    columnValues,
    setColumnValues,
  });

  const { columnsWithValues, setColumnsWithValues } =
    useColumnsWithUniqueValues({
      objects: objectsFormat,
      typeIdsSelected: [],
      columnsSelected,
      handlerClickColumn,
      columnsLibrary: columnsLibrarySelected,
    });

  const handlerColumnValues = (columnName, selectedValues) => {
    setColumnValues({
      ...columnValues,
      [columnName]: selectedValues,
    });
  };

  const handlerLibrarySelected = (e) => {
    updateDataInputs({ librarySelected: e });
    const library = libraries.find((library) => library.id === e.value);
    setLibrarySelectedId(library.id);
    const objectsByLibraryId = objects.filter(
      (object) => object.typeElement.pointLibraryId === e.value
    );
    const rows = createRowsObjects({ objects: objectsByLibraryId, library });
    setObjectsFormat(rows);
    updateDataInputs({ columnY: null, columnX: null, alias: "" });
    setColumnsWithValues([]);
  };

  useEffect(() => {
    // find library by library select from libraries
    if (libraries && dataInputs.librarySelected) {
      const library = libraries.find(
        (library) => library.id === dataInputs.librarySelected.value
      );

      const columnsLibrary = library?.fields;

      setColumnsLibrarySelected(columnsLibrary);

      const columnsFiltered = columnsLibrary?.filter(
        (field) =>
          field.type !== "captureMedia" &&
          field.type !== "notification" &&
          field.type !== "number" &&
          field.type !== "operation" &&
          field.type !== "range"
      );
      if (columnsFiltered && columnsFiltered.length > 0) {
        setColumnsLibrary([...defaultFieldsObjects, ...columnsFiltered] || []);
      } else {
        setColumnsLibrary([...defaultFieldsObjects] || []);
      }
      columsTypeNumber(columnsLibrary || []);
    } else {
      setColumnsLibrary([]);
    }
  }, [libraries, dataInputs.librarySelected]);

  useEffect(() => {
    if (librarySelectedId && !errorTypeElements && typeElements?.length > 0) {
      const names = typeElements.map((elm) => {
        return elm.name;
      });
      setTypeElementNames(names);
    }
  }, [typeElements, errorTypeElements, librarySelectedId]);

  const handlerAddChart = async () => {
    const userId = localStorage.getItem("userId");
    const urlLineChart = urlsApiMg.dashboard.lineChart("objects");
    const urlOrders = urlsApiMg.dashboard.orderCards;

    const columnsValuesFilter = Object.entries(columnValues).map(
      ([name, values]) => ({
        name,
        values: values.map((item) => item.value),
      })
    );

    const chart = {
      from: "objects",
      columnX: dataInputs.columnX,
      columnsY: dataInputs.columnY ? [dataInputs.columnY] : [],
      alias: dataInputs.alias,
      libraryId: dataInputs.librarySelected.value,
      type: dataInputs.typeOperation,
      adminCompanyId: parseInt(localStorage.getItem("adminCompanyId")),
      columnsValuesFilter,
      lineStyle: {
        lablel: "Line",
        borderColor: dataInputs.color || PRIMARY_COLOR,
        backgroundColor: dataInputs.color || PRIMARY_COLOR,
      },
      trendLine: {
        label: "Cumulative Line",
        borderColor: cumulativeLine.color,
        backgroundColor: cumulativeLine.color,
        borderDash: [5, 8],
        hidden: !cumulativeLine.value,
      },
      instanceId: instanceId,
    };

    setLoading(true);
    const { data } = await createChart(chart);
    if (data) {
      mutate(urlLineChart);
      await addChartInAllViews({
        userId,
        type: "objects",
        body: {
          newChart: {
            id: data._id,
          },
        },
      });
      mutate(urlOrders);
      setNewCreateChart(!newCreateChart);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  return (
    <>
      {!error && libraries && (
        <SelectLibrary
          libraries={libraries}
          handlerLibrarySelected={handlerLibrarySelected}
          librarySelected={dataInputs.librarySelected}
        />
      )}
      {dataInputs.librarySelected && (
        <SelectTypeOperation
          handlerSelectTypeOperation={handlerTypeOperation}
        />
      )}
      {dataInputs.librarySelected &&
        columnsLibrary &&
        dataInputs.typeOperation === "sum" && (
          <SelectColumnLibrary
            title={"Select Y-Axis"}
            columnsLibrary={columnsTypeNumber}
            handlerClickColumn={handlerClickColumnY}
            columnSelected={dataInputs.columnY}
          />
        )}
      {dataInputs.librarySelected && columnsLibrary && (
        <SelectColumnLibrary
          columnsLibrary={columnsLibrary}
          handlerClickColumn={handlerClickColumnX}
          columnSelected={dataInputs.columnX}
          title={"Select X-Axis"}
        />
      )}
      {dataInputs.librarySelected && columnsLibrary && (
        <SelectColumnsLibrary
          columnsLibrary={columnsLibrary}
          handlerClickColumn={handlerClickColumn}
          columnsSelected={columnsSelected}
          columnsWithValues={columnsWithValues}
          handlerColumnValues={handlerColumnValues}
          columnValues={columnValues}
          typeElementNames={typeElementNames}
          title={"Set filters"}
        />
      )}
      <section className="container-type-charts ">
        <div>
          <h2 className="subtitle">Trend line </h2>
          <div className="checkbox-container">
            <input
              className="checkbox"
              type="checkbox"
              value={cumulativeLine.value}
              onChange={() => {
                setCumulativeLine((current) => ({
                  ...current,
                  value: !cumulativeLine.value,
                }));
              }}
            />
          </div>
        </div>
        <div>
          <h2 className="subtitle">Color</h2>
          <input
            className="alias"
            type="color"
            value={cumulativeLine.color || PRIMARY_COLOR}
            onChange={(e) => {
              setCumulativeLine((current) => ({
                ...current,
                color: e.target.value,
              }));
            }}
          />
        </div>
      </section>
      <section className="container-type-charts ">
        <div>
          <h2 className="subtitle">Alias</h2>
          <input
            className="alias"
            type="text"
            value={dataInputs.alias}
            onChange={(e) => {
              handlerAlias(e);
            }}
            required={true}
          />
        </div>
        <div>
          <h2 className="subtitle">Color</h2>
          <input
            className="alias"
            type="color"
            value={dataInputs.color || PRIMARY_COLOR}
            onChange={(e) => {
              handlerColor(e);
            }}
          />
        </div>
      </section>
      {dataInputs.alias && (
        <CustomButton
          className="btn-add-chart"
          onClick={() => handlerAddChart()}
          isLoad={loading}
          text={"Add chart"}
          margin={"15px 0"}
        />
      )}
    </>
  );
};

export default FormLineChart;
