import axios from "axios";
import { config } from "config.js";
import { urls } from "utils/urlKeys";

export const createTypeEventsTypeComponents = async (body) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_PG}api/v1/type-events-type-components`;
  const result = await axios.post(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

/**
 * Finds type events type components based on queries.
 *
 * @param {Object} queries - An object containing the queries to search for type events type components.
 * @param {string} queries.typeElementId - The ID of the type element to search for type events type components.
 * @param {string} queries.pointTypeEventId - The ID of the point type event to search for type events type components.
 * @return {Promise<Object>} Returns a promise that resolves to an object containing the type events type components found.
 */
/**
 * Finds type events type components based on queries.
 *
 * @param {Object} queries - An object containing the queries to search for type events type components.
 * @param {string} queries.typeElementId - The ID of the type element to search for type events type components.
 * @param {string} queries.pointTypeEventId - The ID of the point type event to search for type events type components.
 * @return {Promise<Object>} Returns a promise that resolves to an object containing the type events type components found.
 */
export const findTypeEventsTypeComponents = async ({ pointTypeEventId }) => {
  const token = localStorage.getItem("token");

  // Construcción de la URL base
  let queryParams = new URLSearchParams();
  if (pointTypeEventId)
    queryParams.append("pointTypeEventId", pointTypeEventId);

  const url = urls.events.typeEventsTypeComponents(queryParams.toString());

  try {
    const { data } = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching type events type components:", error);
    throw error;
  }
};

/**
 * Deletes a type event type component with the given ID.
 *
 * @param {number} id - The ID of the type event type component to be deleted.
 * @return {Promise<Object>} Returns a promise that resolves with the deleted data object.
 */
export const deleteTypeEventsTypeComponents = async (id) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_PG}api/v1/type-events-type-components/${id}`;
  const result = await axios.delete(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};
