import axios from "axios";
import { config } from "config.js";
const URL_BASE_PG = config.URL_BACKEND_PG;

/**
 * Validate a token with the API.
 * @param {string} token
 * @returns {Promise<Object>}
 */
export const validateToken = async (token) => {
  try {
    const request = await axios.get(
      `${URL_BASE_PG}api/v1/auth/validate-token?token=${token}`
    );
    const res = await request.data;
    return res;
  } catch (error) {
    console.error(error);
  }
};
