import styled, { css } from "styled-components";
import Paper from "@mui/material/Paper";
import {
  BLACK_COLOR,
  BORDER_GREY,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_BACKGROUND,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "utils/const";

export const ContentTypes = styled("section")`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${WHITE_COLOR};
  border: 1px solid #bebebe;
  padding: 21px;
  border-radius: 5px;

  .card-type-chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: auto;
    border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin: 5px;
  }

  .card-type-chart-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: auto;
    border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin: 5px;
  }

  .delete-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10%;
  }

  .label {
    font-size: 1.6rem;
    font-weight: 600;
    color: #5c5757;
    margin-bottom: 15px;
  }

  span {
    cursor: pointer;
  }
`;

export const CustomSelect = styled("div")`
  display: flex;
  flex-direction: column;

  select {
    margin-top: 19px;
    padding: 12px 10px;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: rgb(61, 61, 61);
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  label {
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    color: none;
  }
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: var(--background-primary_color);
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: ${WHITE_COLOR};
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DeleteUserButton = styled.div`
  outline: none;
  border: none;
  padding-left: 15px;
`;

const colors = {
  border: "#15bbfd",
  error: "#f67070",
  success: "#54c25d",
};

export const FormSetUp = styled.form`
  min-width: 300px;
  width: 100%;
  padding: 21px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${BORDER_GREY};
  background-color: ${WHITE_BACKGROUND};
  border: 1px solid #bebebe;
  height: 800px;
  overflow: auto;
  h1 {
    display: "flex";
    flex-direction: "row";
    /* color: ${BLACK_COLOR}; */
    text-align: center;
    font-weight: 500;
    font-size: 2rem;
    align-items: center;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    margin: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ProfileWorkIcon = styled.p``;

export const Wrapperbtn = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const AddButton = styled("label")`
  height: 45px;
  line-height: 45px;
  width: 100%;
  background: var(--background-primary_color);
  color: ${WHITE_COLOR};
  font-weight: bold;

  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 1px;

  &:hover {
    background: var(--background-secondary_color);
  }
`;

export const CustomizedAccordion = styled(Paper)`
  margin-top: 0px;
  background-color: ${WHITE_SECOND_COLOR};
  border-bottom: 1px solid #b8b2b2;
  padding: 10px;
  box-shadow: none;
`;

export const FormInput = styled.input`
  margin-left: 10px;
  width: 100%;
  border: none;
  background-color: ${WHITE_SECOND_COLOR};
  color: rgb(61, 61, 61);
  font-size: 1.4rem;
  cursor: line;
  border-bottom: 1px solid rgb(61, 61, 61);
`;

export const ProfileButton = styled.button`
  height: 45px;
  line-height: 45px;
  width: 45%;
  background: var(--background-primary_color);
  color: ${WHITE_COLOR};
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1.4rem;
  transition: 0.1s ease all;

  &:hover {
    background: var(--background-secondary_color);
  }
`;

export const ProfileFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 2%;
  background-color: ${WHITE_COLOR};
`;

export const ProfileContainer = styled.div`
  padding: 16px 30px 16px 30px;
  height: 100%;
  width: 100%;
  background-color: ${WHITE_BACKGROUND};
  border: solid 1px ${BORDER_GREY};
  border-radius: 12px;
  height: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
`;
export const StyledP = styled.p`
  font-size: 1.6rem;
  color: #5c5757;
  font-weight: 600;
`;

const profileContentStyles = ({ isActive }) => {
  return isActive
    ? css`
        border: solid 1px ${BORDER_GREY};
        color: ${BLACK_COLOR};
        box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
      `
    : css`
        //none
      `;
};

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  height: 100%;

  ${profileContentStyles}
`;

export const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  margin: 0 auto;
  padding: 5px 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Label = styled.label`
  position: relative;
  display: block;
  font-weight: 600;
  cursor: pointer;
  color: none;
  font-size: 1.6rem;
  z-index: 1;

  ${(props) =>
    props.valid === "false" &&
    css`
      color: ${colors.error};
    `}
`;

export const WrapperInput = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  position: ${({ position }) => position};
  z-index: 90;
  top: -25px;
  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .login-visibility {
    position: absolute;
    right: 0px;
    margin: 0 10px;
    color: var(--background-primary_color);
  }
  .login-visibility:hover {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  width: 100%;
  background: #f1f1f1;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  padding: 10px 40px 10px 20px;
  transition: 0.3s ease all;
  border: none;
  /* border-bottom: 1px solid #2e2e2e; */
  display: flex;
  font-size: 1.5rem;

  &.little {
    transform: scale(0.5);
    margin-top: 0;
  }

  &:focus {
    border: 3px solid ${colors.border};
    outline: none;
    /* box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4); */
  }

  ${(props) =>
    props.valid === "true" &&
    css`
      border: 3px solid transparent;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      border: 3px solid ${colors.error} !important;
    `}
`;

export const InputProfile = styled.input`
  width: 100%;
  background: ${WHITE_COLOR};
  color: grey;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  padding: 10px 40px 10px 20px;
  border: 1px solid ${BORDER_GREY};
  display: flex;
  margin: 5px 0;
  ${(props) =>
    props.valid === "true" &&
    css`
      border: 1px solid ${BORDER_GREY};
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      border: 3px solid ${colors.error} !important;
    `}

  ${(props) =>
    props.type === "range" &&
    css`
      pointer-events: visible;
      padding: 10px 0;
    `}

    ${(props) =>
    props.type === "descriptionText" &&
    css`
      height: 60px;
    `}
`;

export const LabelFile = styled.label`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 1;

  span {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
      props.valid === "false" &&
      css`
        color: #f00;
      `}
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 50%;
    height: 50%;
    border: 1px solid ${BLACK_COLOR};
    background-color: ${WHITE_COLOR};
    padding: 1rem;

    border-radius: 13px;
    ${(props) =>
      props.valid === "false" &&
      css`
        border-color: #f00;
      `}
  }
`;

export const InputFile = styled.input`
  display: none;
`;

export const LegendError = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
  color: ${colors.error};
  display: none;

  ${(props) =>
    props.valid === "true" &&
    css`
      display: none;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      display: block;
    `}
`;

export const ContainerTerms = styled.div`
  grid-column: span 2;

  input {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`;
export const ContainerButtonRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;
  width: 100%;
  left: 40px;

  @media (max-width: 800px) {
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;
export const ContainerButtonBack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;
  width: 100%;
  left: 40px;

  @media (max-width: 800px) {
    width: 100%;
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;
export const ContainerButtonBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;
  width: 95%;

  @media (max-width: 800px) {
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;

export const ContainerInputs = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-end;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 1rem;
  background-color: #fafafa;
  margin-bottom: 1rem;
`;

export const ContainerButtonCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;
  width: 95%;

  @media (max-width: 800px) {
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;

export const ContainerButtonCenterUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;

  @media (max-width: 800px) {
    grid-column: span 1;
  }

  ${(props) =>
    props.inputSetUp &&
    css`
      width: 100%;
    `}
`;

export const Button = styled.button`
  height: 45px;
  line-height: 45px;
  width: 100%;
  background: var(--background-primary_color);
  ${(props) =>
    props.type === "delete" &&
    css`
      background: #960909;
    `}
  color: ${WHITE_COLOR};
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.1s ease all;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: var(--background-secondary_color);
    ${(props) =>
      props.type === "delete" &&
      css`
        background: #d30a0a;
      `}
  }
`;

export const MessageSuccess = styled.p`
  height: 30px;
  line-height: 45px;
  padding: 0px 15px;
  border-radius: 3px;
  margin: 10px auto;
  color: ${colors.success};
`;

//Here is the error message
export const MessageError = styled.div`
  height: 30px;
  line-height: 45px;
  color: #f66060;
  padding: 0px 5px;
  border-radius: 3px;
  margin: 10px auto;
  font-size: 1.6rem;
`;

export const ContainerForm = styled.div`
  transition: 0.3s all ease-in-out;
  height: 100%;

  ${(props) =>
    !props.see &&
    css`
      height: 0;
      pointer-events: none;
      padding: 0;
      animation: upForm 1s;
    `}

  ${(props) =>
    props.see &&
    css`
      pointer-events: all;
      opacity: 1;
      animation: downForm 1s;
    `}

  @keyframes downForm {
    0% {
      height: 0%;
    }

    100% {
      height: 100%;
    }
  }

  @keyframes upForm {
    0% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }
`;
