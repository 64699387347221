import { useState, useEffect } from "react";
import useSwr from "swr";
import { urls } from "utils/urlKeys";

export const useFetchUser = ({ userId }) => {
  const [loading, setLoading] = useState(true);

  const { data, error } = useSwr(urls.users.detail(userId));

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  const user = data && !error ? data : [];

  return { user, loading };
};
