import { useState } from "react";
import { useDispatch } from "react-redux";
// Components
import CustomizedButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
// Colors
import { DELETE_COLOR, HOVER_DELETE_COLOR } from "utils/const";
import { useSWRConfig } from "swr";
import { setFeaturesDetailsDT } from "redux/actions/admin";
import DeleteIcon from "@mui/icons-material/Delete";
import { urlsApiMg } from "utils/urlKeys";

const DeleteLine = ({ lineId, deletedLine }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this Line?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const handleClick = () => setOpenDialog(true);

  const handleDeleteLine = async () => {
    deletedLine();
    setOpenDialog(true);
    dispatch(
      setFeaturesDetailsDT({
        geometry: {
          type: null,
        },
      })
    );
    // mutate(urlsApiMg.lines.all);
  };
  const shouldShowTooltip = true;
  return (
    <>
      <CustomizedButton
        text={<DeleteIcon />}
        primaryColor={DELETE_COLOR}
        secondaryColor={HOVER_DELETE_COLOR}
        onClick={handleClick}
        margin={"10px 0"}
        showTooltip={shouldShowTooltip}
        tooltipTitle="Delete"
      />
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={handleDeleteLine}
        content={contentDialog}
      />
    </>
  );
};

export default DeleteLine;
