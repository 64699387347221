//components
import { PopUpContainer } from "./LineStyles";
import OpenDialog from "components/Dialogs/OpenDialog";
import { Popup } from "react-map-gl";
import LineDetails from "components/ElementDetails/LineDetails/Index";

// icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "components/Buttons/CustomButton";
import MenuIcon from "@mui/icons-material/Menu";
// colors
import { DELETE_COLOR, HOVER_DELETE_COLOR } from "utils/const";

import { setFeaturesDetailsDT } from "redux/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { calculateLength, calculateLengthFromDistance } from "./LinesServices";
import { useEffect } from "react";
import { CloseIcon } from "components/SideBar/SidebarElements";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";

export default function PopUp({
  popUp,
  setPopUp,
  line,
  lineIdPg,
  handleUpdateLine,
  handleOpenLineDetails,
  handleDeleteLine,
  showLineDetails,
  setShowLineDetails,
  deleteConfirmation,
  setDeleteConfirmation,
  deletedLine,
}) {
  // redux selectors
  const dispatch = useDispatch();

  const mapSettings = useSelector(
    (state) => state.digitalTwinReducer.mapSettings
  );

  useEffect(() => {
    const modifyPopupStyles = () => {
      const popupContentElement = document.querySelector(
        ".mapboxgl-popup-content"
      );

      if (popupContentElement) {
        popupContentElement.style.backgroundColor = "#ffffffca";
        popupContentElement.style.width = "300px";
        popupContentElement.style.alignSelf = "center";
        popupContentElement.style.boxShadow = `0 -2px 5px  #fff`;
        popupContentElement.style.borderRadius = "0.5rem";
        popupContentElement.style.backdropFilter = "blur(4px)";
      }

      const popupTipElement = document.querySelector(".mapboxgl-popup-tip");

      if (popupTipElement) {
        popupTipElement.style.borderTopColor = "#ffffffca";
        popupTipElement.style.flex = "1";
      }
    };

    modifyPopupStyles();
  }, []);

  return (
    <Popup
      longitude={popUp?.longitude}
      latitude={popUp?.latitude}
      anchor="bottom"
      onClose={() => setPopUp({})}
      closeButton={false}
    >
      <PopUpContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3 className="title">Details Of Line</h3>
          <CloseIcon
            style={{
              fontSize: "20px",
              cursor: "pointer",
              color: "#5a5a5a",
              marginLeft: "1.5rem",
            }}
            onClick={() => setPopUp({})}
          />
        </div>
        <hr />
        <div>
          <div className="row">
            <label>
              Line id: <span className="colorblack">{lineIdPg}</span>
            </label>
          </div>
          <div className="row">
            <label>
              Line Library:{" "}
              <span className="colorblack">
                {line?.lineLibrary
                  ? line?.lineLibrary
                  : line?.lineType?.lineLibrary?.name
                    ? line?.lineType?.lineLibrary?.name
                    : "Loading..."}
              </span>
            </label>
          </div>
          <div className="row">
            <label>
              Type Line:{" "}
              <span className="colorblack">
                {line?.lineType && typeof line?.lineType === "string"
                  ? line?.lineType
                  : line?.lineType?.type
                    ? line?.lineType?.type
                    : "Loading..."}
              </span>
            </label>
          </div>
          <div className="row">
            <label>
              Line Distance:{" "}
              <span className="colorblack">
                {mapSettings !== undefined &&
                  mapSettings?.measurementUnit !== undefined &&
                  (mapSettings?.measurementUnit?.symbol === "km" ||
                    mapSettings?.measurementUnit?.symbol === "mi")
                  ? calculateLength(
                    line?.feature?.[0]?.geometry?.coordinates,
                    mapSettings?.measurementUnit?.unit
                  )
                  : calculateLengthFromDistance(
                    calculateLength(
                      line?.feature?.[0]?.geometry?.coordinates,
                      mapSettings?.measurementUnit?.unit
                    ),
                    mapSettings?.measurementUnit?.unit
                  )}{" "}
                {mapSettings?.measurementUnit?.symbol}
              </span>
            </label>
          </div>
          <div className="actions">
            <SimpleToolTip title="Edit Line" placement="bottom">
              <div className="action-item">
                <CustomButton
                  type="onClick"
                  text={<EditIcon />}
                  onClick={handleUpdateLine}
                  margin={0}
                />
              </div>
            </SimpleToolTip>
            <SimpleToolTip title="Line Details" placement="bottom">
              <div className="action-item">
                <CustomButton
                  type="onClick"
                  text={<MenuIcon />}
                  onClick={handleOpenLineDetails}
                  margin={0}
                />
              </div>
            </SimpleToolTip>
            <SimpleToolTip title="Delete Line" placement="bottom">
              <div className="action-item">
                <CustomButton
                  type="onClick"
                  text={<DeleteIcon />}
                  onClick={handleDeleteLine}
                  margin={0}
                  primaryColor={DELETE_COLOR}
                  secondaryColor={HOVER_DELETE_COLOR}
                />
              </div>
            </SimpleToolTip>
          </div>
          <OpenDialog
            openDialog={showLineDetails}
            setOpenDialog={setShowLineDetails}
            disagree={() => {
              dispatch(
                setFeaturesDetailsDT({
                  geometry: {
                    type: null,
                  },
                })
              );
            }}
            content={{
              title: "Line Details",
              description: (
                <LineDetails
                  lineId={lineIdPg}
                  deletedLine={deletedLine}
                  setShowLineDetails={setShowLineDetails}
                />
              ),
            }}
            maxWidth="90%"
            minWidth="300px"
          />
          <OpenDialog
            openDialog={deleteConfirmation}
            setOpenDialog={setDeleteConfirmation}
            execute={deletedLine}
            content={{
              title: "Delete line",
              description: "Do you want to delete selected line?",
              agree: "delete",
              disagree: "cancel",
            }}
          />
        </div>
      </PopUpContainer>
    </Popup>
  );
}
