import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HeadModal } from "./DialogsStyles";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  WHITE_COLOR,
} from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";
import { AiFillCloseCircle } from "react-icons/ai";
import { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ThemeContext } from "App";

/**
 * Generates a generic dialog component.
 *
 * @param {Object} props - The props passed to the component.
 * @param {boolean} props.openDialog - Determines if the dialog is open.
 * @param {function} props.setOpenDialog - Sets the open state of the dialog.
 * @param {function} props.execute - The function to execute.
 * @param {Object} props.content - The content of the dialog.
 * @param {Object} props.style - The style of the dialog.
 * @return {JSX.Element} The dialog component.
 */
const GenericDialog = (props) => {
  const { theme } = useContext(ThemeContext);

  const {
    openDialog,
    setOpenDialog,
    execute,
    content,
    style,
    width,
    maxWidth,
    minWidth,
  } = props;
  const open = openDialog;
  const [isLoad, setIsLoad] = useState(false);
  const theme2 = useTheme();
  const fullScreen = useMediaQuery(theme2.breakpoints.down("md"));

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleExecute = async () => {
    setIsLoad(true);
    await execute();
    setIsLoad(false);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "auto",
      }}
    >
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: maxWidth || "1900px",
              minWidth: minWidth || "1000px",
              overflowY: "hidden",
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
            },
          },
        }}
        // sx={{
        //   "& .MuiDialog-paper": style,
        //   "& .MuiDialogActions-root": {
        //     fontFamily: document.body.style.fontFamily,
        //     color: theme === "light" ? document.body.style.color : WHITE_COLOR,
        //     background:
        //       theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
        //     padding: "0px",
        //   },
        //   "& .MuiDialogContent-root": {
        //     fontFamily: document.body.style.fontFamily,

        //     background:
        //       theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
        //     color: theme === "light" ? document.body.style.color : WHITE_COLOR,
        //     padding: "0px 10px 0px 10px",
        //   },
        // }}
      >
        <HeadModal>
          <div className="title">
            <img
              src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
              alt=""
              className="neurona"
            />
            <span className="titleHeadModal">{content.title}</span>
          </div>
          <Button onClick={handleClose}>
            <AiFillCloseCircle
              style={{ color: WHITE_COLOR, fontSize: 21, marginBottom: -4 }}
            />
          </Button>
        </HeadModal>
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            fontFamily: document.body.style.fontFamily,
            color: theme === "light" ? document.body.style.color : WHITE_COLOR,
            background:
              theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
          }}
        ></DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              fontFamily: document.body.style.fontFamily,
              display: "flex",
              justifyContent: "center",
              color:
                theme === "light" ? document.body.style.color : WHITE_COLOR,
            }}
          >
            {content.description}
          </DialogContentText>
          {content.content}
        </DialogContent>
        <DialogActions>
          {content.disagree && (
            <Button
              onClick={handleClose}
              style={{
                background: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: WHITE_COLOR,
              }}
            >
              {content.disagree}
            </Button>
          )}
          {content.agree && (
            <Button
              onClick={handleExecute}
              autoFocus
              style={{
                background: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: WHITE_COLOR,
                margin: "10px 40px 10px 30px",
                width: "100%",
              }}
            >
              {isLoad ? <CircularProgress /> : content.agree}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GenericDialog;
