export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_USER_PARAMS = "SET_CURRENT_USER_PARAMS";
export const RELOAD_DETAILS = "RELOAD_DETAILS";
export const SHOW_DRAWER_DIGITAL_TWIN = "SHOW_DRAWER_DIGITAL_TWIN";
export const SHOW_DRAWER_OBJECT_DT = "SHOW_DRAWER_OBJECT_DT";
export const SHOW_CARD_MAKER_DIGITAL_TWIN = "SHOW_CARD_MAKER_DIGITAL_TWIN";
export const SET_DATA_OBJECT_DT = "SET_DATA_OBJECT_DT";
export const SET_PROFILE_IMAGE = " SET_PROFILE_IMAGE";
export const SET_DATA_OBJECT_DT_IMAGES = "SET_DATA_OBJECT_DT_IMAGES";
export const SHOW_MARKERS_DIGITAL_TWIN = "SHOW_MARKERS_DIGITAL_TWIN";
export const SET_MARKER_BY_TYPE_ELEMENT_ID = "SET_MARKER_BY_TYPE_ELEMENT_ID";
export const SET_VIEW_MODE_COLLECTION = "SET_VIEW_MODE_COLLECTION";
export const SET_SECTION_COLLECTION = "SET_SECTION_COLLECTION";
export const SET_DATE_COLLECTION = "SET_DATE_COLLECTION";
export const SHOW_MARKERS_BY_ACTIVITIES = "SHOW_MARKERS_BY_ACTIVITIES";
export const SHOW_OPERATION_DETAILS = "SHOW_OPERATION_DETAILS";
export const OPERATION_DETAILS = "OPERATION_DETAILS";
export const SET_OPERATION_CLUSTERS = "SET_OPERATION_CLUSTERS";
export const SET_OBJECT_CLUSTERS = "SET_OBJECT_CLUSTERS";
export const SET_COMPONENT_CLUSTERS = "SET_COMPONENT_CLUSTERS";
export const SET_DATA_EVENT_POLYGON = "SET_DATA_EVENT_POLYGON";
export const SET_RADIUS_ELEMENTS_NEAR = "SET_RADIUS_ELEMENTS_NEAR";

// types of admin
export const SHOW_DRAWER_USER_ROUTES = "SHOW_DRAWER_USER_ROUTES";
export const TYPE_JOB = "TYPE_JOB";
export const SHOW_DRAWER_DIGITAL_TWIN_ADMIN = "SHOW_DRAWER_DIGITAL_TWIN_ADMIN";
export const SET_LOCATIONSID_IN_POLYGON = "SET_LOCATIONSID_IN_POLYGON";
export const SET_GEOMETRY_POLYGON = "SET_GEOMETRY_POLYGON";
// admin add user jobs
export const SET_COORDINATES_USER_JOB = "SET_COORDINATES_USER_JOB";
export const SET_FEATURES_DETAILS_DT = "SET_FEATURES_DETAILS_DT";
export const SET_FEATURES_DETAILS = "SET_FEATURES_DETAILS";
// Admin - Set data objects
export const SET_DATA_OBJECTS = "SET_DATA_OBJECTS";
// Digital twin admin
export const SHOW_MARKER_BY_TYPE_OBJECT_ID = "SHOW_MARKER_BY_TYPE_OBJECT_ID";
export const SHOW_MARKER_BY_TYPE_ELEMENT_ID = "SHOW_MARKER_BY_TYPE_ELEMENT_ID";
export const SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD =
  "SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD";
export const SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD =
  "SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD";
export const SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON =
  "SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON";
export const SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON =
  "SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON";
export const SHOW_MARKER_BY_ELEMENT_WITH_MEDIA =
  "SHOW_MARKER_BY_ELEMENT_WITH_MEDIA";
export const SET_OBJECTS_DIGITAL_TWIN = "SET_OBJECTS_DIGITAL_TWIN";
export const SET_FILTERED_OBJECTS_DIGITAL_TWIN =
  "SET_FILTERED_OBJECTS_DIGITAL_TWIN";
export const SET_OPERATIONS = "SET_OPERATIONS";
export const SET_FILTERED_OPERATIONS = "SET_FILTERED_OPERATIONS";
export const SET_DRAW_ANY_THING = "SET_DRAW_ANY_THING";
export const SET_DRAWER_CONTROL = "SET_DRAWER_CONTROL";
export const SET_UPDATE_REGION = "SET_UPDATE_REGION";
export const SET_MAP = "SET_MAP";
export const SET_OBJECTS_UNDER_DRAWN_POINT = "SET_OBJECTS_UNDER_DRAWN_POINT";
export const SHOW_EVENTS_BY_INSPECTION_TYPE = "SHOW_EVENTS_BY_INSPECTION_TYPE";
export const SET_DATA_EVENTS = "SET_DATA_EVENTS";
export const SHOW_LABELS = "SHOW_LABELS";
export const SET_SHOW_LINES = "SET_SHOW_LINES";
export const SET_SHOW_TRACKINGS = "SET_SHOW_TRACKINGS";
export const SET_NEURON_SELECT_TOOL = "SET_NEURON_SELECT_TOOL";
// Admin - Set data objects in Polygon
export const SET_ORIGINAL_DATA_OBJECTS_POLYGON =
  "SET_ORIGINAL_DATA_OBJECTS_POLYGON";
export const SET_DATA_OBJECTS_POLYGON = "SET_DATA_OBJECTS_POLYGON";
export const SET_FEATURES_POLYGON = "SET_FEATURES_POLYGON";
export const SET_DATA_REGION = "SET_DATA_REGION";
export const SHOW_REGION_DETAILS_DRAWER = "SHOW_REGION_DETAILS_DRAWER";
export const SHOW_LINE_DETAILS_DRAWER = "SHOW_LINE_DETAILS_DRAWER";
export const SET_TABLE_DETAILS_FORWARD = "SEND_TABLE_DETAILS_FORWARD";
// filters
export const SET_FILTER_USERS = "SET_FILTER_USERS";
export const SET_SHOW_FILTER_USERS = "SET_SHOW_FILTER_USERS";
export const SET_SHOW_FILTER_ALL_USERS = "SET_SHOW_FILTER_ALL_USERS";
export const SET_FILTER_OBJECTS_BY_DATA = "SET_FILTER_OBJECTS_BY_DATA";
export const SET_GEOMETRY_LINE = "SET_GEOMETRY_LINE";
export const SET_UPDATE_GEOMETRY_LINE = "SET_UPDATE_GEOMETRY_LINE";
export const SET_GEOMETRY_LINE_CONFIG = "SET_GEOMETRY_LINE_CONFIG";
export const SHOW_OPERATIONS_BY_TYPE = "SHOW_OPERATION_BY_TYPE";
export const SET_FLY_TO = "SET_FLY_TO";
export const STATE_FILTER_BUTTON_TABLE = "STATE_FILTER_BUTTON_TABLE";
export const SET_OBJECT_DELETE_LINE_DRAWS = "SET_OBJECT_DELETE_LINE_DRAWS";
export const SET_DRAWLINE_MODE = "SET_DRAWLINE_MODE";

export const DYNAMIC_ROW_NUM = "DYNAMIC_ROW_NUM";
export const GET_TABLE_ROWS_FILTER = "GET_TABLE_ROWS_FILTER";
export const GET_TABLE_ROWS_FULL = "GET_TABLE_ROWS_FULL";
export const OPEN_SELECT_COLUMNS = "OPEN_SELECT_COLUMNS";
export const OPEN_SELECT_ORDER_VIEW_COLUMNS = "OPEN_SELECT_ORDER_VIEW_COLUMNS";
export const SET_ORIGINAL_ORDER_VIEW_COLUMNS =
  "SET_ORIGINAL_ORDER_VIEW_COLUMNS";
export const CURRENT_TABLE_VIEW = "CURRENT_TABLE_VIEW";
export const DOWNLOAD_OPTION = "DOWNLOAD_OPTION";
export const DATA_NEW_TABLE_WINDOW = "DATA_NEW_TABLE_WINDOW";
export const OBJECTS_FILTER_TABLE = "OBJECTS_FILTER_TABLE";
export const SET_DATA_EVENTS_FILTER_TABLE = "SET_DATA_EVENTS_FILTER_TABLE";
export const STATE_OBJECT_FILTER_TABLE = "STATE_OBJECT_FILTER_TABLE";
export const STATE_EVENT_FILTER_TABLE = "STATE_EVENT_FILTER_TABLE";

export const SET_DATA_COMPONENTS = "SET_DATA_COMPONENTS";
export const SHOW_DATA_COMPONENTS_LIBRARY = "SHOW_DATA_COMPONENTS_LIBRARY";
export const SHOW_DATA_COMPONENTS_BY_TYPE = "SHOW_DATA_COMPONENTS_BY_TYPE";
export const SHOW_DATA_COMPONENTS = "SHOW_DATA_COMPONENTS";
export const STATE_COMPONENT_FILTER_TABLE = "STATE_COMPONENT_FILTER_TABLE";
export const SET_FILTER_COMPONENT = "SET_FILTER_COMPONENT";
export const SET_DATA_COMPONENTS_POLYGON = "SET_DATA_COMPONENTS_POLYGON";
export const COMPONENTS_FILTER_TABLE = "COMPONENTS_FILTER_TABLE";

export const SHOW_DATA_OBJECTS = "SHOW_DATA_OBJECTS";
export const SET_FILTER_OBJECT = "SET_FILTER_OBJECT";

export const SET_FILTER_EVENTS = "SET_FILTER_EVENTS";
export const SHOW_EVENTS = "SHOW_EVENTS";
export const SHOW_EVENTS_BY_LIBRARY = "SHOW_EVENTS_BY_LIBRARY";
export const SHOW_EVENTS_BY_TYPE = "SHOW_EVENTS_BY_TYPE";

export const SHOW_COMPONENT_LABELS = "SHOW_COMPONENT_LABELS";
export const SHOW_EVENT_LABEL = "SHOW_EVENT_LABEL";
export const LOAD_DATA_POINTS = "LOAD_DATA_POINTS";
export const RELATION_POINT_LINES = "RELATION_POINT_LINES";
export const DATA_LINES = "DATA_LINES";
export const CANCEL_DRAWING = "CANCEL_DRAWING";

export const OBJECTS_LINES_IDS = "OBJECTS_LINES_IDS";
export const CURRENT_UPDATE_LINE_ID = "CURRENT_UPDATE_LINE_ID";
export const SHOW_LINE_POPUP = "SHOW_LINE_POPUP";
export const RESET_STATES = "RESET_STATES";
export const SETTING_MAP = "SETTING_MAP";

export const TMP_DISTANCE_FEATURES = "TMP_DISTANCE_FEATURES";

export const SET_OBJECT_RELATION_OBJECT = "SET_OBJECT_RELATION_OBJECT";

export const MODAL_RELATION_OBJECT = "MODAL_RELATION_OBJECT";

export const POPUP_RELATION_OBJECT = "POPUP_RELATION_OBJECT";

export const INFO_RELATION_OBJECT = "INFO_RELATION_OBJECT"; //Activate the dialog to show the info of the object

export const DATA_RELATION_OBJECT = "DATA_RELATION_OBJECT"; //Save the data of the object to relate

export const DATABASE_RELATION_OBJECT = "DATABASE_RELATION_OBJECT"; //Save the data of the object to relate

export const SEE_OBJECT_CHILD_RELATION = "SEE_OBJECT_CHILD_RELATION"; //See the data of the object to relate

export const INFO_FILTER_DATE = "INFO_FILTER_DATE";

export const M_ZOOM_VIEW = "M_ZOOM_VIEW";

export const MAP_SETTINGS = "MAP_SETTINGS";

export const LINES_FILTERS = "LINES_FILTERS"; //State for lines filter DT & Lines.jsx

export const SHOW_FILTER_LINES_IN_MAP = "SHOW_FILTER_LINES_IN_MAP";
