import { useEffect, useMemo, useState } from "react";
// Style
import { GeneralButton } from "components/ElementDetails/listEventTableStyle.jsx";
// Hooks
import useSwr from "swr";
import { urls } from "utils/urlKeys";

function useCreateComponentObjects({ objectId }) {
  const [rows, setRows] = useState([]);
  const [markedComponent, setMarkedComponent] = useState("");
  const seePointComponentTable = useMemo(() => rows.length > 0, [rows]);
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const urlDynamicComponent = urls.components.object(objectId);
  const { data: dataDynamic, error: errorDataDynamics } =
    useSwr(urlDynamicComponent);

  const dataDynamicComponent = useMemo(() => {
    return dataDynamic && !errorDataDynamics
      ? dataDynamic?.filter(
          (it) => it.adminCompanyId === parseInt(adminCompanyId)
        )
      : [];
  }, [dataDynamic, errorDataDynamics, adminCompanyId]);

  const handleMark = (id) => {
    setMarkedComponent(id);
  };

  useEffect(() => {
    if (markedComponent !== "") {
      const timer = setTimeout(() => {
        setMarkedComponent("");
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [markedComponent]);

  useEffect(() => {
    if (dataDynamicComponent) {
      const rows = dataDynamicComponent.map((item) => {
        return {
          id: item.id,
          name: item.pointTypeComponent.name,
          date: item.date,
          longitude: item.locationComponent.longitude,
          latitude: item.locationComponent.latitude,
          createdAt: item.createdAt,
          markPoint: (
            <GeneralButton onClick={() => handleMark(item.id)}>
              Flash
            </GeneralButton>
          ),
        };
      });
      setRows(rows);
    }
  }, [dataDynamicComponent, objectId]);

  return {
    componentRows: rows,
    dataDynamicComponent,
    seePointComponentTable,
    markedComponent,
    setMarkedComponent,
  };
}

export default useCreateComponentObjects;
