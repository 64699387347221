import { useState, useEffect, useCallback } from "react";
// Hooks
import useFindObjects from "hooks/objects/useFindObjects";
// Helpers
import {
  countByValues,
  sumValues,
} from "pages/Dashboard/helpers/componentsDonutChart";
import { createRowsComponents } from "pages/Dashboard/helpers/createRowsComponents";
// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";

const useFindDoughnutCharts = ({ openDialog, components, loading }) => {
  const [charts, setCharts] = useState(null);
  const [donutCharts, setDonutCharts] = useState(null);
  const [componentsFormat, setComponentsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataDonutChart, error: errorDataDonutChart } = useSwr(
    urlsApiMg.dashboard.doughnutChart("components")
  );

  const { objects } = useFindObjects();

  useEffect(() => {
    if (!errorDataDonutChart && dataDonutChart) setCharts(dataDonutChart);
  }, [dataDonutChart, errorDataDonutChart, openDialog, reloadCharts]);

  useEffect(() => {
    const componentsFormat = createRowsComponents({ components, objects });
    setComponentsFormat(componentsFormat);
  }, [components, objects]);

  const getDataDonutChart = useCallback(async () => {
    if (componentsFormat && !loading) {
      const countCharts = countByValues({
        charts,
        components: componentsFormat,
      });
      const sumCharts = sumValues({
        charts,
        components: componentsFormat,
      });

      setDonutCharts([...countCharts, ...sumCharts]);
    }
  }, [loading, componentsFormat, charts]);

  useEffect(() => {
    if (charts && components) {
      getDataDonutChart();
    }
  }, [charts, components, getDataDonutChart]);

  return { doughnutCharts: donutCharts };
};

export default useFindDoughnutCharts;
