import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ManageUserButtons, TableConteinerStyle } from "./TableStyle";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import Profile from "components/Profile/Profile";
import OperationDialog from "components/Dialogs/OperationDialog";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { config } from "config.js";
import { UseHandleManageUser } from "../GenericTable/ManageUsers/UseHandleManageUser";
import {
  setOperationDetails,
  setShowOperationDetails,
} from "redux/actions/index.jsx";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Loading from "components/Lodings/LoadingV2";
// Services
import {
  userVerification,
  updateUserVerification,
} from "services/users/userVerification";
import {
  BACKGROUND_GREY,
  BACKGROUND_THIRD_DARK,
  BLACK_COLOR,
  BORDER_GREY,
  SECOND_COLOR,
  WHITE_BACKGROUND,
} from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import { SOURCE_ID } from "utils/constStrings";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "secondName",
    numeric: false,
    disablePadding: false,
    label: "Second Name",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "E-mail",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "area",
    numeric: false,
    disablePadding: false,
    label: "Area",
  },
  {
    id: "project",
    numeric: false,
    disablePadding: false,
    label: "Project",
  },
  {
    id: "confirmationToken",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
  {
    id: "noSessions",
    numeric: true,
    disablePadding: false,
    label: "No Sessions Web",
  },
  {
    id: "userLogOut",
    numeric: true,
    disablePadding: false,
    label: "User Log Out Web",
  },
  {
    id: "noSessionsMobile",
    numeric: true,
    disablePadding: false,
    label: "No Sessions Mobile",
  },
  {
    id: "userLogOutMobile",
    numeric: true,
    disablePadding: false,
    label: "User Log Out Mobile",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created At",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

/**
 * TODO:
 *  1. Sacar las peticiones de fetch y axios a la carpeta de services
 */
export default function EnhancedTable({ handleClose }) {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [idUserDelete, setIdUserDelete] = useState(null);
  const [contentDialog, setContentDialog] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [valuesNoSessions, setValuesNoSessions] = useState({});
  const [valuesNoSessionsMobile, setValuesNoSessionsMobile] = useState({});
  const { theme } = useContext(ThemeContext);

  const {
    handlers: { handlerUserLogout, userLogout, handlerUserLogoutMobile },
    states: {
      setOpenUserLogout,
      openUserLogout,
      openUserLogoutMobile,
      setOpenUserLogoutMobile,
    },
  } = UseHandleManageUser({ setRows, rows, setValuesNoSessions });

  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${config.URL_BACKEND_PG}api/v1/admin-decimetrix/admins/${adminDecimetrixId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const res = await result.data;
      const admins = await createRows(res.adminCompany);
      setRows(admins);
    };
    fetchData();

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, [handleClose, adminDecimetrixId, token]);

  const createRows = async (admins) => {
    return await Promise.all(
      admins.map(async (admin) => {
        const res = await userVerification(admin.user.email);
        const id = admin.userId;
        setValuesNoSessions((prevValues) => ({
          ...prevValues,
          [id]: res.noSessions,
        }));
        setValuesNoSessionsMobile((prevValues) => ({
          ...prevValues,
          [id]: res.noSessionsMobile,
        }));

        return {
          id,
          firstName: admin.firstName,
          lastName: admin.firstLastName,
          secondName: admin.secondName,
          email: admin.user.email,
          company: admin.company.name,
          role: admin.role?.role,
          area: admin.area,
          project: admin.project,
          createdAt: admin.createdAt.substring(0, 10),
          canDelete: admin.operators.length === 0,
          confirmationToken: admin.user.userVerification.confirmationToken,
          noSessions: res.noSessions,
          noSessionsMobile: res.noSessionsMobile,
        };
      })
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleDelete = async (user) => {
    setIdUserDelete(user.id);
    setContentDialog({
      title: "",
      description: `Are you sure you want to delete ${user.firstName}?`,
      disagree: "Cancel",
      agree: "Accept",
    });
    setOpenDialog(true);
  };

  const deleteUser = async () => {
    await axios.delete(
      `${config.URL_BACKEND_PG}api/v1/admin-company/${idUserDelete}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const rowsHelp = rows.filter((row) => row.id !== idUserDelete);
    setRows(rowsHelp);
    setContentDialog({});
    setIdUserDelete(null);
    setOpenDialog(false);
  };

  const handleClickUser = async (userId) => {
    const user = {
      content: <Profile userId={userId} />,
      title: "User Details",
    };
    dispatch(setOperationDetails(user));
    dispatch(setShowOperationDetails(true));
  };

  const handlerNoSessions = async (e, row) => {
    const noSessions = e.target.value;
    const id = row.id;
    setValuesNoSessions((prevValues) => ({
      ...prevValues,
      [id]: noSessions,
    }));
    if (noSessions !== "" && parseInt(noSessions) > 0) {
      await updateUserVerification({
        userId: id,
        changes: {
          noSessions: parseInt(noSessions),
        },
      });
    }
  };

  const handlerNoSessionsMobile = async (e, row) => {
    const noSessionsMobile = e.target.value;
    const id = row.id;
    setValuesNoSessionsMobile((prevValues) => ({
      ...prevValues,
      [id]: noSessionsMobile,
    }));
    if (noSessionsMobile !== "" && parseInt(noSessionsMobile) > 0) {
      await updateUserVerification({
        userId: id,
        changes: {
          noSessionsMobile: parseInt(noSessionsMobile),
        },
      });
    }
  };

  const styleColor =
    theme === "light" ? BACKGROUND_THIRD_DARK : WHITE_BACKGROUND;

  return (
    <TableConteinerStyle>
      <Box sx={{ width: "100%" }}>
        {
          rows.length > 0 ? (
            <>
              <OpenDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                execute={deleteUser}
                content={contentDialog}
              />
              <OpenDialog
                openDialog={openUserLogout}
                setOpenDialog={setOpenUserLogout}
                execute={() => userLogout({ source: SOURCE_ID.desktop })} // Flag source: 1 - TO CLOSE SESSION WEB
                content={{
                  title: "User Log Out Web",
                  description:
                    "Do you want to close all sessions web for this user?",
                  disagree: "Cancel",
                  agree: "Accept",
                }}
                disagree={() => {}}
              />
              <OpenDialog
                openDialog={openUserLogoutMobile}
                setOpenDialog={setOpenUserLogoutMobile}
                execute={() => userLogout({ source: SOURCE_ID.mobile })} // Flag source: 2 - TO CLOSE SESSION MOBILE
                content={{
                  title: "User Log Out Mobile",
                  description:
                    "Do you want to close all sessions Mobile for this user?",
                  disagree: "Cancel",
                  agree: "Accept",
                }}
                disagree={() => {}}
              />
              <OperationDialog
                style={{
                  margin: 0,
                  padding: 0,
                  width: "70%",
                  height: "70%",
                  maxWidth: "none",
                }}
              />
              <Paper
                sx={{
                  width: "100%",
                  mb: 2,
                  border: `solid 1px ${BORDER_GREY}`,
                  borderRadius: "10px",
                  boxShadow: "none",
                }}
              >
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                  <Table
                    className="background-table-color"
                    sx={{
                      borderRadius: "10px",
                      minWidth: 750,
                      "& th": {
                        fontWeight: "bold",
                        color: document.body.style.color,
                        fontFamily: document.body.style.fontFamily,
                      },
                    }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody className="background-row-1">
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.name);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          const prop = row.canDelete
                            ? { onClick: () => handleDelete(row) }
                            : { disable: "true" };
                          return (
                            <TableRow
                              hover
                              // onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              sx={{
                                "& .css-17iuw0d-MuiTableCell-root": {
                                  color: document.body.style.color,
                                  fontFamily: document.body.style.fontFamily,
                                },
                              }}
                            >
                              <TableCell id={labelId} align="left">
                                <p
                                  className="color1 font-color"
                                  sx={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: document.body.style.color,
                                    fontFamily: document.body.style.fontFamily,
                                  }}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClickUser(row.id)}
                                >
                                  {row.firstName}
                                </p>
                                <OpenInNewIcon
                                  onClick={() => handleClickUser(row.id)}
                                  sx={{
                                    ml: 0.5,
                                    mb: 0.5,
                                    verticalAlign: "inherit",
                                    cursor: "pointer",
                                    color: `${SECOND_COLOR}`,
                                  }}
                                ></OpenInNewIcon>
                              </TableCell>

                              <TableCell align="left">
                                <p className="font-color">{row.secondName}</p>
                              </TableCell>
                              <TableCell align="left">
                                <p className="font-color">{row.lastName}</p>
                              </TableCell>
                              <TableCell align="left">
                                <p className="font-color">{row.email}</p>
                              </TableCell>
                              <TableCell align="left">
                                <p className="font-color">{row.company}</p>
                              </TableCell>
                              <TableCell align="left">
                                <p className="font-color">{row.role}</p>
                              </TableCell>
                              <TableCell align="left">
                                <p className="font-color">{row.area}</p>
                              </TableCell>
                              <TableCell align="left">
                                <p className="font-color">{row.project}</p>
                              </TableCell>
                              <TableCell align="left">
                                <p className="font-color">
                                  {row.confirmationToken === null
                                    ? "E-mail Verificado"
                                    : "E-Mail Pendiente de Verificacion"}
                                </p>
                              </TableCell>

                              <TableCell align="left">
                                <ManageUserButtons {...prop}>
                                  <DeleteIcon
                                    sx={{
                                      fontSize: "2rem",
                                      color: prop.disable
                                        ? "	#808080"
                                        : "#ff5151",
                                      cursor: prop.disable
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                  />
                                </ManageUserButtons>
                              </TableCell>
                              <TableCell align="center">
                                <input
                                  style={{
                                    width: "45px",
                                    padding: "0 5px",
                                    border: "none",
                                    background: `${BACKGROUND_GREY}`,
                                    color: `${BLACK_COLOR}`,
                                  }}
                                  type="number"
                                  value={valuesNoSessions[row.id]}
                                  min={1}
                                  onChange={(e) => handlerNoSessions(e, row)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <LogoutIcon
                                  className="color1"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handlerUserLogout(row.id)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <input
                                  style={{
                                    width: "45px",
                                    padding: "0 5px",
                                    border: "none",
                                    background: `${BACKGROUND_GREY}`,
                                    color: `${BLACK_COLOR}`,
                                  }}
                                  type="number"
                                  value={valuesNoSessionsMobile[row.id]}
                                  min={1}
                                  onChange={(e) =>
                                    handlerNoSessionsMobile(e, row)
                                  }
                                />
                              </TableCell>

                              <TableCell align="center">
                                <LogoutIcon
                                  className="color1"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handlerUserLogoutMobile(row.id)
                                  }
                                />
                              </TableCell>
                              <TableCell align="left">
                                <p className="font-color">{row.createdAt}</p>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="color1 background-2 border-radius-1 font-color"
                  style={{
                    borderRadius: "0px 0px 10px 10px",
                    fontFamily: document.body.style.fontFamily,
                  }}
                  sx={{
                    "& .css-15u00r0-MuiButtonBase-root-MuiIconButton-root.Mui-disabled, & .css-16fnxu0-MuiSvgIcon-root-MuiSelect-icon, & .css-fc10xi-MuiSvgIcon-root-MuiSelect-icon":
                      {
                        color: styleColor,
                      },
                    "& .css-14hwa88-MuiTablePagination-selectLabel, & .css-129onsn-MuiTablePagination-displayedRows":
                      {
                        fontFamily: document.body.style.fontFamily,
                        color: document.body.style.color,
                      },
                  }}
                  rowsPerPageOptions={[8, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </>
          ) : isLoading && isLoading ? (
            <Loading />
          ) : (
            <h1>No Administrators</h1>
          ) //funciona
        }
      </Box>
    </TableConteinerStyle>
  );
}
