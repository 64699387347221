import { WHITE_COLOR } from "utils/const";
import { ContainerInput } from "./style";
import { useContext } from "react";
import { ThemeContext } from "App";
import OperationInfo from "components/ElementDetails/OperationInfo";

function TextInput({ field, register = () => {}, value, errors = {}, from }) {
  const { theme } = useContext(ThemeContext);

  const paramsRegister = () => {
    let params = {};
    if (field.type !== "operation" && field.required)
      params.required = "this is required";
    // regular expression to strigs
    if (field.type === "string")
      params.pattern = {
        value: "",
        message: "carácteres inválidos",
      };

    if (field.type === "range") {
      params.min = {
        value: field.min,
        message: `numero mayor o igual a ${field.min}`,
      };
      params.max = {
        value: field.max,
        message: `numero menor o igual a ${field.max}`,
      };
    }
    // regular expression to email
    if (field.type === "email")
      params.pattern = {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "debe ser un email",
      };

    // regular expression to email
    if (field.type === "url") {
      params.pattern = {
        value:
          /^((http(s)?:\/\/)?(www\.)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}((\/[a-zA-Z0-9\-_\.]+)*\/?)(\?[a-zA-Z0-9_]+\=[a-zA-Z0-9_]+)?)$/,
        message: "debe ser una url",
      };
    }

    // regular expression to email
    if (field.type === "coords") {
      params.pattern = {
        value: /^-?\d+(\.\d+)$/,
        message: "debe ser una coordenada",
      };
    }

    if (field.type === "operation") {
      params.operation = {
        value: field.operation,
        message: "debe ser una expresion válida",
      };
    }

    if (field.type === "string" && field.regularExpression) {
      params.pattern = {
        value: field.regularExpression,
        message:
          "The name will be used in the Database. No spaces nor special characters are allowed. If want to see a name with special characters or spaces please use the Display Name field. (Required)",
      };
    }

    return params;
  };

  // If field.type === file
  let extensionsAccept = null;
  if (field.type === "file") {
    const extensions = field?.possible?.map((ext) => "." + ext + ",");
    extensionsAccept = extensions?.reduce(
      (current, prev) => current + " " + prev,
      ""
    );
  }

  if (field.type === "range") {
    if (field.step === "par") {
      field.step = "2";
      if (field.min % 2 === 1) {
        field.min = field.min - 1;
      }
    }
    if (field.step === "impar") {
      field.step = "2";
      if (field.min % 2 === 0) {
        field.min = field.min + 1;
      }
    }
  }
  return (
    <ContainerInput
      display={
        field.name === "Association" || field.name === "Synchronized"
          ? "none"
          : "flex"
      }
    >
      <div
        className="label"
        style={{
          color: theme === "light" ? document.body.style.color : WHITE_COLOR,
        }}
        htmlFor={field.name}
      >
        {field.alias ? field.alias : field.name}
        {field.required ? <div className="required">*</div> : null}
        {field.type === "operation" && <OperationInfo content={field} />}
        {field.globalSelect && (
          <OperationInfo content={field} autoComplete={true} />
        )}
        {field.warning && <OperationInfo warning={field.warning} />}
      </div>

      {field.type === "operation" ? (
        <div className="divformathfield">
          <math-field
            read-only
            className="math-field"
            id={field.name}
            style={{ display: "inline-block" }}
            value={field.operation}
          />
        </div>
      ) : field.type === "file" && field.globalSelect ? (
        <>
          <input
            className="input"
            id={field.name}
            type="text"
            disabled={true}
            {...register(field.name, {
              ...paramsRegister(),
            })}
          ></input>
        </>
      ) : (
        <>
          <input
            className="input"
            id={field.name}
            type={
              field.type === "range"
                ? "range"
                : field.type === "currency" || field.type === "operation"
                ? "number"
                : field.type === "check" || field.type === "boolean"
                ? "checkbox"
                : field.type
            }
            accept={extensionsAccept}
            {...register(field.name, {
              ...paramsRegister(),
            })}
            disabled={
              field.type === "operation" || field.noTouch || field.globalSelect
            }
            placeholder={field.type === "operation" ? `${field.operation}` : ""}
            step={field.type === "number" ? "any" : field.step}
            min={field.type === "range" ? field.min : ""}
            max={field.type === "range" ? field.max : ""}
            defaultValue={
              field.type === "range"
                ? field.min + field.step
                : field.type === "number" || field.type === "currency"
                ? 0
                : field.type === "file"
                ? ""
                : ""
            }
          />
          {field.type === "range" && (
            <div className="text-sm text-gray-600">
              Value:{" "}
              {value || value === 0 ? value : field.min + Number(field.step)}
            </div>
          )}
        </>
      )}
      {errors[field.name]?.message && (
        <p className="message">{errors[field.name]?.message}</p>
      )}
    </ContainerInput>
  );
}
export default TextInput;
