import styled from "styled-components";

export const OperationsTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  .class-title {
    width: 100%;
  }
  .class-operation-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .class-operation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
