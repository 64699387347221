import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { useSWRConfig } from "swr";
// styles
import { Form, StepSection } from "./EditFilterTableStyle";
// Hooks
import { useForm } from "react-hook-form";
import {
  useFetchObjectLibraries,
  useFetchComponentsLibraries,
  useFetchEventLibraries,
  useFetchObjectLineLibraries,
} from "hooks/fetchLibraries";
// Components
import TextInput from "components/Forms/GenericInput";
import SelectInput from "components/Forms/SelectInput";
import CustomButton from "components/Buttons/CustomButton";
// Services
import {
  updateAttributesObjectMg,
  updateAttributesComponentMg,
  updateAttributesEventMg,
  updateAttributesObjectLineMg,
} from "services/updateAttributesMg";
import updateObjectPg from "services/updateObjectPg";
import { setUpdateAtEvent } from "services/savePointEvent";
import { setUpdateAtComponent } from "services/savePointComponent";
import { updateLinePg } from "services/lines/updateLine";
// Constants
import { urlKeys, urls, urlsApiMg } from "utils/urlKeys";
import { config } from "config.js";
// Helpers
import { createAttributes, waitSeconds } from "./helpers";
import updatePointEvent from "services/updatePointEvent";
import updatePointComponent from "services/updatePointComponent";

// Magic strings
const TYPE_ELEMENT = {
  OBJECT: "objects",
  COMPONENT: "components",
  EVENT: "events",
};

const EditFilterTable = ({
  typeOfTable,
  pointLibraryId,
  setOpenEditFilterData,
  isLine,
}) => {
  const allFilterTableRows = useSelector(
    (state) => state.digitalTwinReducer.filterTableRows
  );

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [globalTableCategories, setGlobalTableCategories] = useState([]);
  const [newPointTypeEventId, setNewPointTypeEventId] = useState(null);
  const [newPointTypeComponentId, setNewPointTypeComponentId] = useState(null);
  const [newPointTypeObjectId, setNewPointTypeObjectId] = useState(null);
  const [newLineTypeId, setNewLineTypeId] = useState(null);

  const [typesArray, setTypesArray] = useState([]);

  // Logic to determine which hook to use
  let useFetchHook;
  if (typeOfTable === TYPE_ELEMENT.OBJECT && !isLine) {
    useFetchHook = useFetchObjectLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.COMPONENT) {
    useFetchHook = useFetchComponentsLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.EVENT) {
    useFetchHook = useFetchEventLibraries;
  } else if (typeOfTable === TYPE_ELEMENT.OBJECT && isLine) {
    useFetchHook = useFetchObjectLineLibraries;
  }

  const { data: dataLibrary, error: dataLibraryError } = useFetchHook({
    id: pointLibraryId,
  });

  const userId = localStorage.getItem("userId");

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    urls.users.getOperators
  );
  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];

  const allDataObjects = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );

  const allDataComponents = useSelector(
    (state) => state.adminReducer.dataComponentPolygon
  );

  const allDataEvents = useSelector(
    (state) => state.adminReducer.dataEventPolygon
  );

  const { data: allDataLines, error: errorLines } = useSwr(urls.lines.all);

  //Get Data Global Tables
  const { data: globalTables, error: errorGlobalTables } = useSwr(
    urlsApiMg.globalValuesFields
  );

  const { data: typeEvents, error: typeEventsError } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-type-events?libraryId=${pointLibraryId}`
  );
  const { data: typeComponents, error: typeComponentsError } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/point-type-components?libraryId=${pointLibraryId}`
  );
  const { data: typeLines, error: typeLinesError } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/type-lines/library/${pointLibraryId}`
  );
  const { data: typeObjects, error: typeObjectsError } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/type-elements/library/${pointLibraryId}`
  );

  useEffect(() => {
    if (
      typeObjects?.length > 0 &&
      !typeObjectsError &&
      typeOfTable === TYPE_ELEMENT.OBJECT &&
      !isLine
    ) {
      setNewPointTypeObjectId(typeObjects?.at(0)?.id);
      setTypesArray(typeObjects);
    } else if (
      typeEvents?.length > 0 &&
      !typeEventsError &&
      typeOfTable === TYPE_ELEMENT.EVENT
    ) {
      setNewPointTypeEventId(typeEvents?.at(0)?.id);
      setTypesArray(typeEvents);
    } else if (
      typeComponents?.length > 0 &&
      !typeComponentsError &&
      typeOfTable === TYPE_ELEMENT.COMPONENT
    ) {
      setNewPointTypeComponentId(typeComponents?.at(0)?.id);
      setTypesArray(typeComponents);
    } else if (
      typeLines?.length > 0 &&
      !typeLinesError &&
      typeOfTable === TYPE_ELEMENT.OBJECT &&
      isLine
    ) {
      setNewLineTypeId(typeLines?.at(0)?.id);
      setTypesArray(typeLines);
    }
  }, [
    isLine,
    typeObjects,
    typeObjectsError,
    typeEvents,
    typeEventsError,
    typeComponents,
    typeComponentsError,
    typeLines,
    typeLinesError,
    typeOfTable,
  ]);

  const { mutate } = useSWRConfig();

  // Forms
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  const handleChangeGlobalSelects = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    setForm({ ...form, [name]: value });
  };

  const handleChangeTypeId = (e, typeOfTable, isLine) => {
    const { value } = e.target;
    let setNewType;
    if (typeOfTable === TYPE_ELEMENT.OBJECT && !isLine) {
      setNewType = setNewPointTypeObjectId;
    } else if (typeOfTable === TYPE_ELEMENT.COMPONENT) {
      setNewType = setNewPointTypeComponentId;
    } else if (typeOfTable === TYPE_ELEMENT.EVENT) {
      setNewType = setNewPointTypeEventId;
    } else if (typeOfTable === TYPE_ELEMENT.OBJECT && isLine) {
      setNewType = setNewLineTypeId;
    }
    setNewType(parseInt(value));
  };

  useEffect(() => {
    if (globalTables && !errorGlobalTables && globalTables.length > 0) {
      setGlobalTableCategories(globalTables[0].tableCategories);
    }
  }, [globalTables, errorGlobalTables]);

  useEffect(() => {
    if (
      dataLibrary &&
      dataLibrary[0]?.fields &&
      dataLibrary[0]?.fields.length !== 0
    ) {
      const selectGlobal = dataLibrary[0]?.fields.filter((elm) => {
        return (
          elm.type === "select" &&
          elm.globalSelect &&
          !elm.hasOwnProperty("columnKeyGlobalSpecs")
        );
      });
      dataLibrary[0]?.fields.forEach((elm) => {
        if (
          (elm.type === "string" || elm.type === "number") &&
          elm.globalSelect
        ) {
          const matchSelect = selectGlobal.find((item) => {
            return (
              elm?.columnKeyGlobalSpecs?.selectGlobalId === item._id ||
              elm?.columnKeyGlobalSpecs?.selectGlobalName === item.name
            );
          });
          const findCategory = globalTableCategories?.find((item) => {
            return elm.columnKeyGlobalSpecs.categoryId === item._id;
          });

          const matchTbale = findCategory?.groupTables?.find((item) => {
            return elm.columnKeyGlobalSpecs.tableId === item._id;
          });

          const row = matchTbale?.rows?.find((row) => {
            return (
              row?.Options ===
              getValues(matchSelect?.name || matchSelect?.alias)
            );
          });

          if (
            getValues(matchSelect?.name || matchSelect?.alias) ===
            "Select global option"
          ) {
            return setValue(elm?.name || elm?.alias, "");
          }
          if (row) {
            return setValue(
              elm?.name || elm?.alias,
              row[elm.columnKeyGlobalSpecs.column.name]
            );
          }
        }
      });
    }
  }, [
    handleChangeGlobalSelects,
    useFetchObjectLibraries,
    dataLibrary,
    dataLibraryError,
    pointLibraryId,
  ]);

  const onSubmit = async (data) => {
    setLoading(true);
    const numericFields = dataLibrary[0]?.fields
      ?.map((field) => {
        if (field.type === "number") {
          const elmt = document.getElementById(field.name);
          if (elmt) {
            field.value = elmt.value;
            return field;
          }
        }
        return null;
      })
      .filter((it) => it !== null);

    const expFields = dataLibrary[0]?.fields?.filter(
      (field) => field.type === "operation"
    );

    if (numericFields?.length > 0)
      numericFields.forEach((fld) => {
        data[fld.name] = JSON.stringify(fld);
      });
    if (expFields?.length > 0)
      expFields.forEach((fld) => {
        data[fld.name] = JSON.stringify(fld);
      });

    // Extract key fields from formFields
    const keyFields = Object.keys(data);
    // Create attributes object
    const attributes = createAttributes(data, keyFields);
    // Filter out attributes with empty values
    const newAttributes = attributes.filter(
      (attribute) =>
        attribute.value !== "" && attribute.name !== "Georeferenced"
    );
    // Add alias to attributes
    newAttributes.forEach((item, index) => {
      const tmp = dataLibrary?.[0]?.fields.find((it) => it.name === item.name);
      if (tmp) newAttributes[index].alias = tmp?.alias || "";
    });

    const newFilterAttributes = newAttributes?.reduce((result, item) => {
      // Check if the name starts with "update-" and the value is "true"
      if (item.name.startsWith("update-") && item.value === "true") {
        // Find the corresponding element without the "update-"
        const correspondingName = item.name.replace("update-", "");
        const correspondingItem = newAttributes.find(
          (i) => i.name === correspondingName
        );
        //If the corresponding element is found, add it to the result
        if (correspondingItem) {
          result.push(correspondingItem);
        }
      }
      // Return the result for the next iteration
      return result;
    }, []);

    // Get objects data with mongoId field
    let newItemsData;
    if (typeOfTable === TYPE_ELEMENT.OBJECT && !isLine)
      newItemsData = allDataObjects.filter((object) =>
        allFilterTableRows?.some((data) => data.id === object.id)
      );

    if (!errorLines && typeOfTable === TYPE_ELEMENT.OBJECT && isLine)
      newItemsData = allDataLines.filter((line) =>
        allFilterTableRows?.some((data) => data.id === line.id)
      );

    if (typeOfTable === TYPE_ELEMENT.COMPONENT)
      newItemsData = allDataComponents.filter((component) =>
        allFilterTableRows?.some((data) => data.id === component.id)
      );

    if (typeOfTable === TYPE_ELEMENT.EVENT)
      newItemsData = allDataEvents.filter((event) =>
        allFilterTableRows?.some((data) => data.id === event.id)
      );

    await Promise.all(
      newItemsData.map(async (element) => {
        // Add _id to each element's fields
        const newData = newFilterAttributes?.map((item, index) => {
          const tmp = element?.attributes?.find((it) => it.name === item.name);

          if (tmp) {
            return {
              ...newFilterAttributes[index],
              _id: tmp._id || null,
            };
          } else {
            return {
              ...newFilterAttributes[index],
            };
          }
        });

        // Get data numbers and operations
        const dataNumbers = element.attributes?.filter((item) => {
          // Check if value starts with "{"
          if (item.value.startsWith("{")) {
            return true;
          }

          // Check if value is a number
          const parsedValue = parseFloat(item.value);
          if (!isNaN(parsedValue)) {
            return true;
          }

          // If neither condition matches, return false
          return false;
        });
        // Delete fields to update
        const itemsToRemove = newData.map((item) => item?.name);
        const attributesToUpdate = dataNumbers.filter(
          (item) => !itemsToRemove?.includes(item.name)
        );

        const newData2 = [...newData, ...attributesToUpdate];

        // Update data element
        if (newData?.length > 0) {
          if (typeOfTable === TYPE_ELEMENT.OBJECT && !isLine) {
            await updateAttributesObjectMg(element.mongoId, newData2);
            await updateObjectPg(
              { lastUpdate: new Date(), userUpdateId: userId },
              element.id
            );
            mutate(urlKeys.objects);
          }
          if (typeOfTable === TYPE_ELEMENT.OBJECT && isLine) {
            await updateAttributesObjectLineMg(element.geojsonLineId, newData2);
            await updateLinePg({ updatedAt: new Date() }, element?.id);
            mutate(urlKeys.lines);
          }
          if (typeOfTable === TYPE_ELEMENT.COMPONENT) {
            await updateAttributesComponentMg(element.mongoId, newData2);
            await setUpdateAtComponent(element.id);
            mutate(urlKeys.components);
          }
          if (typeOfTable === TYPE_ELEMENT.EVENT) {
            await updateAttributesEventMg(element.mongoId, newData2);
            await setUpdateAtEvent(element.id);
            mutate(urlKeys.eventsComponents);
            mutate(urlKeys.eventsObjects);
            mutate(urlKeys.eventsEvents);
          }
        }
        if (typeOfTable === TYPE_ELEMENT.EVENT) {
          if (
            element.locationOperation.latitude &&
            element.locationOperation.longitude
          ) {
            await updatePointEvent({
              id: element.id,
              body: {
                isGeographic: data.Georeferenced,
              },
            });
          }
          // change event type
          if (newPointTypeEventId !== null && data["update-typePoint"]) {
            await updatePointEvent({
              id: element.id,
              body: {
                pointTypeEventId: newPointTypeEventId,
              },
            });
          }
        }
        if (typeOfTable === TYPE_ELEMENT.OBJECT && !isLine) {
          //change object type
          if (newPointTypeObjectId !== null && data["update-typePoint"]) {
            await updateObjectPg(
              { typeElementId: newPointTypeObjectId },
              element.id
            );
          }
        }
        if (typeOfTable === TYPE_ELEMENT.COMPONENT) {
          // change component type
          if (newPointTypeComponentId !== null && data["update-typePoint"]) {
            await updatePointComponent({
              id: element.id,
              body: {
                pointTypeComponentId: newPointTypeComponentId,
              },
            });
          }
        }
        if (typeOfTable === TYPE_ELEMENT.OBJECT && isLine) {
          // change line type
          if (newLineTypeId !== null && data["update-typePoint"]) {
            await updateLinePg({ lineTypeId: newLineTypeId }, element?.id);
          }
          mutate(urlKeys.eventsComponents);
          mutate(urlKeys.eventsObjects);
          mutate(urlKeys.eventsEvents);
        }
      })
    );
    await waitSeconds(5);
    setLoading(false);
    reset();
    setOpenEditFilterData(false);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    }
    setForm({ ...form, [name]: value });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!dataLibraryError && dataLibrary && (
        <div>
          {/* Selector of types for bulk edit */}
          <div className="wrapper-check-update">
            <TextInput
              field={{
                name: `update-typePoint`,
                required: false,
                type: "checkbox",
                possible: [],
                alias: " ",
              }}
              register={register}
              errors={errors}
            />
            <div>
              <label
                htmlFor={"select-convert-point-type"}
                className="label-select-point-type"
              >
                Convert {isLine ? "lines" : typeOfTable} type
              </label>
              <select
                id={"select-convert-point-type"}
                className="select-point-type"
                value={
                  typeOfTable === TYPE_ELEMENT.COMPONENT
                    ? newPointTypeComponentId
                    : typeOfTable === TYPE_ELEMENT.EVENT
                    ? newPointTypeEventId
                    : typeOfTable === TYPE_ELEMENT.OBJECT && isLine
                    ? newLineTypeId
                    : newPointTypeObjectId
                }
                onChange={(e) => handleChangeTypeId(e, typeOfTable, isLine)}
              >
                {typesArray?.map((type, index) => {
                  return (
                    <option key={index} value={type.id}>
                      {type.name ?? type.type}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {dataLibrary[0]?.fields?.map((field, index) => {
            if (field.type === "operation") {
              return (
                <div className="wrapper-check-update">
                  <TextInput
                    field={{
                      name: `update-${field.name}`,
                      required: false,
                      type: "checkbox",
                      possible: [],
                      alias: " ",
                    }}
                    register={register}
                    errors={errors}
                  />
                  <TextInput
                    key={index}
                    field={{ ...field, required: false }}
                    register={register}
                    errors={errors}
                  />
                </div>
              );
            }
            if (field.type === "select" && field.globalSelect) {
              const currentCategory = globalTableCategories?.find(
                (category) => {
                  return category._id === field.globalTable.categoryId;
                }
              );
              const selectTable = currentCategory?.groupTables.find((table) => {
                return table._id === field.globalTable.tableId;
              });
              if (selectTable)
                return (
                  <div
                    className="wrapper-check-update"
                    onChange={(e) => handleChangeGlobalSelects(e)}
                  >
                    <TextInput
                      field={{
                        name: `update-${field.name}`,
                        required: false,
                        type: "checkbox",
                        possible: [],
                        alias: " ",
                      }}
                      register={register}
                      errors={errors}
                    />
                    <SelectInput
                      key={index}
                      field={{
                        name: field.name,
                        options: ["Select global option"].concat(
                          selectTable.rows.map((elm) => elm.Options)
                        ),
                        required: field.required,
                        type: field.type,
                        alias: field.alias || field.name,
                      }}
                      register={register}
                    />
                  </div>
                );
            }
            if (field.type === "select") {
              return (
                <div className="wrapper-check-update">
                  <TextInput
                    field={{
                      name: `update-${field.name}`,
                      required: false,
                      type: "checkbox",
                      possible: [],
                      alias: " ",
                    }}
                    register={register}
                    errors={errors}
                  />
                  <SelectInput
                    key={index}
                    field={{ ...field, required: false }}
                    register={register}
                  />
                </div>
              );
            }
            if (field.type === "currency") {
              return (
                <div className="wrapper-check-update">
                  <TextInput
                    field={{
                      name: `update-${field.name}`,
                      required: false,
                      type: "checkbox",
                      possible: [],
                      alias: " ",
                    }}
                    register={register}
                    errors={errors}
                  />
                  <div key={index}>
                    <TextInput
                      field={{ ...field, required: false }}
                      register={register}
                      errors={errors}
                    />
                    <SelectInput
                      field={{ ...field, required: false }}
                      register={register}
                    />
                  </div>
                </div>
              );
            }
            if (field.type === "range") {
              const step =
                field.options.length !== 0 ? parseInt(field.options[0]) : 1;

              return (
                <div className="wrapper-check-update">
                  <TextInput
                    field={{
                      name: `update-${field.name}`,
                      required: false,
                      type: "checkbox",
                      possible: [],
                      alias: " ",
                    }}
                    register={register}
                    errors={errors}
                  />
                  <div key={index} onChange={(e) => handleChange(e)}>
                    <TextInput
                      key={index}
                      field={{
                        name: field.name,
                        alias: field.alias,
                        required: false,
                        type: field.type,
                        step: step,
                        max: field.max,
                        min: field.min,
                      }}
                      register={register}
                      errors={errors}
                    />
                    <StepSection>
                      <b>Step: {step}</b>
                      <b>
                        {form[field.name] ? form[field.name] : field.min + step}
                      </b>
                    </StepSection>{" "}
                  </div>
                </div>
              );
            }
            if (field.type === "delegate") {
              if (
                form[field.name] === "" &&
                operatorsAdmin &&
                !errorOperators
              ) {
                form[
                  field.name
                ] = `${allOperators.operators[0].firstName} ${allOperators.operators[0].firstLastName}`;
              }
              return (
                <div className="wrapper-check-update">
                  <TextInput
                    field={{
                      name: `update-${field.name}`,
                      required: false,
                      type: "checkbox",
                      possible: [],
                      alias: " ",
                    }}
                    register={register}
                    errors={errors}
                  />
                  <div onChange={(e) => handleChange(e)}>
                    <SelectInput
                      key={index}
                      field={{
                        name: field.name,
                        options: allOperators.operators?.map((operator) => {
                          return `${operator?.firstName} ${operator?.firstLastName}`;
                        }),
                        required: false,
                        type: field.type,
                        alias: field.alias || field.name,
                      }}
                      register={register}
                    />
                  </div>
                </div>
              );
            }
            if (field.type === "file") return null;
            if (field.type === "captureMedia") return null;
            if (field.type === "notification") return null;
            if (field.type === "lineTag") return null;
            return (
              <div className="wrapper-check-update">
                <TextInput
                  field={{
                    name: `update-${field.name}`,
                    required: false,
                    type: "checkbox",
                    possible: [],
                    alias: " ",
                  }}
                  register={register}
                  errors={errors}
                />
                <TextInput
                  key={index}
                  field={{
                    ...field,
                    required: false,
                    warning: field.globalSelect ? true : false,
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          })}
          {typeOfTable === TYPE_ELEMENT.EVENT && (
            <div className="wrapper-check-update">
              <TextInput
                field={{
                  name: `update-isGeographic`,
                  required: false,
                  type: "checkbox",
                  possible: [],
                  alias: " ",
                }}
                register={register}
                errors={errors}
              />
              <TextInput
                key={"Georeferenced"}
                field={{
                  name: `Georeferenced`,
                  type: "checkbox",
                  required: false,
                }}
                register={register}
                errors={errors}
              />
            </div>
          )}
        </div>
      )}
      <CustomButton
        type={"submit"}
        isLoad={loading}
        text={"Update"}
        margin={0}
      />
    </Form>
  );
};

export default EditFilterTable;
