import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  ContainerIntro,
  ContainerTitle,
  HubH1,
  HubD,
  LogoCont,
  BottomLeftContainer,
} from "./HubStyles";
// import LastLoginInfo from "./LastLoginInfo";
import NeuronaIconD from "images/NeuronaIconD";
// hooks
import { useColorHostName } from "hooks/colorHostName";
import { useContentData } from "hooks/useContentData";
import { useUserStore } from "zustandGloabalStore/user";
import { urls } from "utils/urlKeys";

const DescriptionHub = ({
  adminCompany,
  adminDecimetrix,
  operatorDecimetrix,
}) => {
  const { isRedDragon } = useColorHostName();
  const contentData = useContentData({ isRedDragon, section: "mainHub" });
  const { instanceId, userId } = useUserStore((state) => state);
  const [instanceName, setInstanceName] = useState("");

  // Función para obtener el nombre de la instancia
  useEffect(() => {
    const fetchInstanceName = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${urls.users.byId(userId)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Actualizar el estado con el nombre de la instancia
        if (response.status === 200) {
          const data = response.data;
          const matchingInstance = data.instancesUsers.find(
            (instanceUser) => instanceUser.instanceId === instanceId
          );
          if (matchingInstance) {
            setInstanceName(matchingInstance?.instance?.name);
          } else {
            setInstanceName("");
          }
        } else {
          console.error("Error al obtener el nombre de la instancia");
          setInstanceName("Error al cargar");
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
        setInstanceName("Error al cargar");
      }
    };

    fetchInstanceName();
  }, [userId, instanceId]);

  // Extract data from contentByHost
  const { title, description, source } = contentData;
  return (
    <>
      <ContainerIntro className="background-1">
        <LogoCont className="miniLogo">
          <NeuronaIconD width={60} height={60} />
        </LogoCont>
        <ContainerTitle>
          <HubH1>
            Decimetrix<sup style={{ fontSize: 25 }}>®</sup>
            {source} <br />
            {title}
          </HubH1>
          <HubD>{description}</HubD>
        </ContainerTitle>
        {/* <LastLoginInfo
          adminCompany={adminCompany}
          adminDecimetrix={adminDecimetrix}
          operatorDecimetrix={operatorDecimetrix}
        /> */}
      </ContainerIntro>
      {instanceName && (
        <BottomLeftContainer>
          <h1>{`${instanceName} Instance`}</h1>

        </BottomLeftContainer>
      )}
    </>
  );
};

export default DescriptionHub;
