import uploadCSV from "services/GlobalListTable/uploadCSV";

const uploadCSVGlobalListTable = async (
  file,
  tableId,
  tableCategoriesId,
  groupId,
  setErrorMessage,
  setErrorMessageFile,
  setCurrentGlobalTable,
  setinvalidRowsBackend,
  upload
) => {
  try {
    const { data, error } = await uploadCSV(
      tableId,
      tableCategoriesId,
      groupId,
      file,
      upload
    );

    const category = data?.table?.tableCategories?.find(
      (category) => category?._id === tableCategoriesId
    );
    const groupTable = category?.groupTables?.find(
      (groupTable) => groupTable?._id === groupId
    );

    const invalidRows = data?.invalidRows;

    if (invalidRows?.length > 0) {
      setinvalidRowsBackend(invalidRows);
    } else {
      setinvalidRowsBackend([]);
    }

    if (error) {
      console.error("Error uploading CSV:", error);
      setErrorMessage(
        "Error uploading CSV. Please check the file format or try again."
      );
      setErrorMessageFile(true);
    } else if (!data || data.length === 0) {
      setErrorMessage("The CSV file is empty or contains invalid rows.");
      setErrorMessageFile(true)
    } else {
      setErrorMessage("");
      setErrorMessageFile(false);
      //update global table with new rows
      setCurrentGlobalTable((current) => ({
        ...current,
        rows: [...groupTable.rows],
      }));
    }
  } catch (e) {
    console.error("Error during upload:", e);
    setErrorMessage("An unexpected error occurred. Please try again.");
    setErrorMessageFile(true);
  }
};

export default uploadCSVGlobalListTable;
