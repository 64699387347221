import useSwr from "swr";
import { urls } from "utils/urlKeys";

const FetchTypeLines = () => {
  const token = localStorage.getItem("token"); // Obtener el token del almacenamiento local

  const { data, error } = useSwr(urls.lines.typeLines, async (url) => {
    const response = await fetch(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  });

  return { data, error };
};

export default FetchTypeLines;
