import { config } from "config.js";
import { useState, useEffect } from "react";
import { urls } from "utils/urlKeys";

async function fetchLineTypes(id) {
  let baseUrl = urls.lines.typeLines;

  if (id !== null && !isNaN(parseInt(id))) {
    baseUrl = `${config.URL_BACKEND_PG}api/v1/type-lines/library/${parseInt(
      id
    )}`;
  }

  const token = localStorage.getItem("token");

  try {
    const response = await fetch(baseUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    if (!response.ok) {
      console.error(
        "❌ Response not OK:",
        response.status,
        response.statusText
      );
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("💥 Fetch error:", error);
  }
}

function useGetLineTypes({ id = null }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchLineTypes(id);
        setData(result);
      } catch (err) {
        setError(err);
      }
    };

    getData();
  }, [id]);

  return { data, error };
}

export default useGetLineTypes;
