import { config } from "config.js";
import { useUserStore } from "zustandGloabalStore/user";

const { adminCompanyId, userId, instanceId, adminDecimetrixId } =
  useUserStore.getState();

console.log("✅✅✅ Local storage zustand: ", {
  adminCompanyId,
  userId,
  instanceId,
  adminDecimetrixId,
});

const backendPg = config.URL_BACKEND_PG;
const backendMg = config.URL_BACKEND_MG;
const backendPy = config.URL_BACKEND_OCR;
// const adminCompanyId = config.ADMIN_COMPANY_ID;
// const userId = config.USER_ID;
// const instanceId = config.INSTANCE_ID;

export const urlKeys = {
  objects: `${backendPg}api/v1/objects?instanceId=${instanceId}`,
  eventsObjects: `${backendPg}api/v1/point-events?instanceId=${instanceId}`,
  eventsComponents: `${backendPg}api/v1/point-events/components?instanceId=${instanceId}`,
  eventsEvents: `${backendPg}api/v1/point-events/relation?instanceId=${instanceId}`,
  components: `${backendPg}api/v1/point-components/components-web?instanceId=${instanceId}`,
  lines: `${backendPg}api/v1/lines?instanceId=${instanceId}`,
};

export const urlKeysDetail = ({ detailId }) => {
  return {
    urlObjects: `${backendPg}api/v1/objects/${detailId}`,
    urlComponents: `${backendPg}api/v1/point-components?id=${detailId}`,
    urlEventsObjects: `${backendPg}api/v1/point-events?id=${detailId}`,
    urlEventsComponents: `${backendPg}api/v1/point-events/components?id=${detailId}`,
    urlEventsEvents: `${backendPg}api/v1/point-events/relation?id=${detailId}`,
    urlDynamicComponentEvents: `${backendPg}api/v1/point-events/components?pointComponentId=${detailId}`,
    urlDynamicEventsObjects: `${backendPg}api/v1/point-events?objectId=${detailId}`,
    urlDynamicEventsEvents: `${backendPg}api/v1/point-events/all?id=${detailId}`,
  };
};

export const ommitBucketUrl = [
  "https://greendragon-mobile-20252ccf1-dev",
  "https://greendragon-development",
  "https://greendragon-production",
  "https://greendragonmobileusersdeployment123656-dev",
  "https://greendragonmobilekotlindeployment123656-dev",
  "https://bucket-develop-local-tmp",
  "https://bucket-production-tmp",
  "https://icons-greendragon",
];

/**
 * Review:
 *  ✅ La ruta ya fue revisasa para que funcione con instance Id en lugar de adminCompanyId
 *  🚶 en camino
 */

export const urls = {
  events: {
    detail: {
      events: (eventId) =>
        `${backendPg}api/v1/point-events/relation?id=${eventId}`,
      components: (eventId) =>
        `${backendPg}api/v1/point-events/components?id=${eventId}`,
      objects: (eventId) => `${backendPg}api/v1/point-events?id=${eventId}`,
      byComponentId: (componentId) =>
        `${backendPg}api/v1/point-events/components?pointComponentId=${componentId}`,
    },
    all: `${backendPg}api/v1/point-events/all?instanceId=${instanceId}`, // ✅
    objects: `${backendPg}api/v1/point-events?instanceId=${instanceId}`, // ✅
    components: `${backendPg}api/v1/point-events/components?instanceId=${instanceId}`, // ✅
    events: `${backendPg}api/v1/point-events/relation?instanceId=${instanceId}`, // ✅
    libraries: `${backendPg}api/v1/point-library-events?instanceId=${instanceId}`, // ⏰ Falta acualizar este endpont en config admin en el setendpoint y setFetchEndpoint. Ahora es oblogatorio enviar el instanceId
    librariesByInstanceId: (instanceId) =>
      `api/v1/point-library-events?instanceId=${instanceId}`, // ❌ Revisarlo con Juan, esta ruta se crea cuando se quiere duplicar una libreria de evento a otra libreria
    allLibrariesByToken: `api/v1/point-library-events`,
    typeEventsTypeElements: (params) =>
      `${backendPg}api/v1/type-events-type-elements?${params}`, // ✅
    typeEventsParams: (params = "") =>
      `${backendPg}api/v1/point-type-events?instanceId=${instanceId}${
        params ? `&${params}` : ""
      }`, // ✅
    typeEventsByInstanceId: (libraryId, instanceId) =>
      `${backendPg}api/v1/point-type-events?libraryId=${libraryId}&instanceId=${instanceId}`, //❌ Revisarlo con Juan, esta ruta se crea cuando se quiere duplicar una libreria de evento a otra libreria
    typeEventsTypeComponents: (params) =>
      `${backendPg}api/v1/type-events-type-components?${params}`, // ✅
    typeEventsTypeEvents: (params) =>
      `${backendPg}api/v1/type-events-type-events?${params}`, // ✅
  },
  objects: {
    all: `${backendPg}api/v1/objects?instanceId=${instanceId}`, // ✅ pero hay que agregar lo nuevo de las firmas de imagenes
    detail: (objectId) => `${backendPg}api/v1/objects/${objectId}`,
    libraries: `${backendPg}api/v1/point-libraries?instanceId=${instanceId}`, // ⏰ Falta acualizar este endpont en config admin en el setendpoint y setFetchEndpoint. Ahora es oblogatorio enviar el instanceId
    librariesByInstanceId: (instanceId) =>
      `api/v1/point-libraries?instanceId=${instanceId}`, // ❌ Revisarlo con Juan, esta ruta se crea cuando se quiere duplicar una libreria de evento a otra libreria
    allLibrariesByToken: `api/v1/point-libraries`,
    typeObjectsParams: (params = "") =>
      `${backendPg}api/v1/type-elements?instanceId=${instanceId}&${params}`, // ⏰ Falta acualizar este endpont en config admin
    typeObjectsTypeObjects: (params = "") =>
      `${backendPg}api/v1/type-objects-type-objects?instanceId=${instanceId}&${params}`, // ✅
    typeObjectsByInstanceId: (libraryId, instanceId) =>
      `${backendPg}api/v1/type-elements?libraryId=${libraryId}&instanceId=${instanceId}`, // ❌ Revisarlo con Juan, esta ruta se crea cuando se quiere duplicar una libreria de evento a otra libreria
    dates: `${backendPg}api/v1/objects/dates`,
  },
  lines: {
    all: `${backendPg}api/v1/lines?instanceId=${instanceId}`, //✅
    libraryTypes: `${backendPg}api/v1/line-libraries/libraries-types?instanceId=${instanceId}`, // ✅
    libraries: `${backendPg}api/v1/line-libraries?instanceId=${instanceId}`, // ⏰ Falta acualizar este endpont en config admin. el instanceId es obligatorio
    librariesByInstanceId: (instanceId) =>
      `api/v1/line-libraries?instanceId=${instanceId}`, // ❌ Revisarlo con Juan, esta ruta se crea cuando se quiere duplicar una libreria de evento a otra libreria
    allLibrariesByToken: `api/v1/line-libraries`,
    typeLinesByInstanceId: (libraryId, instanceId) =>
      `${backendPg}api/v1/type-lines/library/${libraryId}?instanceId=${instanceId}`, // ❌ Revisarlo con Juan, esta ruta se crea cuando se quiere duplicar una libreria de evento a otra libreria
    typeLines: `${backendPg}api/v1/type-lines?instanceId=${instanceId}`, // ⏰ Falta acualizar este endpont en config admin en setEndpoint
    typeLinesColors: `${backendPg}api/v1/type-lines/colors?instanceId=${instanceId}`, // ✅
    detail: (lineId) => `${backendPg}api/v1/lines?id=${lineId}`,
    objects: (objectId) =>
      `${backendPg}api/v1/lines-objects?objectId=${objectId}`,
    typeLinesTypeObjects: (params) =>
      `${backendPg}api/v1/type-lines-type-objects?instanceId=${instanceId}${params}`, // ✅
  },
  styleMap: {
    all: `${backendPg}api/v1/style-map?instanceId=${instanceId}`, // ✅
  },
  components: {
    all: `${backendPg}api/v1/point-components/components-web?instanceId=${instanceId}`, // ✅
    // allWeb: `${backendPg}api/v1/point-components/components-web?adminCompanyId=${adminCompanyId}`,
    libraries: `${backendPg}api/v1/point-library-components?instanceId=${instanceId}`, // ⏰ Falta acualizar este endpont en config admin en el setendpoint y setFetchEndpoint. Ahora es oblogatorio enviar el instanceId
    librariesByInstanceId: (instanceId) =>
      `api/v1/point-library-components?instanceId=${instanceId}`, // ❌ Revisarlo con Juan, esta ruta se crea cuando se quiere duplicar una libreria de evento a otra libreria
    allLibrariesByToken: `api/v1/point-library-components`,
    typeComponentsByInstanceId: (libraryId, instanceId) =>
      `${backendPg}api/v1/point-type-components?libraryId=${libraryId}&instanceId=${instanceId}`, // ❌ Revisarlo con Juan, esta ruta se crea cuando se quiere duplicar una libreria de evento a otra libreria
    detail: (componentId) =>
      `${backendPg}api/v1/point-components?id=${componentId}`,
    object: (objectId) =>
      `${backendPg}api/v1/point-components?objectId=${objectId}&instanceId=${instanceId}`, // ✅
    typeComponentsTypeElements: (params = "") =>
      `${backendPg}api/v1/type-components-type-elements?instanceId=${instanceId}&${params}`, // ✅
    typeComponentsParams: (params) =>
      `${backendPg}api/v1/point-type-components?instanceId=${instanceId}&${params}`, // ⏰ Falta acualizar este endpont en config admin
  },
  adminCompany: {
    all: `${backendPg}api/v1/admin-company`,
    detail: `${backendPg}api/v1/admin-company/${adminCompanyId}`,
  },
  instance: {
    all: `${backendPg}api/v1/instances`,
    getSimpleInfoUsers: `${backendPg}api/v1/instances/simple-info-users?id=${instanceId}`, // ✅
    instancesByAdminDecimetrix: (id) =>
      `${backendPg}api/v1/instances?adminDecimetrixId=${
        adminDecimetrixId || id
      }`,
    accessInstanceUser: (userId, instanceId) =>
      `${backendPg}api/v1/instances-users?userId=${userId}&instanceId=${instanceId}`,
    instanceUser: `${backendPg}api/v1/instances-users`,
  },
  adminDecimetrix: {
    detail: `${backendPg}api/v1/admin-decimetrix/${adminDecimetrixId}`,
    admins: `${backendPg}api/v1/admin-decimetrix/admins/${adminDecimetrixId}`,
  },
  globalConstants: (params) =>
    `${backendPg}api/v1/global-constants?instanceId=${instanceId}&${params}`, // ✅
  updateGlobalConstants: (id) =>
    `${backendPg}api/v1/global-constants/${id}?instanceId=${instanceId}`, // ✅
  constantLibrary: (params = "") =>
    `${backendPg}api/v1/constant-library?instanceId=${instanceId}${
      params ? `&${params}` : ""
    }`, // ✅
  tags: (params) => `${backendPg}api/v1/tags?instanceId=${instanceId}${params}`, // ✅
  regions: {
    libraries: `${backendPg}api/v1/region-libraries`,
    typeRegion: `${backendPg}api/v1/type-region`,
  },
  users: {
    all: `${backendPg}api/v1/users`, // VERIFICAR QUE LOS USUARIOS PERTENEZACAN A LA INSTANCIA DONDE SE HACEN LAS PETICIONES
    byId: (userId) => `${backendPg}api/v1/users/${userId}`,
    detail: (id) => `${backendPg}api/v1/users/${userId || id}`,
    getOperators: `${backendPg}api/v1/operators?instanceId=${instanceId}`, // ✅
    getOperatorsInstanceDynamic: (instanceId) =>
      `${backendPg}api/v1/operators?instanceId=${instanceId}`,
    deleteOperator: (id, instanceId) =>
      `${backendPg}api/v1/operators/${id}?instanceId=${instanceId}`,
    getAdminCompanyByInstance: (instanceId) =>
      `${backendPg}api/v1/admin-company?instanceId=${instanceId}`,
    deleteAdminCompany: (id, instanceId) =>
      `${backendPg}api/v1/admin-company/${id}?instanceId=${instanceId}`,
  },
  videoProcessed: `${backendPg}api/v1/type-video-processed`,
  typeInspectionsFlir: `${backendPg}api/v1/type-inspections-flir`,
  operators: {
    all: `${backendPg}api/v1/operators`,
    detail: (operatorId) => `${backendPg}api/v1/operators/${operatorId}`,
  },
  globalConstant: {
    saveConstantLibrary: `${backendPg}api/v1/constant-library?instanceId=${instanceId}`,
  },
};

export const urlsApiMg = {
  // lines: {
  //   all: `${backendMg}line?admin_company_id=${adminCompanyId}`,
  // },
  regions: {
    all: `${backendMg}region?admin_company_id=${adminCompanyId}`, // las regiones no se estan usando
  },
  mapSettings: {
    get: `${backendMg}map-settings?instanceId=${instanceId}&userId=${userId}`,
    update: (id) => `${backendMg}map-settings/${id}?instanceId=${instanceId}`,
    updatePointConfig: (type) =>
      `${backendMg}map-settings/point-config/${userId}?type=${type}&instanceId=${instanceId}`,
  },
  dashboard: {
    orderCards: `${backendMg}order-cards-dashboard?instanceId=${instanceId}`, // falta arreglar el orden por usuario hay problema porque los datos de una instania es diferente a los de otra intancia por lo tanto el dashbard es distinto y no se puede tener el mismo orden entre instancias, hay que guardar el orden tanto por instancia como por usuario
    barChart: (from) =>
      `${backendMg}bar-chart?instanceId=${instanceId}&from=${from}`,
    doughnutChart: (from) =>
      `${backendMg}doughnut-chart?instanceId=${instanceId}&from=${from}`,
    barChartGroup: (from) =>
      `${backendMg}bar-chart-group?instanceId=${instanceId}&from=${from}`,
    doughnutChartGroup: (from) =>
      `${backendMg}group-doughnut-chart?instanceId=${instanceId}&from=${from}`,
    kpiChart: (from) =>
      `${backendMg}kpi-chart?instanceId=${instanceId}&from=${from}`,
    lineChart: (from) =>
      `${backendMg}line-chart?instanceId=${instanceId}&from=${from}`,
    chartText: (from) =>
      `${backendMg}charts-text?instanceId=${instanceId}&from=${from}`,
    timeSeriesBarChart: (from) =>
      `${backendMg}time-series-bar-chart?instanceId=${instanceId}&from=${from}`,
    timeSeriesChart: (from) =>
      `${backendMg}time-series-chart?instanceId=${instanceId}&from=${from}`,
    imgChart: (from) =>
      `${backendMg}img-chart?instanceId=${instanceId}&from=${from}`,
    chartType: (urlType, from) =>
      `${backendMg}${urlType}?instanceId=${instanceId}&from=${from}`,
  },
  orderColumns: (params) =>
    `${backendMg}order-columns/${userId}?instanceId=${instanceId}&${params}`, // ❌ Revisarlo con Juan
  globalValuesFields: `${backendMg}global-values-fields?instanceId=${instanceId}`,
  globalListTalble: `${backendMg}global-list-table?instanceId=${instanceId}`,
  globalMeasurementTable: `${backendMg}global-measurement-table?instanceId=${instanceId}`,
  configPreferences: `${backendMg}config-preferences?instanceId=${instanceId}`,
  routes: `${backendMg}routes?instanceId=${instanceId}`,
};

export const urlsApiPy = {
  filterEventsPolygon: `${backendPy}filterEventsPolygon?adminCompanyId=${adminCompanyId}`,
};
