import { create } from "zustand";

/**
 * Zustand store for managing company root bucket configuration
 * @namespace useStoreBucketUser
 * @property {Object|null} bucketUser - Bucket configuration object or null
 * @property {Function} setBucketUser - Function to update the bucketUser state
 *
 * @example
 * // bucketUser object structure:
 * {
 *   companyName: "Decimetrix",         // Legal company name
 *   email: "user@domain.com",          // Associated account email
 *   companyNameFormat: "decimetrix",    // Normalized company name (lowercase/no spaces)
 *   bucketName: "decimetrix/user@domain.com" // Unique storage path
 * }
 *
 * @example
 * // Component usage:
 * const { bucketUser, setBucketUser } = useStoreBucketUser();
 *
 * // Updating state:
 * setBucketUser({
 *   companyName: "MyCompany",
 *   email: "contact@mycompany.com",
 *   companyNameFormat: "mycompany",
 *   bucketName: "mycompany/contact@mycompany.com"
 * });
 *
 */
export const useStoreBucketUser = create((set) => ({
  bucketUser: null,

  /**
   * Updates the bucketUser state
   * @param {Object} payload - New configuration object
   * @param {string} payload.companyName - Full legal company name
   * @param {string} payload.email - Valid associated email
   * @param {string} payload.companyNameFormat - Normalized name version (lowercase/no spaces)
   * @param {string} payload.bucketName - Full path format: "formattedName/email"
   */
  setBucketUser: (payload) => set(() => ({ bucketUser: payload })),
}));
