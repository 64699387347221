import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import dayjs from "dayjs";

import { StaticDatePicker } from "@mui/x-date-pickers";

const dateFormatter = (date) => {
  return new dayjs(date).format("YYYY-MM-DD");
};

export const ContentDatePicker = (
  startdate,
  enddate,
  changefunction,
  closefunction
) => {
  const [selected, setSelected] = useState();

  const handleNewDate = (newDate) => {
    setSelected(newDate);
    changefunction(dateFormatter(newDate));
  };

  useEffect(() => {
    const start = dayjs(startdate);
    const end = dayjs(enddate);

    if (startdate === "" && enddate === "") {
      setSelected(dayjs());
    }
    if (start.isSame(end)) {
      setSelected(start);
      changefunction(start.format("YYYY-MM-DD"));
    }
    if (start.isAfter(end)) {
      setSelected(end);
      changefunction(end.format("YYYY-MM-DD"));
    }
  }, [startdate, enddate]);

  return {
    title: "Select date",
    description: (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Container>
            <div
              style={{
                width: "500px",
                minHeight: "350px",
              }}
            >
              <StaticDatePicker
                orientation="landscape"
                inputFormat="yyyy-MM-DD"
                displayWeekNumber
                onAccept={handleNewDate}
                onClose={closefunction}
                value={dayjs(selected)}
              />
            </div>
          </Container>
        </Grid>
      </>
    ),
  };
};
