import { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import useGet from "hooks/useFetch.js";
import { urls } from "utils/urlKeys";

const AccordionRender = ({
  library,
  typeLinesTypeObjects,
  handleChangeCheck,
}) => {
  const { name, id } = library;
  const [types] = useGet(
    urls.objects.typeObjectsParams(`libraryId=${parseInt(id)}`)
  );
  const [check, setCheck] = useState(false);

  const handleChangeCheckLibrary = () => {
    const thisCheck = check;
    if (thisCheck) {
      types.forEach((type) => {
        const element = typeLinesTypeObjects?.[type?.id];
        if (element) {
          handleChangeCheck(type.id);
        }
      });
      return;
    }
    types.forEach((type) => {
      const element = typeLinesTypeObjects?.[type?.id];
      if (!element) {
        handleChangeCheck(type.id);
      }
    });
  };

  useEffect(() => {
    if (!types || types.error) return;
    types.every((type) => {
      const element = typeLinesTypeObjects?.[type?.id];
      if (element) return setCheck(true);
      return setCheck(false);
    });
  }, [types, typeLinesTypeObjects]);

  return (
    <Accordion className="background-row-1 color1 border-color-1 no-shadow">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="color1" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <FormControlLabel
            sx={{
              "& .css-119cnn6-MuiTypography-root": {
                fontFamily: document.body.style.fontFamily,
                fontWeight: "bold",
                color: document.body.style.color,
              },
            }}
            control={
              <Checkbox checked={check} onChange={handleChangeCheckLibrary} />
            }
            label={name}
          />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          {types &&
            !types.error &&
            types.length > 0 &&
            types.map((type, key) => {
              const element = typeLinesTypeObjects?.[type?.id];
              let check = false;
              if (element) check = true;
              return (
                <FormControlLabel
                  sx={{
                    "& .css-119cnn6-MuiTypography-root": {
                      fontFamily: document.body.style.fontFamily,
                      color: document.body.style.color,
                    },
                  }}
                  key={key}
                  control={
                    <Checkbox
                      checked={check}
                      onChange={() => handleChangeCheck(type.id)}
                    />
                  }
                  label={type.name}
                />
              );
            })}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionRender;
