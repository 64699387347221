import axios from "axios";
import { urls } from "utils/urlKeys";

const findPointLibraries = async (id) => {
  const token = localStorage.getItem("token");

  let url = urls.objects.libraries;
  if (id) url = `${url}&id=${id}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export default findPointLibraries;
