import axios from "axios";
import { config } from "config.js";

const updateGlobalTable = async ({ id, body }) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.patch(
      `${config.URL_BACKEND_MG}global-values-fields/${id}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    return { error };
  }
};

export default updateGlobalTable;
