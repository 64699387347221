const MEASUREMENT_UNITS = [
  { symbol: "in", unit: "inch" },
  { symbol: "ft", unit: "foot" },
  { symbol: "mi", unit: "miles" },
  { symbol: "m", unit: "meters" },
  { symbol: "km", unit: "kilometers" },
];

export const getMeasurementUnits = () => {
  return MEASUREMENT_UNITS;
};

export const getMeasurementUnitSymbol = (toReturn = "km") => {
  return MEASUREMENT_UNITS.find(
    (item) => item.symbol === toReturn || item.unit === toReturn
  );
};
