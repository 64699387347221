import React, { useState, useEffect, useCallback } from "react";
import { LinearProgress, Typography, Box } from "@mui/material";
import CustomButton from "components/Buttons/CustomButton";
import { DELETE_COLOR, HOVER_DELETE_COLOR } from "utils/const";
import { deleteObjectsByIds } from "services/objects/bulkDelete";
import { deleteEventsByIds } from "services/Events/bulkDelete";
import { deleteComponentsByIds } from "services/components/bulkDelete";
import { deleteLinesByIds } from "services/lines/bulkDelete";
import { useFilteredData } from "../Hooks/useFilteredData";
import { useSocket } from "hooks/useSocket";
import { useSWRConfig } from "swr";
import { cancelBulkDeleteObject } from "services/objects/cancelBulkDeleteObject";
import { urlKeys } from "utils/urlKeys";
import { cancelBulkDeleteComponent } from "services/components/cancelBulkDeleteComponent";
import { cancelBulkDeleteEvent } from "services/Events/cancelBulkDeleteEvent";
import { cancelBulkDeleteLines } from "services/lines/cancelBulkDeleteLines";

const TYPE_ELEMENT = {
  OBJECT: "objects",
  COMPONENT: "components",
  EVENT: "events",
  LINE: "lines",
};

const DELETION_STATUS = {
  IDLE: "idle",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  CANCELED: "canceled",
  ERROR: "error",
};

export const BulkDelete = ({ setOpenBulkDelete, typeOfTable, isLine }) => {
  const socket = useSocket();
  const [ids, setIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const { mutate } = useSWRConfig();
  const [deleteWithRelations, setDeleteWithRelations] = useState(false);

  const [deletionState, setDeletionState] = useState({
    status: DELETION_STATUS.IDLE,
    progress: {
      deleted: 0,
      total: 0,
      errors: 0,
      errorDetails: [],
    },
  });

  const { itemsFiltered } = useFilteredData({
    typeOfTable: isLine ? TYPE_ELEMENT.LINE : typeOfTable,
  }); // de donde salen los datos para eliminar

  useEffect(() => {
    setIds(itemsFiltered.map((item) => item.id));
  }, [itemsFiltered]);

  const handleSocketEvents = useCallback(() => {
    if (!socket) return;

    const eventHandlers = {
      deleteProgress: (data) => {
        setDeletionState((prev) => ({
          status: DELETION_STATUS.IN_PROGRESS,
          progress: {
            ...prev.progress,
            deleted: data.deleted,
            total: data.total,
          },
        }));
      },

      deleteBatchComplete: (data) => {
        setDeletionState((prev) => ({
          status: DELETION_STATUS.IN_PROGRESS,
          progress: {
            ...prev.progress,
            deleted: data.deleted,
            total: data.total,
            errors: data.errors || prev.progress.errors,
          },
        }));
      },

      bulkDeleteComplete: (data) => {
        setDeletionState({
          status: DELETION_STATUS.COMPLETED,
          progress: {
            deleted: data.deleted,
            total: data.total,
            errors: data.errors || 0,
          },
        });
        setIsLoading(false);
        setDeletionState({
          status: DELETION_STATUS.IDLE,
          progress: {
            deleted: 0,
            total: 0,
            errors: 0,
            errorDetails: [],
          },
        });
        if (data.deleted === data.total) {
          setOpenBulkDelete(false);
          mutate(urlKeys.objects, null, { revalidate: true });
          mutate(urlKeys.components, null, { revalidate: true });
          mutate(urlKeys.componentsWeb, null, { revalidate: true });
          mutate(urlKeys.eventsObjects, null, { revalidate: true });
          mutate(urlKeys.eventsComponents, null, { revalidate: true });
          mutate(urlKeys.eventsEvents, null, { revalidate: true });
          mutate(urlKeys.lines, null, { revalidate: true });
        }
      },

      bulkDeleteCanceled: (data) => {
        setDeletionState({
          status: DELETION_STATUS.CANCELED,
          progress: {
            deleted: data.deleted,
            total: data.total,
            errors: data.errors || 0,
          },
        });
        setIsLoading(false);
        mutate(urlKeys.objects);
        mutate(urlKeys.components);
        mutate(urlKeys.componentsWeb);
        mutate(urlKeys.eventsObjects);
        mutate(urlKeys.eventsComponents);
        mutate(urlKeys.eventsEvents);
        mutate(urlKeys.lines);
      },

      bulkDeleteError: (data) => {
        setDeletionState((prev) => ({
          status: DELETION_STATUS.ERROR,
          progress: {
            ...prev.progress,
            errorDetails: [...prev.progress.errorDetails, data.error],
          },
        }));
        setIsLoading(false);
      },
    };

    Object.entries(eventHandlers).forEach(([event, handler]) => {
      socket.on(event, handler);
    });

    return () => {
      Object.keys(eventHandlers).forEach((event) => {
        socket.off(event);
      });
    };
  }, [socket, mutate]);

  useEffect(() => {
    const cleanup = handleSocketEvents();
    return () => {
      if (cleanup) cleanup();
    };
  }, [handleSocketEvents]);

  const handleCancelDelete = async () => {
    try {
      setIsLoading(false);
      setIsLoadingCancel(true);
      if (TYPE_ELEMENT.OBJECT === typeOfTable) {
        await cancelBulkDeleteObject();
      }
      if (TYPE_ELEMENT.COMPONENT === typeOfTable) {
        await cancelBulkDeleteComponent();
      }
      if (TYPE_ELEMENT.EVENT === typeOfTable) {
        await cancelBulkDeleteEvent();
      }
      if (isLine) {
        await cancelBulkDeleteLines();
      }
      mutate(urlKeys.objects);
      mutate(urlKeys.components);
      mutate(urlKeys.componentsWeb);
      mutate(urlKeys.eventsObjects);
      mutate(urlKeys.eventsComponents);
      mutate(urlKeys.eventsEvents);
      mutate(urlKeys.lines);
      await Promise.all([
        mutate(urlKeys.objects, null, { revalidate: true }),
        mutate(urlKeys.components, null, { revalidate: true }),
        mutate(urlKeys.componentsWeb, null, { revalidate: true }),
        mutate(urlKeys.eventsObjects, null, { revalidate: true }),
        mutate(urlKeys.eventsComponents, null, { revalidate: true }),
        mutate(urlKeys.eventsEvents, null, { revalidate: true }),
        mutate(urlKeys.lines, null, { revalidate: true }),
      ]);

      if (deleteWithRelations) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
      setIsLoadingCancel(false);
      setOpenBulkDelete(false);
    } catch (error) {
      console.error("Error canceling deletion process:", error);
      setDeletionState((prev) => ({
        ...prev,
        status: DELETION_STATUS.ERROR,
        progress: {
          ...prev.progress,
          errorDetails: [...prev.progress.errorDetails, error.message],
        },
      }));
    }
  };

  const handleDeleteData = async () => {
    setIsLoading(true);
    setDeletionState({
      status: DELETION_STATUS.IN_PROGRESS,
      progress: {
        deleted: 0,
        total: ids.length,
        errors: 0,
        errorDetails: [],
      },
    });

    try {
      const deleteActions = {
        [TYPE_ELEMENT.OBJECT]: deleteObjectsByIds,
        [TYPE_ELEMENT.COMPONENT]: deleteComponentsByIds,
        [TYPE_ELEMENT.EVENT]: deleteEventsByIds,
        [TYPE_ELEMENT.LINE]: deleteLinesByIds,
      };

      const deleteFunction =
        deleteActions[isLine ? TYPE_ELEMENT.LINE : typeOfTable];
      if (deleteFunction) {
        if (typeOfTable === TYPE_ELEMENT.OBJECT) {
          await deleteFunction({ ids, relations: deleteWithRelations });
        } else {
          await deleteFunction(ids);
        }
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      setDeletionState((prev) => ({
        status: DELETION_STATUS.ERROR,
        progress: {
          ...prev.progress,
          errorDetails: [...prev.progress.errorDetails, error.message],
        },
      }));
      setIsLoading(false);
    }
  };

  const getProgressBarColor = () => {
    switch (deletionState.status) {
      case DELETION_STATUS.COMPLETED:
        return "success";
      case DELETION_STATUS.ERROR:
        return "error";
      case DELETION_STATUS.CANCELED:
        return "warning";
      default:
        return "primary";
    }
  };

  const getStatusMessage = () => {
    const { status, progress } = deletionState;
    const { deleted, total, errors } = progress;

    switch (status) {
      case DELETION_STATUS.IN_PROGRESS:
        return `Progress: ${deleted}/${total} (${Math.round(
          (deleted / total) * 100
        )}%)`;
      case DELETION_STATUS.COMPLETED:
        return `Completed: ${deleted} items deleted${
          errors ? `, ${errors} errors` : ""
        }`;
      case DELETION_STATUS.CANCELED:
        return `Canceled: ${deleted}/${total} items processed`;
      case DELETION_STATUS.ERROR:
        return `Error: ${progress.errorDetails.join(", ")}`;
      default:
        return "";
    }
  };

  const ProgressBar = () => {
    const { status, progress } = deletionState;
    if (status === DELETION_STATUS.IDLE) return null;

    const value = (progress.deleted / progress.total) * 100;

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              color={getProgressBarColor()}
              variant="determinate"
              value={value}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography>{`${Math.round(value)}%`}</Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          color={status === DELETION_STATUS.ERROR ? "error" : "text.secondary"}
        >
          {getStatusMessage()}
        </Typography>
      </Box>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <section>
        <p style={{ fontSize: "2rem" }}>
          Are you sure you want to delete all filtered items?
        </p>
        <section>
          {typeOfTable === TYPE_ELEMENT.OBJECT && (
            <div
              style={{
                marginTop: "1rem",
                paddingLeft: "1rem",
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <input
                id="delete-relations"
                name="delete-relations"
                type="checkbox"
                onChange={() => setDeleteWithRelations(!deleteWithRelations)}
                checked={deleteWithRelations}
              />
              <label htmlFor="delete-relations">Delete all relations?</label>
            </div>
          )}
        </section>
        <ProgressBar />
      </section>
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CustomButton
          text="Delete filtered data"
          onClick={handleDeleteData}
          primaryColor={DELETE_COLOR}
          secondaryColor={HOVER_DELETE_COLOR}
          margin="0"
          isLoad={isLoading}
          disabled={deletionState.status === DELETION_STATUS.IN_PROGRESS}
        />
        <CustomButton
          text={
            deletionState.status === DELETION_STATUS.IN_PROGRESS
              ? "Cancel"
              : "Close"
          }
          onClick={
            deletionState.status === DELETION_STATUS.IN_PROGRESS
              ? handleCancelDelete
              : () => setOpenBulkDelete(false)
          }
          margin="0 10"
          isLoad={isLoadingCancel}
        />
      </div>
    </div>
  );
};
