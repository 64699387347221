import { useCallback, useEffect, useState } from "react";
import {
  DynamicColorsContainer,
  DynamicColorsFields,
  LibraryField,
  MappingLibraryContainer,
} from "./DynamicPointsColorStyles";
import { updateBackendMapSettings } from "services/updateBackendMapSettings";
import { setMapSettings } from "redux/actions/";
import { useDispatch } from "react-redux";

const pointTypesColors = [
  ["object", "#ff5f7e"],
  ["component", "#251351"],
  ["event", "#810955"],
];
export default function SelectDynamicPointsColor({
  configData,
  setConfigData,
}) {
  // campos por tipo de libreria (objs/evts/cmps)
  const [fieldsByLibraryObjects, setFieldsByLibraryObjects] = useState([]);
  const [fieldsByLibraryComponents, setFieldsByLibraryComponents] = useState(
    []
  );
  const [fieldsByLibraryEvents, setFieldsByLibraryEvents] = useState([]);
  // librerias por objs/evts/cmps
  const [pointLibrariesObjects, setPointLibrariesObjects] = useState([]);
  const [pointLibrariesComponents, setPointLibrariesComponents] = useState([]);
  const [pointLibrariesEvents, setPointLibrariesEvents] = useState([]);
  // campos dinamicos seleccionados por libreria por objs/evts/cmps
  const [optionsSelectedObjects, setOptionsSelectedObjects] = useState([
    [0, "pointType", "object"],
  ]);

  const [optionsSelectedComponents, setOptionsSelectedComponents] = useState([
    [0, "pointType", "component"],
  ]);
  const [optionsSelectedEvents, setOptionsSelectedEvents] = useState([
    [0, "pointType", "event"],
  ]);

  const dispatch = useDispatch();

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = parseInt(localStorage.getItem("userId"));
  const urlQuery = new URLSearchParams();
  urlQuery.append("adminCompanyId", adminCompanyId);

  // configuracion de las opciones seleccionadas de objs/evts/cmps
  useEffect(() => {
    if (configData !== undefined) {
      if (
        configData.objectsColorsSelected !== undefined &&
        configData.objectsColorsSelected.length > 0
      ) {
        setOptionsSelectedObjects(configData.objectsColorsSelected);
      }
      if (
        configData.componentsColorsSelected !== undefined &&
        configData.componentsColorsSelected.length > 0
      ) {
        setOptionsSelectedComponents(configData.componentsColorsSelected);
      }
      if (
        configData.eventsColorsSelected !== undefined &&
        configData.eventsColorsSelected.length > 0
      ) {
        setOptionsSelectedEvents(configData.eventsColorsSelected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setear las librerias de objs/cmps/evts
  useEffect(() => {
    if (
      configData?.librariesObjects &&
      configData?.librariesObjects?.length >= 0
    ) {
      setPointLibrariesObjects(configData?.librariesObjects);
    }
    if (
      configData?.librariesComponents &&
      configData?.librariesComponents?.length >= 0
    ) {
      setPointLibrariesComponents(configData?.librariesComponents);
    }
    if (
      configData?.librariesEvents &&
      configData?.librariesEvents?.length >= 0
    ) {
      setPointLibrariesEvents(configData?.librariesEvents);
    }
  }, [
    configData?.librariesObjects,
    configData?.librariesComponents,
    configData?.librariesEvents,
  ]);

  // set fields (number, select) by libraries
  useEffect(() => {
    const auxObjects = [];
    pointLibrariesObjects.forEach((ln) => {
      auxObjects.push(parseLibrary(ln, ["select", "number"]));
    });
    setFieldsByLibraryObjects(auxObjects);

    const auxComponents = [];
    pointLibrariesComponents.forEach((ln) => {
      auxComponents.push(parseLibrary(ln, ["select", "number"]));
    });
    setFieldsByLibraryComponents(auxComponents);

    const auxEvents = [];
    pointLibrariesEvents.forEach((ln) => {
      auxEvents.push(parseLibrary(ln, ["select", "number"]));
    });
    setFieldsByLibraryEvents(auxEvents);
  }, [pointLibrariesObjects, pointLibrariesComponents, pointLibrariesEvents]);

  // updated selected fields on database (MG)
  useEffect(() => {
    const updated = {
      ...configData,
      objectsColorsSelected: optionsSelectedObjects,
      componentsColorsSelected: optionsSelectedComponents,
      eventsColorsSelected: optionsSelectedEvents,
    };
    const libs = {
      fieldsByLibraryObjects,
      fieldsByLibraryComponents,
      fieldsByLibraryEvents,
    };
    const updateSelectedPointColors = async () => {
      if (
        !optionsSelectedObjects.length &&
        !optionsSelectedComponents.length &&
        !optionsSelectedEvents.length
      )
        return;
      await updateBackendMapSettings({
        id: userId,
        body: {
          objectsColorsSelected: optionsSelectedObjects,
          componentsColorsSelected: optionsSelectedComponents,
          eventsColorsSelected: optionsSelectedEvents,
        },
      });
    };
    updateSelectedPointColors();
    setConfigData(updated);
    // set state for options selected
    dispatch(
      setMapSettings({
        ...updated,
        ...libs,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    optionsSelectedObjects,
    optionsSelectedComponents,
    optionsSelectedEvents,
    fieldsByLibraryObjects,
    fieldsByLibraryComponents,
    fieldsByLibraryEvents,
  ]);

  // selects only one option per Object libraries
  const handleSelectOption = (libId, fdName, libType) => {
    let optionsSelected = [];
    let setOptionSelected = () => {};

    if (libType === "object") {
      optionsSelected = optionsSelectedObjects;
      setOptionSelected = setOptionsSelectedObjects;
    } else if (libType === "component") {
      optionsSelected = optionsSelectedComponents;
      setOptionSelected = setOptionsSelectedComponents;
    } else if (libType === "event") {
      optionsSelected = optionsSelectedEvents;
      setOptionSelected = setOptionsSelectedEvents;
    } else {
      optionsSelected = [];
      setOptionSelected = () => {};
    }

    const options = optionsSelected
      .map((it) => (it[0] === libId ? [it[0], fdName, libType] : it))
      .map((it) => JSON.stringify(it));
    // opciones parseadas a String para hacer un conjunto sin elementos repetidos
    setOptionSelected([...new Set(options)].map((it) => JSON.parse(it)));
  };

  const checkChecked = useCallback(
    (id, name, libType) => {
      let optionsSelected = [];
      if (libType === "object") {
        optionsSelected = optionsSelectedObjects; // [ [1, "pointType", "object"], [2, "numuno", "object"] ]
      } else if (libType === "component") {
        optionsSelected = optionsSelectedComponents;
      } else if (libType === "event") {
        optionsSelected = optionsSelectedEvents;
      } else {
        optionsSelected = [];
      }
      return optionsSelected.find((it) => it[0] === id && it[1] === name)
        ? true
        : false;
    },
    [optionsSelectedObjects, optionsSelectedComponents, optionsSelectedEvents]
  );

  return (
    <DynamicColorsContainer>
      <section>
        <p>
          <b>Dynamic Points Color by Category</b>
        </p>
      </section>
      <br />
      <DynamicColorsFields>
        <section>
          <p>
            <b>Objects</b>
          </p>
          <MapPointLibrary
            fieldsByLibrary={fieldsByLibraryObjects}
            checkChecked={checkChecked}
            handleSelectOption={handleSelectOption}
            arrTypes={pointTypesColors}
            libType="object"
          />
        </section>
        <section>
          <p>
            <b>Components</b>
          </p>
          <MapPointLibrary
            fieldsByLibrary={fieldsByLibraryComponents}
            checkChecked={checkChecked}
            handleSelectOption={handleSelectOption}
            arrTypes={pointTypesColors}
            libType="component"
          />
        </section>
        <section>
          <p>
            <b>Events</b>
          </p>
          <MapPointLibrary
            fieldsByLibrary={fieldsByLibraryEvents}
            checkChecked={checkChecked}
            handleSelectOption={handleSelectOption}
            arrTypes={pointTypesColors}
            libType="event"
          />
        </section>
      </DynamicColorsFields>
    </DynamicColorsContainer>
  );
}
// mapea la estructura general de las librerias para objs/evts/cmps
function MapPointLibrary({
  fieldsByLibrary = [],
  checkChecked = () => {},
  handleSelectOption = () => {},
  arrTypes = [],
  libType = "",
}) {
  return (
    <MappingLibraryContainer>
      {fieldsByLibrary?.length >= 0 &&
        fieldsByLibrary?.map((ln) => {
          return (
            <div>
              <div className="library-name-container">
                <div
                  style={{
                    width: "50px",
                  }}
                >
                  Library:
                </div>{" "}
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {ln.name}
                </div>
              </div>
              <div>
                <LibraryField>
                  <div className="library-names">
                    <input
                      type="checkbox"
                      value={ln.id}
                      checked={checkChecked(ln.id, "pointType", libType)}
                      onChange={() =>
                        handleSelectOption(ln.id, "pointType", libType)
                      }
                    />
                    <p>Point Type</p>
                  </div>
                  <div
                    className="color-container"
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    {arrTypes.length >= 0 &&
                      arrTypes
                        .filter((tp) => tp[0] === libType)
                        .map((item) => (
                          <div className="color-item">
                            <input type="color" value={item[1]} disabled />
                            <p>{item[0]}</p>
                          </div>
                        ))}
                  </div>
                </LibraryField>
                {ln.fields.map((fd, indx) => {
                  // list of dynamic fields type select with circuit (only one)
                  return (
                    <ColorOptionElement
                      checkChecked={checkChecked}
                      handleSelectOption={handleSelectOption}
                      lib={ln}
                      field={fd}
                      optionIndex={indx}
                      key={indx}
                      libType={libType}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
    </MappingLibraryContainer>
  );
}

function ColorOptionElement({
  optionIndex,
  lib,
  field,
  checkChecked,
  handleSelectOption,
  libType,
}) {
  return (
    <LibraryField>
      <div className="library-names">
        {field.type === "select" && (
          <input
            type="checkbox"
            value={field.optionsColors.at(optionIndex)}
            checked={checkChecked(lib.id, field.name, libType)}
            onChange={() => handleSelectOption(lib.id, field.name, libType)}
          />
        )}
        {field.type === "number" && (
          <input
            type="checkbox"
            value={field.numberGroupingOptions.restrictions.at(optionIndex)}
            checked={checkChecked(lib.id, field.name, libType)}
            onChange={() => handleSelectOption(lib.id, field.name, libType)}
          />
        )}
        <p>{field.alias}</p>
      </div>
      <div className="color-container">
        {field.type === "select" &&
          field.optionsColors.length !== 0 &&
          field.optionsColors.map((cl, indx) => (
            <div className="color-item">
              <input type="color" value={cl} disabled />
              <p>{field.options[indx]}</p>
            </div>
          ))}
        {field.type === "number" &&
          field.numberGroupingOptions.restrictions.length !== 0 &&
          field.numberGroupingOptions.restrictions.map((rt, indx) => {
            if (!rt.state) return <></>;
            return (
              <div className="color-item">
                <input type="color" value={rt.color} disabled />
                <p>{rt.label}</p>
                {rt.symbol === "><" || rt.symbol === ">=<=" ? (
                  <p>
                    {rt.valueMin} - {rt.valueMax}
                  </p>
                ) : (
                  <p>{rt.value}</p>
                )}
              </div>
            );
          })}
      </div>
    </LibraryField>
  );
}

function parseLibrary(item, types = []) {
  if (item === undefined || !types.length) return null;
  const fields =
    item.fields !== undefined
      ? item.fields.filter((fd) => types.includes(fd.type) && fd.circuit)
      : [];
  return {
    id: item.lineLibraryId || item.id,
    name: item.name,
    fields,
  };
}
