import { isDate } from "helpers/isDate";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  defaultFieldsObjects,
  defaultFieldsComponents,
  defaultFieldsEvents,
} from "utils/defaultColumns";
function updateColumnsWithValues({
  columnsValues,
  columnsLibrary,
  columnSelected,
}) {
  const matchingLibraryColumn = columnsLibrary.find(
    (libColumn) =>
      libColumn.name === columnSelected.name && libColumn.type === "select"
  );

  if (matchingLibraryColumn) {
    return matchingLibraryColumn.options.map((elm) => {
      return {
        value: elm,
        label: elm,
      };
    });
  }

  return columnsValues.map((elm) => {
    return {
      value: elm,
      label: elm,
    };
  });
}

export const useSelectAllFieldsColumn = ({
  columnsLibrary,
  columnSelected,
  typeElementNames,
  handlerSelectColumn,
  setSelectedOptions,
  data,
}) => {
  const [selectedColumn, setSelectedColumn] = useState(columnSelected?.name);

  const allOptions = useMemo(() => {
    if (!selectedColumn) return [];
    let uniqueValues = [];

    if (
      selectedColumn === defaultFieldsObjects[1].name ||
      selectedColumn === defaultFieldsComponents[0].name ||
      selectedColumn === defaultFieldsEvents[0].name
    ) {
      uniqueValues = typeElementNames;
    } else {
      const valuesObjects = data
        .map((item) => {
          const attribute = item.attributes.find(
            (attr) => attr.name === selectedColumn
          );
          return attribute;
        })
        .filter(Boolean);

      uniqueValues = [
        ...new Set(
          valuesObjects.map((item) => {
            try {
              const value = JSON.parse(item.value);
              return value.hasOwnProperty("value") ? value.value : item.value;
            } catch {
              if (isDate(item.value)) {
                return item.value.split(" ")[0];
              }
              return item.value;
            }
          })
        ),
      ];
    }

    const newColumnsWithValues = updateColumnsWithValues({
      columnSelected: selectedColumn,
      columnsValues: uniqueValues,
      columnsLibrary,
    });

    return newColumnsWithValues;
  }, [data, selectedColumn, typeElementNames]);

  useEffect(() => {
    if (selectedColumn) {
      const formatValues = new Set();
      for (const item of allOptions) {
        formatValues.add(item.value);
      }
      setSelectedOptions(allOptions);
      handlerSelectColumn({
        name: selectedColumn,
        values: Array.from(formatValues),
      });
    }
  }, [selectedColumn, allOptions]);

  const handlerSelectedColumn = useCallback(
    (columnName) => {
      if (selectedColumn === columnName) {
        setSelectedColumn(null);
        handlerSelectColumn(null);
        setSelectedOptions([]);
      } else {
        setSelectedColumn(columnName);
        handlerSelectColumn({ name: columnName });
      }
    },
    [handlerSelectColumn]
  );

  const handleSelectChange = (newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions || []);
    const formatValues = new Set();
    for (const item of newSelectedOptions) {
      formatValues.add(item.value);
    }
    handlerSelectColumn({
      name: selectedColumn,
      values: Array.from(formatValues),
    });
  };

  return {
    handlerSelectedColumn,
    handleSelectChange,
    allOptions,
  };
};
