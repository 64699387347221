/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { Container } from "./StyleContentDialog";
import CustomSelect from "components/Buttons/CustomSelect";
import CustomButton from "components/Buttons/CustomButton";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";
import { config } from "config.js";
import FetchEvents from "hooks/fetchEvents";
import updatePointEvent from "services/updatePointEvent";
import { urls } from "utils/urlKeys";

const ContentConvertEvent = ({ pointEvent, setOpenDialog }) => {
  const [isLoad, setIsLoad] = useState(false);
  const [select, setSelect] = useState();
  const [icon, setIcon] = useState("");

  const { mutate } = useSWRConfig();

  const pointLibraryEventId = pointEvent.pointTypeEvent.pointLibraryEventId;
  const { data: typeEvents, error: errorTypeEvents } =
    FetchEvents(pointLibraryEventId);

  useEffect(() => {
    if (icon === "" || icon === undefined) {
      setIcon(typeEvents?.[0].icon);
      setSelect(typeEvents?.[0].id);
    }
  }, [typeEvents]);

  useEffect(() => {
    const typeEvent = typeEvents?.find(
      (typeEvent) => typeEvent.id === parseInt(select)
    );
    setIcon(typeEvent?.icon);
  }, [select]);

  const convertEvent = async () => {
    let body = {
      pointTypeEventId: Number(select),
      updatedAt: new Date(),
    };
    setIsLoad(true);
    await updatePointEvent({ id: pointEvent.id, body: body });
    setIsLoad(false);
    setOpenDialog(false);
    mutate(urls.events.detail.objects(pointEvent?.id));
    mutate(urls.events.detail.components(pointEvent?.id));
    mutate(urls.events.detail.events(pointEvent?.id));
  };

  return (
    <>
      {!errorTypeEvents && (
        <Container>
          <h2 className="title">
            <span>Library: </span>
            {typeEvents?.[0]?.pointLibraryEvent?.name}
          </h2>
          <CustomSelect
            options={typeEvents
              ?.slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                };
              })}
            label={"Event type"}
            select={select}
            setSelect={setSelect}
          />
          {icon && (
            <figure className="icon">
              <img src={icon} alt="icon of event" />
            </figure>
          )}
          <CustomButton
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECOND_COLOR}
            text={"CONVERT"}
            onClick={convertEvent}
            isLoad={isLoad}
            margin={0}
          />
        </Container>
      )}
    </>
  );
};

export default ContentConvertEvent;
