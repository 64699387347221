import useSwr from "swr";
import { urls } from "utils/urlKeys";

/**
 * Fetches components by object ID.
 *
 * @param {string} objectId - The ID of the object to fetch components for.
 * @return {object} - An object {data, error} containing the fetched data and any errors that occurred.
 */
const FetchComponentsByObjectId = (objectId) => {
  const { data, error } = useSwr(urls.components.object(objectId));
  return { data, error };
};

export default FetchComponentsByObjectId;
