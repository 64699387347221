import { useState, useEffect, useCallback } from "react";
import { getInfoBucketUser } from "services/users/infoBucketUser";
// Global Store
import { useStoreBucketUser } from "zustandGloabalStore/bucketRoot";
import { useUserStore } from "zustandGloabalStore/user";

/**
 * Custom hook for fetching and managing bucket root configuration
 * @returns {Object} Hook API containing loading state, error, and refresh capability
 * @property {boolean} isLoading - Data fetching status
 * @property {Error|null} error - Error object if request fails
 * @property {function} refresh - Function to manually reload data
 *
 * @example
 * // Component usage:
 * const { isLoading, error, refresh } = useBucketUser();
 *
 * if (isLoading) return <Loader />;
 * if (error) return <ErrorDisplay message={error.message} />;
 *
 * return (
 *   <button onClick={refresh}>Reload Data</button>
 * );
 */
export const useBucketUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const setBucketUser = useStoreBucketUser((state) => state.setBucketUser);
  const { userId, instanceId, adminDecimetrixId } = useUserStore(
    (state) => state
  );
  /**
   * Fetches bucket configuration data from API
   * @param {string} userId - User identifier from localStorage
   * @returns {Promise<void>} No return value, updates state directly
   */
  const fetchBucketData = useCallback(
    async (userId) => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await getInfoBucketUser({ userId });
        const dataInstance = data.find(
          (item) =>
            item.instanceId === instanceId ||
            item.decimetrixId === adminDecimetrixId
        );
        setBucketUser(dataInstance);
      } catch (err) {
        setError(err);
        console.error("Bucket root fetch failed:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [setBucketUser, instanceId]
  );

  // Automatic fetch on mount
  useEffect(() => {
    if (userId) {
      fetchBucketData(userId);
    } else {
      console.error("No user ID found in storage");
      setError(new Error("No user ID found in storage"));
    }
  }, [fetchBucketData, userId]);

  /**
   * Manual refresh handler
   * @returns {Promise<void>} Refresh operation promise
   */
  const handleRefresh = useCallback(async () => {
    const userId = localStorage.getItem("userId");
    await fetchBucketData(userId);
  }, [fetchBucketData]);

  return {
    isLoading,
    error,
    refresh: handleRefresh,
  };
};
