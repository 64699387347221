import axios from "axios";
import { urls } from "utils/urlKeys";
const saveOperator = async (body) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(urls.operators.all, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return res;
};

export default saveOperator;
