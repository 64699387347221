import axios from "axios";
import { urls } from "utils/urlKeys";
const deleteOperator = async (id, instanceId) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(urls.users.deleteOperator(id, instanceId), {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await res.data;
  return { data, res };
};

export default deleteOperator;
