import styled from "styled-components";
import {
  BACKGROUND_DARK,
  BACKGROUND_GREY,
  BLACK_COLOR,
  BORDER_DARK,
  BORDER_GREY,
  PRIMARY_COLOR,
  WHITE_BACKGROUND,
  WHITE_COLOR,
} from "utils/const";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const CustomizedChevronLeftIcon = styled(ChevronLeftIcon)`
  background-color: ${PRIMARY_COLOR};
  border-radius: 23px;
  font-size: 30px;
`;

export const ContainerOperations = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 10px;
  /* background-color: #f0f0f0; */

  .table-container .operation-title {
    display: flex;
    color: ${PRIMARY_COLOR};
    margin: 10px 0px 0px 0px;
    flex-direction: column;
    height: 5rem;
  }
  .current-object {
    display: flex;
    justify-content: ${(props) =>
      props.seeArrow ? "space-between" : "center"};
    align-items: center;
    background-color: ${BACKGROUND_GREY};
    padding: 0.5rem 1rem;
    border-radius: 10px;

    span {
      font-size: 1.6rem;
      background: ${PRIMARY_COLOR};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border-radius: 10px;
      color: ${WHITE_COLOR};
      cursor: pointer;
    }

    span.disabled {
      background: #ccc;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .map-container,
  .container-operation-galery,
  .c3,
  .c4,
  .c5,
  .c6 {
    margin: 0.3%;
    width: 100%;
    min-width: 150px;
    max-height: 500px;
    background-color: ${WHITE_COLOR};
    border-radius: 5px;
  }

  .table-container {
    margin: 10px 10px 10px 0px;
    width: 100%;
    min-width: 99.7%;
    max-height: 500px;
    background-color: ${WHITE_COLOR};
    border-radius: 5px;
  }

  .table-container .event-title {
    display: flex;
    margin: 10px 0px 0px 0px;
  }
  .table-container .event-title h1 {
    font-size: 1.5rem;
  }

  .table-container .operation-table {
    padding: 24px 24px 24px 24px;
  }

  .operation-type span {
    color: ${PRIMARY_COLOR};
  }

  .info-event {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    background-color: ${WHITE_COLOR};
    align-items: center;
    height: 680px;
    width: 28.3vw;
    margin: 10px 16px 20px 0px;
    border-radius: 5px;
    overflow-y: scroll;
    .wrapper-buttons {
      justify-content: center;
    }
  }
  .bb {
    background-color: ${WHITE_BACKGROUND};
    border: solid 1px ${BORDER_GREY};
  }

  .bb2 {
    background-color: ${BACKGROUND_DARK};
    border: solid 1px ${BORDER_DARK};
  }
  @media (max-width: 1279px) {
    .info-event {
      width: 100%;
    }
  }

  @media (min-width: 1280px) and (max-width: 1620px) {
    .info-event {
      width: 96.3%;
    }
  }

  @media (min-width: 1621px) {
    .info-event {
      width: 97%;
    }
  }

  
  .link-style {
    display: inline-block;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
    transition: color 0.3s ease;
    max-width: 250px;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .link-style:hover {
    color: darkblue;
  }

  @media (max-width: 820px) {
    .link-style {
      max-width: 300px;
    }
  }

  @media (min-width: 821px) and (max-width: 1280px) {
    .link-style {
      max-width: 700px;
    }
  }

  @media (min-width: 1280px) and (max-width: 1320px) {
    .link-style {
      max-width: 110px;
    }
  }

  @media (min-width: 1320px) and (max-width: 1420px) {
    .link-style {
      max-width: 130px;
    }
  }

  @media (min-width: 1420px) and (max-width: 1620px) {
    .link-style {
      max-width: 180px;
    }
  }

  @media (min-width: 1620px) and (max-width: 1750px) {
    .link-style {
      max-width: 210px;
    }
  }

  @media (min-width: 1751px) {
    .link-style {
      max-width: 250px;
    }
  }

  .photo-container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 57.7vw;
    height: 680px;
    /* background-color: ${WHITE_COLOR}; */
    margin: 10px 10px 10px 10px;
    border-radius: 5px;
  }

  @media (max-width: 1279px) {
    .photo-container-image {
      width: 100%;
      margin-left: 0px;
    }
  }

  @media (min-width: 1280px) and (max-width: 1620px) {
    .photo-container-image {
      width: 98.7%;
    }
  }

  @media (min-width: 1621px) {
    .photo-container-image {
      width: 99%;
    }
  }

  .event-title {
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
  }

  .event-title span {
    color: ${PRIMARY_COLOR};
    align-self: center;
    text-align: center;
    padding: 0 0 1rem 1rem;
  }

  .operation-details {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .details-item {
    padding: 0 10px;
    font-size: 1.4rem;
    padding: 5px 10px;
    border-radius: 0;
    color: ${BLACK_COLOR};
  }
  .details-item span {
    color: #595959;
  }

  .wrapper-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
  }

  .button-load {
    height: 50px;
    width: 50%;
    border: none;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
  }

  .content-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 550px;

    /* padding-bottom: 10px; */
    /* background-color: ${BACKGROUND_GREY}; */
    margin: 10px 0px 10px 0px;
    border-radius: 5px;
  }

  .map-container {
    height: 100%;
  }

  .photo-container-image .gallery {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    overflow: hidden;
  }

  .photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 550px;
    width: 28.3vw;
    border-radius: 5px;
    /* background-color: ${WHITE_COLOR}; */
  }

  @media (max-width: 1279px) {
    .photo-container {
      width: 100%;
      margin-left: 0px;
    }
  }

  @media (min-width: 1280px) and (max-width: 1620px) {
    .photo-container {
      width: 100%;
    }
  }

  @media (min-width: 1621px) {
    .photo-container {
      width: 100%;
    }
  }

  .photo-container img {
    width: 85%;
    height: 400px;
    object-fit: cover;
  }

  .leak-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 95.5%;
    margin: 10px 0px 20px 0px;
    border-radius: 5px;
    /* background-color: ${WHITE_COLOR}; */
  }

  .leak-container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 650px;
    /* background-color: ${WHITE_COLOR}; */
    margin: 10px 0px 0px 0px;
    border-radius: 5px;
  }

  .leak-container-image .gallery {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    overflow: hidden;
  }

  .leak-container .event-title h1 {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    font-size: 1.5rem;
  }

  .photo-container .event-title h1 {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    font-size: 1.5rem;
  }

  .c3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 1000px) {
    .article {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media screen and (min-width: 666px) {
    .photo-container-image .gallery {
      width: 80%;
    }
    .leak-container-image .gallery {
      width: 80%;
    }
  }
  @media screen and (min-width: 850px) {
    .container-operation-galery,
    .c3 {
      width: 49.4%;
    }
    .c4,
    .c5 {
      width: 49.4%;
    }
    .photo-container-image .gallery {
      width: 60%;
    }
    .leak-container-image .gallery {
      width: 60%;
    }
  }

  @media screen and (min-width: 1100px) {
    .map-container {
      width: 59.8%;
    }
    .table-container {
      width: 100%;
    }
    .container-operation-galery {
      width: 39%;
    }
    .c3,
    .c4 {
      width: 33%;
    }
    .c5 {
      width: 32.1%;
    }
    .c6 {
      width: 100%;
    }
    .photo-container-image .gallery {
      width: 50%;
    }
    .leak-container-image .gallery {
      width: 50%;
    }
  }
`;

export const Wrapperbtn = styled("div")`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  min-width: 230px;
  max-width: 380px;
  margin: 0 auto;
`;
