// React
import { useEffect, useState } from "react";
// Redux
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
// Dependecies
import useSwr from "swr";
import { useSWRConfig } from "swr";
// Components
import {
  ContainerOperations,
  CustomizedChevronLeftIcon,
} from "components/ElementDetails/EventDetails/EventDetailsElements";
import OpenDialog from "../../Dialogs/OpenDialog";
import ObjectDetails from "../ObjectDetails/ObjectDetails";
import TableComponent from "./TableComponent";
import ComponentMap from "components/Maps/ComponentMap";
import PhotoGallery from "../PhotoGalley/PhotoGallery";
import AddPicture from "./AddPicture/AddPicture";
import EventHistory from "./../EventHistory";
// Services
import findObjects from "services/objects/findObjects";
// Hooks
import useCreateComponentEvents from "hooks/useCreateComponentEvents";
import useMediaElement from "hooks/useMediaElement";
// Style
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { InfoTitle } from "../ImageDetailsElements";
// Config
import { config } from "config.js";
import {
  BACKGROUND_SECOND_DARK,
  BACKGROUND_GREY,
  INFORMATION_COLOR,
  WHITE_COLOR,
} from "utils/const";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import findLibraryComponents from "services/findLibraryComponents";
import DetailTypeIformation from "../DetailTypeInformation";
// import Styles from "../../../Styles";
import { useContext } from "react";
import { ThemeContext } from "App";
import EventHistoryNoLocation from "../EventHistoryNoLocation";

const ComponentDetails = ({ componentId, fromObject, currentObjectId }) => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const { theme } = useContext(ThemeContext);
  const [errorMessage, setErrorMessage] = useState(false);

  const [objectGHG, setObjectGHG] = useState(false);
  const [componentLibrary, setComponentLibrary] = useState([]);
  const [movePoint, setMovePoint] = useState(false);

  const urlData = `${config.URL_BACKEND_PG}api/v1/point-components?id=${componentId}`;
  const { data: component, error: errorComponent } = useSwr(urlData);

  const { mediaObject, widthMap } = useMediaElement({
    element: component?.[0],
  });

  const urlDataCountEvents = `${config.URL_BACKEND_PG}api/v1/point-components?countEvents=${componentId}`;
  const { data: countEvents, error: errorCountEvents } =
    useSwr(urlDataCountEvents);

  // Get object the current component
  useEffect(() => {
    const getObject = async () => {
      const objectId = component[0]?.objectId;
      const data = await findObjects(objectId);
      const dataLibrary = await findLibraryComponents(
        component[0]?.pointTypeComponent.pointLibraryComponentId
      );
      setComponentLibrary(dataLibrary);
      setObjectGHG(data);
    };
    component && getObject();
  }, [component, movePoint]);

  const returnToObject = () => {
    const object = {
      content: <ObjectDetails objectId={objectGHG.id} />,
      title: "Object Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
  };

  const uploadImages = () => {
    mutate(`${config.URL_BACKEND_PG}api/v1/point-components?id=${componentId}`);
  };

  const { eventRows, seePointEventsTable, markedEvent } =
    useCreateComponentEvents({ componentId });
  return (
    <>
      {component && !errorComponent && (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            position: "relative",
          }}
        >
          {fromObject && (
            <IconButton
              onClick={returnToObject}
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          <OpenDialog
            openDialog={errorMessage}
            setOpenDialog={setErrorMessage}
            content={{
              title: "Conexion lost",
              description: "Please check your internet conexion and try again.",
              disagree: "Continue",
            }}
          />
          <ContainerOperations
            className={`${
              theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK
            }`}
          >
            {/* --COMPONENT DETAILS-- */}
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div
                // className="info-event"
                className={`info-event ${theme === "light" ? "bb" : "bb2"}`}
              >
                <DetailTypeIformation
                  typeName={component[0]?.pointTypeComponent?.name}
                  typeLibrary={componentLibrary[0]?.name}
                  iconLibrary={componentLibrary[0]?.icon}
                  icon={component[0]?.pointTypeComponent?.icon}
                  type={"Component"}
                />
                <InfoTitle>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <section className="main-tables-container">
                      {/* Component Details */}
                      <TableComponent
                        component={component[0]}
                        deleteButton={
                          countEvents?.eventCount.length > 0 ? false : true
                        }
                      />
                      <div className="main-currency-table">
                        {countEvents?.eventCount.length > 0 && (
                          <p
                            className="currency-table--title"
                            style={{
                              color:
                                theme === "light"
                                  ? "var(--background-primary_color)"
                                  : WHITE_COLOR,
                            }}
                          >
                            Events
                          </p>
                        )}
                        <div className="currency-table--container">
                          <table>
                            {!errorCountEvents &&
                              countEvents &&
                              countEvents?.eventCount
                                .filter(
                                  (it) =>
                                    it.quantity > 0 && it.quantity !== undefined
                                )
                                .map((it) => {
                                  return (
                                    <tr
                                      style={{
                                        background:
                                          theme === "light"
                                            ? BACKGROUND_GREY
                                            : BACKGROUND_SECOND_DARK,
                                        color:
                                          theme === "light"
                                            ? document.body.style.color
                                            : WHITE_COLOR,
                                      }}
                                    >
                                      <td className="table__bottom-left">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {it.typeName}
                                          <SimpleToolTip
                                            title={`Library: ${it.libName}`}
                                            placement="right"
                                          >
                                            <LocalLibraryIcon
                                              sx={{
                                                fontSize: "1.8rem",
                                                color: INFORMATION_COLOR,
                                              }}
                                            />
                                          </SimpleToolTip>
                                        </div>
                                      </td>
                                      <td className="table__bottom-right table__right">
                                        {it.quantity}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </table>
                        </div>

                        {countEvents?.eventCount.length > 0 && (
                          <p
                            className="currency-table--title"
                            style={{
                              color:
                                theme === "light"
                                  ? "var(--background-primary_color)"
                                  : WHITE_COLOR,
                            }}
                          >
                            Non-Georeferenced Event
                          </p>
                        )}
                        <div className="currency-table--container">
                          <table>
                            {!errorCountEvents &&
                              countEvents &&
                              countEvents?.eventCount
                                .filter(
                                  (it) =>
                                    it.quantityNoGeographic > 0 &&
                                    it.quantityNoGeographic !== undefined
                                )
                                .map((it) => {
                                  return (
                                    <tr
                                      style={{
                                        background:
                                          theme === "light"
                                            ? BACKGROUND_GREY
                                            : BACKGROUND_SECOND_DARK,
                                        color:
                                          theme === "light"
                                            ? document.body.style.color
                                            : WHITE_COLOR,
                                      }}
                                    >
                                      <td className="table__bottom-left">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {it.typeName}
                                          <SimpleToolTip
                                            title={`Library: ${it.libName}`}
                                            placement="right"
                                          >
                                            <LocalLibraryIcon
                                              sx={{
                                                fontSize: "1.8rem",
                                                color: INFORMATION_COLOR,
                                              }}
                                            />
                                          </SimpleToolTip>
                                        </div>
                                      </td>
                                      <td className="table__bottom-right table__right">
                                        {it.quantityNoGeographic}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </table>
                        </div>
                      </div>
                    </section>
                  </Grid>
                </InfoTitle>
              </div>
            </Grid>

            {/* --COMPONENT GALLERY-- */}

            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div
                className={`photo-container-image ${
                  theme === "light" ? "bb" : "bb2"
                }`}
              >
                <h1
                  className="event-title"
                  style={{
                    color:
                      theme === "light"
                        ? "var(--background-primary_color)"
                        : WHITE_COLOR,
                  }}
                >
                  <span>GALLERY</span>
                </h1>
                <div
                  className="gallery"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 24px 0px 20px",
                  }}
                >
                  {component[0]?.componentFiles.length > 0 ||
                  component[0]?.media?.length > 0 ? (
                    <>
                      <p
                        style={{
                          fontSize: "1.6rem",
                          color:
                            theme === "light"
                              ? "var(--background-primary_color)"
                              : WHITE_COLOR,
                        }}
                      >
                        <b>Total Pictures: </b>{" "}
                        {
                          [
                            ...component[0]?.componentFiles,
                            ...component[0]?.media,
                          ]?.length
                        }
                      </p>
                      <br />
                      <PhotoGallery
                        photos={[
                          ...component[0]?.componentFiles,
                          ...component[0]?.media,
                        ]}
                        ownId={component[0]?.id}
                        mongoId={component[0]?.mongoId}
                        type={"component"}
                        uploadImages={uploadImages}
                      />
                      <AddPicture
                        pointComponent={component[0]}
                        uploadImages={uploadImages}
                      />
                    </>
                  ) : (
                    <AddPicture
                      pointComponent={component[0]}
                      uploadImages={uploadImages}
                    />
                  )}
                </div>
              </div>
            </Grid>

            {/* GALLERIES BY TYPE  */}
            <Grid
              container
              spacing={3}
              justifyContent="flex-start"
              alignItems="center"
            >
              {mediaObject &&
                mediaObject.map((mediaGroup) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      key={mediaGroup[0].alias}
                    >
                      <div
                        // className="photo-container"
                        className={`photo-container ${
                          theme === "light" ? "bb" : "bb2"
                        }`}
                      >
                        <h1
                          className="event-title"
                          style={{
                            color:
                              theme === "light"
                                ? "var(--background-primary_color)"
                                : WHITE_COLOR,
                          }}
                        >
                          <span>{mediaGroup[0].alias}</span>
                        </h1>
                        {
                          <div
                            style={{
                              width: "85%",
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              padding: "0px 0px 20px 0px",
                            }}
                          >
                            <PhotoGallery
                              photos={mediaGroup}
                              ownId={component[0]?.id}
                              state={false}
                              mongoId={component[0]?.mongoId}
                              type={"component"}
                              uploadImages={uploadImages}
                            />{" "}
                          </div>
                        }
                      </div>
                    </Grid>
                  );
                })}
              {/* -- MAP -- */}
              {widthMap && (
                <Grid item xs={12} md={12} lg={widthMap} xl={widthMap}>
                  <div
                    className={`content-map ${
                      theme === "light" ? "bb" : "bb2"
                    }`}
                  >
                    <h1
                      className="event-title"
                      style={{
                        color:
                          theme === "light"
                            ? "var(--background-primary_color)"
                            : WHITE_COLOR,
                      }}
                    >
                      <span>LOCATION</span>
                    </h1>
                    {component && (
                      <ComponentMap
                        component={component[0]}
                        markedEvent={markedEvent}
                        setMovePoint={setMovePoint}
                      />
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
            {/* --EVENT HISTORY-- */}
            <EventHistory
              eventRows={eventRows}
              seePointEventsTable={seePointEventsTable}
              detailId={componentId}
            />
            {/**EVENT HISTORY NO LOCATION */}
            <EventHistoryNoLocation
              eventRows={eventRows}
              seePointEventsTable={seePointEventsTable}
              detailId={componentId}
            />
          </ContainerOperations>
        </Box>
      )}
    </>
  );
};

export default ComponentDetails;
