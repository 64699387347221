import { useState, useEffect, useRef } from "react";
import { useSWRConfig } from "swr";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

// Components
import OpenDialog from "components/Dialogs/OpenDialog";
import ContentDialog from "./ContentDialog";
// Services
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
import uploadFilePointEvent, {
  saveCaptureMedia,
} from "services/uploadFilePointEvent";
// Config
import { config } from "config.js";
import { setUpdateAtEvent } from "services/savePointEvent";
import { pathFolder } from "utils/paths";
import { getPathBucket } from "helpers/getPathBucket";
import { Box } from "@mui/material";
import CustomButton from "components/Buttons/CustomButton";
import { ProgressBar } from "components/ElementDetails/PhotoGalley/ProgressBar";

const AddPicture = ({ event }) => {
  const [photosFile, setPhotosFile] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [typeEventMedia, setTypeEventMedia] = useState(null);
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);

  const abortControllerRef = useRef(null);

  const { mutate } = useSWRConfig();

  useEffect(() => {
    const typeMedia = event?.fieldsCaptureMedia[0]?.name;
    setTypeEventMedia(typeMedia);
  }, [event]);

  const uploadFile = async (e) => {
    const files = e.target.files;
    let size = 0;
    Object.keys(files).forEach((key) => {
      size += files[key].size;
    });
    setTotalSize(size);

    Object.keys(files).forEach((key) => {
      setPhotosFile((current) => [...current, files[key]]);
    });
    Object.keys(files).forEach((key) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[key]);
      reader.onload = () => {
        setPhotos((current) => [reader.result, ...current]);
      };
    });
    setOpenModal(true);
    setTotalFiles(files.length);
  };

  const cancelUpload = () => {
    setIsCancelled(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setOpenModal(false);
    setPhotosFile([]);
    setPhotos([]);
    setUploadedSize(0);
    setUploadProgress(0);
    setIsUploading(false);
  };

  const savePhotos = async () => {
    try {
      setIsUploading(true);
      setIsCancelled(false);
      setUploadProgress(0);
      setUploadedSize(0);

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const folderPath = getPathBucket(
        pathFolder.events.points.pictures(typeEventMedia)
      );

      for (let i = 0; i < photosFile.length; i++) {
        if (isCancelled) {
          console.log("Upload cancelled");
          break;
        }

        const file = photosFile[i];
        try {
          const { fileUrl } = await uploadFileToS3({
            file,
            folderPath,
            signal,
          });

          if (isCancelled) break;

          setUploadedSize((prev) => prev + file.size);

          if (typeEventMedia === null || typeEventMedia === undefined) {
            await uploadFilePointEvent({
              pointEventId: event.id,
              eventFileTypeId: 1,
              url: fileUrl,
            });
          } else {
            const aliasFieldMedia = event?.fieldsCaptureMedia.find(
              (item) => item.name === typeEventMedia
            ).alias;

            await saveCaptureMedia({
              pointEventMgId: event.mongoId,
              media: [
                {
                  type: typeEventMedia,
                  alias: aliasFieldMedia,
                  url: fileUrl,
                },
              ],
            });
          }

          setUploadProgress((prev) => prev + 1);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Upload aborted");
            break;
          } else {
            console.error("Error uploading file:", error);
          }
        }
      }

      if (!isCancelled) {
        setOpenModal(false);
        setTotalFiles(0);
        setPhotosFile([]);
        setPhotos([]);
        setUploadedSize(0);
        setUpdateAtEvent(event.id);
        mutate(`${config.URL_BACKEND_PG}api/v1/point-events?id=${event.id}`);
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${event.id}`
        );
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${event.id}`
        );
      }
    } catch (error) {
      console.error("Error saving photos:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <CustomButton
        text="Add picture"
        onClick={() => document.getElementById("files").click()}
        margin={0}
        icon={<AddAPhotoIcon sx={{ fontSize: "18px" }} />}
        padding={"7px 10px"}
      />
      <input
        id="files"
        style={{ visibility: "hidden", position: "absolute" }}
        accept="image/*"
        type="file"
        name="file"
        multiple
        onChange={uploadFile}
      />
      <OpenDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        content={{
          title: "Upload pictures",
          description: (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0,
                }}
              >
                {isUploading && (
                  <ProgressBar
                    count={uploadProgress}
                    totalFiles={totalFiles}
                    porcentaje={(uploadProgress * 100) / totalFiles}
                    uploadedSize={uploadedSize}
                    totalSize={totalSize}
                  />
                )}
                <ContentDialog
                  pictures={photos}
                  event={event}
                  typeEventMedia={typeEventMedia}
                  setTypeEventMedia={setTypeEventMedia}
                />
              </Box>
            </>
          ),
          agree: "Save",
          disagree: "Cancel",
        }}
        execute={savePhotos}
        disableBackdropClick={isUploading}
        disableEscapeKeyDown={isUploading}
        toExecuteOnClose={cancelUpload}
      />
    </>
  );
};

export default AddPicture;
