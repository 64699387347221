import axios from "axios";
import { urls } from "utils/urlKeys";

const deleteAccessUserToInstances = async ({ userId, instanceId }) => {
  const token = localStorage.getItem("token");
  const res = await axios.delete(
    urls.instance.accessInstanceUser(userId, instanceId),
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const data = await res.data;
  return { data, res };
};

export default deleteAccessUserToInstances;
