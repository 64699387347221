import Typography from "@mui/material/Typography";
// check layer
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// custom style
import {
  WrapperTypeObjectTitle,
  useStyles,
  CustomizedAccordion,
} from "./AccordionStyles";

// redux
import { useDispatch, useSelector } from "react-redux";

import {
  showLabels,
  showComponentLabels,
  showEventLabels,
} from "redux/actions/digitalTwin";


const AccordionLabels = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let showLabelsElements = useSelector(
    (state) => state.digitalTwinReducer.showLabels
  );

  const showComponentsLabels = useSelector(
    (state) => state.digitalTwinReducer.showComponentLabels
  );

  const showEventsLabels = useSelector(
    (state) => state.digitalTwinReducer.showEventLabels
  );

  const handlerClickShowLabel = () => {
    showLabelsElements = !showLabelsElements;
    dispatch(showLabels(showLabelsElements));
  };

  const handlerClickShowComponentLabel = () => {
    dispatch(showComponentLabels(!showComponentsLabels));
  };

  const handlerClickShowEventlabel = () => {
    dispatch(showEventLabels(!showEventsLabels));
  };

  return (
    <>
      <CustomizedAccordion
        className="background-1 border-color-1"
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={false}
        style={{ borderRadius: "4px", marginBottom: "1rem", boxShadow: "none" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontFamily: document.body.style.fontFamily,
              color: document.body.style.color,
              fontWeight: "bold",
            }}
          >
            Labels
          </Typography>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="color1" />}
          ></AccordionSummary>
        </div>

        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {!showLabelsElements ? (
              <VisibilityOffIcon
                sx={{
                  margin: "10px 15px",
                }}
                className="iconEyeOff"
                onClick={handlerClickShowLabel}
              />
            ) : (
              <VisibilityIcon
                sx={{
                  margin: "10px 10px 10px 15px",
                }}
                className="iconEyeOn"
                onClick={handlerClickShowLabel}
              />
            )}
            <p>Object labels</p>
          </div>
        </WrapperTypeObjectTitle>
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {!showComponentsLabels ? (
              <VisibilityOffIcon
                sx={{
                  margin: "10px 15px",
                }}
                className="iconEyeOff"
                onClick={handlerClickShowComponentLabel}
              />
            ) : (
              <VisibilityIcon
                sx={{
                  margin: "10px 10px 10px 15px",
                }}
                className="iconEyeOn"
                onClick={handlerClickShowComponentLabel}
              />
            )}
            <p>Component labels</p>
          </div>
        </WrapperTypeObjectTitle>
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {!showEventsLabels ? (
              <VisibilityOffIcon
                sx={{
                  margin: "10px 15px",
                }}
                className="iconEyeOff"
                onClick={handlerClickShowEventlabel}
              />
            ) : (
              <VisibilityIcon
                sx={{
                  margin: "10px 10px 10px 15px",
                }}
                className="iconEyeOn"
                onClick={handlerClickShowEventlabel}
              />
            )}
            <p>Event labels</p>
          </div>
        </WrapperTypeObjectTitle>
      </CustomizedAccordion>
    </>
  );
};

export default AccordionLabels;
