// React
import { useEffect, useState } from "react";

// Components
import TextInput from "../GenericInput";
import SelectInput from "components/Forms/SelectInput";
import CustomButton from "components/Buttons/CustomButton";
// Services
import savePointEventApiPg, {
  createPointEvent,
  savePointEvenRelation,
  setUpdateAtEvent,
} from "services/savePointEvent";
// Hooks
import { useFetchEventLibraries } from "hooks/fetchLibraries";
// Dependencies
import { useForm } from "react-hook-form";
import useSwr from "swr";
import { useSWRConfig } from "swr";

// Config
import { config } from "config.js";
// styles
import { Currency } from "./DynamicEventStyles";
import { Form } from "../SaveObject/DynamicFieldsStyle";
import { ContentButton } from "../../Buttons/CustomButtonStyle";
import { updateAttributesEventMg } from "services/updateAttributesMg";
import CircularIndeterminate from "components/Lodings/LoadingV2";
import { urlKeys, urls } from "utils/urlKeys";
import { LineConteiner2 } from "pages/ConfigAdmin/typeElemts/FieldsStyle";
import { createPointEventNoLocation } from "services/saveEventNoLocation";
import { useDispatch } from "react-redux";
import { setMessageLoadingMap } from "redux/actions/digitalTwin";
import { bodyEventtWithFiles } from "services/Events/createBodyEventWithFiles";
import { createBodyEvent } from "services/Events/createBodyEvent ";

export default function DynamicEvent({
  feature,
  objectId = null,
  closeModal,
  pointTypeEventId,
  pointComponentId = null,
  pointEventId = null,
  dataEvent,
  update = false,
  setOpenDialog,
  handleUpdateData,
}) {
  // Local storage
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  // Swr
  const { mutate } = useSWRConfig();
  // Redux
  const dispatch = useDispatch();
  // States
  const [typeEventFields, setTypeEventFields] = useState();
  const [loading, setLoading] = useState(false);
  const [globalTableCategories, setGlobalTableCategories] = useState(null);
  const [useSelectAutocomplete, setUseSelectAutocomplete] = useState(false);
  // Forms
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    urls.users.getOperators
  );

  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];

  const { data: dataLibrary, error: errorLibrary } = useFetchEventLibraries({
    id: dataEvent?.pointTypeEvent?.pointLibraryEventId,
  });
  // Find type events to type elements
  const { data: typeEvents, error: errorTypeEvents } = useSwr(
    urls.events.typeEventsParams(
      `id=${pointTypeEventId || dataEvent?.pointTypeEventId}`
    )
  );
  //Get Data Global Tables
  const urlGlobalTables = `${config.URL_BACKEND_MG}global-values-fields?adminCompanyId=${adminCompanyId}`;
  const { data: globalTables, error: errorGlobalTables } =
    useSwr(urlGlobalTables);

  useEffect(() => {
    if (!errorGlobalTables && globalTables && globalTables.length > 0) {
      setGlobalTableCategories(globalTables[0].tableCategories);
    }
  }, [globalTables, errorGlobalTables]);

  useEffect(() => {
    !errorTypeEvents &&
      typeEvents?.length > 0 &&
      setTypeEventFields(typeEvents[0]);
  }, [typeEvents, errorTypeEvents]);

  useEffect(() => {
    pointTypeEventId && reset();
    dataEvent && reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointTypeEventId, dataEvent?.pointTypeEventId]);

  useEffect(() => {
    if (update && dataEvent) {
      const existingFieldNames = dataEvent?.attributes?.map(
        (attribute) => attribute.name
      );
      !errorLibrary &&
        dataLibrary?.[0]?.fields?.forEach((field) => {
          if (
            !existingFieldNames.includes(field.name) &&
            field.type !== "notification" &&
            field.type !== "captureMedia" &&
            field.type !== "lineTag"
          ) {
            const newAttribute = {
              name: field?.name,
              alias: field?.alias,
              value: "",
              userId: null,
            };
            dataEvent.attributes.push(newAttribute);
          }
        });

      dataEvent?.attributes?.forEach((elm) => {
        if (elm.value?.[0] === "{") {
          const dataParse = JSON.parse(elm.value);

          if (dataParse.type === "number" && dataParse.value) {
            return setValue(elm.name, parseFloat(dataParse.value));
          }
        } else if (elm.value === "true" || elm.value === "false") {
          return setValue(elm.name || elm.alias, JSON.parse(elm.value));
        } else {
          const value =
            elm?.value?.length === 0 || elm?.value === " "
              ? ""
              : !isNaN(elm.value)
                ? parseFloat(elm.value)
                : elm.value;
          return setValue(elm.name, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEvent, errorLibrary, dataLibrary]);

  useEffect(() => {
    if (typeEventFields && typeEventFields.fields.length !== 0) {
      const selectGlobal = typeEventFields.fields.filter((elm) => {
        return (
          elm.type === "select" &&
          elm.globalSelect &&
          !elm.hasOwnProperty("columnKeyGlobalSpecs")
        );
      });
      typeEventFields.fields.forEach((elm) => {
        if (
          (elm.type === "string" ||
            elm.type === "number" ||
            elm.type === "file" ||
            elm.type === "date") &&
          elm.globalSelect &&
          globalTableCategories
        ) {
          const matchSelect = selectGlobal.find((item) => {
            return (
              elm?.columnKeyGlobalSpecs?.selectGlobalId === item._id ||
              elm?.columnKeyGlobalSpecs?.selectGlobalName === item.name
            );
          });
          const findCategory = globalTableCategories?.find((item) => {
            return elm.columnKeyGlobalSpecs.categoryId === item._id;
          });

          const matchTbale = findCategory?.groupTables?.find((item) => {
            return elm.columnKeyGlobalSpecs.tableId === item._id;
          });

          const row = matchTbale?.rows?.find((row) => {
            return (
              row?.Options ===
              getValues(matchSelect?.name || matchSelect?.alias)
            );
          });

          if (row) {
            return setValue(
              elm?.name || elm?.alias,
              row[elm.columnKeyGlobalSpecs.column.name]
            );
          }
        }
      });
    }
  }, [
    useSelectAutocomplete,
    globalTableCategories,
    typeEventFields,
    getValues,
    setValue,
  ]);

  const handleSave = async (data) => {
    setLoading(true);
    if (update) {
      const numericFields = typeEventFields.fields
        ?.map((field) => {
          if (field.type === "number") {
            const elmt = document.getElementById(field.name);
            if (elmt) {
              field.value = elmt.value;
              return field;
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = typeEventFields.fields?.filter(
        (field) => field.type === "operation"
      );

      if (numericFields.length > 0)
        numericFields.forEach((fld) => {
          fld.value = fld.value === "" ? NaN : fld.value;
          data[fld.name] = JSON.stringify(fld);
        });
      if (expFields.length > 0)
        expFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });

      const fileFields = typeEventFields?.fields?.filter(
        (field) => field.type === "file"
      );

      await bodyEventtWithFiles({ fileFields, getValues, data });

      const newBody = createBodyEvent({
        dataForm: data,
        typeEventFields,
        allOperators,
      });

      const newAttributes = newBody.attributes
        ?.map((attribute) => {
          const matchingField = typeEventFields.fields.find(
            (field) => field.name === attribute.name && field.globalSelect
          );
          if (matchingField) {
            return {
              ...attribute,
              autocomplete: matchingField.globalSelect,
              columnKeyGlobalSpecs:
                matchingField.type === "select"
                  ? {
                    categoryId: matchingField.globalTable.categoryId,
                    tableId: matchingField.globalTable.tableId,
                    column: {
                      name: "Options",
                    },
                    selectGlobalName: matchingField.name,
                    globalKey: data[matchingField.name],
                    pointLibrary: {
                      id: typeEventFields.pointLibraryEventId,
                      mongoId: null,
                    },
                    typeElementId:
                      pointTypeEventId || dataEvent?.pointTypeEventId,
                  }
                  : {
                    ...matchingField.columnKeyGlobalSpecs,
                    globalKey:
                      data[
                      matchingField.columnKeyGlobalSpecs.selectGlobalName
                      ],
                    pointLibrary: {
                      id: typeEventFields.pointLibraryEventId,
                      mongoId: null,
                    },
                    typeElementId:
                      pointTypeEventId || dataEvent?.pointTypeEventId,
                  },
            };
          } else {
            return attribute;
          }
        })
        .filter((attribute) =>
          typeEventFields.fields.some(
            (field) => field.name === attribute.name && attribute.alias
          )
        );

      await updateAttributesEventMg(dataEvent.mongoId, newAttributes);
      await setUpdateAtEvent(dataEvent.id);

      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${dataEvent.id}`
      );
      mutate(urlKeys.eventsObjects);
      mutate(urlKeys.eventsComponents);
      mutate(urlKeys.eventsEvents);

      handleUpdateData();
      setOpenDialog(false);
    } else {
      //get all numeric fields from the form
      const numericFields = await typeEventFields.fields
        ?.map((field) => {
          if (field.type === "number") {
            const elmt = document.getElementById(field.name);
            if (elmt) {
              field.value = elmt.value;
              return field;
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = typeEventFields?.fields?.filter(
        (field) => field.type === "operation"
      );

      if (numericFields.length > 0)
        numericFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });
      if (expFields.length > 0)
        expFields.forEach((fld) => {
          data[fld.name] = JSON.stringify(fld);
        });

      const fileFields = typeEventFields?.fields?.filter(
        (field) => field.type === "file"
      );

      await bodyEventtWithFiles({ fileFields, getValues, data });

      const newBody = createBodyEvent({
        dataForm: data,
        typeEventFields,
        allOperators,
      });

      let event = {};

      if (
        feature === undefined ||
        feature === null ||
        Object.keys(feature).length === 0
      ) {
        event = createPointEventNoLocation(
          newBody.attributes,
          objectId,
          pointComponentId,
          pointTypeEventId
        );
      } else {
        event = createPointEvent(
          feature,
          newBody.attributes,
          objectId,
          pointComponentId,
          pointTypeEventId
        );
      }

      event.attributes = newBody.attributes
        ?.map((attribute) => {
          const matchingField = typeEventFields.fields.find(
            (field) => field.name === attribute.name && field.globalSelect
          );
          if (matchingField) {
            return {
              ...attribute,
              autocomplete: matchingField.globalSelect,
              columnKeyGlobalSpecs:
                matchingField.type === "select"
                  ? {
                    categoryId: matchingField.globalTable.categoryId,
                    tableId: matchingField.globalTable.tableId,
                    column: {
                      name: "Options",
                    },
                    selectGlobalName: matchingField.name,
                    globalKey: data[matchingField.name],
                    pointLibrary: {
                      id: typeEventFields.pointLibraryEventId,
                      mongoId: null,
                    },
                    typeElementId:
                      pointTypeEventId || dataEvent?.pointTypeEventId,
                  }
                  : {
                    ...matchingField.columnKeyGlobalSpecs,
                    globalKey:
                      data[
                      matchingField.columnKeyGlobalSpecs.selectGlobalName
                      ],
                    pointLibrary: {
                      id: typeEventFields.pointLibraryEventId,
                      mongoId: null,
                    },
                    typeElementId:
                      pointTypeEventId || dataEvent?.pointTypeEventId,
                  },
            };
          } else {
            return attribute;
          }
        })
        .filter((attribute) =>
          typeEventFields.fields.some(
            (field) => field.name === attribute.name && attribute.alias
          )
        );

      const { data: newPointEvent } = await savePointEventApiPg(event);
      dispatch(setMessageLoadingMap(true));

      // Update all point events
      mutate(urlKeys.eventsObjects);
      mutate(urlKeys.eventsComponents);
      mutate(urlKeys.eventsEvents);
      if (objectId) {
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events?objectId=${objectId}`
        );
      }
      if (pointComponentId) {
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events/components?pointComponentId=${pointComponentId}`
        );
      }
      if (pointEventId) {
        await savePointEvenRelation(pointEventId, newPointEvent.id);
        // Event event
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${pointEventId}`
        );
        // Event object
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events?id=${pointEventId}`
        );
        // Event Component
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${pointEventId}`
        );
      }
      closeModal(false);
    }
    setLoading(false);
    reset();
  };

  if (typeEventFields === undefined) {
    return <CircularIndeterminate />;
  }

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      {typeEventFields &&
        typeEventFields?.fields?.map((field, index) => {
          if (field.type === "operation") {
            return (
              <TextInput
                key={index}
                field={field}
                register={register}
                errors={errors}
              />
            );
          }
          if (field.type === "select" && field.globalSelect) {
            const currentCategory = globalTableCategories?.find((category) => {
              return category._id === field.globalTable.categoryId;
            });
            const selectTable = currentCategory?.groupTables.find((table) => {
              return table._id === field.globalTable.tableId;
            });

            return (
              <div
                key={index}
                onChange={() =>
                  setUseSelectAutocomplete(!useSelectAutocomplete)
                }
              >
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    options: selectTable?.rows?.map((elm) => elm.Options),
                    required: field.required,
                    type: field.type,
                    alias: field.alias || field.name,
                  }}
                  register={register}
                />
              </div>
            );
          }
          if (field.type === "select") {
            return (
              <SelectInput key={index} field={field} register={register} />
            );
          }
          if (field.type === "currency") {
            return (
              <div key={index}>
                <TextInput field={field} register={register} errors={errors} />
                <SelectInput field={field} register={register} />
              </div>
            );
          }
          if (field.type === "range") {
            return (
              <div key={index}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    alias: field.alias,
                    required: field.required,
                    type: field.type,
                    step:
                      field.options.length !== 0
                        ? parseFloat(field.options[0])
                        : 1,
                    max: field.max,
                    min: field.min,
                  }}
                  value={watch(field.name)}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          }
          if (field.type === "delegate") {
            return (
              <div>
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    options: allOperators?.map((operator) => {
                      return `${operator?.firstName} ${operator?.firstLastName}`;
                    }),
                    required: field.required,
                    type: field.type,
                    alias: field.alias || field.name,
                  }}
                  register={register}
                />
              </div>
            );
          }
          if (field.type === "lineTag") {
            return (
              <LineConteiner2>
                <hr />
                <h3>{field.alias}</h3>
                <hr />
              </LineConteiner2>
            );
          }
          if (field.type === "file") {
            return (
              <div>
                <TextInput
                  field={{
                    ...field,
                    name: field.name,
                    required: update ? false : field.required,
                    type: field.type,
                    possible: field.possible,
                    alias: field.alias || field.name,
                    warning: field.globalSelect ? true : false,
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          }
          if (field.type === "captureMedia") return null;
          if (field.type === "notification") return null;
          if (field.type === "lineTag") return null;
          return (
            <TextInput
              key={index}
              field={{
                ...field,
                warning: field.globalSelect ? true : false,
              }}
              register={register}
              errors={errors}
            />
          );
        })}
      <ContentButton style={{ margin: "-10px" }}>
        <CustomButton
          type={"submit"}
          isLoad={loading}
          text={update ? "Update" : "Save"}
        />
      </ContentButton>
    </Form>
  );
}
