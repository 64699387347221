import { useEffect, useState } from "react";
import ListUser from "components/Tables/BasicTable/ListAdminCompany";
import {
  Title,
  CustomContainer,
  ContainerTables,
} from "./adminCompaniesStyles";
import Layout from "components/Layout/AdminDecimetrix";
import { useUserStore } from "zustandGloabalStore/user";
import useSWR from "swr";
import { urls } from "utils/urlKeys";

const AdminCompanies = () => {
  const { instanceManager } = useUserStore((state) => state);

  const {
    data: adminCompanyByInstanceId,
    erros: adminCompanyByInstanceIdError,
  } = useSWR(`${urls.users.getAdminCompanyByInstance(instanceManager.id)}`);

  const { data: operatorsByInstanceId, erros: operatorsByInstanceIdError } =
    useSWR(`${urls.users.getOperatorsInstanceDynamic(instanceManager.id)}`);

  const [usersAdminByInstanceId, setUsersAdminByInstanceId] = useState(null);
  const [usersOperatorByInstanceId, setUsersOperatorByInstanceId] =
    useState(null);

  useEffect(() => {
    if (!adminCompanyByInstanceIdError && adminCompanyByInstanceId) {
      setUsersAdminByInstanceId(adminCompanyByInstanceId);
    }
  }, [
    adminCompanyByInstanceId,
    adminCompanyByInstanceIdError,
    instanceManager,
  ]);

  useEffect(() => {
    if (!operatorsByInstanceIdError && operatorsByInstanceId) {
      setUsersOperatorByInstanceId(operatorsByInstanceId);
    }
  }, [operatorsByInstanceId, operatorsByInstanceIdError, instanceManager]);

  return (
    <Layout>
      <CustomContainer className="background-1">
        <Title>
          {`${instanceManager.name} INSTANCE USER MANAGER`.toUpperCase()}
        </Title>
        <ContainerTables>
          <ListUser
            usersAdminByInstanceId={usersAdminByInstanceId}
            usersOperatorByInstanceId={usersOperatorByInstanceId}
          />
        </ContainerTables>
      </CustomContainer>
    </Layout>
  );
};

export default AdminCompanies;
