// React
import { React, useState } from "react";
import { useForm } from "react-hook-form";
// Redux
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setOriginalOrderViewColumns } from "redux/actions/digitalTwin";
// Components
import { Form } from "components/Accordions/AccordionBookmarksViewsStyle";
import CustomButton from "components/Buttons/CustomButton";
import TextInput from "components/Forms/GenericInput";
import OpenDialog from "./OpenDialog";
// Services
import createViewTableColumns from "services/createViewTableColumns";
// Dependencies
import { useSWRConfig } from "swr";
// Config-utils
import { roles } from "utils/roles";
import { BLACK_COLOR } from "utils/const";
import { urlsApiMg } from "utils/urlKeys";
import { useUserStore } from "zustandGloabalStore/user";

function FormTable({
  typeOfLibrary,
  typeOfTable,
  columnOrder,
  selectedFilters,
  setOpenDialogCreateView,
  setUseOrderColumns,
  setCurrentNameViewColumnOrder,
  setCurrentViewColumnOrder,
}) {
  const { mutate } = useSWRConfig();

  const dispatch = useDispatch();

  const { instanceId, userId, role } = useUserStore((state) => state);

  const [loading, setLoading] = useState(false);
  const [openDialogError, setOpenDialogError] = useState(false);
  const [contentDialogError, setContentDialogError] = useState({
    title: "",
    description: ``,
    agree: "",
  });

  const originalOrderColumns = useSelector(
    (state) => state.digitalTwinReducer.originalOrderViewColumns
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const { response } = await createViewTableColumns({
      typeOfLibrary,
      typeOfTable,
      nameView: data["Name of the table view"],
      body: {
        name: data["Name of the table view"],
        orderColumns: columnOrder,
        selectedFilters: selectedFilters,
        userId: data["Public"] ? null : userId,
        instanceId: data["Public"] ? instanceId : null,
      },
    });
    if (typeof response === "string") {
      const text = (
        <div>
          The name of the {data["Public"] ? "public" : "private"} view{" "}
          <b>{data["Name of the table view"]}</b> already exists,
        </div>
      );
      setOpenDialogError(true);
      setContentDialogError({
        title: "Sorry, try again!",
        description: text,
        agree: "Try again",
      });
    } else {
      setUseOrderColumns(false);
      setOpenDialogCreateView(false);
      setCurrentNameViewColumnOrder(data["Name of the table view"]);
      setCurrentViewColumnOrder(columnOrder);
      dispatch(setOriginalOrderViewColumns(originalOrderColumns));
      mutate(
        urlsApiMg.orderColumns(
          `typeOfLibrary=${typeOfLibrary}&type=${typeOfTable}`
        )
      );
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: "1.5rem",
      }}
    >
      <OpenDialog
        openDialog={openDialogError}
        setOpenDialog={setOpenDialogError}
        execute={() => setOpenDialogError(false)}
        content={contentDialogError}
        maxWidth={"200px"}
        minWidth={"500px"}
      />
      <p style={{ marginBottom: "1rem", color: `${BLACK_COLOR}` }}>
        Here you can save a new view with the current table view.
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <>
          <TextInput
            field={{
              name: "Name of the table view",
              type: "string",
              required: true,
            }}
            register={register}
            errors={errors}
          />
          {role === roles.companyAdmin && (
            <TextInput
              field={{
                name: "Public",
                type: "checkbox",
                required: false,
              }}
              register={register}
              errors={errors}
            />
          )}
        </>
        <div
          style={{
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <CustomButton
            type={"submit"}
            isLoad={loading}
            text={"Create View"}
            margin={0}
          />
        </div>
      </Form>
    </div>
  );
}

export default FormTable;
