import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import NavBarDrawer from "../../NavBar/navDrawer";

import { PRIMARY_COLOR } from "utils/const";
import logoWhite from "images/Digital-Transformation.png";
import { Img, StyledP } from "./TopBarStyles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";

import { useDispatch } from "react-redux";
import { resetStatesIndex } from "redux/actions/index";
import { resetStatesAdmin } from "redux/actions/admin";
import { resetStatesDigitalTwin } from "redux/actions/digitalTwin";

import { useState } from "react";
// Services
import { updateUserVerification } from "services/users/userVerification";
import { createRevoquedToken } from "services/sessions/create";
import useSwr from "swr";
import { config } from "config.js";
import ThemeToggleButton from "components/Buttons/ThemeToggleButton";
// Zustand
import useStoreDashboard from "../../../pages/Dashboard/Store";

const settings = ["Profile", "Dashboard", "Help", "Log Out"];

const ResponsiveAppBar = ({ linkLogo }) => {
  const userId = localStorage.getItem("userId");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const url = `${config.URL_BACKEND_PG}api/v1/users/${userId}`;
  const { data: user } = useSwr(url);
  const dispatch = useDispatch();
  const status = localStorage.getItem("role");
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const navigate = useNavigate();
  const openLink = (url) => window.open(url, "_blank")?.focus();
  const dashboardViewName = useStoreDashboard(
    (state) => state.dashboardViewName
  );

  const lastLogin = async () => {
    const token = localStorage.getItem("token");
    const date = new Date();
    const lastLoginTimestamp = format(date, "yyyy-MM-dd'T'HH:mm:ss");

    try {
      await axios.patch(
        `${config.URL_BACKEND_PG}api/v1/user-verification/last-login/${userId}`,
        { lastLogin: lastLoginTimestamp },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
    } catch (error) {
      console.error("Error in lastLogin:", error);
      throw error;
    }
  };

  const handleCloseUserMenu = async (event) => {
    setAnchorElUser(null);
    const option = event.currentTarget.innerText;
    switch (option) {
      case "Profile":
        status === "Administrador de compañía"
          ? navigate("/profileAdmin")
          : status === "Operario"
          ? navigate("/profile")
          : navigate("/profile-admin-decimetrix");
        break;
      case "Dashboard":
        status === "Administrador de compañía"
          ? navigate("/dashboard-admin-decimetrix")
          : navigate("/dashboard-admin-decimetrix");
        break;
      case "Help":
        openLink("https://co.linkedin.com/company/decimetrix");
        break;
      case "Log Out":
        await lastLogin();
        await updateUserVerification({
          userId,
          changes: { activeSession: false },
        });
        await createRevoquedToken();
        localStorage.setItem("loginStatus", false);
        localStorage.clear();
        dispatch(resetStatesIndex());
        dispatch(resetStatesAdmin());
        dispatch(resetStatesDigitalTwin());
        navigate("/SignIn");
        break;
      default:
        break;
    }
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: PRIMARY_COLOR,
        height: "80px",
        justifyContent: "center",
      }}
    >
      <Toolbar
        style={{
          paddingRight: 40,
        }}
      >
        <NavBarDrawer />
        <Box component="div" sx={{ flexGrow: 5, mr: 2 }}>
          <Link to={`/${linkLogo}`}>
            <Img src={logoWhite} />
          </Link>
        </Box>

        {/* aqui va  el componente para el nombre de la vista del dashboard */}
        <Box component="div" sx={{ flexGrow: 5, mr: 2 }}>
          <ShowViewName text={dashboardViewName} fontSize={"1.30rem"} />
        </Box>

        <Box
          component="p"
          sx={{
            display: "flex",
            mr: 3,
          }}
        >
          {user && !user.error && (
            <StyledP>
              <ThemeToggleButton />
              {user.operator !== null ? (
                <>
                  {user.operator.firstName} {user.operator.firstLastName}
                </>
              ) : user.adminCompany !== null ? (
                <>
                  {user.adminCompany.firstName}{" "}
                  {user.adminCompany.firstLastName}
                </>
              ) : user.adminDecimetrix !== null ? (
                <>
                  {user.adminDecimetrix.firstName}{" "}
                  {user.adminDecimetrix.firstLastName}
                </>
              ) : null}
            </StyledP>
          )}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          {user && !user.error && (
            <Tooltip title="Open Settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="photo-profile" src={user.urlPhoto} size="small" />
              </IconButton>
            </Tooltip>
          )}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                  }}
                  textAlign="center"
                >
                  {setting}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const ShowViewName = ({ text, fontSize }) => {
  if (!text || !text.length) return <></>;
  return (
    <div
      style={{
        textAlign: "end",
      }}
    >
      <span
        style={{
          fontSize,
        }}
      >
        {"".concat(text).toUpperCase()}
      </span>
    </div>
  );
};
export default ResponsiveAppBar;
