import { styled } from "@mui/material/styles";
import { WHITE_COLOR } from "utils/const";
import { Box } from "@mui/material";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  padding: theme.spacing(2),
  width: "100%",
  minWidth: "400px",
  borderRadius: theme.spacing(2),
}));

export const ImagePreviewContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "200px",
  border: `2px dashed ${WHITE_COLOR}`,
  borderRadius: "8px",
  marginTop: "16px",
});
