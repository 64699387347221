// Services
import timeZoneOffset from "services/timeZoneOffset";
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
import { getPathBucket } from "helpers/getPathBucket";
import { pathFolder } from "utils/paths";

const createBodyObject = (formFields, otherFields) => {
  // Extract longitude and latitude from formFields

  // Create initial body object
  const body = {
    dataSourceId: 2, // desktop source
    date: new Date(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneOffset: timeZoneOffset(),
    typeElementId: parseInt(formFields["Type element"]),
    ...otherFields,
  };

  // Extract key fields from formFields
  const keyFields = getKeyFields(formFields);

  // Create attributes object
  const attributes = createAttributes(formFields, keyFields);

  // Filter out attributes with empty values
  const objectAttributes = filterEmptyAttributes(attributes);

  // Combine attributes with initial body object
  const newBody = {
    ...body,
    attributes: objectAttributes,
  };

  return newBody;
};

// Extract key fields from formFields, excluding exceptions
const getKeyFields = (formFields) => {
  const exceptions = [
    "Object type",
    "Type element",
    "Field baseline",
    "Field equipment name",
    "Comments",
  ];

  return Object.keys(formFields).filter((key) => !exceptions.includes(key));
};

// Create attributes object from key fields and formFields
const createAttributes = (formFields, keyFields) => {
  return keyFields.map((key) => {
    let value = formFields[key];
    if (typeof value === "boolean") value = value.toString();
    return {
      name: key,
      value: value === "" ? " " : value,
    };
  });
};

// Filter out attributes with empty values
const filterEmptyAttributes = (attributes) => {
  return attributes.filter((attribute) => attribute.value !== "");
};

/**
 * Generates a structured request body with uploaded file URLs.
 *
 * This function processes file fields, uploads them to S3, and replaces the file references
 * in the provided data object with their corresponding URLs.
 *
 * @async
 * @function bodyObjectWithFiles
 * @param {Array<Object>} fileFields - List of file fields containing file names.
 * @param {Object} data - Data object containing file references.
 * @param {Object} genericData - Additional data used for creating the final object.
 * @returns {Promise<Object>} The structured request body with uploaded file URLs.
 * @throws {Error} If file upload fails.
 *
 * @example
 * const fileFields = [{ name: "profileImage" }];
 * const data = { profileImage: [file] };
 * const genericData = { point: someData };
 * const result = await bodyObjectWithFiles(fileFields, data, genericData);
 */
const bodyObjectWithFiles = async (fileFields, data, genericData) => {
  // Process each file field and upload the files to S3
  const uploadedFiles = await Promise.all(
    fileFields.map(async (fileField) => {
      const files = data[fileField.name];
      if (!files || files.length === 0) return { [fileField.name]: null };

      const folderPath = getPathBucket(
        pathFolder.objects.points.files(fileField.name)
      );

      const { fileUrl } = await uploadFileToS3({
        folderPath,
        file: files[0],
        isUrl: files,
      });

      return { [fileField.name]: fileUrl };
    })
  );

  // Merge uploaded file URLs into the original data object
  const files = Object.assign({}, ...uploadedFiles);
  Object.keys(files).forEach((key) => {
    if (key in data) {
      data[key] = files[key];
    }
  });

  // Create the final structured body object
  return createBodyObject(data, genericData.point);
};

export default bodyObjectWithFiles;
