export const contentByHost = {
  greenDragon: {
    en: {
      signIn: {
        title: "GREEN DRAGON",
        subtitle: "CARBON FOOTPRINT MANAGEMENT SYSTEM",
        description:
          "End to end solution for the inventory, detection, quantification, estimation and management of GHG emissions.",
        image:
          "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/img_footer.png",
      },
      mainHub: {
        source: "Green Dragon",
        title: "Accelerate your sustainability journey.",
        description:
          "Enhance ESG data transparency and resilience with our NO CODE solutions. Create your own Web, mobile, NFC, Blockchain, and AI-ready technology to drive growth. Capture and leverage your digital inventory, streamline sustainability efforts, and track progress towards Carbon Neutrality. Detect, quantify, and report within a unified tool. Collaborate effectively to achieve your sustainability goals and operational excellence.",
      },
    },
    es: {
      signIn: {
        title: "",
        subtitle: "",
        description: "",
      },
      mainHub: {
        title: "",
        description: "",
      },
    },
  },
  redDragon: {
    en: {
      signIn: {
        title: "RED DRAGON",
        subtitle: "POWER SYSTEMS MANAGEMENT SOLUTION",
        description:
          "End to End solution for digitalizing electrical infrastructure and optimizing grid health through power systems field Inspections and simulations. It integrates digital passports, LPI monitoring, and supports web, mobile, and AI-driven management. systems.",
        image:
          "https://greendragon-production.s3.us-east-2.amazonaws.com/default/POWER-9.png",
      },
      mainHub: {
        source: "Red Dragon",
        title: "Healthy Grids, Reliable Future.",
        description:
          "Design and deploy no-code Digital Passports and AI-ready solutions to digitalize your infrastructure with seamless web and mobile integration. Leverage power system simulations and accurate data for effective campaign management, enhancing grid health, improving team safety, and optimizing response times. Integrate multiple data sources while ensuring regulatory compliance, reducing downtime, and driving profitability and performance.",
      },
    },
    es: {
      signIn: {
        title: "",
        subtitle: "",
        description: "",
      },
      mainHub: {
        title: "",
        description: "",
      },
    },
  },
};
