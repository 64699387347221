import useGet from "hooks/useFetch";
import { useEffect, useState } from "react";
import {
  DynamicColorsContainer,
  DynamicColorsFields,
  LibraryField,
} from "./DynamicLinesColorStyles";
import { updateBackendMapSettings } from "services/updateBackendMapSettings";
import { setMapSettings } from "redux/actions/";
import { useDispatch } from "react-redux";
import {
  getMeasurementUnitSymbol,
  getMeasurementUnits,
} from "utils/measurement";
import SwitchSquared from "../helpers/switchSquared";
import { mutate } from "swr";
import { config } from "config";
import { urls, urlsApiMg } from "utils/urlKeys";

export default function SelectDynamicLinesColor({ configData, setConfigData }) {
  const [fieldsByLibrary, setFieldsByLibrary] = useState([]);
  const [lineLibraries, setLineLibraries] = useState([]);
  const [typeLines, setTypeLines] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [checkUserConfig, setCheckUserConfig] = useState(
    configData.userConfigColors !== undefined &&
      typeof configData.userConfigColors.checkedLines === "boolean"
      ? configData.userConfigColors.checkedLines
      : false
  );
  const [optionsSelectedLinesUserConfig, setOptionsSelectedLinesUserConfig] =
    useState(
      configData.userConfigColors !== undefined &&
        configData.userConfigColors.colorLines.length > 0
        ? configData.userConfigColors.colorLines
        : []
    );
  const [floatingDecimals, setFloatingDecimals] = useState(
    configData.floatingDecimals !== undefined &&
      configData.floatingDecimals >= 0
      ? configData.floatingDecimals
      : "4"
  );

  const dispatch = useDispatch();

  const userId = parseInt(localStorage.getItem("userId"));

  const [libraries, librariesError] = useGet(urls?.lines?.libraries);
  const [typeLinesDB, typeLinesErrorDB] = useGet(urls?.lines?.typeLinesColors);

  useEffect(() => {
    if (
      configData !== undefined &&
      configData.dynamicColorsSelected !== undefined &&
      configData.dynamicColorsSelected.length > 0
    ) {
      setOptionsSelected(configData.dynamicColorsSelected);
    }
  }, [configData]);

  useEffect(() => {
    // set libraries for use
    if (libraries && !librariesError && libraries?.length >= 0) {
      setLineLibraries(libraries);
    }
  }, [libraries, librariesError]);

  useEffect(() => {
    const aux = [];
    // set fields by libraries
    lineLibraries.forEach((ln) => {
      aux.push(parseLibrary(ln, ["select", "number"]));
    });
    setFieldsByLibrary(aux);
  }, [lineLibraries]);

  useEffect(() => {
    try {
      if (typeLinesDB && !typeLinesErrorDB && typeLinesDB?.length >= 0) {
        setTypeLines(typeLinesDB?.map((it) => parseTypes(it)).filter((it) => it));
      }
    } catch (e) { console.log(e) }

  }, [typeLinesDB, typeLinesErrorDB]);

  // updated selected fields on database (MG)
  useEffect(() => {
    const updated = {
      ...configData,
      dynamicColorsSelected: optionsSelected,
    };
    const updateDynamicColors = async () => {
      if (!optionsSelected.length) return;
      await updateBackendMapSettings({
        id: userId,
        body: { dynamicColorsSelected: optionsSelected },
      });
      mutate(urlsApiMg.mapSettings.get);
      mutate(`${config.URL_BACKEND_MG}map-settings/${userId}`);
    };
    updateDynamicColors();
    setConfigData(updated);
    // set state for options selected
    dispatch(setMapSettings({ ...updated, fieldsByLibrary }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsSelected]);

  useEffect(() => {
    const updateDynamicColors = async () => {
      const { measurementUnit } = configData;
      await updateBackendMapSettings({
        id: userId,
        body: {
          measurementUnit,
        },
      });
      mutate(urlsApiMg.mapSettings.get);
      mutate(`${config.URL_BACKEND_MG}map-settings/${userId}`);
    };
    updateDynamicColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData.measurementUnit]);

  useEffect(() => {
    const updateFloatingDecimals = async () => {
      const { floatingDecimals } = configData;
      await updateBackendMapSettings({
        id: userId,
        body: {
          floatingDecimals,
        },
      });
    };
    mutate(urlsApiMg.mapSettings.get);
    updateFloatingDecimals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData.floatingDecimals]);

  // update user configuration
  useEffect(() => {
    const updateDynamicColors = async () => {
      const userConfigColors = {
        ...configData.userConfigColors,
        checkedLines: checkUserConfig,
        colorLines: optionsSelectedLinesUserConfig,
      };
      await updateBackendMapSettings({
        id: userId,
        body: {
          userConfigColors,
        },
      });
      mutate(urlsApiMg.mapSettings.get);
      mutate(`${config.URL_BACKEND_MG}map-settings/${userId}`);
    };
    updateDynamicColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, checkUserConfig, optionsSelectedLinesUserConfig]);

  const handleSelectOption = (libId, fdName) => {
    // selects only one option per library
    const options = optionsSelected
      .map((it) => (it[0] === libId ? [it[0], fdName] : it))
      .map((it) => JSON.stringify(it));
    setOptionsSelected([...new Set(options)].map((it) => JSON.parse(it)));
  };

  const handleSelectOptionUserConfig = (libId, fdName) => {
    const libs = optionsSelectedLinesUserConfig.map((lib) => {
      if (lib.id === libId) {
        const fields = lib.fields
          .map((it) => {
            if (it.name === fdName) it.state = !it.state;
            return it;
          })
          .map((it) => {
            if (it.name !== fdName) it.state = false;
            return it;
          });
        lib.fields = fields;
        return lib;
      }
      return lib;
    });
    setOptionsSelectedLinesUserConfig(libs);
  };

  const checkChecked = (id, name) => {
    return optionsSelected.find((it) => it[0] === id && it[1] === name)
      ? true
      : false;
  };

  const checkCheckedUserConfig = (id, name) => {
    return optionsSelectedLinesUserConfig
      .find((it) => it.id === id) // looks for which library
      ?.fields?.find((it) => it.name === name)?.state; // if exists find the field with the name and return state
  };

  const handleChangeMeasurementUnit = (e) => {
    const value = e.target.value;
    if (!configData && !Object.keys(configData).length) return;
    const unitSymbol = getMeasurementUnitSymbol(value);
    setConfigData((current) => ({
      ...current,
      measurementUnit: unitSymbol,
    }));
    dispatch(
      setMapSettings({
        ...configData,
        measurementUnit: unitSymbol,
      })
    );
  };

  const handleCheckUserConfig = (checked) => {
    setCheckUserConfig(checked);
  };

  // {libId:lib.id, fieldName:field.name, fieldType:field.type, colorIndex:indx, event}
  const handleChangeFieldColor = ({
    libId,
    fieldName,
    fieldType,
    colorIndex,
    event,
    checkboxColor,
  }) => {
    const color = event && event.target.value;
    const libs = optionsSelectedLinesUserConfig.map((lib) => {
      if (lib.id === libId) {
        const fields = lib.fields.map((it) => {
          if (it.name === fieldName && it.type === "number") {
            const restrictions = it.numberGroupingOptions.restrictions; // take numeric restrictions
            const restriction = restrictions.at(colorIndex); // take restriction at specific index
            if (checkboxColor) restriction.state = !restriction.state;
            if (color !== undefined) restriction.color = color; // change color
            restrictions[colorIndex] = restriction; // set restriction changed at restrictions array
            it.numberGroupingOptions.restrictions = restrictions;
            return it;
          }
          if (it.name === fieldName && it.type === "select") {
            const optionsColors = it.optionsColors;
            optionsColors[colorIndex] = color;
            it.optionsColors = optionsColors;
            return it;
          }
          return it;
        });
        lib.fields = fields;
        return lib;
      }
      return lib;
    });
    setOptionsSelectedLinesUserConfig(libs);
  };

  const handleFloatingDecimals = (e) => {
    const rawValue = e.target.value;
    if (rawValue.length) {
      const sanitizedValue = isNaN(parseInt(rawValue)) ? 4 : parseInt(rawValue);
      const numDecimals =
        sanitizedValue >= 0 && sanitizedValue <= 10 ? sanitizedValue : 4;
      setFloatingDecimals(`${numDecimals}`);
      setConfigData({
        ...configData,
        floatingDecimals: numDecimals,
      });
      dispatch(
        setMapSettings({
          ...configData,
          floatingDecimals: numDecimals,
        })
      );
      return;
    }
    setFloatingDecimals("0");
  };

  const handleChangeFieldRestrictionValue = ({
    libId,
    fieldName,
    colorIndex,
    event,
    valueMin = false,
    valueMax = false,
  }) => {
    const value = event && event.target.value && parseFloat(event.target.value);
    const libs = optionsSelectedLinesUserConfig.map((lib) => {
      if (lib.id === libId) {
        const fields = lib.fields.map((it) => {
          if (it.name === fieldName && it.type === "number") {
            const restrictions = it.numberGroupingOptions.restrictions; // take numeric restrictions
            const restriction = restrictions.at(colorIndex); // take restriction at specific index
            if (valueMin) restriction.valueMin = value;
            if (valueMax) restriction.valueMax = value;
            if (!valueMin && !valueMax) restriction.value = value;
            restrictions[colorIndex] = restriction; // set restriction changed at restrictions array
            it.numberGroupingOptions.restrictions = restrictions;
            return it;
          }
          return it;
        });
        lib.fields = fields;
        return lib;
      }
      return lib;
    });
    setOptionsSelectedLinesUserConfig(libs);
  };

  return (
    <DynamicColorsContainer>
      {/* measurements units */}
      <section>
        <b>Measurement units:</b>
        <div className="body-item">
          <div className="select-input">
            <select
              className="input-field select"
              name=""
              onChange={handleChangeMeasurementUnit}
              value={configData?.measurementUnit?.unit || "kilometers"}
            >
              {getMeasurementUnits().map(({ symbol, unit }, index) => (
                <option key={index} value={unit}>
                  {unit} - {symbol}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>
      <section>
        <div className="body-item">
          <div className="object-clusters-title">
            <p>Number of decimals to use on platform floating values:</p>
          </div>
          <div className="items">
            <div className="body-item">
              <label className="label">
                Decimals:
                <input
                  type="text"
                  name="decimals"
                  value={floatingDecimals}
                  onChange={handleFloatingDecimals}
                />
              </label>
            </div>
          </div>
        </div>
      </section>
      <section>
        <p>
          <b>Dynamic Lines Color by Category</b>
        </p>
      </section>
      <br />
      <section>
        <div
          style={{
            display: "flex",
            gap: "20px",
          }}
        >
          <p>Configuration:</p>
          <SwitchSquared
            label={["default", "user"]}
            checked={checkUserConfig}
            onChange={handleCheckUserConfig}
          />
        </div>
      </section>
      <br />
      <DynamicColorsFields>
        {/* default options */}
        {!checkUserConfig &&
          fieldsByLibrary?.length >= 0 &&
          fieldsByLibrary?.map((ln) => {
            return (
              <div>
                <div className="library-name-container">
                  <div
                    style={{
                      width: "50px",
                    }}
                  >
                    Library:
                  </div>{" "}
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    {ln.name}
                  </div>
                </div>
                <div>
                  {/* opcion default - lineType */}
                  <LibraryField>
                    <div className="library-names">
                      <input
                        type="checkbox"
                        value={ln.id}
                        checked={checkChecked(ln.id, "lineType")}
                        onChange={() => handleSelectOption(ln.id, "lineType")}
                      />
                      <p>Line Type</p>
                    </div>
                    <div
                      className="color-container"
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      {typeLines.length >= 0 &&
                        typeLines
                          .filter((tp) => tp[0] === ln.id)
                          .map((item) => (
                            <div className="color-item">
                              <input type="color" value={item[2]} disabled />
                              <p>{item[1]}</p>
                            </div>
                          ))}
                    </div>
                  </LibraryField>
                  {ln.fields.map((fd, indx) => {
                    // list of dynamic fields type select with circuit (only one)
                    return (
                      <ColorOptionElement
                        checkChecked={checkChecked}
                        handleSelectOption={handleSelectOption}
                        lib={ln}
                        field={fd}
                        optionIndex={indx}
                        key={indx}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        {/* user config options */}
        {checkUserConfig &&
          optionsSelectedLinesUserConfig?.length >= 0 &&
          optionsSelectedLinesUserConfig?.map((ln) => {
            if (!ln) return <></>;
            return (
              <div>
                <div className="library-name-container">
                  <div
                    style={{
                      width: "50px",
                    }}
                  >
                    Library:
                  </div>{" "}
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    {ln.name}
                  </div>
                </div>
                <div>
                  {/* opcion default - lineType */}
                  <LibraryField>
                    <div className="library-names">
                      <input
                        type="checkbox"
                        value={ln.id}
                        checked={checkCheckedUserConfig(ln.id, "lineType")}
                        onChange={() =>
                          handleSelectOptionUserConfig(ln.id, "lineType")
                        }
                      />
                      <p>Line Type</p>
                    </div>
                    <div
                      className="color-container"
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      {typeLines.length >= 0 &&
                        typeLines
                          .filter((tp) => tp[0] === ln.id)
                          .map((item) => (
                            <div className="color-item">
                              <input type="color" value={item[2]} disabled />
                              <p>{item[1]}</p>
                            </div>
                          ))}
                    </div>
                  </LibraryField>
                  {ln.fields.map((fd, indx) => {
                    // list of dynamic fields type select with circuit (only one)
                    return (
                      <ColorOptionElement
                        checkChecked={checkCheckedUserConfig}
                        handleSelectOption={handleSelectOptionUserConfig}
                        lib={ln}
                        field={fd}
                        optionIndex={indx}
                        key={indx}
                        checkUserConfig={checkUserConfig}
                        handleChangeFieldColor={handleChangeFieldColor}
                        handleChangeFieldRestrictionValue={
                          handleChangeFieldRestrictionValue
                        }
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
      </DynamicColorsFields>
    </DynamicColorsContainer>
  );
}

function ColorOptionElement({
  optionIndex,
  lib,
  field,
  checkChecked,
  handleSelectOption,
  checkUserConfig = false,
  handleChangeFieldColor = () => { },
  handleChangeFieldRestrictionValue = () => { },
}) {
  if (field.type === "lineType") return <></>;
  return (
    <LibraryField>
      <div className="library-names">
        <input
          type="checkbox"
          value={field.state}
          checked={checkChecked(lib.id, field.name)}
          onChange={() => handleSelectOption(lib.id, field.name)}
        />
        <p>{field.alias}</p>
      </div>
      <div
        className="color-container"
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        {field.type === "select" &&
          field.optionsColors.length !== 0 &&
          field.optionsColors.map((cl, indx) => (
            <div className="color-item">
              {checkUserConfig ? (
                <input
                  type="color"
                  value={cl}
                  onChange={(event) =>
                    handleChangeFieldColor({
                      libId: lib.id,
                      fieldName: field.name,
                      fieldType: field.type,
                      colorIndex: indx,
                      event,
                    })
                  }
                />
              ) : (
                <input type="color" value={cl} disabled />
              )}
              <p>{field.options[indx]}</p>
            </div>
          ))}
        {field.type === "number" &&
          field.numberGroupingOptions.restrictions.length !== 0 &&
          field.numberGroupingOptions.restrictions.map((rt, indx) => {
            return (
              <div className="color-item">
                {checkUserConfig ? (
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div className="color-item-container">
                      <input
                        type="checkbox"
                        value={
                          field?.numberGroupingOptions?.restrictions?.at(indx)
                            ?.state
                        }
                        checked={
                          field?.numberGroupingOptions?.restrictions?.at(indx)
                            ?.state
                        }
                        onChange={() =>
                          handleChangeFieldColor({
                            libId: lib.id,
                            fieldName: field.name,
                            fieldType: field.type,
                            colorIndex: indx,
                            event: undefined,
                            checkboxColor: true,
                          })
                        }
                      />
                      <input
                        type="color"
                        value={rt.color}
                        onChange={(event) =>
                          handleChangeFieldColor({
                            libId: lib.id,
                            fieldName: field.name,
                            fieldType: field.type,
                            colorIndex: indx,
                            event,
                            checkboxColor: false,
                          })
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="color-item-container">
                    <input type="color" value={rt.color} disabled />
                  </div>
                )}
                <div className="label-restriction-container">
                  {rt.label} {rt.symbol}
                </div>
                <div className="input-number-container">
                  {rt.symbol === "><" || rt.symbol === ">=<=" ? (
                    <div className="input-number-container-doble">
                      {checkUserConfig ? (
                        <>
                          <input
                            type="number"
                            value={
                              field?.numberGroupingOptions?.restrictions?.at(
                                indx
                              )?.valueMin
                            }
                            onChange={(event) =>
                              handleChangeFieldRestrictionValue({
                                libId: lib.id,
                                fieldName: field.name,
                                colorIndex: indx,
                                event, // this event with the value
                                valueMin: true,
                              })
                            }
                          />
                          <input
                            type="number"
                            value={
                              field?.numberGroupingOptions?.restrictions?.at(
                                indx
                              )?.valueMax
                            }
                            onChange={(event) =>
                              handleChangeFieldRestrictionValue({
                                libId: lib.id,
                                fieldName: field.name,
                                colorIndex: indx,
                                event, // this event with the value
                                valueMax: true,
                              })
                            }
                          />
                        </>
                      ) : (
                        <p>
                          {rt.valueMin} - {rt.valueMax}
                        </p>
                      )}
                    </div>
                  ) : (
                    <p>
                      {checkUserConfig ? (
                        <input
                          type="number"
                          value={
                            field?.numberGroupingOptions?.restrictions?.at(indx)
                              ?.value
                          }
                          onChange={(event) =>
                            handleChangeFieldRestrictionValue({
                              libId: lib.id,
                              fieldName: field.name,
                              colorIndex: indx,
                              event, // this event with the value
                              valueMin: false,
                              valueMax: false,
                            })
                          }
                        />
                      ) : (
                        rt.value
                      )}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </LibraryField>
  );
}

function parseLibrary(item, types = []) {
  if (item === undefined || !types.length) return null;
  const fields =
    item.fields !== undefined
      ? item.fields.filter((fd) => types.includes(fd.type) && fd.circuit)
      : [];
  return {
    id: item.lineLibraryId || item.id,
    name: item.name,
    fields,
  };
}
/**
 * parses the next structure
 * {
  "id": 1,
  "type": "powerline",
  "color": "#e01b24",
  "adminCompanyId": 1,
  "lineLibraryId": 1
}
 * @param {*} item item from endpoint /type-lines/colors
 * @returns [1, "powerline", "#e01b24"]
 */
function parseTypes(item) {
  if (item === undefined) return null;
  return [item.lineLibraryId, item.type, item.color];
}
