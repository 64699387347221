import { useSWRConfig } from "swr";
import { useEffect, useState } from "react";
import { updateChart } from "services/Dashboard/lineChart";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";
import { sumTrendLine } from "..";
import { urlsApiMg } from "utils/urlKeys";

export const useSetCurrentStyle = ({ dataChart, setChartEditDialog }) => {
  const { mutate } = useSWRConfig();

  const [currentStyleLineDataChart, setCurrentStyleLineDataChart] = useState({
    data: {
      labels: [],
      datasets: [
        {
          label: "line",
          data: dataChart.datasets[0].data,
          borderColor: PRIMARY_COLOR,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 1,
          borderCapStyle: "butt",
          borderJoinStyle: "miter",
          backgroundColorHexadecimal: PRIMARY_COLOR,
          backgroundColor: "rgba(14, 77, 69, 0.2)",
          fill: false,
          pointBackgroundColor: PRIMARY_COLOR,
          pointBorderColor: PRIMARY_COLOR,
          pointBorderWidth: 1,
          pointRadius: 5,
          pointStyle: "circle",
          pointHitRadius: 8,
          pointHoverBackgroundColor: SECOND_COLOR,
          pointHoverBorderColor: SECOND_COLOR,
          pointHoverBorderWidth: 1,
          pointHoverRadius: 10,
          cubicInterpolationMode: "default",
          tension: 0,
          stepped: false,
          spanGaps: false,
          showLine: true,
          hidden: false,
          clip: true,
          _id: "pricipalLine",
        },
        {
          label: "Cumulative Line",
          data: sumTrendLine(dataChart.datasets[0].data),
          borderColor: SECOND_COLOR,
          borderWidth: 2,
          borderDash: [5, 8],
          borderDashOffset: 1,
          borderCapStyle: "butt",
          borderJoinStyle: "miter",
          backgroundColorHexadecimal: SECOND_COLOR,
          backgroundColor: "rgba(49, 166, 54, 0.2)",
          fill: false,
          pointBackgroundColor: SECOND_COLOR,
          pointBorderColor: SECOND_COLOR,
          pointBorderWidth: 1,
          pointRadius: 5,
          pointStyle: "triangle",
          pointHitRadius: 8,
          pointHoverBackgroundColor: PRIMARY_COLOR,
          pointHoverBorderColor: PRIMARY_COLOR,
          pointHoverBorderWidth: 1,
          pointHoverRadius: 10,
          cubicInterpolationMode: "default",
          tension: 0,
          stepped: false,
          spanGaps: false,
          showLine: true,
          hidden: true,
          clip: true,
          _id: "cumulativeLine",
        },
      ],
    },
    currentNameLineChart: "",
    currentTitleColor: "",
    currentTotalFontColor: "#EEEEEE",
    currentTotalBackground: "#EEEEEE",
    currentAxisXColor: "#EEEEEE",
    currentAxisYColor: "#EEEEEE",
    currentLabelsXColor: "",
    currentLabelsYColor: "",
    currentTitleXColor: "#EEEEEE",
    currentTitleYColor: "#EEEEEE",
    currentTotalPosition: "top",
    currentTotalFontSize: 15,
    currentTitleFontSize: 25,
    currentTitleTooltipFontSize: 15,
    currentBodyTooltipFontSize: 13,
    currentLabelsXFontSize: 15,
    currentLabelsYFontSize: 15,
    currentTitleXFontSize: 15,
    currentTitleYFontSize: 15,
    currentTitleAxisX: "",
    currentDisplayAxisX: false,
    currentTitleAxisY: "",
    currentDisplayAxisY: false,
    currentDisplayTotal: false,
    currentDisplayBackgroundTotal: false,
    currentDisplayTitleX: false,
    currentDisplayTitleY: false,
    currentStatusBeginAtZero: false,
    currentMinValueAxis: 0,
    currentMaxValueAxis: null,
    currentStepSizeY: 5,
    currentPaddingAxisY: 5,
    currentPaddingAxisX: 5,
    currentTotalNumDecimals: null,
    currentDisplayShowCerosTotal: true,
  });

  const [constantLine, setConstantLine] = useState({
    create: false,
    label: "",
    borderColor: PRIMARY_COLOR,
    borderColorHexadecimal: PRIMARY_COLOR,
    constantValue: 0,
    currentTotalNumDecimals: null,
  });

  useEffect(() => {
    setCurrentStyleLineDataChart((current) => {
      const baseDataset = {
        data: dataChart.datasets[0].data,
        stepped: false,
        ...dataChart.lineStyle,
      };

      const trendLineDataset = {
        label: dataChart.trendLine?.label || "",
        data: sumTrendLine(dataChart.datasets[0].data),
        stepped: false,
        borderDash: [5, 8],
        ...dataChart.trendLine,
      };

      const datasets = dataChart.hasOwnProperty("lineStyle")
        ? [baseDataset, trendLineDataset, ...dataChart.dataSeriesConstant]
        : [...current.data.datasets, ...dataChart.dataSeriesConstant];

      return {
        ...current,
        data: {
          labels: dataChart.labels,
          datasets: datasets.map((elm) => {
            if (elm.stepped === "false") {
              return {
                ...elm,
                stepped: false,
              };
            }
            return elm;
          }),
        },
        currentNameLineChart: dataChart.alias,
        currentTitleColor: dataChart.chartStyle.colorTitle,
        currentTotalFontColor: dataChart.chartStyle.datalabels.colorTotal,
        currentTotalBackground:
          dataChart.chartStyle.datalabels.backgroundColorTotal,
        currentAxisXColor: dataChart.chartStyle.scales.x.axisColor,
        currentAxisYColor: dataChart.chartStyle.scales.y.axisColor,
        currentLabelsXColor: dataChart.chartStyle.scales.x.labelsColor,
        currentLabelsYColor: dataChart.chartStyle.scales.y.labelsColor,
        currentTitleXColor: dataChart.chartStyle.scales.x.titleAxis.colorTitle,
        currentTitleYColor: dataChart.chartStyle.scales.y.titleAxis.colorTitle,
        currentTotalPosition: dataChart.chartStyle.datalabels.positionTotal,
        currentTotalFontSize: dataChart.chartStyle.datalabels.fontSizeTotal,
        currentTitleFontSize: dataChart.chartStyle.fontSizeTitle,
        currentTitleTooltipFontSize: dataChart.chartStyle.tooltip.fontSizeTitle,
        currentBodyTooltipFontSize: dataChart.chartStyle.tooltip.fontSizeBody,
        currentLabelsXFontSize: dataChart.chartStyle.scales.x.labelsFontSize,
        currentLabelsYFontSize: dataChart.chartStyle.scales.y.labelsFontSize,
        currentTitleXFontSize:
          dataChart.chartStyle.scales.x.titleAxis.fontSizeTitle,
        currentTitleYFontSize:
          dataChart.chartStyle.scales.y.titleAxis.fontSizeTitle,
        currentTitleAxisX:
          dataChart.chartStyle.scales.x.titleAxis.text ||
          dataChart.columnX.name,
        currentDisplayAxisX: dataChart.chartStyle.scales.x.displayAxis,
        currentTitleAxisY:
          dataChart.chartStyle.scales.y.titleAxis.text ||
          (dataChart.type === "count" ? "Count" : dataChart.columnsY[0]),
        currentDisplayAxisY: dataChart.chartStyle.scales.y.displayAxis,
        currentDisplayTotal: dataChart.chartStyle.datalabels.displayTotal,
        currentDisplayBackgroundTotal:
          dataChart.chartStyle.datalabels.displayBackgroundTotal,
        currentDisplayTitleX:
          dataChart.chartStyle.scales.x.titleAxis.displayTitle,
        currentDisplayTitleY:
          dataChart.chartStyle.scales.y.titleAxis.displayTitle,
        currentStatusBeginAtZero: dataChart.chartStyle.scales.y.beginAtZero,
        currentMinValueAxis: dataChart.chartStyle.scales.y.minValueAxis,
        currentMaxValueAxis: dataChart.chartStyle.scales.y.maxValueAxis,
        currentStepSizeY: dataChart.chartStyle.scales.y.stepSizeY,
        currentPaddingAxisY: dataChart.chartStyle.scales.y.paddingAxis,
        currentPaddingAxisX: dataChart.chartStyle.scales.x.paddingAxis,
        currentTotalNumDecimals: dataChart.chartStyle.datalabels.numDecimals,
        currentDisplayShowCerosTotal: dataChart.chartStyle.datalabels.showCeros,
      };
    });
  }, [dataChart]);

  const handleUpdateChart = async () => {
    const { data } = await updateChart({
      id: dataChart._id,
      body: {
        ...dataChart,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        alias: currentStyleLineDataChart.currentNameLineChart,
        chartStyle: {
          ...dataChart.chartStyle,
          colorTitle: currentStyleLineDataChart.currentTitleColor,
          fontSizeTitle: currentStyleLineDataChart.currentTitleFontSize,
          datalabels: {
            ...dataChart.chartStyle.datalabels,
            displayTotal: currentStyleLineDataChart.currentDisplayTotal,
            positionTotal: currentStyleLineDataChart.currentTotalPosition,
            colorTotal: currentStyleLineDataChart.currentTotalFontColor,
            displayBackgroundTotal:
              currentStyleLineDataChart.currentDisplayBackgroundTotal,
            backgroundColorTotal:
              currentStyleLineDataChart.currentTotalBackground,
            fontSizeTotal: currentStyleLineDataChart.currentTotalFontSize,
            showCeros: currentStyleLineDataChart.currentDisplayShowCerosTotal,
            numDecimals: currentStyleLineDataChart.currentTotalNumDecimals,
          },
          scales: {
            x: {
              ...dataChart.chartStyle.scales.x,
              titleAxis: {
                displayTitle: currentStyleLineDataChart.currentDisplayTitleX,
                text: currentStyleLineDataChart.currentTitleAxisX,
                colorTitle: currentStyleLineDataChart.currentTitleXColor,
                fontSizeTitle: currentStyleLineDataChart.currentTitleXFontSize,
              },
              displayAxis: currentStyleLineDataChart.currentDisplayAxisX,
              axisColor: currentStyleLineDataChart.currentAxisXColor,
              labelsFontSize: currentStyleLineDataChart.currentLabelsXFontSize,
              labelsColor: currentStyleLineDataChart.currentLabelsXColor,
              paddingAxis: currentStyleLineDataChart.currentPaddingAxisX,
            },
            y: {
              ...dataChart.chartStyle.scales.y,
              titleAxis: {
                displayTitle: currentStyleLineDataChart.currentDisplayTitleY,
                text: currentStyleLineDataChart.currentTitleAxisY,
                colorTitle: currentStyleLineDataChart.currentTitleYColor,
                fontSizeTitle: currentStyleLineDataChart.currentTitleYFontSize,
              },
              displayAxis: currentStyleLineDataChart.currentDisplayAxisY,
              axisColor: currentStyleLineDataChart.currentAxisYColor,
              labelsFontSize: currentStyleLineDataChart.currentLabelsYFontSize,
              labelsColor: currentStyleLineDataChart.currentLabelsYColor,
              beginAtZero: currentStyleLineDataChart.currentStatusBeginAtZero,
              minValueAxis: currentStyleLineDataChart.currentMinValueAxis,
              maxValueAxis: currentStyleLineDataChart.currentMaxValueAxis,
              stepSizeY: currentStyleLineDataChart.currentStepSizeY,
              paddingAxis: currentStyleLineDataChart.currentPaddingAxisY,
            },
          },
          tooltip: {
            ...dataChart.chartStyle.tooltip,
            fontSizeTitle:
              currentStyleLineDataChart.currentTitleTooltipFontSize,
            fontSizeBody: currentStyleLineDataChart.currentBodyTooltipFontSize,
          },
        },
        lineStyle: {
          label: currentStyleLineDataChart.data.datasets[0].label,
          alias: currentStyleLineDataChart.data.datasets[0].alias,
          borderColor: currentStyleLineDataChart.data.datasets[0].borderColor,
          borderWidth: currentStyleLineDataChart.data.datasets[0].borderWidth,
          borderDash: currentStyleLineDataChart.data.datasets[0].borderDash,
          borderDashOffset:
            currentStyleLineDataChart.data.datasets[0].borderDashOffset,
          borderCapStyle:
            currentStyleLineDataChart.data.datasets[0].borderCapStyle,
          borderJoinStyle:
            currentStyleLineDataChart.data.datasets[0].borderJoinStyle,
          backgroundColorHexadecimal:
            currentStyleLineDataChart.data.datasets[0]
              .backgroundColorHexadecimal,
          backgroundColor:
            currentStyleLineDataChart.data.datasets[0].backgroundColor,
          fill: currentStyleLineDataChart.data.datasets[0].fill,
          pointBackgroundColor:
            currentStyleLineDataChart.data.datasets[0].pointBackgroundColor,
          pointBorderColor:
            currentStyleLineDataChart.data.datasets[0].pointBorderColor,
          pointBorderWidth:
            currentStyleLineDataChart.data.datasets[0].pointBorderWidth,
          pointRadius: currentStyleLineDataChart.data.datasets[0].pointRadius,
          pointStyle: currentStyleLineDataChart.data.datasets[0].pointStyle,
          pointHitRadius:
            currentStyleLineDataChart.data.datasets[0].pointHitRadius,
          pointHoverBackgroundColor:
            currentStyleLineDataChart.data.datasets[0]
              .pointHoverBackgroundColor,
          pointHoverBorderColor:
            currentStyleLineDataChart.data.datasets[0].pointHoverBorderColor,
          pointHoverBorderWidth:
            currentStyleLineDataChart.data.datasets[0].pointHoverBorderWidth,
          pointHoverRadius:
            currentStyleLineDataChart.data.datasets[0].pointHoverRadius,
          cubicInterpolationMode:
            currentStyleLineDataChart.data.datasets[0].cubicInterpolationMode,
          tension: currentStyleLineDataChart.data.datasets[0].tension,
          stepped: currentStyleLineDataChart.data.datasets[0].stepped,
          spanGaps: currentStyleLineDataChart.data.datasets[0].spanGaps,
          showLine: currentStyleLineDataChart.data.datasets[0].showLine,
          hidden: currentStyleLineDataChart.data.datasets[0].hidden,
          clip: currentStyleLineDataChart.data.datasets[0].clip,
          datalabels: currentStyleLineDataChart.data.datasets[0].datalabels,
        },
        trendLine: {
          label: currentStyleLineDataChart.data.datasets[1].label,
          alias: currentStyleLineDataChart.data.datasets[1].alias,
          borderColor: currentStyleLineDataChart.data.datasets[1].borderColor,
          borderWidth: currentStyleLineDataChart.data.datasets[1].borderWidth,
          borderDash: currentStyleLineDataChart.data.datasets[1].borderDash,
          borderDashOffset:
            currentStyleLineDataChart.data.datasets[1].borderDashOffset,
          borderCapStyle:
            currentStyleLineDataChart.data.datasets[1].borderCapStyle,
          borderJoinStyle:
            currentStyleLineDataChart.data.datasets[1].borderJoinStyle,
          backgroundColorHexadecimal:
            currentStyleLineDataChart.data.datasets[1]
              .backgroundColorHexadecimal,
          backgroundColor:
            currentStyleLineDataChart.data.datasets[1].backgroundColor,
          fill: currentStyleLineDataChart.data.datasets[1].fill,
          pointBackgroundColor:
            currentStyleLineDataChart.data.datasets[1].pointBackgroundColor,
          pointBorderColor:
            currentStyleLineDataChart.data.datasets[1].pointBorderColor,
          pointBorderWidth:
            currentStyleLineDataChart.data.datasets[1].pointBorderWidth,
          pointRadius: currentStyleLineDataChart.data.datasets[1].pointRadius,
          pointStyle: currentStyleLineDataChart.data.datasets[1].pointStyle,
          pointHitRadius:
            currentStyleLineDataChart.data.datasets[1].pointHitRadius,
          pointHoverBackgroundColor:
            currentStyleLineDataChart.data.datasets[1]
              .pointHoverBackgroundColor,
          pointHoverBorderColor:
            currentStyleLineDataChart.data.datasets[1].pointHoverBorderColor,
          pointHoverBorderWidth:
            currentStyleLineDataChart.data.datasets[1].pointHoverBorderWidth,
          pointHoverRadius:
            currentStyleLineDataChart.data.datasets[1].pointHoverRadius,
          cubicInterpolationMode:
            currentStyleLineDataChart.data.datasets[1].cubicInterpolationMode,
          tension: currentStyleLineDataChart.data.datasets[1].tension,
          stepped: currentStyleLineDataChart.data.datasets[1].stepped,
          spanGaps: currentStyleLineDataChart.data.datasets[1].spanGaps,
          showLine: currentStyleLineDataChart.data.datasets[1].showLine,
          hidden: currentStyleLineDataChart.data.datasets[1].hidden,
          clip: currentStyleLineDataChart.data.datasets[1].clip,
          datalabels: currentStyleLineDataChart.data.datasets[1].datalabels,
        },
        dataSeriesConstant: currentStyleLineDataChart.data.datasets
          .filter((elm) => elm.constantValue !== null)
          .map((elm) => {
            const { _id, ...rest } = elm;
            return rest;
          }),
      },
    });
    if (data) {
      mutate(
        urlsApiMg.dashboard.lineChart(
          dataChart.fromType ? dataChart.fromType : dataChart.from
        )
      );
      setChartEditDialog(false);
    }
  };

  const handleCreateConstantLine = () => {
    const newGoalLine = {
      _id: constantLine.label,
      label: constantLine.label,
      alias: constantLine.label,
      borderColor: constantLine.borderColorHexadecimal,
      borderWidth: 2,
      borderDash: [5, 5],
      borderDashOffset: 1,
      borderCapStyle: "butt",
      borderJoinStyle: "miter",
      backgroundColorHexadecimal: constantLine.borderColorHexadecimal,
      backgroundColor: constantLine.borderColor,
      fill: false,
      pointBackgroundColor: constantLine.borderColorHexadecimal,
      pointBorderColor: "#0E4D45",
      pointBorderWidth: 0,
      pointRadius: 0,
      pointStyle: "circle",
      pointHitRadius: 0,
      pointHoverBackgroundColor: constantLine.borderColorHexadecimal,
      pointHoverBorderColor: constantLine.borderColorHexadecimal,
      pointHoverBorderWidth: 0,
      pointHoverRadius: 0,
      cubicInterpolationMode: "default",
      tension: 0,
      stepped: false,
      spanGaps: false,
      showLine: true,
      hidden: false,
      datalabels: {
        displayTotal: false,
        positionTotal: "end",
        colorTotal: "#EEEEEE",
        displayBackgroundTotal: false,
        backgroundColorTotal: "rgba(255, 255, 255, 0)",
        fontSizeTotal: 10,
        offsetTotal: 3,
      },
      clip: true,
      constantValue: constantLine.constantValue,
      data: new Array(
        currentStyleLineDataChart.data.datasets[0].data.length
      ).fill(constantLine.constantValue),
    };
    setCurrentStyleLineDataChart((current) => ({
      ...current,
      data: {
        ...current.data,
        datasets: [...current.data.datasets, newGoalLine],
      },
    }));
    setConstantLine({
      create: false,
      label: "",
      borderColor: PRIMARY_COLOR,
      borderColorHexadecimal: PRIMARY_COLOR,
      constantValue: 0,
    });
  };

  const handleDeleteConstantLine = ({ id }) => {
    setCurrentStyleLineDataChart((current) => ({
      ...current,
      data: {
        ...current.data,
        datasets: current.data.datasets.filter((elm) => elm._id !== id),
      },
    }));
  };

  return {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
    handleCreateConstantLine,
    handleDeleteConstantLine,
    constantLine,
    setConstantLine,
  };
};
