import Typography from "@mui/material/Typography";
// check layer
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// custom style
import {
  WrapperTypeObjectTitle,
  useStyles,
  CustomizedAccordion,
} from "./AccordionStyles";

// redux
import { useDispatch, useSelector } from "react-redux";
import { showMarkerByElementWithMedia } from "redux/actions/admin";

import {
  showLabels,
  setShowLines,
  setShowTrackings,
  showComponentLabels,
  showEventLabels,
} from "redux/actions/digitalTwin";
import { useEffect, useRef, useState } from "react";

import { PRIMARY_COLOR, VISIBILITY_OFF_ICON } from "utils/const";

const AccordionSpecialFilters = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let showElementWitheMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );

  let showLabelsElements = useSelector(
    (state) => state.digitalTwinReducer.showLabels
  );

  const showComponentsLabels = useSelector(
    (state) => state.digitalTwinReducer.showComponentLabels
  );

  const showEventsLabels = useSelector(
    (state) => state.digitalTwinReducer.showEventLabels
  );

  const showLines = useSelector((state) => state.digitalTwinReducer.showLines);
  const showTrackings = useSelector(
    (state) => state.digitalTwinReducer.showTrackings
  );
  const [hasHiddenFilters, setHasHiddenFilters] = useState(false);
  const containerRef = useRef(null);

  const setShowLinesHandler = (change) => {
    dispatch(setShowLines(change));
  };

  const setShowTrackingsHandler = (change) => {
    dispatch(setShowTrackings(change));
  };

  const handlerClickFilterMedia = () => {
    showElementWitheMedia = !showElementWitheMedia;
    dispatch(showMarkerByElementWithMedia(showElementWitheMedia));
  };

  const handlerClickShowLabel = () => {
    showLabelsElements = !showLabelsElements;
    dispatch(showLabels(showLabelsElements));
  };

  const handlerClickShowComponentLabel = () => {
    dispatch(showComponentLabels(!showComponentsLabels));
  };

  const handlerClickShowEventlabel = () => {
    dispatch(showEventLabels(!showEventsLabels));
  };

  // Detectar si hay algún "iconEyeOff" en el DOM
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const hiddenIcons = container.querySelectorAll(".iconEyeOff");
      setHasHiddenFilters(hiddenIcons.length > 0); // Hay ojos apagados
    }
  }, [showElementWitheMedia, showTrackings]);

  return (
    <>
      <CustomizedAccordion
        ref={containerRef}
        className="background-1 border-color-1"
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={false}
        style={{
          borderRadius: "4px", marginBottom: "1rem", boxShadow: "none",
          border: hasHiddenFilters ? "1px solid #ff7c36" : "1px solid #bebebe", // Borde dinámico
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontFamily: document.body.style.fontFamily,
              color: document.body.style.color,
              fontWeight: "bold",
            }}
          >
            Special Filters
          </Typography>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="color1" />}
          ></AccordionSummary>
        </div>
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {showElementWitheMedia ? (
              <VisibilityOffIcon
                sx={{
                  margin: "10px 15px",
                }}
                className="iconEyeOff"
                onClick={handlerClickFilterMedia}
              />
            ) : (
              <VisibilityIcon
                sx={{
                  margin: "10px 10px 10px 15px",
                }}
                className="iconEyeOn"
                onClick={handlerClickFilterMedia}
              />
            )}
            <p>Objects with media</p>
          </div>
        </WrapperTypeObjectTitle>
        {/* <WrapperTypeObjectTitle>
          <div className="icon-title">
            {showLines ? (
              <VisibilityIcon
                sx={{
                  margin: "10px 10px 10px 15px",
                }}
                className="iconEyeOn"
                onClick={() => setShowLinesHandler(false)}
              />
            ) : (
              <VisibilityOffIcon
                sx={{
                  margin: "10px 15px",
                }}
                className="iconEyeOff"
                onClick={() => setShowLinesHandler(true)}
              />
            )}
            <p>Lines</p>
          </div>
        </WrapperTypeObjectTitle> */}
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {showTrackings ? (
              <VisibilityIcon
                sx={{
                  margin: "10px 10px 10px 15px",
                }}
                className="iconEyeOn"
                onClick={() => setShowTrackingsHandler(false)}
              />
            ) : (
              <VisibilityOffIcon
                sx={{
                  margin: "10px 15px",
                }}
                className="iconEyeOff"
                onClick={() => setShowTrackingsHandler(true)}
              />
            )}
            <p>Trackings</p>
          </div>
        </WrapperTypeObjectTitle>
      </CustomizedAccordion>
    </>
  );
};

export default AccordionSpecialFilters;
