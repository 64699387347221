import { useState, useEffect } from "react";
import OpenDialog from "components/Dialogs/OpenDialog";
import checkCSVHeaders from "helpers/checkCSVFileHeaders";
import { LoadCsv, CustomInput, ContentConfirm } from "./LoadCSVStyle";
import { LinearProgress, Typography, Box } from "@mui/material";

import { useSocket } from "hooks/useSocket";
import { uploadCSVObjectsWithCmpEvtsRelations } from "services/objects/uploadCSVObjectsWithCmpEvtsRelations";
import { useUserStore } from "zustandGloabalStore/user";

export const LoadCSVObjectsWithCmpEvtRelations = () => {
  const socket = useSocket();

  const [haveObjects, setHaveObjects] = useState(false);
  const [mediaMessage, setMediaMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fileName, setFileName] = useState("");
  const [autocomplete, setAutocomplete] = useState(false);
  const [saveProgress, setSaveProgress] = useState();
  const [batchComplete, setBatchComplete] = useState();
  const [bulkSaveComplete, setBulkComplete] = useState();
  const { instanceId } = useUserStore((state) => state);

  useEffect(() => {
    const handleSocketEvent = (event, setDataFlag) => {
      if (socket) {
        socket.on(event, (data) => {
          setDataFlag(data);
        });
        return () => {
          socket.off(event);
        };
      }
    };

    const cleanupSocketListeners = [
      handleSocketEvent("saveProgress", setSaveProgress),
      handleSocketEvent("batchComplete", setBatchComplete),
      handleSocketEvent("bulkSaveComplete", setBulkComplete),
    ];

    return () => {
      cleanupSocketListeners.forEach((cleanup) => cleanup());
    };
  }, [socket]);

  // handle file upload
  const handleFileUploadCSV = (e) => {
    const file = e.target.files[0];
    setFileName(file);
    setShowConfirm(true);
    e.target.value = "";
  };

  // read the upload file
  const loadCSVFile = async (file) => {
    if (!file || !(file instanceof File)) {
      setErrorMessage(true);
      return;
    }

    const readFileAsText = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file, "utf-8");
      });
    };

    try {
      const rawString = await readFileAsText(file);

      if (rawString.length === 0) {
        setHaveObjects(true);
        setErrorMessage(true);
        setShowConfirm(false);
        return;
      }

      if (
        checkCSVHeaders(rawString, [
          "typePoint",
          "typeId",
          "pointName",
          "pointRelationship",
          "longitude",
          "latitude",
        ])
      ) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("instanceId", instanceId);
        await uploadCSVObjectsWithCmpEvtsRelations({
          body: formData,
          autocomplete,
        });
        setShowConfirm(false);
      } else {
        setHaveObjects(true);
        setErrorMessage(true);
        setShowConfirm(false);
      }
    } catch (error) {
      console.error("Error reading or processing file:", error);
      setErrorMessage(true);
    }
  };

  const ProgressBar = ({ value }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress color="success" variant="determinate" value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography>{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const UploadProgress = ({
    saveProgress,
    batchComplete,
    bulkSaveComplete,
  }) => {
    if (!saveProgress && !batchComplete && !bulkSaveComplete) {
      return null;
    }

    let progress = 0;
    let statusText = "";

    if (saveProgress) {
      progress = (saveProgress.saved / saveProgress.total) * 100;
      statusText = `Saving: ${saveProgress.saved} / ${saveProgress.total}`;
    } else if (batchComplete) {
      progress = (batchComplete.saved / batchComplete.total) * 100;
      statusText = `Batch complete: ${batchComplete.saved} saved, ${batchComplete.errors} errors`;
    } else if (bulkSaveComplete) {
      progress = 100;
      statusText = `Upload complete: ${bulkSaveComplete.saved} saved, ${bulkSaveComplete.errors} errors`;
    }

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <ProgressBar value={progress} />
        <Typography variant="body2" sx={{ mt: 1 }}>
          {statusText}
        </Typography>
      </Box>
    );
  };

  return (
    <LoadCsv>
      <label
        htmlFor="csvObjectsWithRelations"
        style={{ textTransform: "none" }}
      >
        Add Data
      </label>
      <CustomInput
        style={{
          visibility: "hidden",
        }}
        id="csvObjectsWithRelations"
        type="file"
        name="file"
        accept=".csv"
        onChange={handleFileUploadCSV}
      />
      <OpenDialog
        openDialog={showConfirm}
        setOpenDialog={setShowConfirm}
        execute={() => loadCSVFile(fileName)}
        content={{
          title: "Confirm upload",
          description: (
            <div>
              <ContentConfirm>
                <section>
                  <div>
                    <p
                      style={{
                        fontSize: "2rem",
                      }}
                    >
                      Are you sure you want to load this
                    </p>
                    <strong>{fileName?.name}</strong>
                    <p>?</p>
                  </div>
                  <br />
                  <div className="autocomplete">
                    <p
                      style={{
                        fontSize: "1.6rem",
                      }}
                    >
                      The file contains attributes of type autocomplete?
                    </p>

                    <div className="contairer-inputs">
                      <label>Yes</label>
                      <input
                        type="checkbox"
                        checked={autocomplete}
                        onChange={() => setAutocomplete(true)}
                      />
                      <label>Not</label>
                      <input
                        type="checkbox"
                        checked={!autocomplete}
                        onChange={() => setAutocomplete(false)}
                      />
                    </div>
                  </div>
                </section>
              </ContentConfirm>
              <UploadProgress
                saveProgress={saveProgress}
                batchComplete={batchComplete}
                bulkSaveComplete={bulkSaveComplete}
              />
            </div>
          ),
          disagree: "Cancel",
          agree: "Upload file",
        }}
      />
      <OpenDialog
        openDialog={errorMessage}
        setOpenDialog={setErrorMessage}
        content={{
          title: "Invalid Format",
          description:
            "Cannot read the file. Please ensure that the format is valid and try again.",
          disagree: "Continue",
        }}
      />
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        content={{
          title: "No Items",
          description:
            "The file that was uploaded is empty. Please provide another one.",
          disagree: "See map",
        }}
      />
      <OpenDialog
        openDialog={mediaMessage}
        setOpenDialog={setMediaMessage}
        content={{
          title: "No Media Files",
          description:
            "The file that was uploaded does not have any media. If you want to add pictures, click on the marker icon and select add pictures option.",
          disagree: "Ok",
        }}
      />
    </LoadCsv>
  );
};
