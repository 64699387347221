// React
import { useState } from "react";
import { useEffect } from "react";
// Components
import DeleteComponentButton from "./DeleteComponent";
import { InfoTitle } from "../ImageDetailsElements";
import ConvertButton from "./convertComponent";
import OperationInfo from "../OperationInfo";
import DynamicFields from "components/Forms/SaveComponent/DynamicFields";
import CustomButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
// Helpers
import { isUrl, nameUrl, handleClickLink } from "helpers/isUrl";
import isoDateToString from "helpers/isoDateToString";
import { formatFloat } from "helpers/formatFloat";
import { orderAttributes } from "components/ElementDetails/helpers";
// Dependencies
import { Grid } from "@mui/material";
import { useSWRConfig } from "swr";
// Config
import { config } from "config.js";
// Hooks
import useShowDetails from "hooks/useShowDetails";
import { useFetchComponentsLibraries } from "hooks/fetchLibraries";
import useUpdateLocationPoint from "hooks/useUpdateLocationPoint";
import CreateEvent from "components/Maps/ComponentMap/CreateEvent";

// Styles
import { Wrapperbtn } from "./ComponentDetailsStyle";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";

import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  BLACK_COLOR,
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  WHITE_COLOR,
} from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import { LineTag } from "pages/ConfigAdmin/typeElemts/FieldsStyle";
import FetchTypeEventsTypeComponents from "hooks/FetchTypeEventsTypeComponents";

const getStyle = (theme) => ({
  color: theme === "light" ? document.body.style.color : WHITE_COLOR,
  background: theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
});

export default function TableComponent({ component, deleteButton }) {
  delete component?.object;
  const { mutate } = useSWRConfig();
  const [openDialog, setOpenDialog] = useState(false);
  const [infoOperation, setInfoOperation] = useState({});
  const { data: dataLibrary, error: errorLibrary } =
    useFetchComponentsLibraries({
      id: component?.pointTypeComponent?.pointLibraryComponentId,
    });
  const { theme } = useContext(ThemeContext);
  const colorStyle = getStyle(theme);

  //create event no geografic
  const [showCreateEventForm, setShowCreateEventForm] = useState(false);
  const [selectedComponentId, setSelectedComponentId] = useState(null);
  const [selectedTypeEvents, setSelectedTypeEvents] = useState([]);

  const {
    data: { updateLongitude, updateLatitude, updateLocation },
    setData: { setUpdateLongitude, setUpdateLatitude, setUpdateLocation },
    handleUpdateLocation,
    handleCancelUpdate,
  } = useUpdateLocationPoint({ type: "component", point: component });

  const { handlerClickObjectId } = useShowDetails();
  const shouldShowTooltip = true;

  useEffect(() => {
    const omit = [
      "mongoId",
      "userId",
      "locationComponentId",
      "pointTypeComponentId",
      "adminCompanyId",
      "locationComponent",
      "point_library_component_id",
      "pointTypeComponent",
      "attributes",
      "date",
      "componentFiles",
      "user",
      "fieldsCaptureMedia",
      "media",
      "timeZoneOffset",
      "timeZone",
      "createdAt",
      "updatedAt",
      "id",
      "comments",
    ];

    Object.keys(component).map((key) => {
      if (omit.includes(key) || key === "QAQC") return null;
      let value = component[key];
      if (!value) {
        return null;
      }
      setInfoOperation((prev) => ({ ...prev, [key]: value }));
      return null;
    });

    // Find user name
    const { adminCompany, operator } = component?.user;
    const { firstName, firstLastName } = adminCompany || operator;

    setInfoOperation((prev) => ({
      ...prev,
      ID: component?.id,
      "Created By": firstName + " " + firstLastName,
      "Created At": isoDateToString(component?.createdAt),
      "Updated At": isoDateToString(component?.updatedAt),
      "Collection Date": isoDateToString(component?.date),
      Latitude: component?.locationComponent?.latitude || 0,
      Longitude: component?.locationComponent?.longitude || 0,
    }));
  }, [component]);

  const reduceAttributes = () => {
    !errorLibrary &&
      component?.attributes?.forEach((attribute) => {
        const matchAlias = dataLibrary?.[0]?.fields?.find(
          (fields) => fields.name === attribute.name
        );
        if (matchAlias) {
          if (matchAlias.QAQC && !matchAlias.alias.includes("✅")) {
            return (attribute.alias = matchAlias.alias + "✅");
          }
          return (attribute.alias = matchAlias.alias);
        }
      });

    const filteredAttributes = component?.attributes?.map((item) => {
      let newItem = { ...item };
      try {
        if (typeof JSON.parse(item.value) === "object")
          newItem.value = JSON.parse(item.value);
      } catch (error) {
        return item;
      }
      return newItem;
    });

    const lineTagAttributes =
      dataLibrary?.[0]?.fields?.filter((field) => field.type === "lineTag") ||
      [];

    const combinedAttributes = [...filteredAttributes, ...lineTagAttributes];

    const newOrderAttributes = orderAttributes({
      // arrayToOrder: filteredAttributes,
      arrayToOrder: combinedAttributes,
      fromArray: dataLibrary,
    });

    return newOrderAttributes;
  };

  const handleUpdateData = () => {
    mutate(
      `${config.URL_BACKEND_PG}api/v1/point-components?id=${component.id}`
    );
  };

  const { data: typeEventsTypeComponents } = FetchTypeEventsTypeComponents(
    component?.pointTypeComponentId
  );

  const handleOpenCreateEventForm = (componentId, typeEvents) => {
    setSelectedComponentId(componentId);
    setSelectedTypeEvents(typeEvents);
    setShowCreateEventForm(true);
  };

  return (
    <InfoTitle>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        content={{
          title: "Update Component",
          description: (
            <DynamicFields
              dataComponent={component}
              update={true}
              setOpenDialog={setOpenDialog}
              handleUpdateData={handleUpdateData}
            />
          ),
          disagree: "Cancel",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />

      <OpenDialog
        openDialog={showCreateEventForm}
        setOpenDialog={setShowCreateEventForm}
        content={{
          title: "Create Non-Georeferenced Event",
          description: (
            <CreateEvent
              pointComponentId={selectedComponentId}
              typeEventsTypeComponents={selectedTypeEvents}
              closeModal={setShowCreateEventForm}
              isGeographic={false}
            />
          ),
          disagree: "Cancel",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <section className="main-tables-container">
          <div className="main-currency-table">
            <div className="currency-table--container">
              <table>
                {Object.keys(infoOperation).map((key) => {
                  if (key === "objectId")
                    return (
                      <tr style={colorStyle}>
                        <td>Object Relation Id</td>
                        <td className="table__right">
                          <p
                            className="object-id"
                            onClick={() =>
                              handlerClickObjectId(infoOperation[key])
                            }
                          >
                            {infoOperation[key]}
                          </p>
                        </td>
                      </tr>
                      // </Contt>
                    );
                  return (
                    <tr style={colorStyle}>
                      <td>
                        <div>
                          {key}
                          {key === "Longitude" && updateLongitude && (
                            <>
                              <button style={{ marginLeft: "1rem" }}>
                                <DoneIcon
                                  sx={{
                                    width: "2rem",
                                    color: PRIMARY_COLOR,
                                    "&:hover": {
                                      color: SECOND_COLOR,
                                    },
                                  }}
                                  onClick={() =>
                                    handleUpdateLocation({
                                      coordinate: "longitude",
                                    })
                                  }
                                />
                              </button>
                              <button
                                onClick={() =>
                                  handleCancelUpdate({
                                    coordinate: "longitude",
                                  })
                                }
                              >
                                <ClearIcon
                                  sx={{
                                    width: "2rem",
                                    color: DELETE_COLOR,
                                    "&:hover": {
                                      color: HOVER_DELETE_COLOR,
                                    },
                                  }}
                                />
                              </button>
                            </>
                          )}
                          {key === "Latitude" && updateLatitude && (
                            <>
                              <button style={{ marginLeft: "1rem" }}>
                                <DoneIcon
                                  sx={{
                                    width: "2rem",
                                    color: PRIMARY_COLOR,
                                    "&:hover": {
                                      color: SECOND_COLOR,
                                    },
                                  }}
                                  onClick={() =>
                                    handleUpdateLocation({
                                      coordinate: "latitude",
                                    })
                                  }
                                />
                              </button>
                              <button
                                onClick={() =>
                                  handleCancelUpdate({ coordinate: "latitude" })
                                }
                              >
                                <ClearIcon
                                  sx={{
                                    width: "2rem",
                                    color: DELETE_COLOR,
                                    "&:hover": {
                                      color: HOVER_DELETE_COLOR,
                                    },
                                  }}
                                />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                      <td className="table__right">
                        {key === "Longitude" &&
                          (!updateLongitude ? (
                            <>
                              {updateLocation.longitude}
                              <button onClick={() => setUpdateLongitude(true)}>
                                <EditIcon
                                  sx={{
                                    width: "1.5rem",
                                    color: "var(--background-primary_color)",
                                    "&:hover": {
                                      color:
                                        "var(--background-secondary_color)",
                                    },
                                  }}
                                />
                              </button>
                            </>
                          ) : (
                            <input
                              type="number"
                              step="any"
                              value={updateLocation.longitude}
                              onChange={(e) =>
                                setUpdateLocation((prev) => ({
                                  ...prev,
                                  longitude: e.target.value,
                                }))
                              }
                            />
                          ))}
                        {key === "Latitude" &&
                          (!updateLatitude ? (
                            <>
                              {updateLocation.latitude}
                              <button onClick={() => setUpdateLatitude(true)}>
                                <EditIcon
                                  sx={{
                                    width: "1.5rem",
                                    color: "var(--background-primary_color)",
                                    "&:hover": {
                                      color:
                                        "var(--background-secondary_color)",
                                    },
                                  }}
                                />
                              </button>
                            </>
                          ) : (
                            <input
                              type="number"
                              step="any"
                              value={updateLocation.latitude}
                              onChange={(e) =>
                                setUpdateLocation((prev) => ({
                                  ...prev,
                                  latitude: e.target.value,
                                }))
                              }
                            />
                          ))}
                        {key !== "Latitude" &&
                          key !== "Longitude" &&
                          infoOperation[key]}
                      </td>
                    </tr>
                  );
                })}
                {/* {component &&
                  reduceAttributes()?.map((item) => {
                    return (
                      !(item?.value === "" || item?.value === " ") && (
                        <tr style={colorStyle}>
                          <td>
                            {item?.alias || item?.name}
                            {(item?.value?.type === "operation" ||
                              item?.operation) && (
                                <OperationInfo content={item?.value} />
                              )}
                          </td>
                          <td className="table__right">
                            {isUrl(item?.value) ? (
                              <a
                                href={item?.value}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {nameUrl(item?.value)}
                              </a>
                            ) : typeof item?.value !== "object" ? (
                              item?.value
                            ) : (
                              formatFloat(item?.value?.value, 6)
                            )}
                          </td>
                        </tr>
                      )
                    );
                  })} */}
                {component &&
                  reduceAttributes()?.map((item, index) => {
                    if (item?.type === "lineTag") {
                      return (
                        <tr
                          key={`lineTag-${index}`}
                          style={{
                            background:
                              theme === "light" ? "#f9f9f9" : "#121212",
                            color:
                              theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                          }}
                        >
                          <td colSpan={2}>
                            <h3
                              style={{
                                color:
                                  theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                                padding: "0.5rem",
                                margin: "0.5rem 0",
                                marginTop: "1.5rem",
                                fontWeight: "bold",
                              }}
                            >
                              {item.alias || item.name}
                            </h3>
                            <LineTag />
                          </td>
                        </tr>
                      );
                    }

                    return (
                      !(item?.value === "" || item?.value === " ") && (
                        <tr style={colorStyle} key={`item-${index}`}>
                          <td>
                            {item?.alias || item?.name}
                            {(item?.value?.type === "operation" ||
                              item?.operation) && (
                              <OperationInfo content={item?.value} />
                            )}
                          </td>
                          <td className="table__right">
                            {isUrl(item?.value) ? (
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  fontSize: "inherit",
                                }}
                                onClick={(e) => handleClickLink(e, item?.value)}
                              >
                                {nameUrl(item?.value)}
                              </button>
                            ) : typeof item?.value !== "object" ? (
                              item?.value
                            ) : (
                              formatFloat(item?.value?.value, 6)
                            )}
                          </td>
                        </tr>
                      )
                    );
                  })}
              </table>
              <Wrapperbtn>
                {component && <ConvertButton pointComponent={component} />}
                <CustomButton
                  text={<EditIcon />}
                  onClick={() => setOpenDialog(true)}
                  margin={"10px 0"}
                  primaryColor={"#ff600a"}
                  secondaryColor={"#f0712c"}
                  showTooltip={shouldShowTooltip}
                  tooltipTitle="Edit"
                />

                <CustomButton
                  text={<DynamicFormIcon />}
                  onClick={() =>
                    handleOpenCreateEventForm(
                      component?.id,
                      typeEventsTypeComponents
                    )
                  }
                  margin={"10px 0"}
                  showTooltip={shouldShowTooltip}
                  tooltipTitle="Add Non-Georeferenced Event"
                />
                {deleteButton && (
                  <DeleteComponentButton pointComponentId={component.id} />
                )}
              </Wrapperbtn>
            </div>
          </div>
        </section>
      </Grid>
    </InfoTitle>
  );
}
