const { writeFile, utils } = require("xlsx");

//const headers = [{ hola: "", como: "", estas: "" }];
const chars = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");

/**
 * Generates the Excel column header by its index
 *
 * @param {Integer} index
 * @returns {String} 1 → 'A' 2 → 'B' 26 → 'Z' 27 → 'AA'
 */
function numberToExcelHeader(index) {
  index -= 1;
  const quotient = Math.floor(index / 26);
  if (quotient > 0) {
    return numberToExcelHeader(quotient) + chars[index % 26];
  }
  return chars[index % 26];
}

/**
 * Function that generates an array of Excel headers by a number
 *
 * @param {Integer} num
 * @returns {<Array<String>>}[ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ', 'BA', 'BB' ...]
 */

function generateKeys(num) {
  if (!num || num <= 0) return [];
  return Array.from(Array(num).keys()).map((it) =>
    numberToExcelHeader(it + 1).concat("2")
  );
}

function saveFile(arr = [], extension = "csv") {
  try {
    if (!arr || !arr.length) throw new Error("Array null or empty");
    const checkType = arr.every((item) => typeof item === "object");
    if (!checkType) throw new Error("Elements in array is not an Object");
    const worksheet = utils.json_to_sheet(arr, { skipHeader: false });

    // funcion para eliminar la segunda fila producto de los valores del objeto de headers
    const lengthProps = Object.keys(arr[0]).length;
    generateKeys(lengthProps).forEach((it) => {
      delete worksheet[it];
    });

    const workbook = utils.book_new();
    const fileName = `File-${new Date().toISOString()}.${extension === "xslx" ? "xlsx" : extension === "csv" ? "csv" : extension
      }`;
    utils.book_append_sheet(workbook, worksheet, "Data");

    writeFile(workbook, fileName, { compression: true });
  } catch (error) {
    console.error("Error downloading CSV template", error);
    return;
  }
}

export default saveFile;
