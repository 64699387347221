import { getPathBucket } from "helpers/getPathBucket";
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
import { pathFolder } from "utils/paths";

/**
 * Generates a structured request body with uploaded file URLs.
 *
 * This function processes file fields, uploads them to S3, and replaces the file references
 * in the provided data object with their corresponding URLs.
 *
 * @async
 * @function bodyObjectWithFiles
 * @param {Object} options - Configuration options
 * @param {Array<Object>} options.fileFields - List of file fields containing field names
 * @param {Function} options.getValues - Function to retrieve form values (typically from react-hook-form)
 * @param {Object} options.data - Data object where URLs will be stored
 * @returns {Promise<Object>} The modified data object with uploaded file URLs
 * @throws {Error} If file upload fails
 *
 * @example
 * const fileFields = [{ name: "profileImage" }];
 * const data = {};
 * const getValues = form.getValues; // From react-hook-form
 * const result = await bodyObjectWithFiles({ fileFields, getValues, data });
 * // result now contains data.profileImage = "https://bucket.s3.amazonaws.com/path/to/file.pdf"
 */
export const bodyObjectWithFiles = async ({ fileFields, getValues, data }) => {
  // Process each file field and upload the files to S3
  await Promise.all(
    fileFields.map(async (fileField) => {
      const files = getValues(fileField.name);
      if (!files || files.length === 0) return (data[fileField.name] = null);
      const folderPath = getPathBucket(
        pathFolder.objects.points.files(fileField.name)
      );

      const { fileUrl } = await uploadFileToS3({
        folderPath,
        file: files[0],
        isUrl: files,
      });

      return (data[fileField.name] = fileUrl);
    })
  );

  return data; // Return the modified data object
};
