export const pathFolder = {
  profile: "profile",
  instances: "instances",
  objects: {
    points: {
      files: (fileFieldName) => `objects/points/files/web/${fileFieldName}`,
      pictures: (photoType) => `objects/points/pictures/web/${photoType}`,
    },
    lines: {
      files: (fileFieldName) => `objects/line/files/web/${fileFieldName}`,
      pictures: (photoType) => `objects/line/pictures/web/${photoType}`,
    },
  },
  events: {
    points: {
      files: (fileFieldName) => `events/points/files/web/${fileFieldName}`,
      pictures: (photoType) => `events/points/pictures/web/${photoType}`,
    },
  },
  components: {
    points: {
      files: (fileFieldName) => `components/points/files/web/${fileFieldName}`,
      pictures: (photoType) => `components/points/pictures/web/${photoType}`,
    },
  },
  icons: {
    configuration: {
      object: {
        types: "icons/configuration/object/types",
        libraries: "icons/configuration/object/libraries",
      },
      component: {
        types: "icons/configuration/component/types",
        libraries: "icons/configuration/component/libraries",
      },
      event: {
        types: "icons/configuration/event/types",
        libraries: "icons/configuration/event/libraries",
      },
      line: {
        types: "icons/configuration/line/types",
        libraries: "icons/configuration/line/libraries",
      },
    },
  },
  dashboard: {
    imageTextChart: "dashboard/imageTextChart",
    imageChart: "dashboard/imageChart",
  },
  standarsCatalogs: (fileFieldName) =>
    `standarsCatalogs/files/web/${fileFieldName}`,
  multinameAsset: (fileFieldName) =>
    `multinameAsset/files/web/${fileFieldName}`,
  measurementData: (fileFieldName) =>
    `measurementData/files/web/${fileFieldName}`,
};
