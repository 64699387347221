import styled from "styled-components";
import { WHITE_COLOR } from "utils/const";

export const CardContainer = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: ${WHITE_COLOR};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: solid 1px ${({ borderColor }) => borderColor};

  .delete-card-in-view-button {
    position: absolute;
    top: 3px;
    right: 25px;
    border: none;
    background-color: ${WHITE_COLOR};
    cursor: pointer;
    z-index: 2;
  }

  .delete-card-button {
    position: absolute;
    top: 3px;
    right: 3px;
    border: none;
    background-color: ${WHITE_COLOR};
    cursor: pointer;
    z-index: 2;
  }
  .restore-card-button {
    position: absolute;
    top: 3px;
    right: 3px;
    border: none;
    background-color: ${WHITE_COLOR};
    cursor: pointer;
    z-index: 2;
  }
`;

export const ResizeRight = styled.div`
  .hover-area {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 0px;
    margin-top: 20px;
    padding-bottom: 20px;
    z-index: 1;
  }

  .hidden-div {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    border: none;
    cursor: pointer;
    z-index: 2;
    height: 100%;
    width: 25px;
    border-bottom-right-radius: 10px;
    background: #03030326;
    border-left: dashed 2px #0000002e;
    border-top: dashed 2px #0000002e;
    margin-top: 5px;
    padding-bottom: 24px;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50%;
      border-bottom: dashed 2px #0000002e;
    }

    span:hover {
      transform: scale(1);
    }
  }

  .hover-area:hover .hidden-div {
    display: flex;
  }
`;

export const ResizeBottom = styled.div`
  .hover-area {
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: 0px;
    z-index: 1;
  }

  .hidden-div {
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;
    position: absolute;
    align-items: center;
    text-align: center;
    border: none;
    cursor: pointer;
    z-index: 2;
    height: 25px;
    width: 100%;
    background: #03030326;
    border-top: dashed 2px #0000002e;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      border-right: dashed 2px #0000002e;
    }
  }

  .hover-area:hover .hidden-div {
    display: flex;
  }
`;

export const ContainerInformationCard = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5px;
  h1 {
    text-align: center;
    font-size: 18px;
  }
  p {
    text-align: center;
  }
`;
