import styled from "styled-components";
import { BLACK_COLOR, GRAY_COLOR } from "utils/const";

export const GalleryContainerNoThumbnails = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 440px;
    background-color: ${BLACK_COLOR};
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .image-gallery-content {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 0px 0px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const GalleryContainer = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 525px;
    background-color: ${BLACK_COLOR};
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .image-gallery-image:nth-child(${(props) => props.items}) {
      transform: rotate(90deg);
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 8px 15px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const CustomImageGalleyItem = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-items: center;

  .button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .wrapper-buttons {
    display: flex;
    width: 10%;
    height: 100%;
    flex-direction: column;
    position: absolute;
    align-items: start;
    justify-content: space-between;
    padding: 10px 10px;
  }
  .wrapper-buttons > div {
    background-color: ${GRAY_COLOR};
    padding: 2px 2px;
    border-radius: 10px;
  }

  .image-gallery-zoom {
    display: flex;
    flex-direction: column;
    padding: 0 0;
    margin: 0 0;
    opacity: 0;
  }
  .image-gallery-buttons {
    display: flex;
    flex-direction: column;
  }
`;

export const ImageGalleryStyleWrapper = styled.div`
  .image-gallery-left-nav {
    padding: 0px 0px !important;
  }
  .image-gallery-right-nav {
    padding: 0px 0px !important;
  }
  .image-gallery-bullet {
    margin: 5px;
  }
  /* .image-gallery-bullets {
  }
  .image-gallery-bullets-container {
  }
*/
`;
