import useSwr from "swr";
import { urls } from "utils/urlKeys";

export const useFetchObjectLibraries = ({ id }) => {
  /**
   * It is not necessary to send the adminCompanyId because it is implicit in the token and is handled in the backend
   */
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("id", id);

  const url = `${urls.objects.libraries}&${urlParams.toString()}`;

  const { data, error } = useSwr(url);

  return { data, error };
};

export const useFetchEventLibraries = ({ id }) => {
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("id", id);

  let url = `${urls.events.libraries}&${urlParams.toString()}`;

  const { data, error } = useSwr(url);

  return { data, error };
};

export const useFetchComponentsLibraries = ({ id }) => {
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("id", id);

  let url = `${urls.components.libraries}&${urlParams.toString()}`;

  const { data, error } = useSwr(url);

  return { data, error };
};

export const useFetchObjectLineLibraries = ({ id }) => {
  const urlParams = new URLSearchParams();
  if (id) urlParams.append("id", id);

  let url = `${urls.lines.libraries}&${urlParams.toString()}`;

  const { data, error } = useSwr(url);

  return { data, error };
};
