import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useUserStore = create(
  persist(
    (set) => ({
      userId: null,
      adminCompanyId: null,
      instanceId: null,
      instanceManager: {
        id: null,
        name: null,
      },
      adminDecimetrixId: null,
      token: null,
      role: null,

      setUserId: (id) => {
        set({ userId: id });
      },

      setAdminDecimetrixId: (id) => {
        set({ adminDecimetrixId: id });
      },

      setAdminCompanyId: (id) => {
        set({ adminCompanyId: id });
      },

      setInstanceId: (id) => {
        set({ instanceId: id });
      },

      setInstanceManager: (instanceManager) => {
        set({
          instanceManager: {
            id: instanceManager.id,
            name: instanceManager.name,
          },
        });
      },

      setToken: (token) => {
        set({ token: token });
      },

      setRole: (role) => {
        set({ role: role });
      },

      clearUserData: () => {
        set({
          userId: null,
          adminCompanyId: null,
          instanceId: null,
          token: null,
          role: null,
          instanceManager: {
            id: null,
            name: null,
          },
          adminDecimetrixId: null,
        });
      },
    }),
    {
      name: "user-storage", // nombre único para el almacenamiento local
      partialize: (state) => ({
        userId: state.userId,
        adminCompanyId: state.adminCompanyId,
        instanceId: state.instanceId,
        instanceManager: state.instanceManager,
        adminDecimetrixId: state.adminDecimetrixId,
        token: state.token,
        role: state.role,
      }),
    }
  )
);
