import { useSWRConfig } from "swr";
import { useEffect, useState } from "react";
import { updateChart } from "services/Dashboard/timeSeriesChart";
import { PRIMARY_COLOR } from "utils/const";
import { urlsApiMg } from "utils/urlKeys";

export const useSetCurrentStyle = ({ dataChart, setChartEditDialog }) => {
  const { mutate } = useSWRConfig();

  const [currentStyleLineDataChart, setCurrentStyleLineDataChart] = useState({
    data: {
      labels: [],
      datasets: dataChart.datasets,
    },
    currentNameLineChart: "",
    currentTitleColor: "",
    currentTotalFontColor: "#EEEEEE",
    currentTotalBackground: "#EEEEEE",
    currentAxisXColor: "#EEEEEE",
    currentAxisYColor: "#EEEEEE",
    currentLabelsXColor: "",
    currentLabelsYColor: "",
    currentTitleXColor: "#EEEEEE",
    currentTitleYColor: "#EEEEEE",
    currentTotalPosition: "top",
    currentTotalFontSize: 15,
    currentTitleFontSize: 25,
    currentTitleTooltipFontSize: 15,
    currentBodyTooltipFontSize: 13,
    currentLabelsXFontSize: 15,
    currentLabelsYFontSize: 15,
    currentTitleXFontSize: 15,
    currentTitleYFontSize: 15,
    currentTitleAxisX: "",
    currentDisplayAxisX: false,
    currentTitleAxisY: "",
    currentDisplayAxisY: false,
    currentDisplayTotal: false,
    currentDisplayBackgroundTotal: false,
    currentDisplayTitleX: false,
    currentDisplayTitleY: false,
    currentStatusBeginAtZero: false,
    currentMinValueAxis: 0,
    currentMaxValueAxis: null,
    currentStepSizeY: 5,
    currentDisplayShowCerosTotal: true,
    currentPaddingAxisY: 5,
    currentPaddingAxisX: 5,
  });

  const [constantLine, setConstantLine] = useState({
    create: false,
    label: "",
    borderColor: PRIMARY_COLOR,
    borderColorHexadecimal: PRIMARY_COLOR,
    constantValue: 0,
    currentTotalNumDecimals: null,
  });

  useEffect(() => {
    setCurrentStyleLineDataChart((current) => {
      return {
        ...current,
        data: {
          labels: dataChart.labels,
          datasets: dataChart.datasets.map((elm) => {
            if (elm.lineStyle.stepped === "false") {
              return {
                ...elm.lineStyle,
                data: elm.data,
                // elm.data.map((value, index, array) => {
                //   if (value === 0 && array[index + 1] === 0) {
                //     return null;
                //   }
                //    if (value === 0 && array[index - 1] === 0) {
                //      return null;
                //    }
                //   return value;
                // }),
                stepped: false,
                // spanGaps: true,
              };
            }
            return {
              ...elm.lineStyle,
              data: elm.data,
              // elm.data.map((value, index, array) => {
              //   if (value === 0 && array[index - 1] === 0) {
              //     return null; // o NaN
              //   }
              //   return value;
              // }),
              // spanGaps: false,
            };
          }),
        },
        currentNameLineChart: dataChart.alias,
        currentTitleColor: dataChart.chartStyle.colorTitle,
        currentTotalFontColor: dataChart.chartStyle.datalabels.colorTotal,
        currentTotalBackground:
          dataChart.chartStyle.datalabels.backgroundColorTotal,
        currentAxisXColor: dataChart.chartStyle.scales.x.axisColor,
        currentAxisYColor: dataChart.chartStyle.scales.y.axisColor,
        currentLabelsXColor: dataChart.chartStyle.scales.x.labelsColor,
        currentLabelsYColor: dataChart.chartStyle.scales.y.labelsColor,
        currentTitleXColor: dataChart.chartStyle.scales.x.titleAxis.colorTitle,
        currentTitleYColor: dataChart.chartStyle.scales.y.titleAxis.colorTitle,
        currentTotalPosition: dataChart.chartStyle.datalabels.positionTotal,
        currentTotalFontSize: dataChart.chartStyle.datalabels.fontSizeTotal,
        currentTitleFontSize: dataChart.chartStyle.fontSizeTitle,
        currentTitleTooltipFontSize: dataChart.chartStyle.tooltip.fontSizeTitle,
        currentBodyTooltipFontSize: dataChart.chartStyle.tooltip.fontSizeBody,
        currentLabelsXFontSize: dataChart.chartStyle.scales.x.labelsFontSize,
        currentLabelsYFontSize: dataChart.chartStyle.scales.y.labelsFontSize,
        currentTitleXFontSize:
          dataChart.chartStyle.scales.x.titleAxis.fontSizeTitle,
        currentTitleYFontSize:
          dataChart.chartStyle.scales.y.titleAxis.fontSizeTitle,
        currentTitleAxisX:
          dataChart.chartStyle.scales.x.titleAxis.text ||
          dataChart.columnX.name,
        currentDisplayAxisX: dataChart.chartStyle.scales.x.displayAxis,
        currentTitleAxisY:
          dataChart.chartStyle.scales.y.titleAxis.text ||
          (dataChart.type === "count" ? "Count" : dataChart.columnsY[0]),
        currentDisplayAxisY: dataChart.chartStyle.scales.y.displayAxis,
        currentDisplayTotal: dataChart.chartStyle.datalabels.displayTotal,
        currentDisplayBackgroundTotal:
          dataChart.chartStyle.datalabels.displayBackgroundTotal,
        currentDisplayTitleX:
          dataChart.chartStyle.scales.x.titleAxis.displayTitle,
        currentDisplayTitleY:
          dataChart.chartStyle.scales.y.titleAxis.displayTitle,
        currentStatusBeginAtZero: dataChart.chartStyle.scales.y.beginAtZero,
        currentMinValueAxis: dataChart.chartStyle.scales.y.minValueAxis,
        currentMaxValueAxis: dataChart.chartStyle.scales.y.maxValueAxis,
        currentStepSizeY: dataChart.chartStyle.scales.y.stepSizeY,
        currentDisplayShowCerosTotal: dataChart.chartStyle.datalabels.showCeros,
        currentPaddingAxisY: dataChart.chartStyle.scales.y.paddingAxis,
        currentPaddingAxisX: dataChart.chartStyle.scales.x.paddingAxis,
        currentTotalNumDecimals: dataChart.chartStyle.datalabels.numDecimals,
      };
    });
  }, [dataChart]);

  const handleUpdateChart = async () => {
    const { data } = await updateChart({
      id: dataChart._id,
      body: {
        ...dataChart,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        alias: currentStyleLineDataChart.currentNameLineChart,
        datasets: currentStyleLineDataChart.data.datasets.map((elm) => {
          return {
            columnsValuesFilter: [],
            lineStyle: { ...(({ _id, ...rest }) => rest)(elm) },
          };
        }),
        chartStyle: {
          ...dataChart.chartStyle,
          colorTitle: currentStyleLineDataChart.currentTitleColor,
          fontSizeTitle: currentStyleLineDataChart.currentTitleFontSize,
          datalabels: {
            ...dataChart.chartStyle.datalabels,
            displayTotal: currentStyleLineDataChart.currentDisplayTotal,
            positionTotal: currentStyleLineDataChart.currentTotalPosition,
            colorTotal: currentStyleLineDataChart.currentTotalFontColor,
            displayBackgroundTotal:
              currentStyleLineDataChart.currentDisplayBackgroundTotal,
            backgroundColorTotal:
              currentStyleLineDataChart.currentTotalBackground,
            fontSizeTotal: currentStyleLineDataChart.currentTotalFontSize,
            showCeros: currentStyleLineDataChart.currentDisplayShowCerosTotal,
            numDecimals: currentStyleLineDataChart.currentTotalNumDecimals,
          },
          scales: {
            x: {
              ...dataChart.chartStyle.scales.x,
              titleAxis: {
                displayTitle: currentStyleLineDataChart.currentDisplayTitleX,
                text: currentStyleLineDataChart.currentTitleAxisX,
                colorTitle: currentStyleLineDataChart.currentTitleXColor,
                fontSizeTitle: currentStyleLineDataChart.currentTitleXFontSize,
              },
              displayAxis: currentStyleLineDataChart.currentDisplayAxisX,
              axisColor: currentStyleLineDataChart.currentAxisXColor,
              labelsFontSize: currentStyleLineDataChart.currentLabelsXFontSize,
              labelsColor: currentStyleLineDataChart.currentLabelsXColor,
              paddingAxis: currentStyleLineDataChart.currentPaddingAxisX,
            },
            y: {
              ...dataChart.chartStyle.scales.y,
              titleAxis: {
                displayTitle: currentStyleLineDataChart.currentDisplayTitleY,
                text: currentStyleLineDataChart.currentTitleAxisY,
                colorTitle: currentStyleLineDataChart.currentTitleYColor,
                fontSizeTitle: currentStyleLineDataChart.currentTitleYFontSize,
              },
              displayAxis: currentStyleLineDataChart.currentDisplayAxisY,
              axisColor: currentStyleLineDataChart.currentAxisYColor,
              labelsFontSize: currentStyleLineDataChart.currentLabelsYFontSize,
              labelsColor: currentStyleLineDataChart.currentLabelsYColor,
              beginAtZero: currentStyleLineDataChart.currentStatusBeginAtZero,
              minValueAxis: currentStyleLineDataChart.currentMinValueAxis,
              maxValueAxis: currentStyleLineDataChart.currentMaxValueAxis,
              stepSizeY: currentStyleLineDataChart.currentStepSizeY,
              paddingAxis: currentStyleLineDataChart.currentPaddingAxisY,
            },
          },
          tooltip: {
            ...dataChart.chartStyle.tooltip,
            fontSizeTitle:
              currentStyleLineDataChart.currentTitleTooltipFontSize,
            fontSizeBody: currentStyleLineDataChart.currentBodyTooltipFontSize,
          },
        },
      },
    });
    if (data) {
      mutate(
        urlsApiMg.dashboard.timeSeriesChart(
          dataChart.fromType ? dataChart.fromType : dataChart.from
        )
      );
      setChartEditDialog(false);
    }
  };

  const handleCreateConstantLine = () => {
    const newGoalLine = {
      _id: constantLine.label,
      label: constantLine.label,
      alias: constantLine.label,
      borderColor: constantLine.borderColorHexadecimal,
      borderWidth: 2,
      borderDash: [5, 5],
      borderDashOffset: 1,
      borderCapStyle: "butt",
      borderJoinStyle: "miter",
      backgroundColorHexadecimal: constantLine.borderColorHexadecimal,
      backgroundColor: constantLine.borderColor,
      fill: false,
      pointBackgroundColor: constantLine.borderColorHexadecimal,
      pointBorderColor: "#0E4D45",
      pointBorderWidth: 0,
      pointRadius: 0,
      pointStyle: "circle",
      pointHitRadius: 0,
      pointHoverBackgroundColor: constantLine.borderColorHexadecimal,
      pointHoverBorderColor: constantLine.borderColorHexadecimal,
      pointHoverBorderWidth: 0,
      pointHoverRadius: 0,
      cubicInterpolationMode: "default",
      tension: 0,
      stepped: false,
      spanGaps: false,
      showLine: true,
      hidden: false,
      datalabels: {
        displayTotal: false,
        positionTotal: "end",
        colorTotal: "#EEEEEE",
        displayBackgroundTotal: false,
        backgroundColorTotal: "rgba(255, 255, 255, 0)",
        fontSizeTotal: 10,
        offsetTotal: 3,
      },
      clip: true,
      constantValue: constantLine.constantValue,
      data: new Array(
        currentStyleLineDataChart.data.datasets[0].data.length
      ).fill(constantLine.constantValue),
    };
    setCurrentStyleLineDataChart((current) => ({
      ...current,
      data: {
        ...current.data,
        datasets: [...current.data.datasets, newGoalLine],
      },
    }));
    setConstantLine({
      create: false,
      label: "",
      borderColor: PRIMARY_COLOR,
      borderColorHexadecimal: PRIMARY_COLOR,
      constantValue: 0,
    });
  };

  const handleDeleteConstantLine = ({ id }) => {
    setCurrentStyleLineDataChart((current) => ({
      ...current,
      data: {
        ...current.data,
        datasets: current.data.datasets.filter((elm) => elm._id !== id),
      },
    }));
  };

  return {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
    handleCreateConstantLine,
    handleDeleteConstantLine,
    constantLine,
    setConstantLine,
  };
};
