// React
import { useEffect, useState } from "react";

// Components
import TextInput from "../GenericInput";
import SelectInput from "components/Forms/SelectInput";
import CustomButton from "components/Buttons/CustomButton";
// Services
import savePointEventApiPg, {
  createPointEvent,
  savePointEvenRelation,
  setUpdateAtEvent,
} from "services/savePointEvent";
import { uploadFileToS3 } from "services/s3Manager/s3Manager";
// Hooks
import { useFetchEventLibraries } from "hooks/fetchLibraries";
// Dependencies
import { useForm } from "react-hook-form";
import useSwr from "swr";
import { useSWRConfig } from "swr";

// Config
import { config } from "config.js";
// styles
import { Currency, StepSection } from "./DynamicEventStyles";
import { Form } from "../SaveObject/DynamicFieldsStyle";
import { ContentButton } from "../../Buttons/CustomButtonStyle";
import { updateAttributesEventMg } from "services/updateAttributesMg";
import useGet from "hooks/useFetch";
import CircularIndeterminate from "components/Lodings/LoadingV2";
import { isValidJSON } from "helpers/expressions/expressionHelpers";
import { urlKeys } from "utils/urlKeys";
import { LineConteiner2 } from "pages/ConfigAdmin/typeElemts/FieldsStyle";
import { createPointEventNoLocation } from "services/saveEventNoLocation";
import { useDispatch } from "react-redux";
import { setMessageLoadingMap } from "redux/actions/digitalTwin";
import { getPathBucket } from "helpers/getPathBucket";
import { pathFolder } from "utils/paths";

export default function DynamicEvent({
  feature,
  objectId = null,
  closeModal,
  pointTypeEventId,
  pointComponentId = null,
  pointEventId = null,
  dataEvent,
  update = false,
  setOpenDialog,
  handleUpdateData,
}) {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataFieldsTypeEvents, setDataFieldsTypeEvents] = useState();
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const { data: operatorsAdmin, error: errorOperators } = useSwr(
    `${config.URL_BACKEND_PG}api/v1/admin-company/operators/${adminCompanyId}`
  );

  const { data: dataLibrary, error: errorLibrary } = useFetchEventLibraries({
    id: dataEvent?.pointTypeEvent?.pointLibraryEventId,
  });

  // Find type events to type elements
  const [fieldsTypeEvents, errorFieldsTypeEvents] = useGet(
    `api/v1/point-type-events?id=${
      pointTypeEventId || dataEvent?.pointTypeEventId
    }`
  );

  const allOperators = operatorsAdmin && !errorOperators ? operatorsAdmin : [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    setForm({});
    fieldsTypeEvents &&
      fieldsTypeEvents?.[0]?.fields?.forEach((field) => {
        setForm((current) => ({
          ...current,
          [field.name]: "",
        }));
      });
    fieldsTypeEvents && setDataFieldsTypeEvents(fieldsTypeEvents?.[0]);
  }, [fieldsTypeEvents, errorFieldsTypeEvents]);

  useEffect(() => {
    pointTypeEventId && reset();
    dataEvent && reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointTypeEventId, dataEvent?.pointTypeEventId]);

  useEffect(() => {
    !errorLibrary &&
      dataEvent?.attributes.forEach((field) => {
        const matchAlias = dataLibrary?.[0]?.fields?.find(
          (fields) => fields.name === field.name || fields.alias === field.name
        );
        if (matchAlias) {
          field.name = matchAlias.name;
          field.alias = matchAlias.alias;
        }
      });
    if (update && dataEvent) {
      const existingFieldNames = dataEvent?.attributes?.map(
        (attribute) => attribute.name
      );
      !errorLibrary &&
        dataLibrary?.[0]?.fields?.forEach((field) => {
          if (
            !existingFieldNames.includes(field.name) &&
            field.type !== "notification" &&
            field.type !== "captureMedia" &&
            field.type !== "lineTag"
          ) {
            const newAttribute = {
              name: field?.name,
              alias: field?.alias,
              value: "",
            };
            dataEvent.attributes.push(newAttribute);
          }
        });

      dataEvent.attributes.forEach((elm) => {
        if (elm.value?.[0] === "{") {
          const dataParse = JSON.parse(elm.value);
          if (dataParse.type === "number" && dataParse.value) {
            setValue(elm.name, parseFloat(dataParse.value));
          }
        } else {
          if (elm.value === "true" || elm.value === "false") {
            return setValue(elm.name || elm.alias, JSON.parse(elm.value));
          }
          const value =
            elm.value.length === 0
              ? ""
              : !isNaN(elm.value)
              ? parseFloat(elm.value)
              : elm.value;
          setValue(elm.name, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEvent]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setForm({ ...form, [name]: checked ? "true" : "false" });
      return;
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  useEffect(() => {
    if (dataFieldsTypeEvents) {
      const initialForm = {};
      dataFieldsTypeEvents.fields.forEach((field) => {
        if (field.type === "check" && !update) {
          initialForm[field.name] = "false"; // Inicializar como false
          return;
        } else {
          initialForm[field.name] = ""; // Otros campos como string vacío
          return;
        }
      });
      setForm(initialForm);
    }
  }, [dataFieldsTypeEvents]);

  const createBodyOfEvent = async ({ fileFields, data }) => {
    const newForm = {};
    Object.keys(form).forEach((key) => {
      const value = form[key];
      if (value !== "") newForm[key] = value;
      if (Object.keys(data).includes(key) && data[key] === "")
        newForm[key] = "";
    });

    const files = await Promise.all(
      fileFields.map(async (fileField) => {
        const folderPath = getPathBucket(
          pathFolder.events.points.files(fileField.name)
        );

        const files = data[fileField.name];
        const { fileUrl } =
          files[0] !== undefined &&
          (await uploadFileToS3({
            file: files[0],
            folderPath: folderPath,
            isUrl: files,
          }));
        const newData = {
          [fileField.name]: fileUrl,
        };
        return newData;
      })
    );

    const updatedForm = {};
    for (const key in newForm) {
      if (newForm.hasOwnProperty(key)) {
        const file = files?.find((fileObj) => fileObj[key] !== undefined);
        if (file) {
          updatedForm[key] = file[key];
        } else {
          updatedForm[key] = newForm[key];
        }
      }
    }

    let updatedFormWithAlias = [];
    for (const key in newForm) {
      const obj = {};
      if (newForm.hasOwnProperty(key)) {
        const file = files?.find((fileObj) => fileObj[key] !== undefined);
        if (file) {
          obj.name = key;
          obj.value = file[key];
        } else {
          obj.name = key;
          obj.value = newForm[key];
        }
      }
      updatedFormWithAlias.push(obj);
    }

    updatedFormWithAlias.forEach((field) => {
      if (update) {
        const matchAlias = dataEvent?.attributes?.find(
          (fields) => fields.name === field.name
        );
        if (matchAlias) {
          field.alias = matchAlias?.alias;
          field._id = matchAlias?._id || null;
        }
      } else {
        const matchAlias = dataFieldsTypeEvents.fields?.find(
          (fields) => fields.name === field.name
        );
        if (matchAlias) {
          field.alias = matchAlias.alias;
        }
      }
    });

    return updatedFormWithAlias;
  };

  const handleSave = async (data) => {
    setLoading(true);
    if (update) {
      const numericFields = dataFieldsTypeEvents?.fields
        ?.map((field) => {
          if (field.type === "number") {
            const entries = Object.entries(data);
            for (const [key, value] of entries) {
              if (key === field.name) {
                return { ...field, value: value === "" ? NaN : value };
              }
            }
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = dataFieldsTypeEvents?.fields?.filter(
        (field) => field.type === "operation"
      );

      const fileFields = dataFieldsTypeEvents?.fields?.filter(
        (field) => field.type === "file"
      );

      let newForm = await createBodyOfEvent({ fileFields, data });

      if (numericFields.length > 0) {
        numericFields.forEach((fld) => {
          const searching = dataEvent?.attributes?.find(
            (it) => it.name === fld.name || it.name === fld.alias
          );
          if (searching) {
            newForm.push({
              name: searching.name,
              value: JSON.stringify(fld),
              alias: searching.alias,
              _id: searching._id,
            });
          } else {
            newForm.push({
              name: fld.name,
              value: JSON.stringify(fld),
              alias: fld.alias,
            });
          }
        });
      }

      if (expFields.length > 0) {
        let searching = {};
        expFields.forEach((fld) => {
          const tmp = JSON.stringify(fld);
          searching = dataEvent?.attributes?.find(
            (it) => it.name === fld.name || it.name === fld.alias
          );
          if (searching) {
            newForm.push({
              ...searching,
              value: tmp,
            });
          } else {
            dataEvent.attributes.push({
              name: fld.name,
              value: tmp,
              alias: fld.alias,
            });
          }
        });
      }

      const objetoMap = new Map();
      //compare equal number fields and value if object parsed
      newForm.forEach((objeto) => {
        if (objetoMap.has(objeto.name)) {
          const objetoExistente = objetoMap.get(objeto.name);
          const objetoActual = objeto;
          if (isValidJSON(objetoActual.value)) {
            objetoMap.set(objeto.name, objetoActual);
          } else {
            objetoMap.set(objeto.name, objetoExistente);
          }
        } else {
          objetoMap.set(objeto.name, objeto);
        }
      });
      newForm = [...objetoMap.values()];
      const form = newForm?.filter((form) => form.value !== "");

      await updateAttributesEventMg(dataEvent.mongoId, form);
      await setUpdateAtEvent(dataEvent.id);

      mutate(
        `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${dataEvent.id}`
      );
      mutate(urlKeys.eventsObjects);
      mutate(urlKeys.eventsComponents);
      mutate(urlKeys.eventsEvents);

      handleUpdateData();
      setOpenDialog(false);
    } else {
      let elmt = "";
      const numericFields = dataFieldsTypeEvents?.fields
        ?.map((field) => {
          elmt =
            document.getElementById(field.alias) ||
            document.getElementById(field.name);
          if (elmt && elmt.type === "number") {
            field.value = elmt.value;
            return field;
          }
          return null;
        })
        .filter((it) => it !== null);

      const expFields = dataFieldsTypeEvents?.fields?.filter(
        (field) => field.type === "operation"
      );

      const fileFields = dataFieldsTypeEvents?.fields?.filter(
        (field) => field.type === "file"
      );

      const newForm = await createBodyOfEvent({ fileFields, data });
      let event = {};

      if (
        feature === undefined ||
        feature === null ||
        Object.keys(feature).length === 0
      ) {
        event = createPointEventNoLocation(
          newForm,
          objectId,
          pointComponentId,
          pointTypeEventId
        );
      } else {
        event = createPointEvent(
          feature,
          newForm,
          objectId,
          pointComponentId,
          pointTypeEventId
        );
      }

      if (numericFields.length > 0) {
        let searching = {};
        numericFields.forEach((fld) => {
          const tmp = JSON.stringify(fld);
          searching = event?.attributes?.find(
            (it) => it.name === fld.name || it.name === fld.alias
          );
          if (searching) {
            event.attributes[event.attributes.indexOf(searching)] = {
              name: fld.name,
              value: tmp,
              alias: fld.alias,
            };
            return null;
          }
          event.attributes.push({
            name: fld.name,
            value: fld.value,
            alias: fld.alias,
          });
        });
      }
      if (expFields.length > 0) {
        let searching = {};
        expFields.forEach((fld) => {
          const tmp = JSON.stringify(fld);
          searching = event?.attributes?.find(
            (it) => it.name === fld.name || it.name === fld.alias
          );
          if (searching) {
            event.attributes[event?.attributes.indexOf(searching)] = {
              name: fld.name,
              value: tmp,
              alias: fld.alias,
            };
            return null;
          }
          event.attributes.push({
            name: fld.name,
            value: tmp,
            alias: fld.alias,
          });
        });
      }
      //filtering empty values while creating the event
      event.attributes = event?.attributes?.filter((it) => it.value !== "");
      const { data: newPointEvent } = await savePointEventApiPg(event);
      dispatch(setMessageLoadingMap(true));

      // Update all point events
      mutate(urlKeys.eventsObjects);
      mutate(urlKeys.eventsComponents);
      mutate(urlKeys.eventsEvents);
      if (objectId) {
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events?objectId=${objectId}`
        );
      }
      if (pointComponentId) {
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events/components?pointComponentId=${pointComponentId}`
        );
      }
      if (pointEventId) {
        await savePointEvenRelation(pointEventId, newPointEvent.id);
        // Event event
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events/relation?id=${pointEventId}`
        );
        // Event object
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events?id=${pointEventId}`
        );
        // Event Component
        mutate(
          `${config.URL_BACKEND_PG}api/v1/point-events/components?id=${pointEventId}`
        );
      }
      closeModal(false);
    }
    setLoading(false);
    reset();
  };

  if (dataFieldsTypeEvents === undefined) {
    return <CircularIndeterminate />;
  }

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      {dataFieldsTypeEvents &&
        Object.keys(form).length > 0 &&
        dataFieldsTypeEvents?.fields?.map((field, index) => {
          if (field.type === "select") {
            if (!update && form[field.name] === "") {
              form[field.name] = field.options[0];
            }
            return (
              <div key={index} onChange={(e) => handleChange(e)}>
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    options: field.options,
                    required: field.required,
                    type: field.type,
                    alias: field.alias,
                  }}
                  register={register}
                />
              </div>
            );
          }
          if (field.type === "check") {
            return (
              <div key={index} onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    required: field.required,
                    type: field.type,
                    alias: field.alias,
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          }
          if (field.type === "file") {
            return (
              <div onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    required: update ? false : field.required,
                    type: field.type,
                    possible: field.possible,
                    alias: field.alias,
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          }
          if (field.type === "range") {
            const step =
              field.options.length !== 0 ? parseInt(field.options[0]) : 1;
            if (Object.keys(form).length === 0 || form[field.name] === "") {
              if (!update) {
                //setForm({ ...form, [field?.name]: field?.min + step });
                const middleValue = field.min;
                form[field.name] = middleValue.toString();
              } else {
                const value = dataEvent?.attributes?.find(
                  (it) => it?.name === field?.name
                );
                if (value) {
                  form[field?.name] = value?.value;
                }
              }
              /* const middleValue =
                 Math.ceil((field.max - field.min) / 2) + field.min;
               form[field.name] = middleValue.toString();*/
            }
            return (
              <div key={index} onChange={(e) => handleChange(e)}>
                <TextInput
                  key={index}
                  field={{
                    name: field.name,
                    alias: field.alias,
                    required: field.required,
                    type: field.type,
                    step: step,
                    max: field.max,
                    min: field.min,
                  }}
                  register={register}
                  errors={errors}
                />
                <StepSection>
                  <b>Step: {step}</b>
                  <b>
                    {form[field.name] ? form[field.name] : field.min + step}
                  </b>
                </StepSection>{" "}
              </div>
            );
          }
          if (field.type === "currency") {
            if (!form.Currency) {
              setForm({ ...form, Currency: field.possible[0] });
            }
            return (
              <Currency onChange={(e) => handleChange(e)}>
                <TextInput
                  key={`${index + 1}`}
                  field={{
                    name: field.name,
                    required: field.required,
                    alias: field.alias,
                    type: field.type,
                  }}
                  register={register}
                  errors={errors}
                />
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    possible: field.possible,
                    required: field.required,
                    type: field.type,
                    alias: field.alias,
                  }}
                  register={register}
                />
              </Currency>
            );
          }
          if (field.type === "delegate") {
            if (
              !update &&
              form[field.name] === "" &&
              operatorsAdmin &&
              !errorOperators
            ) {
              form[
                field.name
              ] = `${allOperators.operators[0].firstName} ${allOperators.operators[0].firstLastName}`;
            }
            return (
              <div onChange={(e) => handleChange(e)}>
                <SelectInput
                  key={index}
                  field={{
                    name: field.name,
                    options: allOperators.operators?.map((operator) => {
                      return `${operator?.firstName} ${operator?.firstLastName}`;
                    }),
                    required: field.required,
                    type: field.type,
                    alias: field.alias,
                  }}
                  register={register}
                />
              </div>
            );
          }

          if (field.type === "lineTag") {
            return (
              <LineConteiner2>
                <hr />
                <h3>{field.alias}</h3>
                <hr />
              </LineConteiner2>
            );
          }
          if (field.type === "operation") {
            return (
              <TextInput
                key={index}
                field={field}
                register={register}
                errors={errors}
              />
            );
          }
          if (field.type === "captureMedia") return null;
          if (field.type === "notification") return null;
          if (field.type === "lineTag") return null;
          return (
            <div onChange={(e) => handleChange(e)}>
              <TextInput
                key={index}
                field={{
                  name: field.name,
                  required: field.required,
                  type: field.type,
                  possible: field.possible,
                  alias: field.alias,
                }}
                register={register}
                errors={errors}
              />
            </div>
          );
        })}
      <ContentButton style={{ margin: "-10px" }}>
        <CustomButton
          type={"submit"}
          isLoad={loading}
          text={update ? "Update" : "Save"}
        />
      </ContentButton>
    </Form>
  );
}
