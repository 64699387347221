import { useEffect, useRef, useState } from "react";
// Mapbox
import Map, { NavigationControl, AttributionControl } from "react-map-gl";
// Style
import "mapbox-gl/dist/mapbox-gl.css";
import Container from "@mui/material/Container";
import { MapCard } from "./MapViewElements";
// Consts
import fogStyle from "utils/fogStyle";
import { MAPBOX_STYLE } from "utils/constStrings";
//components
import OpenDialog from "components/Dialogs/OpenDialog";
import BaseMapButton from "components/Buttons/BaseMapButton";
// Config
import { config } from "config.js";
// Helpers
import ShowLinesJoin from "helpers/showLines";
import CenterMapFromFeatures from "helpers/map/centerMapFromFeatures";

import { findLinesObjects } from "services/lines/findLinesObjects";
import showMarkers from "helpers/showMarkers";
import useShowDetails from "hooks/useShowDetails";
import { useSelector } from "react-redux";

const LineMap = ({ line }) => {
  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const settingMap = useSelector(
    (state) => state.digitalTwinReducer.settingMap
  );

  const [baseMap, setBaseMap] = useState(
    settingMap?.styleMap
      ? { id: settingMap.styleMap.id, value: settingMap.styleMap.value }
      : {
          id: MAPBOX_STYLE.streets.id,
          value: MAPBOX_STYLE.streets.value,
        }
  );
  const [haveObjects, setHaveObjects] = useState(false);
  const [contentDialog, setContentDialog] = useState({});

  const mapRef = useRef();

  // Handler click to marker of objects
  const { handlerClickObjectId } = useShowDetails();

  const [markersObjects, setMarkersObjects] = useState([]);
  useEffect(() => {
    findLinesObjects({ lineId: line?.id }).then((linesObjects) => {
      const markers = linesObjects.map((item) => {
        const { id } = item?.object;
        const { longitude, latitude } = item?.object?.location;
        const { urlIconColor } = item?.object?.typeElement;

        return {
          id: id,
          icon: urlIconColor,
          latitude: latitude,
          longitude: longitude,
          handlerClick: () => handlerClickObjectId(id),
        };
      });

      setMarkersObjects(markers);
    });
  }, [line]);

  useEffect(() => {
    line && (line.feature[0].properties.color = line.lineType.color);
  }, [line]);

  return (
    <Container
      maxWidth="l"
      sx={{
        width: "100%",
        height: "90%",
        padding: "0px 0px 24px 0px",
      }}
    >
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        disagree={() => {}}
        content={contentDialog}
        maxWidth={contentDialog.title === "Base Map" ? null : "500px"}
        minWidth={contentDialog.title === "Base Map" ? null : "300px"}
      />
      <MapCard>
        {line && (
          <Map
            mapboxAccessToken={config.MAPBOX_TOKEN}
            initialViewState={{
              latitude: line.feature[0].geometry.coordinates[0][1],
              longitude: line.feature[0].geometry.coordinates[0][0],
              zoom: 20,
            }}
            style={{ width: "100%", height: 435, margin: "auto" }}
            mapStyle={baseMap.value}
            attributionControl={false}
            projection={"globe"}
            fog={fogStyle}
            ref={mapRef}
          >
            {/* Button layer map */}
            <BaseMapButton
              setHaveObjects={setHaveObjects}
              setContentDialog={setContentDialog}
              setBaseMap={setBaseMap}
              baseMap={baseMap}
              position={line.id ? 104 : 173}
            />
            <AttributionControl customAttribution="© Decimetrix® 2024" />
            <NavigationControl style={navControlStyle} />
            <ShowLinesJoin id={`line-${line?.id}`} features={line.feature} />
            {markersObjects && showMarkers({ markers: markersObjects })}
            <CenterMapFromFeatures
              mapref={mapRef}
              coordinates={line?.feature?.[0]?.geometry?.coordinates}
            />
          </Map>
        )}
      </MapCard>
    </Container>
  );
};

export default LineMap;
