// Styles
import {
  ContainerContentTable,
  ContainerTable,
  ContentTable,
  Divider,
} from "../GenericTableStyle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  BACKGROUND_GREY,
  BLACK_COLOR,
  PRIMARY_COLOR,
  RED_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";
// React
import { useState } from "react";
// Dependencies
import { flexRender } from "@tanstack/react-table";
// Helpers
import GenericFilterTable from "../helpers/components/Filter/GenericFilterTable";
import { FooterTools } from "../helpers/components/FooterTools";
import { InputSearchTable } from "../helpers/components/InputSearchTable";
// Components
import OpenDialog from "components/Dialogs/OpenDialog";
import OperationDialog from "components/Dialogs/OperationDialog";
import { ManageUserButtons } from "../../BasicTable/TableStyle";
import FormRegisterOperator from "components/Dialogs/OperatorRegister";
import FormRegisterAdminCompany from "components/Dialogs/AdminCompanyRegister";

import CustomButton from "components/Buttons/CustomButton";
// Hooks
import { UseHandleManageUser } from "./UseHandleManageUser";
//Utils
import { roles } from "utils/roles";
import useUserRole from "hooks/useUserRole";
import { SOURCE_ID } from "utils/constStrings";
import { useUserStore } from "zustandGloabalStore/user";
import { AllowAccessInstance } from "components/Dialogs/AllowAccessInstance";

export const ManageUsersTable = ({
  register,
  style,
  table,
  useFilter,
  filterValue,
  setFilterCheckBox,
  filterCheckBox,
  columnResizeMode,
  state,
  handleGlobalFilterChange,
  buttonFilters,
  handlerSetValue,
  setUseFilter,
  setRows,
  rows,
  valuesNoSessions,
  setValuesNoSessions,
  valuesNoSessionsMobile,
  setValuesNoSessionsMobile,
}) => {
  const [openFormRegister, setOpenFormRegister] = useState(false);
  const [openFormAllowAccess, setOpenFormAllowAccess] = useState(false);

  const { userRole } = useUserRole();
  const { role } = useUserStore((state) => state);

  const {
    handlers: {
      handlerUserLogout,
      handlerUserLogoutMobile,
      userLogout,
      handlerNoSessions,
      handlerNoSessionsMobile,
      handleClickUser,
      statusUser,
      deleteUser,
      handleUserActivation,
      handleDelete,
    },
    states: {
      openDialog,
      contentDialog,
      openManageUser,
      openUserLogout,
      openUserLogoutMobile,
      setOpenDialog,
      setOpenManageUser,
      setOpenUserLogout,
      setOpenUserLogoutMobile,
    },
  } = UseHandleManageUser({
    setRows,
    rows,
    setValuesNoSessions,
    setValuesNoSessionsMobile,
    register,
  });

  if (openFormAllowAccess && role === roles.decimetrixAdmin) {
    return (
      <AllowAccessInstance
        openDialog={openFormAllowAccess}
        setOpenDialog={() => setOpenFormAllowAccess(false)}
        register={register}
      />
    );
  } else {
    return (
      <div
        className="background-1"
        style={{
          marginTop: "0px",
        }}
      >
        <OpenDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          execute={deleteUser}
          content={contentDialog}
          disagree={() => {}}
        />
        <OpenDialog
          openDialog={openManageUser}
          setOpenDialog={setOpenManageUser}
          execute={statusUser}
          content={contentDialog}
          disagree={() => {}}
        />
        <OpenDialog
          openDialog={openUserLogout}
          setOpenDialog={setOpenUserLogout}
          execute={() => userLogout({ source: SOURCE_ID.desktop })} // Flag source: 1 - TO CLOSE SESSION WEB
          content={contentDialog}
          disagree={() => {}}
        />
        <OpenDialog
          openDialog={openUserLogoutMobile}
          setOpenDialog={setOpenUserLogoutMobile}
          execute={() => userLogout({ source: SOURCE_ID.mobile })} // Flag source: 1 - TO CLOSE SESSION MOBILE
          content={contentDialog}
          disagree={() => {}}
        />
        <OperationDialog
          style={{
            margin: 0,
            padding: 0,
            width: "70%",
            height: "70%",
            maxWidth: "none",
          }}
        />

        {register === roles.companyAdmin && (
          <FormRegisterAdminCompany
            open={openFormRegister}
            handleClose={() => setOpenFormRegister(false)}
          />
        )}
        {register === roles.operario && (
          <FormRegisterOperator
            open={openFormRegister}
            handleClose={() => setOpenFormRegister(false)}
          />
        )}

        <ContainerTable className="background-2 border-color-1">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <h1>
              {register === roles.companyAdmin
                ? "Add Administrator"
                : "Add Operator"}
            </h1>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "45%",
              }}
            >
              <CustomButton
                text={
                  register === roles.companyAdmin
                    ? "Add New Administrator"
                    : "Add New Operator"
                }
                onClick={() => setOpenFormRegister(true)}
                margin={"0px 5"}
              />
              {role === roles.decimetrixAdmin && (
                <CustomButton
                  text={
                    register === roles.companyAdmin
                      ? "Allow Access To Existing Administrators"
                      : "Allow Access To Existing Operators"
                  }
                  onClick={() => setOpenFormAllowAccess(true)}
                  margin={"0px 5"}
                />
              )}
            </div>
          </div>
          <InputSearchTable
            state={state}
            handleGlobalFilterChange={handleGlobalFilterChange}
            table={table}
            useFilter={useFilter}
            setUseFilter={setUseFilter}
            buttonFilters={buttonFilters}
            handlerSetValue={handlerSetValue}
          />
        </ContainerTable>
        <Divider />
        <ContainerContentTable
          className="background-2 border-color-1"
          style={{ flex: 1, borderRadius: "5px" }}
        >
          <ContentTable
            className="background-row-2 color1"
            maxHeight={style?.maxHeight}
            style={{
              borderRadius: "5px 5px 0 0",
              overflow: "scroll",
              height: "480px",
            }}
          >
            <table
              {...{
                style: {
                  width: "100%",
                },
              }}
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    style={{
                      width: "100%",
                      fontFamily: document.body.style.fontFamily,
                    }}
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className="background-table-color"
                        {...{
                          key: header.id,
                          colSpan: header.colSpan,
                          style: {
                            width: "100vw",
                          },
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <ExpandLessIcon />,
                                desc: <ExpandMoreIcon />,
                              }[header.column.getIsSorted()] ?? null}
                            </div>
                            {header.column.getCanFilter() && useFilter ? (
                              <div style={{ marginTop: "5px" }}>
                                <GenericFilterTable
                                  column={header.column}
                                  table={table}
                                  setValue={filterValue}
                                  setFilterCheckBox={setFilterCheckBox}
                                  filterCheckBox={filterCheckBox}
                                />
                              </div>
                            ) : null}
                          </>
                        )}

                        <div
                          {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `resizer ${
                              header.column.getIsResizing() ? "isResizing" : ""
                            }`,
                            style: {
                              transform:
                                columnResizeMode === "onEnd" &&
                                header.column.getIsResizing()
                                  ? `translateX(${
                                      table.getState().columnSizingInfo
                                        .deltaOffset
                                    }px)`
                                  : "",
                            },
                          }}
                        />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <tr
                    style={{ fontFamily: document.body.style.fontFamily }}
                    key={row.id}
                    className={
                      index % 2 !== 0
                        ? "background-row-1 color1"
                        : "background-row-2 color1"
                    }
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      const prop = row.original.canDelete
                        ? { onClick: () => handleDelete(row.original) }
                        : { disable: "true" };
                      const prop2 = {
                        onClick: () => handleUserActivation(row.original),
                      };
                      if (
                        cell.getContext().column.columnDef.header().props
                          .children === "Name"
                      )
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                minWidth: `${cell.column.getSize()}px`,
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => handleClickUser(row.original.id)}
                          >
                            {flexRender(cell.getValue(), cell.getContext())}
                          </td>
                        );
                      if (
                        cell.getContext().column.columnDef.header().props
                          .children === "Session"
                      )
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                minWidth: `${cell.column.getSize()}px`,
                              },
                            }}
                          >
                            {flexRender(cell.getValue(), cell.getContext()) ===
                            "Online"
                              ? "🟢"
                              : "🔴"}
                          </td>
                        );
                      if (
                        cell.getContext().column.columnDef.header().props
                          .children === "Actions"
                      )
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                minWidth: `${cell.column.getSize()}px`,
                              },
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <ManageUserButtons {...prop}>
                                {userRole && userRole !== roles.itSupport && (
                                  <DeleteIcon
                                    sx={{
                                      color: prop.disable
                                        ? `${VISIBILITY_OFF_ICON}`
                                        : `${RED_COLOR}`,
                                      cursor: prop.disable
                                        ? "not-allowed"
                                        : "pointer",
                                      fontSize: "2.6rem",
                                    }}
                                  />
                                )}
                              </ManageUserButtons>
                              <ManageUserButtons {...prop2}>
                                {row.original.isEnable === false ? (
                                  <PersonOffIcon
                                    sx={{
                                      color: `${VISIBILITY_OFF_ICON}`,
                                      cursor: "pointer",
                                      fontSize: "2.6rem",
                                    }}
                                  />
                                ) : (
                                  <PersonOutlineIcon
                                    className="color1"
                                    sx={{
                                      color: `${PRIMARY_COLOR}`,
                                      cursor: "pointer",
                                      fontSize: "2.6rem",
                                    }}
                                  />
                                )}
                              </ManageUserButtons>
                            </div>
                          </td>
                        );

                      if (
                        cell.getContext().column.columnDef.header().props
                          .children === "No Sessions Web"
                      )
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                minWidth: `${cell.column.getSize()}px`,
                              },
                            }}
                          >
                            <input
                              style={{
                                width: "45px",
                                padding: "0 5px",
                                border: "none",
                                background: `${BACKGROUND_GREY}`,
                                color: `${BLACK_COLOR}`,
                              }}
                              type="number"
                              value={valuesNoSessions[row.original.id]}
                              min={1}
                              onChange={(e) =>
                                handlerNoSessions(e, row.original)
                              }
                            />
                          </td>
                        );
                      if (
                        cell.getContext().column.columnDef.header().props
                          .children === "User Log Out"
                      )
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                minWidth: `${cell.column.getSize()}px`,
                              },
                            }}
                          >
                            <LogoutIcon
                              className="color1"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => handlerUserLogout(row.original.id)}
                            />
                          </td>
                        );
                      if (
                        cell.getContext().column.columnDef.header().props
                          .children === "No Sessions Mobile"
                      )
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                minWidth: `${cell.column.getSize()}px`,
                              },
                            }}
                          >
                            <input
                              style={{
                                width: "45px",
                                padding: "0 5px",
                                border: "none",
                                background: `${BACKGROUND_GREY}`,
                                color: `${BLACK_COLOR}`,
                              }}
                              type="number"
                              value={valuesNoSessionsMobile[row.original.id]}
                              min={1}
                              onChange={(e) =>
                                handlerNoSessionsMobile(e, row.original)
                              }
                            />
                          </td>
                        );

                      if (
                        cell.getContext().column.columnDef.header().props
                          .children === "User Log Out Mobile"
                      )
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                minWidth: `${cell.column.getSize()}px`,
                              },
                            }}
                          >
                            <LogoutIcon
                              className="color1"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handlerUserLogoutMobile(row.original.id)
                              }
                            />
                          </td>
                        );
                      return (
                        <td
                          {...{
                            key: cell.id,
                            style: {
                              minWidth: `${cell.column.getSize()}px`,
                            },
                          }}
                        >
                          {flexRender(cell.getValue(), cell.getContext()) ||
                            cell.getValue()}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="h-2" />
          </ContentTable>
          <FooterTools table={table} />
        </ContainerContentTable>
      </div>
    );
  }
};
