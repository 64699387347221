// React
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
// Services
import { moveTokenToRevoquedToken } from "services/sessions/create";
import deleteUserSession from "services/sessions/delete";
import findUserSessions from "services/sessions/find";
import deleteOperator from "services/users/deleteOperator";
import {
  updateUserVerification,
  updateUserVerificationStatus,
  userVerification,
} from "services/users/userVerification";
// Components
import Profile from "components/Profile/Profile";
import { SOURCE_ID } from "utils/constStrings";
import deleteAdminCompany from "services/users/deleteAdminCompany";
import { useSWRConfig } from "swr";
import { useUserStore } from "zustandGloabalStore/user";
import { urls } from "utils/urlKeys";
import { roles } from "utils/roles";

export const UseHandleManageUser = ({
  setRows,
  rows,
  setValuesNoSessions,
  setValuesNoSessionsMobile,
  register,
}) => {
  const { mutate } = useSWRConfig();

  const [openDialog, setOpenDialog] = useState(false);
  const [idUserDelete, setIdUserDelete] = useState(null);
  const [contentDialog, setContentDialog] = useState({});
  const [openManageUser, setOpenManageUser] = useState(false);
  const [infoUser, setInfoUser] = useState({
    id: 0,
    email: "",
  });
  const [openUserLogout, setOpenUserLogout] = useState(false);
  const [openUserLogoutMobile, setOpenUserLogoutMobile] = useState(false);

  const [userIdLogout, setUserIdLogout] = useState(null);

  const dispatch = useDispatch();
  const { instanceManager } = useUserStore((state) => state);

  const handleDelete = async (user) => {
    setIdUserDelete(user.id);
    setContentDialog({
      title: "",
      description: `Are you sure you want to delete ${user.firstName}?`,
      disagree: "Cancel",
      agree: "Accept",
    });
    setOpenDialog(true);
  };

  const handleUserActivation = async (user) => {
    setInfoUser({
      id: user.id,
      email: user.email,
    });
    setContentDialog({
      title: "Acess Management",
      description: user.isEnable
        ? `Do you want disable ${user.firstName} user?`
        : `Do you want enable ${user.firstName} user?`,
      disagree: "Cancel",
      agree: "Accept",
    });
    setOpenManageUser(true);
  };

  const deleteUser = async () => {
    if (register === roles.companyAdmin) {
      await deleteAdminCompany(idUserDelete, instanceManager.id);
    } else {
      await deleteOperator(idUserDelete, instanceManager.id);
    }
    await mutate(urls.users.getAdminCompanyByInstance(instanceManager.id));
    await mutate(urls.users.getOperatorsInstanceDynamic(instanceManager.id));
    setContentDialog({});
    setIdUserDelete(null);
    setOpenDialog(false);
  };

  const statusUser = async () => {
    const userVerificationResponse = await userVerification(infoUser.email);
    await updateUserVerificationStatus({
      userId: infoUser.id,
      status: !userVerificationResponse.isEnable,
    });
    await mutate(urls.users.getAdminCompanyByInstance(instanceManager.id));
    await mutate(urls.users.getOperatorsInstanceDynamic(instanceManager.id));
    setOpenManageUser(false);
    setContentDialog({});
    setInfoUser({});
  };

  const handleClickUser = async (userId) => {
    if (userId) {
      const user = {
        content: <Profile userId={userId} />,
        title: "User Details",
      };
      dispatch(setOperationDetails(user));
      dispatch(setShowOperationDetails(true));
    }
  };

  const handlerNoSessions = async (e, row) => {
    const noSessions = e.target.value;
    const id = row.id;
    setValuesNoSessions((prevValues) => ({
      ...prevValues,
      [id]: noSessions,
    }));
    if (noSessions !== "" && parseInt(noSessions) > 0) {
      await updateUserVerification({
        userId: id,
        changes: {
          noSessions: parseInt(noSessions),
        },
      });
    }
  };

  const handlerNoSessionsMobile = async (e, row) => {
    const noSessionsMobile = e.target.value;
    const id = row.id;
    setValuesNoSessionsMobile((prevValues) => ({
      ...prevValues,
      [id]: noSessionsMobile,
    }));
    if (noSessionsMobile !== "" && parseInt(noSessionsMobile) > 0) {
      await updateUserVerification({
        userId: id,
        changes: {
          noSessionsMobile: parseInt(noSessionsMobile),
        },
      });
    }
  };

  const userLogout = async ({ source }) => {
    try {
      const userSessions = await findUserSessions({
        token: localStorage.getItem("token"),
        userId: userIdLogout,
        sourceId: source,
      });
      if (userSessions.length > 0) {
        await Promise.all(
          userSessions.map(async (userSession) => {
            await moveTokenToRevoquedToken({
              token: userSession.token,
              sourceId: source,
            });
            const resDeleteSession = await deleteUserSession({
              id: userSession.id,
            });
            return resDeleteSession;
          })
        );
      }
      await updateUserVerification({
        userId: userIdLogout,
        changes: { activeSession: false },
      });
      setOpenUserLogout(false);
      setOpenUserLogoutMobile(false);
    } catch (error) {
      console.error(error);
      throw new Error("Error deleting user session");
    }
  };

  const handlerUserLogout = async (userId) => {
    setUserIdLogout(userId);
    setContentDialog({
      title: "User Log Out Web",
      description: "Do you want to close all sessions web for this user?",
      disagree: "Cancel",
      agree: "Accept",
    });
    setOpenUserLogout(true);
  };

  const handlerUserLogoutMobile = async (userId) => {
    setUserIdLogout(userId);
    setContentDialog({
      title: "User Log Out Mobile",
      description: "Do you want to close all sessions mobile for this user?",
      disagree: "Cancel",
      agree: "Accept",
    });
    setOpenUserLogoutMobile(true);
  };

  return {
    handlers: {
      handlerUserLogout,
      handlerUserLogoutMobile,
      userLogout,
      handlerNoSessions,
      handlerNoSessionsMobile,
      handleClickUser,
      statusUser,
      deleteUser,
      handleUserActivation,
      handleDelete,
    },
    states: {
      openDialog,
      contentDialog,
      openManageUser,
      openUserLogout,
      openUserLogoutMobile,
      setOpenDialog,
      setOpenManageUser,
      setOpenUserLogout,
      setOpenUserLogoutMobile,
    },
  };
};
