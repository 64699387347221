import GenericTable from "components/Tables/GenericTable/GenericTable";
import {
  ColumnAdminConfig,
  CreateNewRegionButton,
  IconAdminConfigChangeColum,
  InformationShare,
  RowAdminConfig,
  TableAdminConfig,
} from "../configAdminStyles";
import { ContainerForm } from "components/Forms/FormStyles";
import Form from "components/Forms/Form";
import { IoIosArrowDropupCircle, IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import useForm from "hooks/useForm";
import MessagesDialog from "components/Dialogs/MessagesDialog.jsx";
import {
  headersTableLibraryLine,
  initialValuesFormLibraryLine,
  keyValuesTableLibraryLine,
  fromLibrary,
} from "../dataConfigAdmin";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";

// Config admin class
import ConfigAdmin from "../ConfigAdmin.js";

import Fields from "pages/ConfigAdmin/typeElemts/Fields.jsx";
import { TYPE_FIELDS, TYPE_OBJECTS } from "utils/constStrings";
import { roles } from "utils/roles";
import { DuplicateLibrary } from "../LibraryPoint/DuplicateLibrary";
import { createLineTypeObject } from "services/lines/createLineTypeObjects";

// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <IoIosArrowDropupCircle className="icon" />
    </IconAdminConfigChangeColum>
  );
};

export default function ObjectLinesLibraries({
  setOpenDialogConfig,
  setContentDialogConfig,
  adminCompanyId,
  adminsInfo,
}) {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  // const [select] = useState(1); // id: 1 -> default parametrized library
  const [typeElementsSelect, setTypeElementsSelect] = useState([]);

  const [dialog, setDialog] = useState({
    open: false,
    actions: {
      agree: "Close",
    },
  });

  const role = localStorage.getItem("role");
  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormLibraryLine);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  //Alerts
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
  });

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [libreriesRows, setLibreriesRows] = useState([]);

  // Click  on type element ghg into table
  const handleClickLibrary = (id) => {
    configAdmin.handlerClickIntoTable(id);
  };

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableLibraryLine);
    configAdmin.setKeyValuesTable(keyValuesTableLibraryLine);
    configAdmin.setSetRows(setLibreriesRows);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetLoadingCreate(setLoadingCreate);
    configAdmin.setSetLoadingDelete(setLoadingDelete);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin.setCreateTypeElements(createLineTypeObject);
    configAdmin?.setEndpoint(
      adminCompanyId && adminCompanyId !== "defaultLibrary"
        ? `api/v1/line-libraries?adminCompanyId=${adminCompanyId}&`
        : `api/v1/line-libraries`
    );
    configAdmin?.setDialog(setOpenDialogConfig, setContentDialogConfig);
    configAdmin.setFromLibrary(fromLibrary.lineObjects);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, [setOpenDialogConfig, setContentDialogConfig, adminCompanyId]);

  // Set library in config admin
  useEffect(() => {
    configAdmin?.setEndpointFetch(
      adminCompanyId && adminCompanyId !== "defaultLibrary"
        ? `api/v1/line-libraries?adminCompanyId=${adminCompanyId}&`
        : `api/v1/line-libraries`
    );
    configAdmin?.fetchData();
  }, [configAdmin, adminCompanyId]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  useEffect(() => {
    configAdmin && configAdmin.setsetTypeElementsSelect(typeElementsSelect);
    configAdmin && configAdmin.setsetAdminCompanyId(adminCompanyId);
  }, [typeElementsSelect, configAdmin, adminCompanyId]);

  //alerts
  useEffect(() => {
    const alert = configAdmin?.getAlertStatus();
    if (alert?.open) {
      setOpenAlert({
        open: alert.open,
        severity: alert.severity,
      });
    }
  }, [configAdmin?.getAlertStatus, configAdmin]);

  return (
    <>
      <RowAdminConfig>
        {/*  container for generic table in libraries configuration */}
        <OpenDialog
          openDialog={dialog?.open}
          setOpenDialog={setDialog}
          execute={() => {}}
          content={{
            title: "Select library and types to duplicate",
            description: (
              <DuplicateLibrary
                adminCompanyId={adminCompanyId}
                adminsInfo={adminsInfo}
                lineObject={true}
                configAdmin={configAdmin}
                handleChangeForm={handleChangeForm}
                setDialog={setDialog}
                setTypeElementsSelect={setTypeElementsSelect}
                setEndpointFetch={configAdmin?.setEndpointFetch()}
              />
            ),
          }}
          minWidth={"500px"}
        />
        <TableAdminConfig>
          {configAdmin && (
            <GenericTable
              headers={configAdmin.getHeadersTable()}
              rows={libreriesRows}
              keyValues={configAdmin.getKeyValuesTable()}
              handlerClick={handleClickLibrary}
              style={{
                width: 60,
              }}
              state={true}
              configBar={true}
              from={"config"}
            />
          )}
        </TableAdminConfig>
        {/*  container holding form for crud with libraries configuration */}
        <ColumnAdminConfig>
          {seeForm ? (
            <ContainerForm
              see={seeForm}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Form
                form={form}
                handleChange={handleChangeForm}
                handleRows={handleClickLibrary}
                buttons={buttonsForm}
                renderBeforeTitle={[
                  <ButtonSeeInitalView
                    click={() => configAdmin.toggleForm()}
                  />,
                ]}
                title={"Create a new Digital Twin Library"}
                renderBeforeButtons={[
                  <Fields
                    typeFields={TYPE_FIELDS.typeToLibrary}
                    form={form}
                    handleChange={handleChangeForm}
                    from={TYPE_OBJECTS.line}
                  />,
                ]}
                loadingCreate={loadingCreate}
                loadingDelete={loadingDelete}
              />
            </ContainerForm>
          ) : (
            <>
              <CreateNewRegionButton
                className="background-row-2 color1 border-color-1"
                margin={role === roles.decimetrixAdmin ? "7px 0" : "13px 0"}
              >
                Create a new Digital Twin Library
                <button onClick={() => configAdmin.toggleForm()}>
                  <span>
                    <IoMdAdd />
                  </span>
                  New Type Library
                </button>
                {role === roles.decimetrixAdmin &&
                  adminCompanyId !== "defaultLibrary" && (
                    <button
                      onClick={() =>
                        setDialog((current) => ({
                          ...current,
                          open: true,
                        }))
                      }
                    >
                      <span>
                        <IoMdAdd />
                      </span>
                      Duplicate Library from another Admin company{" "}
                    </button>
                  )}
              </CreateNewRegionButton>
              <InformationShare className="background-row-2 border-color-1">
                <div className="head color1">
                  Edit Existing Digital Twin Library
                </div>
                <div className="body color1">
                  To Edit Click on Existing Digital Twin Library ID
                </div>
              </InformationShare>
            </>
          )}
        </ColumnAdminConfig>
      </RowAdminConfig>
      {openAlert.open && (
        <MessagesDialog
          open={openAlert.open}
          severity={openAlert?.severity}
          message={configAdmin?.messageShow}
          handleClose={() => setOpenAlert(false)}
        />
      )}
    </>
  );
}
