import React, { useEffect } from "react";
import { HubCard, HubH2, HubP } from "./HubStyles";
import Cicleicon from "images/Cicleicon";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "zustandGloabalStore/user";
import { roles } from "utils/roles";

const InstancesCards = ({ instancesUser }) => {
  const { setInstanceId, role } = useUserStore((state) => state);
  const navigate = useNavigate();

  // Verificar al cargar el componente si hay una ruta pendiente para navegar
  useEffect(() => {
    const pendingNavigation = localStorage.getItem("pendingNavigation");
    if (pendingNavigation) {
      // Limpiar la navegación pendiente
      localStorage.removeItem("pendingNavigation");
      // Navegar a la ruta guardada
      navigate(pendingNavigation);
    }
  }, [navigate]);

  const handleCardClick = (item) => {
    // Guardar el ID en localStorage
    setInstanceId(item.instanceId);

    // Determinar la ruta de navegación según el rol
    let targetRoute = "/";
    if (role === roles.operario) {
      targetRoute = "/hub";
    } else if (role === roles.companyAdmin) {
      targetRoute = "/admin";
    }

    // Guardar la ruta para después de la recarga
    localStorage.setItem("pendingNavigation", targetRoute);

    // Recargar la página para que el store se actualice correctamente
    window.location.reload();
  };

  return (
    <>
      {instancesUser &&
        instancesUser.map((item) => (
          <HubCard
            className="background-card-color"
            key={item.id}
            onClick={() => handleCardClick(item)}
          >
            <HubH2>{item.instance.name}</HubH2>
            <img width="200px" src={item.instance.urlImage}/>
            <HubP>{item.instance.description}</HubP>
          </HubCard>
        ))}
    </>
  );
};

export default InstancesCards;
