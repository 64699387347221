import styled from "styled-components";
import { BLACK_COLOR, WHITE_BACKGROUND, WHITE_COLOR } from "utils/const";

export const Container = styled.div`
  width: 100%;
  height: ${(props) => props.height}%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 3px;
  background-color: ${WHITE_BACKGROUND};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 10)};
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8); */
  border-radius: 5px 5px 0 0;
  display: ${(props) => props.display};

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    border-top: ${WHITE_COLOR};
    border-left: ${WHITE_COLOR};
    border-right: ${WHITE_COLOR};
  }
  .head-right {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    gap: 0.5rem;
  }

  @media (min-width: 1200px) and (max-width: 1845px) {
    .head-right {
      margin-bottom: 5rem;
    }
  }

  @media (min-width: 1845px) and (max-width: 1920px) {
    .head-right {
      margin-bottom: 0rem;
    }
  }

  .select {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #9bc4cb;
    background-color: ${WHITE_COLOR};
    color: ${BLACK_COLOR};
    font-size: 16px;
    padding: 0 1rem;
    outline: none;
  }

  .search {
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
    padding: 0 1rem;
    outline: none;
    border: 1px solid #9bc4cb;
  }

  .close {
    cursor: pointer;
    color: var(--background-primary_color);
    font-size: 1.7rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }

  .title {
    font-size: 2rem;
    font-weight: 500;
  }

  .title.total {
    margin-right: 1rem;
    color: ${BLACK_COLOR};
  }

  .bold {
    font-weight: 700;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 5px;
    padding: 5px 8px;
    border: none;
    background-color: var(--background-primary_color);
    color: ${WHITE_COLOR};
    font-size: 1.6rem;
    margin: auto;
    cursor: pointer;
  }

  .button:hover {
    background-color: var(--background-secondary_color);
  }
`;
export const SearchPosition = styled.div`
  margin-top: -50px;

  @media (max-width: 1201px) {
    margin-top: 0;
  }
`;

export const customStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  menu: (base) => ({
    ...base,
    width: "200px",
    maxWidth: "none",
    maxHeight: "auto",
  }),

  valueContainer: (styles) => {
    return {
      ...styles,
      padding: "1px 2px",
    };
  },
};

export const customStylesLibraries = {
  option: (provided) => ({
    ...provided,
    fontSize: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
  menu: (base) => ({
    ...base,
    width: "150px",
    maxWidth: "none",
    maxHeight: "auto",
  }),
};
