/**
 *
 * @param {*} field - campo dinamico, generico para librerias de objetos, eventos, componentes y lineas
 * @param {*} colorIndex - indice del color a buscar en "optionsColors" con tipo "Select"
 * @param {*} value - valor del campo dinamico tipo "Number" para evaluar restricciones
 * @returns field?.optionsColors?.at(colorIndex) - cuando el tipo de campo dinamico es "Select"
 * @returns it.color - cuando el tipo de campo dinamico es "Number"
 */
function getColorFromField({ field = {}, colorIndex = 0, value, typeColor }) {
  const fieldType = field?.type;
  if (!Object.keys(field).length) return undefined;
  if (fieldType === "select" && colorIndex < 0) return undefined;
  if (fieldType === "select" && field.circuit) {
    return field?.optionsColors?.at(colorIndex);
  }
  if (
    fieldType === "number" &&
    field.circuit &&
    (value !== undefined || !isNaN(value))
  ) {
    const restrictions = field?.numberGroupingOptions?.restrictions?.filter(
      (it) => it?.state
    );
    let color = typeColor !== undefined ? typeColor : "#000000";
    restrictions.forEach((it) => {
      if (it.symbol === ">" && value > it.value) {
        color = it.color;
        return;
      } else if (it.symbol === ">=" && value >= it.value) {
        color = it.color;
        return;
      } else if (it.symbol === "<" && value < it.value) {
        color = it.color;
        return;
      } else if (it.symbol === "<=" && value <= it.value) {
        color = it.color;
        return;
      } else if (it.symbol === "!==" && value !== it.value) {
        color = it.color;
        return;
      } else if (
        it.symbol === "><" &&
        value > it.valueMin &&
        value < it.valueMax
      ) {
        color = it.color;
        return;
      } else if (
        it.symbol === ">=<=" &&
        value >= it.valueMin &&
        value <= it.valueMax
      ) {
        color = it.color;
        return;
      } else if (it.symbol === "==" && value === it.value) {
        color = it.color;
        return;
      } else {
        return;
      }
    });
    return color;
  }
  return undefined;
}

export default getColorFromField;
