import Grid from "@material-ui/core/Grid";
import PhotoGallery from "components/ElementDetails/PhotoGalley/PhotoGallery";
import { useSWRConfig } from "swr";
import { config } from "config.js";
import { PRIMARY_COLOR, WHITE_COLOR } from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import DownloadPhotos from "../PhotoGalley/DownloadPhotos";
/**
 * Renders a gallery of media objects by type.
 *
 * @param {Object} object - The object data.
 * @param {Array} mediaObject - An array of media objects.
 * @return {JSX.Element} The rendered gallery.
 */
const GalleryByType = ({ object, mediaObject }) => {
  const urlData = `${config.URL_BACKEND_PG}api/v1/objects/${object?.id}`;
  const { theme } = useContext(ThemeContext);

  const { mutate } = useSWRConfig();

  const uploadImages = () => {
    mutate(urlData);
  };

  return (
    <>
      {mediaObject.map((mediaGroup) => {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            key={mediaGroup[0].alias}
          >
            <div
              className={`photo-container ${theme === "light" ? "bb" : "bb2"}`}
            >
              <h1
                className="operation-title"
                style={{
                  color:
                    theme === "light"
                      ? "var(--background-primary_color)"
                      : WHITE_COLOR,
                }}
              >
                <span>{mediaGroup[0].alias}</span>
              </h1>
              {
                <div
                  style={{
                    width: "85%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 0px 20px 0px",
                  }}
                >
                  <PhotoGallery
                    photos={mediaGroup}
                    ownId={object.id}
                    state={false}
                    type={"object"}
                    mongoId={object.mongoId}
                    uploadImages={uploadImages}
                  />{" "}
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <DownloadPhotos
                      photos={mediaGroup}
                      infoOPoint={`${object?.id}_${object?.typeElement?.name}`}
                    />
                  </div>
                </div>
              }
            </div>
          </Grid>
        );
      })}
    </>
  );
};

export default GalleryByType;
