import Container from "@mui/material/Container";
import useGet from "hooks/useFetch";
import Layout from "components/Layout/AdminDecimetrix";
import DescriptionHub from "pages/AdminCompany/Hub/DescriptionHub";
import React, { useEffect } from "react";
import Loading from "components/Lodings/LoadingV2";
import AdminTabsDeci from "./AdminTabsDeci";
import { urls } from "utils/urlKeys";

const Admin = () => {
  const [adminDecimetrix, errorAdminDecimetrix] = useGet(
    urls.adminDecimetrix.detail
  );

  // useEffect(() => {
  //   const persist = localStorage.getItem("persist:root");
  //   if (persist !== null) {
  //     localStorage.removeItem("persist:root");
  //   }
  // }, []);

  return (
    <>
      <Layout>
        <Container
          className="background-1"
          maxWidth="false"
          style={{
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          {!errorAdminDecimetrix && adminDecimetrix ? (
            <>
              <DescriptionHub adminDecimetrix={adminDecimetrix} />
              <AdminTabsDeci />
            </>
          ) : (
            <Loading />
          )}
        </Container>
      </Layout>
    </>
  );
};

export default Admin;
