import { useState, useEffect, useCallback } from "react";
// Helpers
import {
  sumValues,
  countByValues,
} from "pages/Dashboard/helpers/eventsGroupDonutChart";
import { createRowsEvents } from "pages/Dashboard/helpers/createRowsEvents";

// Store
import useStoreDashboard from "pages/Dashboard/Store";
// Dependecies
import useSwr from "swr";
import { urlsApiMg } from "utils/urlKeys";

export const useFindGroupDoughnutChartsEvents = ({
  openDialog,
  events,
  loading,
}) => {
  const [charts, setCharts] = useState(null);
  const [groupDonutCharts, setGroupDonutCharts] = useState(null);
  const [eventsFormat, setEventsFormat] = useState(null);
  const reloadCharts = useStoreDashboard((state) => state.reloadCharts);

  const { data: dataGroupDonutChart, error: errorDataGroupDonutChart } = useSwr(
    urlsApiMg.dashboard.doughnutChartGroup("events")
  );

  useEffect(() => {
    if (!errorDataGroupDonutChart && dataGroupDonutChart)
      setCharts(dataGroupDonutChart);
  }, [dataGroupDonutChart, errorDataGroupDonutChart, openDialog, reloadCharts]);

  useEffect(() => {
    const eventsFormat = createRowsEvents({ events });
    setEventsFormat(eventsFormat);
  }, [events]);

  const getDataGroupDonutChart = useCallback(async () => {
    if (!loading && eventsFormat) {
      const chartsFilterCount = charts.filter(
        (chart) => chart.type === "count"
      );
      const countCharts = countByValues({
        charts: chartsFilterCount,
        events: eventsFormat,
      });

      const chartsFilterSum = charts.filter((chart) => chart.type === "sum");
      const sumCharts = sumValues({
        charts: chartsFilterSum,
        events: eventsFormat,
      });

      setGroupDonutCharts([...countCharts, ...sumCharts]);
    }
  }, [loading, eventsFormat, charts]);

  useEffect(() => {
    if (charts && events) {
      getDataGroupDonutChart();
    }
  }, [charts, events, getDataGroupDonutChart]);

  return { groupDoughnutCharts: groupDonutCharts };
};
