import { contentByHost } from "utils/contentByHost";

export function useContentData({
  section = "signIn",
  defaultLanguage = "en",
  isRedDragon = false,
}) {
  // Determine the active "dragon"
  const currentDragon = isRedDragon ? "redDragon" : "greenDragon";

  // Set the language dynamically (you can pass a default or allow changes)
  const language = defaultLanguage;

  // Extract data from contentByHost based on the section (signIn, mainHub, etc.)
  const contentData = contentByHost[currentDragon][language][section];

  return contentData; // Returns an object containing the section's content (image, title, subtitle, etc.)
}
