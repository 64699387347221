import { length, lineString } from "@turf/turf";

// export function calculateLength(coordinates = [], unit = "kilometers") {
//   let distance = 0;
//   if (!coordinates.length) return distance;
//   const preparedLineString = lineString(coordinates);
//   distance = length(preparedLineString, { units: unit });
//   return parseFloat(Number(distance).toFixed(4));
// }

export function calculateLength(coordinates = [], unit = "kilometers") {
  let distance = 0;
  if (!coordinates.length) return distance;
  const preparedLineString = lineString(coordinates);
  // Calcular la distancia en metros (unidad base)
  const distanceInMeters = length(preparedLineString, { units: "meters" });
  // Mapeo de conversiones
  const conversions = {
    inch: distanceInMeters * 39.3701, // 1 meter = 39.3701 inches
    foot: distanceInMeters * 3.28084, // 1 meter = 3.28084 feet
    miles: distanceInMeters / 1609.34, // 1 mile = 1609.34 meters
    meters: distanceInMeters, // No conversión, ya está en metros
    kilometers: distanceInMeters / 1000, // 1 kilometer = 1000 meters
  };
  // Realiza la conversión a la unidad deseada
  if (!conversions[unit]) {
    return 0;
    // throw new Error(`Unsupported unit: ${unit}. Please use one of: ${Object.keys(conversions).join(", ")}`);
  }
  distance = conversions[unit];
  return parseFloat(distance.toFixed(4));
}

export function calculateLengthFromDistance(distance = 0, unit = "kilometers") {
  const conversionRates = {
    kilometers: 1,
    meters: 1000,
    miles: 0.621371,
    foot: 3280.84,
    inch: 39370.1,
  };
  // Si la unidad no es válida, retornamos la distancia original
  if (!conversionRates[unit]) {
    return parseFloat(Number(distance).toFixed(4));
  }
  // Convertimos la distancia en kilómetros a la unidad deseada
  const convertedDistance = distance * conversionRates[unit];
  return parseFloat(Number(convertedDistance).toFixed(4));
}
