import { useMemo, useState, useEffect } from "react";
import useSwr from "swr";
import { urls } from "utils/urlKeys";

const useFindAllEvents = () => {
  const [loading, setLoading] = useState(true);

  const { data: allDataEvents, error: errorEvents } = useSwr(
    urls.events.objects
  );

  const eventsObjects = allDataEvents && !errorEvents ? allDataEvents : null;

  // Fetch component events
  const { data: dataComponentEvents, error: errorComponentEvents } = useSwr(
    urls.events.components
  );
  const eventsComponents =
    dataComponentEvents && !errorComponentEvents ? dataComponentEvents : null;

  // Fetch events events
  const { data: dataEventsRelation, error: errorEventsRelation } = useSwr(
    urls.events.events
  );
  const eventsEvents =
    dataEventsRelation && !errorEventsRelation ? dataEventsRelation : null;

  const events = useMemo(() => {
    if (eventsObjects && eventsComponents && eventsEvents)
      return [...eventsObjects, ...eventsComponents, ...eventsEvents];
    return null;
  }, [eventsObjects, eventsComponents, eventsEvents]);

  // Utiliza useEffect para cambiar el estado de carga una vez que los datos se han cargado
  useEffect(() => {
    if (eventsObjects && eventsComponents && eventsEvents) {
      setLoading(false);
    }
  }, [eventsObjects, eventsComponents, eventsEvents]);

  return { events, loading };
};

export default useFindAllEvents;
