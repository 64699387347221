// React
import { useState } from "react";
import { useEffect } from "react";
// Components
import DeleteEventButton from "./DeleteEvent";
import OpenDialog from "components/Dialogs/OpenDialog";
import CustomButton from "components/Buttons/CustomButton";
// Helpers
import { isUrl, nameUrl, handleClickLink } from "helpers/isUrl";
import isoDateToString from "helpers/isoDateToString";
import { orderAttributes } from "components/ElementDetails/helpers";
// Dependecies
import { useSWRConfig } from "swr";
import { Grid } from "@mui/material";
import DynamicEvent from "components/Forms/Events/DynamicEvent";
// Hooks
import { useFetchEventLibraries } from "hooks/fetchLibraries";
import useShowDetails from "hooks/useShowDetails";
import { formatFloat } from "helpers/formatFloat";
import OperationInfo from "../OperationInfo";
import useUpdateLocationPoint from "hooks/useUpdateLocationPoint";
// Styles
import { Wrapperbtn } from "./EventDetailsElements";
import { InfoTitle } from "../ImageDetailsElements";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  BLACK_COLOR,
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  WHITE_COLOR,
} from "utils/const";
import { useContext } from "react";
import { ThemeContext } from "App";
import { LineTag } from "pages/ConfigAdmin/typeElemts/FieldsStyle";
import CreateEvent from "components/Maps/EventMap/CreateEvent";
import useFetchPointTypeEvent from "hooks/useFetchPointTypeEvents";
import updatePointEvent from "services/updatePointEvent";
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import ConvertButton from "./convertComponent";
import { urls } from "utils/urlKeys";

const getStyle = (theme) => ({
  color: theme === "light" ? document.body.style.color : WHITE_COLOR,
  background: theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
});

export default function TableEvent({ event, deleteButton }) {
  delete event?.object;
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [infoOperation, setInfoOperation] = useState({});
  const [showCreateEventForm, setShowCreateEventForm] = useState(false);
  const [openDialogNonGeoreferencedEvent, setOpenDialogNonGeoreferencedEvent] =
    useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedTypeEvents, setSelectedTypeEvents] = useState([]);
  const { data: dataLibrary, error: errorLibrary } = useFetchEventLibraries({
    id: event?.pointTypeEvent?.pointLibraryEventId,
  });
  const { theme } = useContext(ThemeContext);
  const colorStyle = getStyle(theme);

  const {
    data: { updateLongitude, updateLatitude, updateLocation },
    setData: { setUpdateLongitude, setUpdateLatitude, setUpdateLocation },
    handleUpdateLocation,
    handleCancelUpdate,
  } = useUpdateLocationPoint({ type: "event", point: event });

  const { handlerClickObjectId, handlerClickComponentId, handlerClickEventId } =
    useShowDetails();

  useEffect(() => {
    const omit = [
      "mongoId",
      "userId",
      "locationOperationId",
      "pointTypeEventId",
      "adminCompanyId",
      "pointTypeEvent",
      "locationOperation",
      "attributes",
      "date",
      "eventFiles",
      "PointEventRelation",
      "PointEventRelationWith",
      "user",
      "eventsCount",
      "fieldsCaptureMedia",
      "media",
      "pointComponent",
      "timeZoneOffset",
      "timeZone",
      "createdAt",
      "updatedAt",
      "id",
      "comments",
      "objectId",
      "pointComponentId",
    ];

    Object.keys(event).map((key) => {
      if (omit.includes(key)) return null;
      let value = event[key];
      if (!value) {
        return null;
      }
      setInfoOperation((prev) => ({ ...prev, [key]: value }));
      return null;
    });

    // Find user name
    const { adminCompany, operator } = event?.user;
    const { firstName, firstLastName } = adminCompany || operator;

    let operationInfoBasic = {
      ID: event?.id,
      "Created By": firstName + " " + firstLastName,
      "Created At": isoDateToString(event.createdAt),
      "Updated At": isoDateToString(event.updatedAt),
      "Collection Date": isoDateToString(event.date),
    };

    // Verificar si latitud y longitud son válidos antes de agregarlos
    if (
      event.locationOperation.latitude !== 0 &&
      event.locationOperation.latitude !== null
    ) {
      operationInfoBasic.Latitude = event.locationOperation.latitude;
    }

    if (
      event.locationOperation.longitude !== 0 &&
      event.locationOperation.longitude !== null
    ) {
      operationInfoBasic.Longitude = event.locationOperation.longitude;
    }

    setInfoOperation(operationInfoBasic);
  }, [event]);

  const reduceAttributes = () => {
    !errorLibrary &&
      event?.attributes?.forEach((attribute) => {
        const matchAlias = dataLibrary?.[0]?.fields?.find(
          (fields) => fields.name === attribute.name
        );
        if (matchAlias) {
          attribute.alias = matchAlias.alias;
        }
      });

    const filteredAttributes = event?.attributes?.map((item) => {
      let newItem = { ...item };
      try {
        if (typeof JSON.parse(item.value) === "object")
          newItem.value = JSON.parse(item.value);
      } catch (error) {
        return item;
      }
      return newItem;
    });

    const lineTagAttributes =
      dataLibrary?.[0]?.fields?.filter((field) => field.type === "lineTag") ||
      [];

    const combinedAttributes = [...filteredAttributes, ...lineTagAttributes];

    const newOrderAttributes = orderAttributes({
      // arrayToOrder: filteredAttributes,
      arrayToOrder: combinedAttributes,
      fromArray: dataLibrary,
    });

    // const newOrderAttributes = orderAttributes({
    //   arrayToOrder: filteredAttributes,
    //   fromArray: dataLibrary,
    // });

    return newOrderAttributes;
  };

  const { data: typeEventsTypeEvents } = useFetchPointTypeEvent({
    id: event.pointTypeEventId,
  });

  const handleUpdateData = () => {
    mutate(urls.events.detail.objects(event.id));
  };
  const shouldShowTooltip = true;

  const handleOpenCreateEventForm = (eventId, typeEvents) => {
    setSelectedEventId(eventId);
    setSelectedTypeEvents(typeEvents);
    setShowCreateEventForm(true);
  };

  const handleConvertToGeoreferencedEvent = async ({ isGeoreferenced }) => {
    await updatePointEvent({
      id: event.id,
      body: {
        isGeographic: isGeoreferenced,
      },
    });
    mutate(urls.events.detail.objects(event.id));
    mutate(urls.events.objects);
    mutate(urls.events.components);
    mutate(urls.events.events);
    setOpenDialogNonGeoreferencedEvent(false);
    dispatch(setOperationDetails({}));
    dispatch(setShowOperationDetails(false));
  };

  return (
    <InfoTitle>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        content={{
          title: "Update Event",
          description: (
            <DynamicEvent
              dataEvent={event}
              update={true}
              setOpenDialog={setOpenDialog}
              handleUpdateData={handleUpdateData}
            />
          ),
          disagree: "Cancel",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />

      <OpenDialog
        openDialog={openDialogNonGeoreferencedEvent}
        setOpenDialog={setOpenDialogNonGeoreferencedEvent}
        execute={() =>
          event.isGeographic
            ? handleConvertToGeoreferencedEvent({ isGeoreferenced: false })
            : handleConvertToGeoreferencedEvent({ isGeoreferenced: true })
        }
        content={{
          title: event.isGeographic
            ? "Convert to Non-Georeferenced Event"
            : "Convert to Georeferenced Event",
          description: (
            <div>
              <p>
                {event.isGeographic
                  ? "Do you want to convert this event to non-georeferenced event?"
                  : "Do you want to georeference this event again?"}
              </p>
              <p>
                {event.isGeographic
                  ? "⚠ Will no longer appear on the digital twin map."
                  : "This event will be shown again on the map"}
              </p>
            </div>
          ),
          disagree: "Cancel",
          agree: "Confirm",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />

      <OpenDialog
        openDialog={showCreateEventForm}
        setOpenDialog={setShowCreateEventForm}
        content={{
          title: "Create Non-Georeferenced Event",
          description: (
            <CreateEvent
              pointEventId={selectedEventId}
              typeEventsTypeEvents={selectedTypeEvents}
              closeModal={setShowCreateEventForm}
              isGeographic={false}
            />
          ),
          disagree: "Cancel",
        }}
        maxWidth="1500px"
        minWidth="500px"
      />
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <section className="main-tables-container">
          <div className="main-currency-table">
            <div className="currency-table--container">
              <table>
                {event?.objectId && (
                  <tr style={colorStyle}>
                    <td>Object Relation Id</td>
                    <td className="table__right">
                      <p
                        className="object-id"
                        onClick={() => handlerClickObjectId(event?.objectId)}
                      >
                        {event?.objectId}
                      </p>
                    </td>
                  </tr>
                )}
                {event?.pointComponentId && (
                  <tr style={colorStyle}>
                    <td>Component Relation Id</td>
                    <td className="table__right">
                      <p
                        className="object-id"
                        onClick={() =>
                          handlerClickComponentId(event?.pointComponentId)
                        }
                      >
                        {event?.pointComponentId}
                      </p>
                    </td>
                  </tr>
                )}
                {event?.PointEventRelationWith?.[0]?.id && (
                  <tr style={colorStyle}>
                    <td>Event Relation Id</td>
                    <td className="table__right">
                      <p
                        className="object-id"
                        onClick={() =>
                          handlerClickEventId(
                            event?.PointEventRelationWith?.[0]?.id
                          )
                        }
                      >
                        {event?.PointEventRelationWith?.[0]?.id}
                      </p>
                    </td>
                  </tr>
                )}

                {Object.keys(infoOperation).map((key) => {
                  return (
                    <tr style={colorStyle}>
                      <td>
                        <div>
                          {key}
                          {key === "Longitude" && updateLongitude && (
                            <>
                              <button style={{ marginLeft: "1rem" }}>
                                <DoneIcon
                                  sx={{
                                    width: "2rem",
                                    color: "var(--background-primary_color)",
                                    "&:hover": {
                                      color:
                                        "var(--background-secondary_color)",
                                    },
                                  }}
                                  onClick={() =>
                                    handleUpdateLocation({
                                      coordinate: "longitude",
                                    })
                                  }
                                />
                              </button>
                              <button
                                onClick={() =>
                                  handleCancelUpdate({
                                    coordinate: "longitude",
                                  })
                                }
                              >
                                <ClearIcon
                                  sx={{
                                    width: "2rem",
                                    color: DELETE_COLOR,
                                    "&:hover": {
                                      color: HOVER_DELETE_COLOR,
                                    },
                                  }}
                                />
                              </button>
                            </>
                          )}
                          {key === "Latitude" && updateLatitude && (
                            <>
                              <button style={{ marginLeft: "1rem" }}>
                                <DoneIcon
                                  sx={{
                                    width: "2rem",
                                    color: "var(--background-primary_color)",
                                    "&:hover": {
                                      color:
                                        "var(--background-secondary_color)",
                                    },
                                  }}
                                  onClick={() =>
                                    handleUpdateLocation({
                                      coordinate: "latitude",
                                    })
                                  }
                                />
                              </button>
                              <button
                                onClick={() =>
                                  handleCancelUpdate({ coordinate: "latitude" })
                                }
                              >
                                <ClearIcon
                                  sx={{
                                    width: "2rem",
                                    color: DELETE_COLOR,
                                    "&:hover": {
                                      color: HOVER_DELETE_COLOR,
                                    },
                                  }}
                                />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                      <td className="table__right">
                        {key === "Longitude" &&
                          (!updateLongitude ? (
                            <>
                              {updateLocation.longitude}
                              <button onClick={() => setUpdateLongitude(true)}>
                                <EditIcon
                                  sx={{
                                    width: "1.5rem",
                                    color: "var(--background-primary_color)",
                                    "&:hover": {
                                      color:
                                        "var(--background-secondary_color)",
                                    },
                                  }}
                                />
                              </button>
                            </>
                          ) : (
                            <input
                              type="number"
                              step="any"
                              value={updateLocation.longitude}
                              onChange={(e) =>
                                setUpdateLocation((prev) => ({
                                  ...prev,
                                  longitude: e.target.value,
                                }))
                              }
                            />
                          ))}
                        {key === "Latitude" &&
                          (!updateLatitude ? (
                            <>
                              {updateLocation.latitude}
                              <button onClick={() => setUpdateLatitude(true)}>
                                <EditIcon
                                  sx={{
                                    width: "1.5rem",
                                    color: "var(--background-primary_color)",
                                    "&:hover": {
                                      color:
                                        "var(--background-secondary_color)",
                                    },
                                  }}
                                />
                              </button>
                            </>
                          ) : (
                            <input
                              type="number"
                              step="any"
                              value={updateLocation.latitude}
                              onChange={(e) =>
                                setUpdateLocation((prev) => ({
                                  ...prev,
                                  latitude: e.target.value,
                                }))
                              }
                            />
                          ))}
                        {key !== "Latitude" &&
                          key !== "Longitude" &&
                          infoOperation[key]}
                      </td>
                    </tr>
                  );
                })}
                {event &&
                  reduceAttributes()?.map((item, index) => {
                    console.log("🚀 ~ reduceAttributes ~ item:", item)
                    if (item?.type === "lineTag") {
                      return (
                        <tr
                          key={`lineTag-${index}`}
                          style={{
                            background:
                              theme === "light" ? "#f9f9f9" : "#121212",
                            color:
                              theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                          }}
                        >
                          <td colSpan={2}>
                            <h3
                              style={{
                                color:
                                  theme === "light" ? BLACK_COLOR : WHITE_COLOR,
                                padding: "0.5rem",
                                margin: "0.5rem 0",
                                marginTop: "1.5rem",
                                fontWeight: "bold",
                              }}
                            >
                              {item.alias || item.name}
                            </h3>
                            <LineTag />
                          </td>
                        </tr>
                      );
                    }
                    if (item?.name === "severity") {
                      return (
                        <tr style={colorStyle}>
                          <td className="table__left">
                            {item?.alias || item?.name}
                          </td>
                          <td
                            className="table__right"
                            style={{
                              background: (() => {
                                return item?.value === "1"
                                  ? "#FF0000"
                                  : item?.value === "2"
                                  ? "#FFFF00"
                                  : item?.value === "3"
                                  ? "#92D050"
                                  : item?.value === "4"
                                  ? "#B4C6E7"
                                  : "#FFFFFF";
                              })(),
                            }}
                          >
                            {item?.value}
                          </td>
                        </tr>
                      );
                    }
                    return (
                      !(item?.value === "" || item?.value === " ") && (
                        <tr style={colorStyle}>
                          <td className="table__left">
                            {item?.alias || item?.name}
                            {(item?.value?.type === "operation" ||
                              item?.operation) && (
                              <OperationInfo content={item?.value} />
                            )}
                          </td>
                          <td className="table__right">
                            {isUrl(item?.value) ? (
                              <a
                                className="link-style"
                                onClick={(e) => handleClickLink(e, item?.value)}
                              >
                                {nameUrl(item?.value)}
                              </a>
                            ) : typeof item?.value !== "object" ? (
                              item?.value
                            ) : (
                              formatFloat(item?.value?.value, 6)
                            )}
                          </td>
                        </tr>
                      )
                    );
                  })}
              </table>
              <Wrapperbtn>
                {event && <ConvertButton pointEvent={event} />}
                <CustomButton
                  text={<EditIcon />}
                  onClick={() => setOpenDialog(true)}
                  margin={"10px 0"}
                  primaryColor={"#ff600a"}
                  secondaryColor={"#f0712c"}
                  showTooltip={shouldShowTooltip}
                  tooltipTitle="Edit"
                />
                <CustomButton
                  text={<DynamicFormIcon />}
                  onClick={() => {
                    handleOpenCreateEventForm(
                      event?.id,
                      typeEventsTypeEvents[0]?.PointTypeEventRelationWith
                    );
                  }}
                  margin={"10px 0"}
                  showTooltip={shouldShowTooltip}
                  tooltipTitle="Add Non-Georeferenced Event"
                />
                {event.locationOperation.latitude &&
                  event.locationOperation.longitude && (
                    <CustomButton
                      text={
                        event.isGeographic ? (
                          <LocationOffIcon />
                        ) : (
                          <LocationOnIcon />
                        )
                      }
                      onClick={() => setOpenDialogNonGeoreferencedEvent(true)}
                      margin={"10px 0"}
                      showTooltip={shouldShowTooltip}
                      tooltipTitle={
                        event.isGeographic
                          ? "Convert to non-georeferenced event"
                          : "Convert to georeferenced event"
                      }
                    />
                  )}
                {deleteButton && <DeleteEventButton pointEventId={event.id} />}
              </Wrapperbtn>
            </div>
          </div>
        </section>
      </Grid>
    </InfoTitle>
  );
}
