import { useEffect, useState } from "react";
import { config } from "config.js";

import useSwr from "swr";

const useGetLineTypes = (id) => {
  let url = `${config.URL_BACKEND_PG}api/v1/type-lines`;
  if (!isNaN(parseInt(id))) {
    url = `${url}/library/${parseInt(id)}`;
  }
  const { data, error } = useSwr(url);
  const lines = data && !error ? data : [];
  return { data: lines, error };
};

const useGetLineLibraries = ({ id }) => {
  const [data, setData] = useState([]); 
  const [error, setError] = useState(null); 

  useEffect(() => {
    const fetchData = async () => {
      let url = `${config.URL_BACKEND_PG}api/v1/line-libraries`;
      if (id && !isNaN(parseInt(id))) {
        url = `${url}?id=${parseInt(id)}`;
      }
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
        });
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error("Error getting line libraries 🚩", error);
        setError(error);
      }
    };

    fetchData();

    return () => {
      setData([]);
    };
  }, [id]); 

  return { data, error, setData };
};

export { useGetLineTypes, useGetLineLibraries };
