import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { FiLogIn } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { BoxNav } from "../DigitalTwin/UserRoutesElements";
import {
  SideBarItems,
  VersionContent,
  iconStyle,
  iconStyle2,
} from "./navDrawerData";
import { useContext, useEffect, useState } from "react";
import { BLACK_COLOR, BORDER_GREY, WHITE_COLOR } from "utils/const";

import { PRIMARY_COLOR } from "utils/globalColorsTheme";

import { styled } from "@mui/material";
import useUserRole from "hooks/useUserRole";
import { ThemeContext } from "App";
import { useTheme } from "pages/PlatformSetUp/Preferences/ThemeContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const drawerWidth = 390;

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "2rem",
  "&:hover": {
    backgroundColor: "var(--background-trans_color)",
  },
}));

const Item = (props) => {
  const { theme } = useContext(ThemeContext);

  const {
    text,
    icon,
    styleListIcon,
    url,
    click,
    isActive,
    hasSubcategories,
    isSubcategoryOpen,
  } = props;
  const navigate = useNavigate();

  const navigation = () => {
    if (click) {
      click();
      return;
    }

    if (url && url.includes("https")) {
      window.open(url);
      return;
    }

    url && navigate(url);
  };

  return (
    <ListItem key={text} disablePadding>
      <StyledListItemButton onClick={(event) => navigation()}>
        {isActive && ( // If the item is active, it will show a background colo}
          <div
            style={{
              position: "absolute",
              width: "0.5rem",
              height: "100%",
              backgroundColor: "var(--background-trans_color)",
            }}
          />
        )}
        <ListItemIcon sx={styleListIcon}>
          <>
            {icon}

            <ReactTooltip
              id={text}
              place="right"
              effect="solid"
              offset="{'left': -15}"
              sx={{ fontSize: "10px" }}
            >
              {text}
            </ReactTooltip>
          </>
        </ListItemIcon>

        <ListItemText
          primary={text}
          sx={{
            color: theme === "light" ? PRIMARY_COLOR : WHITE_COLOR,
            "& .css-1710b0l-MuiTypography-root": {
              fontFamily: document.body.style.fontFamily,
            },
          }}
        />
        <div style={{ color: "var(--background-primary_color)" }}>
          {hasSubcategories &&
            (isSubcategoryOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            ))}
        </div>
      </StyledListItemButton>
    </ListItem>
  );
};

export default function PersistentDrawerLeft() {
  const location = useLocation();
  const { colorName } = useTheme();
  const { theme } = useContext(ThemeContext);
  const styleListIcon = {
    justifyContent: "center",
  };
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const [sidebarItems, setSidebarItems] = useState({});

  const { userRole } = useUserRole();
  const [openSubcategories, setOpenSubcategories] = useState({});

  useEffect(() => {
    if (userRole) {
      const items = SideBarItems[userRole] || {};
      setSidebarItems(items);
    }
  }, [userRole]);

  const [open, setOpen] = useState(false);

  // Abrir subcategorías según la URL actual
  useEffect(() => {
    const currentPath = location.pathname;

    const initialOpenSubcategories = Object.keys(sidebarItems).reduce(
      (acc, category) => {
        const { subcategories } = sidebarItems[category] || {};
        if (subcategories) {
          const isActiveSubcategory = Object.values(subcategories).some(
            (sub) => sub.url === currentPath
          );
          acc[category] = isActiveSubcategory;
        }
        return acc;
      },
      {}
    );

    setOpenSubcategories(initialOpenSubcategories);
  }, [location.pathname, sidebarItems]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleToggleSubcategories = (category) => {
    setOpenSubcategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  useEffect(() => {
    document.title = colorName;
  }, [colorName]);

  return (
    <BoxNav>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="end"
        sx={{ mr: 1, ...(open && {}) }}
      >
        <MenuIcon sx={{ fontSize: "2.5rem" }} />
      </IconButton>
      <div>
        <Drawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          position="fixed"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <VersionContent
            className={
              theme === "light" ? "whiteColo1" : "background-default-color"
            }
          >
            <h1
              style={{
                color: theme === "light" ? BLACK_COLOR : WHITE_COLOR,
              }}
            >
              Decimetrix<sup>®</sup>
            </h1>
            <h2
              style={{
                color: "var(--background-secondary_color)",
              }}
            >
              {colorName}
            </h2>
            {/* <p>Version xx</p> */}
          </VersionContent>
          <Divider style={{ backgroundColor: BORDER_GREY }} />
          <List
            style={{
              height: "100%",
            }}
            className={
              theme === "light" ? "whiteColo1" : "background-default-color"
            }
          >
            {/* Sign In only when no exis role */}
            {role === null && (
              <Item
                text={"Log In"}
                icon={
                  <FiLogIn
                    style={theme === "light" ? iconStyle : iconStyle2}
                    data-tip
                  />
                }
                styleListIcon={styleListIcon}
                url={"/signIn"}
              />
            )}

            <Item
              text={"Home"}
              icon={
                <HomeOutlinedIcon
                  style={theme === "light" ? iconStyle : iconStyle2}
                  data-tip
                />
              }
              styleListIcon={styleListIcon}
              url={role ? "/hub" : "/"}
            />
            {/* Render items of sidebar depending on your role */}
            {Object.keys(sidebarItems).map((item, index) => {
              const { icon, url, subcategories } = sidebarItems[item];
              const isOpen = openSubcategories[item];
              const isActive = location.pathname === url;
              const hasSubcategories = !!subcategories;
              return (
                <div key={index}>
                  {/* Categoría Principal */}
                  <Item
                    text={item}
                    icon={icon}
                    styleListIcon={styleListIcon}
                    url={url}
                    click={() => {
                      if (subcategories) {
                        handleToggleSubcategories(item);
                      } else if (url) {
                        if (url.startsWith("http")) {
                          window.open(url, "_blank"); // Abre enlaces externos
                        } else {
                          navigate(url); // Maneja rutas internas
                        }
                      }
                    }}
                    isActive={isActive}
                    hasSubcategories={hasSubcategories}
                    isSubcategoryOpen={isOpen}
                  />

                  {/* Subcategorías */}
                  {isOpen && subcategories && (
                    <div style={{ marginLeft: "1rem" }}>
                      {Object.keys(subcategories).map((key, subIndex) => {
                        const isSubActive =
                          location.pathname === subcategories[key].url;
                        return (
                          <Item
                            text={key}
                            styleListIcon={styleListIcon}
                            url={subcategories[key].url}
                            key={subIndex}
                            isActive={isSubActive}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </List>
        </Drawer>
      </div>
    </BoxNav>
  );
}
