import useSwr from "swr";
import { urls } from "utils/urlKeys";

const FetchTypeElements = (libraryId) => {
  const token = localStorage.getItem("token");

  const { data, error } = useSwr(
    urls.objects.typeObjectsParams(libraryId ? `libraryId=${libraryId}` : ""),
    async (url) => {
      const response = await fetch(url, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    }
  );

  return { data, error };
};

export default FetchTypeElements;
