import { useSWRConfig } from "swr";
import { useEffect, useState } from "react";
import { updateChart } from "services/Dashboard/timeSeriesBarChart";
import { getGradient } from "helpers/getGradient";
import { PRIMARY_COLOR } from "utils/const";
import { urlsApiMg } from "utils/urlKeys";

export const useSetCurrentStyle = ({ dataChart, setChartEditDialog }) => {
  const { mutate } = useSWRConfig();

  const [currentStyleLineDataChart, setCurrentStyleLineDataChart] = useState({
    data: {
      labels: [],
      datasets: dataChart.datasets,
    },
    currentHorizontalBar: false,
    currentNameLineChart: "",
    currentTitleColor: "",
    currentTotalFontColor: "#EEEEEE",
    currentTotalBackground: "#EEEEEE",
    currentAxisXColor: "#EEEEEE",
    currentAxisYColor: "#EEEEEE",
    currentLabelsXColor: "",
    currentLabelsYColor: "",
    currentTitleXColor: "#EEEEEE",
    currentTitleYColor: "#EEEEEE",
    currentTotalPosition: "top",
    currentTotalFontSize: 15,
    currentTitleFontSize: 25,
    currentTitleTooltipFontSize: 15,
    currentBodyTooltipFontSize: 13,
    currentLabelsXFontSize: 15,
    currentLabelsYFontSize: 15,
    currentTitleXFontSize: 15,
    currentTitleYFontSize: 15,
    currentTitleAxisX: "",
    currentDisplayAxisX: false,
    currentTitleAxisY: "",
    currentDisplayAxisY: false,
    currentDisplayTotal: false,
    currentDisplayBackgroundTotal: false,
    currentDisplayTitleX: false,
    currentDisplayTitleY: false,
    currentStatusBeginAtZero: false,
    currentMinValueAxis: 0,
    currentMaxValueAxis: null,
    currentStepSizeY: 5,
    currentDisplayShowCerosTotal: true,
    currentPaddingAxisY: 5,
    currentPaddingAxisX: 5,
  });

  const [constantLine, setConstantLine] = useState({
    create: false,
    label: "",
    borderColor: PRIMARY_COLOR,
    borderColorHexadecimal: PRIMARY_COLOR,
    constantValue: 0,
    currentTotalNumDecimals: null,
  });

  const [allStacks, setAllStacks] = useState({});
  const [currentStackName, setCurrentStackName] = useState("");

  function updateAllStacks(
    currentState,
    currentDataChart,
    newStackName = null,
    newItemStack = null,
    removeItemStack = null
  ) {
    const updatedState = { ...currentState };

    for (const stack in updatedState) {
      updatedState[stack] = updatedState[stack].filter(
        (alias) => alias !== currentDataChart.alias && alias !== newItemStack
      );
    }
    updatedState[currentDataChart.stack] = updatedState[currentDataChart.stack]
      ? [...updatedState[currentDataChart.stack], currentDataChart.alias]
      : [currentDataChart.alias];

    if (removeItemStack) {
      for (const stack in updatedState) {
        updatedState[stack] = updatedState[stack].filter(
          (alias) =>
            alias !== currentDataChart.alias && alias !== removeItemStack
        );
      }
    }
    if (newStackName && newItemStack) {
      for (const stack in updatedState) {
        updatedState[stack] = updatedState[stack].filter(
          (alias) =>
            alias !== currentDataChart.alias && alias !== removeItemStack
        );
      }
      updatedState[newStackName] = updatedState[newStackName]
        ? [...updatedState[newStackName], newItemStack]
        : [newItemStack];
    }
    for (const stack in updatedState) {
      if (updatedState[stack].length === 0) {
        delete updatedState[stack];
      }
    }
    return updatedState;
  }

  useEffect(() => {
    setCurrentStyleLineDataChart((current) => {
      return {
        ...current,
        data: {
          labels: dataChart.labels,
          datasets: dataChart.datasets.map((elm) => {
            let updatedElm = { ...elm.barStyle, data: elm.data };

            if (elm.barStyle.type === "line") {
              return {
                ...elm.barStyle.lineStyle,
                data: elm.data,
                _id: elm._id,
              };
            }

            if (!updatedElm.hasOwnProperty("colorsByLabel")) {
              updatedElm = {
                ...updatedElm,
                data: elm.data,
                colorsByLabel: dataChart.labels.map(
                  (label) => (label = elm.barStyle.backgroundColor)
                ),
                colorsHexadecimalByLabel: dataChart.labels.map(
                  (label) => (label = elm.barStyle.backgroundColorHexadecimal)
                ),
              };
            }

            if (
              elm.barStyle.stack === null ||
              elm.barStyle.stack === "" ||
              elm.barStyle.stack === elm.barStyle._id
            ) {
              const { stack, ...restBarStyle } = updatedElm;
              updatedElm = restBarStyle;
            } else {
              setAllStacks((current) => updateAllStacks(current, elm.barStyle));
            }

            if (elm.barStyle.dataGradient.display) {
              updatedElm = {
                ...updatedElm,
                backgroundColor: function (context) {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;
                  if (!chartArea) {
                    return;
                  }
                  return getGradient({
                    ctx,
                    chartArea,
                    color: elm.barStyle.backgroundColorHexadecimal,
                    colorStop: elm.barStyle.dataGradient.colorStop,
                  });
                },
                colorsByLabelGradient: function (context) {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;
                  if (!chartArea) {
                    return;
                  }

                  return updatedElm.colorsHexadecimalByLabel.map((item) => {
                    return getGradient({
                      ctx,
                      chartArea,
                      color: item,
                      colorStop: elm.barStyle.dataGradient.colorStop,
                    });
                  });
                },
              };
            }

            return updatedElm;
          }),
        },
        currentHorizontalBar: dataChart.chartStyle.horizontal,
        currentNameLineChart: dataChart.alias,
        currentTitleColor: dataChart.chartStyle.colorTitle,
        currentTotalFontColor: dataChart.chartStyle.datalabels.colorTotal,
        currentTotalBackground:
          dataChart.chartStyle.datalabels.backgroundColorTotal,
        currentAxisXColor: dataChart.chartStyle.scales.x.axisColor,
        currentAxisYColor: dataChart.chartStyle.scales.y.axisColor,
        currentLabelsXColor: dataChart.chartStyle.scales.x.labelsColor,
        currentLabelsYColor: dataChart.chartStyle.scales.y.labelsColor,
        currentTitleXColor: dataChart.chartStyle.scales.x.titleAxis.colorTitle,
        currentTitleYColor: dataChart.chartStyle.scales.y.titleAxis.colorTitle,
        currentTotalPosition: dataChart.chartStyle.datalabels.positionTotal,
        currentTotalFontSize: dataChart.chartStyle.datalabels.fontSizeTotal,
        currentTitleFontSize: dataChart.chartStyle.fontSizeTitle,
        currentTitleTooltipFontSize: dataChart.chartStyle.tooltip.fontSizeTitle,
        currentBodyTooltipFontSize: dataChart.chartStyle.tooltip.fontSizeBody,
        currentLabelsXFontSize: dataChart.chartStyle.scales.x.labelsFontSize,
        currentLabelsYFontSize: dataChart.chartStyle.scales.y.labelsFontSize,
        currentTitleXFontSize:
          dataChart.chartStyle.scales.x.titleAxis.fontSizeTitle,
        currentTitleYFontSize:
          dataChart.chartStyle.scales.y.titleAxis.fontSizeTitle,
        currentTitleAxisX:
          dataChart.chartStyle.scales.x.titleAxis.text ||
          dataChart.columnX.name,
        currentDisplayAxisX: dataChart.chartStyle.scales.x.displayAxis,
        currentTitleAxisY:
          dataChart.chartStyle.scales.y.titleAxis.text ||
          (dataChart.type === "count" ? "Count" : dataChart.columnsY[0]),
        currentDisplayAxisY: dataChart.chartStyle.scales.y.displayAxis,
        currentDisplayTotal: dataChart.chartStyle.datalabels.displayTotal,
        currentDisplayBackgroundTotal:
          dataChart.chartStyle.datalabels.displayBackgroundTotal,
        currentDisplayTitleX:
          dataChart.chartStyle.scales.x.titleAxis.displayTitle,
        currentDisplayTitleY:
          dataChart.chartStyle.scales.y.titleAxis.displayTitle,
        currentStatusBeginAtZero: dataChart.chartStyle.scales.y.beginAtZero,
        currentMinValueAxis: dataChart.chartStyle.scales.y.minValueAxis,
        currentMaxValueAxis: dataChart.chartStyle.scales.y.maxValueAxis,
        currentStepSizeY: dataChart.chartStyle.scales.y.stepSizeY,
        currentDisplayShowCerosTotal: dataChart.chartStyle.datalabels.showCeros,
        currentPaddingAxisY: dataChart.chartStyle.scales.y.paddingAxis,
        currentPaddingAxisX: dataChart.chartStyle.scales.x.paddingAxis,
        currentTotalNumDecimals: dataChart.chartStyle.datalabels.numDecimals,
      };
    });
  }, [dataChart]);

  const handleUpdateChart = async () => {
    const { data } = await updateChart({
      id: dataChart._id,
      body: {
        ...dataChart,
        from: dataChart.fromType ? dataChart.fromType : dataChart.from,
        alias: currentStyleLineDataChart.currentNameLineChart,
        datasets: currentStyleLineDataChart.data.datasets.map((elm) => {
          if (elm.type === "line") {
            return {
              columnsValuesFilter: [],
              barStyle: {
                label: elm.label,
                alias: elm.alias,
                constantValue: elm.constantValue,
                type: "line",
                lineStyle: { ...(({ _id, ...rest }) => rest)(elm) },
              },
            };
          } else {
            return {
              columnsValuesFilter: [],
              barStyle: elm,
            };
          }
        }),
        chartStyle: {
          ...dataChart.chartStyle,
          colorTitle: currentStyleLineDataChart.currentTitleColor,
          fontSizeTitle: currentStyleLineDataChart.currentTitleFontSize,
          horizontal: currentStyleLineDataChart.currentHorizontalBar,
          datalabels: {
            ...dataChart.chartStyle.datalabels,
            displayTotal: currentStyleLineDataChart.currentDisplayTotal,
            positionTotal: currentStyleLineDataChart.currentTotalPosition,
            colorTotal: currentStyleLineDataChart.currentTotalFontColor,
            displayBackgroundTotal:
              currentStyleLineDataChart.currentDisplayBackgroundTotal,
            backgroundColorTotal:
              currentStyleLineDataChart.currentTotalBackground,
            fontSizeTotal: currentStyleLineDataChart.currentTotalFontSize,
            showCeros: currentStyleLineDataChart.currentDisplayShowCerosTotal,
            numDecimals: currentStyleLineDataChart.currentTotalNumDecimals,
          },
          scales: {
            x: {
              ...dataChart.chartStyle.scales.x,
              titleAxis: {
                displayTitle: currentStyleLineDataChart.currentDisplayTitleX,
                text: currentStyleLineDataChart.currentTitleAxisX,
                colorTitle: currentStyleLineDataChart.currentTitleXColor,
                fontSizeTitle: currentStyleLineDataChart.currentTitleXFontSize,
              },
              displayAxis: currentStyleLineDataChart.currentDisplayAxisX,
              axisColor: currentStyleLineDataChart.currentAxisXColor,
              labelsFontSize: currentStyleLineDataChart.currentLabelsXFontSize,
              labelsColor: currentStyleLineDataChart.currentLabelsXColor,
              paddingAxis: currentStyleLineDataChart.currentPaddingAxisX,
            },
            y: {
              ...dataChart.chartStyle.scales.y,
              titleAxis: {
                displayTitle: currentStyleLineDataChart.currentDisplayTitleY,
                text: currentStyleLineDataChart.currentTitleAxisY,
                colorTitle: currentStyleLineDataChart.currentTitleYColor,
                fontSizeTitle: currentStyleLineDataChart.currentTitleYFontSize,
              },
              displayAxis: currentStyleLineDataChart.currentDisplayAxisY,
              axisColor: currentStyleLineDataChart.currentAxisYColor,
              labelsFontSize: currentStyleLineDataChart.currentLabelsYFontSize,
              labelsColor: currentStyleLineDataChart.currentLabelsYColor,
              beginAtZero: currentStyleLineDataChart.currentStatusBeginAtZero,
              minValueAxis: currentStyleLineDataChart.currentMinValueAxis,
              maxValueAxis: currentStyleLineDataChart.currentMaxValueAxis,
              stepSizeY: currentStyleLineDataChart.currentStepSizeY,
              paddingAxis: currentStyleLineDataChart.currentPaddingAxisY,
            },
          },
          tooltip: {
            ...dataChart.chartStyle.tooltip,
            fontSizeTitle:
              currentStyleLineDataChart.currentTitleTooltipFontSize,
            fontSizeBody: currentStyleLineDataChart.currentBodyTooltipFontSize,
          },
        },
      },
    });
    if (data) {
      mutate(
        urlsApiMg.dashboard.timeSeriesBarChart(
          dataChart.fromType ? dataChart.fromType : dataChart.from
        )
      );
      setChartEditDialog(false);
    }
  };

  const handleCreateConstantLine = () => {
    const newGoalLine = {
      _id: constantLine.label,
      label: constantLine.label,
      alias: constantLine.label,
      type: "line",
      constantValue: constantLine.constantValue,
      data: new Array(
        currentStyleLineDataChart.data.datasets[0].data.length
      ).fill(constantLine.constantValue),
      borderColor: constantLine.borderColorHexadecimal,
      borderWidth: 2,
      borderDash: [5, 5],
      borderDashOffset: 1,
      borderCapStyle: "butt",
      borderJoinStyle: "miter",
      backgroundColorHexadecimal: constantLine.borderColorHexadecimal,
      backgroundColor: constantLine.borderColor,
      fill: false,
      pointBackgroundColor: constantLine.borderColorHexadecimal,
      pointBorderColor: "#0E4D45",
      pointBorderWidth: 0,
      pointRadius: 0,
      pointStyle: "circle",
      pointHitRadius: 0,
      pointHoverBackgroundColor: constantLine.borderColorHexadecimal,
      pointHoverBorderColor: constantLine.borderColorHexadecimal,
      pointHoverBorderWidth: 0,
      pointHoverRadius: 0,
      cubicInterpolationMode: "default",
      tension: 0,
      stepped: false,
      spanGaps: false,
      showLine: true,
      hidden: false,
      datalabels: {
        displayTotal: false,
        positionTotal: "end",
        colorTotal: "#EEEEEE",
        displayBackgroundTotal: false,
        backgroundColorTotal: "rgba(255, 255, 255, 0)",
        fontSizeTotal: 10,
        offsetTotal: 3,
      },
      clip: true,
    };
    setCurrentStyleLineDataChart((current) => ({
      ...current,
      data: {
        ...current.data,
        datasets: [...current.data.datasets, newGoalLine],
      },
    }));
    setConstantLine({
      create: false,
      label: "",
      borderColor: PRIMARY_COLOR,
      borderColorHexadecimal: PRIMARY_COLOR,
      constantValue: 0,
    });
  };

  const handleDeleteConstantLine = ({ id }) => {
    setCurrentStyleLineDataChart((current) => ({
      ...current,
      data: {
        ...current.data,
        datasets: current.data.datasets.filter((elm) => elm._id !== id),
      },
    }));
  };

  return {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
    handleCreateConstantLine,
    handleDeleteConstantLine,
    constantLine,
    setConstantLine,
    allStacks,
    setAllStacks,
    currentStackName,
    setCurrentStackName,
    updateAllStacks,
  };
};
