// Required modules;
import GenericTable from "components/Tables/GenericTable/GenericTable.jsx";
import Form from "components/Forms/Form.jsx";
import Fields from "../Fields.jsx";
import MessagesDialog from "components/Dialogs/MessagesDialog.jsx";
// Config admin class
import ConfigAdmin from "../../ConfigAdmin.js";
// Hooks
import { useCallback, useEffect, useState } from "react";
import useForm from "hooks/useForm.jsx";
// Necessary data
import {
  headersTableTypeElementsGhg,
  keyValuesTableTypeElementsGhg,
  initialValuesFormTypeElementGhg,
} from "../../dataConfigAdmin.jsx";

// Styles
import {
  ColumnAdminConfig,
  RowAdminConfig,
  CreateNewRegionButton,
  InformationShare,
  IconAdminConfigChangeColum,
  SelectContainerAdminConfig,
  SelectChooseLibrayAdminConfig,
  TableAdminConfig,
} from "../../configAdminStyles.jsx";
import { IoMdAdd } from "react-icons/io";
import { ContainerForm } from "components/Forms/FormStyles";
import useGet from "hooks/useFetch.js";
import { IoIosArrowDropupCircle } from "react-icons/io";
// Services
import findTypeObjectsTypeObjects from "services/typeObjectsTypeObjects/find.js";
import createTypeObjectsTypeObjects from "services/typeObjectsTypeObjects/create.js";
// Components
import UpdateTypeObjectsTypeObjects from "./UpdateObjectsObjects.jsx";
import CheckListsObjectsElements from "./CheckListObjectElements.jsx";
import { FROM_TABLE } from "utils/constStrings.jsx";

// Button to render before of title in form
const ButtonSeeInitalView = ({ click }) => {
  const some = () => {
    click();
  };

  return (
    <IconAdminConfigChangeColum onClick={some}>
      <IoIosArrowDropupCircle className="icon" />
    </IconAdminConfigChangeColum>
  );
};

const findLibrary = (libraries, e) => {
  return libraries.find((library) => library.id === parseInt(e.target.value));
};

export default function ObjectElements({ setOpenDialog, setContentDialog }) {
  // Config admin object
  const [configAdmin, setConfigAdmin] = useState(null);
  const [seeForm, setSeeForm] = useState(false);
  const [libraries] = useGet("api/v1/point-libraries");
  const [library, setLibrary] = useState({});

  const [typeObjectsTypeObjects, setTypeObjectsTypeObjects] = useState({});
  const [pointTypeObjectId, setpointTypeObjectId] = useState(null);

  // Everything related to the form
  let [form, methodsForm] = useForm(initialValuesFormTypeElementGhg);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([]);

  // Necessary functions in the config admin of the form
  if (configAdmin) {
    configAdmin.setChangeFormForSpecificValues(changeFormForSpecificValues);
    configAdmin.setOnChangeFields(handleChangeForm);
  }

  // Rows of table
  const [typeElementsGhgRow, setTypeElementsGhgRow] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  //Alerts
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
  });

  // Click  on type element ghg into table
  const handleClickTypeElementGhg = (id) => {
    if (id !== pointTypeObjectId) setTypeObjectsTypeObjects({});
    setpointTypeObjectId(id);
    configAdmin.handlerClickIntoTable(id);
  };

  // Change in library select
  const handleLibrary = (e) => {
    setLibrary((currentLibrary) => ({
      ...currentLibrary,
      id: e.target.value,
      name: findLibrary(libraries, e)?.name,
      typeLibraryId: findLibrary(libraries, e)?.typeLibraryId,
    }));
  };

  const create = useCallback(
    async (typeObjectTypeObject) => {
      if (typeObjectsTypeObjects) {
        await Promise.all(
          Object.keys(typeObjectsTypeObjects).map(async (typeObjectId) => {
            await createTypeObjectsTypeObjects({
              pointTypeObjectId: typeObjectTypeObject.id,
              pointTypeObjectRelationId: parseInt(typeObjectId),
            });
          })
        );
      }
      setTypeObjectsTypeObjects({});
      setpointTypeObjectId(null);
    },
    [typeObjectsTypeObjects]
  );

  const update = useCallback(
    async (typeObjectTypeObject) => {
      const updateObjectsObjects = UpdateTypeObjectsTypeObjects({
        typeObjectTypeObject,
        typeObjectsTypeObjects,
        setTypeObjectsTypeObjects,
      });
      await updateObjectsObjects();
      setTypeObjectsTypeObjects({});
      setpointTypeObjectId(null);
    },
    [typeObjectsTypeObjects, setTypeObjectsTypeObjects]
  );

  const handleChangeCheck = (id) => {
    const element = typeObjectsTypeObjects?.[id];
    if (element) {
      setTypeObjectsTypeObjects((current) => {
        const { [id]: value, ...rest } = current;
        return rest;
      });
    } else {
      setTypeObjectsTypeObjects((current) => ({
        ...current,
        [id]: true,
      }));
    }
  };

  useEffect(() => {
    if (!pointTypeObjectId) return;
    const getData = async () => {
      const data = await findTypeObjectsTypeObjects({
        pointTypeObjectId,
      });
      data.forEach(({ pointTypeObjectRelationId }) => {
        setTypeObjectsTypeObjects((current) => ({
          ...current,
          [pointTypeObjectRelationId]: true,
        }));
      });
    };
    getData();
  }, [pointTypeObjectId]);

  // Create object to config admin and set it
  useEffect(() => {
    const configAdmin = new ConfigAdmin();
    configAdmin.setHeadersTable(headersTableTypeElementsGhg);
    configAdmin.setKeyValuesTable(keyValuesTableTypeElementsGhg);
    configAdmin.setSetRows(setTypeElementsGhgRow);
    configAdmin.setSetButtons(setButtonsForm);
    configAdmin.setSetLoadingCreate(setLoadingCreate);
    configAdmin.setSetLoadingDelete(setLoadingDelete);
    configAdmin.setSetSeeForm(setSeeForm);
    configAdmin?.setEndpoint(`api/v1/type-elements`);
    configAdmin?.setDialog(setOpenDialog, setContentDialog);
    setConfigAdmin(configAdmin);
    setButtonsForm([configAdmin.buttonCreate]);
  }, [setOpenDialog, setContentDialog]);

  // Set callback update
  useEffect(() => {
    configAdmin && configAdmin?.setUpdateCallback(update);
  }, [configAdmin, update]);

  // Set callback create
  useEffect(() => {
    configAdmin && configAdmin?.setCreateCallback(create);
  }, [configAdmin, create]);

  // Set library default
  useEffect(() => {
    if (libraries) {
      setLibrary(() => libraries[0]);
    }
  }, [libraries]);

  // Set library in config admin
  useEffect(() => {
    const setEndpoint = () => {
      configAdmin?.setEndpointFetch(
        `api/v1/type-elements?libraryId=${parseInt(library?.id)}`
      );
      configAdmin?.setLibraryId(library?.id);
      configAdmin?.fetchData();
    };
    if (library?.id) setEndpoint();
  }, [configAdmin, library?.id]);

  // Field forms of config admin
  useEffect(() => {
    configAdmin && configAdmin.setFieldForm(form);
  }, [form, configAdmin]);

  // Clear fields form
  useEffect(() => {
    configAdmin && configAdmin.setClearFields(clearField);
  }, [clearField, configAdmin]);

  //alerts
  useEffect(() => {
    const alert = configAdmin?.getAlertStatus();
    if (alert?.open) {
      setOpenAlert({
        open: alert.open,
        severity: alert.severity,
      });
    }
  }, [configAdmin?.getAlertStatus]);

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "5px",
        }}
      >
        <SelectChooseLibrayAdminConfig className="color1">
          Select Library:
          <select name="endPoint" id="endPoint" onChange={handleLibrary}>
            {libraries?.map((library) => (
              <option
                key={library.id}
                value={library.id}
                disabled={library.available ? false : true}
              >
                {library.name}
              </option>
            ))}
          </select>
        </SelectChooseLibrayAdminConfig>
      </div>
      <RowAdminConfig>
        <TableAdminConfig>
          {configAdmin && (
            <GenericTable
              headers={configAdmin.getHeadersTable()}
              rows={typeElementsGhgRow}
              keyValues={configAdmin.getKeyValuesTable()}
              handlerClick={handleClickTypeElementGhg}
              style={{
                width: 100,
              }}
              state={true}
              configBar={true}
              from={FROM_TABLE.config}
            />
          )}
        </TableAdminConfig>
        <ColumnAdminConfig>
          <ContainerForm
            see={seeForm}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Form
              form={form}
              handleChange={handleChangeForm}
              handleRows={handleClickTypeElementGhg}
              buttons={buttonsForm}
              loadingCreate={loadingCreate}
              loadingDelete={loadingDelete}
              renderBeforeTitle={[
                <ButtonSeeInitalView click={() => configAdmin.toggleForm()} />,
              ]}
              title={"Create a new Digital Twin Point"}
              renderBeforeButtons={[
                library?.typeLibraryId === 1 && ( // Parametrized object
                  <Fields form={form} handleChange={handleChangeForm} />
                ),
                <CheckListsObjectsElements
                  typeObjectsTypeObjects={typeObjectsTypeObjects}
                  handleChangeCheck={handleChangeCheck}
                />,
              ]}
            />
          </ContainerForm>

          {!seeForm && (
            <>
              <CreateNewRegionButton className="background-row-2 border-color-1 color1">
                <p>Create a new Digital Twin Point</p>
                <button
                  onClick={() => {
                    configAdmin.toggleForm();
                    setTypeObjectsTypeObjects({});
                  }}
                >
                  <span>
                    <IoMdAdd />
                  </span>
                  New Type Element {library?.name}
                </button>
              </CreateNewRegionButton>

              <InformationShare className="background-row-2 border-color-1">
                <div className="body color1">
                  To Edit Click on Existing Digital Twin Point ID
                </div>
              </InformationShare>
            </>
          )}
        </ColumnAdminConfig>
      </RowAdminConfig>
      {openAlert.open && (
        <MessagesDialog
          open={openAlert.open}
          severity={openAlert?.severity}
          message={configAdmin?.messageShow}
          handleClose={() => setOpenAlert(false)}
        />
      )}
    </>
  );
}
