import { useState, useEffect } from "react";
// Hooks
import useFindKpiChartsObjects from "pages/Dashboard/Hooks/Objects/useFindKpiCharts";
import useFindTextChartsObjects from "pages/Dashboard/Hooks/Objects/useFindTextCharts";
import useFindLineChartsObjects from "pages/Dashboard/Hooks/Objects/useFindLineCharts";
import useFindBarChartsObjects from "pages/Dashboard/Hooks/Objects/useFindBarCharts";
import useFindDoughnutChartsObjects from "pages/Dashboard/Hooks/Objects/useFindDoughnutCharts";
import { useFindTimeSeriesChartObjects } from "pages/Dashboard/Hooks/Objects/useFindTimeSeriesCharts";
import { useFindTimeSeriesBarChartObjects } from "pages/Dashboard/Hooks/Objects/useFindTimeSeriesBarCharts";
import { useFindGroupDoughnutChartsObjects } from "pages/Dashboard/Hooks/Objects/useFindGroupDoughnutCharts";

import useFindKpiChartsComponents from "pages/Dashboard/Hooks/components/useFindKpiCharts";
import useFindBarChartsComponents from "pages/Dashboard/Hooks/components/useFindBarCharts";
import useFindTextChartsComponents from "pages/Dashboard/Hooks/components/useFindTextCharts";
import useFindLineChartsComponents from "pages/Dashboard/Hooks/components/useFindLineCharts";
import useFindDoughnutChartsComponents from "pages/Dashboard/Hooks/components/useFindDoughnutCharts";
import { useFindTimeSeriesChartComponents } from "pages/Dashboard/Hooks/components/useFindTimeSeriesCharts";

import useFindKpiChartsEvents from "pages/Dashboard/Hooks/events/useFindKpiCharts";
import useFindTextChartsEvents from "pages/Dashboard/Hooks/events/useFindTextCharts";
import useFindLineChartsEvents from "pages/Dashboard/Hooks/events/useFindLineCharts";
import useFindBarChartsEvents from "pages/Dashboard/Hooks/events/useFindBarCharts";
import useFindDoughnutChartsEvents from "pages/Dashboard/Hooks/events/useFindDoughnutCharts";
import { useFindTimeSeriesChartEvents } from "pages/Dashboard/Hooks/events/useFindTimeSeriesCharts";

// Components
import DropDragGrid from "pages/Dashboard/DragAndDrop/DragAndDrollGrid";
import { useGroupBarChart } from "pages/Dashboard/Hooks/Objects/useFindGroupBarCharts";
import { useFindGroupBarChartEvent } from "pages/Dashboard/Hooks/events/useFindGroupBarCharts";
import { useFindGroupBarChartComponent } from "pages/Dashboard/Hooks/components/useFindGroupBarChart";
import { useFindTimeSeriesBarChartComponents } from "pages/Dashboard/Hooks/components/useFindTimeSeriesBarCharts";
import { useFindTimeSeriesBarChartEvents } from "pages/Dashboard/Hooks/events/useFindTimeSeriesBarCharts";
import { useFindImgChartsEvents } from "pages/Dashboard/Hooks/events/useFindImgCharts";
import { useFindImgChartsObjects } from "pages/Dashboard/Hooks/Objects/useFindImgCharts";
import { useFindImgChartsComponents } from "pages/Dashboard/Hooks/components/useFindImgCharts";
import { useFindGroupDoughnutChartsEvents } from "pages/Dashboard/Hooks/events/useFindGroupDoughnutCharts";
import { useFindGroupDoughnutChartsComponents } from "pages/Dashboard/Hooks/components/useFindGroupDoughnutCharts";

const AllCharts = ({
  openDialog,
  components = [],
  events = [],
  objects = [],
  loading,
  privateViews,
  publicViews,
  currentOrderName,
  setCurrentOrderViews,
  currentOrderViews,
  currentTemplate,
  setCurrentTemplate,
  currentPositionId,
  openDialogRestoreChart,
  setOpenDialogRestoreChart,
  orderId,
}) => {
  const [dataChartObjects, setDataChartObjects] = useState([]);
  console.log("🔴🔴🔴🔴🔴", dataChartObjects);

  const [dataChartComponents, setDataChartComponents] = useState([]);
  const [dataChartEvents, setDataChartEvents] = useState([]);
  const [allDataChart, setAllDataChart] = useState([]);
  console.log("🚀 ~ allDataChart:", allDataChart)
  const [allKpiCharts, setAllKpiCharts] = useState([]);
  const [allDataKpiCharts, setAllDataKpiCharts] = useState([]);

  const { kpiCharts: kpiChartsComponents } = useFindKpiChartsComponents({
    openDialog,
    components,
    loading,
  });
  const { barCharts: barChartsComponents } = useFindBarChartsComponents({
    openDialog,
    components,
    loading,
  });
  const { textCharts: textChartsComponents } = useFindTextChartsComponents({
    openDialog,
    components,
    loading,
  });
  const { lineCharts: lineChartsComponents } = useFindLineChartsComponents({
    openDialog,
    components,
    loading,
  });
  const { timeSeriesCharts: timeSeriesChartsComponents } =
    useFindTimeSeriesChartComponents({
      openDialog,
      components,
      loading,
    });
  const { kpiCharts: kpiChartsEvents } = useFindKpiChartsEvents({
    openDialog,
    events,
    loading,
  });
  const { textCharts: textChartsEvents } = useFindTextChartsEvents({
    openDialog,
    events,
    loading,
  });
  const { lineCharts: lineChartsEvents } = useFindLineChartsEvents({
    openDialog,
    events,
    loading,
  });
  const { barCharts: barChartsEvents } = useFindBarChartsEvents({
    openDialog,
    events,
    loading,
  });

  const { timeSeriesCharts: timeSeriesChartsEvents } =
    useFindTimeSeriesChartEvents({
      openDialog,
      events,
      loading,
    });

  const { groupBarCharts: groupBarEvents } = useFindGroupBarChartEvent({
    openDialog,
    events,
    loading,
  });
  const { imgCharts: imgChartsEvents } = useFindImgChartsEvents({
    openDialog,
  });

  const { kpiCharts: kpiChartsObjects } = useFindKpiChartsObjects({
    openDialog,
    objects,
    loading,
  });

  const { textCharts: textChartsObjects } = useFindTextChartsObjects({
    openDialog,
  });

  const { lineCharts: lineChartsObjects } = useFindLineChartsObjects({
    openDialog,
    objects,
    loading,
  });

  const { timeSeriesCharts: timeSeriesObjects } = useFindTimeSeriesChartObjects(
    {
      openDialog,
      objects,
      loading,
    }
  );

  // Time Series Bar Charts (Objs / Cmps / Evts)
  const { timeSeriesBarCharts: timeSeriesObjectsBarCharts } =
    useFindTimeSeriesBarChartObjects({
      openDialog,
      objects,
      loading,
    });

  const { imgCharts: imgChartsObjects } = useFindImgChartsObjects({
    openDialog,
  });

  const { timeSeriesBarCharts: timeSeriesComponentsBarCharts } =
    useFindTimeSeriesBarChartComponents({
      openDialog,
      components,
      loading,
    });

  const { timeSeriesBarCharts: timeSeriesEventsBarCharts } =
    useFindTimeSeriesBarChartEvents({
      openDialog,
      events,
      loading,
    });

  const { groupBarCharts: groupBarObjects } = useGroupBarChart({
    openDialog,
    objects,
    loading,
  });

  const { barCharts: barChartsObjects } = useFindBarChartsObjects({
    openDialog,
    objects,
    loading,
  });

  const { doughnutCharts: doughnutChartsObjects } =
    useFindDoughnutChartsObjects({
      openDialog,
      objects,
      loading,
    });
  const { groupDoughnutCharts: groupDoughnutChartsObjects } =
    useFindGroupDoughnutChartsObjects({
      openDialog,
      objects,
      loading,
    });

  const { doughnutCharts: doughnutChartsEvents } = useFindDoughnutChartsEvents({
    openDialog,
    events,
    loading,
  });

  const { groupDoughnutCharts: groupDoughnutChartsEvents } =
    useFindGroupDoughnutChartsEvents({
      openDialog,
      events,
      loading,
    });

  const { doughnutCharts: doughnutChartsComponents } =
    useFindDoughnutChartsComponents({
      openDialog,
      components,
      loading,
    });

  const { groupDoughnutCharts: groupDoughnutChartsComponents } =
    useFindGroupDoughnutChartsComponents({
      openDialog,
      components,
      loading,
    });

  const { groupBarCharts: groupBarComponents } = useFindGroupBarChartComponent({
    openDialog,
    components,
    loading,
  });

  const { imgCharts: imgChartsComponents } = useFindImgChartsComponents({
    openDialog,
  });

  useEffect(() => {
    if (kpiChartsComponents && kpiChartsEvents && kpiChartsObjects) {
      setAllKpiCharts([
        ...kpiChartsComponents,
        ...kpiChartsEvents,
        ...kpiChartsObjects,
      ]);
    }
  }, [kpiChartsComponents, kpiChartsEvents, kpiChartsObjects, currentTemplate]);

  // Components
  useEffect(() => {
    if (
      kpiChartsComponents &&
      barChartsComponents &&
      textChartsComponents &&
      lineChartsComponents &&
      doughnutChartsComponents &&
      groupDoughnutChartsComponents &&
      timeSeriesChartsComponents &&
      groupBarComponents &&
      timeSeriesComponentsBarCharts &&
      imgChartsComponents
    ) {
      setDataChartComponents([
        ...kpiChartsComponents,
        ...barChartsComponents,
        ...textChartsComponents,
        ...lineChartsComponents,
        ...doughnutChartsComponents,
        ...groupDoughnutChartsComponents,
        ...timeSeriesChartsComponents,
        ...groupBarComponents,
        ...timeSeriesComponentsBarCharts,
        ...imgChartsComponents,
      ]);
    }
  }, [
    kpiChartsComponents,
    barChartsComponents,
    textChartsComponents,
    lineChartsComponents,
    doughnutChartsComponents,
    groupDoughnutChartsComponents,
    timeSeriesChartsComponents,
    currentTemplate,
    groupBarComponents,
    timeSeriesComponentsBarCharts,
    imgChartsComponents,
  ]);

  // Events
  useEffect(() => {
    if (
      kpiChartsEvents &&
      textChartsEvents &&
      lineChartsEvents &&
      barChartsEvents &&
      doughnutChartsEvents &&
      groupDoughnutChartsEvents &&
      timeSeriesChartsEvents &&
      groupBarEvents &&
      timeSeriesEventsBarCharts &&
      imgChartsEvents
    ) {
      setDataChartEvents([
        ...kpiChartsEvents,
        ...textChartsEvents,
        ...lineChartsEvents,
        ...barChartsEvents,
        ...doughnutChartsEvents,
        ...groupDoughnutChartsEvents,
        ...timeSeriesChartsEvents,
        ...groupBarEvents,
        ...timeSeriesEventsBarCharts,
        ...imgChartsEvents,
      ]);
    }
  }, [
    kpiChartsEvents,
    textChartsEvents,
    lineChartsEvents,
    barChartsEvents,
    doughnutChartsEvents,
    groupDoughnutChartsEvents,
    groupBarEvents,
    timeSeriesChartsEvents,
    currentTemplate,
    timeSeriesEventsBarCharts,
    imgChartsEvents,
  ]);

  // Objects
  useEffect(() => {
    if (
      kpiChartsObjects &&
      textChartsObjects &&
      lineChartsObjects &&
      timeSeriesObjects &&
      groupBarObjects &&
      barChartsObjects &&
      doughnutChartsObjects &&
      timeSeriesObjectsBarCharts &&
      imgChartsObjects &&
      groupDoughnutChartsObjects
    ) {
      setDataChartObjects([
        ...kpiChartsObjects,
        ...textChartsObjects,
        ...lineChartsObjects,
        ...timeSeriesObjects,
        ...groupBarObjects,
        ...barChartsObjects,
        ...doughnutChartsObjects,
        ...timeSeriesObjectsBarCharts,
        ...imgChartsObjects,
        ...groupDoughnutChartsObjects,
      ]);
    }
  }, [
    kpiChartsObjects,
    textChartsObjects,
    lineChartsObjects,
    groupBarObjects,
    timeSeriesObjects,
    barChartsObjects,
    doughnutChartsObjects,
    currentTemplate,
    timeSeriesObjectsBarCharts,
    imgChartsObjects,
    groupDoughnutChartsObjects,
  ]);

  useEffect(() => {
    const dataAllTypes = [
      ...dataChartObjects,
      ...dataChartComponents,
      ...dataChartEvents,
    ].map((elm) => {
      return {
        ...elm,
        from: "allTypes",
        fromType: elm.from,
      };
    });
    setAllDataChart(dataAllTypes);
  }, [
    dataChartObjects,
    dataChartComponents,
    dataChartEvents,
    currentOrderName,
    currentTemplate,
  ]);

  useEffect(() => {
    const dataAllKpi = allKpiCharts.map((elm) => {
      return {
        ...elm,
        from: "allTypes",
        fromType: elm.from,
      };
    });
    setAllDataKpiCharts(dataAllKpi);
  }, [allKpiCharts, currentOrderName, currentTemplate]);

  return (
    <>
      {allDataChart && components && events && objects && (
        <DropDragGrid
          dataChart={allDataChart}
          allDataKpiCharts={allDataKpiCharts}
          privateViews={privateViews}
          publicViews={publicViews}
          currentOrderName={currentOrderName}
          setCurrentOrderViews={setCurrentOrderViews}
          currentOrderViews={currentOrderViews}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
          currentPositionId={currentPositionId}
          openDialogRestoreChart={openDialogRestoreChart}
          setOpenDialogRestoreChart={setOpenDialogRestoreChart}
          orderId={orderId}
        />
      )}
    </>
  );
};

export default AllCharts;
