// React
import { useCallback, useState } from "react";
// Components
import FilterTableDigitalTwin from "./Filter/FilterTableDigitalTwin";
import GenericFilterTable from "./Filter/GenericFilterTable";
// Helpers
import createDefaultColumns from "../createDefaultColumns";
// Dependencies
import { useDrag, useDrop } from "react-dnd";
import { flexRender } from "@tanstack/react-table";
// Styles
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import { FROM_TABLE } from "utils/constStrings";
import { BLACK_COLOR, BLACK_GRAY_COLOR } from "utils/const";
import qaqc from "images/qaqc.png";
import FunctionsIcon from "@mui/icons-material/Functions";
import BasicOperationsTable from "components/Tables/FunctionsTable/BasicOperations";
import ClickToolTip from "components/ToolTips/ClickToolTip";

const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
  const data = [...columnOrder];
  data.splice(
    data.indexOf(targetColumnId),
    0,
    data.splice(data.indexOf(draggedColumnId), 1)[0]
  );
  return [...data];
};

const DraggableColumnHeader = ({
  header,
  table,
  customWidth,
  columnFilter,
  setValue,
  setFilterCheckBox,
  filterCheckBox,
  setDateFilters,
  dateFilters,
  useFilter,
  columnResizeMode,
  headers,
  keyValues,
  rowsDynamic,
  setUseOrderColumns,
  rows,
  typeOfLibrary,
  dataChecked,
  setDataChecked,
  from,
  selectedFiltersByColumnOrder,
  currentNameviewColumnOrder,
  numericFields = [],
  typeOfTable,
  isLine,
  pointLibraryId,
}) => {
  const [dragging, setDragging] = useState(false);

  const { allKeyValues } = createDefaultColumns(
    headers,
    keyValues,
    rowsDynamic
  );

  const handleMouseDown = () => {
    setDragging(true);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };
  const cursorStyle = dragging ? { cursor: "grabbing" } : { cursor: "grab" };

  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedColumn) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder || allKeyValues
      );
      setColumnOrder(newColumnOrder);
      setUseOrderColumns(true);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: "column",
  });

  const checkHeaderInFieldsPointsNumbers = (headerId) => {
    if (numericFields.length) {
      const toFind = numericFields.find(
        (item) => item.name === headerId || item.alias === headerId
      );
      return toFind ? true : false;
    }
    return false;
  };

  const GetOpsToolTipContent = (header) => {
    return (
      <BasicOperationsTable
        headerName={header}
        typeOfTable={typeOfTable}
        isLine={isLine}
        pointLibraryId={pointLibraryId}
      />
    );
  };

  return (
    <th
      className="background-table-color"
      ref={dropRef}
      colSpan={header.colSpan}
      style={{
        opacity: isDragging ? 0.5 : 1,
        width: customWidth,
      }}
    >
      {header.isPlaceholder ? null : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              zIndex: 999,
            }}
          >
            {/* operations toltip for type number and type operations fields */}
            {checkHeaderInFieldsPointsNumbers(header.id) && (
              <ClickToolTip
                title={GetOpsToolTipContent(header.id)}
                placement="left"
              >
                <span
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    display: "flex",
                    alignContent: "center",
                  }}
                >
                  <FunctionsIcon
                    sx={{
                      fontSize: "1.9rem",
                      color: `${BLACK_GRAY_COLOR}`,
                      mt: "50%",
                      mb: "50%",
                      ml: "5px",
                      mr: "10px",
                    }}
                  />
                </span>
              </ClickToolTip>
            )}
            <div
              {...{
                className: header.column.getCanSort()
                  ? "cursor-pointer select-none"
                  : "",
                onClick: header.column.getToggleSortingHandler(),
              }}
              ref={previewRef}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {header.getContext().column.id.includes("✅")
                ? header.column.id.slice(
                    0,
                    header.getContext().column.id.length - 1
                  )
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              {header.getContext().column.id.includes("✅") && (
                <img src={qaqc} style={{ width: "30px" }} alt="" />
              )}
              {flexRender(
                header.column.columnDef.header,
                header.getContext()
              ) !== null && (
                <button
                  sty
                  ref={dragRef}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    border: "none",
                    color: `${BLACK_COLOR}`,
                    backgroundColor: "transparent",
                    margin: 0,
                    marginLeft: 10,
                    ...cursorStyle,
                  }}
                >
                  <ControlCameraIcon sx={{ fontSize: "1.5rem" }} />
                </button>
              )}
              {{
                asc: <ExpandLessIcon />,
                desc: <ExpandMoreIcon />,
              }[header.column.getIsSorted()] ?? null}
            </div>
          </div>
          {header.column.getCanFilter() && useFilter && (
            <div style={{ marginTop: "5px" }}>
              {from === FROM_TABLE.digitalTwin ? (
                <FilterTableDigitalTwin
                  column={columnFilter}
                  table={table}
                  setValue={setValue}
                  setFilterCheckBox={setFilterCheckBox}
                  filterCheckBox={filterCheckBox}
                  setDateFilters={setDateFilters}
                  dateFilters={dateFilters}
                  rows={rows}
                  typeOfLibrary={typeOfLibrary}
                  dataChecked={dataChecked}
                  setDataChecked={setDataChecked}
                  selectedFiltersByColumnOrder={selectedFiltersByColumnOrder}
                  currentNameviewColumnOrder={currentNameviewColumnOrder}
                />
              ) : (
                <GenericFilterTable
                  column={columnFilter}
                  table={table}
                  setValue={setValue}
                  setFilterCheckBox={setFilterCheckBox}
                  filterCheckBox={filterCheckBox}
                  setDateFilters={setDateFilters}
                  dateFilters={dateFilters}
                  rows={rows}
                  useFilter={useFilter}
                />
              )}
            </div>
          )}
        </div>
      )}

      <div
        {...{
          onMouseDown: header.getResizeHandler(),
          onTouchStart: header.getResizeHandler(),
          className: `resizer ${
            header.column.getIsResizing() ? "isResizing" : ""
          }`,
          style: {
            transform:
              columnResizeMode === "onEnd" && header.column.getIsResizing()
                ? `translateX(${
                    table.getState().columnSizingInfo.deltaOffset
                  }px)`
                : "",
          },
        }}
      />
    </th>
  );
};

export default DraggableColumnHeader;
